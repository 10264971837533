import React, { Component } from 'react'
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Modal from "react-modal";
import { Icon } from '@iconify/react';
// import { default as ReactSelect } from "react-select";
import { isDesktop } from "react-device-detect";
import Swal from "sweetalert2";

//Miscellaneous 
import { formValidation, clearFormValidation } from "../../hoc/Shared/utility";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import StringInput from "../Input/StringInput/StringInput";
import Button from "../Button/Button";

import { updateMemberAddress } from "../../store/ducks/profile/actions";
import { getMemberProfile } from "../../store/ducks/profile/actions";
import MobileSubHeader from '../PageHeader/MobileSubHeader';
// const options = [
//   { value: 'MY', label: 'Malaysia' },
//   { value: 'ID', label: 'Indonesia' },
// ]

class AddAddressModal extends Component 
{ 
    constructor(props) 
    {
        super(props);
        this.state = 
        { 
            input:
            {
                addr_line_1: null,
                addr_line_2: null,
                state: null,
                city: null,
                postal_code: null,
                country: null,
            },
            countries: [],
            errorMsg: [],
			formError: false,
        };
        this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
    }

	componentDidMount() 
	{
        formValidation("add_adderss_form");
        if(this.props.formFilter.address && this.props.formFilter.address.full_address)
        {
            let newInput = Object.assign({}, this.state.input);
            Object.keys(this.props.formFilter.address).forEach((val) => 
            {
                newInput[val] = this.props.formFilter.address[val];
            });
            
            this.setState({
                input: newInput,
            });
        }

	}

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;
        if (nextProps.memberUpdateAddress !== this.props.memberUpdateAddress) 
		{
			if (nextProps.memberUpdateAddress.responseCode === 200) 
			{
				Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
					icon: 'success',
					title: t('global.success'), 
                    html: t('global.record-updated'),
					confirmButtonText: t('global.ok'),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    }: [],
				}).then((result) => 
				{
					if (result.isConfirmed) 
					{
                        this.props.getMemberProfile();
                        !isDesktop ? this.closeAddAddress() : this.props.toggleAddressModal();
					}

                    
				});
			} 
			else 
			{
				clearFormValidation('add_adderss_form');
				if (nextProps.memberUpdateAddress.message.input_error) 
				{
					this.setState({
						errorMsg: (nextProps.memberUpdateAddress.message.input_error || []),
						formError: true,
					});
					Swal.close();
				}
				else 
				{
					let msg = null;
					msg = '<ul>';
					nextProps.memberUpdateAddress.message.forEach((val) => {
						msg += '<li>' + val + '</li>';
					});
					msg += '</ul>';

					Swal.fire({
						iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393A2A7">' : '',
						icon: 'error',
						title: t("global.sorry"),
						html: msg,
						showConfirmButton: true,
						confirmButtonText: t("global.ok"),
						customClass: (!isDesktop) ? {
							container: 'swal-mobile-container sorry',
							icon: 'swal-sorry-icon'
						} : [],
					});

				}
			}
		}

        if (nextProps.countryData !== undefined) 
        {
            if (nextProps.countryData !== this.props.countryData) 
            {
                let countryData = nextProps.countryData.country;
                // console.log(countryData)
                let countries = [];

                Object.keys(countryData).map((item, i) => 
                {
                    countries[countryData[item].countryId] = countryData[item].countryName;

                    return countries;
                });
                this.setState({
                    countries,
                });
            }
        }

        return true;
    }

    onInputChangeHandler = (event) => 
    {
        // console.log(event.target.name+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        newInput[event.target.name] = event.target.value;
        
        this.setState({
            input: newInput,
            errorMsg: [],
			formError: false,
        });
    }

    onAfterOpen = (e) => {
        formValidation("add_adderss_form");
    }

    closeAddAddress = () => 
    {
        this.props.toggleAddAddressHandler();
    }

    submitHandler = (e) => 
	{
		e.preventDefault();
		const { t } = this.props;

        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
			title: t("global.confirmation"),
			text: t("global.would-you-like-to-proceed") + "?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: t("global.save"),
			cancelButtonText: t("global.cancel"),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            } : [],
		}).then((result) => 
		{
			if (result.isConfirmed) 
			{
				Swal.fire({
					title: "",
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});

                let data = {};
                data['member_id'] = this.props.currentUser.id;

                Object.keys(this.state.input).forEach((val) => 
                {
                    data[val] = this.state.input[val];
                });
                data['country'] = (this.state.countries[this.props.currentUser.country_id] || t(`register.country-available.${this.props.currentUser.country_id}`));
                // console.log(data);
                this.props.updateMemberAddress(data);        
			}
		});
	}

    render() 
    {
        const { t } = this.props;
        
        return (
            <Auxiliary>
                {
                    (isDesktop) ? (
                        <Modal
                            isOpen={this.props.addressModal}
                            closeTimeoutMS={500}
                            portalClassName={`modal-address-form global-modal`}
                            className="modal modal-dialog modal-dialog-centered"
                            ariaHideApp={false}
                            onRequestClose={this.props.toggleAddressModal}
                            id="addAddressModal"
                            onAfterOpen={this.onAfterOpen}
                            >
                            
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.props.formFilter.address && this.props.formFilter.address.full_address  ?   t("profile.edit-address") : t("profile.add-address")  }</h5>
                                    <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleAddressModal} />
                                </div>
                                <div className="modal-body">
                                    <form className="forms-col" method="post" id="add_adderss_form" encType='multipart/form-data' onSubmit={this.submitHandler} noValidate>
                                        <div className="row">
                                            <label className="col-3 col-form-label">{t("profile.address-line-1")}</label>
                                            <div className="col-9 align-self-center">
                                                <StringInput
                                                    id='addr_line_1'
                                                    name='addr_line_1'
                                                    placeholder={t("profile.address-line-1")}
                                                    className={`form-control ${(this.state.errorMsg.addr_line_1) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.addr_line_1 || ''}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    maxLength={100}
                                                    errorMsg={this.state.errorMsg.addr_line_1 || ''}
                                                    requiredMessage={t("validation.required")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col-3 col-form-label">{t("profile.address-line-2")}</label>
                                            <div className="col-9 align-self-center">
                                                <StringInput
                                                    id='addr_line_2'
                                                    name='addr_line_2'
                                                    placeholder={t("profile.address-line-2")}
                                                    className={`form-control ${(this.state.errorMsg.addr_line_2) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.addr_line_2 || ''}
                                                    maxLength={100}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    errorMsg={this.state.errorMsg.addr_line_2}
                                                />
                                            </div>
                                        </div>
                                        <div className="row align-items-center">
                                            <label className="col-3 col-form-label">{t("profile.state")}</label>
                                            <div className="col-3 align-self-center">
                                                <StringInput
                                                    id='state'
                                                    name='state'
                                                    placeholder={t("profile.state")}
                                                    className={`form-control ${(this.state.errorMsg.state) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.state || ''}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    maxLength={30}
                                                    errorMsg={this.state.errorMsg.state || ''}
                                                    requiredMessage={t("validation.required")}
                                                />
                                            </div>
                                            <label className="col-3 col-form-label">{t("profile.city")}</label>
                                            <div className="col-3 align-self-center">
                                                <StringInput
                                                    id='city'
                                                    name='city'
                                                    placeholder={t("profile.city")}
                                                    className={`form-control ${(this.state.errorMsg.city) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.city || ''}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    maxLength={50}
                                                    errorMsg={this.state.errorMsg.city || ''}
                                                    requiredMessage={t("validation.required")}
                                                />
                                            </div>
                                        </div>
                                        <div className="row">
                                            <label className="col-3 col-form-label">{t("profile.postal-code")}</label>
                                            <div className="col-3 align-self-center">
                                                <StringInput
                                                    id='postal_code'
                                                    name='postal_code'
                                                    placeholder={t("profile.postal-code")}
                                                    className={`form-control ${(this.state.errorMsg.postal_code) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.postal_code || ''}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    maxLength={7}
                                                    errorMsg={this.state.errorMsg.postal_code || ''}
                                                    requiredMessage={t("validation.required")}
                                                />
                                            </div>
                                            <label className="col-3 col-form-label">{t("profile.country")}</label>
                                            <div className="col-3 align-self-center">
                                                <StringInput
                                                    id='country'
                                                    name='country'
                                                    value={this.state.countries[this.props.currentUser.country_id] || t(`register.country-available.${this.props.currentUser.country_id}`) || ''}
                                                    disabled={true}
                                                />
                                                {/* <ReactSelect
                                                    classNamePrefix="react-select"
                                                    options={options}
                                                    formatOptionLabel={(item) => (
                                                    <>
                                                        <p>{item.label}</p>
                                                    </>
                                                    )}
                                                /> */}
                                                {/* <ReactSelect
													classNamePrefix="react-select"
													placeholder='Select'
													name="country"
													options={options}
													onChange={(event) => this.onInputChangeHandler(event)}
                                                    // value={this.state.input.country || ''}
													formatOptionLabel={(item) => (
														<>
															<p>{item.label}</p>
														</>
													)}
												/> */}
                                            </div>
                                        </div>
                                        <Button typeButton="submit" classValue="btn btn-gradient-blue" idValue="submit" buttonName={t("global.submit")}/>
                                    </form>
                                </div>
                            </div>
                        </Modal>
                    ) : (
                        <section className="checkout">
                        <div className={`pf-m-dashboard ${this.props.addAddress ? 'd-block pe-auto on-top mall' : 'd-none'}`}>
                            <MobileSubHeader
                                // className="bg-white"
                                closeModalHandler={this.closeAddAddress}
                                header={this.props.formFilter.address && this.props.formFilter.address.full_address  ?   t("profile.edit-address") : t("profile.add-address") }
                                subPage='/'
                            />
                            <form className="forms-col my-3" method="post" id="add_adderss_form" encType='multipart/form-data' onSubmit={this.submitHandler} noValidate>
                                <div className='pd-form-m-col'>
                                    <div className='input-group-col'>
                                        <div className="py-2">
                                            <label className="col-form-label lh-1 p-0 m-0">{t("profile.address-line-1")}</label>
                                            <div className="input-group has-validation">
                                                {/* <input type="text" className="form-control px-0 " placeholder={t("profile.insert-address")} /> */}
                                                <StringInput
                                                    id='addr_line_1'
                                                    name='addr_line_1'
                                                    placeholder={t("profile.address-line-1")}
                                                    className={`form-control px-0 ${(this.state.errorMsg.addr_line_1) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.addr_line_1 || ''}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    maxLength={100}
                                                    errorMsg={this.state.errorMsg.addr_line_1 || ''}
                                                    requiredMessage={t("validation.required")}
                                                />
                                            </div>
                                        </div>
                                        <div className="py-2 border-top">
                                            <label className="col-form-label lh-1 p-0 m-0">{t("profile.address-line-2")}</label>
                                            <div className="input-group has-validation">
                                                <StringInput
                                                    id='addr_line_2'
                                                    name='addr_line_2'
                                                    placeholder={t("profile.address-line-2")}
                                                    className={`form-control px-0 ${(this.state.errorMsg.addr_line_2) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.addr_line_2 || ''}
                                                    maxLength={100}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    errorMsg={this.state.errorMsg.addr_line_2}
                                                />
                                            </div>
                                        </div>
                                        <div className="py-2 border-top">
                                            <label className="col-form-label lh-1 p-0 m-0">{t("profile.city")}</label>
                                            <div className="input-group has-validation">
                                                <StringInput
                                                    id='city'
                                                    name='city'
                                                    placeholder={t("profile.city")}
                                                    className={`form-control px-0 ${(this.state.errorMsg.city) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.city || ''}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    maxLength={50}
                                                    errorMsg={this.state.errorMsg.city || ''}
                                                    requiredMessage={t("validation.required")}
                                                />
                                            </div>
                                        </div>
    
                                        <div className="py-2 border-top">
                                            <label className="col-form-label lh-1 p-0 m-0">{t("profile.state")}</label>
                                            <div className="input-group has-validation">
                                                <StringInput
                                                    id='state'
                                                    name='state'
                                                    placeholder={t("profile.state")}
                                                    className={`form-control px-0 ${(this.state.errorMsg.state) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.state || ''}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    maxLength={30}
                                                    errorMsg={this.state.errorMsg.state || ''}
                                                    requiredMessage={t("validation.required")}
                                                />
                                            </div>
                                        </div>
    
                                        <div className="py-2 border-top">
                                            <label className="col-form-label lh-1 p-0 m-0">{t("profile.postal-code")}</label>
                                            <div className="input-group has-validation">
                                                {/* <input type="text" className="form-control px-0" placeholder={t("profile.insert-zip")} /> */}
                                                <StringInput
                                                    id='postal_code'
                                                    name='postal_code'
                                                    placeholder={t("profile.postal-code")}
                                                    className={`form-control px-0 ${(this.state.errorMsg.postal_code) ? 'was-validated is-invalid' : ''}`}
                                                    value={this.state.input.postal_code || ''}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    maxLength={7}
                                                    errorMsg={this.state.errorMsg.postal_code || ''}
                                                    requiredMessage={t("validation.required")}
                                                />
                                            </div>
                                        </div>
    
                                        <div className="py-2 border-top">
                                            <label className="col-form-label lh-1 p-0 m-0">{t("profile.country")}</label>
                                            <div className="input-group has-validation">
                                                <StringInput
                                                    id='country'
                                                    name='country'
                                                    className="form-control px-0"
                                                    value={this.state.countries[this.props.currentUser.country_id] || t(`register.country-available.${this.props.currentUser.country_id}`) || ''}
                                                    disabled={true}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <button type='submit' className='btn btn-gradient-blue mx-3'>{t("global.submit")}</button>
                                </div>
                            </form>
                        </div>
                    </section>
					)
				}
            </Auxiliary>
        )
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
        memberUpdateAddress: state.profile.update_member_address,
        memberProfileData: state.profile.member_profile_data,
        countryData: state.home.country_data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        updateMemberAddress: (data) => dispatch(updateMemberAddress(data)),
        getMemberProfile: () => dispatch(getMemberProfile()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(AddAddressModal));
