import React, { Component, createRef } from 'react';
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
import { Fade } from "react-awesome-reveal";
import Timer from "react-compound-timer";
import Confetti from 'react-confetti';

import Button from "../../components/Button/Button";
import SideBar from '../../components/SideBar/SideBar';
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

class MobileFifaWorldCup extends Component 
{
    constructor(props) 
    {
        super(props);
        this.topSection = createRef();
    }

    scrollToTop = () => 
    {
        this.topSection.current.scrollIntoView({ behavior: "smooth", block: "start" });
    }

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    render() 
    {
        const { t } = this.props;
        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        // const img = this.imageImport(require.context("../../assets/images/leaderboard", false, /\.(png|jpe?g|svg)$/));
        const image = this.imageImport(require.context("../../assets/images/world-cup", false, /\.(png|jpe?g|svg)$/));
        const flag = this.imageImport(require.context("../../assets/images/world-cup/flag", false, /\.(png|jpe?g|svg)$/));

        let imageLanguage = '';
        switch (this.props.currentUser.language_code) 
        {
            case "zh-CN":
                imageLanguage = "-cn";
            break;
            // case "ms":
            //   imageLanguage = "-ms";
            //   break;
            default:
                imageLanguage = "";
            break;
        }
        
        let dateList = this.props.dropDown.dateList ? this.props.dropDown.dateList : [];
        let match = this.props.dropDown.match ? this.props.dropDown.match : [];
        let fakeData = [];

        if(this.props.totalMatch[this.props.input.date] && this.props.totalMatch[this.props.input.date].count > 0)
        {
            let fakeAmt = 8;
            let fakeCount = (this.props.totalMatch[this.props.input.date].count > 4) ? 4 : this.props.totalMatch[this.props.input.date].count;
            for (let i = 1; i <= fakeCount; i++) 
            {
                fakeData.push(
                    <div className={`wc-reward-container active col-4 `} key={i}>
                        <div className='wc-reward-header'>
                            <p className='m-0'>{t('world-cup.modal-msg', {attribute: i})}</p>
                        </div>
                        <div>
                            <img src={image[`reward-${i}.png`]} alt={`reward-${i}`} />
                            <p>{fakeAmt}</p>
                        </div>
                    </div>
                );
                fakeAmt = fakeAmt + 8;
            }
        }

        return (
            <Auxiliary>
                <SideBar />
                <div className="banner-col" >
                    <div className="banner-item">
                        <img src={banner[`world-cup-banner${imageLanguage}.webp`]} alt="R77 Promos" />
                    </div>
                </div>
                <section className='wc-section' ref={this.topSection}>
                    <div className='section-header-gray mt-3 mb-0'>
                        <h1>{t('world-cup.title')}</h1>
                        <h5>{t('world-cup.title-1')}</h5>
                    </div>
                    <div className='lb-section'>
                        <div className='lb-event-date mt-3'>
                            {/* <button className='btn btn-gradient-blue'>
                                <Icon icon="uil:angle-left" />
                            </button>
                            <div className='date'>
                                <Icon icon="uil:clock" />
                                <Fade>
                                <span>2022年11月23日</span>
                                <span className='status active'>进行中</span>
                                </Fade>
                            </div>
                            <button className='btn btn-gradient-blue'>
                                <Icon icon="uil:angle-right" />
                            </button> */}

                            {
                                (this.props.input.date !== '') 
                                ?
                                    dateList.length > 0 && dateList.map((item, index) => (
                                        (item.name === this.props.input.date) 
                                        ? 
                                            <>
                                                <button className='btn btn-gradient-blue' onClick={() => this.props.changeDateHandler(dateList[((index - 1) < 0) ? (dateList.length - 1) : (index - 1)].name)}>
                                                    <Icon icon="uil:angle-left" />
                                                </button>
                                                <div className='date'>
                                                    <span>{item.label}</span>
                                                    <span className={`status ${item.status}`}>{item.annouce}</span>
                                                </div>
                                                <button className='btn btn-gradient-blue' onClick={() => this.props.changeDateHandler(dateList[((index + 1) === dateList.length) ? 0 : (index + 1)].name)}>
                                                    <Icon icon="uil:angle-right" />
                                                </button>
                                            </>
                                        : 
                                            null
                                    ))
                                :
                                    null
                            }
                        </div>
                    </div>

                    <div className='mb-5'>
                        <form className="" method="post" id="wc_form" encType='multipart/form-data' onSubmit={this.props.submitHandler} noValidate>
                            <div className='wc-vs-section card-shadow wc-table mt-5 text-center'>
                                <div className='section-header-date'>
                                    <h3 className='text-center'>{t('world-cup.title-2', {attribute: ((this.props.totalMatch[this.props.input.date] && this.props.totalMatch[this.props.input.date].slug && t(`world-cup.${this.props.totalMatch[this.props.input.date].slug}`)) || '')})}</h3>
                                    {(this.props.totalMatch[this.props.input.date] 
                                    && this.props.totalMatch[this.props.input.date].disabled 
                                    && this.props.totalMatch[this.props.input.date].totalWinCount > 0)
                                    ? 
                                        (<h4 className='result-text'>{t('world-cup.congrats-msg-3')}<span className='orange-text'>{this.props.totalMatch[this.props.input.date].totalWinCount}</span>{t('world-cup.congrats-msg-4')}</h4>)
                                    :
                                        <p className='event-date'>{t('world-cup.remain-time')}：
                                            {this.props.input.date && this.props.input.date !== '' && !this.props.loading && 
                                                <Timer 
                                                    initialTime={this.props.getDuration()}
                                                    direction="backward"
                                                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                >
                                                    <Timer.Days />{t('world-cup.d')} <Timer.Hours />{t('world-cup.h')} <Timer.Minutes />{t('world-cup.m')} <Timer.Seconds />{t('world-cup.s')}
                                                </Timer>
                                            }
                                        </p>
                                    }
                                    <h5 className='mt-3'>
                                        {this.props.totalMatch[this.props.input.date] && !this.props.totalMatch[this.props.input.date].disabled &&
                                            (<>{t('world-cup.submit-msg', {attribute: (this.props.totalMatch[this.props.input.date] && this.props.totalMatch[this.props.input.date].count) || 0})}</>)
                                        }
                                    </h5>
                                </div>
                                <div className='wc-head row gy-2'>
                                    {match.length > 0 &&
                                    <>
                                        {match.map((item, index) => 
                                        {
                                            if(item.date === this.props.input.date)
                                            {
                                                return (
                                                    <div className='wc-body' key={index}>
                                                        <Fade duration={1000} triggerOnce>
                                                            <div className='wc-bg'>
                                                                <img src={image["world-cup-vs-bg-mobile.jpg"]} alt="world cup vs bg" />
                                                                <div className='wc-content'>
                                                                    <p>{t('world-cup.start-time')}：{item.label}</p>
                                                                    <div className='wc-details'>
                                                                        <div className={`form-check form-check-inline`}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name={`matchOpt${item.teamAId}`}
                                                                                id={`matchOpt${item.teamAId}`}
                                                                                value={`${item.id}_${item.teamAId}`}
                                                                                onChange={
                                                                                    (this.props.totalMatch[this.props.input.date] && !this.props.totalMatch[this.props.input.date].disabled)
                                                                                    ?
                                                                                    (event) => this.props.onInputChangeHandler(event)
                                                                                    :
                                                                                    null
                                                                                }
                                                                                checked={
                                                                                    (this.props.input.matchArr[item.id]
                                                                                    && this.props.input.matchArr[item.id] !== undefined
                                                                                    && Number(this.props.input.matchArr[item.id]) === Number(item.teamAId))
                                                                                    || (Number(this.props.input.matchBetArr[item.id]) === Number(item.teamAId))
                                                                                    ? 
                                                                                    true 
                                                                                    : 
                                                                                    false
                                                                                }
                                                                                // disabled={
                                                                                //     (this.props.input.matchBetArr[item.id] || this.props.totalMatch[this.props.input.date].disabled)
                                                                                //     ? 
                                                                                //     true
                                                                                //     :
                                                                                //     false
                                                                                // }
                                                                            />
                                                                            <label className={`btn form-check-label ${(Number(item.teamAScore) < Number(item.teamBScore)) ? 'disabled' : ''}`} htmlFor={`matchOpt${item.teamAId}`}>
                                                                                <img src={flag[`${item.teamA.toLowerCase()}.svg`]} alt={item.teamA} />
                                                                                <p>{t(`world-cup.team.${item.teamA.toLowerCase()}`)}</p>
                                                                            </label>
                                                                            {(Number(item.teamAScore) > Number(item.teamBScore))
                                                                            // {((Number(item.teamAScore) > Number(item.teamBScore)) || ((Number(item.teamAExtraScore) > Number(item.teamBExtraScore))))
                                                                            ?
                                                                                <img className="win-img" src={image["win.png"]} alt={`win${item.teamA}`} />
                                                                            :
                                                                                null
                                                                            }
                                                                        </div>

                                                                        {/* <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `versus-body` : ''}>
                                                                            <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `mb-2` : ''}>
                                                                                <img className='versus-img' src={image["versus.png"]} alt="vs" />
                                                                            </div>
                                                                            {(item.teamAScore !== null && item.teamBScore !== null) 
                                                                            ? 
                                                                                <>
                                                                                    <span>{t('world-cup.score')}</span>
                                                                                    <span className='btn-white'>
                                                                                        {item.teamAScore} - {item.teamBScore}
                                                                                    </span>
                                                                                </>
                                                                            :
                                                                                null
                                                                            }
                                                                        </div> */}
                                                                        <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `versus-body` : ''}>
                                                                            {(item.teamAScore !== null && item.teamBScore !== null) 
                                                                            ? 
                                                                                <>
                                                                                    <span>{t('world-cup.score')}</span>
                                                                                    <span className='score-font'>
                                                                                        {(item.isExtra === 1)
                                                                                        ?
                                                                                        <>{item.teamAExtraScore} - {item.teamBExtraScore} </>
                                                                                        :
                                                                                        <>{item.teamAScore} - {item.teamBScore} </>
                                                                                        }
                                                                                    </span>    
                                                                                    {item.isExtra >= 1 && <span className='aet-font'>({t('world-cup.aet')})</span>}
                                                                                    
                                                                                    {item.isExtra === 2 &&
                                                                                        <><span className='mt-2'>{t('world-cup.penalties')}</span>
                                                                                        <span className='btn-gradient-blue'>{item.teamAExtraScore} - {item.teamBExtraScore}</span>
                                                                                    </>
                                                                                    }
                                                                                    
                                                                                </>
                                                                            :
                                                                                <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `mb-3` : ''}>
                                                                                    <img className='versus-img' src={image["versus.png"]} alt="vs" />
                                                                                </div>
                                                                            }
                                                                        </div>

                                                                        {/* <div className='versus-body'>
                                                                            <span>总得分</span>
                                                                            <span className='score-font'>2 - 2</span>
                                                                            <span className='aet-font'>(a.e.t)</span>
                                                                            <span className='mt-2'>Penalties</span>
                                                                            <span className='btn-gradient-blue'>3 - 2</span>
                                                                        </div> */}

                                                                        <div className={`form-check form-check-inline`}>
                                                                            <input
                                                                                className="btn-check"
                                                                                type="radio"
                                                                                name={`matchOpt${item.teamBId}`}
                                                                                id={`matchOpt${item.teamBId}`}
                                                                                value={`${item.id}_${item.teamBId}`}
                                                                                onChange={
                                                                                    (this.props.totalMatch[this.props.input.date] && !this.props.totalMatch[this.props.input.date].disabled)
                                                                                    ?
                                                                                    (event) => this.props.onInputChangeHandler(event)
                                                                                    :
                                                                                    null
                                                                                }
                                                                                checked={
                                                                                    (this.props.input.matchArr[item.id]
                                                                                    && this.props.input.matchArr[item.id] !== undefined
                                                                                    && Number(this.props.input.matchArr[item.id]) === Number(item.teamBId))
                                                                                    || (Number(this.props.input.matchBetArr[item.id]) === Number(item.teamBId))
                                                                                    ? 
                                                                                    true 
                                                                                    : 
                                                                                    false
                                                                                }
                                                                                // disabled={
                                                                                //     (this.props.input.matchBetArr[item.id] || this.props.totalMatch[this.props.input.date].disabled)
                                                                                //     ?
                                                                                //     true
                                                                                //     :
                                                                                //     false
                                                                                // }
                                                                            />
                                                                            <label className={`btn form-check-label ${(Number(item.teamBScore) < Number(item.teamAScore)) ? 'disabled' : ''}`} htmlFor={`matchOpt${item.teamBId}`}>
                                                                                <img src={flag[`${item.teamB.toLowerCase()}.svg`]} alt={item.teamB} />
                                                                                <p>{t(`world-cup.team.${item.teamB.toLowerCase()}`)}</p>
                                                                            </label>
                                                                            {(Number(item.teamBScore) > Number(item.teamAScore))
                                                                            // {((Number(item.teamBScore) > Number(item.teamAScore)) || ((Number(item.teamBExtraScore) > Number(item.teamAExtraScore))))
                                                                            ?
                                                                                <img className="win-img" src={image["win.png"]} alt={`win${item.teamB}`} />
                                                                            :
                                                                                null
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {(item.teamAScore !== null && item.teamBScore !== null) &&
                                                                        <Confetti
                                                                            width={544}
                                                                            height={270}
                                                                            numberOfPieces={30}
                                                                            opacity={0.3}
                                                                        />
                                                                    }
                                                                </div>
                                                            </div>
                                                        </Fade>
                                                    </div>
                                                );
                                            }
                                            else
                                            {
                                                return null;
                                            }
                                        })}
                                    </>}
                                </div>
                            </div>
                            {(this.props.totalMatch[this.props.input.date] && !this.props.totalMatch[this.props.input.date].disabled)
                            ?
                                <Button
                                    typeButton={(localStorage.getItem("accessToken")) ? `submit` : `button`}
                                    classValue="btn-gradient-blue w-100 mt-3"
                                    buttonName={(localStorage.getItem("accessToken")) ? t('global.submit') : t("world-cup.login-to-submit")}
                                    clicked={(localStorage.getItem("accessToken")) ? null : () => this.props.history.push("/login")}
                                />
                            :
                                null
                            }
                        </form>

                        <div className='section-header-gray mt-5 mb-2'>
                            <h1>{t('world-cup.title-3')}</h1>
                            <h5>{t('world-cup.title-4')}</h5>
                        </div>
                        <div className='wc-reward row gap-3 mb-5'>
                            {fakeData}
                            {/* {
                                this.props.fakeData.table2 && this.props.fakeData.table2.map((item, index) => (
                                    <div className={`wc-reward-container  ${item.status}`} key={index}>
                                    <div className='wc-reward-header'>
                                        <p className='m-0'>{item.value}</p>
                                    </div>
                                    <div>
                                        <img src={image[`${item.img}.png`]} alt="reward-1" />
                                        <p>{item.label}</p>
                                    </div>
                                    </div>
                                ))
                            } */}
                        </div>
                    </div>
                </section>

                {/* using lb section */}

                <div className='term-section no-bg'>
                    {/* <h4 className='tnc-title'>{t('world-cup.title-6')}</h4> */}
                    <div className='term-container no-bg'>
                        <p>{t('world-cup.title-5')}</p>
                        <table className='table mt-2'>
                        <thead>
                            <tr>
                            <th>{t('world-cup.title-7')}</th>
                            <th>{t('world-cup.title-8')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                            <td>{t('world-cup.guess', {attribute: 1})}</td>
                            <td>8</td>
                            </tr>
                            <tr>
                            <td>{t('world-cup.guess', {attribute: 2})}</td>
                            <td>16</td>
                            </tr>
                            <tr>
                            <td>{t('world-cup.guess', {attribute: 3})}</td>
                            <td>24</td>
                            </tr>
                            <tr>
                            <td>{t('world-cup.guess', {attribute: 4})}</td>
                            <td>32</td>
                            </tr>
                        </tbody>
                        </table>

                        <div className='term-content no-bg'>
                            <p className='mb-2'>{t('world-cup.rules')}：</p>
                            <ol>
                                <li>{t('world-cup.rule-1')}</li>
                                <li>{t('world-cup.rule-2')}</li>
                                <li>{t('world-cup.rule-3')}</li>
                                <li>{t('world-cup.rule-4')}</li>
                                <li>{t('world-cup.rule-5')}</li>
                                <li>{t('world-cup.rule-6')}</li>
                                <li>{t('world-cup.rule-7')}</li>
                                <li>{t('world-cup.rule-8')}</li>
                                <li>{t('world-cup.rule-9')}</li>
                                <li>{t('world-cup.rule-10')}</li>
                                <li>{t('world-cup.rule-11')}</li>
                            </ol>
                        </div>
                    </div>
                </div>

                <div className="btt-btn mt-0 mb-5">
                    <div className="btn btn-white" onClick={this.scrollToTop}>
                        <Icon icon="ph:caret-double-up-bold" />
                        {t("vip.back-to-top")}
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

export default (withNamespaces("translation")(MobileFifaWorldCup)); 