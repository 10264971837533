import React, { Component } from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Icon } from '@iconify/react';
import { withNamespaces } from "react-i18next";
import { getUserData } from "../../../store/ducks/currentUser/actions";
import { connect } from 'react-redux';
import * as actions from "../../../store/ducks/christmas/actions";
import { NavLink } from "react-router-dom";
import { Fade } from "react-awesome-reveal";
import SideBar from '../../../components/SideBar/SideBar';

export class MobileChristmasEvent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            eventEnd: false,
        };
    }


    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const tNc = this.imageImport(require.context("../../../assets/images/tnc", false, /\.(png|jpe?g|svg)$/));
        const banner = this.imageImport(require.context("../../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const prizeImg = this.imageImport(require.context("../../../assets/images/christmas-lucky-draw", false, /\.(png|jpe?g|svg|webp)$/));
        const tncMobile = this.imageImport(require.context("../../../assets/images/tnc/mobile", false, /\.(png|jpe?g|svg)$/));

        let imageLanguage = '';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn";
                break;
            case "ms":
                imageLanguage = "-bm"
                break;
            case "indon":
                imageLanguage = "-id"
                break;
            default:
                imageLanguage = "";
                break;
        }


        return (
            <Auxiliary>
                <SideBar />
                <div className="banner-col">
                    <div className="banner-item">
                        <img src={banner[`xmas_2023${imageLanguage}.jpg`]} alt="R77 Promos" />
                    </div>
                </div>

                <section className='pl-section'>
                    <div className='section-header-gray mt-3'>
                        <h2>{t('christmas-event.title')}</h2>
                        <p>{t('christmas-event.subtitle')}</p>
                        <p className='event-date'>{t('christmas-event.date')}</p>
                    </div>

                    {this.props.eventEnd ? (
                        <>
                            <div className='d-flex align-items-center justify-content-center mb-3 mt-5'>
                                <span className='p-2'>
                                    <img className="w-100 h-auto object-fit-cover" src={prizeImg[`star.svg`]} alt="star icon" />
                                </span>
                                <div className='section-header-gray mb-0'>
                                <h2>{t('christmas-event.lucky-winner')}</h2>
                                </div>
                                <span className='p-2'>
                                    <img className="w-100 h-auto object-fit-cover" src={prizeImg[`star.svg`]} alt="star icon" />
                                </span>
                            </div>
                            <Fade duration={1000} triggerOnce>
                                <div className='term-section christmas ' style={{ backgroundColor: 'unset' }}>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                            <th>{t('christmas-event.rank')}</th>
                                                            <th>{t('leaderboard.prize')}</th>
                                                            <th>{t('leaderboard.winner')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>{t('christmas-event.1st-prize')}</td>
                                                            <td>{t('christmas-event.myr33000')}</td>
                                                <td>J*l*n*77</td>
                                            </tr>
                                            <tr>
                                            <td>{t('christmas-event.2nd-prize')}</td>
                                                            <td>{t('christmas-event.macbook')}</td>
                                                <td>C*a*l*s76</td>
                                            </tr>
                                            <tr>
                                            <td>{t('christmas-event.3rd-prize')}</td>
                                                            <td>{t('christmas-event.iphone')}</td>
                                                <td>J*m*s*a</td>
                                            </tr>
                                            <tr>
                                            <td>{t('christmas-event.4th-more')}</td>
                                                            <td>{t('christmas-event.myr133')}</td>
                                                <td>D*v*d*3
                                                    T*o*a*8
                                                    S*e*e*998
                                                    B*I*n*aq
                                                    W*l*I*m67
                                                    T*m*t*y56
                                                    M*q*h*aq</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>


                            </Fade>
                        </>

                    ) : ("")}

                    <div className='section-header-gray my-3'>
                    <h3>{t('christmas-event.prize-to-won')}</h3>
                                    <p> {t('christmas-event.minimun-deposit')} </p>
                    </div>

                    <div className='row text-center'>
                        <div className='col-12'>
                            <img className='w-75 h-auto object-fit-cover' src={prizeImg[`prize-1${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>

                        <div className='col-6' >
                            <img className='w-100 h-auto object-fit-cover' src={prizeImg[`prize-2${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>

                        <div className='col-6' >
                            <img className='w-100 h-auto object-fit-cover' src={prizeImg[`prize-3${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>
                        <div className='col-12'>
                            <img className='w-100 h-auto object-fit-cover' src={prizeImg[`prize-4${imageLanguage}.webp`]} alt="xmas lucky draw one" />
                        </div>
                        <div className="d-flex align-items-center justify-content-center mt-4">
                            {(localStorage.getItem("accessToken")) ?
                                <button 
                                    className="d-flex align-items-center justify-content-center btn-gradient-blue btn" 
                                    onClick={this.props.claimHandler} 
                                    id="checked"
                                    disabled={this.props.buttonDisabled}
                                    ref={(ref) => (this.checkedButtonRef = ref)} 
                                >
                                    {t('christmas-event.check')}
                                </button>
                            : 
                            null
                            }
                        </div>
                    </div>

                    <div className='term-section mobile mt-5'>
                        <div className='term-container card-shadow'>
                            <img src={tncMobile[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" />
                            <div className='term-content'>
                                <ol>
                                <li>{t('christmas-event.tnc-1')}</li>
                                                <li>{t('christmas-event.tnc-2')}</li>
                                    <table className='table'>
                                        <thead>
                                            <tr>
                                                <th>{t('christmas-event.rank')}</th>
                                                <th>{t('leaderboard.prize')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                            <td>{t('christmas-event.1st-prize')}</td>
                                                            <td>{t('christmas-event.myr33000')}</td>
                                            </tr>
                                            <tr>
                                            <td>{t('christmas-event.2nd-prize')}</td>
                                                            <td>{t('christmas-event.macbook')}</td>
                                            </tr>
                                            <tr>
                                            <td>{t('christmas-event.3rd-prize')}</td>
                                                            <td>{t('christmas-event.iphone')}</td>
                                            </tr>
                                            <tr>
                                            <td>{t('christmas-event.4th-more')}</td>
                                                            <td>{t('christmas-event.myr133')}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <li>{t('christmas-event.tnc-3')}</li>
                                                <li>{t('christmas-event.tnc-4')}</li>
                                                <li>{t('christmas-event.tnc-5')}</li>
                                                <li>{t('christmas-event.tnc-6')}</li>
                                                <li>{t('christmas-event.tnc-7')}</li>
                                                <li>{t('christmas-event.tnc-8')}</li>
                                </ol>
                            </div>
                            <img src={tNc["term-footer.png"]} alt="Terms Condition footer" />
                        </div>
                    </div>
                </section>
            </Auxiliary>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
    };
}


const mapDispatchToProps = (dispatch) => {
    return {
        getUserData: (data) => dispatch(getUserData(data)),
    };
}
export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileChristmasEvent));