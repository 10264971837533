import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";

//Miscellaneous
// import { default as ReactSelect } from "react-select";
import ReactPinField from "react-pin-field";
// import Swal from "sweetalert2";
import { setSecurityPin, verifySecurityPin, updateSecurityPin, getMemberProfile, removeSecurityPin } from "../../store/ducks/profile/actions";
import { getCountry } from "../../store/ducks/home/actions";
import { generateSMSOTP, verifySMSOTP, resendOTP } from "../../store/ducks/profile/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";

import ValidationError from "../../components/ValidationError/ValidationError";
import StringInput from "../../components/Input/StringInput/StringInput";
import Select from "../../components/Input/Select/Select";
import Button from "../../components/Button/Button";
import Modal from "react-modal";

//Components
// import ValidationError from "../ValidationError/ValidationError";
// import StringInput from "../Input/StringInput/StringInput";
// import Select from "../Input/Select/Select";
// import Button from "../Button/Button";

import MobileSecurityPin from "./MobileSecurityPin";
import { isDesktop } from "react-device-detect";

//Icons
import { Icon } from "@iconify/react";

let countryArry = [];
let mobileCountryArray = [];
let contact_no_min_limits = 9;
let contact_no_max_limits = 10;
class SecurityPinModal extends Component {
    ref = React.createRef();
    constructor(props) {
        super(props);
        this.state = {
            securityPin: "",
            verifySecurityPinStatus: false,
            forgetSecurityStatus: false,
            forgetSecurityChangeStatus: false,
            sms_otp_code: "",
            currentMobileNo: this.props.currentUser.data.contact_no ? this.props.currentUser.data.contact_no.substr(2) : "",
            currentCountryId: "",
            currentMobileDialCode: "",
            check_sending_sms_times: "",
            check_sending_sms_status: true,
            time: {},
            seconds: 180,
            timer: 0,
            generateOTPStatus: false,
            resend_otp_button_disabled: true,
            errorMsg: [],
            formError: false,

            removeSecurityPin: false,
            openSecurityPinModal: false,
        };
    }

    componentDidMount() {
        this.props.getCountry();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.set_security_pin_result !== this.props.set_security_pin_result) {
            if (isDesktop) {
                if (nextProps.set_security_pin_result.responseCode === 200) {
                    Swal.fire({
                        // icon: nextProps.set_security_pin_result.msgType,
                        icon: "success",
                        title: t("global.success"),
                        html: nextProps.set_security_pin_result.message[0],
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                    }).then(() => {
                        Swal.close();

                        this.setState({
                            verifySecurityPinStatus: false,
                        });

                        this.props.getMemberProfile();

                        // document.getElementById("securityPinModal").click();

                        window.location.reload();
                    });
                } else {
                    Swal.fire({
                        // icon: nextProps.set_security_pin_result.msgType,
                        icon: "error",
                        title: t("global.error"),
                        html: nextProps.set_security_pin_result.message[0],
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.set_security_pin_result.message.input_error || [],
                                formError: true,
                            });
                            Swal.close();
                        }
                    });
                }
            } else {
                if (nextProps.set_security_pin_result.responseCode === 200) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
                        title: t("global.success"),
                        icon: "success",
                        html: nextProps.set_security_pin_result.message[0],
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();

                            this.props.closeVerification("");

                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                        icon: "error",
                        title: t("global.sorry"),
                        html: nextProps.set_security_pin_result.message[0],
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.set_security_pin_result.message.input_error || [],
                                formError: true,
                            });
                            Swal.close();

                            // this.props.closeVerification();
                        }
                    });
                }
            }
        }

        if (this.props.securityPinModal !== "enter-sec-pin") {
            if (nextProps.verify_security_pin_result !== this.props.verify_security_pin_result) {
                if (isDesktop) {
                    if (nextProps.verify_security_pin_result.responseCode === 200) {
                        Swal.fire({
                            // icon: nextProps.verify_security_pin_result.msgType,
                            icon: "success",
                            title: t("global.success"),
                            html: nextProps.verify_security_pin_result.message[0],
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (nextProps.verify_security_pin_result.data.verify_security_pin_status === true) {
                                    Swal.close();
                                    this.setState({
                                        verifySecurityPinStatus: true,
                                        securityPin: "",
                                    });
                                    Array.from(this.ref.current.children).forEach((input) => (input.value = ""));
                                    this.ref.current.children[0].focus();
                                    // this.props.getMemberProfile();
                                    // window.location.reload();
                                }
                            }
                        });
                    } else {
                        Swal.fire({
                            // icon: nextProps.verify_security_pin_result.msgType,
                            icon: "error",
                            title: t("global.error"),
                            html: nextProps.verify_security_pin_result.message[0],
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                        }).then(() => {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.verify_security_pin_result.message.input_error || [],
                                formError: true,
                            });

                            Array.from(this.ref.current.children).forEach((input) => (input.value = ""));
                            this.ref.current.children[0].focus();

                            Swal.close();
                        });
                    }
                } else {
                    if (nextProps.verify_security_pin_result.responseCode === 200) {
                        Swal.fire({
                            iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
                            icon: "success",
                            title: t("global.success"),
                            html: nextProps.verify_security_pin_result.message[0],
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                            customClass: {
                                container: "swal-mobile-container sucess",
                                icon: "swal-success-icon",
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                if (nextProps.verify_security_pin_result.data.verify_security_pin_status === true) {
                                    Swal.close();
                                    //Ethan here to reset after enter current security pin to remove the pin field value
                                    this.setState({
                                        verifySecurityPinStatus: true,
                                        securityPin: "",
                                    });
                                    Array.from(this.ref.current.children).forEach((input) => (input.value = ""));
                                    this.ref.current.children[0].focus();
                                }
                            }
                        });
                    } else {
                        Swal.fire({
                            iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                            icon: "error",
                            title: t("global.sorry"),
                            html: nextProps.verify_security_pin_result.message[0],
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                            customClass: {
                                container: "swal-mobile-container sorry",
                                icon: "swal-sorry-icon",
                            },
                        }).then(() => {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.verify_security_pin_result.message.input_error || [],
                                formError: true,
                            });

                            Array.from(this.ref.current.children).forEach((input) => (input.value = ""));
                            this.ref.current.children[0].focus();

                            Swal.close();
                        });
                    }
                }
            }
        }

        if (this.props.transType !== "withdrawal") {
            if (nextProps.update_security_pin_result !== this.props.update_security_pin_result) {
                if (nextProps.update_security_pin_result.responseCode === 200) {
                    if (isDesktop) {
                        Swal.fire({
                            // icon: nextProps.update_security_pin_result.msgType,
                            icon: "success",
                            title: t("global.success"),
                            html: nextProps.update_security_pin_result.message[0],
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Swal.close();
                                // document.getElementById("securityPinModal").click();
                                window.location.reload();
                            }
                        });
                    } else {
                        Swal.fire({
                            iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
                            title: t("global.success"),
                            icon: "success",
                            html: nextProps.update_security_pin_result.message[0],
                            showConfirmButton: true,
                            confirmButtonText: t("global.ok"),
                            customClass: {
                                container: "swal-mobile-container sucess",
                                icon: "swal-success-icon",
                            },
                        }).then((result) => {
                            if (result.isConfirmed) {
                                Swal.close();

                                this.props.closeVerification("");

                                window.location.reload();
                            }
                        });
                    }
                } else {
                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: nextProps.update_security_pin_result.message[0],
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: !isDesktop
                            ? {
                                container: "swal-mobile-container sorry",
                                icon: "swal-sorry-icon",
                            }
                            : [],
                    }).then(() => {
                        this.setState({
                            touched: true,
                            errorMsg: nextProps.update_security_pin_result.message.input_error || [],
                            formError: true,
                        });
                        Swal.close();
                    });
                }
            }
        }

        if (nextProps.countryData !== undefined) {
            if (nextProps.countryData !== this.props.countryData) {
                let countryData = nextProps.countryData.country;
                countryArry = [];
                mobileCountryArray = [];
                Object.keys(countryData).forEach((item, i) => {
                    if (this.props.currentUser.data.country_code === countryData[item].countryShortCode) {
                        this.setState({
                            currentCountryId: countryData[item].countryId,
                            currentMobileDialCode: countryData[item].dialCode,
                        });
                    }
                    countryArry.push({
                        label: countryData[item].countryName + countryData[item].dialCode,
                        value: countryData[item].countryId,
                    });
                    mobileCountryArray.push({
                        code: countryData[item].countryShortCode,
                        label: countryData[item].dialCode,
                        value: countryData[item].countryId,
                        details: { label: countryData[item].countryName + countryData[item].dialCode, value: countryData[item].countryId },
                    });
                });
            }
        }

        if (nextProps.generate_sms_otp_result !== this.props.generate_sms_otp_result) {
            if (nextProps.generate_sms_otp_result.responseCode === 200) {
                if (!nextProps.generate_sms_otp_result.data.sms_reached_limit) {
                    let timeLeftVar = this.secondsToTime(this.state.seconds);

                    this.setState({ time: timeLeftVar });

                    this.startTimer();
                }

                this.setState({
                    generateOTPStatus: true,
                    check_sending_sms_times: nextProps.generate_sms_otp_result.data.count_sending_sms_times,
                    check_sending_sms_status: nextProps.generate_sms_otp_result.data.sms_reached_limit === true ? false : true,
                    resend_otp_button_disabled: true,
                });

                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : "",
                    // icon: nextProps.generate_sms_otp_result.msgType,
                    icon: "success",
                    title: t("global.success"),
                    html: nextProps.generate_sms_otp_result.data.sms_reached_limit === true ? t("profile.reached-sms-limit") : t("profile.generate-sms-otp-success"),
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.close();
                    }
                });
            } else {
                if (!isDesktop) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                        icon: "error",
                        title: t("global.sorry"),
                        html: nextProps.generate_sms_otp_result.message[0],
                        customClass: {
                            container: "",
                            icon: "swal-sorry-icon",
                        },
                    }).then((result) => {
                        this.setState({
                            touched: true,
                            errorMsg: nextProps.generate_sms_otp_result.message.input_error || [],
                            formError: true,
                        });
                    });
                } else {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.generate_sms_otp_result.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                }
            }
        }

        if (nextProps.verify_sms_otp_result !== this.props.verify_sms_otp_result) {
            if (nextProps.verify_sms_otp_result.responseCode === 200) {
                if (isDesktop) {
                    Swal.fire({
                        // icon: nextProps.verify_sms_otp_result.msgType,
                        icon: "success",
                        title: t("global.success"),
                        html: t("profile.verify-sms-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();

                            if (this.props.transType === "withdrawal") {
                                this.setState({ forgetSecurityChangeStatus: true });
                            } else {
                                document.getElementById("verificationModal").click();

                                this.props.getMemberProfile();

                                if (this.state.forgetSecurityStatus === false) {
                                    window.location.reload();
                                }

                                this.setState({ forgetSecurityChangeStatus: true });
                            }
                        }
                    });
                } else {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
                        icon: "success",
                        title: t("global.success"),
                        html: "",
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            clearInterval(this.timer);
                            Swal.close();

                            if (this.state.forgetSecurityStatus === false) {
                                this.props.closeVerification("");
                                window.location.reload();
                            }
                            this.setState({ forgetSecurityChangeStatus: true });
                        }
                    });
                }

                // const data = this.props.currentUser;
                // data.contact_no = this.state.currentMobileDialCode + this.state.currentMobileNo;

                // // here is that the goup id problem
                // this.props.getUserData(data);

                // this.props.getTopProfile();
                // this.props.getMemberProfile();

                // clearInterval(this.timer);
            } else {
                if (!isDesktop) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                        icon: "error",
                        title: t("global.sorry"),
                        text: nextProps.verify_sms_otp_result.message,
                        customClass: {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.verify_sms_otp_result.message || [],
                                formError: true,
                            });
                        }
                    });
                } else {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.verify_sms_otp_result.message || [],
                        formError: true,
                    });

                    Swal.close();
                }
            }
        }

        if (nextProps.resend_otp_result !== this.props.resend_otp_result) {
            if (nextProps.resend_otp_result.responseCode === 200) {
                if (nextProps.resend_otp_result.data.sms_reached_limit === true) {
                    this.setState({ check_sending_sms_status: false });
                    Swal.close();
                } else {
                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : "",
                        // icon: nextProps.resend_otp_result.msgType,
                        icon: "success",
                        title: t("global.success"),
                        html: t("profile.resend-otp-success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: !isDesktop
                            ? {
                                container: "swal-mobile-container sucess",
                                icon: "swal-success-icon",
                            }
                            : [],
                    }).then(() => {
                        this.setState({
                            generateOTPStatus: true,
                            openMobileModal: true,
                            check_sending_sms_times: nextProps.resend_otp_result.data.count_sending_sms_times,
                            seconds: 180,
                            timer: 0,
                            resend_otp_button_disabled: true,
                        });
                    });
                }
            } else {
                if (!isDesktop) {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                        icon: "error",
                        title: t("global.sorry"),
                        text: nextProps.resend_otp_result.message.input_error,
                        customClass: {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            this.setState({
                                touched: true,
                                errorMsg: nextProps.resend_otp_result.message.input_error || [],
                                formError: true,
                                resend_otp_button_disabled: true,
                            });
                        }
                    });
                } else {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.resend_otp_result.message.input_error || [],
                        formError: true,
                        resend_otp_button_disabled: true,
                    });
                    Swal.close();
                }
            }
        }

        if (nextProps.remove_security_pin_result !== this.props.remove_security_pin_result) {
            if (nextProps.remove_security_pin_result.responseCode === 200) {
                if (isDesktop) {
                    Swal.fire({
                        // icon: nextProps.update_security_pin_result.msgType,
                        icon: "success",
                        title: t("global.success"),
                        html: nextProps.remove_security_pin_result.message[0],
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();
                            // document.getElementById("securityPinModal").click();
                            window.location.reload();
                        }
                    });
                } else {
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
                        title: t("global.success"),
                        icon: "success",
                        html: nextProps.remove_security_pin_result.message[0],
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        },
                    }).then((result) => {
                        if (result.isConfirmed) {
                            Swal.close();

                            this.props.closeVerification("");

                            window.location.reload();
                        }
                    });
                }
            } else {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
                    icon: "error",
                    title: t("global.sorry"),
                    html: nextProps.remove_security_pin_result.message[0],
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        }
                        : [],
                }).then(() => {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.remove_security_pin_result.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                });
            }
        }

        return true;
    }

    onSetSecurityPinHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;
        if (isDesktop) {
            Swal.fire({
                title: t("global.confirmation"),
                icon: "warning",
                text: t("global.would-you-like-to-proceed") + "?",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const data = {};

                    data.security_pin = this.state.securityPin;

                    this.props.setSecurityPin(data);
                }
            });
        } else {
            if (this.props.bindingMobilePhoneStatus === false) {
                Swal.fire({
                    iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                    title: t("global.sorry"),
                    html: t("profile.security-pin-verify-mobile-no"),
                    icon: "warning",
                    confirmButtonText: t("profile.verify-mobile-number"),
                    customClass: {
                        container: "swal-mobile-container sorry",
                        icon: "swal-sorry-icon",
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.closeVerification("verification-phone");
                    }
                });
            } else {
                Swal.fire({
                    iconHtml: '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">',
                    icon: "warning",
                    title: t("global.confirmation"),
                    text: t("global.would-you-like-to-proceed") + "?",
                    confirmButtonText: t("global.confirm"),
                    showDenyButton: true,
                    denyButtonText: t("global.cancel"),
                    customClass: {
                        container: "swal-mobile-container confirmation",
                        icon: "swal-confirmation-icon",
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        Swal.fire({
                            allowOutsideClick: false,
                            allowEscapeKey: false,
                            allowEnterKey: false,
                            didOpen: () => {
                                Swal.showLoading();
                            },
                        });

                        const data = {};

                        data.security_pin = this.state.securityPin;

                        this.props.setSecurityPin(data);
                    }
                });
            }
        }
    };

    onEditSecurityPinHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.security_pin = this.state.securityPin;

                this.props.verifySecurityPin(data);
            }
        });
    };

    onUpdateSecurityPinHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.security_pin = this.state.securityPin;

                this.props.updateSecurityPin(data);
            }
        });
    };

    onInputChange = (e) => {
        this.setState({
            securityPin: e,
        });
    };

    onForgetSecurityHandler = () => {
        this.setState({
            forgetSecurityStatus: true,
        });
    };

    handlePromoChange = (event) => {
        this.setState({ countryCode: event });
    };

    generateOTPHandler = (event) => {
        event.preventDefault();
        const data = {};

        // if (this.state.resend_contact_no === "") {
        data.contact_number = this.state.currentMobileDialCode + this.state.currentMobileNo;
        // } else {
        // data.contact_number = this.state.currentMobileDialCode + this.state.resend_contact_no;
        // }

        data.contact_prefix = this.state.currentMobileDialCode;

        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        this.props.generateSMSOTP(data);
    };

    verifyOtpHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                // if (this.state.resend_contact_no !== "") {
                // data.contact_no = this.state.currentMobileDialCode + this.state.resend_contact_no;
                // } else {
                data.contact_no = this.state.currentMobileDialCode + this.state.currentMobileNo;
                // }

                data.otp_code = this.state.sms_otp_code;

                this.props.verifySMSOTP(data);
            }
        });
    };

    resendOTPHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;
        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.contact_number = this.state.currentMobileDialCode + this.state.currentMobileNo;

                data.contact_prefix = this.state.currentMobileDialCode;

                this.props.resendOTP(data);

                this.setState({ seconds: 180 });

                let timeLeftVar = this.secondsToTime(this.state.seconds);

                this.setState({ time: timeLeftVar });

                this.startTimer();
            }
        });
    };

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds.toString().padStart(2, "0"),
        };
        return obj;
    }

    startTimer() {
        if (this.state.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0) {
            clearInterval(this.timer);
            this.setState({ resend_otp_button_disabled: false, disabled_resend_contact_no: false });
        }
    };

    onEnterSecPinHandler = (e) => {
        e.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.security_pin = this.state.securityPin;
                if (this.props.transType) {
                    data.trans_type = this.props.transType;
                    data.security_pin_attempts = this.props.enter_pin_attempts === 0 ? 0 : this.props.enter_pin_attempts - 1;
                }

                if (isDesktop) {
                    Array.from(this.ref.current.children).forEach((input) => (input.value = ""));
                    this.ref.current.children[0].focus();
                }

                this.props.verifySecurityPin(data);
            }
        });
    };

    closeRequestOTPHandler = () => {
        this.setState({
            forgetSecurityStatus: false,
            errorMsg: [],
        });
    };

    onVerificationCodeHandler = (e) => {
        this.setState({
            sms_otp_code: e.target.value,
        });
    };

    onSetMobileNoHandler = (e) => {
        this.setState({
            currentMobileNo: e.target.value,
        });
    };

    //open remove security pin layout
    openRemovePin = () => {
        this.setState({
            removeSecurityPin: true
        })
    }
    //close remove security pin 
    closeRemoveSecPin = () => {
        this.setState({
            removeSecurityPin: false
        })
    }

    onRemoveSecurityPinHandler = (event) => {
        event.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            confirmButtonText: t("global.confirm"),
            showDenyButton: true,
            denyButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                const data = {};

                data.security_pin = this.state.securityPin;

                this.props.removeSecurityPin(data);
            }
        });

    }

    toggleSecurityPinModal = () => {
        this.setState({
            openSecurityPinModal: !this.state.openSecurityPinModal
        })
    }

    render() {
        const { t } = this.props;

        switch (this.props.currentUser.data.country_id) {
            case 1:
                contact_no_min_limits = 9;
                contact_no_max_limits = 13;
                break;
            case 3:
                contact_no_min_limits = 8;
                contact_no_max_limits = 8;
                break;
            case 4:
                contact_no_min_limits = 11;
                contact_no_max_limits = 14;
                break;
            case 5:
                contact_no_min_limits = 9;
                contact_no_max_limits = 9;
                break;
            case 7:
                contact_no_min_limits = 10;
                contact_no_max_limits = 10;
                break;
            default:
                break;
        }

        let resendOTPCountDownTimer;

        if (this.state.resend_otp_button_disabled === true) {
            resendOTPCountDownTimer = "0" + this.state.time.m + ":" + this.state.time.s;
        } else {
            resendOTPCountDownTimer = t("top-profile.resend");
        }

        let securityPin;
        if (this.state.verifySecurityPinStatus === false && this.state.forgetSecurityStatus === false) {
            securityPin = this.props.securityPinModal;
            if (this.state.removeSecurityPin === true) {
                securityPin = "remove-sec-pin"
            }
        } else if (this.state.forgetSecurityStatus === true && this.state.forgetSecurityChangeStatus === false) {
            securityPin = "request-otp-sec-pin";
        } else if (this.state.forgetSecurityChangeStatus === true) {
            securityPin = "change-new-sec-pin";
        } else {
            securityPin = "change-new-sec-pin";
        }

        return (
            <>
                {isDesktop ? (
                    <Modal
                        isOpen={this.props.openSecurityPinModal}
                        closeTimeoutMS={500}
                        portalClassName={`global-modal`}
                        className="modal modal-dialog modal-dialog-centered modal-verification"
                        ariaHideApp={false}
                        onRequestClose={this.props.toggleSecurityPinModal}
                    >
                        <div className="modal-content">
                            {securityPin === "set-sec-pin" ? (
                                <>
                                    <div className="modal-header">
                                        <div>
                                            {this.props.bindingMobilePhoneStatus === true && (
                                                <>
                                                    <h5 className="modal-title">{t("profile.add-security-pin")}</h5>
                                                    <small>{t("profile.security-pin-desc")}</small>
                                                </>
                                            )}
                                        </div>
                                        <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleSecurityPinModal} />
                                    </div>
                                    <div className="modal-body">
                                        <form className="forms-col needs-validation" method="post" onSubmit={this.onSetSecurityPinHandler}>
                                            <div className="text-center">
                                                {this.props.bindingMobilePhoneStatus === true && (
                                                    <>
                                                        <label className="col-form-label lh-1 mx-auto">{t("profile.set-your-pin")}</label>
                                                        <ReactPinField
                                                            className="pin-field"
                                                            length="6"
                                                            validate="0123456789"
                                                            type="password"
                                                            value={this.state.securityPin}
                                                            onComplete={this.onInputChange}
                                                        />
                                                    </>
                                                )}
                                                {this.props.bindingMobilePhoneStatus === false && (
                                                    <>
                                                        <div className="d-block lh-1">{t("profile.security-pin-verify-mobile-no")}</div>
                                                    </>
                                                )}
                                            </div>
                                            {this.props.bindingMobilePhoneStatus === false ? (
                                                <button
                                                    className="btn btn-gradient-blue"
                                                    type="button"
                                                    data-bs-dismiss="close"
                                                    data-bs-toggle="modal"
                                                    data-bs-target="#verificationModal"
                                                    onClick={() => this.props.verificationModalHandler("verification-phone")}
                                                >
                                                    {t("profile.verify-mobile-number")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                                    {t("global.submit")}
                                                </button>
                                            )}
                                        </form>
                                    </div>
                                </>
                            ) : securityPin === "enter-sec-pin" ? (
                                <>
                                    <div className="modal-header">
                                        <div>
                                            <h5 className="modal-title">{t("profile.enter-security-pin")}</h5>
                                            <small>{t("profile.security-pin-desc")}</small>
                                        </div>
                                        <Icon
                                            type="button"
                                            className="btn-close"
                                            icon="mdi:close"
                                            // data-bs-dismiss="modal" 
                                            // aria-label="Close" 
                                            onClick={this.props.toggleSecurityPinModal}
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <form className="forms-col needs-validation" method="post" onSubmit={this.onEnterSecPinHandler}>
                                            <div className="text-center">
                                                {this.props.checkSecurityAttemptsLoading === true ? (
                                                    <Skeleton count={1} />
                                                ) : this.props.reenter_security_pin_status === true && (
                                                    <>
                                                        <label className="col-form-label lh-1 mx-auto">{t("profile.enter-your-pin")}</label>
                                                        <ReactPinField className="pin-field" length="6" validate="0123456789" type="password" onComplete={this.onInputChange} ref={this.ref} />
                                                    </>
                                                )}

                                                {this.props.checkSecurityAttemptsLoading === true ? (
                                                    <Skeleton count={1} />
                                                ) : this.props.reenter_security_pin_status === false ? (
                                                    <div className="invalid-feedback d-block">
                                                        {t("global.please-wait")} {"0" + this.props.time.m + ":" + this.props.time.s} {t("profile.reenter-pin")}.
                                                    </div>
                                                ) : (
                                                    this.props.verify_security_pin_status === false && (
                                                        <div className="invalid-feedback d-block">
                                                            {t("profile.incorrect-pin")} ( {this.props.enter_pin_attempts} {t("global.attemp-left")}).
                                                        </div>
                                                    )
                                                )}
                                            </div>

                                            {this.props.checkSecurityAttemptsLoading === true ? (
                                                <Skeleton count={1} />
                                            ) : this.props.reenter_security_pin_status === true && (
                                                <div className="d-grid">
                                                    <button
                                                        className="btn btn-gradient-blue"
                                                        type="submit"
                                                        value="Submit"
                                                        disabled={this.props.reenter_security_pin_status === false ? true : false}
                                                    >
                                                        {t("global.submit")}
                                                    </button>
                                                    <button className="btn btn-underlined" type="button" onClick={this.onForgetSecurityHandler}>
                                                        <small>{t("profile.forget-security-pin")}</small>
                                                    </button>
                                                </div>
                                            )}
                                        </form>
                                    </div>
                                </>
                            ) : securityPin === "request-otp-sec-pin" ? (
                                <>
                                    <div className="modal-header">
                                        <div>
                                            <h5 className="modal-title">{t("profile.request-security-pin-otp")}</h5>
                                            <small>{t("profile.request-security-pin-otp-desc")}</small>
                                        </div>
                                        <Icon
                                            type="button"
                                            className="btn-close"
                                            icon="mdi:close"
                                            // data-bs-dismiss="modal"
                                            // aria-label="Close"
                                            onClick={this.closeRequestOTPHandler}
                                        />
                                    </div>
                                    <div className="modal-body">
                                        {this.state.check_sending_sms_status === true ? (
                                            <form
                                                className="forms-col needs-validation"
                                                name="mobile_verify_from"
                                                id="mobile_verify_from"
                                                method="post"
                                                onSubmit={this.verifyOtpHandler}
                                                noValidate
                                            >
                                                <ValidationError formName="mobile_verify_from" touched={this.state.touched} message={this.state.errorMsg} />
                                                <div className="input-group left has-validation">
                                                    <Select
                                                        className="form-select"
                                                        style={{ width: "30%" }}
                                                        name="selectCountryCode"
                                                        options={countryArry}
                                                        onChange={this.handlePromoChange}
                                                        defaultValue={countryArry[0]}
                                                        value={this.state.currentCountryId}
                                                    />
                                                    <StringInput
                                                        className={`${this.state.errorMsg.contact_no && "was-validated is-invalid"}`}
                                                        style={{ width: "70%" }}
                                                        type="text"
                                                        placeholder="Mobile Phone No."
                                                        name="verificationPhoneNo"
                                                        defaultValue=""
                                                        value={this.state.currentMobileNo}
                                                        onChange={(e) => this.onSetMobileNoHandler(e)}
                                                        minLength={contact_no_min_limits}
                                                        maxLength={contact_no_max_limits}
                                                        minLengthMessage={t("validation.minLength", { 0: contact_no_min_limits })}
                                                        maxLengthMessage={t("validation.maxLength", { 0: contact_no_max_limits })}
                                                        required
                                                        requiredMessage={t("validation.required")}
                                                        errorMsg={this.state.errorMsg.contact_no || ""}
                                                        formError={this.state.formError}
                                                    />
                                                </div>
                                                <div>
                                                    <input
                                                        type="number"
                                                        onChange={(e) => this.onVerificationCodeHandler(e)}
                                                        className="form-control"
                                                        placeholder="Please enter verification code"
                                                    />
                                                    {this.state.generateOTPStatus && this.state.check_sending_sms_status ? (
                                                        <div className="d-flex align-items-center justify-content-between">
                                                            <small>
                                                                {t("top-profile.otp-attempt-msg")} {this.state.check_sending_sms_times} / 5
                                                            </small>
                                                            <Button
                                                                typeButton="button"
                                                                clicked={(event) => this.resendOTPHandler(event)}
                                                                classValue="btn btn-underlined"
                                                                buttonName={resendOTPCountDownTimer}
                                                                disabled={this.state.resend_otp_button_disabled === true ? true : false}
                                                            />
                                                        </div>
                                                    ) : (
                                                        <button
                                                            className="btn btn-underlined"
                                                            type="button"
                                                            onClick={this.generateOTPHandler}
                                                            disabled={this.state.generateOTPStatus === true && this.state.resend_otp_button_disabled === true ? "active" : ""}
                                                        >
                                                            {t("forgot-password.request")} OTP
                                                        </button>
                                                    )}
                                                </div>
                                                <button
                                                    className="btn btn-gradient-blue"
                                                    type="submit"
                                                    value="Submit"
                                                    disabled={this.state.sms_otp_code === "" ? "active" : ""}
                                                >
                                                    {t("global.submit")}
                                                </button>
                                            </form>
                                        ) : (
                                            <div className="verification-error">
                                                <p>
                                                    {t("top-profile.otp-failed-word")} {t("top-profile.otp-failed-msg")}
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                </>
                            ) : securityPin === "change-new-sec-pin" ? (
                                <>
                                    <div className="modal-header">
                                        <div>
                                            <h5 className="modal-title">{t("profile.enter-new-security-pin")}</h5>
                                            <small>{t("profile.security-pin-desc")}</small>
                                        </div>
                                        <Icon
                                            type="button"
                                            className="btn-close"
                                            icon="mdi:close"
                                            // data-bs-dismiss="modal"
                                            // aria-label="Close" 
                                            onClick={this.props.toggleSecurityPinModal}
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <form className="forms-col needs-validation" method="post" onSubmit={this.onUpdateSecurityPinHandler}>
                                            <div className="text-center">
                                                <label className="col-form-label lh-1 mx-auto">{t("profile.set-your-pin")}</label>
                                                {/* After "Set your PIN" , next is "Confirm your PIN", same UI  */}
                                                <ReactPinField
                                                    className="pin-field"
                                                    length="6"
                                                    validate="0123456789"
                                                    type="password"
                                                    onComplete={this.onInputChange}
                                                    value={this.state.securityPin}
                                                    ref={this.ref}
                                                />
                                            </div>
                                            <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                                {t("global.submit")}
                                            </button>
                                        </form>
                                    </div>
                                </>
                            ) : securityPin === "edit-sec-pin" ? (
                                <>
                                    <div className="modal-header">
                                        <div>
                                            <h5 className="modal-title">{t("profile.enter-current-security-pin")}</h5>
                                            <small>{t("profile.security-pin-desc")}</small>
                                        </div>
                                        <Icon
                                            type="button"
                                            className="btn-close"
                                            icon="mdi:close"
                                            // data-bs-dismiss="modal" 
                                            // aria-label="Close" 
                                            onClick={this.props.toggleSecurityPinModal}
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <form className="forms-col needs-validation" method="post" onSubmit={this.onEditSecurityPinHandler}>
                                            <div className="text-center">
                                                <label className="col-form-label lh-1 mx-auto">{t("profile.enter-your-pin")}</label>
                                                {/* After "Set your PIN" , next is "Confirm your PIN", same UI  */}
                                                <ReactPinField
                                                    className="pin-field"
                                                    length="6"
                                                    validate="0123456789"
                                                    type="password"
                                                    value={this.state.securityPin}
                                                    onComplete={this.onInputChange}
                                                    ref={this.ref}
                                                />
                                            </div>
                                            <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                                {t("global.submit")}
                                            </button>

                                            <button className="btn btn-underlined" type="button" onClick={this.onForgetSecurityHandler}>
                                                <small>{t("profile.forget-security-pin")}</small>
                                            </button>
                                        </form>
                                    </div>
                                </>
                            ) : securityPin === "remove-sec-pin" ? (
                                <>
                                    <div className="modal-header">
                                        <div>
                                            <h5 className="modal-title">{t("profile.remove-security-pin")}</h5>
                                            <small>{t("profile.enter-current-security-pin")}</small>
                                        </div>
                                        <Icon
                                            type="button"
                                            className="btn-close"
                                            icon="mdi:close"
                                            // data-bs-dismiss="modal"
                                            // aria-label="Close"
                                            onClick={this.props.toggleSecurityPinModal}
                                        />
                                    </div>
                                    <div className="modal-body">
                                        <form className="forms-col needs-validation" method="post" onSubmit={this.onRemoveSecurityPinHandler}>
                                            <div className="text-center">
                                                <label className="col-form-label lh-1 mx-auto">{t("profile.enter-your-pin")}</label>
                                                {/* After "Set your PIN" , next is "Confirm your PIN", same UI  */}
                                                <ReactPinField
                                                    className="pin-field"
                                                    length="6"
                                                    validate="0123456789"
                                                    type="password"
                                                    value={this.state.securityPin}
                                                    onComplete={this.onInputChange}
                                                    ref={this.ref}
                                                />
                                            </div>
                                            <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                                {t("global.submit")}
                                            </button>

                                            <button className="btn btn-underlined" type="button" onClick={this.onForgetSecurityHandler}>
                                                <small>{t("profile.forget-security-pin")}</small>
                                            </button>
                                        </form>
                                    </div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </Modal>
                    // <div className="modal global-modal fade" id="securityPinModal" tabIndex="-1" aria-labelledby="securityPinModalLabel" aria-hidden="true">
                    //     <div className="modal-dialog modal-dialog-centered modal-verification">
                    //         <div className="modal-content">
                    //             {securityPin === "set-sec-pin" ? (
                    //                 <>
                    //                     <div className="modal-header">
                    //                         <div>
                    //                             {this.props.bindingMobilePhoneStatus === true && (
                    //                                 <>
                    //                                     <h5 className="modal-title">{t("profile.add-security-pin")}</h5>
                    //                                     <small>{t("profile.security-pin-desc")}</small>
                    //                                 </>
                    //                             )}
                    //                         </div>
                    //                         <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
                    //                     </div>
                    //                     <div className="modal-body">
                    //                         <form className="forms-col needs-validation" method="post" onSubmit={this.onSetSecurityPinHandler}>
                    //                             <div className="text-center">
                    //                                 {this.props.bindingMobilePhoneStatus === true && (
                    //                                     <>
                    //                                         <label className="col-form-label lh-1 mx-auto">{t("profile.set-your-pin")}</label>
                    //                                         <ReactPinField
                    //                                             className="pin-field"
                    //                                             length="6"
                    //                                             validate="0123456789"
                    //                                             type="password"
                    //                                             value={this.state.securityPin}
                    //                                             onComplete={this.onInputChange}
                    //                                         />
                    //                                     </>
                    //                                 )}
                    //                                 {this.props.bindingMobilePhoneStatus === false && (
                    //                                     <>
                    //                                         <div className="d-block lh-1">{t("profile.security-pin-verify-mobile-no")}</div>
                    //                                     </>
                    //                                 )}
                    //                             </div>
                    //                             {this.props.bindingMobilePhoneStatus === false ? (
                    //                                 <button
                    //                                     className="btn btn-gradient-blue"
                    //                                     type="button"
                    //                                     data-bs-dismiss="close"
                    //                                     data-bs-toggle="modal"
                    //                                     data-bs-target="#verificationModal"
                    //                                     onClick={() => this.props.verificationModalHandler("verification-phone")}
                    //                                 >
                    //                                     {t("profile.verify-mobile-number")}
                    //                                 </button>
                    //                             ) : (
                    //                                 <button className="btn btn-gradient-blue" type="submit" value="Submit">
                    //                                     {t("global.submit")}
                    //                                 </button>
                    //                             )}
                    //                         </form>
                    //                     </div>
                    //                 </>
                    //             ) : securityPin === "enter-sec-pin" ? (
                    //                 <>
                    //                     <div className="modal-header">
                    //                         <div>
                    //                             <h5 className="modal-title">{t("profile.enter-security-pin")}</h5>
                    //                             <small>{t("profile.security-pin-desc")}</small>
                    //                         </div>
                    //                         <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
                    //                     </div>
                    //                     <div className="modal-body">
                    //                         <form className="forms-col needs-validation" method="post" onSubmit={this.onEnterSecPinHandler}>
                    //                             <div className="text-center">
                    //                                 {this.props.checkSecurityAttemptsLoading === true ? (
                    //                                     <Skeleton count={1} />
                    //                                 ) : this.props.reenter_security_pin_status === true && (
                    //                                     <>
                    //                                         <label className="col-form-label lh-1 mx-auto">{t("profile.enter-your-pin")}</label>
                    //                                         <ReactPinField className="pin-field" length="6" validate="0123456789" type="password" onComplete={this.onInputChange} ref={this.ref} />
                    //                                     </>
                    //                                 )}

                    //                                 {this.props.checkSecurityAttemptsLoading === true ? (
                    //                                     <Skeleton count={1} />
                    //                                 ) : this.props.reenter_security_pin_status === false ? (
                    //                                     <div className="invalid-feedback d-block">
                    //                                         {t("global.please-wait")} {"0" + this.props.time.m + ":" + this.props.time.s} {t("profile.reenter-pin")}.
                    //                                     </div>
                    //                                 ) : (
                    //                                     this.props.verify_security_pin_status === false && (
                    //                                         <div className="invalid-feedback d-block">
                    //                                             {t("profile.incorrect-pin")} ( {this.props.enter_pin_attempts} {t("global.attemp-left")}).
                    //                                         </div>
                    //                                     )
                    //                                 )}
                    //                             </div>

                    //                             {this.props.checkSecurityAttemptsLoading === true ? (
                    //                                 <Skeleton count={1} />
                    //                             ) : this.props.reenter_security_pin_status === true && (
                    //                                 <div className="d-grid">
                    //                                     <button
                    //                                         className="btn btn-gradient-blue"
                    //                                         type="submit"
                    //                                         value="Submit"
                    //                                         disabled={this.props.reenter_security_pin_status === false ? true : false}
                    //                                     >
                    //                                         {t("global.submit")}
                    //                                     </button>
                    //                                     <button className="btn btn-underlined" type="button" onClick={this.onForgetSecurityHandler}>
                    //                                         <small>{t("profile.forget-security-pin")}</small>
                    //                                     </button>
                    //                                 </div>
                    //                             )}
                    //                         </form>
                    //                     </div>
                    //                 </>
                    //             ) : securityPin === "request-otp-sec-pin" ? (
                    //                 <>
                    //                     <div className="modal-header">
                    //                         <div>
                    //                             <h5 className="modal-title">{t("profile.request-security-pin-otp")}</h5>
                    //                             <small>{t("profile.request-security-pin-otp-desc")}</small>
                    //                         </div>
                    //                         <Icon
                    //                             type="button"
                    //                             className="btn-close"
                    //                             icon="mdi:close"
                    //                             data-bs-dismiss="modal"
                    //                             aria-label="Close"
                    //                             onClick={this.closeRequestOTPHandler}
                    //                         />
                    //                     </div>
                    //                     <div className="modal-body">
                    //                         {this.state.check_sending_sms_status === true ? (
                    //                             <form
                    //                                 className="forms-col needs-validation"
                    //                                 name="mobile_verify_from"
                    //                                 id="mobile_verify_from"
                    //                                 method="post"
                    //                                 onSubmit={this.verifyOtpHandler}
                    //                                 noValidate
                    //                             >
                    //                                 <ValidationError formName="mobile_verify_from" touched={this.state.touched} message={this.state.errorMsg} />
                    //                                 <div className="input-group left has-validation">
                    //                                     <Select
                    //                                         className="form-select"
                    //                                         style={{ width: "30%" }}
                    //                                         name="selectCountryCode"
                    //                                         options={countryArry}
                    //                                         onChange={this.handlePromoChange}
                    //                                         defaultValue={countryArry[0]}
                    //                                         value={this.state.currentCountryId}
                    //                                     />
                    //                                     <StringInput
                    //                                         className={`${this.state.errorMsg.contact_no && "was-validated is-invalid"}`}
                    //                                         style={{ width: "70%" }}
                    //                                         type="text"
                    //                                         placeholder="Mobile Phone No."
                    //                                         name="verificationPhoneNo"
                    //                                         defaultValue=""
                    //                                         value={this.state.currentMobileNo}
                    //                                         onChange={(e) => this.onSetMobileNoHandler(e)}
                    //                                         minLength={contact_no_min_limits}
                    //                                         maxLength={contact_no_max_limits}
                    //                                         minLengthMessage={t("validation.minLength", { 0: contact_no_min_limits })}
                    //                                         maxLengthMessage={t("validation.maxLength", { 0: contact_no_max_limits })}
                    //                                         required
                    //                                         requiredMessage={t("validation.required")}
                    //                                         errorMsg={this.state.errorMsg.contact_no || ""}
                    //                                         formError={this.state.formError}
                    //                                     />
                    //                                 </div>
                    //                                 <div>
                    //                                     <input
                    //                                         type="number"
                    //                                         onChange={(e) => this.onVerificationCodeHandler(e)}
                    //                                         className="form-control"
                    //                                         placeholder="Please enter verification code"
                    //                                     />
                    //                                     {this.state.generateOTPStatus && this.state.check_sending_sms_status ? (
                    //                                         <div className="d-flex align-items-center justify-content-between">
                    //                                             <small>
                    //                                                 {t("top-profile.otp-attempt-msg")} {this.state.check_sending_sms_times} / 5
                    //                                             </small>
                    //                                             <Button
                    //                                                 typeButton="button"
                    //                                                 clicked={(event) => this.resendOTPHandler(event)}
                    //                                                 classValue="btn btn-underlined"
                    //                                                 buttonName={resendOTPCountDownTimer}
                    //                                                 disabled={this.state.resend_otp_button_disabled === true ? true : false}
                    //                                             />
                    //                                         </div>
                    //                                     ) : (
                    //                                         <button
                    //                                             className="btn btn-underlined"
                    //                                             type="button"
                    //                                             onClick={this.generateOTPHandler}
                    //                                             disabled={this.state.generateOTPStatus === true && this.state.resend_otp_button_disabled === true ? "active" : ""}
                    //                                         >
                    //                                             {t("forgot-password.request")} OTP
                    //                                         </button>
                    //                                     )}
                    //                                 </div>
                    //                                 <button
                    //                                     className="btn btn-gradient-blue"
                    //                                     type="submit"
                    //                                     value="Submit"
                    //                                     disabled={this.state.sms_otp_code === "" ? "active" : ""}
                    //                                 >
                    //                                     {t("global.submit")}
                    //                                 </button>
                    //                             </form>
                    //                         ) : (
                    //                             <div className="verification-error">
                    //                                 <p>
                    //                                     {t("top-profile.otp-failed-word")} {t("top-profile.otp-failed-msg")}
                    //                                 </p>
                    //                             </div>
                    //                         )}
                    //                     </div>
                    //                 </>
                    //             ) : securityPin === "change-new-sec-pin" ? (
                    //                 <>
                    //                     <div className="modal-header">
                    //                         <div>
                    //                             <h5 className="modal-title">{t("profile.enter-new-security-pin")}</h5>
                    //                             <small>{t("profile.security-pin-desc")}</small>
                    //                         </div>
                    //                         <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
                    //                     </div>
                    //                     <div className="modal-body">
                    //                         <form className="forms-col needs-validation" method="post" onSubmit={this.onUpdateSecurityPinHandler}>
                    //                             <div className="text-center">
                    //                                 <label className="col-form-label lh-1 mx-auto">{t("profile.set-your-pin")}</label>
                    //                                 {/* After "Set your PIN" , next is "Confirm your PIN", same UI  */}
                    //                                 <ReactPinField
                    //                                     className="pin-field"
                    //                                     length="6"
                    //                                     validate="0123456789"
                    //                                     type="password"
                    //                                     onComplete={this.onInputChange}
                    //                                     value={this.state.securityPin}
                    //                                     ref={this.ref}
                    //                                 />
                    //                             </div>
                    //                             <button className="btn btn-gradient-blue" type="submit" value="Submit">
                    //                                 {t("global.submit")}
                    //                             </button>
                    //                         </form>
                    //                     </div>
                    //                 </>
                    //             ) : securityPin === "edit-sec-pin" ? (
                    //                 <>
                    //                     <div className="modal-header">
                    //                         <div>
                    //                             <h5 className="modal-title">{t("profile.enter-current-security-pin")}</h5>
                    //                             <small>{t("profile.security-pin-desc")}</small>
                    //                         </div>
                    //                         <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
                    //                     </div>
                    //                     <div className="modal-body">
                    //                         <form className="forms-col needs-validation" method="post" onSubmit={this.onEditSecurityPinHandler}>
                    //                             <div className="text-center">
                    //                                 <label className="col-form-label lh-1 mx-auto">{t("profile.enter-your-pin")}</label>
                    //                                 {/* After "Set your PIN" , next is "Confirm your PIN", same UI  */}
                    //                                 <ReactPinField
                    //                                     className="pin-field"
                    //                                     length="6"
                    //                                     validate="0123456789"
                    //                                     type="password"
                    //                                     value={this.state.securityPin}
                    //                                     onComplete={this.onInputChange}
                    //                                     ref={this.ref}
                    //                                 />
                    //                             </div>
                    //                             <button className="btn btn-gradient-blue" type="submit" value="Submit">
                    //                                 {t("global.submit")}
                    //                             </button>

                    //                             <button className="btn btn-underlined" type="button" onClick={this.onForgetSecurityHandler}>
                    //                                 <small>{t("profile.forget-security-pin")}</small>
                    //                             </button>
                    //                         </form>
                    //                     </div>
                    //                 </>
                    //             ) : securityPin === "remove-sec-pin" ? (
                    //                 <>
                    //                     <div className="modal-header">
                    //                         <div>
                    //                             <h5 className="modal-title">{t("profile.remove-security-pin")}</h5>
                    //                             <small>{t("profile.enter-current-security-pin")}</small>
                    //                         </div>
                    //                         <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
                    //                     </div>
                    //                     <div className="modal-body">
                    //                         <form className="forms-col needs-validation" method="post" onSubmit={this.onRemoveSecurityPinHandler}>
                    //                             <div className="text-center">
                    //                                 <label className="col-form-label lh-1 mx-auto">{t("profile.enter-your-pin")}</label>
                    //                                 {/* After "Set your PIN" , next is "Confirm your PIN", same UI  */}
                    //                                 <ReactPinField
                    //                                     className="pin-field"
                    //                                     length="6"
                    //                                     validate="0123456789"
                    //                                     type="password"
                    //                                     value={this.state.securityPin}
                    //                                     onComplete={this.onInputChange}
                    //                                     ref={this.ref}
                    //                                 />
                    //                             </div>
                    //                             <button className="btn btn-gradient-blue" type="submit" value="Submit">
                    //                                 {t("global.submit")}
                    //                             </button>

                    //                             <button className="btn btn-underlined" type="button" onClick={this.onForgetSecurityHandler}>
                    //                                 <small>{t("profile.forget-security-pin")}</small>
                    //                             </button>
                    //                         </form>
                    //                     </div>
                    //                 </>
                    //             ) : (
                    //                 <></>
                    //             )}
                    //         </div>
                    //     </div>
                    // </div>
                ) : (
                    <MobileSecurityPin
                        {...this.state}
                        {...this.props}
                        onRef={this.ref}
                        mobileCountryArray={mobileCountryArray}
                        securityPin={securityPin}
                        onSetSecurityPinHandler={this.onSetSecurityPinHandler}
                        onForgetSecurityHandler={this.onForgetSecurityHandler}
                        onEnterSecPinHandler={this.onEnterSecPinHandler}
                        onUpdateSecurityPinHandler={this.onUpdateSecurityPinHandler}
                        onEditSecurityPinHandler={this.onEditSecurityPinHandler}
                        onInputChange={this.onInputChange}
                        generateOTPHandler={this.generateOTPHandler}
                        verifyOtpHandler={this.verifyOtpHandler}
                        otpTimer={resendOTPCountDownTimer}
                        onSetMobileNoHandler={this.onSetMobileNoHandler}
                        onVerificationCodeHandler={this.onVerificationCodeHandler}
                        onRemoveSecurityPinHandler={this.onRemoveSecurityPinHandler}

                        openRemovePin={this.openRemovePin}
                        closeRemoveSecPin={this.closeRemoveSecPin}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state;
    return {
        set_security_pin_result: profile.set_security_pin_result,
        verify_security_pin_result: profile.verify_security_pin_result,
        update_security_pin_result: profile.update_security_pin_result,
        generate_sms_otp_result: profile.generate_sms_otp_result,
        verify_sms_otp_result: profile.verify_sms_otp_result,
        countryData: state.home.country_data,
        resend_otp_result: state.profile.resend_otp_result,
        remove_security_pin_result: state.profile.remove_security_pin_result,
        currentUser: state.currentUser,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        setSecurityPin: (data) => dispatch(setSecurityPin(data)),
        verifySecurityPin: (data) => dispatch(verifySecurityPin(data)),
        updateSecurityPin: (data) => dispatch(updateSecurityPin(data)),
        generateSMSOTP: (data) => dispatch(generateSMSOTP(data)),
        verifySMSOTP: (data) => dispatch(verifySMSOTP(data)),
        resendOTP: (data) => dispatch(resendOTP(data)),
        getCountry: () => dispatch(getCountry()),
        getUserData: (data) => dispatch(getUserData(data)),
        getMemberProfile: () => dispatch(getMemberProfile()),
        removeSecurityPin: (data) => dispatch(removeSecurityPin(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(SecurityPinModal));
