import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";

import { isDesktop } from 'react-device-detect';
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";

class ResponsibleGaming extends Component {
  render() {
    const { t } = this.props;
    return (
      <Auxiliary>
        {isDesktop ? (
          <section className="responsible-gaming-section ">
            <div className="x-container">
              <div className="row">
                <div className="section-header mt-5">
                  <h2>{t("responsible-gaming.responsible-gaming")}</h2>
                </div>
                <div className="section-content">
                  <h3>{t("responsible-gaming.problem-gambling-checklist")}</h3>
                  <p>
                    {t("responsible-gaming.rg-details")}
                  </p>
                  <br />
                  <p>
                    {t("responsible-gaming.rg-title-1")}
                  </p>
                  <br />
                  <ul>
                    <li>{t("responsible-gaming.rg1-li-1")}</li>
                    <li>{t("responsible-gaming.rg1-li-2")}</li>
                    <li>{t("responsible-gaming.rg1-li-3")}</li>
                    <li>{t("responsible-gaming.rg1-li-4")}</li>
                    <li>{t("responsible-gaming.rg1-li-5")}</li>
                    <li>{t("responsible-gaming.rg1-li-6")}</li>
                    <li>{t("responsible-gaming.rg1-li-7")}</li>
                    <li>{t("responsible-gaming.rg1-li-8")}</li>
                    <li>{t("responsible-gaming.rg1-li-9")}</li>                                                             
                  </ul>
                  
                  <p>
                    {t("responsible-gaming.rg-title-2")}
                  </p>
                  <br />
                  <ul>
                    <li> {t("responsible-gaming.rg2-li-1")}</li>
                    <li> {t("responsible-gaming.rg2-li-2")}</li>
                    <li> {t("responsible-gaming.rg2-li-3")}</li>
                    <li> {t("responsible-gaming.rg2-li-4")}</li>
                    <li> {t("responsible-gaming.rg2-li-5")}</li>
                  </ul>
                  <br />
                  <p>
                    {t("responsible-gaming.rg-title-3")}
                  </p>

                  <h3>{t("responsible-gaming.rg-title-4")}</h3>
                  <p>
                    {t("responsible-gaming.rg-desc-4")}
                  </p>

                  <h3>{t("responsible-gaming.rg-title-5")}</h3>
                  <p>
                    {t("responsible-gaming.rg-desc-5-1")}
                  </p>
                  <br />
                  <p>
                    {t("responsible-gaming.rg-desc-5-2")}
                  </p>

                  <h3>{t("responsible-gaming.rg-title-6")}</h3>
                  <p>
                    {t("responsible-gaming.rg-desc-6")}
                  </p>
                  <br />
                  <p>
                    {t("responsible-gaming.rg6-li-1")} <a href="https://www.cybersitter.com/" target="_blank">www.cybersitter.com</a>
                  </p>
                  <p>
                    {t("responsible-gaming.rg6-li-2")} <a href="https://www.netnanny.com/" target="_blank">www.netnanny.com</a>
                  </p>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <section className="responsible-gaming-section">
            <MobileSubHeader
              // className="bg-white"
              returnPage="/"
              header={t("responsible-gaming.responsible-gaming")}
              style={{ justifyContent: "unset" }}
            />
            <div>
              <div className="section-content mobile">
                <h3 className="p-0">{t("responsible-gaming.problem-gambling-checklist")}</h3>
                <p>
                  {t("responsible-gaming.rg-details")}
                </p>
                <br />
                <p>
                  {t("responsible-gaming.rg-title-1")}
                </p>
                <br />
                <ul>
                  <li>{t("responsible-gaming.rg1-li-1")}</li>
                  <li>{t("responsible-gaming.rg1-li-2")}</li>
                  <li>{t("responsible-gaming.rg1-li-3")}</li>
                  <li>{t("responsible-gaming.rg1-li-4")}</li>
                  <li>{t("responsible-gaming.rg1-li-5")}</li>
                  <li>{t("responsible-gaming.rg1-li-6")}</li>
                  <li>{t("responsible-gaming.rg1-li-7")}</li>
                  <li>{t("responsible-gaming.rg1-li-8")}</li>
                  <li>{t("responsible-gaming.rg1-li-9")}</li>                                                             
                </ul>
                
                <p>
                  {t("responsible-gaming.rg-title-2")}
                </p>
                <br />
                <ul>
                  <li> {t("responsible-gaming.rg2-li-1")}</li>
                  <li> {t("responsible-gaming.rg2-li-2")}</li>
                  <li> {t("responsible-gaming.rg2-li-3")}</li>
                  <li> {t("responsible-gaming.rg2-li-4")}</li>
                  <li> {t("responsible-gaming.rg2-li-5")}</li>
                </ul>
                <br />
                <p>
                  {t("responsible-gaming.rg-title-3")}
                </p>

                <h3>{t("responsible-gaming.rg-title-4")}</h3>
                <p>
                  {t("responsible-gaming.rg-desc-4")}
                </p>

                <h3>{t("responsible-gaming.rg-title-5")}</h3>
                <p>
                  {t("responsible-gaming.rg-desc-5-1")}
                </p>
                <br />
                <p>
                  {t("responsible-gaming.rg-desc-5-2")}
                </p>

                <h3>{t("responsible-gaming.rg-title-6")}</h3>
                <p>
                  {t("responsible-gaming.rg-desc-6")}
                </p>
                <br />
                <p>
                  {t("responsible-gaming.rg6-li-1")} <a href="https://www.cybersitter.com/" target="_blank">www.cybersitter.com</a>
                </p>
                <p>
                  {t("responsible-gaming.rg6-li-2")} <a href="https://www.netnanny.com/" target="_blank">www.netnanny.com</a>
                </p>
              </div>
            </div>
          </section>
        )}

      </Auxiliary>
    );
  }
}

export default (withNamespaces("translation")(ResponsibleGaming));
