import React, { Component, createRef } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Slider from "react-slick";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Icon } from "@iconify/react";
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";
import { withNamespaces } from "react-i18next";
import moment from "moment";
export class MobileVip extends Component {
    constructor(props) {
        super(props);
        this.state = {
            oldSlide: 0,
            activeSlide: 0,
            activeSlide2: 0,
            vip: [],
            set_birthday_date: false,
        };
        this.topSection = createRef();
    }

    scrollToTop = () => {
        this.topSection.current.scrollIntoView({
            behavior: "smooth",
            block: "start",
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const vip = this.imageImport(require.context("../../assets/images/vip", false, /\.(gif|png|jpe?g|svg|webp|webm)$/));

        const settings = {
            slidesToShow: 1,
            adaptiveHeight: false,
            focusOnSelect: true,
            dots: true,
            arrows: false,
            customPaging: function (i) {
                var i = i + 1;
                return <span>
                    <img src={vip[`vip${i + 1}.webp`]} alt="logo" />
                    <p>{t(`vip.vip-${i + 1}`)}</p>
                </span>
                    ;
            },
            beforeChange: (current, next) => this.setState({ oldSlide: current, activeSlide: next }),
            afterChange: (current) => this.setState({ activeSlide2: current }),
        };

        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        let vipInfo = this.props.customizedVip
            ? this.props.customizedVip.map((vipval, idx) => {
                return {
                    id: vipval.level,
                    name: vipval.level,
                    img: "vip-" + (vipval.level - 1),
                    deposit: new Intl.NumberFormat("en-US", num_format).format(vipval.minDepositTierUpgrade),
                    turnover: new Intl.NumberFormat("en-US", num_format).format(vipval.minTurnoverTierUpgrade),
                    relegation: new Intl.NumberFormat("en-US", num_format).format(vipval.depositTierMaintain),
                    liveCasinoRebate: vipval.live_casino_rebate,
                    slotRebate: vipval.slotRebate,
                    sportRebate: vipval.sportRebate,
                    virtualRebate: vipval.virtualRebate,
                    bonusMoney: new Intl.NumberFormat("en-US", num_format).format(vipval.upLevelBonus),
                    weeklyBonus: new Intl.NumberFormat("en-US", num_format).format(vipval.vipWeeklybonus),
                    birthdayBonus: new Intl.NumberFormat("en-US", num_format).format(vipval.birthdayBonus),
                    dailyWithdrawalLimit: new Intl.NumberFormat("en-US", num_format).format(vipval.maxDailyWithdrawal),
                    turnoverWeeklyTarget: new Intl.NumberFormat("en-US", num_format).format(vipval.turnoverWeeklyTarget)
                };
            })
            : [];
        return (
            <Auxiliary>
                <section className="vip-m-section" ref={this.topSection}>
                    <MobileSubHeader
                        returnPage={this.props.prevPage ? (this.props.prevPage === "/me" ? "/vip-exclusive" : "/") : "/"}
                        header={t("vip.vip-details-mobile")}
                        style={{ justifyContent: "unset" }}
                    />
                    <div className="banner-col">
                        <div className="banner-item">
                            <img src={banner[`vip-banner-mobile.webp`]} alt="R77 Vip" />
                        </div>
                    </div>
                    <div className="vip-m-slick">
                        <div className="slick-container">
                            <Slider ref={(c) => (this.slider = c)} {...settings}>
                                {vipInfo &&
                                    vipInfo.map((item, index) => (
                                        <div className="vip-card-container" key={index}>
                                            <span className="card-shadow">
                                                <img
                                                    src={
                                                        vip[
                                                        `${item.id === 10
                                                            ? "vip10-bg.webp"
                                                            : item.id === 11
                                                                ? "vip11-bg.webp"
                                                                : item.id === 12
                                                                    ? "vip12-bg.webp"
                                                                    : "vip-bg.webp"
                                                        }`
                                                        ]
                                                    }
                                                    alt="vip-img"
                                                />
                                            </span>
                                            <div className="vip-card-mobile">
                                                <div className="vip-level">
                                                    <LazyLoadImage
                                                        className="vip-m-logo"
                                                        src={vip[`${item.img}${this.state.activeSlide2 === index ? ".webp" : ".webp"}`]}
                                                        alt="vip-logo"
                                                        effect="blur"
                                                    />

                                                    <p>{t(`vip.vip-${item.name}`)}</p>

                                                </div>
                                                <div className="vip-info">
                                                    <div className="d-flex mb-2">
                                                        <img className="info-icon" src={vip["t-deposit.webp"]} alt="vip-deposit" />
                                                        <div className="vip-content">
                                                            <p>
                                                                {t("vip.total-deposit")}
                                                                <span>&nbsp; ({this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]})</span>
                                                            </p>
                                                            <p className="amount">{item.deposit}</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex mb-2">
                                                        <img src={vip["t-turnover.webp"]} alt="vip-turnover" />
                                                        <div className="vip-content">
                                                            <p>
                                                                {t("vip.total-turnover")}
                                                                <span>&nbsp; ({this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]})</span>
                                                            </p>
                                                            <p className="amount">{item.turnover}</p>
                                                        </div>
                                                    </div>

                                                    <div className="d-flex">
                                                        <img src={vip["relegation.webp"]} alt="vip-relagation" />
                                                        <div className="vip-content">
                                                            <p>{t("vip.relegation-turnover")}</p>
                                                            {item.relegation <= 0 ? (
                                                                <p className="amount">
                                                                    {item.relegation} <span>{t("vip.lifetime-day")}</span>
                                                                </p>
                                                            ) : (
                                                                <p className="amount">
                                                                    {item.relegation} <span>{t("vip.relegation-day")}</span>
                                                                </p>
                                                            )
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </Slider>
                        </div>
                    </div>
                    <div className="vip-m-table">
                        {vipInfo && vipInfo.length > 0 && 
                            <>
                                <h3>{t(`vip.vip-${vipInfo[this.state.activeSlide].name}`)} {t("vip.vip-levels-privileges")}</h3>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>{t('vip.valid-rollover-req')}</td>
                                            <td>{vipInfo[this.state.activeSlide].turnover}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.tier-validity')}</td>
                                            <td>{vipInfo[this.state.activeSlide].relegation <= 0 ? t("vip.lifetime") : '90 ' + t("datepicker.day")}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={6}>{t('vip.red-packet-tnc')}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.required-deposit')} ({this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]})</td>
                                            <td>{vipInfo[this.state.activeSlide].deposit}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.weekly-red-packet')}</td>
                                            <td>{vipInfo[this.state.activeSlide].weeklyBonus}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.tailored-cs-agent')}</td>
                                            <td>{vipInfo[this.state.activeSlide].id <= 4 ? '-' : <img src={vip["correct-ico.webp"]} alt="checked-icon"/>}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.deposit-withdrawal-priority')}</td>
                                            <td>{vipInfo[this.state.activeSlide].id <= 3 ? '-' : <img src={vip["correct-ico.webp"]} alt="checked-icon"/>}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.withdrawal-limit')}</td>
                                            <td>{vipInfo[this.state.activeSlide].dailyWithdrawalLimit}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.promotion-bonus')}</td>
                                            <td>{vipInfo[this.state.activeSlide].bonusMoney}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.birthday-bonus-small')}</td>
                                            <td>{vipInfo[this.state.activeSlide].birthdayBonus}</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.live-casino-rebate')}</td>
                                            <td>{vipInfo[this.state.activeSlide].liveCasinoRebate}%</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.sport-rebate')}</td>
                                            <td>{vipInfo[this.state.activeSlide].sportRebate}%</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.slot-rebate')}</td>
                                            <td>{vipInfo[this.state.activeSlide].slotRebate}%</td>
                                        </tr>
                                        <tr>
                                            <td>{t('vip.tailored-program')}</td>
                                            <td>{vipInfo[this.state.activeSlide].id <= 5 ? '-' : <img src={vip["correct-ico.webp"]} alt="checked-icon"/>}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </>
                        }
                    </div>

                    <div className="vip-m-bullet-content">
                        <h4 className="pt-3">{t("vip.term-condition")}</h4>
                        <ol>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.tc-upgrade-bonus")} </span>
                                    <span>{t("vip.tc-upgrade-bonus-content")}</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span>{t("vip.tc-content-2")}</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span className="tnc-title">{t("vip.tc-birthday-bonus")} </span>
                                    <span>{t("vip.tc-birthday-bonus-content")}</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span>{t("vip.tc-content-4")}</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span>{t("vip.tc-content-5")}</span>
                                </div>
                            </li>
                            <li>
                                <div className="tnc-list ">
                                    <span>{t("vip.tc-content-6")}</span>
                                </div>
                            </li>
                            
                            <li>
                                <div className="tnc-list ">
                                    <span>{t("vip.tc-content-7")}</span>
                                </div>
                            </li>
                        </ol>

                        <div className="btt-btn">
                            <div className="btn btn-white" onClick={this.scrollToTop}>
                                <Icon icon="ph:caret-double-up-bold" />
                                {t("vip.back-to-top")}
                            </div>
                        </div>
                    </div>
                </section>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(MobileVip);
