import React, { Component, createRef } from 'react'
// import Auxiliary from '../../../hoc/Auxiliary/Auxiliary';
import { withNamespaces } from "react-i18next";
import { Fade } from "react-awesome-reveal";
import { Icon } from "@iconify/react";
import SideBar from "../../../components/SideBar/SideBar";
export class MobileBetAndEarn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeProvider: 1
    };
    this.topSection = createRef();
  }

  toggleActiveProdiver = (id) => {
    this.setState({
      activeProvider: id
    })
  }

  scrollToTop = () => {
    this.topSection.current.scrollIntoView({ behavior: "smooth", block: "start" });
  }

  BettingType = (props) => {
    const { t } = this.props;
    return (
      <Fade duration={1000}>
        <div className='bne-total-amount card-shadow'>
          <div className='total-amount-header'>
            <img src={props.icon} alt="" />
            <h3>{props.title}</h3>
          </div>
          <div className='total-amount-content p-3'>
            <div className='border-bottom pb-2'>
              <p className='text-center'>{t('bet_and_earn.this_week_jackpot')}</p>
              <p className='text-end'>{this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]}
                {
                  props.summary && props.summary.map((item, index) => (
                    item.name === props.type && (
                      <span className='number'>
                        {item.total}
                      </span>
                    )
                  ))
                }
              </p>
            </div>
            <div className='pt-2'>
              <div className='receive-state'>
                <p>{t('bet_and_earn.claimed')}:</p>
                <p>{this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]}
                  {
                    props.summary && props.summary.map((item, index) => (
                      item.name === props.type && (
                        <span className='number'>
                          {item.received}
                        </span>
                      )
                    ))
                  }
                </p>
              </div>
              <div className='receive-state'>
                <p>{t('bet_and_earn.unclaimed')}:</p>
                <p>{this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]}
                  {
                    props.summary && props.summary.map((item, index) => (
                      item.name === props.type && (
                        <span className='number'>
                          {item.unreceived}
                        </span>
                      )
                    ))
                  }
                </p>
              </div>
            </div>
            {
              props.summary && props.summary.map((item, index) => (
                item.name === props.type && (
                  <button
                    className='btn btn-gradient-blue'
                    disabled={(item.claimAllData && item.claimAllData.length > 0) || item.claimAllDisabled === false ? false : true}
                    onClick={localStorage.getItem("accessToken") ? (e) => this.props.handleClaim(e, item.claimAllData, props.type) : (e) => this.props.redirectLogin(e)}
                  >
                    {item.claimAll}
                  </button>
                )
              ))
            }
            <p className='total-amount-mention'>
              {t('bet_and_earn.next_tier_1')} {this.props.login ? this.props.currentUser.currency : this.props.currency[this.props.currentUser.country_id]}
              {
                props.summary && props.summary.map((item, index) => (
                  item.name === props.type && (
                    <span> {item.nextBonus} </span>
                  )
                ))
              }
              {t('bet_and_earn.next_tier_2')}
              <br />
              {t('bet_and_earn.next_tier_3')}
              {
                props.summary && props.summary.map((item, index) => (
                  item.name === props.type && (
                    <span> {item.nextBet}</span>
                  )
                ))
              }
            </p>
          </div>
        </div>

        <div className='events-table-container card-shadow'>
          <table className="table table-borderless  events-table">
            <thead>
              <tr>
                <th>{t('bet_and_earn.valid_bet')}</th>
                <th>{t('bet_and_earn.bonus')}</th>
              </tr>
            </thead>
            <tbody>
              {
                props.data && props.data.map((item, index) => (
                  item.name === props.type && item.list.map((item, index) => (
                    <tr key={index}>
                      <td>{item.amount}</td>
                      <td>
                        <button
                          className={`btn ${item.status === "unavailable" ? 'btn-outline-blue' : 'btn-gradient-blue'}`}
                          disabled={item.status === "received" ? true : false}
                          onClick={(e) => this.props.handleClaim(e, [item.amount], props.type)}
                        >
                          {item.status === "received" ? t('bet_and_earn.claimed') : item.reward}
                        </button>
                      </td>
                    </tr>
                  ))
                ))
              }
            </tbody>
          </table>
        </div>
      </Fade>
    )
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const banner = this.imageImport(require.context("../../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
    const icon = this.imageImport(require.context("../../../assets/images/icons", false, /\.(png|jpe?g|svg)$/));

    // const providers = [
    //   { id: 1, icon: "lc-icon", lable: "live-casino" },
    //   { id: 2, icon: "sports-icon", lable: "sports" },
    //   { id: 3, icon: "slot-icon", lable: "slots" },
    //   { id: 4, icon: "game-icon", lable: "games" },
    // ];

    return (
      // <Auxiliary>
      <section className='bne-m-section' ref={this.topSection}>
        <SideBar />
        <div className="banner-col">
          <div className="banner-item">
            <img className="w-100" src={banner[`bet-and-earn-banner${this.props.imageLanguage}.webp`]} alt="banner" />
          </div>
        </div>

        <div className='p-3'>
          <div className='section-header-gray mobile mb-0'>
            <h2 className='mb-2'>{t('bet_and_earn.title_1')}</h2>
            <p>{t('bet_and_earn.title_2')}</p>
            {/*} <p className='event-date'>{t('bet_and_earn.time_1')}：
              <span>{t('bet_and_earn.time_2')}</span>
              <span className='px-2'>{t('bet_and_earn.time_3')}</span>
              <span>{t('bet_and_earn.time_4')}</span>
            </p> */}
          </div>
          <div className='product-m-section pt-3'>
            <div className='game-m-nav my-2'>
              <div className='row g-2'>
                {
                  this.props.gameTypeData && this.props.gameTypeData.map((val, idx) => (
                    <div className='col-6'>
                      <div className={`game-nav-btn ${idx === this.state.activeProvider ? "active" : ""}`} onClick={() => this.toggleActiveProdiver(idx)} style={{
                        pointerEvents: ` ${this.state.activeAnimation === false ? "none" : ""}`
                      }} key={idx}>
                        <img className='nav-img' src={icon[val.icon]} alt="" />
                        <p>{val.title}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>
            {
              this.props.gameTypeData && this.props.gameTypeData.map((val, idx) => (
                this.state.activeProvider === idx &&
                <this.BettingType
                  icon={icon[val.icon]}
                  title={val.title}
                  type={val.gameType}
                  summary={this.props.summary}
                  data={this.props.data}
                />
              ))
            }
            {/* this.state.activeProvider === 1 &&
              <this.BettingType
                icon={icon["lc-icon.png"]}
                title="真人直播"
                type="live casino"
                totalAmount={this.props.totalAmount}
                data={this.props.data}
              />
            }
            {this.state.activeProvider === 2 &&
              <this.BettingType
                icon={icon["sports-icon.png"]}
                title="体育赛事"
                type="sports"
                totalAmount={this.props.totalAmount}
                data={this.props.data}
              />
            }
            {this.state.activeProvider === 3 &&
              <this.BettingType
                icon={icon["slot-icon.png"]}
                title="老虎机游戏"
                type="slot"
                totalAmount={this.props.totalAmount}
                data={this.props.data}
              />
            }
            {this.state.activeProvider === 4 &&
              <this.BettingType
                icon={icon["game-icon.png"]}
                title="电子游戏"
                type="games"
                totalAmount={this.props.totalAmount}
                data={this.props.data}
              />
            */}
          </div>
        </div>


        <div className='term-section'>
          <h4 className='bet-tnc-title'>{t('bet_and_earn.tnc')}</h4>
          <div className='term-container'>
            <div className='term-content'>
              <h4 className='mb-3'>{t('bet_and_earn.rule_of_activity')}：</h4>
              <ol>
                <li>{t('bet_and_earn.rule_1').replace("##currency##", this.props.currency[this.props.currentUser.country_id] ?? "")}</li>
                <li>{t('bet_and_earn.rule_2')}</li>
                <li>{t('bet_and_earn.rule_3')}</li>
                <li>{t('bet_and_earn.rule_4')}</li>
                <li>{t('bet_and_earn.rule_5')}</li>
                <li>{t('bet_and_earn.rule_6')}</li>
              </ol>
            </div>
          </div>
        </div>

        <div className="btt-btn mt-0 mb-5">
          <div className="btn btn-white" onClick={this.scrollToTop}>
            <Icon icon="ph:caret-double-up-bold" />
            {t("vip.back-to-top")}
          </div>
        </div>
      </section>
      // </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileBetAndEarn));