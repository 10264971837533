import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { getCountry } from "../../store/ducks/home/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";
import { getSystemSetting } from "../../store/ducks/setting/actions";

// import { Fade } from "react-awesome-reveal";
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";
import MobileSelectRadio from "../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio";
import { Icon } from "@iconify/react";

let countries = [];
let langArr = [];
export class MobileRegionLanguage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            // openSelectLangauge: false,
            language: "en",
            region: "MY",

            showSelectRadio: false,

            loading: false,
            openModal: false,
            currentCountryImage: "MY",
            currentLanguageCode: "EN",
        };
    }

    componentDidMount() {
        // const { t } = this.props;
        this.props.getCountry();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;

        if (nextProps.country_data !== undefined) {
            if (nextProps.country_data !== this.props.country_data) {
                let countryData = nextProps.country_data.country;
                let languageData = nextProps.country_data.language;
                // here

                //Clear the country array
                countries = [];
                langArr = [];

                Object.keys(countryData).map((item, i) => {
                    import(`../../assets/images/flags/${countryData[item].countryShortCode}.webp`).then((image) => {
                        if (this.props.currentUser.country_code === countryData[item].countryShortCode) {
                            this.setState({
                                currentCountryImage: image.default,
                                currentCountryShortCode: countryData[item].countryShortCode,
                                countryLoading: false,
                                currentLanguageCode: countryData[item].defaultLanguageCode.toUpperCase(),
                            });
                        }

                        if(localStorage.getItem("accessToken")){
                            // if login & same country
                            if(this.props.currentUser.country_id === countryData[item].countryId){
                                countries.push({
                                    image: image.default,
                                    countryId: countryData[item].countryId,
                                    countryName: countryData[item].countryName,
                                    countryCode: countryData[item].countryShortCode,
                                    currentLanguageCode: countryData[item].defaultLanguageCode.toUpperCase(),
                                });
                            }
                        }
                        else{
                            countries.push({
                                image: image.default,
                                countryId: countryData[item].countryId,
                                countryName: countryData[item].countryName,
                                countryCode: countryData[item].countryShortCode,
                                currentLanguageCode: countryData[item].defaultLanguageCode.toUpperCase(),
                            });
                        }

                        this.setState({
                            loading: true,
                        });
                    });

                    return countries;
                });

                Object.keys(languageData).map((item, i) => {
                    return langArr.push({
                        languageId: languageData[item].languageId,
                        countryCode: languageData[item].countryShortCode,
                        code: languageData[item].languageCode,
                        desc: languageData[item].languageName,
                        displayLanguageName: languageData[item].displayLanguageName,
                        timezone: languageData[item].timezone,
                    });
                });
            }
        }

        return true;
    }

    changeCountryHandler = (event, countryCode, countryId, currentLanguageCode) => {
        event.preventDefault();

        let languageId;
        let timezone;

        langArr.map((langVal, idx) => {
            if (countryCode === langVal.countryCode && currentLanguageCode === langVal.code.toUpperCase()) {
                languageId = langVal.languageId;
                timezone = langVal.timezone;
            }
            return null;
        });

        // languageId, languageCode, timezone
        if (this.props.currentUser.length === 0) {
            const data = {};
            data.country_code = countryCode;
            data.country_id = countryId;
            data.language_code = currentLanguageCode;
            data.language_id = languageId;
            data.time_zone = timezone;
            data.member_group_id = this.props.currentUser.member_group_id;
            // here
            this.props.getUserData(data);
        } else {
            const data = this.props.currentUser;
            data.country_code = countryCode;
            data.country_id = countryId;
            data.language_code = currentLanguageCode;
            data.language_id = languageId;
            data.time_zone = timezone;
            data.member_group_id = this.props.currentUser.member_group_id;
            // here is that the goup id problem
            this.props.getUserData(data);
        }
        let la = currentLanguageCode.replace("-", "_").toString().toLowerCase();
        localStorage.setItem("lang", la || "en");
        localStorage.removeItem('promosData');
        localStorage.removeItem('vipDetails');
        this.props.i18n.changeLanguage(la);

        import(`../../assets/images/flags/${countryCode}.webp`).then((image) => {
            this.setState({
                currentCountryImage: image.default,
                currentCountryShortCode: countryCode,
                currentLanguageCode: currentLanguageCode.toUpperCase(),
                timezone: timezone,
                openModal: false,
            });
        });

        // call system setting again for mobile version
        this.props.getSystemSetting();
    };

    changeLanguageHandler = (e) => {

        let lang = e.value;

        this.setState({
            currentLanguageCode: lang,
            showSelectRadio: false,
        });

        // event.preventDefault();
        let languageId;
        langArr.map((langVal, idx) => {
            if (lang === langVal.code && langVal.countryCode === this.props.currentUser.country_code) {
                languageId = langVal.languageId;
            }
            return null;
        });

        const data = this.props.currentUser;
        // data.country_code = this.props.currentUser.country_code;
        // data.country_id = countryId;
        data.language_code = lang;
        data.language_id = languageId;
        // data.time_zone = timezone;
        data.member_group_id = this.props.currentUser.member_group_id;
        // here is that the goup id problem
        this.props.getUserData(data);

        let la = lang.replace("-", "_").toString().toLowerCase();
        localStorage.setItem("lang", la || "en");
        localStorage.setItem("changeCountry", true);
        localStorage.removeItem('promosData');
        localStorage.removeItem('vipDetails');
        this.props.i18n.changeLanguage(la);
    };

    toggleSelectRadio = (e) => {
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };

    changeLanguage = (newLanguage) => {
        this.setState({
            language: newLanguage,
        });
    };

    changeRegion = (newRegion) => {
        this.setState({
            region: newRegion,
            language: "en",
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    chooseLanguageHandler = (e) => {
        this.setState({
            currentLanguageCode: e.value,
        });
    };

    render() {
        // const options = [
        //   {label:"中文", value:"zh-cn"},
        //   {label:"English", value:"en"}
        // ]
        const { t } = this.props;

        let options = [];

        langArr.map((langVal, idx) => {
            if (this.props.currentUser.country_code === langVal.countryCode) {
                options.push({
                    label: langVal.displayLanguageName,
                    value: langVal.code,
                });
            }
            return null;
        });

        return (
            <Auxiliary>
                {this.state.loading &&
                <>
                <MobileSelectRadio
                    showSelectRadio={this.state.showSelectRadio}
                    // toggleHandler={(event) => this.changeLanguageHandler(event)}
                    toggleHandler={this.toggleSelectRadio}
                    header={t('profile-sidebar.choose-language')}
                    options={options}
                    name="language"
                    onChange={this.changeLanguageHandler}
                    value={(this.props.currentUser && this.props.currentUser.language_code) || null}
                />
                <MobileSubHeader returnPage="/" header={t("footer.region-language")} style={{ justifyContent: "unset" }} />
                <section className="regionLanguage-section mt-4">
                    <div className="select-language">
                        <p>{t("global.languages")}:</p>
                        <div className="btn-outline-blue" onClick={this.toggleSelectRadio}>
                            <button className="btn">
                                {this.props.currentUser.language_code ? this.props.currentUser.language_code.toUpperCase() : this.state.currentLanguageCode}
                            </button>
                            <Icon icon="ic:sharp-arrow-drop-up" />
                        </div>
                    </div>
                    <div className="select-region mt-4">
                        <ul>
                            {countries &&
                                countries.map((item, index) => (
                                    <li
                                        className={`${this.props.currentUser.country_code === item.countryCode && "active"}`}
                                        key={index}
                                        onClick={(event) => this.changeCountryHandler(event, item.countryCode, item.countryId, item.currentLanguageCode)}
                                    >
                                        <div className="d-flex align-items-center">
                                            <img className="flags-img" src={item.image} alt="flags" />
                                            <p>{item.countryName}</p>
                                        </div>
                                        <Icon className="uncheck" icon="mdi:radiobox-blank" />
                                        <Icon className="checked" icon="teenyicons:tick-circle-outline" />
                                    </li>
                                ))}
                        </ul>
                    </div>
                </section>
                </>
                }
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { home } = state;
    return {
        country_data: home.country_data,
        currentUser: state.currentUser.data,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = {
    getCountry,
    getUserData,
    getSystemSetting
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withNamespaces("translation")(MobileRegionLanguage)));
