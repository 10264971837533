import React, { Component } from "react";
import { connect } from "react-redux";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from 'react-device-detect';

//Miscellaneous
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";

//Components
// import NoRecords from "../../../components/NoRecords/NoRecords";
import { Fade } from "react-awesome-reveal";
import DataTable from "../../../components/Datatable/DataTable";

//Icons
import { Icon } from "@iconify/react";

import { getNotification, deleteNotification, readNotification, getCountUnreadInboxAlert } from "../../../store/ducks/inbox/actions";
class Announcement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      msgListings: [],
      loading: true,
      page: this.props.activePage ? this.props.activePage : Number(process.env.REACT_APP_DATATABLE_PAGE),
      limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
      sorted: null,
      totalPages: 0,
      totalRecords: 0,
      isInterval: false,
      unreadNotice: 0,
      unreadAnnouncement: 0
    };
  }

  componentDidMount() {
    this.fetchData(false);

    this.intervalId = setInterval(() => {
      this.setState({
        isInterval: true
      });
      this.fetchData(true);
    }, 10000);

    if(!isDesktop){
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    if(!isDesktop){
      this.props.onRef(undefined);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]", nextProps);
    if (nextProps.get_notification_data !== undefined && nextProps.get_notification_data !== this.props.get_notification_data) {
      if (nextProps.get_notification_data.data.responseCode === 200 && nextProps.get_notification_data.data.msgType === "success") {
        let notification_data = nextProps.get_notification_data.data.data;
        notification_data.forEach((val) => {
          val.isChecked = false;
          val.value = val.Id;
          val.read = val.status;
          val.date = val.created_at;
          val.html_content = val.content;
          val.content = val.content.replace(/(<([^>]+)>)/ig, '');
          val.icon = "msg-ann";
          val.msgType = "notification";
        });

        if (this.state.isInterval === true && nextProps.get_notification_data.data.hasNewMsg === "1") {
          this.setState({
            loading: true
          })
        }

        this.setState({
          msgListings: notification_data,
          loading: false,
          totalPages: nextProps.get_notification_data.data.totalPages,
          totalRecords: nextProps.get_notification_data.data.totalRecords
        });
      }

      if (nextProps.totalUnread !== undefined && nextProps.totalUnread !== this.props.totalUnread) {
        if (nextProps.totalUnread.responseCode === 200 && nextProps.totalUnread.msgType === "success") {
          let unreadNoticeCnt = nextProps.totalUnread.data.inboxCount;
          let unreadAnnouncementCnt = nextProps.totalUnread.data.notificationCount;
          unreadNoticeCnt = unreadNoticeCnt >= 99 ? 99 : unreadNoticeCnt;
          unreadAnnouncementCnt = unreadAnnouncementCnt >= 99 ? 99 : unreadAnnouncementCnt;
          this.setState({
            loading: false,
            unreadNotice: unreadNoticeCnt,
            unreadAnnouncement: unreadAnnouncementCnt
          });
        }
      }
    }

    if (nextProps.read_notification_data !== this.props.read_notification_data) {
      this.fetchData(true);
    }

    if (nextProps.delete_notification_result !== this.props.delete_notification_result) {
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
          title:  t("global.success"),
          text: t("global.record-deleted"),
          icon: "success",
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sucess',
            icon: 'swal-success-icon'
        } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            this.fetchData(true);
          }
        });
    }
    return true;
  }

  fetchData = loading => {
    let params = {};
    params.page = this.state.page;
    params.limit = this.state.limit;
    params.member_id = this.props.currentUser.data.id;
    params.language_id = this.props.currentUser.data.language_id;
    if (loading && this.state.isInterval === false) {
      this.setState({ loading: true });
    }
    this.props.getNotification(params);

    params = {};
    params.lang_id = this.props.currentUser.data.language_id;
    this.props.getCountUnreadInboxAlert(params);
  }

  handleEdit = () => {
    this.setState({ showEdit: !this.state.showEdit }, () => {
      if (this.state.showEdit) {
        clearInterval(this.intervalId);
      }
      else {
        this.intervalId = setInterval(() => {
          this.setState({
            isInterval: true
          });
          this.fetchData(true);
        }, 10000);
      }
    });
  }

  handleAllChecked = (event) => {
    const msgListings = this.state.msgListings;
    msgListings.forEach(msgListing => (msgListing.isChecked = event.target.checked));
    this.setState({ msgListings: msgListings });
  };

  handleCheckChildElement = (event) => {
    const msgListings = this.state.msgListings;
    msgListings.forEach(msgListing => {
      if (msgListing.value === parseInt(event.target.value)) {
        msgListing.isChecked = event.target.checked;
      }
    });
    this.setState({ msgListings: msgListings });
  };

  handleRead = (event, type) => {
    event.preventDefault();
    const { t } = this.props;
    let read_notification = [];

    this.setState({isInterval: false});
    
    if (type === "all") {
      clearInterval(this.intervalId);
      read_notification = this.state.msgListings.filter((item) => item.status === 0).map((item) => item.Id);
    }
    else if (type === "selectable") {
      read_notification = this.state.msgListings.filter((item) => item.status === 0 && item.isChecked === true).map((item) => item.Id);
    }
    if (read_notification.length > 0) {
      if (isDesktop) {
        Swal.fire({
          title: t("global.confirmation"),
          text: t("global.would-you-like-to-proceed") + "?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: t("global.cancel"),
          confirmButtonText: t("global._yes"),
        }).then((result) => {
          if (result.isConfirmed) {
            const readNotificationData = {};
            readNotificationData.inbox_id = read_notification;
            if (type === "selectable") {
              this.setState({ showEdit: false });
            }
            this.props.readNotification(readNotificationData);

            this.intervalId = setInterval(() => {
              this.setState({
                isInterval: true
              });
              this.fetchData(true);
            }, 10000);
          }
          if (result.isDismissed && type === "all") {
            this.intervalId = setInterval(() => {
              this.setState({
                isInterval: true
              });
              this.fetchData(true);
            }, 10000);
          }
        });
      } else {
        Swal.fire({
          iconHtml:
          '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">',
          icon: "warning",
          title: t("global.confirmation"),
          text: t("global.would-you-like-to-proceed") + "?",
          cancelButtonText: t("global.cancel"),
          confirmButtonText: t("global._yes"),
          showCancelButton: true,
          customClass: {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const readNotificationData = {};
            readNotificationData.inbox_id = read_notification;
            if (type === "selectable") {
              this.setState({ showEdit: false });
            }
            this.props.readNotification(readNotificationData);

            this.intervalId = setInterval(() => {
              this.setState({
                isInterval: true
              });
              this.fetchData(true);
            }, 10000);

            this.props.unsetEditBtnState(2);
          }
          if (result.isDismissed && type === "all") {
            this.intervalId = setInterval(() => {
              this.setState({
                isInterval: true
              });
              this.fetchData(true);
            }, 10000);
          }
        });
      }
    }
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  datatablesChangeHandler = (val) => {
    this.setState({
      filter: {
        activePage: val,
      },
      msgListings: [],
      loading: true,
      page: val,
      isInterval: false
    }, () => {
      this.fetchData(true);
    });
  }

  handleDelete = event => {
    event.preventDefault();

    // let cancel = true;
    const { t } = this.props;
    let delete_notification = this.state.msgListings.filter((item) => item.isChecked === true).map((item) => item.Id);
    if (delete_notification.length > 0) {
      if (isDesktop) {
        Swal.fire({
          title: t("global.reminder"),
          text: t("global.once-deleted-it") + "?",
          icon: "warning",
          showCancelButton: true,
          cancelButtonText: t("global.cancel"),
          confirmButtonText: t("global.delete"),
        }).then((result) => {
          if (result.isConfirmed) {
            // cancel = false;
            const deleteinboxData = {};
            deleteinboxData.inbox_id = delete_notification;
            this.props.deleteNotification(deleteinboxData);

            //return to first page regardless of where the picked messages are deleted
            this.setState({ page: 1, loading: true, msgListings: [], showEdit: false }, () => {
              this.fetchData(false);

              this.intervalId = setInterval(() => {
                this.setState({
                  isInterval: true
                });
                this.fetchData(true);
              }, 10000);
            });
          }
        });
      } else {
        Swal.fire({
          iconHtml:
            '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">',
          title: t("global.reminder"),
          text: t("global.once-deleted-it") + "?",
          icon: "warning",
          confirmButtonText: t("global.delete"),
          cancelButtonText: t("global.cancel"),
          showCancelButton: true,
          customClass: {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            // cancel = false;
            const deleteinboxData = {};
            deleteinboxData.inbox_id = delete_notification;
            this.props.deleteNotification(deleteinboxData);

            //return to first page regardless of where the picked messages are deleted
            this.setState({ page: 1, loading: true, msgListings: [], showEdit: false }, () => {
              this.fetchData(false);

              this.intervalId = setInterval(() => {
                this.setState({
                  isInterval: true
                });
                this.fetchData(true);
              }, 10000);

              this.props.unsetEditBtnState(2);
            });
          }
        });
      }
    }

  }

  render() {
    let rsData = [];
    const { t } = this.props;

    const truncate = (value) =>
      isDesktop ? (
        value?.length > 60 ? `${value.substring(0, 60)}...` : value
      ) : (
        value?.length > 40 ? `${value.substring(0, 40)}...` : value
      );
    const replaceUnknown = (value) => value.replace("&nbsp;","") ;

    const columns = [
      { text: 'content', dataField: "msgContent", headerAttrs: { hidden: true }, classes: 'msg-list-group' },
    ];

    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    rsData = this.state.msgListings.map((val, idx) => {
      const msgContent = (
        <div className={`list-group-item ${this.state.showEdit && 'show'}`} onClick={(event) => this.props.handleChangePage(event, 'details', val.value, this.state.msgListings, 'announcement', val.status, this.state.page)}>
          {isDesktop ? (
            this.state.showEdit &&
            <input
              className="form-check-input"
              onChange={this.handleCheckChildElement}
              type="checkbox"
              checked={val.isChecked}
              value={val.value}
              onClick={(event) => event.stopPropagation()}
            />
          ) : (
            <input
              className="form-check-input"
              onChange={this.handleCheckChildElement}
              type="checkbox"
              checked={val.isChecked}
              value={val.value}
              onClick={(event) => event.stopPropagation()}
            />
            )
          }
          <div className="msg-icon-col">
            {
              !val.read &&
              <span className="notify-red-dot" />
            }
            <img src={icons['msg-ann.svg']} alt='msg-ann' />
          </div>
          {isDesktop ? (
            <>
              <h6>【<span>{val.title}</span>】</h6>
              <p>{truncate(replaceUnknown(val.content))}</p>
              <span>{val.date}</span>
            </>
          ) : (
            <div className="list-group-item-r">
              <label>
                <h6>【<span>{val.title}</span>】</h6>
                <span>{val.date}</span>
              </label>
              <p>{truncate(replaceUnknown(val.content))}</p>
            </div>
          )}
        </div>
      );
      return {
        msgContent: msgContent
      };
    });

    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              <div className={`tab-pane fade ${this.props.activePanel === 2 ? "show active" : ""}`} id="msg-announcement" role="tabpanel">
                <div className="msg-list-col">
                  <div className="msg-edit-col" >
                    {this.state.showEdit &&
                      <div className="msg-edit-panel left">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checkAll" onChange={this.handleAllChecked} key="checkAll" />
                          <label htmlFor="checkAll">
                            {t("message.all")}
                          </label>
                        </div>
                        <button className="btn text-icon" type="button" onClick={(event) => this.handleRead(event, "selectable")}>
                          <Icon icon="mdi:email-open-outline" />
                          {t("message.mark-as-read")}
                        </button>
                        <button className="btn text-icon trash" type="button" onClick={(event) => this.handleDelete(event)}>
                          <Icon icon="uil:trash-alt" />
                          {t("message.delete")}
                        </button>
                      </div>
                    }
                    <div className="msg-edit-panel right">
                      {this.state.showEdit ? (
                        <button className="btn text-icon" type="button" onClick={this.handleEdit}>
                          <>{t("message.done")}</>
                        </button>
                      ) : (
                        // notification && notification.length>0 &&
                        <>
                          <button className="btn text-icon" type="button" onClick={(event) => this.handleRead(event, "all")}>
                            <Icon icon="mdi:email-open-multiple-outline" />
                            {t("message.all-read")}
                          </button>
                          <button className="btn text-icon" type="button" onClick={this.handleEdit}>
                            {t("global.edit")}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="msg-list">
                    {this.state.loading ? (
                      <Skeleton count={2} height={50} style={{ marginBottom: ".25rem" }} />
                    ) : (
                      <Fade duration={1000} triggerOnce>
                        <DataTable
                          keyField="no"
                          data={rsData}
                          columns={columns}
                          activePage={this.state.page}
                          limit={this.state.limit}
                          totalItems={this.state.totalRecords}
                          paginationHandler={(val) => this.datatablesChangeHandler(val)}
                          sizePerPageHandler={(val) => this.datatablesChangeHandler(val)}
                          loading={this.state.loading}
                          hideSearchBar={true}
                          hideTotalRecords={true}
                          classValue="pd-table"
                          type="announcement"
                        />
                      </Fade>
                    )}
                  </div>
                </div>
              </div>
            </>) : (
            <div className={`tab-pane fade ${this.props.activePanel === 2 ? "show active" : ""}`} id="msg-announcement" role="tabpanel">
              <div className="msg-list-col">
                <div className="msg-list">
                  {this.state.loading ? (
                    <Skeleton count={2} height={180} style={{ marginBottom: ".5rem" }} />
                  ) : (
                    <Fade duration={1000} triggerOnce>
                      <DataTable
                        keyField="no"
                        data={rsData}
                        columns={columns}
                        activePage={this.state.page}
                        limit={this.state.limit}
                        totalItems={this.state.totalRecords}
                        paginationHandler={(val) => this.datatablesChangeHandler(val)}
                        sizePerPageHandler={(val) => this.datatablesChangeHandler(val)}
                        loading={this.state.loading}
                        hideSearchBar={true}
                        hideTotalRecords={true}
                        classValue="pd-table"
                        type="announcement"
                      />
                    </Fade>
                  )}
                </div>
              </div>
              <div className={`msg-edit-col ${this.state.showEdit && 'show'}`} >
                <div className="msg-edit-panel left">
                  <button className="btn text-icon" type="button" onClick={(event) => this.handleRead(event, 'selectable')}>
                    {t("message.mark-as-read")}
                  </button>
                </div>
                <div className="msg-edit-panel right">
                  {/* <button className="btn text-icon" type="button" onClick={this.handleEdit}>
                      <>{t("message.done")}</>
                    </button> */}
                  <button className="btn text-icon trash" type="button" onClick={(event) => this.handleDelete(event)}>
                    {t("message.delete")}
                  </button>
                </div>
              </div>
            </div>
          )
        }
      </Auxiliary >
    );
  }
}

const mapStateToProps = state => {
  // console.log("[mapStateToProps]", state);
  return {
    currentUser: state.currentUser,
    get_notification_data: state.inbox.get_notification_data,
    delete_notification_result: state.inbox.delete_notification_result,
    read_notification_data: state.inbox.read_notification_data,
    totalUnread: state.inbox.count_unread_inbox_alert_data,
  }
}

const mapDispatchToProps = {
  getCountUnreadInboxAlert,
  getNotification,
  readNotification,
  deleteNotification
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Announcement));
