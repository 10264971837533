import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import { isMobile } from "react-device-detect";
import { withNamespaces } from "react-i18next";
import moment from "moment-timezone";
import { isDesktop } from "react-device-detect";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

import { getUserData } from "../../store/ducks/currentUser/actions";
import * as currentUserActions from "../../store/ducks/currentUser/actions";
import * as sportActions from "../../store/ducks/sport/actions";
import * as casinoActions from "../../store/ducks/liveCasino/actions";
import * as lotteryActions from "../../store/ducks/lottery/actions";
import * as gamesActions from "../../store/ducks/games/actions";
import * as p2pActions from "../../store/ducks/p2p/actions";
import * as slotActions from "../../store/ducks/slot/actions";

class GameLauncher extends Component {
    // constructor(props) {
    //     super(props);
        // this.state = {
        //     url: "",
        //     loading: true,
        //     gameLoading: true,
        // };
    // }

    componentDidMount() 
    {
        if (!localStorage.getItem("accessToken")) 
        {
            const { t } = this.props;
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">Aa">Aa">Aa">Aa">Aa">' : '',
                icon: "info",
                title: t("global.sorry"),
                text: t("global.login-proceed"),
                confirmButtonText: t("global.ok"),
                customClass: (!isDesktop) ? {
                    container: 'swal-mobile-container sorry',
                    icon: 'swal-sorry-icon',
                } : [],
            });
        }
        else 
        {
            if(localStorage.getItem("gameLauncher"))
            {
                Swal.fire({
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    }
                });

                let gameLauncher = localStorage.getItem("gameLauncher").split("|");
                let type = gameLauncher[0];
                let game = gameLauncher[1];
                localStorage.removeItem("gameLauncher");

                switch (type) 
                {
                    case 'sport':
                        this.getSportUrl(game);
                    break;
                    case 'casino':
                        this.getCasinoUrl(game);
                    break;                    
                    case 'lottery':
                    case 'games':
                    case 'p2p':
                    case 'slot':
                        let vendor = (gameLauncher[2]) ? gameLauncher[2] : '';
                        let demoUrl = (gameLauncher[3]) ? gameLauncher[3] : '';
                        
                        if(vendor)
                        {
                            if(demoUrl)
                            {
                                switch(type)
                                {
                                    case 'lottery':
                                        this.getLotteryDemoUrl(game, vendor, demoUrl);
                                    break;
                                    case 'games':
                                        this.getGamesDemoUrl(game, vendor, demoUrl);
                                    break;
                                    case 'slot':
                                        this.getSlotDemoUrl(game, vendor, demoUrl);
                                    break;
                                    case 'p2p':
                                        this.getP2pDemoUrl(game, vendor, demoUrl);
                                    break;
                                    default:
                                    break;
                                }
                            }
                            else
                            {
                                switch(type)
                                {
                                    case 'lottery':
                                        this.getLotteryUrl(game, vendor);
                                    break;
                                    case 'games':
                                        this.getGamesUrl(game, vendor);
                                    break;
                                    case 'p2p':
                                        this.getP2pUrl(game, vendor);
                                    break;
                                    case 'slot':
                                        this.getSlotUrl(game, vendor, demoUrl);
                                    break;
                                    default:
                                    break;
                                }
                            }
                        }
                        else
                        {
                            window.location.assign('/');
                        }
                    break;
                    default:
                        window.location.assign('/');
                    break;
                }
            }
            else
            {
                window.location.assign('/');
            }
        }
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;

        if (nextProps.sportRs !== this.props.sportRs && nextProps.sportRs !== undefined) 
        {
            if (nextProps.sportRs.responseCode === 200 && nextProps.sportRs.msgType === "success") 
            {
                if(nextProps.sport.actionFrom === "PageContent") 
                {
                    if(nextProps.sportRs.data.hasOwnProperty('url')) 
                    {
                        window.location.assign(nextProps.sportRs.data.url);
                    }
                    else if (nextProps.sportRs.data.hasOwnProperty('gameError')) 
                    {
                        this.gameErrorResponse(nextProps.sportRs.data.gameError);
                    }
                    // Swal.close();
                }
            } else {
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} (${nextProps.sportRs.message[0]}). ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                });
            }
        }

        if (nextProps.casinoRs !== this.props.casinoRs && nextProps.casinoRs !== undefined) 
        {
            if (nextProps.casinoRs.responseCode === 200 && nextProps.casinoRs.msgType === "success") 
            {
                if (nextProps.liveCasino.actionFrom === "PageContent") 
                {
                    // Swal.close();
                    if (nextProps.casinoRs.data.hasOwnProperty("url")) 
                    {
                        window.location.assign(nextProps.casinoRs.data.url);
                    } 
                    else if (nextProps.casinoRs.data.hasOwnProperty("gameError")) 
                    {
                        this.gameErrorResponse(nextProps.casinoRs.data.gameError);
                    }
                }
            }
            else 
            {
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} (${nextProps.casinoRs.message[0]}). ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                });
            }
        }

        if (nextProps.lotteryRs !== this.props.lotteryRs && nextProps.lotteryRs !== undefined) 
        {
            if (nextProps.lotteryRs.responseCode === 200 && nextProps.lotteryRs.msgType === "success") 
            {
                // Swal.close();
                if (nextProps.lotteryRs.data.hasOwnProperty("url")) 
                {
                    window.location.assign(nextProps.lotteryRs.data.url);
                } 
                else if (nextProps.lotteryRs.data.hasOwnProperty("gameError")) 
                {
                    this.gameErrorResponse(nextProps.lotteryRs.data.gameError);
                }
            } 
            else 
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} (${nextProps.lotteryRs.message[0]}). ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                });
            }
        }

        if (nextProps.gamesRs !== this.props.gamesRs && nextProps.gamesRs !== undefined) 
        {
            // console.log(nextProps.gamesRs);
            if (nextProps.gamesRs.responseCode === 200 && nextProps.gamesRs.msgType === "success") 
            {
                // Swal.close();
                if (nextProps.gamesRs.data.hasOwnProperty("url")) 
                {
                    window.location.assign(nextProps.gamesRs.data.url);
                }
                else if (nextProps.gamesRs.data.hasOwnProperty("gameError")) 
                {
                    this.gameErrorResponse(nextProps.gamesRs.data.gameError);
                }
            }
            else 
            {
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} (${nextProps.gamesRs.message[0]}). ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                });
            }
        }

        if (nextProps.p2pRs !== this.props.p2pRs && nextProps.p2pRs !== undefined) 
        {
            if (nextProps.p2pRs.responseCode === 200 && nextProps.p2pRs.msgType === "success") 
            {
                // window.location.assign(nextProps.p2pRs.data.url);
                if (nextProps.p2pRs.data.hasOwnProperty("url")) 
                {
                    window.location.assign(nextProps.p2pRs.data.url);
                }
                else if (nextProps.p2pRs.data.hasOwnProperty("gameError")) 
                {
                    this.gameErrorResponse(nextProps.p2pRs.data.gameError);
                }
            }
            else 
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} (${nextProps.p2pRs.message[0]}). ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                });
            }
        }

        if (nextProps.slotRs !== this.props.slotRs && nextProps.slotRs !== undefined) 
        {
            if (nextProps.slotRs.responseCode === 200 && nextProps.slotRs.msgType === "success") 
            {
                if (nextProps.slotRs.data.hasOwnProperty("url")) 
                {
                    window.location.assign(nextProps.slotRs.data.url);
                }
                else if (nextProps.slotRs.data.hasOwnProperty("gameError")) 
                {
                    this.gameErrorResponse(nextProps.slotRs.data.gameError);
                }
            } 
            else 
            {
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} (${nextProps.slotRs.message[0]}). ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
            
                });
            }
        }

        return true;
    }

    getSportUrl = (game) => 
    {
        let localLang = localStorage.getItem('lang');
        let languageCode = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        let countryId = this.props.currentUser.data.country_id;

        let data = {};
        data.lang = languageCode;
        data.country_id = countryId;
        data.game_category = 'sp'; // Sports
        data.game_type = "";
        data.lobby_url = window.location.href;
        data.is_mobile = isMobile;
        data.action_from = "PageContent";
        // console.log(data);
        switch (game) {
            case "sb":
                this.props.funcSportAccessGame(data, 'saba');
            break;
            case "sbo":
                this.props.funcSportAccessGame(data, 'sbobet');
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),
                    icon: "info",
                    text: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                });
                Swal.close();
            break;
        }
    }

    getCasinoUrl = (game) => 
    {
        let localLang = localStorage.getItem('lang');
        let languageCode = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        let countryId = this.props.currentUser.data.country_id;

        let data = {};
        data.lang = languageCode;
        data.country_id = countryId;
        data.game_category = "ca";
        data.game_type = "casino";
        data.lobby_url = window.location.href;
        data.action_from = "PageContent";
        data.is_mobile = isMobile;
        data.game_mode = 1;
        // localStorage.setItem('testingPurpose', JSON.stringify(data));
        switch (game) {
            case "dg":
                this.props.funcCasinoAccessGame(data, 'dream_gaming');
            break;
            case "pp":
                this.props.funcCasinoAccessGame(data, 'pragmatic_play');
            break;
            case "sa":
                this.props.funcCasinoAccessGame(data, 'sa_gaming');
            break;
            case "ag":
                this.props.funcCasinoAccessGame(data, 'asia_gaming');
            break;
            case "gd":
                this.props.funcCasinoAccessGame(data, 'golden_deluxe');
            break;
            case "ea":
                this.props.funcCasinoAccessGame(data, 'ea_gaming');
            break;
            case "gp":
                data.other_url = true;
                this.props.funcCasinoAccessGame(data, 'game_play_int');
            break;
            case "sbo":
                this.props.funcCasinoAccessGame(data, 'sbobet');
            break;
            case "evo":
                this.props.funcCasinoAccessGame(data, 'evolution_gaming');
            break;
            case "mg":
                this.props.funcCasinoAccessGame(data, 'micro_gaming');
            break;
            case "pt":
                this.props.funcCasinoAccessGame(data, 'playtech');
            break;
            case "ab":
                this.props.funcCasinoAccessGame(data, 'allbet');
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),
                    icon: "info",
                    text: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                });
            break;
        }
    }

    getLotteryUrl = (game, vendor) => 
    {
        let localLang = localStorage.getItem('lang');
        let data = {};
        data.lang = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        data.game_type = game;
        data.lobby_url = window.location.href;
        data.country_id = this.props.currentUser.data.country_id;
        data.game_category = "ke";
        data.is_mobile = isMobile ? 1 : 0;
        data.game_mode = 1;

        switch (vendor) {
            case "qt":
                if (this.props.currentUser.gameData.hasOwnProperty("qt")) {
                    data.member_username = this.props.currentUser.data.username;
                    data.qt_access_token = this.props.currentUser.gameData.qt.qt_access_token;
                    data.expires_at = this.props.currentUser.gameData.qt.qt_expires_at;
                }
                this.props.funcLotteryAccessGame(data, 'qtech');
            break;
            case "sb":
                this.props.funcLotteryAccessGame(data, 'saba');
            break;
            case "gp":
                data.other_url = true;
                // console.log(data);
                this.props.funcLotteryAccessGame(data, 'game_play_int');
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),
                    icon: "info",
                    text: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                });
            break;
        }
    }

    getLotteryDemoUrl = (game, vendor, url) => 
    {
        let localLang = localStorage.getItem('lang');
        let demoUrl = url;
        let launch = false;
        let data = {};
        data.lang = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        data.country_code = this.props.currentUser.data.country_code;;
        data.game_mode = 0;
        data.game_type = game;
        data.is_mobile = isMobile ? 1 : 0;
        data.country_id = this.props.currentUser.data.country_id;
        data.game_category = "ke";

        switch (vendor) {
            case "qt":
                if (this.props.currentUser.gameData.hasOwnProperty("qt")) {
                    data.member_username = this.props.currentUser.data.username;
                    data.qt_access_token = this.props.currentUser.gameData.qt.qt_access_token;
                    data.expires_at = this.props.currentUser.gameData.qt.qt_expires_at;
                }
                this.props.funcLotteryAccessGame(data, 'qtech');
                Swal.showLoading();
            break;
            case "gp":
                data.other_url = true;
                this.props.funcLotteryAccessGame(data, 'game_play_int');
                Swal.showLoading();
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),
                    icon: "info",
                    text: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                });
            break;
        }

        if (launch) {
            Swal.fire({
                timer: 1000,
                didOpen: () => {
                    Swal.showLoading();
                },
            }).then(() => {
                window.location.assign(demoUrl);
                Swal.close();
            });
        }
    }

    getGamesUrl = (game, vendor) => 
    {
        let localLang = localStorage.getItem('lang');
        let data = {};
        data.lang = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        data.game_type = game;
        data.country_id = this.props.currentUser.data.country_id;
        data.game_category = "ga";
        data.is_mobile = isMobile ? 1 : 0;
        data.game_mode = 1;

        switch (vendor) {
            case "pp":
                this.props.funcGamesAccessGame(data, 'pragmatic_play');
            break;
            case "qt":
                if (this.props.currentUser.gameData.hasOwnProperty("qt")) {
                    data.member_username = this.props.currentUser.data.username;
                    data.qt_access_token = this.props.currentUser.gameData.qt.qt_access_token;
                    data.expires_at = this.props.currentUser.gameData.qt.qt_expires_at;
                }
                this.props.funcGamesAccessGame(data, 'qtech');
            break;
            case "isb":
                this.props.funcGamesAccessGame(data, 'i_soft_bet');
            break;
            case "sb":
                this.props.funcGamesAccessGame(data, 'saba');
            break;
            case "sp":
                this.props.funcGamesAccessGame(data, 'simple_play');
            break;
            case "gd":
                data.other_url = true;
                this.props.funcGamesAccessGame(data, 'golden_deluxe');
            break;
            case "gp":
                data.other_url = true;
                this.props.funcGamesAccessGame(data, 'game_play_int');
            break;
            case "sbo":
                this.props.funcGamesAccessGame(data, 'sbobet');
            break;
            case "mg":
                this.props.funcGamesAccessGame(data, 'micro_gaming');
            break;
            case "evo":
                this.props.funcGamesAccessGame(data, 'evolution_gaming');
            break;
            case "sg":
                this.props.funcGamesAccessGame(data, 'spade_gaming');
            break;
            case "net":
            case "png":
            case "qs":
                this.props.funcGamesAccessGame(data, 'flow_gaming');
            break;
            case "pt":
                this.props.funcGamesAccessGame(data, 'playtech');
            break;
            case "pg":
                this.props.funcGamesAccessGame(data, 'pocket_games_soft');
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),
                    icon: "info",
                    text: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                });
                Swal.close();
            break;
        }
    }

    getGamesDemoUrl = (game, vendor, demoUrl) => 
    {
        let localLang = localStorage.getItem('lang');
        let launch = false;
        let data = {};
        data.lang = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        data.country_code = this.props.currentUser.data.country_code;;
        data.game_mode = 0;
        data.game_type = game;
        data.is_mobile = isMobile ? 1 : 0;
        data.country_id = this.props.currentUser.data.country_id;
        data.game_category = "ga";

        switch (vendor) {
            case "pp":
                launch = true;
            break;
            case "isb":
                this.props.funcGamesAccessGame(data, 'i_soft_bet');
                Swal.showLoading();
            break;
            case "qt":
                if (this.props.currentUser.gameData.hasOwnProperty("qt")) {
                    data.member_username = this.props.currentUser.data.username;
                    data.qt_access_token = this.props.currentUser.gameData.qt.qt_access_token;
                    data.expires_at = this.props.currentUser.gameData.qt.qt_expires_at;
                }
                this.props.funcGamesAccessGame(data, 'qtech');
                Swal.showLoading();
            break;
            case "sp":
                this.props.funcGamesAccessGame(data, 'simple_play');
                Swal.showLoading();
            break;
            case "gp":
                this.props.funcGamesAccessGame(data, 'game_play_int');
                Swal.showLoading();
            break;
            case "evo":
                this.props.funcGamesAccessGame(data, 'evolution_gaming');
                Swal.showLoading();
            break;
            case "sg":
                this.props.funcGamesAccessGame(data, 'spade_gaming');
                Swal.showLoading();
            break;
            case "net":
            case "png":
            case "qs":
                this.props.funcGamesAccessGame(data, 'flow_gaming');
                Swal.showLoading();
            break;
            case "pt":
                this.props.funcGamesAccessGame(data, 'playtech');
                Swal.showLoading();
            break;
            default:
                const { t } = this.props;
                alert(t("global.coming-soon"));
                Swal.close();
            break;
        }

        if (launch) {
            Swal.fire({
                timer: 1000,
                didOpen: () => {
                    Swal.showLoading();
                },
            }).then(() => {
                window.location.assign(demoUrl);
                Swal.close();
            });
        }
    }

    getP2pUrl = (game, vendor) => 
    {
        let localLang = localStorage.getItem('lang');
        let data = {};
        data.lang = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        data.game_type = game;
        data.country_id = this.props.currentUser.data.country_id;
        data.game_category = "p2";
        data.is_mobile = isMobile ? 1 : 0;
        data.game_mode = 1;

        switch (vendor) {
            case "gp":
                data.other_url = true;
                this.props.funcP2pAccessGame(data, 'game_play_int');
            break;
            case "sg":
                data.other_url = true;
                this.props.funcP2pAccessGame(data, 'spade_gaming');
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),
                    icon: "info",
                    text: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
            
                });
            break;
        }
    }

    getP2pDemoUrl = (game, vendor) => 
    {
        let localLang = localStorage.getItem('lang');
        let data = {};
        data.lang = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        data.game_type = game;
        data.country_id = this.props.currentUser.data.country_id;
        data.game_category = "p2";
        data.is_mobile = isMobile ? 1 : 0;
        data.game_mode = 0;

        switch (vendor) {
            case "sg":
                data.other_url = true;
                this.props.funcP2pAccessGame(data, 'spade_gaming');
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),
                    icon: "info",
                    text: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
            

                });
            break;
        }
    }

    getSlotUrl = (game, vendor) => 
    {
        let localLang = localStorage.getItem('lang');
        let languageCode = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        let countryId = this.props.currentUser.data.country_id;

        let data = {};
        data.lang = languageCode;
        data.game_type = game;
        data.lobby_url = window.location.href;
        data.country_id = countryId;
        data.game_category = "sl";
        data.is_mobile = isMobile ? 1 : 0;
        data.game_mode = 1;

        switch (vendor) {
            case "pp":
                this.props.funcSlotAccessGame(data, 'pragmatic_play');
            break;
            case "qt":
                if (this.props.currentUser.gameData.hasOwnProperty("qt")) {
                    data.member_username = this.props.currentUser.data.username;
                    data.qt_access_token = this.props.currentUser.gameData.qt.qt_access_token;
                    data.expires_at = this.props.currentUser.gameData.qt.qt_expires_at;
                }
                this.props.funcSlotAccessGame(data, 'qtech');
            break;
            case "ag":
                this.props.funcSlotAccessGame(data, 'asia_gaming');
            break;
            case "ttg":
                let gameCode = game.split("@@");
                data.gameId = gameCode[1];
                data.gameName = gameCode[0];
                data.gameType = 0;
                this.props.funcSlotAccessGame(data, 'top_trend_gaming');
            break;
            case "isb":
                this.props.funcSlotAccessGame(data, 'i_soft_bet');
            break;
            case "sp":
                this.props.funcSlotAccessGame(data, 'simple_play');
            break;
            case "gp":
                data.fun = 0;
                data.other_url = true;
                this.props.funcSlotAccessGame(data, 'game_play_int');
            break;
            case "gd":
                data.other_url = true;
                this.props.funcSlotAccessGame(data, 'golden_deluxe');
            break;
            case "sbo":
                this.props.funcSlotAccessGame(data, 'sbobet');
            break;
            case "evo":
                this.props.funcSlotAccessGame(data, 'evolution_gaming');
            break;
            case "mg":
                this.props.funcSlotAccessGame(data, 'micro_gaming');
            break;
            case "sg":
                this.props.funcSlotAccessGame(data, 'spade_gaming');
            break;
            case "net":
            case "png":
            case "qs":
                this.props.funcSlotAccessGame(data, 'flow_gaming');
            break;
            case "pt":
                this.props.funcSlotAccessGame(data, 'playtech');
            break;
            case "pg":
                this.props.funcSlotAccessGame(data, 'pocket_games_soft');
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    // title:  t("global.reminder"),
                    icon: "info",
                    title: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                });
            break;
        }
    }

    getSlotDemoUrl = (game, vendor, url) => 
    {
        let localLang = localStorage.getItem('lang');
        let demoUrl = url;
        let launch = false;
        let data = {};
        data.lang = this.props.currentUser.data.language_code ?? (localLang === 'zh_cn' ? 'zh-CN' : localLang);
        data.country_code = this.props.currentUser.data.country_code;
        data.game_mode = 0;
        data.game_type = game;
        data.is_mobile = isMobile ? 1 : 0;
        data.country_id = this.props.currentUser.data.country_id;
        data.game_category = "sl";

        switch (vendor) {
            case "pp":
                demoUrl = demoUrl.replace("{{language}}", data.lang).replace("{{currency}}", "USD").replace("{{game_id}}", game).replace("{{lobby_url}}", window.location.href);
                launch = true;
            break;
            case "isb":
                this.props.funcSlotAccessGame(data, 'i_soft_bet');
                Swal.showLoading();
            break;
            case "qt":
                if (this.props.currentUser.gameData.hasOwnProperty("qt")) {
                    data.member_username = this.props.currentUser.data.username;
                    data.qt_access_token = this.props.currentUser.gameData.qt.qt_access_token;
                    data.expires_at = this.props.currentUser.gameData.qt.qt_expires_at;
                }
                this.props.funcSlotAccessGame(data, 'qtech');
                Swal.showLoading();
            break;
            case "sp":
                this.props.funcSlotAccessGame(data, 'simple_play');
                Swal.showLoading();
            break;
            case "ag":
                this.props.funcSlotAccessGame(data, 'asia_gaming');
                Swal.showLoading();
            break;
            case "ttg":
                let gameCode = game.split("@@");
                data.gameId = gameCode[1];
                data.gameName = gameCode[0];
                data.gameType = 0;
                this.props.funcSlotAccessGame(data, 'top_trend_gaming');
                Swal.showLoading();
            break;
            case "gp":
                data.other_url = true;
                this.props.funcSlotAccessGame(data, 'game_play_int');
                Swal.showLoading();
            break;
            case "evo":
                this.props.funcSlotAccessGame(data, 'evolution_gaming');
                Swal.showLoading();
            break;
            case "sg":
                this.props.funcSlotAccessGame(data, 'spade_gaming');
                Swal.showLoading();
            break;
            case "net":
            case "png":
            case "qs":
                this.props.funcSlotAccessGame(data, 'flow_gaming');
                Swal.showLoading();
            break;
            case "pt":
                this.props.funcSlotAccessGame(data, 'playtech');
                Swal.showLoading();
            break;
            default:
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    // title:  t("global.reminder"),
                    icon: "info",
                    title: t("global.coming-soon"),
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                });
            break;
        }

        if (launch) {
            Swal.fire({
                timer: 1000,
                didOpen: () => {
                    Swal.showLoading();
                },
            }).then(() => {
                window.location.assign(demoUrl);
                Swal.close();
            });
        }
    }

    gameErrorResponse = (response) => 
    {
        const { t } = this.props;

        switch (response.status) {
            case 1:
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),          
                    icon: "error",
                    html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.kindly-refresh-the-page")}`,
                    showConfirmButton: true,
                    confirmButtonText: `${t("global.refresh")}`,
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.close();
                        this.props.history.push('/');
                    }
                });
                break;
            case 2:
                const currentUserTimeZone = this.props.currentUser.data.time_zone;
                const startTime = moment(response.maintenance_start_time)
                    .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
                    .format("YYYY/MM/DD HH:mm:ss")
                    .toString();
                const endTime = moment(response.maintenance_end_time)
                    .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
                    .format("YYYY/MM/DD HH:mm:ss")
                    .toString();
                const timeZone = moment()
                    .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
                    .format("[(GMT] z[)]")
                    .toString();
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    icon: "info",
                    titleText: `${t("global.under-maintenance")}`,
                    html: `${t("global.from")} <b>${startTime}</b> ${t("global.to")} <b>${endTime}</b> ${timeZone}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.close();
                        this.props.history.push('/');
                    }
                });
                break;
            case 3:
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.close();
                        this.props.history.push('/');
                    }
                });
                break;
            case 4:
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.pause-bet-or-deactivated-warning")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.close();
                        this.props.history.push('/');
                    }
                });
                break;
            default:
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} ${t("global.code")}: 500 - ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        // window.close();
                        this.props.history.push('/');
                    }
                });
            break;
        }
    };

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item) => 
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const img = this.imageImport(
            require.context(
              "../../assets/images/sport",
              false,
              /\.(png|jpe?g|svg|webp)$/
            )
        );
        return (
            <Auxiliary>
                {isDesktop ? (
                    <section className="provider-games-section" style={{ backgroundImage: `url(${img["sport-bg.webp"]}` }}>
                        <div className="x-container-no-m">
                            <div className="row"></div>
                        </div>
                    </section>
                ) : (
                    <section>
                        <div className='m-container'>
                        </div>
                    </section>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser,

        sport: state.sport,
        sportRs: state.sport.rs,

        liveCasino: state.liveCasino,
        casinoRs: state.liveCasino.rs,

        lotteryRs: state.lottery.rs,
        gamesRs: state.games.rs,
        p2pRs: state.p2p.rs,
        slotRs: state.slot.rs,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getUserData: (data) => dispatch(getUserData(data)),
        funcSportAccessGame: (data, vendor) => dispatch(sportActions.accessGame(data, vendor)),

        funcCasinoAccessGame: (data, vendor) => dispatch(casinoActions.accessGame(data, vendor)),
        setGameQT: (data) => dispatch(currentUserActions.setGameQT(data)),

        funcLotteryAccessGame: (data, vendor) => dispatch(lotteryActions.accessGame(data, vendor)),
        funcGamesAccessGame: (data, vendor) => dispatch(gamesActions.accessGame(data, vendor)),
        funcP2pAccessGame: (data, vendor) => dispatch(p2pActions.accessGame(data, vendor)),
        funcSlotAccessGame: (data, vendor) => dispatch(slotActions.accessGame(data, vendor)),        
        // setGameQT: (data) => dispatch(currentUserActions.setGameQT(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(GameLauncher));
