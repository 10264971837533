import React, { Component } from 'react'

import { LazyLoadImage } from "react-lazy-load-image-component";
import { withNamespaces } from "react-i18next";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';

export class MobileContactUs extends Component {




  ContactUsType = (props) => {
    return (
      <div className='col-6'>
        <div className='cu-content-container my-3'>
          <a href={props.link} onClick={props.onClick} target="_blank" rel="noreferrer">
            <img
              className="contact-icon"
              src={props.icon[`${props.img}`]}
              alt="contact icon"
            />
            <div className='cu-content'>
              <h4>{props.title}</h4>
              {props.content}
            </div>
          </a>
        </div>
      </div>
    )
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const icon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    );
    // const img = this.imageImport(
    //   require.context(
    //     "../../assets/images/contact-us",
    //     false,
    //     /\.(png|jpe?g|svg)$/
    //   )
    // );
    const customerImg = this.imageImport(
      require.context(
        "../../assets/images/about-us",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <Auxiliary>
        <MobileSubHeader
          returnPage="/"
          header={t("contact-us.contact-us")}
          style={{ justifyContent: "unset" }}
        />
        <section className='contacts-us-m px-4'>
          <div className='text-center'>
            <p>{t("contact-us.contact-us-details")}</p>
            <br />
            <p> {t("contact-us.contact-us-details-2")}</p>
          </div>
          <div className='mt-5 d-flex flex-column align-items-center'>
            <LazyLoadImage
              className="cu-m-img"
              src={customerImg["customer-img.webp"]}
              alt="customer-img"
              effect="blur"
            />

            <div className='row my-4'>
              <this.ContactUsType
                onClick={window['liveChatButton']}
                icon={icon}
                img="live-chat.svg"
                title={t("contact-us.live-chat")}
                content={
                  <p>
                    {t("contact-us.live-chat-details")}
                  </p>
                }
              />

              <this.ContactUsType
                link={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MERCHANT_WHATSAPP.replace(/[+ ]/g, '')}&text=Welcome%20to%20${process.env.REACT_APP_WEBSITE_TITLE}%20%EF%BC%81`}
                icon={icon}
                img="whatsapp.svg"
                title={t("contact-us.whatsapp")}
                content={
                  <p>
                     {t("contact-us.whatsapp-details")} <br /> {process.env.REACT_APP_MERCHANT_WHATSAPP}
                  </p>
                }
              />

              <this.ContactUsType
                link={`https://t.me/${process.env.REACT_APP_MERCHANT_TELEGRAM_BOT_SUPPORT}`}
                icon={icon}
                img="telegram.svg"
                title={t("contact-us.telegram")}
                content={
                  <p>
                    {t("contact-us.telegram-details")} <br /> {process.env.REACT_APP_MERCHANT_TELEGRAM_BOT}
                  </p>
                }
              />

              <this.ContactUsType
                link={`mailto:${process.env.REACT_APP_MERCHANT_EMAIL}`}
                icon={icon}
                img="email.svg"
                title={t("contact-us.email")}
                content={
                  <p>
                    {t("contact-us.email-details")} <br />{" "}
                    {process.env.REACT_APP_MERCHANT_EMAIL}
                  </p>
                }
              />

            </div>



          </div>
        </section>
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileContactUs))