import React, { Component } from 'react'
import { withNamespaces } from "react-i18next";
import Modal from "react-modal";
import { isDesktop } from "react-device-detect";
import { Icon } from "@iconify/react";
import { currency } from "../../common/config/currency";

export class X33CoinModal extends Component {
  render() {
    const { t } = this.props;
    return (
      <Modal
        isOpen={this.props.openCoinModal}
        closeTimeoutMS={500}
        portalClassName={`global-modal`}
        className="modal modal-dialog modal-dialog-centered modal-pd"
        ariaHideApp={false}
        onRequestClose={this.props.toggleCoinModal}
      >
        <div className={`modal-content my-wallet-modal ${isDesktop ? "" : "mobile-modal-bg-white p-0"}`}>
          <div className={`modal-header border-bottom ${!isDesktop && "p-3"}`}>
            <div className={`${isDesktop ? 'pb-3' : ''}`}>
              <h5>{t("top-profile.coin-learn-more-title")}</h5>
              {/* <p>{t("top-profile.upgrade-business-acc-role")}</p> */}
            </div>
            {isDesktop && <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleCoinModal} />}

          </div>
          <div className={`modal-body ${!isDesktop ? " p-3" : " mt-3"}`}>
            <h6 className='pt-0'>{t("top-profile.how-earn-coin")}</h6>
            <ol className='my-wallet-modal-content'>
              <li>
                <span>{t("top-profile.how-earn-coin-1").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</span>
              </li>
              <li>
                <span>{t("top-profile.how-earn-coin-2")}</span>
              </li>
              <li>
                <span>{t("top-profile.how-earn-coin-3")}</span>
              </li>
              <li>
                <span>{t("top-profile.how-earn-coin-4")}</span>
              </li>
              <li>
                <span>{t("top-profile.how-earn-coin-5")}</span>
              </li>
              <li>
                <span>{t("top-profile.how-earn-coin-6")}</span>
              </li>
              <li>
                <span>{t("top-profile.how-earn-coin-7")}</span>
              </li>
            </ol>
            {isDesktop && <button className="btn btn-gradient-blue mt-3" onClick={this.props.toggleCoinModal}>{t("global.ok")}</button>}
          </div>
          {!isDesktop && <button type="button" className="btn btn-dark-gray" onClick={this.props.toggleCoinModal} >{t("global.ok")}</button>}
        </div>
      </Modal>
    )
  }
}

export default (withNamespaces("translation")(X33CoinModal));