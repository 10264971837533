import React, { Component } from 'react'

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { connect } from 'react-redux';
import { isDesktop } from "react-device-detect";
import { Icon } from "@iconify/react";
// import Slider from "react-slick";
import { Fade } from "react-awesome-reveal";
import Skeleton from "react-loading-skeleton";
import MobileLeaderboard from './MobileLeaderboard';
import { list, showWinner } from "../../store/ducks/leaderboard/actions";
import LoadingPage from '../../components/LoadingPage/LoadingPage';
import { currency } from "../../common/config/currency";

export class Leaderboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeGame: '',
            activeGameStartDate: '',
            activeGameEndDate: '',
            activeGameIndex: 0,
            activeGamePrizes: {},
            weeklyWinners: [],
            leaderboards: [],
            candidates: [],
            initiated: false,
            loading: true
        };
    }

    componentDidMount() {
        this.props.list();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.leaderboard_data !== this.props.leaderboard_data) {
            if (nextProps.leaderboard_data.responseCode === 200) {
                if (!nextProps.leaderboard_data.data.leaderboards.length) {
                    return window.location.href = '/';
                }

                this.setState({
                    activeGame: nextProps.leaderboard_data.data.leaderboards[0].id,
                    activeGameStartDate: nextProps.leaderboard_data.data.leaderboards[0].start_date,
                    activeGameEndDate: nextProps.leaderboard_data.data.leaderboards[0].end_date,
                    activeGamePrizes: JSON.parse(nextProps.leaderboard_data.data.leaderboards[0].prize),
                    weeklyWinners: nextProps.leaderboard_data.data.weekly_winners,
                    leaderboards: nextProps.leaderboard_data.data.leaderboards,
                    candidates: nextProps.leaderboard_data.data.candidates,
                    initiated: true,
                    loading: false
                });
            }
        }

        if (nextProps.show_winner !== this.props.show_winner) {
            if (nextProps.show_winner.data.responseCode === 200) {
                let fetchedNewWeeklyWinners = nextProps.show_winner.data.data.weekly_winners;
                let newWeeklyWinners = Object.assign({}, this.state.weeklyWinners);
                newWeeklyWinners.game_slug = fetchedNewWeeklyWinners.game_slug;
                newWeeklyWinners.game_title = fetchedNewWeeklyWinners.game_title;
                newWeeklyWinners.start_date = fetchedNewWeeklyWinners.start_date;
                newWeeklyWinners.end_date = fetchedNewWeeklyWinners.end_date;
                newWeeklyWinners.winners = fetchedNewWeeklyWinners.winners;
                this.setState({
                    loading: false,
                    candidates: nextProps.show_winner.data.data.candidates,
                    weeklyWinners: newWeeklyWinners,
                    initiated: true
                });
            }
        }

        return true;
    }

    switchLeaderboard = (index) => {
        const leaderboard = this.state.leaderboards[index];

        if (typeof leaderboard === 'undefined' || this.state.activeGame === leaderboard.id) return;

        this.props.showWinner({
            leaderboard_id: leaderboard.id
        });

        this.setState({
            activeGame: leaderboard.id,
            activeGameStartDate: leaderboard.start_date,
            activeGameEndDate: leaderboard.end_date,
            activeGameIndex: index,
            activeGamePrizes: JSON.parse(leaderboard.prize),
            candidates: [],
            loading: true
        });
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const gameIcon = this.imageImport(require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/));
        const img = this.imageImport(require.context("../../assets/images/leaderboard", false, /\.(png|jpe?g|svg|webp)$/));
        const tNc = this.imageImport(require.context("../../assets/images/tnc", false, /\.(png|jpe?g|svg)$/));

        // const profile = this.imageImport(require.context("../../assets/images/profile", false, /\.(png|jpe?g|svg)$/));

        let imageLanguage = '';
        let tncImageLanguage = '';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn"
                tncImageLanguage = 'leaderboard-tnc-cn.svg'
                break;
            case "ms":
                imageLanguage = ""
                break;
                case "indon":
                    imageLanguage = ""
                    break;
            default:
                imageLanguage = ""
                tncImageLanguage = 'term-condition.svg'
                break;
        }

        return (
            <Auxiliary>
                {!this.state.initiated && <LoadingPage />}
                {
                    isDesktop ? (
                        <section className='lb-section'>
                            {this.state.initiated && this.state.weeklyWinners.winners?.length > 0 ?
                                (
                                    <div className="banner-col">
                                        <div className="banner-item">
                                            <img src={banner[`winner-leaderboard-banner${imageLanguage}.webp`]} alt="R77 Promos" />
                                        </div>

                                        <div className='banner-provider'>
                                            <img
                                                src={img['lb-' + this.state.weeklyWinners.game_slug + '.webp']}
                                                alt={this.state.weeklyWinners.game_title}
                                            />
                                            {/* <img src={img['lb-' + "ab" + '.svg']} alt={this.state.weeklyWinners.game_title} /> */}
                                        </div>

                                        <div className='banner-date'>
                                            <p>{t("leaderboard.weekly-list-time")}：
                                                <span>{this.state.weeklyWinners.start_date}</span>
                                                <span className='px-2'>{t('check_in.time_3')}</span>
                                                <span>{this.state.weeklyWinners.end_date}</span>
                                            </p>
                                        </div>

                                        {this.state.weeklyWinners.winners[1] &&
                                        <div className='banner-ranking-2'>
                                            <div className='profile'>
                                                <img src={this.state.weeklyWinners.winners[1].avatar} alt="profile" />
                                            </div>
                                            <div className='winner'>
                                                <p>{this.state.weeklyWinners.winners[1].username}</p>
                                                <p className='prize'>{t("leaderboard.prize")} {this.state.weeklyWinners.winners[1].prize}</p>
                                            </div>
                                        </div>
                                        }

                                        {this.state.weeklyWinners.winners[0] &&
                                        <div className='banner-ranking-1'>
                                            <div className='profile'>
                                                <img src={this.state.weeklyWinners.winners[0].avatar} alt="profile" />
                                            </div>
                                            <div className='winner'>
                                                <p>{this.state.weeklyWinners.winners[0].username}</p>
                                                <p className='prize'>{t("leaderboard.prize")} {this.state.weeklyWinners.winners[0].prize}</p>
                                            </div>
                                        </div>
                                        }

                                        {this.state.weeklyWinners.winners[2] &&
                                        <div className='banner-ranking-3'>
                                            <div className='profile'>
                                                <img src={this.state.weeklyWinners.winners[2].avatar} alt="profile" />
                                            </div>
                                            <div className='winner'>
                                                <p>{this.state.weeklyWinners.winners[2].username}</p>
                                                <p className='prize'>{t("leaderboard.prize")} {this.state.weeklyWinners.winners[2].prize}</p>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                ) : (
                                    <div className='banner-col'>
                                        <div className="banner-item">
                                            <img src={banner[`leaderboard-banner${imageLanguage}.webp`]} alt="R77 Promos" />
                                        </div>
                                    </div>
                                )
                            }
                            <div className='x-container mt-0'>
                                <div className='section-header-gray'>
                                    <h2>{t("leaderboard.tournament-ranking")}</h2>
                                    {/* <p className='event-date'>{t("leaderboard.weekly-list-time")}：
                                    <span>2022年7月11日</span>
                                    <span className='px-2'>{t('check_in.time_3')}</span>
                                    <span>2022年7月17日</span>
                                </p> */}
                                    {/*<div className='lb-slick w-100'>
                                    <Slider {...dateSetting}>
                                    {
                                        this.state.fakeData && this.state.fakeData.map((item, index) => (
                                        <p className='event-date' key={index}>{t("leaderboard.weekly-list-time")}：
                                            <span>{item.date.start.year}年{item.date.start.month}月{item.date.start.day}日</span>
                                            <span className='px-2'>{t('check_in.time_3')}</span>
                                            <span>{item.date.end.year}年{item.date.end.month}月{item.date.end.day}日</span>
                                        </p>
                                        ))
                                    }
                                    </Slider>
                                </div>*/}
                                    <div className='lb-event-date'>
                                        <button className='btn btn-gradient-blue' onClick={() => this.switchLeaderboard(this.state.activeGameIndex - 1)}>
                                            <Icon icon="uil:angle-left" />
                                        </button>

                                        <div className='date'>
                                            <Icon icon="uil:clock" />
                                            <Fade>
                                                <span>{this.state.activeGameStartDate}</span>
                                                <span className='px-2'>{t('check_in.time_3')}</span>
                                                <span>{this.state.activeGameEndDate}</span>
                                            </Fade>
                                        </div>

                                        <button className='btn btn-gradient-blue' onClick={() => this.switchLeaderboard(this.state.activeGameIndex + 1)}>
                                            <Icon icon="uil:angle-right" />
                                        </button>
                                    </div>
                                </div>

                                <div className='lb-game-container'>
                                    <ul className='row justify-content-center'>
                                        {
                                            this.state.leaderboards.length > 0 && this.state.leaderboards.map((item, index) => (
                                                <li className='col-5ths' key={index}>
                                                    <div
                                                        className={`lb-game ${this.state.activeGame === item.id && 'active'}`}
                                                        onClick={() => this.switchLeaderboard(index)}
                                                    >
                                                        <span data-game-title={item.game_title}>
                                                            <img className='icon-grey' src={gameIcon[item.game_slug + '-grey.svg']} alt={item.game_title} />
                                                            <img className='icon-white' src={gameIcon[item.game_slug + '-white.svg']} alt={item.game_title} />
                                                        </span>
                                                        <p>{t("vendor." + item.game_slug)}</p>
                                                    </div>
                                                </li>
                                            ))
                                        }
                                    </ul>
                                </div>

                                {
                                    this.state.loading === true ? (
                                        <Skeleton count={4} />
                                    ) : (
                                        this.state.candidates.length &&
                                        <Fade duration={1000}>
                                            <div className='lb-name-list mt-5'>
                                                <div className='row'>
                                                    <div className='col-5'>
                                                        <div className='d-flex align-item-center justify-content-center mb-3'>
                                                            <img src={img["paddy.webp"]} alt="deco" />
                                                            <h1>{t("leaderboard.winner-list-1")}</h1>
                                                            <img src={img["paddy.webp"]} alt="deco" style={{ transform: 'scaleX(-1)' }} />
                                                        </div>
                                                        <div className='first-card'>
                                                            <span className='lazy-load-image-background'>
                                                                <img className='emblem' src={img["top1-logo.webp"]} alt="top" />
                                                            </span>
                                                            <div className='winner'>
                                                                <p className='name'>{this.state.candidates[0].username}</p>
                                                                <p>{t("leaderboard.betting")}:</p>
                                                                <p className='number pb-3'>{this.state.candidates[0].total_win}</p>
                                                                <p>{t("leaderboard.prize")}:</p>
                                                                <p className='number bonus'>{this.state.candidates[0].prize}</p>
                                                            </div>
                                                        </div>
                                                        <div className='second-card'>
                                                            <span className='lazy-load-image-background'>
                                                                <img className='emblem' src={img["top2-logo.webp"]} alt="top" />
                                                            </span>
                                                            <div className='winner'>
                                                                <p className='name'>{this.state.candidates[1].username}</p>
                                                                <p>{t("leaderboard.betting")}:</p>
                                                                <p className='number pb-3'>{this.state.candidates[1].total_win}</p>
                                                                <p>{t("leaderboard.prize")}:</p>
                                                                <p className='number bonus'>{this.state.candidates[1].prize}</p>
                                                            </div>
                                                        </div>
                                                        <div className='third-card'>
                                                            <span className='lazy-load-image-background'>
                                                                <img className='emblem' src={img["top3-logo.webp"]} alt="top" />
                                                            </span>
                                                            <div className='winner'>
                                                                <p className='name'>{this.state.candidates[2].username}</p>
                                                                <p>{t("leaderboard.betting")}:</p>
                                                                <p className='number pb-3'>{this.state.candidates[2].total_win}</p>
                                                                <p>{t("leaderboard.prize")}:</p>
                                                                <p className='number bonus'>{this.state.candidates[2].prize}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='col-7'>
                                                        <div className='d-flex align-item-center justify-content-center mb-3'>
                                                            <img src={img["paddy.webp"]} alt="deco" />
                                                            <h1>{t("leaderboard.winner-list-2")}</h1>
                                                            <img src={img["paddy.webp"]} alt="deco" style={{ transform: 'scaleX(-1)' }} />
                                                        </div>

                                                        <div className='events-table-container card-shadow'>
                                                            <table className='table table-borderless events-table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t("leaderboard.ranking")}</th>
                                                                        <th>{t("leaderboard.winner")}</th>
                                                                        <th>{t("leaderboard.betting")}</th>
                                                                        <th>{t("leaderboard.prize")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.candidates.slice(3, 10).map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td>{index + 4}</td>
                                                                                <td>{item.username}</td>
                                                                                <td className='number'>{item.total_win}</td>
                                                                                <td className='number bonus'>{item.prize}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='row mt-5'>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-item-center justify-content-center mb-3'>
                                                            <img src={img["paddy.webp"]} alt="deco" />
                                                            <h1>{t("leaderboard.winner-list-3-1").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</h1>
                                                            <img src={img["paddy.webp"]} alt="deco" style={{ transform: 'scaleX(-1)' }} />
                                                        </div>
                                                        <div className='events-table-container card-shadow'>
                                                            <table className='table table-borderless events-table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t("leaderboard.ranking")}</th>
                                                                        <th className='w-75'>{t("leaderboard.winner")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.candidates.slice(10, 20).map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td>{index + 11}</td>
                                                                                <td className='w-75'>{item.username}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                    <div className='col-6'>
                                                        <div className='d-flex align-item-center justify-content-center mb-3'>
                                                            <img src={img["paddy.webp"]} alt="deco" />
                                                            <h1>{t("leaderboard.winner-list-3-2").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</h1>
                                                            <img src={img["paddy.webp"]} alt="deco" style={{ transform: 'scaleX(-1)' }} />
                                                        </div>
                                                        <div className='events-table-container card-shadow'>
                                                            <table className='table table-borderless events-table'>
                                                                <thead>
                                                                    <tr>
                                                                        <th>{t("leaderboard.ranking")}</th>
                                                                        <th className='w-75'>{t("leaderboard.winner")}</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {
                                                                        this.state.candidates.slice(20, 30).map((item, index) => (
                                                                            <tr key={index}>
                                                                                <td>{index + 21}</td>
                                                                                <td className='w-75'>{item.username}</td>
                                                                            </tr>
                                                                        ))
                                                                    }
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Fade>
                                    )
                                }

                                <div className='term-section mt-5'>
                                    <div className='term-container card-shadow'>
                                        <img src={tNc[`${tncImageLanguage}`]} alt="Terms Condition" />
                                        <div className='term-content'>
                                            <h4 className=''>{t("leaderboard.ranking-prize")}:</h4>
                                            <div className='events-table-container card-shadow'>
                                                <table className='table table-borderless events-table'>
                                                    <thead>
                                                        <tr>
                                                            <th>{t("leaderboard.ranking")}</th>
                                                            <th>{t("leaderboard.prize")}</th>
                                                            <th>{t("leaderboard.top-11-20")}</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>1</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize1}</td>
                                                            <td rowSpan="5" style={{ borderRight: '0' }}>{t("leaderboard.prize")} {currency[this.props.currentUser.country_id]} {this.state.activeGamePrizes.prize11_20}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>2</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize2}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>3</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize3}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>4</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize4}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>5</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize5}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>6</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize6}</td>
                                                            <td style={{ borderRight: '0' }}>{t("leaderboard.top-21-30")}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>7</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize7}</td>
                                                            <td rowSpan="5" style={{ borderRight: '0', borderBottom: '0' }}>{t("leaderboard.prize")} {currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize21_30}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>8</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize8}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>9</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize9}</td>
                                                        </tr>
                                                        <tr>
                                                            <td>10</td>
                                                            <td>{currency[this.props.currentUser.country_id]}{this.state.activeGamePrizes.prize10}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </div>

                                            <h4 className='ps-4 mt-5 mb-3'>{t('bet_and_earn.rule_of_activity')}：</h4>
                                            <ol>
                                                <li>{t("leaderboard.rule-1")}</li>
                                                <li>{t("leaderboard.rule-2")}</li>
                                                <li>{t("leaderboard.rule-3")}</li>
                                                <li>{t("leaderboard.rule-4")}</li>
                                                <li>{t("leaderboard.rule-5")}</li>
                                                <li>{t("leaderboard.rule-6")}</li>
                                                <li>{t("leaderboard.rule-7")}</li>
                                                <li>{t("leaderboard.rule-8")}</li>
                                                <li>{t("leaderboard.rule-9")}</li>
                                            </ol>
                                        </div>
                                        <img src={tNc["term-footer.png"]} alt="Terms Condition footer" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <MobileLeaderboard
                            {...this.state}
                            {...this.props}
                            switchLeaderboard={this.switchLeaderboard}
                            currency={currency}
                        />
                    )
                }
            </Auxiliary>
        )
    }
}

const mapStateToProps = state => {
    const { leaderboard } = state;

    return {
        currentUser: state.currentUser.data,
        leaderboard_data: leaderboard.leaderboard_data,
        show_winner: leaderboard.show_winner,
    };
};

const mapDispatchToProps = {
    list,
    showWinner
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Leaderboard));
