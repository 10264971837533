import React, { Component } from "react";
import { connect } from "react-redux";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import { LazyLoadImage } from 'react-lazy-load-image-component';
import { isDesktop } from 'react-device-detect';
import MobileCertificate from "./MobileCertificate";
import { withNamespaces } from "react-i18next";
export class Certificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
      triggerAnimation: true,
    };
  }

  //certificate content component START
  certificateContent = (props) => {
    let imageLanguage = '';
    switch (this.props.currentUser.data.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //   imageLanguage = "-ms"
      //   break;
      default:
        imageLanguage = ""
        break;
    }
    return (
      <div className="position-relative">
        <span className="card-shadow">
          <img className="w-100 h-auto" src={props.img[`license-bg${imageLanguage}.webp`]} alt="license background" />
        </span>
        {/* <img className={`cert-img ${
                        this.state.triggerAnimation === true
                          ? "fade-in-left"
                          : "fade-out-left"
                      }`} src={props.img[props.cert]} alt="cert" key={props.img[props.cert]}/> */}
        <div className={`cert-img ${this.state.triggerAnimation === true
          ? "fade-in-left"
          : "fade-out-left"
          }`}>
          {/* <LazyLoadImage src={props.img[props.cert]} alt="cert" key={props.img[props.cert]} effect="blur" /> */}
          <img src={props.img[props.cert]} alt="cert" key={props.img[props.cert]} />
        </div>
        <div className={`cert-content-container ${this.state.triggerAnimation === true
          ? "fade-in-right"
          : "fade-out-right"
          }`}>
          {props.header}
          {props.content}
        </div>
      </div>
    );
  };
  //certificate content component END

  updateActiveCert = (num) => {
    this.removeAnimation();
    setTimeout(() => {
      this.setState({
        activePage: num,
        triggerAnimation: true,
      });
    }, 500)
  };

  removeAnimation = () => {
    this.setState({
      triggerAnimation: false
    })
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/certificate",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    return (
      <Auxiliary>
        {
          isDesktop ? (
            <section style={{ backgroundImage: `url(${img["cert-bg.webp"]} alt="cert bg")` }}>
              <div className="x-container py-5">
                <div className="cert-page-btn">
                  <ul>
                    <li
                      className={`cert-btn ${this.state.activePage === 1 ? "active" : ""
                        }`}
                      onClick={() => this.updateActiveCert(1)}
                    >
                      PAGCOG
                    </li>
                    <li
                      className={`cert-btn ${this.state.activePage === 2 ? "active" : ""
                        }`}
                      onClick={() => this.updateActiveCert(2)}
                    >
                      Isle of Man Government
                    </li>
                    <li
                      className={`cert-btn ${this.state.activePage === 3 ? "active" : ""
                        }`}
                      onClick={() => this.updateActiveCert(3)}
                    >
                      First Cagayan
                    </li>
                  </ul>
                </div>

                {this.state.activePage === 1 ? (
                  <this.certificateContent
                    img={img}
                    cert={"pagcor-cert.webp"}
                    header={
                      <h1>
                        PAGCOR <br /> {t("gaming-license.gaming-license")}
                      </h1>
                    }
                    content={
                      <p>
                        {t("gaming-license.pagcor-content-1")}
                        <br />
                        <br />
                        {t("gaming-license.pagcor-content-2")}
                        <br />
                        <br />
                        {t("gaming-license.pagcor-content-3")}
                        <br />
                        <br />
                        {t("gaming-license.pagcor-content-4")}
                        <br />
                        <br />
                        {t("gaming-license.pagcor-content-5")}
                        <br />
                        <br />
                      </p>
                    }
                  />
                ) : this.state.activePage === 2 ? (
                  <this.certificateContent
                    img={img}
                    cert={"isle-cert.webp"}
                    header={
                      <h1>
                        Isle of Man Government <br /> {t("gaming-license.gaming-license")}
                      </h1>
                    }
                    content={
                      <p>
                        {t("gaming-license.iomg-content-1")}
                        <br />
                        <br />
                        {t("gaming-license.iomg-content-2")}
                        <br />
                        <br />
                        {t("gaming-license.iomg-content-3")}
                        <br />
                        <br />
                        {t("gaming-license.iomg-content-4")}
                        <br />
                        <br />
                        {t("gaming-license.iomg-content-5")}
                        <br />
                        <br />
                      </p>
                    }
                  />
                ) : this.state.activePage === 3 ? (
                  <this.certificateContent
                    img={img}
                    cert={"cagayan-cert.webp"}
                    header={
                      <h1>
                        First Cagayan <br /> {t("gaming-license.gaming-license")}
                      </h1>
                    }
                    content={
                      <p>
                        {t("gaming-license.fc-content-1")}
                        <br />
                        <br />
                        {t("gaming-license.fc-content-2")}
                        <br />
                        <br />
                        {t("gaming-license.fc-content-3")}
                        <br />
                        <br />
                        {t("gaming-license.fc-content-4")}
                        <br />
                        <br />
                        {t("gaming-license.fc-content-5")}
                      </p>
                    }
                  />
                ) : (
                  <div></div>
                )}
              </div>
            </section>
          ) : (
            <MobileCertificate />
          )
        }

      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
};


export default connect(mapStateToProps)(withNamespaces("translation")(Certificate));
