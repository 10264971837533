import React, { Component } from 'react';
import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import MallCheckbox from './MallCheckbox';
import { Link } from 'react-router-dom';
import Skeleton from "react-loading-skeleton";
import { Fade, Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

export class MobileX33MallCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    // const img = this.imageImport(require.context("../../assets/images/x33mall/", false, /\.(png|jpe?g|svg)$/));

    const fadeUp = keyframes`
      from {
        opacity: 0;
        transform: translate3d(0, 50px ,0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    `;

    return (
      <Auxiliary>
        <section className="x33mall checkout cart">
          <div className='ei-m-section'>
            <div className="pf-m-dashboard">
              <MobileSubHeader
                // className="bg-white"
                returnPage="/x33mall"
                header={t("x33-mall.cart")}
                subPage='/'
              />
              {
                !this.props.initiated ? (
                  <Skeleton count={4} />
                ) : (
                  <Fade duration={1000}>
                    <div className="input-group-col mt-3">
                      <small className="mb-1">{t("x33-mall.your-order")}</small>
                      {this.props.products && this.props.products.map((product, idx) => {
                        return (
                          <MallCheckbox
                            {...product}
                            {...this.props}
                            idx={idx}
                            handleCheckChildElement={this.props.handleCheckChildElement}
                            minusProductCount={this.props.minusProductCount}
                            addProductCount={this.props.addProductCount}
                            removeProduct={this.props.removeProduct}
                          />
                        );
                      })}
                    </div>
                  </Fade>
                )
              }
            </div>
          </div>
          <div className="mall-fade-col">
            <Reveal keyframes={fadeUp} duration={1000} triggerOnce>
              <div className='mall-fade-body'>
              <div className='mall-fade-cont'>
                <div>
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    onClick={this.props.handleAllChecked}
                    value="checkedAll"
                  />
                  <label htmlFor="checkAll">
                  {t("global.select-all")}
                  </label>
                </div>
                <p>{t("x33-mall.total")}({this.props.totalItem} {t("x33-mall.items")}) : <span className='fw-bold'>{this.props.totalCoin} {t("x33-mall.coins")}</span></p>
              </div>
              <div className='mall-fade-cont'>
                <Link className="btn btn-gradient-blue w-100 submit-btn"
                  to={{ pathname: "/x33mall/checkout" }}>
                  {t("x33-mall.checkout")}
                </Link>
              </div>
              </div>
            </Reveal>
          </div>
        </section>
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileX33MallCart));