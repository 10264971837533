import React, { Component } from 'react'
import Modal from "react-modal";
import { Icon } from "@iconify/react";
import Skeleton from "react-loading-skeleton";
import Slider from 'react-slick';
// import NewFeature from './NewFeature';
// import { isDesktop } from 'react-device-detect';

export class BackupUrlModal extends Component {
  constructor(props) {
    super(props);
    const t = this.props.t;
    this.state = {
      header: t("global.new-feature-added"),
      slideCount: 0,
    };
    this.sliderRef = React.createRef();
  }

  componentDidUpdate() {
    // const t = this.props.t;
    const slideCount = this.sliderRef.current?.innerSlider?.state?.slideCount;
    if (slideCount && slideCount !== this.state.slideCount) {
      this.setState({ slideCount });
    }
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const t = this.props.t;
    const icons = this.imageImport(require.context("../../assets/images/icons", false, /\.(gif|png|jpe?g|svg)$/));
    const settings = {
      dots: true,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      // autoplay: true,
      autoplaySpeed: 3000,
      cssEase: "linear",
      beforeChange: (oldIndex, newIndex) => {
        switch (newIndex) {
          case 0:
            this.setState({ header: t("global.new-feature-added") })
            break;
          case this.state.slideCount - 1:
            this.setState({ header: t("global.cant-open-r77-how") })
            break;
          default:
            this.setState({ header: t("global.new-feature-added") })
            break;
        }
      }
    };

    return (

      <Modal
        isOpen={this.props.showBackupUrlModal}
        closeTimeoutMS={500}
        portalClassName={`global-modal`}
        className="modal modal-dialog modal-dialog-centered modal-pd vip-link-modal"
        ariaHideApp={false}
        onRequestClose={this.props.toggleBackupUrlModal}
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="vip-link-modal-label">
              {this.state.header}
            </h5>
            <Icon type="button" className="btn-close text-white" icon="mdi:close" onClick={this.props.toggleBackupUrlModal} />
          </div>

          <form className="py-3" onSubmit={this.props.handleSubmit}>
            <Slider {...settings} ref={this.sliderRef}>

              {/* <NewFeature
                featureHeader={t("global.redeem-bonus-way")}
                featureDesc={t("global.redeem-bonus-way-1")}
                featureImg={isDesktop ? ["redeem-bonus1-1.webp", "redeem-bonus1-2.webp"] : ["redeem-bonus1-m-1.webp", "redeem-bonus1-m-2.webp", "redeem-bonus1-m-3.webp"]}
                linkBtn={"/personal-information"}
                {...this.props}
              />

              <NewFeature
                featureHeader={t("global.redeem-bonus-way")}
                featureDesc={t("global.redeem-bonus-way-2")}
                featureImg={isDesktop ? ["redeem-bonus2-1.webp", "redeem-bonus2-2.webp"] : ["redeem-bonus2-m-1.webp", "redeem-bonus2-m-2.webp"]}
                linkBtn={"/personal-information"}
                {...this.props}
              />

              <NewFeature
                featureHeader={t("global.whatsapp-notification-setting")}
                featureDesc={t("global.whatsapp-feature-desc")}
                featureImg={isDesktop ? ["whatsapp-notice-1.webp", "whatsapp-notice-2.webp"] : ["whatsapp-notice-m-1.webp", "whatsapp-notice-m-2.webp"]}
                linkBtn={"/messages"}
                {...this.props}
              /> */}

              <div>
                <div className="modal-body">
                  <h6>{t("global.one-click-bookmark")}</h6>
                  <img src={icons["change-link-img.svg"]} alt="change link img" />
                  <hr />

                  <h5 className="modal-title" id="vip-link-modal-label">
                    {t("global.personal-vip-domain")}
                  </h5>
                  <ul>
                    {(() => {
                      this.props.backupUrlLoading === true && <Skeleton count={4} height="100%" />;

                      if (this.props.backupUrlLoading === false) {
                        return (
                          <>
                            <li
                              className={`btn btn-outline-blue ${this.props.backupUrlValue === process.env.REACT_APP_PUBLIC_URL && "active"}`}
                              onClick={(e) => this.props.redirectBackupUrlHandler(e, process.env.REACT_APP_PUBLIC_URL)}
                            >
                              <Icon icon="uil:globe" />
                              <span>{process.env.REACT_APP_PUBLIC_URL}</span>
                            </li>
                          </>
                        );
                      }
                    })()}
                  </ul>
                </div>
              </div>
            </Slider>
            {this.props.backupUrlLoading === false ? (
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-gradient-blue" onClick={this.redirectBackupUrlHandler}>
                                    {t("global.confirm")}
                                </button> */}
                <div className="d-flex align-items-center w-100 px-3">
                  <input type="checkbox" id="dont-show-check" value="showModal" onChange={(e) => this.props.setDontShow(e)} />
                  <label className="ms-1" htmlFor="dont-show-check">
                    {t("global.dont-ask-again")}
                  </label>
                </div>
              </div>
            ) : (
              <Skeleton count={1} height="100%" />
            )}
          </form>

        </div>
      </Modal>

    )
  }
}

export default BackupUrlModal