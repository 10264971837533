import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous 
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { default as ReactSelect } from "react-select";
import { Icon } from "@iconify/react";
import Modal from "react-modal";
import DomPurify from "../../../components/Sanitize/DomPurify";

import { isDesktop } from "react-device-detect";

//Components 
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import { storedDepositDropdownValidation } from "../../../common/config/depositDataValidation";
import StringInput from "../../../components/Input/StringInput/StringInput";

//Actions 
import * as actions from "../../../store/ducks/deposit/actions";
import MobileEWallet from "./MobileEWallet";
import DoItNowDepositModal from "../../../components/DoItNowDepositModal/DoItNowDepositModal";
import * as CryptoJS from "crypto-js";
import moment from "moment";

const depositAmts = [
    { value: 30 }, { value: 50 }, { value: 100 }, { value: 300 }, { value: 500 }, { value: 1000 }, { value: 5000 }
];

let minTransactionAmt;
let maxTransactionAmt;

class EWallet extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            this.setState({ files })
        }
        this.state =
        {
            promoOpts: "",
            depositAmt: "",
            files: [],
            input:
            {
                amount: "",
                promo_id: "",
            },
            dropDown:
            {
                groupSetting: [],
                promoList: [],
                lastDeposit: 0,
                paymentGatewayOptions: []
            },
            loading: false,
            errorMsg: [],
            formError: false,
            copyText: "",

            openPromoTncModal: false,
            showPromosTitle: "",
            showPromosContent: "",
            showPromosTable: "",
            selectedPaymentGateway: '',
            selectedPaymentTypeID: '',

            ewalletOption: [],
            ewalletCode: '',

            openDoItNowModal: false,
            doitnowFormInput: [],
            
            isOptionLoading: true
        }
        this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
    }

    componentDidMount() {
        formValidation("deposit_form");
        this.props.funcGetDropDown();

        let data = {};
        data.transType = "DEPOSIT";
        data.currencyCode = this.props.currentUser.currency;
        this.props.funcEwalletTypeOptions(data);

        this.fetchStorageDeposit();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            this.setState({
                isOptionLoading: false
            });

            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                let to_store_data = storedDepositDropdownValidation(this.props.default_dropdown_options, nextProps.dropDown.data);
                if(to_store_data.overwrite === true){
                    let newInput = Object.assign({}, this.state.input);

                    let paymentGatewayOptions = [];
                    if (nextProps.dropDown.data.payment_gateway) {
                        let ewalletGateway = nextProps.dropDown.data.payment_gateway.filter((val) => val.transaction_slug === "e-wallet");
                        ewalletGateway.forEach((val) => {
                            if (val.transaction_slug === 'e-wallet' && val.dp_status === 1) {
                                paymentGatewayOptions.push(val);

                                if (this.state.selectedPaymentGateway === '') {
                                    this.setState({
                                        // selectedPaymentGateway: val.slug
                                        selectedPaymentGateway: ewalletGateway[0].slug // default first
                                    });
                                }

                                if (this.state.selectedPaymentTypeID === '') {
                                    this.setState({
                                        // selectedPaymentTypeID: val.id
                                        selectedPaymentTypeID: ewalletGateway[0].id // default first
                                    });
                                }

                                minTransactionAmt = ewalletGateway[0].transaction_minimum_payment_amount; //val.transaction_minimum_payment_amount;
                                maxTransactionAmt = ewalletGateway[0].transaction_maximum_payment_amount; //val.transaction_maximum_payment_amount;
                            }
                        });
                    }

                    this.setState({
                        input: newInput,
                        dropDown: {
                            paymentGatewayOptions: paymentGatewayOptions,
                            groupSetting: nextProps.dropDown.data.member_group,
                            promoList: nextProps.dropDown.data.promotion,
                            lastDeposit: (nextProps.dropDown.data.last_deposit || 0)
                        },
                        loading: false,
                    });

                    const storeDropdown = {};
                    storeDropdown.data = nextProps.dropDown.data;
                    this.props.funcDefaultDropDownOptions(storeDropdown);
                }
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === "success") {
                if (nextProps.store.data.p_url) //Fpay
                {
                    window.location.href = nextProps.store.data.p_url;
                }
                else if (nextProps.store.data.deposit_url) // SecretPay
                {
                    window.location.href = nextProps.store.data.deposit_url;
                }
                else if(nextProps.store.data.doitnow_url){ // DoItNow
                    Swal.close();
                    
                    clearFormValidation("deposit_form");
                    let newInput = Object.assign({}, this.state.input);
                    newInput["amount"] = "";
                    newInput["bank_last_five_digit"] = "";

                    this.setState({
                        depositAmt: null,
                        input: newInput,
                        errorMsg: [],
                        formError: false,
                        doitnowFormInput: nextProps.store.data,
                        openDoItNowModal: true
                    });
                }
                else // bank transfer
                {
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                        icon: "success",
                        title: t('global.success'),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        timer: 2000,
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container sucess',
                            icon: 'swal-success-icon'
                        } : [],
                    }).then(() => {
                        clearFormValidation("deposit_form");
                        let newInput = Object.assign({}, this.state.input);
                        newInput["amount"] = "";
                        newInput["bank_last_five_digit"] = "";

                        this.setState({
                            depositAmt: null,
                            input: newInput,
                            errorMsg: [],
                            formError: false,
                        });

                        this.props.redirectHandler();
                    });
                }
            }
            else {
                clearFormValidation("deposit_form");
                const { t } = this.props;

                if (nextProps.store.message.input_error) {
                    this.setState({
                        errorMsg: (nextProps.store.message.input_error || []),
                        formError: true,
                    });
                    Swal.close();
                }
                else {
                    let msg = null;
                    msg = '<div>';
                    nextProps.store.message.forEach((val) => {
                        msg += '<p>' + val + '</p>';
                    });
                    msg += '</div>';

                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                        icon: 'error',
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container sorry',
                            icon: 'swal-sorry-icon'
                        } : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (nextProps.store.redirect && nextProps.store.redirect !== '' && nextProps.store.redirect !== null) {
                                window.location.href = nextProps.store.redirect;
                            }

                        }
                    });
                }
            }
        }

        if (nextProps.ewallet_type_option !== this.props.ewallet_type_option) {
            if (nextProps.ewallet_type_option.responseCode === 200) {
             
                    this.setState({
                        isOptionLoading: false,
                        ewalletOption: nextProps.ewallet_type_option.data,
                        ewalletCode: (nextProps.ewallet_type_option.data.length > 0) ? nextProps.ewallet_type_option.data[0].walletCode : ''
                    });

                
            }
            else {
                return false;
            }
        }

        return true;
    }

    fetchStorageDeposit = () => {
        if(this.props.default_dropdown_options !== undefined){
            let newInput = Object.assign({}, this.state.input);

            let paymentGatewayOptions = [];
            if (this.props.default_dropdown_options.data.payment_gateway) {
                let ewalletGateway = this.props.default_dropdown_options.data.payment_gateway.filter((val) => val.transaction_slug === "e-wallet");
                ewalletGateway.forEach((val) => {
                    if (val.transaction_slug === 'e-wallet' && val.dp_status === 1) {
                        paymentGatewayOptions.push(val);

                        if (this.state.selectedPaymentGateway === '') {
                            this.setState({
                                selectedPaymentGateway: ewalletGateway[0].slug
                            });
                        }

                        if (this.state.selectedPaymentTypeID === '') {
                            this.setState({
                                selectedPaymentTypeID: ewalletGateway[0].id
                            });
                        }

                        minTransactionAmt = ewalletGateway[0].transaction_minimum_payment_amount;
                        maxTransactionAmt = ewalletGateway[0].transaction_maximum_payment_amount;
                    }
                });
            }

            this.setState({
                input: newInput,
                dropDown: {
                    paymentGatewayOptions: paymentGatewayOptions,
                    groupSetting: this.props.default_dropdown_options.data.member_group,
                    promoList: this.props.default_dropdown_options.data.promotion,
                    lastDeposit: (this.props.default_dropdown_options.data.last_deposit || 0)
                },
                loading: false,
                isOptionLoading: false
            });
        }
    }

    onInputChangeHandler = (event) => {
        // console.log(event.target.name+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        let depositAmt = (event.target.name === "depositAmt") ? event.target.value : this.state.depositAmt;
        
        if (event.target.name === "bonusCode") {
            event.target.value = event.target.value.toUpperCase();
        }
        
        newInput[event.target.name] = event.target.value;

        if (event.target.name === "depositAmt") {
            newInput["amount"] = event.target.value;
        }

        if (event.target.name.includes('amount')) {
            const splitedValue = event.target.value.split('.')
            if (splitedValue[1] && splitedValue[1].length > 2) {
                let val = parseFloat(event.target.value).toFixed(2);
                newInput[event.target.name] = val;
            }
            depositAmt = '';
        }

        this.setState({
            input: newInput,
            depositAmt: depositAmt,
            errorMsg: [],
            formError: false,
        });
    }

    removeFileHandler = () => {
        this.setState({
            files: [],
            // progress: 0,
        });
    }

    promoChangeHandler = (event) => {
        let newInput = Object.assign({}, this.state.input);
        newInput['promo_id'] = (event === null || event.id === '') ? '' : event.id;

        let showBonus = this.state.dropDown.promoList.filter((val) => event !== null && val.id === event.id);
        let showBonusTitle = showBonus && showBonus[0] && showBonus[0].title ? showBonus[0].title : '';
        let showBonusContent = showBonus && showBonus[0] && showBonus[0].content ? showBonus[0].content : '';

        this.setState({
            promoOpts: (event !== null && event.id > 0) ? event : '',
            input: newInput,
            showPromosTitle: showBonusTitle,
            showPromosContent: showBonusContent
        });
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    copyToClipboard = (e, ref) => {
        const { t } = this.props;
        ref.select();
        document.execCommand('copy');
        e.target.focus();
        this.setState({ copySuccess: `${t('global.copied')}!`, copyText: ref.name });
        setTimeout(
            () => this.setState({ copyText: "" }),
            1000
        );
    }

    selectPaymentGatewayHandler = (val) => {
        this.state.dropDown.paymentGatewayOptions.map((item) => {
            return null;
        });
        this.setState({
            selectedPaymentGateway: val.slug,
            selectedPaymentTypeID: val.id
        });
        // update min & max transaction amt based on selected
        minTransactionAmt = val.transaction_minimum_payment_amount;
        maxTransactionAmt = val.transaction_maximum_payment_amount;
    }

    confirmHandler = (e) => {
        e.preventDefault();
        if (this.state.input.promo_id > 0) {
            const { t } = this.props;
            let pickedBonus = this.state.dropDown.promoList.filter((val, idx) => {
                if (val.id === this.state.input.promo_id) {
                    return val;
                }
                return null;
            });
            
            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
            let bonusTitle = pickedBonus && pickedBonus[0] && pickedBonus[0].title ? pickedBonus[0].title : "";
            let bonusTurnover = pickedBonus && pickedBonus[0] && pickedBonus[0].turnover_multiply ? pickedBonus[0].turnover_multiply : 0;
            let bonusPercentage = pickedBonus && pickedBonus[0] && pickedBonus[0].percentage ? pickedBonus[0].percentage : 0;
            let bonusMaxPayout = pickedBonus && pickedBonus[0] && pickedBonus[0].max_payout ? pickedBonus[0].max_payout : 0;
            let promotionAmt = (parseFloat(this.state.input.amount) * parseFloat(bonusPercentage)) / 100;
            let bonusAmt = promotionAmt > parseFloat(bonusMaxPayout) ? bonusMaxPayout : promotionAmt;
            let totalRollover = (parseFloat(this.state.input.amount) + parseFloat(bonusAmt)) * bonusTurnover;
            let htmlText = '<div>' + t("deposit.deposit-warning-msg-1").replace("_promotion_", bonusTitle).replace("_turnover_", bonusTurnover) + '</div><br>'
                + '<div style="text-align: left">' + t("deposit.deposit-warning-msg-2") + `: ${this.props.currentUser.currency} ${this.state.input.amount}</div>`
                + '<div style="text-align: left">' + t("deposit.deposit-warning-msg-3") + `: ${bonusPercentage}%</div>`
                + '<div style="text-align: left">' + t("deposit.deposit-warning-msg-4") + `: ${this.props.currentUser.currency} ${new Intl.NumberFormat("en-US", num_format).format(totalRollover)}</div>`;
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : '',
                title: t("validation.promotion_confirmation"),
                html: htmlText,
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: (!isDesktop) ? {
                    container: "swal-mobile-container reminder",
                    icon: "swal-reminder-icon",
                } : [],
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitHandler();
                }
            });
        }
        else {
            this.submitHandler();
        }
    }

    submitHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        let formData = new FormData();
        Object.keys(this.state.input).forEach((val) => {
            formData.append(val, this.state.input[val]);
        });

        formData.append('payment_type', this.props.depositOption);
        formData.append('transaction_channel_id', this.props.transactionChannelId);
        formData.append('payment_gateway', this.state.selectedPaymentGateway);
        formData.append('currency', this.props.currentUser.currency);
        formData.append('email', this.props.currentUser.email);
        formData.append('phone_number', this.props.currentUser.contact_no);
        formData.append('promo_id', this.state.input.promo_id);
        formData.append('redirect_url', window.location.host + '/transaction-history');
        formData.append('payment_type_id', this.state.selectedPaymentTypeID);
        formData.append('pg_min_deposit', minTransactionAmt);
        formData.append('pg_max_deposit', maxTransactionAmt);
        formData.append('platform', this.props.platform);
        
        if(this.state.selectedPaymentGateway === "doitnow_ewallet"){
            formData.append("returnUrl", window.location.host + '/transaction-history');
            formData.append("callbackUrl", process.env.REACT_APP_PAYMENT_GATEWAY_URL + '/payment_gateway/doitnow/deposit_success');
        }

        if(this.state.selectedPaymentGateway === "secret_pay"){
            formData.append('ownerBy', this.props.currentUser.id);
            formData.append('callbackUrl', process.env.REACT_APP_PAYMENT_GATEWAY_URL + '/payment_gateway/secret_pay/deposit_success');
            formData.append('failedRedirectURL', window.location.origin + '/transaction-history');
            formData.append('successRedirectURL', window.location.origin + '/transaction-history');
            formData.append('walletCode', this.state.ewalletCode);
        }

        // HASHING ALGO
        formData.append("trxTime",moment().unix());
        let toHashStr = "";
        let hashStr = "";
        let paramsKey = [];
        for (var to_hash of formData.entries()){
            if(!paramsKey.includes(to_hash[0])){
                paramsKey.push(to_hash[0]);
            }
        }
        paramsKey.sort();
        paramsKey.forEach((val) => {
            let x = formData.get(val);
            if(x !== ''){
                toHashStr += val + x;
            }
        });
        toHashStr += process.env.REACT_APP_HASH_SECRET;
        hashStr = CryptoJS.MD5(toHashStr).toString();
        formData.append("trxHash",hashStr);

        // for (var pair of formData.entries()) {
        //     console.log("FORMDATA - ", pair[0]+ ', ' + pair[1]); 
        // }

        this.props.funcStore(formData);
    }

    toggleTncModalHandler = () => {
        this.setState({
            openPromoTncModal: !this.state.openPromoTncModal,
        });
    };

    toggleDoItNowModalHandler = () => {
        this.setState({
          openDoItNowModal: !this.state.openDoItNowModal
        });
        
        document.getElementById("doitnow_form").submit();
        this.props.redirectHandler();
    }

    selectEwalletOption = (val) => {
        this.setState({
            ewalletCode: val.walletCode
        });
    }

    render() {
        const { t } = this.props;

        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        
        const payment = this.imageImport(require.context("../../../assets/images/profile/payment", false, /\.(png|jpe?g|svg)$/));
        const tncImg = this.imageImport(require.context("../../../assets/images/tnc/mobile", false, /\.(png|jpe?g|svg)$/));

        let promoArry = [];
        let groupSetting = this.state.dropDown.groupSetting ? this.state.dropDown.groupSetting : [];
        let lastDeposit = (this.state.dropDown.lastDeposit.last_deposit || 0);

        let files = this.state.files.map(file => (
            <li key={file.name}>
                <p>
                    {file.name} - {Math.round(file.size / 1024)} KB{" "}
                    <button className="btn icon" onClick={this.removeFileHandler}>
                        <Icon icon="mdi:close" />
                    </button>
                </p>
            </li>
        ));

        if (this.state.dropDown.promoList && !this.state.loading) {
            if(isDesktop)
            {
                promoArry.push({
                    id: '',
                    label: t("global.none"),
                    key: '',
                });
            }
            // promoArry = this.state.dropDown.promoList.map((val, idx) => {
            //     return {
            //         id: val.id,
            //         label: val.title,
            //         key: idx,
            //     };
            // });
            this.state.dropDown.promoList.map((val, idx) => {
                return promoArry.push({
                    id: val.id,
                    label: val.title,
                    key: idx,
                });
            });            
        }
        return (
            (isDesktop) ? (
                <>
                <DoItNowDepositModal 
                    {...this.state}
                    {...this.props}
                    toggleDoItNowModalHandler={this.toggleDoItNowModalHandler}
                />
                <form className="forms-col needs-validation" method="post" id="deposit_form" encType='multipart/form-data' onSubmit={this.confirmHandler} noValidate>
                    {this.state.loading ? (
                        <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                    ) : (
                        <>
                            {this.state.openPromoTncModal && (
                                <Modal
                                    isOpen={this.state.openPromoTncModal}
                                    closeTimeoutMS={500}
                                    portalClassName={`global-modal`}
                                    className="modal modal-dialog modal-dialog-centered modal-dialog-scrollable  modal-pd"
                                    ariaHideApp={false}
                                    onRequestClose={this.toggleTncModalHandler}>
                                    <div className="modal-content tnc-content">
                                        <div className="modal-header">
                                            <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.toggleTncModalHandler} />
                                        </div>
                                        <div className="modal-body table-responsive">
                                            <section className="promos-table-section">
                                                <div className="promos-table-container p-0">
                                                    <h2>{this.state.showPromosTitle}</h2>
                                                    {this.state.showPromosTable !== null && (
                                                        <span className="card-shadow">
                                                            <DomPurify text={this.state.showPromosTable} />
                                                        </span>
                                                    )}
                                                </div>
                                            </section>

                                            <section className="term-section mt-3">
                                                <div className="term-container p-0">
                                                    <img src={tncImg["term-condition.svg"]} alt="Terms Condition" />
                                                    <div className="term-content">
                                                        <DomPurify text={this.state.showPromosContent} />
                                                    </div>
                                                    <img src={tncImg["term-footer-m.jpg"]} alt="Terms Footer" />
                                                </div>
                                            </section>
                                        </div>
                                    </div>
                                </Modal>
                            )}
                            <div className="row">
                                <label className="col-3 col-form-label">{t('deposit.payment-option')}:</label>
                                <div className="col-9 select-bank-opts">
                                    {this.state.dropDown.paymentGatewayOptions.length > 0 ? (
                                        this.state.dropDown.paymentGatewayOptions.map((val, idx) => {
                                            return (
                                                <div className="form-check form-check-inline" key={idx}>
                                                    <input
                                                        className="btn-check"
                                                        type="radio"
                                                        id={`payment${val.slug}`}
                                                        value={val.slug}
                                                        checked={this.state.selectedPaymentGateway === val.slug ? true : false}
                                                        onChange={() => { }}
                                                    />
                                                    <label className="btn form-check-label" htmlFor={`payment${val.slug}`} onClick={() => this.selectPaymentGatewayHandler(val)}>
                                                        <img className="active" src={payment[`${val.slug==="doitnow_ewallet"?'doitnow':val.slug}.svg`]} alt={val.slug} />
                                                        <p>{val.slug.replaceAll('_', ' ')}</p>
                                                        {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                            <div className="transfer-duration">
                                                                {/* <Icon icon="ic:baseline-alarm" />
                                                                Deposit in 1 min */}
                                                                {val.deposit_est_time === "00:00:00" ? (
                                                                <>
                                                                    <Icon icon="ant-design:thunderbolt-outlined" />
                                                                        {t("deposit.instant-deposit")}
                                                                </>
                                                                ) : (
                                                                <>
                                                                    <Icon icon="ic:baseline-alarm" />
                                                                        {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                </>
                                                                )}
                                                                                    
                                                            </div>                 
                                                        )}
                                                    </label>
                                                </div>
                                            )
                                        })
                                    ) : (
                                        this.state.isOptionLoading === false ?
                                        <span className="text-danger">{t('deposit.invalid-payment-gateway')}</span> :
                                        <Skeleton />
                                    )}
                                </div>
                            </div>

                            {this.state.selectedPaymentGateway === 'secret_pay' && (
                                <div className="row">
                                    <label className="col-3 col-form-label">{t('deposit.choose-a-ewallet-type')}:</label>
                                    <div className="col-9 align-self-center">
                                        {(this.state.ewalletOption.length > 0 && this.state.ewalletOption.length > 0) ? (
                                            <div className="form-check-group select-bank-opts">
                                                {this.state.ewalletOption.map((val, idx) => {
                                                    return (
                                                        <div className="form-check form-check-inline" key={idx}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="bank_code"
                                                                id={`bankOpt${val.walletCode}`}
                                                                value={val.walletCode}
                                                                checked={this.state.ewalletCode === val.walletCode ? true : false}
                                                                onChange={() => { }}
                                                            />
                                                            <label className="btn form-check-label" htmlFor={`bankOpt${val.walletCode}`} onClick={() => this.selectEwalletOption(val)}>
                                                                <img className="active" src={payment[`${val.walletCode==="DUITNOW"?'duitnow':val.walletCode}.svg`]} alt={val.walletCode} />
                                                                <p>{val.walletName}</p>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            this.state.loadingBank === false ? 
                                            <span className="text-danger">{t('deposit.invalid-bank')}</span> :
                                            <Skeleton />
                                        )
                                        }
                                    </div>
                                </div>
                            )}

                            <div className="row">
                                <label className="col-3 col-form-label">{t('deposit.promotion')}:</label>
                                <div className="col-9">
                                    <ReactSelect
                                        classNamePrefix="react-select"
                                        className="w-100"
                                        placeholder={t('history.select-promotion')}
                                        name="selectPromo"
                                        options={promoArry}
                                        onChange={this.promoChangeHandler}
                                        value={this.state.promoOpts}
                                        // isClearable={true}
                                    />
                                    {
                                        this.state.promoOpts && this.state.promoOpts.id &&
                                        <p className="tnc-text" onClick={this.toggleTncModalHandler}> {t('deposit.tnc-apply')} </p>
                                    }
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-3 col-form-label">{t('transaction-type.bonus-code')}</label>
                                <div className="col-9 d-grid gap-2">
                                    <div className="input-group left has-validation">
                                    <StringInput
                                        id="bonusCode"
                                        name="bonusCode"
                                        className={`form-control ${this.state.errorMsg.bonusCode && 'was-validated is-invalid'}`}
                                        value={this.state.input.bonusCode}
                                        placeholder= {`${t("global.insert")} ${t("transaction-type.bonus-code")}`}
                                        onChange={(event) => this.onInputChangeHandler(event)}
                                        formError={this.state.formError}
                                        errorMsg={this.state.errorMsg.bonusCode || ''}
                                        autoComplete="off"
                                    />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <label className="col-3 col-form-label">{t('deposit.deposit-amount')}:</label>
                                <div className="col-9 d-grid gap-2">
                                    <div className="input-group left has-validation">
                                        <span className="input-group-text">{this.props.currentUser.currency}</span>
                                        <NumberInput
                                            id="amount"
                                            name="amount"
                                            // className="form-control"
                                            className={`form-control ${this.state.errorMsg.amount && 'was-validated is-invalid'}`}
                                            aria-label="Username"
                                            aria-describedby="depositAmtHelp"
                                            value={this.state.input.amount}
                                            placeholder={`${t('deposit.min-max-limit')} ${new Intl.NumberFormat("en-US", num_format).format(minTransactionAmt || 0.0)}/${new Intl.NumberFormat("en-US", num_format).format(maxTransactionAmt || 0.0)}`}
                                            onChange={(event) => this.onInputChangeHandler(event)}
                                            required={true}
                                            min={minTransactionAmt || 0}
                                            max={maxTransactionAmt || 0}
                                            step="any"
                                            onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                            minValueMessage={t("validation.amtMinValue", { 0: minTransactionAmt })}
                                            maxValueMessage={t("validation.amtMaxValue", { 0: maxTransactionAmt })}
                                            requiredMessage={t("validation.required")}
                                            formError={this.state.formError}
                                            errorMsg={this.state.errorMsg.amount || ''}
                                        />
                                    </div>
                                    <div className="form-check-group deposit-amts">
                                        {depositAmts && depositAmts.map((depositAmt, index) => (
                                            <div className="form-check form-check-inline" key={index}>
                                                <input
                                                    className="btn-check"
                                                    type="radio"
                                                    name="depositAmt"
                                                    id={`depositAmt${depositAmt.value}`}
                                                    value={depositAmt.value}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    checked={Number(this.state.depositAmt) === Number(depositAmt.value) ? true : false}
                                                />
                                                <label className="btn form-check-label" htmlFor={`depositAmt${depositAmt.value}`}>
                                                    {depositAmt.value}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <small>{t('deposit.last-deposit-amount')}: <span style={{ fontWeight: '600' }}>{this.props.currentUser.currency}{lastDeposit}</span></small>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-9 offset-3">
                                    <div className="dropzone-container">
                                    {files && files.length ? (
                                        <aside>
                                            <ul>{files}</ul>
                                        </aside>
                                    ) : ''}
                                    </div>
                                    <div className="d-flex">
                                        <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                            {t('global.confirm')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </>
                    )}
                </form>
                </>
            ) : (
                <MobileEWallet
                    depositAmts={depositAmts}
                    onDrop={this.onDrop}
                    minTransactionAmt={minTransactionAmt}
                    maxTransactionAmt={maxTransactionAmt}

                    onInputChangeHandler={this.onInputChangeHandler}
                    promoChangeHandler={this.promoChangeHandler}
                    copyToClipboard={this.copyToClipboard}
                    confirmHandler={this.confirmHandler}
                    toggleTncModalHandler={this.toggleTncModalHandler}
                    selectPaymentGatewayHandler={this.selectPaymentGatewayHandler}
                    toggleDoItNowModalHandler={this.toggleDoItNowModalHandler}

                    numFormat={num_format}
                    promoArry={promoArry}
                    groupSetting={groupSetting}
                    lastDeposit={lastDeposit}
                    dropFiles={files}
                    paymentGatewayOptions={this.state.dropDown.paymentGatewayOptions}
                    selectedPaymentGateway={this.state.selectedPaymentGateway}
                    selectedPaymentTypeID={this.state.selectedPaymentTypeID}
                    payment={payment}

                    isOptionLoading={this.state.isOptionLoading}

                    {...this.state}
                    {...this.props}
                />
            )
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.deposit.dropDown,
        store: state.deposit.store,
        currentUser: state.currentUser.data,
        default_dropdown_options: state.deposit.default_dropdown_options,
        ewallet_type_option: state.deposit.ewallet_type_option
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcStore: (data) => dispatch(actions.store(data)),
        funcDefaultDropDownOptions: (data) => dispatch(actions.defaultDropDownOptions(data)),
        funcEwalletTypeOptions: (data) => dispatch(actions.getSecretPayEwalletOption(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(EWallet));
