import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { connect } from "react-redux";


//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

import { Fade } from "react-awesome-reveal";
import { isDesktop } from "react-device-detect";
import MobileInfoCentre from "./MobileInfoCentre";
import Accordion from 'react-bootstrap/Accordion'
import AccordionItem from "react-bootstrap/esm/AccordionItem";
import Modal from "react-modal";
import { Icon } from "@iconify/react";
import RegisterVideo from "../../assets/images/videos/how-to-register-video.mp4";
import PaymentGatewayVideo from "../../assets/images/videos/how-to-deposit-payment-gateway-video.mp4";
import BankInVideo from "../../assets/images/videos/how-to-deposit-local-bank-in-video.mp4";
import TransferVideo from "../../assets/images/videos/how-to-transfer-credit.mp4";
import WithdrawalVideo from "../../assets/images/videos/how-to-withdrawal-video.mp4";

class InfoCentre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "faq",
      videoType: "",
      openVideoPlayerModal: false,
    };
  }

  tabShow = (activeTab) => {
    this.setState({
      activeTab: this.props.activeTab !== activeTab ? activeTab : "",
    });
  };

  toggleVideoPlayerModal = (type) => {
    this.setState({
      openVideoPlayerModal: !this.state.openVideoPlayerModal,
      videoType: type
  })}

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(
      require.context(
        "../../assets/images/info-centre/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const infoCentreTopNav = (
      <nav className="nav nav-pills gap-3">
        <button
          className={`nav-link gap-2 ${this.state.activeTab === "faq" ? "active" : ""
            }`}
          onClick={() => this.tabShow("faq")}
        >
          <p>{t("info-centre.faq")}</p>
        </button>

        <button
          className={`nav-link gap-2 ${this.state.activeTab === "tnc" ? "active" : ""
            }`}
          onClick={() => this.tabShow("tnc")}
        >
          <p>{t("info-centre.terms-and-conditions")}</p>
        </button>

        <button
          className={`nav-link gap-2 ${this.state.activeTab === "gaming" ? "active" : ""
            }`}
          onClick={() => this.tabShow("gaming")}
        >
          <p>{t("info-centre.responsible-gaming")}</p>
        </button>
      </nav>
    );

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //     imageLanguage = "-ms"
      //     break;
      default:
        imageLanguage = ""
        break;
    }    

    // console.log("Video Type:", this.state.videoType)

    return (
      <Auxiliary>
        <Modal
          isOpen={this.state.openVideoPlayerModal}
          closeTimeoutMS={500}
          portalClassName={`global-modal`}
          className="modal modal-dialog modal-dialog-centered"
          ariaHideApp={false}
          onRequestClose={this.toggleVideoPlayerModal}
        >
          <div className="modal-content">                            
            {this.state.videoType === "register" ? (
              <>
                <div className="modal-header">
                  <div>                    
                    <h5 className="modal-title">{t("info-centre.how-to-register")}</h5>
                    <small>{t("info-centre.instructions")}</small>                          
                  </div>
                  <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.toggleVideoPlayerModal} />
                </div>
                <div className="modal-body">
                  <video controls>
                    <source src={RegisterVideo} type="video/mp4"/>
                  </video>
                </div>        
              </>
            ) : this.state.videoType === "payment_gateway" ? (
              <>
                 <div className="modal-header">
                  <div>                    
                    <h5 className="modal-title">{t("info-centre.htd-payment-gateway")}</h5>
                    <small>{t("info-centre.instructions")}</small>              
                  </div>
                  <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.toggleVideoPlayerModal} />
                </div>
                <div className="modal-body">
                  <video controls>
                    <source src={PaymentGatewayVideo} type="video/mp4"/>
                  </video>
                </div>        
              </>
            ) : this.state.videoType === "bank_in" ? (
              <>
                 <div className="modal-header">
                  <div>                    
                    <h5 className="modal-title">{t("info-centre.htd-bank-in")}</h5>
                    <small>{t("info-centre.instructions")}</small>             
                  </div>
                  <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.toggleVideoPlayerModal} />
                </div>
                <div className="modal-body">
                  <video controls>
                    <source src={BankInVideo} type="video/mp4"/>
                  </video>
                </div>        
              </>
            ) : this.state.videoType === "transfer" ? (
              <>
                 <div className="modal-header">
                  <div>                    
                    <h5 className="modal-title">{t("info-centre.how-to-transfer")}</h5>
                    <small>{t("info-centre.instructions")}</small>                
                  </div>
                  <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.toggleVideoPlayerModal} />
                </div>
                <div className="modal-body">
                  <video controls>
                    <source src={TransferVideo} type="video/mp4"/>
                  </video>
                </div>        
              </>
            ) : this.state.videoType === "withdrawal" ? (
              <>
                 <div className="modal-header">
                  <div>                    
                    <h5 className="modal-title">{t("info-centre.how-to-withdrawal")}</h5>
                    <small>{t("info-centre.instructions")}</small>                    
                  </div>
                  <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.toggleVideoPlayerModal} />
                </div>
                <div className="modal-body">
                  <video controls>
                    <source src={WithdrawalVideo} type="video/mp4"/>
                  </video>
                </div>        
              </>
            ) : (
              <></>
            )}                                                            
          </div>
         
        </Modal>

        {
          isDesktop ? (
            <section className="info-center-section">
              <div className="x-container">
                {/* head-tab */}
                <div className="tab-content pt-5">
                  {/* faq-start */}
                  <div
                    className={`tab-pane ${this.state.activeTab === "faq" ? "fade show active" : ""
                      }`}
                    id="faq"
                  >
                    <div className="row">
                      {/* faq-side-tab */}
                      {/* <Fade duration={1000} className="col-3 sidebar-nav">
                        <div className="nav flex-column nav-pills me-3 card-shadow">
                          <button
                            className="nav-link active"
                            id="general-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#general"
                          >
                            {t("info-centre.general")}
                          </button>
                          <button
                            className="nav-link"
                            id="myacc-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#myacc"
                          >
                            {t("info-centre.my-acc")}
                          </button>
                          <button
                            className="nav-link"
                            id="payment-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#payment"
                          >
                            {t("info-centre.payment")}
                          </button>
                          <button
                            className="nav-link"
                            id="crypto-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#crypto"
                          >
                            {t("info-centre.cryptocurrency")}
                          </button>
                          <button
                            className="nav-link"
                            id="cash-rebates-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#cash-rebates"
                          >
                            {t("info-centre.cash-rebates")}
                          </button>
                          <button
                            className="nav-link"
                            id="products-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#products"
                          >
                            {t("info-centre.products")}
                          </button>
                        </div>
                      </Fade> */}
                      {/* faq-content */}
                      <div className="info-content">
                        {/* faq-nav-btn*/}
                        <div className="top-nav nav mb-lg-4">
                          {infoCentreTopNav}
                        </div>
                        {/* faq-content-start */}
                        <div className="tab-content">
                          {/* faq-general-start*/}
                          <div className="tab-pane fade show active" id="general">
                            <Fade duration={1000}>
                              <Accordion>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header><h6>{t("info-centre.general-title-1")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-1")}</p>
                                    <p>{t("info-centre.general-details-1-2")}</p>
                                    {/* Video */}
                                    <div className="watch-video" onClick={() => this.toggleVideoPlayerModal("register")}>
                                      <img src={img['watch-video.webp']}/>
                                      <span>{t('info-centre.watch-video-tutorial')}</span>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="1">
                                  <Accordion.Header><h6>{t("info-centre.general-title-2")}</h6></Accordion.Header>
                                  {/* <Accordion.Body> <p>{t("info-centre.general-details-2")} <Link to={{ pathname: "/register" }}> { window.location.hostname === "localhost" ? window.location.protocol + "//" + window.location.hostname + ":" + window.location.port : window.location.protocol + "//" + window.location.hostname }/register</Link>{t("info-centre.general-details-2-1")}</p></Accordion.Body> */}
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-2")}</p>
                                    {/* Video */}
                                    <div className="watch-video" onClick={() => this.toggleVideoPlayerModal("payment_gateway")}>
                                      <img src={img['watch-video.webp']}/>
                                      <span>{t('info-centre.watch-video-tutorial')}</span>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="2">
                                  <Accordion.Header><h6>{t("info-centre.general-title-3")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-3")}</p>
                                    {/* Video */}
                                    <div className="watch-video" onClick={() => this.toggleVideoPlayerModal("bank_in")}>
                                      <img src={img['watch-video.webp']}/>
                                      <span>{t('info-centre.watch-video-tutorial')}</span>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="3">
                                  <Accordion.Header><h6>{t("info-centre.general-title-4")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    {/* Video */}
                                    <div className="watch-video" onClick={() => this.toggleVideoPlayerModal("transfer")}>
                                      <img src={img['watch-video.webp']}/>
                                      <span>{t('info-centre.watch-video-tutorial')}</span>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="4">
                                  <Accordion.Header><h6>{t("info-centre.general-title-5")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-5")}</p>
                                    <ul>
                                      <li>{t("info-centre.gd5-li-1")}</li>
                                      <li>{t("info-centre.gd5-li-2")}</li>
                                    </ul>                               
                                    {/* Video */}
                                    <div className="watch-video" onClick={() => this.toggleVideoPlayerModal("withdrawal")}>
                                      <img src={img['watch-video.webp']}/>
                                      <span>{t('info-centre.watch-video-tutorial')}</span>
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="5">
                                  <Accordion.Header><h6>{t("info-centre.general-title-6")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-6")}</p>                                                                
                                  </Accordion.Body>                                  
                                </Accordion.Item>

                                <Accordion.Item eventKey="6">
                                  <Accordion.Header><h6>{t("info-centre.general-title-7")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-7")}</p>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="7">
                                  <Accordion.Header><h6>{t("info-centre.general-title-8")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-8")}</p>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="8">
                                  <Accordion.Header><h6>{t("info-centre.general-title-9")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-9")}</p>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="9">
                                  <Accordion.Header><h6>{t("info-centre.general-title-10")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    <p>
                                      {t("info-centre.general-details-10-1")}
                                      <span onClick={() => this.tabShow("tnc")}>
                                        {t("info-centre.terms-and-conditions")}.
                                      </span>
                                      {t("info-centre.general-details-10-2")}
                                    </p>
                                  </Accordion.Body>
                                </Accordion.Item>

                                <Accordion.Item eventKey="10">
                                  <Accordion.Header><h6>{t("info-centre.general-title-11")}</h6></Accordion.Header>                                 
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-11")}</p>
                                    <ul>
                                      <li>
                                        <Link to={{ pathname: "/contact-us"}}>
                                          {t("info-centre.gd11-li-1")}                                      
                                        </Link>
                                      </li>
                                      <li>
                                        {t("info-centre.gd11-li-2-1")} {" "}
                                        <Link to={{ pathname: "/contact-us"}}>
                                          {t("info-centre.gd11-li-2-2")}                                      
                                        </Link>
                                      </li>
                                      <li>Wechat ID: {" "} Royal77Asia</li>
                                      <li>
                                        {t("info-centre.gd11-li-4")} {" "}
                                        <Link to={{ pathname: "/contact-us"}}>
                                          help@royal77.com                                  
                                        </Link>                                        
                                      </li>
                                    </ul>
                                  </Accordion.Body>
                                </Accordion.Item>

                                {/* <Accordion.Item eventKey="3">
                                  <Accordion.Header><h6>{t("info-centre.general-title-3")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <p>{t("info-centre.general-details-3")}</p>
                                    <ul>
                                      <li>{t("info-centre.gd3-li-1")}</li>
                                      <li>{t("info-centre.gd3-li-2")}</li>
                                      <li>{t("info-centre.gd3-li-3")}</li>
                                      <li>{t("info-centre.gd3-li-4")}</li>
                                      <li>{t("info-centre.gd3-li-5")}</li>
                                      <li>{t("info-centre.gd3-li-6")}</li>
                                      <li>{t("info-centre.gd3-li-7")}</li>
                                      <li>{t("info-centre.gd3-li-8")}</li>
                                      <li>{t("info-centre.gd3-li-9")}</li>
                                    </ul>
                                    <p>{t("info-centre.pd-notes")}
                                      <br />{t("info-centre.gd3-1")}
                                      <br />{t("info-centre.gd3-1-1")}</p>
                                  </Accordion.Body>
                                </Accordion.Item> */}
                              </Accordion>
                            </Fade>
                          </div>                        
                          {/* faq-products-end*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* faq-end*/}                  
                  {/* tnc-start */}
                  <div
                    className={`tab-pane ${this.state.activeTab === "tnc" ? " fade show active" : ""
                      }`}
                    id="tnc"
                  >
                    <div className="row">
                      {/* tnc-btn*/}
                      <div className="info-content">
                        <div className="top-nav nav mb-lg-4">
                          {infoCentreTopNav}
                        </div>
                        <p>{t("info-centre.tnc-details")}</p>
                        <div className="tab-content">
                          {/* faq-general-start*/}
                          <div className="tab-pane fade show active info-tnc" id="tnc-main">
                            {/* <Fade triggerOnce duration={1000}>
                              <Accordion>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-1-definitions")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>1.1</label>
                                      <li>{t("info-centre.tnc1-1")}</li>
                                      <label>1.2</label>
                                      <li>{t("info-centre.tnc1-2")}</li>
                                      <label>1.3</label>
                                      <li>{t("info-centre.tnc1-3")}</li>
                                      <label>1.4</label>
                                      <li>{t("info-centre.tnc1-4")}</li>
                                      <label>1.5</label>
                                      <li>{t("info-centre.tnc1-5")}</li>
                                      <label>1.6</label>
                                      <li>{t("info-centre.tnc1-6")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="1">
                                  <Accordion.Header> <h6>{t("info-centre.tnc-title-2")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>2.1</label>
                                      <li>{t("info-centre.tnc-details-2")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc2-1-li-1")}</li>
                                        <li>{t("info-centre.tnc2-1-li-2")}</li>
                                        <li>{t("info-centre.tnc2-1-li-3")}</li>
                                      </ul>
                                      <label>2.2</label>
                                      <li>{t("info-centre.tnc2-2")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc2-2-li-1")}</li>
                                        <li>{t("info-centre.tnc2-2-li-2")}</li>
                                      </ul>
                                      <label>2.3</label>
                                      <li>{t("info-centre.tnc2-3")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc2-3-li-1")}</li>
                                        <li>{t("info-centre.tnc2-3-li-2")}</li>
                                        <li>{t("info-centre.tnc2-3-li-3")}</li>
                                      </ul>
                                      <label>2.4</label>
                                      <li>{t("info-centre.tnc2-4")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="2">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-3")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>3.1</label>
                                      <li>{t("info-centre.tnc-details-3")}</li>
                                      <label>3.2</label>
                                      <li>{t("info-centre.tnc3-1")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="3">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-4")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>4.1</label>
                                      <li>{t("info-centre.tnc-details-4")}</li>
                                      <label>4.2</label>
                                      <li>{t("info-centre.tnc4-1")}</li>
                                      <label>4.3</label>
                                      <li>{t("info-centre.tnc4-2")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="4">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-5")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>5.1</label>
                                      <li>{t("info-centre.tnc-details-5")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc5-li-1")}</li>
                                        <li>{t("info-centre.tnc5-li-2")}</li>
                                        <li>{t("info-centre.tnc5-li-3")}</li>
                                        <li>{t("info-centre.tnc5-li-4")}</li>
                                        <li>{t("info-centre.tnc5-li-5")}</li>
                                        <li>{t("info-centre.tnc5-li-6")}</li>
                                        <li>{t("info-centre.tnc5-li-7")}</li>
                                        <li>{t("info-centre.tnc5-li-8")}</li>
                                        <li>{t("info-centre.tnc5-li-9")}</li>
                                        <li>{t("info-centre.tnc5-li-10")}</li>
                                        <li>{t("info-centre.tnc5-li-11")}</li>
                                        <li>{t("info-centre.tnc5-li-12")}</li>
                                        <li>{t("info-centre.tnc5-li-13")}</li>
                                        <li>{t("info-centre.tnc5-li-14")}</li>
                                        <li>{t("info-centre.tnc5-li-15")}</li>
                                        <li>{t("info-centre.tnc5-li-16")}</li>
                                      </ul>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="5">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-6")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>6.1</label>
                                      <li>{t("info-centre.tnc-details-6")}</li>
                                      <label>6.2</label>
                                      <li>{t("info-centre.tnc6-2")}</li>
                                      <label>6.3</label>
                                      <li>{t("info-centre.tnc6-3")}</li>
                                      <label>6.4</label>
                                      <li>{t("info-centre.tnc6-4")}</li>
                                      <label>6.5</label>
                                      <li>{t("info-centre.tnc6-5")}</li>
                                      <label>6.6</label>
                                      <li>{t("info-centre.tnc6-6")}</li>
                                      <label>6.7</label>
                                      <li>{t("info-centre.tnc6-7")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="6">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-7")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>7.1</label>
                                      <li>{t("info-centre.tnc-details-7")}</li>
                                      <label>7.2</label>
                                      <li>{t("info-centre.tnc7-2")}</li>
                                      <label>7.3</label>
                                      <li>{t("info-centre.tnc7-3")}</li>
                                      <label>7.4</label>
                                      <li>{t("info-centre.tnc7-4")}</li>
                                      <label>7.5</label>
                                      <li>{t("info-centre.tnc7-5")}</li>
                                      <label>7.6</label>
                                      <li>{t("info-centre.tnc7-6")}</li>
                                      <label>7.7</label>
                                      <li>{t("info-centre.tnc7-7")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc7-7-li-1")}</li>
                                        <li>{t("info-centre.tnc7-7-li-2")}</li>
                                        <li>{t("info-centre.tnc7-7-li-3")}</li>
                                      </ul>
                                      <label>7.8</label>
                                      <li>{t("info-centre.tnc7-8")}</li>
                                      <label>7.9</label>
                                      <li>{t("info-centre.tnc7-9")}</li>
                                      <label>7.10</label>
                                      <li>{t("info-centre.tnc7-10")}</li>
                                      <label>7.11</label>
                                      <li>{t("info-centre.tnc7-11")}</li>
                                      <label>7.12</label>
                                      <li>{t("info-centre.tnc7-12")}</li>
                                      <label>7.13</label>
                                      <li>{t("info-centre.tnc7-13")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="7">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-8")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>8.1</label>
                                      <li>{t("info-centre.tnc-details-8")}</li>
                                      <label>8.2</label>
                                      <li>{t("info-centre.tnc8-2")}</li>
                                      <label>8.3</label>
                                      <li>{t("info-centre.tnc8-3")}</li>
                                      <label>8.4</label>
                                      <li>{t("info-centre.tnc8-4")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc8-4-li-1")}</li>
                                        <li>{t("info-centre.tnc8-4-li-2")}</li>
                                        <li>{t("info-centre.tnc8-4-li-3")}</li>
                                        <li>{t("info-centre.tnc8-4-li-4")}</li>
                                        <li>{t("info-centre.tnc8-4-li-5")}</li>
                                        <li>{t("info-centre.tnc8-4-li-6")}</li>
                                      </ul>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="8">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-9")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>9.1</label>
                                      <li>{t("info-centre.tnc-details-9")}</li>
                                      <label>9.2</label>
                                      <li>{t("info-centre.tnc9-2")}</li>
                                      <label>9.3</label>
                                      <li>{t("info-centre.tnc9-3")}</li>
                                      <label>9.4</label>
                                      <li>{t("info-centre.tnc9-4")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="9">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-10")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>10.1</label>
                                      <li>{t("info-centre.tnc-details-10")}</li>
                                      <label>10.2</label>
                                      <li>{t("info-centre.tnc10-2")}</li>
                                      <label>10.3</label>
                                      <li>{t("info-centre.tnc10-3")}</li>
                                      <label>10.4</label>
                                      <li>{t("info-centre.tnc10-4")}</li>
                                      <label>10.5</label>
                                      <li>{t("info-centre.tnc10-5")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="10">
                                  <Accordion.Header> <h6>{t("info-centre.tnc-title-11")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>11.1</label>
                                      <li>{t("info-centre.tnc-details-11")}</li>
                                      <label>11.2</label>
                                      <li>{t("info-centre.tnc11-2")}</li>
                                      <label>11.3</label>
                                      <li>{t("info-centre.tnc11-3")}</li>
                                      <label>11.4</label>
                                      <li>{t("info-centre.tnc11-4")}</li>
                                      <label>11.5</label>
                                      <li>{t("info-centre.tnc11-5")}</li>
                                      <label>11.6</label>
                                      <li>{t("info-centre.tnc11-6")}</li>
                                      <label>11.7</label>
                                      <li>{t("info-centre.tnc11-7")}</li>
                                      <label>11.8</label>
                                      <li>{t("info-centre.tnc11-8")}</li>
                                      <label>11.9</label>
                                      <li>{t("info-centre.tnc11-9")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="11">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-12")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>12.1</label>
                                      <li>{t("info-centre.tnc-details-12")}</li>
                                      <label>12.2</label>
                                      <li>{t("info-centre.tnc12-2")}</li>
                                      <label>12.3</label>
                                      <li>{t("info-centre.tnc12-3")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc12-3-li-1")}</li>
                                        <li>{t("info-centre.tnc12-3-li-2")}</li>
                                        <li>{t("info-centre.tnc12-3-li-3")}</li>
                                        <li>{t("info-centre.tnc12-3-li-4")}</li>
                                      </ul>
                                      <label>12.4</label>
                                      <li>{t("info-centre.tnc12-4")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc12-4-li-1")}</li>
                                        <li>{t("info-centre.tnc12-4-li-2")}</li>
                                        <li>{t("info-centre.tnc12-4-li-3")}</li>
                                        <li>{t("info-centre.tnc12-4-li-4")}</li>
                                      </ul>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="12">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-13")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>13.1</label>
                                      <li>{t("info-centre.tnc-details-13")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="13">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-14")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>14.1</label>
                                      <li>{t("info-centre.tnc-details-14")}</li>
                                      <label>14.2</label>
                                      <li>{t("info-centre.tnc14-2")}</li>
                                      <label>14.3</label>
                                      <li>{t("info-centre.tnc14-3")}</li>
                                      <label>14.4</label>
                                      <li>{t("info-centre.tnc14-4")}</li>
                                      <label>14.5</label>
                                      <li>{t("info-centre.tnc14-5")}</li>
                                      <label>14.6</label>
                                      <li>{t("info-centre.tnc14-6")}</li>
                                      <label>14.7</label>
                                      <li>{t("info-centre.tnc14-7")}</li>
                                      <label>14.8</label>
                                      <li>{t("info-centre.tnc14-8")}</li>
                                      <label>14.9</label>
                                      <li>{t("info-centre.tnc14-9")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="14">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-15")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>15.1</label>
                                      <li>{t("info-centre.tnc-details-15")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc15-li-1")}</li>
                                        <li>{t("info-centre.tnc15-li-2")}</li>
                                        <li>{t("info-centre.tnc15-li-3")}</li>
                                        <li>{t("info-centre.tnc15-li-4")}</li>
                                        <li>{t("info-centre.tnc15-li-5")}</li>
                                        <li>{t("info-centre.tnc15-li-6")}</li>
                                        <li>{t("info-centre.tnc15-li-7")}</li>
                                        <li>{t("info-centre.tnc15-li-8")}</li>
                                        <li>{t("info-centre.tnc15-li-9")}</li>
                                        <li>{t("info-centre.tnc15-li-10")}</li>
                                        <li>{t("info-centre.tnc15-li-11")}</li>
                                        <li>{t("info-centre.tnc15-li-12")}</li>
                                      </ul>
                                      <label>15.2</label>
                                      <li>{t("info-centre.tnc15-2")}</li>
                                      <label>15.3</label>
                                      <li>{t("info-centre.tnc15-3")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="15">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-16")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>16.1</label>
                                      <li>{t("info-centre.tnc-details-16")}</li>
                                      <label>16.2</label>
                                      <li>{t("info-centre.tnc16-2")}</li>
                                      <label>16.3</label>
                                      <li>{t("info-centre.tnc16-3")}</li>
                                      <label>16.4</label>
                                      <li>{t("info-centre.tnc16-4")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="16">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-17")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>17.1</label>
                                      <li>{t("info-centre.tnc-details-17")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="17">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-18")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>18.1</label>
                                      <li>{t("info-centre.tnc-details-18")}</li>
                                      <label>18.2</label>
                                      <li>{t("info-centre.tnc18-2")}</li>
                                      <ul className="li-roman">
                                        <li>{t("info-centre.tnc18-2-li-1")}</li>
                                        <li>{t("info-centre.tnc18-2-li-2")}</li>
                                        <li>{t("info-centre.tnc18-2-li-3")}</li>
                                      </ul>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey="18">
                                  <Accordion.Header><h6>{t("info-centre.tnc-title-19")}</h6></Accordion.Header>
                                  <Accordion.Body>
                                    <ol className="tnc-custom-list">
                                      <label>19.1</label>
                                      <li>{t("info-centre.tnc-details-19")}</li>
                                      <label>19.2</label>
                                      <li>{t("info-centre.tnc19-2")}</li>
                                    </ol>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </Fade> */}

                            <ol className="tnc-custom-list">
                                <label>1</label>
                                <li>{t('info-centre.tnc-desc-1')}</li>
                              
                                <label>2</label>
                                <li>{t('info-centre.tnc-desc-2')}</li>
                             
                                <label>3</label>
                                <li>{t('info-centre.tnc-desc-3')}</li>
                             
                                <label>4</label>
                                <li>{t('info-centre.tnc-desc-4')}</li>
                            
                                <label>5</label>
                                <li>{t('info-centre.tnc-desc-5')}</li>
                             
                                <label>6</label>
                                <li>{t('info-centre.tnc-desc-6')}</li>
                              
                                <label>7</label>
                                <li>{t('info-centre.tnc-desc-7')}</li>
                            
                                <label>8</label>
                                <li>{t('info-centre.tnc-desc-8')}</li>
                           
                                <label>9</label>
                                <li>{t('info-centre.tnc-desc-9')}</li>
                             
                                <label>10</label>
                                <li>{t('info-centre.tnc-desc-10')}</li>
                            
                                <label>11</label>
                                <li>{t('info-centre.tnc-desc-11')}</li>
                            
                                <label>12</label>
                                <li>{t('info-centre.tnc-desc-12')}</li>
                             
                                <label>13</label>
                                <li>{t('info-centre.tnc-desc-13')}</li>
                             
                                <label>14</label>
                                <li>{t('info-centre.tnc-desc-14')}</li>
                             
                                <label>15</label>
                                <li>{t('info-centre.tnc-desc-15')}</li>
                              
                                <label>16</label>
                                <li>{t('info-centre.tnc-desc-16')}</li>
                           
                                <label>17</label>
                                <li>{t('info-centre.tnc-desc-17')}</li>
                             
                                <label>18</label>
                                <li>{t('info-centre.tnc-desc-18')}</li>
                            
                                <label>19</label>
                                <li>{t('info-centre.tnc-desc-19')}</li>

                                <label>20</label>
                                <li>{t('info-centre.tnc-desc-20')}</li>
                            </ol>
                          </div>
                          {/* tnc-accordion-end*/}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* tnc-end */}
                  {/* gaming-start */}
                  <div
                    className={`tab-pane ${this.state.activeTab === "gaming" ? " fade show active" : ""
                      }`}
                    id="gaming"
                  >
                    <div className="row">
                      <div className="info-content">
                        {/* rules-btn*/}
                        <div className="top-nav nav mb-lg-4">
                          {infoCentreTopNav}
                        </div>
                        <p>{t("info-centre.rg-details")}</p>
                        <div className="tab-content">
                          <div className="info-rg" id="rg-main">
                            <p>{t("info-centre.rg-title-1")}</p>

                            <ol className="rg-custom-list">
                              <li>{t("info-centre.rg1-li-1")}</li>
                              <li>{t("info-centre.rg1-li-2")}</li>
                              <li>{t("info-centre.rg1-li-3")}</li>
                              <li>{t("info-centre.rg1-li-4")}</li>
                              <li>{t("info-centre.rg1-li-5")}</li>
                              <li>{t("info-centre.rg1-li-6")}</li>
                              <li>{t("info-centre.rg1-li-7")}</li>
                              <li>{t("info-centre.rg1-li-8")}</li>
                              <li>{t("info-centre.rg1-li-9")}</li>                              
                            </ol>

                            <p>{t("info-centre.rg-title-2")}</p>

                            <ol className="rg-custom-list">
                              <li>{t("info-centre.rg2-li-1")}</li>
                              <li>{t("info-centre.rg2-li-2")}</li>
                              <li>{t("info-centre.rg2-li-3")}</li>
                              <li>{t("info-centre.rg2-li-4")}</li>
                              <li>{t("info-centre.rg2-li-5")}</li>                             
                            </ol>

                            <p>{t("info-centre.rg-title-3")}</p>

                            <p className="blue-text">{t("info-centre.rg-title-4")}</p>
                            <span>{t("info-centre.rg-desc-4")}</span>

                            <p className="blue-text">{t("info-centre.rg-title-5")}</p>
                            <span>{t("info-centre.rg-desc-5-1")}</span>

                            <p>{t("info-centre.rg-desc-5-2")}</p>

                            <p className="blue-text">{t("info-centre.rg-title-6")}</p>
                            <span>{t("info-centre.rg-desc-6")}</span>

                            <ol className="rg-custom-list">
                              <li>
                                {t("info-centre.rg6-li-1")}
                                <a target="_blank" href="https://www.netnanny.com">
                                  www.netnanny.com
                                </a>
                              </li>
                              <li>
                                {t("info-centre.rg6-li-2")}
                                <a target="_blank" href="https://www.cybersitter.com">
                                  www.cybersitter.com
                                </a>
                              </li>                                             
                            </ol>
                          </div>
                        </div>
                       
                      </div>
                    </div>
                  </div>
                  {/* gaming-end */}
                </div>
              </div>

            </section>
          ) : (
            <MobileInfoCentre
              {...this.props} 
              toggleVideoPlayerModal={this.toggleVideoPlayerModal}  
            />
          )
        }       
      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};


export default connect(mapStateToProps)(withNamespaces("translation")(InfoCentre));
