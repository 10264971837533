import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { default as ReactSelect } from "react-select";

//Miscellaneous 
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";
import { Icon } from "@iconify/react";

//Components 
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import { storedDepositDropdownValidation, storedSecretPayBankOptionsValidation } from "../../../common/config/depositDataValidation";

//Actions
import * as actions from "../../../store/ducks/deposit/actions";
import MobileQuickPay from "./MobileQuickPay";

import PromosTncModal from "../../../components/PromosTncModal/PromosTncModal";
import * as CryptoJS from "crypto-js";
import moment from "moment";
import StringInput from "../../../components/Input/StringInput/StringInput";

const depositAmts = [
    { value: 30 }, { value: 50 }, { value: 100 }, { value: 300 }, { value: 500 }, { value: 1000 }, { value: 5000 }
];

let quickPayTransactionMinimumPaymentAmt;
let quickPayTransactionMaximumPaymentAmt;

class QuickPay extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            promoOpts: "",
            depositAmt: "",
            // files: [],
            input:
            {
                amount: "",
                promo_id: "",
            },
            dropDown:
            {
                groupSetting: [],
                promoList: [],
                paymentGatewayOptions: [],
                lastDeposit: 0,
            },
            loading: false,
            errorMsg: [],
            formError: false,

            loadingBank: true,
            paymentGatewaySelected: (this.props.paymentGatewaySelected) ? this.props.paymentGatewaySelected : "",

            secretPayBankOption: [],
            secretPayBankCode: '',
            secretPayBankName: '',

            paymentTypeId: '',

            openPromoTncModal: false,
            showPromosTitle: "",
            showPromosContent: "",
            showPromosTable: "",

            isOptionLoading: true
        }
        this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
    }

    componentDidMount() {
        formValidation("deposit_form");
        this.props.funcGetDropDown();

        if (this.props.loading === false) {
            let data = {};
            data.transType = "deposit";
            data.currencyCode = this.props.currentUser.currency;

            this.props.funcGetBankOptionSecretPay(data);
        }

        this.fetchStorageDeposit();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            this.setState({
                isOptionLoading: false
            });

            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                let to_store_data = storedDepositDropdownValidation(this.props.default_dropdown_options, nextProps.dropDown.data);
                if(to_store_data.overwrite === true){
                    nextProps.dropDown.data.payment_gateway.forEach((val) => {
                        if (this.state.paymentGatewaySelected === val.slug && this.props.depositOption === val.transaction_slug) {
                            quickPayTransactionMinimumPaymentAmt = val.transaction_minimum_payment_amount;
                            quickPayTransactionMaximumPaymentAmt = val.transaction_maximum_payment_amount
                        }
                    });

                    this.setState({
                        dropDown:
                        {
                            groupSetting: nextProps.dropDown.data.member_group,
                            promoList: nextProps.dropDown.data.promotion,
                            paymentGatewayOptions: nextProps.dropDown.data.payment_gateway,
                            lastDeposit: (nextProps.dropDown.data.last_deposit || 0)
                        },
                        loading: false,
                    });

                    const storeDropdown = {};
                    storeDropdown.data = nextProps.dropDown.data;
                    this.props.funcDefaultDropDownOptions(storeDropdown);
                }
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === "success") {
                if (nextProps.store.data.p_url) //Fpay
                {
                    window.location.href = nextProps.store.data.p_url;
                }
                else if (nextProps.store.data.deposit_url) // SecretPay
                {
                    window.location.href = nextProps.store.data.deposit_url;
                }
                else // bank transfer
                {
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                        icon: "success",
                        title: t('global.success'),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        timer: 2000,
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container sucess',
                            icon: 'swal-success-icon'
                        } : [],
                    }).then(() => {
                        clearFormValidation("deposit_form");

                        let newInput = Object.assign({}, this.state.input);
                        newInput["amount"] = "";
                        newInput["bank_last_five_digit"] = "";

                        this.setState({
                            depositAmt: null,
                            input: newInput,
                            errorMsg: [],
                            formError: false,
                        });

                        this.props.redirectHandler();
                    });
                }
            }
            else {
                clearFormValidation("deposit_form");
                const { t } = this.props;

                if (nextProps.store.message.input_error) {
                    this.setState({
                        errorMsg: (nextProps.store.message.input_error || []),
                        formError: true,
                    });
                    Swal.close();
                }
                else {
                    let msg = null;

                    if (this.state.paymentGatewaySelected === "secret_pay") {
                        msg = '<div>';

                        msg += '<p>' + this.state.secretPayBankName + ' ' + nextProps.store.message + '</p>';

                        msg += '</div>';
                    } else {
                        msg = '<div>';
                        nextProps.store.message.forEach((val) => {
                            msg += '<p>' + val + '</p>';
                        });
                        msg += '</div>';
                    }


                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                        icon: 'error',
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container sorry',
                            icon: 'swal-sorry-icon'
                        } : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (nextProps.store.redirect && nextProps.store.redirect !== '' && nextProps.store.redirect !== null) {
                                window.location.href = nextProps.store.redirect;
                            }

                        }
                    });
                }
            }
        }

        if (nextProps.bank_option !== this.props.bank_option) {
            if (nextProps.bank_option.responseCode === 200) {
                let to_store_data = storedSecretPayBankOptionsValidation(this.props.default_secretpay_bank_options, nextProps.bank_option.data);
                if(to_store_data.overwrite === true){
                    this.setState({
                        loadingBank: false,
                        secretPayBankOption: nextProps.bank_option.data,
                        secretPayBankCode: (nextProps.bank_option.data.length > 0) ? nextProps.bank_option.data[0].bankCode : ''
                    });

                    const storeBankOptions = {};
                    storeBankOptions.data = nextProps.bank_option.data;
                    this.props.funcDefaultSecretPayBankOptions(storeBankOptions);
                }
            }
            else {
                return false;
            }
        }

        return true;
    }

    fetchStorageDeposit = () => {
        if(this.props.default_dropdown_options !== undefined){
            this.props.default_dropdown_options.data.payment_gateway.forEach((val) => {
                if (this.state.paymentGatewaySelected === val.slug && this.props.depositOption === val.transaction_slug) {
                    quickPayTransactionMinimumPaymentAmt = val.transaction_minimum_payment_amount;
                    quickPayTransactionMaximumPaymentAmt = val.transaction_maximum_payment_amount
                }
            });
            
            this.setState({
                dropDown:
                {
                    groupSetting: this.props.default_dropdown_options.data.member_group,
                    promoList: this.props.default_dropdown_options.data.promotion,
                    paymentGatewayOptions: this.props.default_dropdown_options.data.payment_gateway,
                    lastDeposit: (this.props.default_dropdown_options.data.last_deposit || 0)
                },
                loading: false,
                isOptionLoading: false
            });
        }

        if(this.props.default_secretpay_bank_options !== undefined){
            this.setState({
                loadingBank: false,
                secretPayBankOption: this.props.default_secretpay_bank_options.data,
                secretPayBankCode: (this.props.default_secretpay_bank_options.data.length > 0) ? this.props.default_secretpay_bank_options.data[0].bankCode : ''
            });
        }
    }

    selectSecretPayBank = (val) => {
        this.setState({
            secretPayBankCode: val.bankCode,
            secretPayBankName: val.bankName
        });
    }

    selectPaymentGatewayHandler = (val) => {
        this.state.dropDown.paymentGatewayOptions.map((item) => {
            if (item.slug === val.slug && this.props.depositOption === val.transaction_slug) {
                quickPayTransactionMinimumPaymentAmt = item.transaction_minimum_payment_amount;
                quickPayTransactionMaximumPaymentAmt = item.transaction_maximum_payment_amount;
            }
            return null;
        });
        // console.log(val);
        this.setState({
            paymentGatewaySelected: val.slug,
            paymentTypeId: val.id
        });

        // if(val.slug === 'secret_pay')
        // {
        //     let data = {};
        //     data.transType = "deposit";
        //     data.currencyCode = this.props.currentUser.currency;
        //     this.props.funcGetBankOptionSecretPay(data);
        // }

        // this.props.funcGetDropDown();
    }

    promoChangeHandler = (event) => {
        let newInput = Object.assign({}, this.state.input);
        newInput['promo_id'] = (event === null || event.id === '') ? '' : event.id;

        let showBonus = this.state.dropDown.promoList.filter((val) => event !== null && val.id === event.id);
        let showBonusTitle = showBonus && showBonus[0] && showBonus[0].title ? showBonus[0].title : '';
        let showBonusContent = showBonus && showBonus[0] && showBonus[0].content ? showBonus[0].content : '';
        let showPromosTable = showBonus && showBonus[0] && showBonus[0].promo_details_image ? showBonus[0].promo_details_image : '';

        this.setState({
            promoOpts: (event !== null && event.id > 0) ? event : '',
            input: newInput,
            showPromosTitle: showBonusTitle,
            showPromosContent: showBonusContent,
            showPromosTable: showPromosTable
        });
    }

    onInputChangeHandler = (event) => {
        // console.log(event.target.name+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        let depositAmt = (event.target.name === "depositAmt") ? event.target.value : this.state.depositAmt;

        if (event.target.name === "bonusCode") {
            event.target.value = event.target.value.toUpperCase();
        }
        
        newInput[event.target.name] = event.target.value;

        if (event.target.name === "bank_id") {
            if (this.state.dropDown.bankAccount[event.target.value]) {
                newInput["bank_account_id"] = this.state.dropDown.bankAccount[event.target.value].id;
            }
        }

        if (event.target.name === "depositAmt") {
            newInput["amount"] = event.target.value;
        }

        if (event.target.name.includes('amount')) {
            const splitedValue = event.target.value.split('.')
            if (splitedValue[1] && splitedValue[1].length > 2) {
                let val = parseFloat(event.target.value).toFixed(2);
                newInput[event.target.name] = val;
            }
            depositAmt = '';
        }

        this.setState({
            input: newInput,
            depositAmt: depositAmt,
            errorMsg: [],
            formError: false,
        });
    }

    toggleTncModalHandler = () => {
        this.setState({
            openPromoTncModal: !this.state.openPromoTncModal,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    confirmHandler = (e) => {
        // console.log('confirmHandler :', this.state);
        e.preventDefault();
        if (this.state.input.promo_id > 0) {
            const { t } = this.props;
            let pickedBonus = this.state.dropDown.promoList.filter((val, idx) => {
                if (val.id === this.state.input.promo_id) {
                    return val;
                }
                return null;
            });
            // console.log(pickedBonus);
            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
            let bonusTitle = pickedBonus && pickedBonus[0] && pickedBonus[0].title ? pickedBonus[0].title : "";
            let bonusTurnover = pickedBonus && pickedBonus[0] && pickedBonus[0].turnover_multiply ? pickedBonus[0].turnover_multiply : 0;
            let bonusPercentage = pickedBonus && pickedBonus[0] && pickedBonus[0].percentage ? pickedBonus[0].percentage : 0;
            let bonusMaxPayout = pickedBonus && pickedBonus[0] && pickedBonus[0].max_payout ? pickedBonus[0].max_payout : 0;
            let promotionAmt = (parseFloat(this.state.input.amount) * parseFloat(bonusPercentage)) / 100;
            // console.log(promotionAmt);
            let bonusAmt = promotionAmt > parseFloat(bonusMaxPayout) ? bonusMaxPayout : promotionAmt;
            // console.log(bonusAmt);
            let totalRollover = (parseFloat(this.state.input.amount) + parseFloat(bonusAmt)) * bonusTurnover;
            // console.log(totalRollover);
            let htmlText = '<div>' + t("deposit.deposit-warning-msg-1").replace("_promotion_", bonusTitle).replace("_turnover_", bonusTurnover) + '</div><br>'
                + '<div style="text-align: left">' + t("deposit.deposit-warning-msg-2") + `: ${this.props.currentUser.currency} ${this.state.input.amount}</div>`
                + '<div style="text-align: left">' + t("deposit.deposit-warning-msg-3") + `: ${bonusPercentage}%</div>`
                + '<div style="text-align: left">' + t("deposit.deposit-warning-msg-4") + `: ${this.props.currentUser.currency} ${new Intl.NumberFormat("en-US", num_format).format(totalRollover)}</div>`;
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : '',
                title: t("validation.promotion_confirmation"),
                html: htmlText,
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: (!isDesktop) ? {
                    container: "swal-mobile-container reminder",
                    icon: "swal-reminder-icon",
                } : [],
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitHandler();
                }
            });
        }
        else {
            this.submitHandler();
        }
    }

    submitHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        let formData = new FormData();
        Object.keys(this.state.input).forEach((val) => {
            formData.append(val, this.state.input[val]);
        });

        formData.append('payment_type', this.props.depositOption);
        formData.append('transaction_channel_id', this.props.transactionChannelId);
        
        if (this.props.depositOption === "quick-pay") {
            // formData.append('transaction_channel_id', this.state.input.transaction_channel_id);
            formData.append('payment_gateway', this.state.paymentGatewaySelected);
            formData.append('currency', this.props.currentUser.currency);
            formData.append('email', this.props.currentUser.email);
            formData.append('phone_number', this.props.currentUser.contact_no);
            // formData.append('amount', this.state.input.amount);
            formData.append('promo_id', this.state.input.promo_id);
            formData.append('redirect_url', window.location.host + '/transaction-history');
            formData.append('pg_min_deposit', quickPayTransactionMinimumPaymentAmt);
            formData.append('pg_max_deposit', quickPayTransactionMaximumPaymentAmt);
            formData.append('payment_type_id', (this.state.paymentTypeId) ? this.state.paymentTypeId : this.props.paymentTypeId);
            // if (this.state.paymentGatewaySelected === "fpay") {
            //     formData.append('payment_type_id', 7);
            // }

            // if (this.state.paymentGatewaySelected === "secret_pay_pro"){
            //     formData.append('payment_type_id', this.state.paymentTypeId);
            // }

            if (this.state.paymentGatewaySelected === "secret_pay" || this.state.paymentGatewaySelected === "secret_pay_pro") {
                formData.append('currencyCode', this.props.currentUser.currency);
                formData.append('bankCode', this.state.secretPayBankCode);
                formData.append('ownerBy', this.props.currentUser.id);
                formData.append('callbackUrl', process.env.REACT_APP_PAYMENT_GATEWAY_URL + '/payment_gateway/secret_pay/deposit_success');
                formData.append('failedRedirectURL', window.location.origin + '/transaction-history');
                formData.append('successRedirectURL', window.location.origin + '/transaction-history');
            }
        }
        // else {
        //     if (this.props.depositOption === 'bank-transfer' && this.state.files[0]) {
        //         formData.append('file', this.state.files[0]);
        //     }
        //     formData.append('payment_type_id', 1);
        // }

        // HASHING ALGO
        formData.append("trxTime",moment().unix());
        let toHashStr = "";
        let hashStr = "";
        let paramsKey = [];
        for (var to_hash of formData.entries()){
            if(!paramsKey.includes(to_hash[0])){
                paramsKey.push(to_hash[0]);
            }
        }
        paramsKey.sort();
        paramsKey.forEach((val) => {
            let x = formData.get(val);
            if(x !== ''){
                toHashStr += val + x;
            }
        });
        toHashStr += process.env.REACT_APP_HASH_SECRET;
        hashStr = CryptoJS.MD5(toHashStr).toString();
        formData.append("trxHash",hashStr);

        // for (var pair of formData.entries()) {
        //     console.log("FORMDATA - ", pair[0]+ ' = ' + pair[1]);
        // }

        this.props.funcStore(formData);
    }

    render() {
        const { t } = this.props;

        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        const payment = this.imageImport(require.context("../../../assets/images/profile/payment", false, /\.(png|jpe?g|svg)$/));
        const bankImg = this.imageImport(require.context("../../../assets/images/profile/banks", false, /\.(png|jpe?g|svg)$/));

        let promoArry = [];

        let paymentGateway = this.state.dropDown.paymentGatewayOptions ? this.state.dropDown.paymentGatewayOptions : [];
        let groupSetting = this.state.dropDown.groupSetting ? this.state.dropDown.groupSetting : [];
        let lastDeposit = (this.state.dropDown.lastDeposit.last_deposit || 0);
        // let promoList = this.props.dropDown.promoList ? this.props.dropDown.promoList : [];

        if (this.state.dropDown.promoList && !this.state.loading) {
            if (isDesktop) {
                promoArry.push({
                    id: '',
                    label: t("global.none"),
                    key: '',
                });
            }
            // promoArry = this.state.dropDown.promoList.map((val, idx) => {
            //     return {
            //         id: val.id,
            //         label: val.title,
            //         key: idx,
            //     };
            // });
            this.state.dropDown.promoList.map((val, idx) => {
                return promoArry.push({
                    id: val.id,
                    label: val.title,
                    key: idx,
                });
            });
        }
        return (
            (isDesktop) ? (
                <form className="forms-col needs-validation" method="post" id="deposit_form" onSubmit={this.confirmHandler} noValidate>
                    {this.state.loading ? (
                        <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                    ) : (
                        <>
                            <PromosTncModal
                                {...this.state}
                                {...this.props}
                                toggleTncModalHandler={this.toggleTncModalHandler}
                            />
                            
                            <div className="row">
                                <label className="col-3 col-form-label">{t('deposit.payment-option')}:</label>
                                <div className="col-9 select-bank-opts">
                                    {paymentGateway.length > 0 ? (
                                        paymentGateway.map((val, idx) => {
                                            if (val.transaction_slug === "quick-pay" && val.dp_status === 1) {
                                                return (
                                                    <div className="form-check form-check-inline" key={idx}>
                                                        <input
                                                            className="btn-check"
                                                            type="radio"
                                                            id={`payment${val.slug}`}
                                                            value={val.slug}
                                                            checked={this.state.paymentGatewaySelected === val.slug ? true : false}
                                                            onChange={() => {}}
                                                        />
                                                        <label className="btn form-check-label" htmlFor={`payment${val.slug}`} onClick={() => this.selectPaymentGatewayHandler(val)}>
                                                            <img className="active" src={payment[`${val.slug}.svg`]} alt={val.slug} />
                                                            <p>{val.slug.replaceAll('_', ' ')}</p>
                                                            {process.env.REACT_APP_ENV === "staging" && val.deposit_est_time && (
                                                                <div className="transfer-duration">
                                                                    {/* <Icon icon="ic:baseline-alarm" />
                                                                    Deposit in 1 min */}
                                                                    {val.deposit_est_time === "00:00:00" ? (
                                                                    <>
                                                                        <Icon icon="ant-design:thunderbolt-outlined" />
                                                                            {t("deposit.instant-deposit")}
                                                                    </>
                                                                    ) : (
                                                                    <>
                                                                        <Icon icon="ic:baseline-alarm" />
                                                                            {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                    </>
                                                                    )}
                                                                                    
                                                                </div>                 
                                                            )}
                                                        </label>
                                                    </div>
                                                )
                                            }
                                            else {
                                                return null;
                                            }
                                        })
                                    ) : (
                                        this.state.isOptionLoading === false ? 
                                            <span className="text-danger">{t('deposit.invalid-payment-gateway')}</span> :
                                            <Skeleton />
                                    )}
                                </div>
                            </div>
                            {this.state.paymentGatewaySelected === 'secret_pay' && (
                                <div className="row">
                                    <label className="col-3 col-form-label">{t('deposit.choose-a-bank')}:</label>
                                    <div className="col-9 align-self-center">
                                        {(paymentGateway.length > 0 && this.state.secretPayBankOption.length > 0) ? (
                                            <div className="form-check-group select-bank-opts">
                                                {this.state.secretPayBankOption.map((val, idx) => {
                                                    let bankColorImage =
                                                        (bankImg[`${val.bankCode.toLowerCase()}-color.svg`] !== undefined)
                                                            ?
                                                            bankImg[`${val.bankCode.toLowerCase()}-color.svg`]
                                                            :
                                                            bankImg['default-bank-icon-color.png'];

                                                    return (
                                                        <div className="form-check form-check-inline" key={idx}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="bank_code"
                                                                id={`bankOpt${val.bankCode}`}
                                                                value={val.bankCode}
                                                                checked={this.state.secretPayBankCode === val.bankCode ? true : false}
                                                                onChange={() => { }}
                                                            />
                                                            <label className="btn form-check-label" htmlFor={`bankOpt${val.bankCode}`} onClick={() => this.selectSecretPayBank(val)}>
                                                                <img src={bankColorImage} alt={val.bankCode} />
                                                                <p>{val.bankName}</p>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        ) : (
                                            this.state.loadingBank === false ? 
                                            <span className="text-danger">{t('deposit.invalid-bank')}</span> :
                                            <Skeleton />
                                        )
                                        }
                                    </div>
                                </div>
                            )}
                            <div className="row">
                                <label className="col-3 col-form-label">{t('deposit.promotion')}:</label>
                                <div className="col-9">
                                    <ReactSelect
                                        classNamePrefix="react-select"
                                        className="w-100"
                                        placeholder={t('history.select-promotion')}
                                        name="selectPromo"
                                        options={promoArry}
                                        onChange={this.promoChangeHandler}
                                        value={this.state.promoOpts}
                                    // isClearable={true}
                                    />
                                    {
                                        this.state.promoOpts &&
                                        <p className="tnc-text" onClick={this.toggleTncModalHandler}> {t('deposit.tnc-apply')}</p>
                                    }

                                </div>
                            </div>
                            <div className="row">
                                <label className="col-3 col-form-label">{t('transaction-type.bonus-code')}</label>
                                <div className="col-9 d-grid gap-2">
                                    <div className="input-group left has-validation">
                                        <StringInput
                                            id="bonusCode"
                                            name="bonusCode"
                                            className={`form-control ${this.state.errorMsg.bonusCode && 'was-validated is-invalid'}`}
                                            value={this.state.input.bonusCode}
                                            placeholder= {`${t("global.insert")} ${t("transaction-type.bonus-code")}`}
                                            onChange={(event) => this.onInputChangeHandler(event)}
                                            formError={this.state.formError}
                                            errorMsg={this.state.errorMsg.bonusCode || ''}
                                            autoComplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <div className="row">
                                <label className="col-3 col-form-label">{t('deposit.deposit-amount')}:</label>
                                <div className="col-9 d-grid gap-2">
                                    <div className="input-group left has-validation">
                                        <span className="input-group-text">{this.props.currentUser.currency}</span>
                                        <NumberInput
                                            id="amount"
                                            name="amount"
                                            className={`form-control ${this.state.errorMsg.amount && 'was-validated is-invalid'}`}
                                            aria-label="Username"
                                            aria-describedby="depositAmtHelp"
                                            value={this.state.input.amount}
                                            placeholder={`${t('deposit.min-max-limit')} ${new Intl.NumberFormat("en-US", num_format).format(
                                                (this.state.depositOption === "bank-transfer") ? groupSetting.min_deposit : quickPayTransactionMinimumPaymentAmt || 0.0
                                            )}/${new Intl.NumberFormat("en-US", num_format).format((this.state.depositOption === "bank-transfer") ? groupSetting.max_deposit : quickPayTransactionMaximumPaymentAmt || 0.0)}`}
                                            onChange={(event) => this.onInputChangeHandler(event)}
                                            required={true}
                                            min={(this.state.depositOption === "bank-transfer") ? groupSetting.min_deposit : quickPayTransactionMinimumPaymentAmt || 0}
                                            max={(this.state.depositOption === "bank-transfer") ? groupSetting.max_deposit : quickPayTransactionMaximumPaymentAmt || 0}
                                            step="any"
                                            onKeyPress={(evt) => ((evt.key === 'e' || evt.key === '-' || evt.key === '+') && evt.preventDefault())}
                                            minValueMessage={t("validation.amtMinValue", { 0: (this.state.depositOption === "bank-transfer") ? groupSetting.min_deposit : quickPayTransactionMinimumPaymentAmt || 0 })}
                                            maxValueMessage={t("validation.amtMaxValue", { 0: (this.state.depositOption === "bank-transfer") ? groupSetting.max_deposit : quickPayTransactionMaximumPaymentAmt || 0 })}
                                            requiredMessage={t("validation.required")}
                                            formError={this.state.formError}
                                            errorMsg={this.state.errorMsg.amount || ''}
                                        />
                                    </div>
                                    <div className="form-check-group deposit-amts">
                                        {depositAmts && depositAmts.map((depositAmt, idx) => (
                                            <div className="form-check form-check-inline" key={idx}>
                                                <input
                                                    className="btn-check"
                                                    type="radio"
                                                    name="depositAmt"
                                                    id={`depositAmt${depositAmt.value}`}
                                                    value={depositAmt.value}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    checked={Number(this.state.depositAmt) === Number(depositAmt.value) ? true : false}
                                                />
                                                <label className="btn form-check-label" htmlFor={`depositAmt${depositAmt.value}`}>
                                                    {depositAmt.value}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <small>{t('deposit.last-deposit-amount')}: <span style={{ fontWeight: '600' }}>{this.props.currentUser.currency}{lastDeposit}</span></small>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-9 offset-3">
                                    <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                        {t('global.confirm')}
                                    </button>
                                </div>
                            </div>
                        </>)}
                </form>
            ) : (
                <MobileQuickPay
                    depositAmts={depositAmts}
                    quickPayTransactionMaximumPaymentAmt={quickPayTransactionMaximumPaymentAmt}
                    quickPayTransactionMinimumPaymentAmt={quickPayTransactionMinimumPaymentAmt}

                    selectSecretPayBank={this.selectSecretPayBank}
                    selectPaymentGatewayHandler={this.selectPaymentGatewayHandler}
                    promoChangeHandler={this.promoChangeHandler}
                    onInputChangeHandler={this.onInputChangeHandler}
                    confirmHandler={this.confirmHandler}
                    submitHandler={this.submitHandler}
                    imageImport={this.imageImport}
                    toggleTncModalHandler={this.toggleTncModalHandler}

                    numFormat={num_format}
                    payment={payment}
                    bankImg={bankImg}
                    promoArry={promoArry}
                    paymentGateway={paymentGateway}
                    groupSetting={groupSetting}
                    lastDeposit={lastDeposit}

                    isOptionLoading={this.state.isOptionLoading}
                    selectedPaymentGateway={this.state.paymentGatewaySelected}

                    {...this.state}
                    {...this.props}
                />
            )
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.deposit.dropDown,
        store: state.deposit.store,
        currentUser: state.currentUser.data,
        bank_option: state.deposit.bank_option,
        default_dropdown_options: state.deposit.default_dropdown_options,
        default_secretpay_bank_options: state.deposit.default_secretpay_bank_options
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcStore: (data) => dispatch(actions.store(data)),
        funcGetBankOptionSecretPay: (data) => dispatch(actions.getSecretPayBankOption(data)),
        funcDefaultDropDownOptions: (data) => dispatch(actions.defaultDropDownOptions(data)),
        funcDefaultSecretPayBankOptions: (data) => dispatch(actions.defaultSecretPayBankOptions(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(QuickPay));