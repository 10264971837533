import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import slugConf from "../../../common/data/games.json";

class P2PHeader extends Component{
    constructor(props){
        super(props);
        this.state = {
            loading: true,
            vendorList: []
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        // console.log("[shouldComponentUpdate]",nextProps);
        if(nextProps.vendor!==undefined && nextProps.vendor!==this.props.vendor){
            if(nextProps.vendor.responseCode===200 && nextProps.vendor.msgType==="success"){
                let vendorList = nextProps.vendor.data.vendor_list;
                let p2pVendorList = [];
                if(vendorList.length>0){
                    vendorList.map((val,idx)=>{
                        if(val.game_type_slug==="p2"){
                            p2pVendorList.push(val);
                        }
                        return null;
                    });
                }
                this.setState({
                    loading: false,
                    vendorList: p2pVendorList
                });
            }
        }
        return true;
    }

    render(){
        const {t} = this.props;
        let imgDropdown = this.props.imgDropdown;
        let p2p = this.state.vendorList ? this.state.vendorList : [];
        return (
            <>
                {(!this.state.loading && p2p) &&
                    p2p.map((item, index) => {
                        if(item.maintenance_status===0){
                            let link = ((slugConf['slug-link'] && slugConf['slug-link'][item.game_vendor_slug]) || ''); 
                            return (
                                <Link to={{pathname: `/p2p/${link}`, state: {vendorSlug: item.game_vendor_slug}}} className="col-3 position-relative" key={index}>
                                    <div className="dropdown-title">
                                        <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt="Dropdown Icon" />
                                        <p>{item.game_vendor_title}</p>
                                    </div>
                                    <div className="more-btn-blue mt-3">{t("page-header.enter")}</div>
                                    <img className="dropdown-img" src={imgDropdown[`p2p-${item.game_vendor_slug}-girl.webp`]} alt="Dropdown" />
                                </Link>
                            );
                        }
                        else{
                            return (
                                <div className="col-3 position-relative under-maintenance" key={index}>
                                    <div className="dropdown-title">
                                        <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt="Dropdown Icon" />
                                        <p>{item.game_vendor_title}</p>
                                    </div>
                                    <div className="more-btn-blue mt-3">{t("global.under-maintenance")}</div>
                                    <img className="dropdown-img" src={imgDropdown[`p2p-${item.game_vendor_slug}-girl.webp`]} alt="Dropdown" />
                                </div>
                            );
                        }
                    })
                }
            </>
        );
    }
}

const mapStateToProps = state => {
    // console.log("[mapStateToProps]",state);
    return {
        vendor: state.general.vendor,
        currentUser: state.currentUser
    }
}

export default connect(mapStateToProps)(withNamespaces("translation")(P2PHeader));