import React, { Component } from 'react';
import MobileSubHeader from '../../components/PageHeader/MobileSubHeader';
import { Icon } from "@iconify/react";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import { default as ReactSelect } from "react-select";
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-awesome-reveal";
import AddAddressModal from '../../components/AddressModal/AddAddressModal';

// const initialFormInputState = {
//   contact_no: "",
//   contact_no_value: "",
//   contact_no_prefix: "",
//   pwdStrength: "low",
// };

let contactNoArry = [];
// Check country to limit the contact no fields
// let contact_no_min_limits = 9;
// let contact_no_max_limits = 10;
let contact_no_prefix = [{ value: "+60", name: "Malaysia", image: "MY.webp", phone: "+60", code: "MY" }];

export class MobileX33MallCheckout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addAddress: false,
    };
  }

  toggleAddAddressHandler = () => {
    if (this.state.addAddress === false) {
      document.body.classList.add('modal-open');
    }
    else {
      document.body.classList.remove('modal-open');
    }
    this.setState({
      addAddress: !this.state.addAddress,
    });
  }

  // handleCountryContactNo = (event) => {
  //   // switch (event.value) {
  //   //   case "+60":
  //   //     contact_no_min_limits = 9;
  //   //     contact_no_max_limits = 10;
  //   //     break;
  //   //   case "+66":
  //   //     contact_no_min_limits = 8;
  //   //     contact_no_max_limits = 8;
  //   //     break;
  //   //   case "+62":
  //   //     contact_no_min_limits = 10;
  //   //     contact_no_max_limits = 10;
  //   //     break;
  //   //   case "+84":
  //   //     contact_no_min_limits = 9;
  //   //     contact_no_max_limits = 9;
  //   //     break;
  //   //   case "+1":
  //   //     contact_no_min_limits = 10;
  //   //     contact_no_max_limits = 10;
  //   //     break;
  //   //   default:
  //   //     break;
  //   // }

  //   let newFilter = Object.assign({}, this.state.formFilter);
  //   newFilter['contact_no_value'] = event.value;

  //   this.setState({
  //     formFilter: newFilter,
  //     formError: false,
  //     errorMsg: []
  //   });
  // };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(require.context("../../assets/images/x33mall/", false, /\.(png|jpe?g|svg)$/));
    const flag = this.imageImport(require.context("../../assets/images/flags/", false, /\.(png|jpe?g|svg|webp)$/));

    return (
      <Auxiliary>
        {this.state.addAddress &&
          <AddAddressModal
            {...this.state}
            {...this.props}
            toggleAddAddressHandler={this.toggleAddAddressHandler}
          />
        }
        <section className="x33mall checkout">
          <div className='ei-m-section'>
            <div className="pf-m-dashboard">
              <MobileSubHeader
                // className="bg-white"
                returnPage="/x33mall/cart"
                header={t("x33-mall.checkout")}
                subPage='/'
              />
              <div className="input-group-col my-3">
                <small className="mb-1">{t("x33-mall.your-order")}</small>
                {this.props.products && this.props.products.map((item, index) => (
                  <div className="checkout-product" key={index}>
                    <div className="row align-items-center">
                      <div className="col-3">
                        <div className="product-broder">
                          <div className="ratio ratio-1x1">
                            <img className="product-img" src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + item.image} alt={item.title} />
                          </div>
                        </div>
                      </div>
                      <div className="col-9">
                        <div className="d-flex justify-content-between align-items-center mb-2">
                          <div>
                            <p className="details-title">{item.title}</p>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          <span className="details-cont">
                            <p>{t("redemption-history.order-total")}</p><small className="f-orange"><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{item.qty * item.coin}</small>
                          </span>
                          <div>
                          <span className="btn count-btn">{item.qty}</span>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>

            {
              !this.props.initiated ? (
                <Skeleton count={4} />
              ) : (
                <Fade duration={1000}>
                  <form className="forms-col my-3" method="post">
                    <div className='pd-form-m-col'>
                      <div className='input-group-col'>
                        <div className="py-2">
                          <label className="col-form-label lh-1 p-0 m-0">{t("profile.fullname")}</label>
                          <div className="input-group ">
                            <input
                              name="name"
                              type="text"
                              className="form-control"
                              placeholder="Full Name"
                              value={this.props.formFilter.name}
                              onChange={(event) => this.props.onInputChangeHandler(event)}
                            />
                          </div>
                        </div>

                        <div className="py-2 border-top">
                          <label className="col-form-label lh-1 p-0 m-0">{t("profile.contact-number")}</label>
                          <div className="input-group">
                            <div className="select-phone-ck">
                              <ReactSelect
                                classNamePrefix="react-select"
                                defaultValue={contact_no_prefix.filter((country) => country.code === "MY")}
                                options={contactNoArry}
                                onChange={this.props.handleCountryContactNo}
                                formatOptionLabel={(item) => (
                                  <>
                                    <img src={flag[`${item.image}`]} alt={item.image} />
                                    <p>{item.value}</p>
                                    <p>{item.name}</p>
                                  </>
                                )}
                              />
                            </div>

                            <input
                              type="number"
                              name="contact_no_value"
                              className="form-control"
                              placeholder="Insert Phone Number"
                              value={this.props.formFilter.contact_no_value}
                              onChange={(event) => this.props.onInputChangeHandler(event)}
                            />
                          </div>
                        </div>

                        <div className="py-2 border-top">
                          <label className="col-form-label lh-1 p-0 m-0" >{t("profile.address")}</label>
                          <div className="input-group right has-validation" onClick={this.toggleAddAddressHandler}>
                            {this.props.formFilter.loading ? (
                              <Skeleton count={1} width={895} height={"100%"} />
                            ) : (
                              <>
                                <p className='form-control lh-lg'>
                                  {(this.props.formFilter.address && this.props.formFilter.address.full_address)
                                    ?
                                    this.props.formFilter.address.full_address
                                    :
                                    '-'
                                  }
                                </p>
                                <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                              </>
                            )}
                          </div>
                        </div>
                        
                        {this.props.emailRequire ? (
                        <div className="py-2">
                          <label className="col-form-label lh-1 p-0 m-0">{t("profile.email")}</label>
                          <div className="input-group ">
                            <input
                              name="email"
                              type="text"
                              className="form-control"
                              placeholder="Email"
                              value={this.props.formFilter.email}
                              onChange={(event) => this.props.onInputChangeHandler(event)}
                            />
                          </div>
                        </div>) : null}

                        {/* <div className="py-2 border-top">
                          <label className="col-form-label lh-1 p-0 m-0">Message</label>
                          <div className="input-group ">
                            <input type="text" className="form-control" placeholder="Please leave a message" />
                          </div>
                        </div> */}

                        <div className="py-2 border-top">
                          <label className="col-form-label lh-1 p-0 m-0">{t("redemption-history.order-total")}</label>
                          <div className="input-group">
                            <p className="f-orange"><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" /> {this.props.totalCoin} ({this.props.totalItem} items)</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </Fade>
              )
            }
          </div>
          <div className='mx-3 mb-3'>
            <button type='button' className='btn btn-gradient-blue w-100' onClick={this.props.checkoutHandler}>{t("x33-mall.place-order")}</button>
          </div>
        </section>
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileX33MallCheckout));