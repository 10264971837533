import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "@iconify/react";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import { formValidation, clearFormValidation } from "../../hoc/Shared/utility";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
// import { isDesktop } from "react-device-detect";

//Components
import NumberInput from "../Input/NumberInput/NumberInput";
import Button from "../Button/Button";
import MobileSelectRadio from "../MobileAsset/MobileSelectRadio/MobileSelectRadio";

//Actions
import { getMemberBank, editMemberBank } from "../../store/ducks/profile/actions";
import { getDropDown } from "../../store/ducks/withdrawal/actions";

class MobileEditBank extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            memberBankData: [],
            bankDropDown: [],
            loading: true,
            selectedBank: "",

            editBankDropDown: [],
            edit_id: null,
            edit_bank_key: null,
            edit_bank_name: "",
            edit_bank_acc_number: "",
            edit_bank_icon: "",

            errorMsg: [],
            formError: false,
            showSelectRadio: false,
        };
    }

    componentDidMount() 
    {
        formValidation("update_bank_form");
        this.searchHandler();
        this.props.onRef(this);
    }

    componentWillUnmount() 
    {
        this.props.onRef(undefined);
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;
        if (nextProps.memberBankData !== this.props.memberBankData && nextProps.memberBankData !== undefined) 
        {
            if (nextProps.memberBankData.responseCode === 200) 
            {
                let accountHolder = nextProps.memberBankData.data.accountHolder;
                let memberBank = nextProps.memberBankData.data.memberBank;
                let bankDropdown = nextProps.memberBankData.data.bank;
                this.setState({
                    bank_acc_name: accountHolder.name,
                    memberBankData: memberBank,
                    loading: false,
                    bankDropDown: bankDropdown,
                });
                Swal.close();
            }
        }

        if (nextProps.memberEditBankResult !== this.props.memberEditBankResult) 
        {
            if (nextProps.memberEditBankResult.responseCode === 200) 
            {
                Swal.fire({
                    iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
                    icon: 'success',
                    title: t('global.success'),
                    html: t('global.bank-record-updated'),
                    confirmButtonText: t("global.ok"),
                    customClass: {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    }
                }).then((result) => 
                {
                    this.searchHandler();
                    if (result.isConfirmed) 
                    {
                        this.setState({
                            edit_bank_name: null,
                            edit_bank_key: null,
                            edit_bank_acc_number: "",
                            bank_name: null,
                            bank_key: null,
                            bank_acc_number: "",
                            touched: false,
                        });
                        
                    }
                    this.closeEditBankHandler();
                });
            } 
            else 
            {
                clearFormValidation('update_bank_form');
                if (nextProps.memberEditBankResult.message.input_error) 
                {
                    this.setState({
                        errorMsg: (nextProps.memberEditBankResult.message.input_error || []),
                        formError: true,
                    });
                    Swal.close();
                }
                else 
                {
                    let msg = null;
                    msg = '<ul>';
                    nextProps.memberEditBankResult.message.forEach((val) => 
                    {
                        msg += '<li>' + val + '</li>';
                    });
                    msg += '</ul>';

                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                        icon: 'error',
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: 'swal-mobile-container sorry',
                            icon: 'swal-sorry-icon'
                        }
                    });
                }
            }
        }
        return true;
    }

    closeEditBankHandler = () => 
    {
        this.props.toggleEditBankHandler();
    }

    toggleSelectRadio = () => 
    {
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };

    handleEditBankChange = (event) => 
    {
        // console.log(event);
        this.setState({
            edit_bank_name: event.id,
            edit_bank_key: event.key,
            edit_bank_icon: event.icon,
            selectedBank: event.label,
        });
    }

    updateBankHandler = (e) => 
    {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.confirm"),
            cancelButtonText: t("global.cancel"),
            customClass: {
            container: "swal-mobile-container confirmation",
            icon: "swal-confirmation-icon",
            },
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.id = this.state.edit_id;
                data.bank_name = this.state.edit_bank_name;
                data.bank_acc_name = this.state.bank_acc_name;
                data.bank_acc_number = this.state.edit_bank_acc_number;

                // console.log("BankingDetails Form Submit:", data);
                this.props.editMemberBank(data);
            }
        });
    }

    openModalHandler = (e, id, bankId, bankName, swiftCode, bankAccNo) => 
    {
        e.preventDefault();
        if (this.state.loading === false) 
        {
            // this.props.toggleEditBankHandler();
            let newArray = Object.assign([], this.state.bankDropDown);
            newArray.push({
                bankId,
                bankName,
                swiftCode,
            });

            this.setState({
                editBankDropDown: newArray,
                edit_id: id,
                edit_bank_name: bankId,
                edit_bank_key: ((newArray.length - 1) >= 0 ? (newArray.length - 1) : 0),
                edit_bank_acc_number: bankAccNo,
                edit_bank_icon: `${swiftCode.toLowerCase()}-color`,
                selectedBank: bankName,
                errorMsg: [],
                formError: false,
            });
        }
    }

    onInputChangeHandler = (event) => 
    {
        this.setState({
            [event.target.name]: event.target.value,
            errorMsg: [],
            formError: false,
        });
    };

    searchHandler = () => 
    {
        this.setState({
            loading: true,
        });
        this.props.getMemberBank();
        this.props.funcGetDropDown();
    };

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item, index) => 
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() 
    {
        const { t } = this.props;
        const bankImg = this.imageImport(require.context("../../assets/images/profile/banks", false, /\.(png|jpe?g|svg)$/));
        let editMemberBankArray = [];
        
        if (this.state.editBankDropDown && !this.state.loading) 
        {
            editMemberBankArray = this.state.editBankDropDown.map((val, idx) => {
                return {
                id: val.bankId,
                label: val.bankName,
                icon: `${val.swiftCode.toLowerCase()}-color`,
                key: idx,
                };
            });
        }

        return (
            <Auxiliary>
                <section className={`pf-m-dashboard on-top remain-w ${this.props.showEditBank ? 'd-block pe-auto' : 'd-none'}`}>
                    <div className="m-subHeader" style={{ justifyContent: "flex-start" }}>
                        <div className="w-25">
                        <Icon icon="uil:angle-left" onClick={this.closeEditBankHandler} />
                        </div>
                        <h6>{t("banking-details.edit-banking-details")}</h6>
                    </div>
                    <form className="forms-col" method="post" id="update_bank_form" encType='multipart/form-data' onSubmit={this.updateBankHandler} noValidate>
                        <div className="pd-form-m-col">
                        {
                            this.state.loading ? (
                                <div className="input-group-col">
                                    <Skeleton count={1} height={100} />
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <label className="col-form-label">{t("banking-details.bank-name")}</label>
                                        <div className="input-group-col" >
                                            <div className="input-group right has-validation" onClick={this.toggleSelectRadio}>
                                                {this.state.edit_bank_icon && (
                                                    <img
                                                    src={(bankImg[`${this.state.edit_bank_icon}.svg`] !== undefined)
                                                        ?
                                                        bankImg[`${this.state.edit_bank_icon}.svg`]
                                                        :
                                                        bankImg["default-bank-icon.png"]}
                                                    alt="bank"
                                                    />
                                                )}
                                                <p className="form-control lh-lg">
                                                    {this.state.selectedBank ? this.state.selectedBank : ''}
                                                </p>
                                                <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                                                <div className="invalid-feedback">{this.state.formError && this.state.errorMsg.bank_name}</div>
                                            </div>
                                            <MobileSelectRadio
                                                name={"edit_bank_name"}
                                                showSelectRadio={this.state.showSelectRadio}
                                                toggleHandler={this.toggleSelectRadio}
                                                onChange={this.handleEditBankChange}
                                                options={editMemberBankArray}
                                                requireChecked={true} 
                                                value={this.state.edit_bank_key} 
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="col-form-label">{t("banking-details.account-no")}</label>
                                        <div className="input-group-col">
                                            <NumberInput
                                                id="edit_bank_acc_number"
                                                name="edit_bank_acc_number"
                                                className={`form-control ${(this.state.errorMsg.bank_acc_number) ? 'was-validated is-invalid' : ''}`}
                                                value={this.state.edit_bank_acc_number}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                step="any"
                                                required={true}
                                                requiredMessage={t("validation.required")}
                                                formError={this.state.formError}
                                                errorMsg={this.state.errorMsg.bank_acc_number || ''}
                                            />
                                        </div>
                                    </div>
                                    <Button typeButton="submit" classValue="btn btn-gradient-blue mx-3" buttonName={t("top-profile.update")} />
                                </>
                            )
                        }
                        </div>
                    </form>
                </section>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state;

    return {
        memberBankData: profile.member_bank_data,
        memberEditBankResult: profile.member_edit_bank_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(getDropDown()),
        getMemberBank: () => dispatch(getMemberBank()),
        editMemberBank: (data) => dispatch(editMemberBank(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileEditBank));
