import React, { Component } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { default as ReactSelect } from "react-select";
import { deviceType } from "react-device-detect";
import { isDesktop } from "react-device-detect";
import axios from "axios";

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { mobilePrefix, mobilePrefixCodeAsKey } from "../../common/config/mobilePrefix";

import BackVideo from "../../assets/images/videos/login-page-video-b.mp4";
import Logo from "../../assets/images/brand-logo.png";
import { Vertify } from "@alex_xu/react-slider-vertify";
import { getCountry } from "../../store/ducks/home/actions";
import { postRegister, checkUsername, checkReferralRegion, checkIpRegion } from "../../store/ducks/register/actions";
import { postLogin } from "../../store/ducks/auth/actions";
import { getTopProfile } from "../../store/ducks/profile/actions";
import ValidationError from "../../components/ValidationError/ValidationError";
import { formValidation } from "../../hoc/Shared/utility";
import StringInput from "../../components/Input/StringInput/StringInput";
// import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
import { getUserData } from "../../store/ducks/currentUser/actions";

import domain from "../../common/data/domain.json";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import * as CryptoJS from "crypto-js";
import moment from "moment";

let contactNoArry = [];
// Check country to limit the contact no fields
let contact_no_min_limits = 9;
let contact_no_max_limits = 10;

let telegramArry = JSON.parse(sessionStorage.getItem("telegram_user_info"));
let googleArry = JSON.parse(sessionStorage.getItem("google_user_info"));
let dynamicCountry = "malaysia";
if(domain.id.key.includes(window.location.host)){
    dynamicCountry = "indonesia";
}

// let username = "";
// let first_name = "";
// let last_name = "";
// let email = "";

// Telegram Parameters
// if (telegramArry !== null) {
//     username = telegramArry["username"] ? telegramArry["username"] : "";
//     first_name = telegramArry["first_name"] ? telegramArry["first_name"] : "";
//     last_name = telegramArry["last_name"] ? telegramArry["last_name"] : "";
// }

// Google Parameters
// if (googleArry !== null) {
//     username = googleArry["name"] ? googleArry["name"] : "";
//     first_name = googleArry["givenName"] ? googleArry["givenName"] : "";
//     last_name = googleArry["familyName"] ? googleArry["familyName"] : "";
//     email = googleArry["email"] ? googleArry["email"] : "";
// }

const initialFormInputState = {
    /* form input value */
    username: "",
    pwd: "",
    pwdConfirmation: "",
    contact_no: "",
    contact_no_value: "", //"+60",
    contact_no_prefix: "", //"+60(Malaysia)",
    pwdStrength: "low",
    showPwd: false,
    showPwdConfirmation: false,
    tnc_check: false,
};

class RegisterSocialMedia extends Component {
    constructor(props) {
        super();
        this.state = {
            formFilter: initialFormInputState,
            visible: true,
            recaptcha_status: false,
            check_username: true,
            errorMsg: [],
            formError: false,
            formSubmit: false,
            recaptcha_image_starting_point: (Math.floor(Math.random() * 10) + 1),
            recaptcha_loading: false,
            referral: "",
            mobile_selection_labelling: [],
            mobile_selection_prefix: "",
            mobile_selection_shortcode: "",
            mobile_selection_loading: true,
            ip_overwrite: false,
            ip_region_code: ''
        };
    }

    componentDidMount() {
        formValidation("member_register_from");
        this.props.getCountry();
        this.setMobileMaxLength();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;

        if (nextProps.country_data !== this.props.country_data) {
            this.setState({
                referral: sessionStorage.getItem("referralCode"),
            // }, this.lockMobileSelection(sessionStorage.getItem("referralCode")));
            }, this.props.checkIpRegion());
            contactNoArry = [];

            let countryData = nextProps.country_data.country;

            Object.keys(countryData).map((item, i) => {
                contactNoArry.push({
                    // label: countryData[item].dialCode + "(" + countryData[item].countryName + ")",
                    name: countryData[item].countryName,
                    value: countryData[item].dialCode,
                    image: countryData[item].countryShortCode + ".webp",
                });

                return [contactNoArry];
            });
        }

        if (nextProps.check_username_data !== this.props.check_username_data) {
            if (nextProps.check_username_data.data.message === "User Exist") {
                this.setState({ check_username: false, errorMsg: nextProps.check_username_data.data.message, formError: true });
                document.getElementById("username").classList.remove('is-valid');
                document.getElementById("username").classList.add('is-invalid');
                // clearInputField("member_register_from");
            } else {
                document.getElementById("username").classList.remove('is-invalid');
                document.getElementById("username").classList.add('is-valid');
                this.setState({ check_username: true });
            }
        }

        if (nextProps.register_data !== this.props.register_data) {
            if (nextProps.register_data.responseCode === 200) {
                this.setState({registerSuccess: true});
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                        title: t("global.success"),
                        text: nextProps.register_data.message,
                        // icon: nextProps.register_data.msgType,
                        icon: 'success',
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container sucess',
                            icon: 'swal-success-icon'
                          } : []
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // this.props.history.push("/register-thank-you");
                                // Telegram when register success
                                if (telegramArry !== null) {
                                    axios
                                    .get(
                                      'https://api6.my-ip.io/v2/ip.json'
                                    )
                                    .then((response) => {
                                        if(response.data){
                                            //Get the IPV4
                                            axios
                                            .get(
                                                'https://api4.my-ip.io/v2/ip.json'
                                            )
                                            .then((responseIPV4) => {
                                            if(responseIPV4.data){
                                                const telegramData  = {};
                                                telegramData.telegram_id = telegramArry['id'];
                                                telegramData.device_type = deviceType;
                                                telegramData.ipv6 = response.data.ip;
                                                telegramData.ipv4 = responseIPV4.data.ip;
                                                let params = this.encryptionAuth(telegramData);

                                                this.props.postLogin(params);

                                                sessionStorage.removeItem('telegram_user_info');
                                                sessionStorage.removeItem('google_user_info');
                                            }
                                            }).catch((err) => {
                                                const telegramData  = {};
                                                telegramData.telegram_id = telegramArry['id'];
                                                if(response.data){
                                                    telegramData.ipv6 = response.data.ip;
                                                }
                                                telegramData.device_type = deviceType;
                                                let params = this.encryptionAuth(telegramData);

                                                this.props.postLogin(params);
            
                                                sessionStorage.removeItem('telegram_user_info');
                                                sessionStorage.removeItem('google_user_info');
                                            });
                                        }
                                       
                                    }).catch((err) => {
                                        //Get the IPV4
                                        axios
                                        .get(
                                            'https://api4.my-ip.io/v2/ip.json'
                                        )
                                        .then((responseIPV4) => {
                                        if(responseIPV4.data){
                                            const telegramData  = {};
                                            telegramData.telegram_id = telegramArry['id'];
                                            telegramData.device_type = deviceType;
                                            telegramData.ipv4 = responseIPV4.data.ip;
                                            let params = this.encryptionAuth(telegramData);

                                            this.props.postLogin(params);

                                            sessionStorage.removeItem('telegram_user_info');
                                            sessionStorage.removeItem('google_user_info');
                                        }
                                        }).catch((err) => {
                                            const telegramData  = {};
                                            telegramData.telegram_id = telegramArry['id'];
                                            telegramData.device_type = deviceType;
                                            let params = this.encryptionAuth(telegramData);
                                            
                                            this.props.postLogin(params);
        
                                            sessionStorage.removeItem('telegram_user_info');
                                            sessionStorage.removeItem('google_user_info');
                                        });
                                      
                                    });
                                }

                                // Google when register success
                                if (googleArry !== null) {
                                    axios
                                    .get(
                                      'https://api6.my-ip.io/v2/ip.json'
                                    )
                                    .then((response) => {
                                        if(response.data){
                                            //Get the IPV4
                                            axios
                                            .get(
                                                'https://api4.my-ip.io/v2/ip.json'
                                            )
                                            .then((responseIPV4) => {
                                            if(responseIPV4.data){
                                                const googleData = {};
                                                googleData.google_id = googleArry['id'];
                                                googleData.device_type = deviceType;
                                                googleData.ipv6 = response.data.ip;
                                                googleData.ipv4 = responseIPV4.data.ip;
                                                let params = this.encryptionAuth(googleData);

                                                this.props.postLogin(params);
        
                                                sessionStorage.removeItem('google_user_info');
                                                sessionStorage.removeItem('telegram_user_info');
                                            }
                                            }).catch((err) => {
                                                const googleData = {};
                                                googleData.google_id = googleArry['id'];
                                                if(response.data){
                                                    googleData.ipv6 = response.data.ip;
                                                }
                                                googleData.device_type = deviceType;
                                                let params = this.encryptionAuth(googleData);

                                                this.props.postLogin(params);
        
                                                sessionStorage.removeItem('google_user_info');
                                                sessionStorage.removeItem('telegram_user_info');
                                            });
                                        }
                                     
                                    }).catch((err) => {
                                        //Get the IPV4
                                        axios
                                        .get(
                                            'https://api4.my-ip.io/v2/ip.json'
                                        )
                                        .then((responseIPV4) => {
                                        if(responseIPV4.data){
                                            const googleData = {};
                                            googleData.google_id = googleArry['id'];
                                            googleData.device_type = deviceType;
                                            googleData.ipv4 = responseIPV4.data.ip;
                                            let params = this.encryptionAuth(googleData);
                                            
                                            this.props.postLogin(params);
    
                                            sessionStorage.removeItem('google_user_info');
                                            sessionStorage.removeItem('telegram_user_info');
                                        }
                                        }).catch((err) => {
                                            const googleData = {};
                                            googleData.google_id = googleArry['id'];
                                            googleData.device_type = deviceType;
                                            let params = this.encryptionAuth(googleData);

                                            this.props.postLogin(params);
    
                                            sessionStorage.removeItem('google_user_info');
                                            sessionStorage.removeItem('telegram_user_info');
                                        });
                                    })
                                }

                            if(sessionStorage.getItem("referralCode")){
                                sessionStorage.removeItem("referralCode");
                            }
                        }
                    });
            } else if (nextProps.register_data.responseCode === 422) {
                this.setState({
                    loading: false,
                    touched: true,
                    errorMsg: (nextProps.register_data.message.input_error || []),
                    formError: true
                });
                Swal.close();
            } else {
                this.setState({ loading: false });
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                        title: t("global.sorry"),
                        text: nextProps.register_data.data.message[0],
                        // icon: nextProps.register_data.data.msgType,
                        icon: "error",
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                            container: 'swal-mobile-container sorry',
                            icon: 'swal-sorry-icon'
                        } : [],
                    });
               
            }
        }
        
        if (nextProps.login_data !== this.props.login_data) {
            if (nextProps.login_data.responseCode === 200) {
                Swal.close();
                let loginData = nextProps.login_data.data;
                const loggedData = {};
                loggedData.id = loginData.id;
                loggedData.username = loginData.username;
                loggedData.name = loginData.name;
                loggedData.user_group = loginData.user_group;
                loggedData.user_group_level = loginData.user_group_level;
                loggedData.country_id = loginData.country_id;
                loggedData.country_code = loginData.country_code;
                loggedData.currency = loginData.currency;
                if(this.props.currentUser.country_id !== loginData.country_id){
                    loggedData.language_id = loginData.language_id;
                    loggedData.language_code = loginData.language_code;
                }
                else{
                    loggedData.language_id = this.props.currentUser.language_id;
                    loggedData.language_code = this.props.currentUser.language_code;
                }
                
                loggedData.member_group_id = loginData.group_id;
                loggedData.group_id = loginData.group_id;
                loggedData.contact_no = loginData.contact_no;
                loggedData.email = loginData.email;
                loggedData.balance = loginData.balance;
                loggedData.register_date_time = loginData.created_at;
                loggedData.time_zone = loginData.time_zone;
                this.props.getUserData(loggedData);

                localStorage.setItem('accessToken', loginData.access_token);
                if(this.props.currentUser.country_id !== loginData.country_id){
                    let defaultLang = loginData.language_code.replace("-", "_").toString().toLowerCase() || "en";
                    localStorage.setItem("lang", defaultLang);
                    this.props.i18n.changeLanguage(defaultLang); // force language change
                }
                else{
                    let storedLang = this.props.currentUser.language_code.replace("-", "_").toString().toLowerCase();
                    let returnedLang = loginData.language_code.replace("-", "_").toString().toLowerCase() || "en";
                    // localStorage.setItem("lang", (loginData.language_code.replace("-", "_").toString().toLowerCase() || 'en'));
                    if(storedLang !== returnedLang){
                        localStorage.setItem('lang', storedLang);
                    }
                    else{
                        localStorage.setItem('lang', returnedLang);
                    }
                }
                if(sessionStorage.getItem("referralCode")){
                    sessionStorage.removeItem("referralCode");
                }

                // reset vip details page if diff country id
                localStorage.removeItem('vipDetails');

                if(isDesktop){
                    this.props.history.push('/');
                }else{
                    this.props.history.push(`/online-sports-games-${dynamicCountry}`);
                }

                this.setState({
                    username: '',
                    password: '',
                    openModal: false
                });
            } else if (nextProps.login_data.responseCode === 422) {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : '',
                    title: t("global.failed"),
                    text: nextProps.login_data.message,
                    // icon: nextProps.login_data.msgType,
                    icon: "error",
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: 'swal-mobile-container failed',
                        icon: 'swal-failed-icon',
                      } : []
                });
            }
        }

        if(nextProps.check_referral_region !== undefined && nextProps.check_referral_region !== this.props.check_referral_region){
            if(nextProps.check_referral_region.responseCode === 200){
                if(nextProps.check_referral_region.data && nextProps.check_referral_region.data.result > 0 && mobilePrefix[nextProps.check_referral_region.data.result]){
                    this.setDefaultMobileCountryCode();
                    // newFilter['contact_no_value'] = mobilePrefix[nextProps.check_referral_region.data.result]["prefix"];

                    // this.setState({
                    //     mobile_selection_labelling: mobilePrefix[nextProps.check_referral_region.data.result]["tag"],
                    //     mobile_selection_prefix: mobilePrefix[nextProps.check_referral_region.data.result]["prefix"],
                    //     mobile_selection_shortcode: mobilePrefix[nextProps.check_referral_region.data.result]["code"],
                    //     mobile_selection_loading: false,
                    //     formFilter: newFilter
                    // });
                }
                else{
                    Swal.fire({
                        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                        title:  t("global.failed"),
                        text: t("register.invalid-referral"),
                        icon: "error",
                        confirmButtonText: t("global.ok"),
                        customClass: (!isDesktop) ? {
                            container: "swal-mobile-container failed",
                            icon: "swal-failed-icon", 
                        } : [],
                    }).then((result) => {
                        if(sessionStorage.getItem("referralCode")){
                            sessionStorage.removeItem("referralCode");
                        }
    
                        return window.location.href = '/';
                    });
                }
            }
        }

        if(nextProps.check_ip_region !== undefined && nextProps.check_ip_region !== this.props.check_ip_region){
            if(nextProps.check_ip_region.responseCode === 200){
                this.setState({
                    ip_overwrite: true,
                    ip_region_code: nextProps.check_ip_region.data.result
                }, () => {
                    this.lockMobileSelection(sessionStorage.getItem('referralCode'));
                });
            }
        }

        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    onInputChange = (event) => {
        let newFilter = Object.assign({}, this.state.formFilter);
        if(event.target.id === "tnc_check"){
            newFilter[event.target.id] = event.target.checked;
        }else{
            newFilter[event.target.id] = event.target.value;
        }   

        this.setState({
            formFilter: newFilter,
            formError: false,
            errorMsg: []
        });
    };

    handleCheckUsername = () => {
        if(this.state.formFilter.username !== ""){
            const data = {};
            data.username = this.state.formFilter.username;
            this.props.checkUsername(data);
        }
    };

    handleCountryContactNo = (event) => {
        switch (event.value) {
            case "+60":
                contact_no_min_limits = 9;
                contact_no_max_limits = 10;
                break;
            case "+66":
                contact_no_min_limits = 8;
                contact_no_max_limits = 8;
                break;
            case "+62":
                contact_no_min_limits = 11;
                contact_no_max_limits = 14;
                break;
            case "+84":
                contact_no_min_limits = 9;
                contact_no_max_limits = 9;
                break;
            case "+1":
                contact_no_min_limits = 10;
                contact_no_max_limits = 10;
                break;
            default:
                break;
        }

        let newFilter = Object.assign({}, this.state.formFilter);
        newFilter['contact_no_value'] = event.value;

        this.setState({
            formFilter: newFilter,
            formError: false,
            errorMsg: []
        });
    };

    encryptionAuth = (data) => {
        data.trxTime = moment().unix();
        let toHashStr = "";
        let hashStr = "";
        let paramsKey = [];
        for (const key in data) {
          if (!paramsKey.includes(key)) {
            paramsKey.push(key);
          }
        }
    
        paramsKey.sort();
        paramsKey.forEach((val) => {
            let x = data[val];
            if (x !== "") {
                if (val === "file") {
                    // file required hashing params
                    toHashStr += val + x.name + x.size;
                } else {
                    toHashStr += val + x;
                }
            }
        });
        toHashStr += process.env.REACT_APP_HASH_SECRET;
        hashStr = CryptoJS.MD5(toHashStr).toString();
        data.trxHash = hashStr;
    
        return data;
    };

    postMemberRegister = (e) => {
        e.preventDefault();
        const { t } = this.props;

        if (this.state.recaptcha_status === false) {
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : '',
                title: t("global.failed"),
                text: t("global.please-completed-recaptcha"),
                icon: "error",
                confirmButtonText: t("global.ok"),
                customClass: (!isDesktop) ? {
                    container: "swal-mobile-container failed",
                    icon: "swal-failed-icon", 
                } : [],
            });
            return false;
        }

        let currencyNotification = "";
        if(this.state.formFilter.contact_no_value === "+60"){
            currencyNotification = t("register.currency-notification").replace("##currency##", "MYR");
        }
        else if(this.state.formFilter.contact_no_value === "+62"){
            currencyNotification = t("register.currency-notification").replace("##currency##", "IDR");
        }

        currencyNotification += t("global.would-you-like-to-proceed") + "?";

            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
                title: t("global.confirmation"),
                // text: t("global.would-you-like-to-proceed") + "?",
                html: currencyNotification,
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: (!isDesktop) ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon", 
                  } : []
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        onOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};
                    data.username = this.state.formFilter.username;
                    // data.password = this.state.formFilter.pwd;
                    // data.confirm_password = this.state.formFilter.pwdConfirmation;
                    data.url = window.location.origin;

                    if (telegramArry !== null) {
                        data.telegram_id = telegramArry['id'];
                    }

                    if (googleArry !== null) {
                        data.google_id = googleArry['id'];
                        data.google_link_email = googleArry['email'];
                    }

                    if (this.state.formFilter.contact_no_prefix.value === undefined || "" || null) {
                        data.contact_number = this.state.formFilter.contact_no_value.replace(/^\+/, "") + this.state.formFilter.contact_no.replace(/ /g, "");
                        data.contact_prefix = this.state.formFilter.contact_no_value;
                    }

                    if (this.state.formFilter.contact_no !== "" && (this.state.formFilter.contact_no_prefix.value !== undefined || "" || null)) {
                        data.contact_number = this.state.formFilter.contact_no_prefix.value.replace(/^\+/, "") + this.state.formFilter.contact_no.replace(/ /g, "");
                        data.contact_prefix = this.state.formFilter.contact_no_prefix.value;
                    }

                    if (this.state.referral !== "" && this.state.referral !== null) {
                        data.refer_code = this.state.referral;
                    }

                    if (localStorage.getItem('lang')){
                        data.language_code = localStorage.getItem('lang');
                    }

                    let params = this.encryptionAuth(data);
                    this.props.postRegister(params);
                }
            });
    };

    recaptchaSuccess = (status) => {
        // alert(status);
        if(status===undefined){
            // reload recaptcha
            this.setState({
                visible: false,
                recaptcha_image_starting_point: this.state.recaptcha_image_starting_point < 10 ? this.state.recaptcha_image_starting_point+1 : 1
            }, ()=>{
                this.setState({ visible: true });
            });
        }
        if(status)
        {
            const { t } = this.props;

            Swal.fire({
                iconHtml: (!isDesktop) ? (status === 'success') ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : '',
                title: t(`global.${status}`), 
                text: (status === 'success') ? t('global.completed-recaptcha') : t('global.please-completed-recaptcha'),
                icon: (status === 'success') ? 'success' : 'error',
                confirmButtonText: t('global.confirm'),
                customClass: (!isDesktop ) ? {
                    container: (status === 'success') ? "swal-mobile-container sucess" : "swal-mobile-container failed",
                    icon: (status === 'success') ? "swal-success-icon" : "swal-failed-icon",
                  } : [],
            })
            if(status === 'success')
            {
                this.setState({
                    visible: false,
                    recaptcha_status: true,
                });
                // document.getElementsByClassName('modal-backdrop')[0].classList.remove("show");
                // document.getElementsByClassName('modal-backdrop')[1].classList.remove("show");
                let elements = document.getElementsByClassName('modal-backdrop');
                Array.prototype.forEach.call(elements, function(el) 
                {
                    el.classList.remove("show");
                });
            }
            else{
                // on failed
                this.setState({
                    recaptcha_loading: true,
                    recaptcha_image_starting_point: this.state.recaptcha_image_starting_point < 10 ? this.state.recaptcha_image_starting_point+1 : 1
                }, ()=>{
                    this.setState({ recaptcha_loading: false });
                });
            }
        }
    };

    lockMobileSelection = (referral_code) => {
        if(referral_code){
            const data = {};
            data.refCode = referral_code;
            this.props.checkReferralRegion(data);
        }
        else{
            this.setDefaultMobileCountryCode();
        }
    }

    setDefaultMobileCountryCode = () => {
        let temp_ip_overwrite = this.state.ip_overwrite;
        let temp_ip_region_code = this.state.ip_region_code;
        let finalize_ip_overwrite = false;
        if(temp_ip_overwrite){
            if(temp_ip_region_code !== '' && mobilePrefixCodeAsKey[temp_ip_region_code]){
                finalize_ip_overwrite = true;
            }
        }

        if(finalize_ip_overwrite){
            let newFilter = Object.assign({}, this.state.formFilter);
            newFilter["contact_no_value"] = mobilePrefixCodeAsKey[temp_ip_region_code]["prefix"];
        
            this.setState({
                mobile_selection_labelling: mobilePrefixCodeAsKey[temp_ip_region_code]["tag"],
                mobile_selection_shortcode: mobilePrefixCodeAsKey[temp_ip_region_code]["code"],
                mobile_selection_prefix: mobilePrefixCodeAsKey[temp_ip_region_code]["prefix"],
                mobile_selection_loading: false,
                formFilter: newFilter
            });
        }
        else{
            // default follow domain to set mobile country code
            let newFilter = Object.assign({}, this.state.formFilter);
            newFilter['contact_no_value'] = "+60";
            let temp_prefix = "+60";
            let temp_country = "Malaysia";
            let temp_image = "MY.webp"; 
            let temp_code = "MY";

            if(domain.id.key.includes(window.location.host)){ // override if indonesia
                newFilter['contact_no_value'] = "+62";
                temp_prefix = "+62";
                temp_country = "Indonesia";
                temp_image = "ID.webp"; 
                temp_code = "ID";
            }

            this.setState({
                mobile_selection_labelling: [{ 
                    value: temp_prefix, //"+60", 
                    name: temp_country, //"Malaysia", 
                    image: temp_image, //"MY.webp", 
                    phone: temp_prefix, //"+60", 
                    code: temp_code //"MY" 
                }],
                mobile_selection_prefix: temp_prefix, //"+60",
                mobile_selection_shortcode: temp_code, //"MY",
                mobile_selection_loading: false,
                formFilter: newFilter
            });
        }
    }

    setMobileMaxLength = () => {
        if (domain.id.key.includes(window.location.host)) {
            contact_no_max_limits = 11;
        }
    }

    render() {
        const { t } = this.props;
        const img = this.imageImport(require.context("../../assets/images/register/", false, /\.(png|jpe?g|svg|webp)$/));
        const flag = this.imageImport(require.context("../../assets/images/flags/", false, /\.(png|jpe?g|svg|webp)$/));
        const recaptchaImg = this.imageImport(require.context("../../assets/images/recaptcha/", false, /\.(png|jpe?g|svg)$/));
        let formSubmit = false;

        if(this.state.recaptcha_status === true && this.state.formFilter.username !== "" && this.state.formFilter.tnc_check === true && this.state.check_username === true && this.state.mobile_selection_loading === false){
            formSubmit = true;
        }

        return (
            <Auxiliary>
                {this.state.isLoading && <LoadingPage />}
                {isDesktop ? (
                    <>
                        <video className="login-video" autoPlay loop muted>
                            <source src={BackVideo} type="video/mp4" />
                        </video>
                        <section className="register-section">
                            <div className="x-container-no-m">
                                <div className="register-form">
                                    <img src={Logo} alt="R77 logo" className="logo-image" />
                                    <form method="post" name="member_register_from" id="member_register_from" onSubmit={this.postMemberRegister} noValidate>
                                        <ValidationError formName="member_register_from" touched={this.state.touched} message={this.state.error_message} />
                                        {/* username */}
                                        <div className="input-group register-global-input mt-3">
                                            <span className="input-group-text left-icon">
                                                <img src={img["icon-user.webp"]} alt="username icon" />
                                            </span>
                                            {/* <span className="out-icon">
                <img
                  src={img["icon-checked.svg"]}
                  alt="the green check icon"
                />
              </span> */}
                                            <StringInput
                                                className={`right-border-radius form-control ${this.state.errorMsg.username && "was-validated is-invalid"}`}
                                                // className=""
                                                id="username"
                                                name="username"
                                                required
                                                requiredMessage={t("validation.required")}
                                                placeholder="Username"
                                                value={this.state.formFilter.username}
                                                onChange={this.onInputChange}
                                                onBlur={this.handleCheckUsername}
                                                autoComplete="off"
                                                errorMsg={this.state.errorMsg.username || ""}
                                                formError={this.state.formError}
                                            />
                                        </div>
                                        {this.state.check_username === false && (
                                            <small className="caution-text">
                                                <img src={img["icon-caution.webp"]} alt="username caution" />
                                                {t("register.username-name-exist")}
                                            </small>
                                        )}
                                        {/* <small className="caution-text">
              <img src={img["icon-caution.svg"]} alt="username caution" />
              Name already been taken
            </small> */}
                                        {!this.state.mobile_selection_loading &&
                                        <div className="input-group register-global-input mt-3 position-relative reg-and-log-select">
                                            <ReactSelect
                                                classNamePrefix="react-select"
                                                defaultValue={this.state.mobile_selection_labelling.filter((country) => country.code === this.state.mobile_selection_shortcode)}
                                                // options={
                                                //     this.state.referral ?
                                                //     contactNoArry.filter((contact) => contact.value === this.state.mobile_selection_prefix)
                                                //     : contactNoArry
                                                // }
                                                options={contactNoArry}
                                                onChange={this.handleCountryContactNo}
                                                formatOptionLabel={(item) => (
                                                    <>
                                                        <img src={flag[`${item.image}`]} alt={item.image} />
                                                        <p>{item.value}</p>
                                                        <p>{item.name}</p>
                                                    </>
                                                )}
                                            />
                                            <StringInput
                                                className={`right-border-radius ${this.state.errorMsg.contact_number && "was-validated is-invalid"}`}
                                                // className="right-border-radius"
                                                onChange={this.onInputChange}
                                                name="contact_no"
                                                id="contact_no"
                                                placeholder="123456789"
                                                value={this.state.formFilter.contact_no}
                                                minLength={contact_no_min_limits}
                                                maxLength={contact_no_max_limits}
                                                minLengthMessage={(this.state.errorMsg.contact_number) ? '' : t("validation.minLength", { 0: contact_no_min_limits })}
                                                maxLengthMessage={(this.state.errorMsg.contact_number) ? '' : t("validation.maxLength", { 0: contact_no_max_limits })}
                                                required
                                                requiredMessage={t("validation.required")}
                                                errorMsg={this.state.errorMsg.contact_number || ""}
                                                formError={this.state.formError}
                                            />
                                        </div>
                                        }

                                        {/* recapcha */}
                                        <button className="register-recaptcha mt-3" type="button" data-bs-toggle="modal" data-bs-target="#recaptcha-modal">
                                            <span>
                                                {!this.state.visible ? (
                                                    <img src={img["icon-robot-select.webp"]} alt="recaptcha select" />
                                                ) : (
                                                    <img src={img["icon-robot-unselect.webp"]} alt="recaptcha unselect" />
                                                )}
                                                <span style={{ color: "#fff" }}>{t("register.i-not-a-robot")}</span>
                                            </span>
                                        </button>
                                        {/* checklist*/}
                                        <div className="checklist mt-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="tnc_check"
                                                username="tnc_check"
                                                value={!this.state.formFilter.tnc_check}
                                                onChange={this.onInputChange}
                                                required
                                            />
                                            <small className="form-check-label px-2" htmlFor="flexCheckChecked">
                                                {t("register.tnc")}
                                            </small>
                                        </div>
                                        <div className="modal recaptcha-modal fade" id="recaptcha-modal" tabIndex="-1" aria-labelledby="recaptcha-modalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    {!this.state.recaptcha_loading &&
                                                    <Vertify
                                                        width={320}
                                                        height={160}
                                                        visible={this.state.visible}
                                                        id="recaptcha_status"
                                                        name="recaptcha_status"
                                                        // value={!this.state.formFilter.recaptcha_status}
                                                        onSuccess={() => this.recaptchaSuccess('success')}
                                                        onFail={() => this.recaptchaSuccess('failed')}
                                                        onRefresh={() => this.recaptchaSuccess()}
                                                        imgUrl={recaptchaImg[`${this.state.recaptcha_image_starting_point}.jpg`]}
                                                    />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <button
                                            className={`btn btn-blue mt-3 ${formSubmit === false ? `disabled` : ``}`}
                                            type="submit"
                                            form="member_register_from"
                                            value="Submit"
                                        >
                                            {t("register.register-button-text")}
                                        </button>
                                        {/* login now / skip and enter/ contact us*/}
                                        <div className="d-flex justify-content-between mt-3 small-w">
                                            <div>
                                                <small>
                                                    {t("register.already-have-account")}
                                                    <br />
                                                    <Link to={{ pathname: "/login" }}>{t("register.login-now")}</Link>
                                                </small>
                                            </div>
                                            <div className="skip-btn">
                                                <Link to={{ pathname: "/" }}>
                                                    <small>{t("register.skip-and-enter")}</small>
                                                </Link>
                                            </div>
                                        </div>
                             
                                        {/* contact us*/}
                                        <div className="text-center contact-us-btn mt-3 small-w">
                                            <Link to={{ pathname: "/contact-us" }}>
                                                <img src={img["icon-contact-us.webp"]} alt="contact us icon" />
                                                <small> {t("footer.contact-us")} </small>
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </>
                ) : (
                    <div>
                        <video className="login-video mobile" autoPlay loop muted>
                            <source src={BackVideo} type="video/mp4" />
                        </video>
                        <section className="register-section register-m-section">
                            <div className="x-container-no-m">
                                <div className="register-form">
                                    <img src={Logo} alt="R77 logo" className="logo-image" />
                                    <form method="post" className="member-form social" name="member_register_from" id="member_register_from" onSubmit={this.postMemberRegister} noValidate>
                                        <ValidationError formName="member_register_from" touched={this.state.touched} message={this.state.error_message} />
                                        {/* username */}
                                        <div className="input-group register-global-input mt-3">
                                            <span className="input-group-text left-icon">
                                                <img src={img["icon-user.webp"]} alt="username icon" />
                                            </span>
                                            {/* <span className="out-icon">
                <img
                  src={img["icon-checked.svg"]}
                  alt="the green check icon"
                />
              </span> */}
                                            <StringInput
                                                className={`right-border-radius form-control ${this.state.errorMsg.username && "was-validated is-invalid"}`}
                                                // className=""
                                                id="username"
                                                name="username"
                                                required
                                                requiredMessage={t("validation.required")}
                                                placeholder="Username"
                                                value={this.state.formFilter.username}
                                                onChange={this.onInputChange}
                                                onBlur={this.handleCheckUsername}
                                                autoComplete="off"
                                                errorMsg={this.state.errorMsg.username || ""}
                                                formError={this.state.formError}
                                            />
                                        </div>
                                        {this.state.check_username === false && (
                                            <small className="caution-text">
                                                <img src={img["icon-caution.webp"]} alt="username caution" />
                                                {t("register.username-name-exist")}
                                            </small>
                                        )}
                                        {/* <small className="caution-text">
              <img src={img["icon-caution.svg"]} alt="username caution" />
              Name already been taken
            </small> */}
                                        {!this.state.mobile_selection_loading &&
                                        <div className="input-group register-global-input mt-3 position-relative reg-and-log-select mobile">
                                            <ReactSelect
                                                classNamePrefix="react-select"
                                                defaultValue={this.state.mobile_selection_labelling.filter((country) => country.code === this.state.mobile_selection_shortcode)}
                                                // options={
                                                //     this.state.referral ? 
                                                //     contactNoArry.filter((contact) => contact.value === this.state.mobile_selection_prefix)
                                                //     : contactNoArry
                                                // }
                                                options={contactNoArry}
                                                onChange={this.handleCountryContactNo}
                                                formatOptionLabel={(item) => (
                                                    <>
                                                        <img src={flag[`${item.image}`]} alt={item.image} />
                                                        <p>{item.value}</p>
                                                        <p>{item.name}</p>
                                                    </>
                                                )}
                                            />
                                            <StringInput
                                                className={`right-border-radius ${this.state.errorMsg.contact_number && "was-validated is-invalid"}`}
                                                // className="right-border-radius"
                                                onChange={this.onInputChange}
                                                name="contact_no"
                                                id="contact_no"
                                                placeholder="123456789"
                                                value={this.state.formFilter.contact_no}
                                                minLength={contact_no_min_limits}
                                                maxLength={contact_no_max_limits}
                                                minLengthMessage={(this.state.errorMsg.contact_number) ? '' : t("validation.minLength", { 0: contact_no_min_limits })}
                                                maxLengthMessage={(this.state.errorMsg.contact_number) ? '' : t("validation.maxLength", { 0: contact_no_max_limits })}
                                                required
                                                requiredMessage={t("validation.required")}
                                                errorMsg={this.state.errorMsg.contact_number || ""}
                                                formError={this.state.formError}
                                            />
                                        </div>
                                        }

                                        {/* recapcha */}
                                        <button className="register-recaptcha mt-3" type="button" data-bs-toggle="modal" data-bs-target="#recaptcha-modal">
                                            <span>
                                                {!this.state.visible ? (
                                                    <img src={img["icon-robot-select.webp"]} alt="recaptcha select" />
                                                ) : (
                                                    <img src={img["icon-robot-unselect.webp"]} alt="recaptcha unselect" />
                                                )}
                                                <span style={{ color: "#fff" }}>{t("register.i-not-a-robot")}</span>
                                            </span>
                                        </button>
                                        {/* checklist*/}
                                        <div className="checklist mt-3">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="tnc_check"
                                                username="tnc_check"
                                                value={!this.state.formFilter.tnc_check}
                                                onChange={this.onInputChange}
                                                required
                                            />
                                            <small className="form-check-label px-2" htmlFor="flexCheckChecked">
                                                {t("register.tnc")}
                                            </small>
                                        </div>
                                        <div className="modal recaptcha-modal fade" id="recaptcha-modal" tabIndex="-1" aria-labelledby="recaptcha-modalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    {!this.state.recaptcha_loading &&
                                                    <Vertify
                                                         width={720}
                                                         height={480}
                                                         l={84}
                                                         r={18}
                                                        visible={this.state.visible}
                                                        id="recaptcha_status"
                                                        name="recaptcha_status"
                                                        // value={!this.state.formFilter.recaptcha_status}
                                                        onSuccess={() => this.recaptchaSuccess('success')}
                                                        onFail={() => this.recaptchaSuccess('failed')}
                                                        onRefresh={() => this.recaptchaSuccess()}
                                                        imgUrl={recaptchaImg[`${this.state.recaptcha_image_starting_point}.jpg`]}
                                                    />
                                                    }
                                                </div>
                                            </div>
                                        </div>

                                        <button
                                            className={`btn btn-blue mt-3 ${formSubmit === false ? `disabled` : ``}`}
                                            type="submit"
                                            form="member_register_from"
                                            value="Submit"
                                        >
                                            {t("register.register-button-text")}
                                        </button>
                                        {/* login now / skip and enter/ contact us*/}
                                        <div className="d-flex justify-content-between mt-3 small-w">
                                            <div>
                                                <small>
                                                    {t("register.already-have-account")}
                                                    <br />
                                                    <Link to={{ pathname: "/login" }}>{t("register.login-now")}</Link>
                                                </small>
                                            </div>
                                            <div className="skip-btn">
                                                <Link to={{ pathname: "/" }}>
                                                    <small>{t("register.skip-and-enter")}</small>
                                                </Link>
                                            </div>
                                        </div>
                             
                                        {/* contact us*/}
                                        <div className="text-center contact-us-btn mt-3 small-w">
                                            <Link to={{ pathname: "/contact-us" }}>
                                                <img src={img["icon-contact-us.webp"]} alt="contact us icon" />
                                                <small> {t("footer.contact-us")} </small>
                                            </Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </section>
                    </div>
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    const { home, register, auth } = state;

    return {
        country_data: home.country_data,
        register_data: register.register_data,
        currentUser: state.currentUser.data,
        login_data: auth.login_data,
        check_username_data: register.check_username_data,
        check_referral_region: register.check_referral_region,
        check_ip_region:  register.check_ip_region
    };
};

//Using the redux calling api methods
const mapDispatchToProps = {
    getCountry,
    postRegister,
    postLogin,
    getUserData,
    // showBanner,
    // showAnnouncement,
    checkUsername,
    getTopProfile,
    checkReferralRegion,
    checkIpRegion
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(RegisterSocialMedia));
