import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

// Components 
import NoRecords from "../../../components/NoRecords/NoRecords";

import { getLatest } from "../../../store/ducks/history/actions";

// const bettingHistoryList = [
//   { value: 1, date: "2021-11-10  13:20:02", provider: "Dream Gaming", category: "Casino", game: "10101", betAmount: "10.00", turnover: "0.00" },
//   { value: 2, date: "2021-11-10  13:20:02", provider: "Dream Gaming", category: "Casino", game: "10101", betAmount: "10.00", turnover: "0.00" }
// ];

class BettingHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
      limit: 2
    };
  }

  componentDidMount(){
    const params = this.getFilterArray();
    this.props.getLatest(params);
  }

  shouldComponentUpdate(nextProps, nextState){
    if(nextProps.latest!==undefined && nextProps.latest!==this.props.latest){
      if(nextProps.latest.responseCode===200 && nextProps.latest.msgType==="success"){
        this.setState({
          data: nextProps.latest.data.result.row,
          loading: false
        });
      }
    }
    return true;
  }

  getFilterArray = () => {
    const listingArr = {};
    listingArr.limit = this.state.limit;
    listingArr.member_id = this.props.currentUser.id;
    listingArr.language = this.props.currentUser.language_code;
    return listingArr;
  }

  render() {
    const { t, i18n } = this.props;
    let data = this.state.data ? this.state.data : [];
    const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    return (
      <>
        {!this.state.loading && data.length>0 &&
        <div className="pd-content">
          <div className="pi-bh-col">
            <ul className="list-group list-group-flush">
                {data.map((item, index) => {
                  let gn_val = "";
                  if(item.gameName !== null){
                    if(item.gameTitle === null && i18n.exists(`games:${item.gameVendorSlug}.${item.gameName}`)){
                      gn_val = t(`games:${item.gameVendorSlug}.${item.gameName}`);
                    }
                    else if(item.gameTitle === null && !i18n.exists(`games:${item.gameVendorSlug}.${item.gameName}`)){
                      gn_val = item.gameName;
                    }
                    else{
                      gn_val = item.gameTitle;
                    }
                  }else{
                    gn_val = item.gameName;
                  }
                  return (
                  <li className="list-group-item" key={index}>
                    <span>
                      <label>{t("profile.date")}</label>
                      <p>{item.update_date}</p>
                    </span>
                    <span>
                      <label>{t("profile.game-provider")}</label>
                      <p>{t(`vendor.${item.gameVendorSlug}`)}</p>
                    </span>
                    <span>
                      <label>{t("profile.category")}</label>
                      <p>{t(`game-type.${item.category}`)}</p>
                    </span>
                    <span>
                      <label>{t("profile.game-name")}</label>
                      <p>{gn_val}</p>
                    </span>
                    <span>
                      <label>{t("profile.bet-amount")}</label>
                      <p>{new Intl.NumberFormat("en-US", num_format).format(item.bet_amount)}</p>
                    </span>
                    <span>
                      <label>{t("profile.turnover")}</label>
                      <p>{new Intl.NumberFormat("en-US", num_format).format(item.turnover)}</p>
                    </span>
                  </li>
                  )
                })}
            </ul>
          </div>
        </div>
        }
        {!this.state.loading && data.length===0 &&
          <NoRecords component="betting-history"/>
        }
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    latest: state.history.latest,
    currentUser: state.currentUser.data
  }
}

const mapDispatchToProps = {
  getLatest
}

export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces("translation")(BettingHistory));
