import React, { Component, createRef } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

//Miscellaneous

//Icons
import { Icon } from "@iconify/react";
import QRCode from "react-qr-code";
import { saveSvgAsPng } from "save-svg-as-png"

const fb_app_id = ""; // app id from facebook developer app

class ReferralModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      referral_code: "",
      referral_link: "",
      referral_settings: []
    };
    this.linkRef = createRef();
  }

  componentDidMount() { }

  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[shouldComponentUpdate]", nextProps);
    if (nextProps.member_referral_data !== undefined && nextProps.member_referral_data !== this.props.member_referral_data) {
      if (nextProps.member_referral_data.responseCode === 200 && nextProps.member_referral_data.msgType === "success") {
        let referralData = nextProps.member_referral_data.data;

        // If Testing In Localhost should included port Number
        let referralLink = "";
        if (window.location.hostname === "localhost") {
          referralLink = window.location.protocol + "//" + window.location.hostname + ":" + window.location.port + "/?refCode=" + referralData.memberReferral.referralCode;
        } else {
          referralLink = window.location.protocol + "//" + window.location.hostname + "/?refCode=" + referralData.memberReferral.referralCode;
        }
        this.setState({
          referral_code: referralData.memberReferral.referralCode,
          referral_link: referralLink,
          referral_settings: referralData.referralSettings,
          loading: false
        });
      }
    }
    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  copyToClipboardHandler = event => {
    this.linkRef.current.select();
    document.execCommand("copy");
  }

  shareToSocialMedia = (event, type) => {
    let url = null;
    switch (type) {
      case "fb":
        // currrently using URL Redirection, an alternate solution suggested by fb (default solution is SDK ver)
        if (fb_app_id) {
          url = `https://www.facebook.com/dialog/share?app_id=${fb_app_id}&display=popup`;
        }
        // &redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer"; // additional optional params
        break;
      // case "ig":
      //   // based on official documentation by meta/fb, possibly support IOS & Android only
      //   break;
      case "whatsapp":
        // url = "https://wa.me/?text="; #possible broken at whatsapp
        url = "https://api.whatsapp.com/send?text=";
        break;
      case "telegram":
        url = "https://t.me/share/url?url=";
        break;
      case "messenger":
        if (fb_app_id) {
          url = `http://www.facebook.com/dialog/send?app_id=${fb_app_id}`;
        }
        break;
      case "twitter":
        url = "https://twitter.com/intent/tweet?text=";
        break;
      default:
        break;
    }
    if (url) {
      if (type === "fb") {
        window.open(url + "&href=" + encodeURI(this.state.referral_link));
        // window.open(url+"&href="+encodeURI("https://www.google.com.my")); // for localhost test only
      }
      else if (type === "messenger") {
        window.open(url + "&link=" + encodeURI(this.state.referral_link) + "&redirect_uri=" + encodeURI(window.location.protocol + "//" + window.location.hostname));
        // window.open(url+"&link="+encodeURI("https://www.google.com.my")+"&redirect_uri="+encodeURI("http://www.google.com.my")); // for localhost test only
      }
      else {
        window.open(url + encodeURI(this.state.referral_link));
      }
    }
  }

  downloadQrCode = () => {
    saveSvgAsPng(document.getElementById("r77-qr-code"), "r77-qr-code.png");
  }

  render() {
    const { t } = this.props;
    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    return (
      <div className="modal global-modal fade" id="referralModal" tabIndex="-1" aria-labelledby="referralModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-dialog-centered modal-referral">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{t("referral.popup.friends")}</h5>
              <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
            </div>
            <div className="modal-body">
              <div className="ref-qr-code-container mb-3">
                <div className="ref-qr-code">
                  {
                    this.state.referral_link &&
                    <QRCode
                      size={150}
                      value={this.state.referral_link}
                      id="r77-qr-code" />
                  }
                </div>
                <h3>{t("referral.popup.scanfr")}</h3>
              </div>
              <p className="or-line">{t("page-header.or")}</p>
              <ul className="list-group list-group-horizontal mt-3">
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "fb")}>
                  <img src={icons["facebook.svg"]} alt="facebook" />
                  <p>{t("referral.popup.fb")}</p>
                </div>
                {/* <div role="button" className="list-group-item">
                  <img src={icons["instagram.svg"]} alt="instagram" />
                  <p>{t("referral.popup.ig")}</p>
                </div> */}
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "whatsapp")}>
                  <img src={icons["whatsapp.svg"]} alt="whatsapp" />
                  <p>{t("referral.popup.whatsapp")}</p>
                </div>
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "telegram")}>
                  <img src={icons["telegram.svg"]} alt="telegram" />
                  <p>{t("referral.popup.telegram")}</p>
                </div>
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "messenger")}>
                  <img src={icons["messenger.svg"]} alt="messenger" />
                  <p>{t("referral.popup.messenger")}</p>
                </div>
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "twitter")}>
                  <img src={icons["twitter.svg"]} alt="twitter" />
                  <p>{t("referral.popup.twitter")}</p>
                </div>
                <a role="button" className="list-group-item" href={`mailto:?subject=${this.state.referral_link}`} target="_blank" rel="noreferrer">
                  <img src={icons["email.svg"]} alt="email" />
                  <p>{t("referral.popup.email")}</p>
                </a>
              </ul>
              {/* <div className="download-share-btn">
                <button className="btn btn-gradient-blue" onClick={this.downloadQrCode}>{t("global.download-qr-code")}</button>
                <button className="btn btn-gradient-blue">{t("global.share-qr-code")}</button>
              </div> */}
              <div className="input-group right mb-3">
                {!this.state.loading &&
                  <>
                    <input
                      ref={this.linkRef}
                      type="text"
                      className="form-control"
                      name="referCode"
                      defaultValue={this.state.referral_link}
                      readOnly
                    />
                    <span className="input-group-text">
                      <button type="button" className="btn btn-gradient-blue" onClick={(event) => this.copyToClipboardHandler(event)}>
                        {t("referral.popup.copy")}
                      </button>
                    </span>
                  </>}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  // console.log("[mapStateToProps]", state);
  return {
    member_referral_data: state.profile.member_referral_data
  }
}

export default connect(mapStateToProps)(withNamespaces("translation")(ReferralModal));
