import React, { Component } from 'react'
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
//Miscellaneous

import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
//Components
import DataTable from "../../../components/Datatable/DataTable";
// import { NavLink, Redirect } from "react-router-dom";
import Button from "../../../components/Button/Button";
import Skeleton from "react-loading-skeleton";

import MobileAddCrypto from '../../../components/MobileAddCrypto/MobileAddCrypto';
import MobileEditCrypto from '../../../components/MobileAddCrypto/MobileEditCrypto';
export class MobileCryptoDetails extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      // openSelectRadio: false,
      // example: false,
      // data: [],
      // bank_acc_name: this.props.bank_acc_name, 
      // editBankDropDown: [],
      // edit_id: null,
      // edit_bank_key: null,
      // edit_bank_name: "",
      // edit_bank_acc_number: "",

      edit_id: '',
      edit_network: '',
      edit_withdrawal_address: '',

      showAddNewCrypto: false,
      showEditCrypto: false,
      // loading: false,
      // errorMsg: [], 
    };
  }

  toggleAddCryptoHandler = () => {
    this.setState({
      showAddNewCrypto: !this.state.showAddNewCrypto,
    });
  }

  toggleEditCryptoHandler = (e, id, network, withdrawalAddress) => {
    // e.preventDefault();
    this.setState({
        edit_id: id,
        edit_network: network,
        edit_withdrawal_address: withdrawalAddress,
        showEditCrypto: !this.state.showEditCrypto,
    });
  }

  actionFormatter = (cell, row) => {
    let removeButton, output;
    removeButton = (
      <div className="text-end" key={row.withdrawalAddressId}>
        <Button
          typeButton="button"
          classValue="btn icon gray"
          key={`edit-${row.withdrawalAddressId}`}
          idValue={row.withdrawalAddressId === undefined ? 0 : row.withdrawalAddressId}
          clicked={(e) => this.toggleEditCryptoHandler(e, row.withdrawalAddressId, row.network, row.withdrawalAddress)}
          buttonName={<Icon icon="uil:edit" />}
        />
        <Button
          typeButton="button"
          classValue="btn icon red"
          key={`remove-${row.withdrawalAddressId}`}
          idValue={row.withdrawalAddressId === undefined ? 0 : row.withdrawalAddressId}
          clicked={(e) => this.props.removeCryptoAddressHandler(e, row.withdrawalAddressId, row.withdrawalAddress)}
          buttonName={<Icon icon="uil:trash-alt" />}
        />
      </div>
    );

    output = [removeButton];
    return output;
  };
  render() {
    const { t } = this.props;
    const columns = [
      {
        text: t("crypto-address-details.crypto"),
        dataField: "crypto",
        headerAttrs: {
          hidden: true,
        },
      },
      {
        text: "",
        dataField: "delete",
        formatter: this.actionFormatter,
        headerAttrs: {
          hidden: true,
        },
      },
    ];
    return (
      <Auxiliary>
        {this.state.showAddNewCrypto && <MobileAddCrypto {...this.state} {...this.props} toggleAddCryptoHandler={this.toggleAddCryptoHandler} />}

        {this.state.showEditCrypto && <MobileEditCrypto {...this.state} toggleEditCryptoHandler={this.toggleEditCryptoHandler} searchHandler={this.props.searchHandler}/>}

        <div className="bank-list-group">
          <div className="tab-pane fade show active" id="banking-details" role="tabpanel" aria-labelledby="banking-tab">
            {
              this.props.loading ? (
                <div className="mt-3">
                  <Skeleton count={2} style={{ marginBottom: "1rem" }} height={"56px"} />
                </div>
              ) : (
                <DataTable
                  keyField="no"
                  data={this.props.rsData}
                  columns={columns}
                  activePage={1}
                  limit={20}
                  totalItems={this.props.totalRecords}
                  paginationHandler={(val) =>
                    this.props.datatablesChangeHandler(val, "activePage")
                  }
                  sizePerPageHandler={(val) =>
                    this.props.datatablesChangeHandler(val, "limit")
                  }
                  loading={this.props.loading}
                  hideSearchBar={true}
                  hidePagination={true}
                  hideTotalRecords={true}
                  classValue="pd-table w-border mb-0"
                  component="banking-details"
                />
              )
            }
            <button type="button" className="btn form-check-label wdrawal-add-bank w-100" onClick={this.toggleAddCryptoHandler}>
              {/* <Icon className="me-2" icon="radix-icons:plus-circled" /> */}
              {t("crypto-address-details.add-new-crypto-acc")}
            </button>
          </div>
        </div>
      </Auxiliary>
    )
  }
}

export default withNamespaces("translation")(MobileCryptoDetails);