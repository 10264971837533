import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import StringInput from "../Input/StringInput/StringInput";
import Dropzone from "react-dropzone";
import { Icon } from "@iconify/react";
import MobileSubHeader from "../PageHeader/MobileSubHeader";
import MobileSelectRadio from "../MobileAsset/MobileSelectRadio/MobileSelectRadio";
import { isDesktop } from "react-device-detect";

import { getMemberBank } from "../../store/ducks/profile/actions";
import { upgradeBusinessAccount } from "../../store/ducks/profile/actions";
import { formValidation } from "../../hoc/Shared/utility";
export class MobileBusinessUpgrade extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            this.setState({
                formError: false,
                errorMsg: [],
                files: files,
                ssm_image: files[0]
            });
        };
        this.state = {
            bankDropDown: [],
            files: [],
            companyName: "",
            companyRegNo: "",
            bank_name: null,
            bank_account_number: "",
            ssm_image: "",
            loading: true,
            bankId: "",
            errorMsg: [],
            formError: false,
        };
    }

    componentDidMount() {
        formValidation("business_account_form");
        this.props.getMemberBank();
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[BankingDetails] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        if (nextProps.memberBankData !== this.props.memberBankData && nextProps.memberBankData !== undefined) {
            if (nextProps.memberBankData.responseCode === 200) {
                let accountHolder = nextProps.memberBankData.data.accountHolder;
                let memberBank = nextProps.memberBankData.data.memberBank;
                let bankDropdown = nextProps.memberBankData.data.bank;
                this.setState({
                    bank_acc_name: accountHolder.name,
                    memberBankData: memberBank,
                    bankDropDown: bankDropdown,
                    loading: false,
                    totalRecords: memberBank.length,
                });
            }
        }

        if (nextProps.upgrade_business_account_result !== undefined && nextProps.upgrade_business_account_result !== this.props.upgrade_business_account_result) {
            if (nextProps.upgrade_business_account_result.responseCode === 200 && nextProps.upgrade_business_account_result.msgType === "success") {
                const { t } = this.props;
                Swal.close();

                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : "",
                    icon: "success",
                    title: t("global.success"),
                    html: t("global.record-added"),
                    showConfirmButton: true,
                    timer: 2000,
                    customClass: !isDesktop
                        ? {
                              container: "swal-mobile-container sucess",
                              icon: "swal-success-icon",
                          }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                      window.location.reload();
                    }
                });
            } else if (nextProps.upgrade_business_account_result.responseCode === 422) {
                this.setState({
                    touched: true,
                    // error_message: nextProps.memberProfileUpdateResult.message,
                    errorMsg: nextProps.upgrade_business_account_result.message.input_error || [],
                    formError: true,
                });
                Swal.close();
            }
        }

        return true;
    }

    toggleSelectRadio = () => {
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };
    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    handleBankChange = (event) => {
        // let newFilter = Object.assign({}, this.state.businessAccDetail);
        // newFilter['bankId'] = event.id;
        // console.log(event);
        this.setState({
            bankId: event.id,
            selectedBank: event.label,
        });
    };

    onInputChange = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            errorMsg: [],
            formError: false,
        });
    };

    setBusinessAcc = (e) => {
        e.preventDefault();

        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                      container: "swal-mobile-container confirmation",
                      icon: "swal-confirmation-icon",
                  }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data = new FormData();
                data.append("company_name", this.state.companyName);
                data.append("company_reg_no", this.state.companyRegNo);
                data.append("bank_id", this.state.bankId);
                data.append("bank_account_number", this.state.bank_account_number);
                data.append("ssm_image", this.state.ssm_image);
                data.append("country_id", this.props.currentUser.data.country_id);

                this.props.upgradeBusinessAccount(data);
            }
        });
    };

    removeFileHandler = () => {
        this.setState({
            files: [],
            // progress: 0,
        });
    };

    render() {
        const { t } = this.props;
        let addMemberBankArray = [];
        if (this.state.bankDropDown && !this.state.loading) {
            addMemberBankArray = this.state.bankDropDown.map((val, idx) => {
                return {
                    id: val.bankId,
                    label: val.bankName,
                    icon: `${val.swiftCode.toLowerCase()}-color`,
                    key: idx,
                };
            });
        }

        let files = this.state.files.map((file) => (
            <li key={file.name}>
                <p>
                    {file.name} - {Math.round(file.size / 1024)} KB{" "}
                    <button className="btn icon" onClick={this.removeFileHandler}>
                        <Icon icon="mdi:close" />
                    </button>
                </p>
            </li>
        ));

        return (
            <Auxiliary>
                {this.props.upgradeAccForm && (
                    <section className="upgrade-acc-m-section pf-m-dashboard on-top d-block pe-auto">
                        <MobileSubHeader
                            // className="bg-white"
                            closeModalHandler={this.props.toggleBusinessForm}
                            header={t("profile.upgrade-business-acc")}
                            style={{ justifyContent: "unset" }}
                        />
                        <form className="forms-col needs-validation my-3" id="business_account_form" method="post" onSubmit={this.setBusinessAcc} noValidate>
                            <div className="pd-form-m-col">
                                <div className="input-group-col">
                                    <div className="pb-3">
                                        <label className="col-form-label p-0 m-0">{t("profile.comp-name")}</label>
                                        <StringInput
                                            className={`${this.state.errorMsg.company_name ? "was-validated is-invalid ps-0" : ""}`}
                                            type="text"
                                            placeholder={t("profile.insert-comp-name")}
                                            name="companyName"
                                            defaultValue=""
                                            value={this.state.companyName}
                                            onChange={this.onInputChange}
                                            required
                                            requiredMessage={t("validation.required")}
                                            errorMsg={this.state.errorMsg.company_name || ""}
                                            formError={this.state.formError}
                                        />
                                    </div>

                                    <div className="pt-3 border-top">
                                        <label className="col-form-label p-0 m-0">{t("profile.comp-reg-no")}</label>
                                        <StringInput
                                            className={`${this.state.errorMsg.company_reg_no ? "was-validated is-invalid ps-0" : ""}`}
                                            type="text"
                                            placeholder={t("profile.insert-comp-reg-no")}
                                            name="companyRegNo"
                                            defaultValue=""
                                            value={this.state.companyRegNo}
                                            onChange={this.onInputChange}
                                            required
                                            requiredMessage={t("validation.required")}
                                            errorMsg={this.state.errorMsg.company_reg_no || ""}
                                            formError={this.state.formError}
                                        />
                                    </div>

                                    <div className="pt-3 border-top">
                                        <label className="col-form-label p-0 m-0">{t("banking-details.bank-name")}</label>
                                        <div className="input-group-col">
                                            <div className="input-group right has-validation" onClick={this.toggleSelectRadio}>
                                                {/* {this.state.bank_icon && (
                          <img
                            src={(bankImg[`${this.state.bank_icon}.svg`] !== undefined)
                              ?
                              bankImg[`${this.state.bank_icon}.svg`]
                              :
                              bankImg["default-bank-icon.png"]}
                            alt="bank"
                          />
                        )} */}
                                                <p className={`form-control lh-lg ${this.state.errorMsg?.bank_id ? "is-invalid" : ""}`}>
                                                    {this.state.selectedBank ? this.state.selectedBank : t("banking-details.select-bank")}
                                                </p>
                                                <span className="input-group-text">
                                                    <Icon icon="uil:angle-right" />
                                                </span>
                                                <div className="invalid-feedback">{this.state.formError && this.state.errorMsg.bank_id}</div>
                                            </div>
                                            <MobileSelectRadio
                                                name={"bank_name"}
                                                showSelectRadio={this.state.showSelectRadio}
                                                toggleHandler={this.toggleSelectRadio}
                                                onChange={this.handleBankChange}
                                                options={addMemberBankArray}
                                                value={this.state.bankId}
                                            />
                                        </div>
                                    </div>

                                    <div className="pt-3 border-top">
                                        <label className="col-form-label p-0 m-0">{t("profile.bank-acc-no")}</label>
                                        <StringInput
                                            className={`${this.state.errorMsg.bank_account_number ? "was-validated is-invalid ps-0" : ""}`}
                                            type="text"
                                            placeholder={t("profile.insert-bank-acc-no")}
                                            name="bank_account_number"
                                            defaultValue=""
                                            value={this.state.bank_account_number}
                                            onChange={this.onInputChange}
                                            required
                                            requiredMessage={t("validation.required")}
                                            errorMsg={this.state.errorMsg.bank_account_number || ""}
                                            formError={this.state.formError}
                                        />
                                    </div>

                                    <div className="pt-3 border-top">
                                        <label className="col-form-label p-0">{t("profile.ssm-no")}</label>
                                        <Dropzone
                                            maxFiles={1}
                                            accept={"image/*,.pdf"}
                                            // maxSize={500000}
                                            onDrop={this.onDrop}
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                                <div className={`dropzone-container ${this.state.errorMsg.ssm_image ? "form-control is-invalid" : ""}`}>
                                                    {files && files.length ? (
                                                        <aside>
                                                            <ul>{files}</ul>
                                                        </aside>
                                                    ) : (
                                                        ""
                                                    )}
                                                    <div {...getRootProps({ className: "dropzone" })}>
                                                        <div>
                                                            <input {...getInputProps()} />
                                                            <Icon icon="mdi:upload" />
                                                            <p>{t("deposit.drop-file-msg-1")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </Dropzone>
                                        <small className="mt-2">{t("deposit.drop-file-msg-4")}</small>
                                    </div>
                                </div>
                                <button className="btn btn-gradient-blue mx-3" type="submit" value="Submit">
                                    {t("global.submit")}
                                </button>
                                <small className="text-center">{t("profile.qualified-upgrade-business-acc")}</small>
                            </div>
                        </form>
                    </section>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state;

    return {
        currentUser: state.currentUser,
        memberBankData: profile.member_bank_data,
        upgrade_business_account_result: profile.upgrade_business_account_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getMemberBank: () => dispatch(getMemberBank()),
        upgradeBusinessAccount: (data) => dispatch(upgradeBusinessAccount(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileBusinessUpgrade));
