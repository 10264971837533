import React, { Component } from "react";
import { connect } from "react-redux";
// import DomPurify from "../../../components/Sanitize/DomPurify";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";

//Miscellaneous
import ReactPlayer from "react-player";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import { Popover, OverlayTrigger } from "react-bootstrap";
import domain from "../../../common/data/domain.json";
// import AdsVideo from "../../../assets/images/videos/ads-video-popular-events.mp4";

//Icons
import { Icon } from "@iconify/react";
// import { Link } from "react-router-dom";

let dynamicCountry = "malaysia";
if (domain.id.key.includes(window.location.host)) {
    dynamicCountry = "indonesia";
}

class EventSectionNew extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeTab: "football",
        };
    }

    changeTab = (tab) => {
        this.setState({
            activeTab: tab,
        });
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });

        return images;
    }

    render() {
        // let imageLanguage = "";
        // switch (this.props.currentUser.language_code) 
        // {
        //     case "zh-CN":
        //         imageLanguage = "-cn";
        //     break;
        //     case "ms":
        //         imageLanguage = "-ms"
        //     break;
        //     default:
        //         imageLanguage = "";
        //     break;
        // }

        const { t } = this.props;
        // const videos = this.imageImport(require.context("../../../assets/images/videos/",false,/\.(png|jpe?g|svg|mp4)$/));
        const liveImg = this.imageImport(require.context("../../../assets/images/live-streaming", false, /\.(png|jpe?g|svg|webp)$/));
        const chinese = this.imageImport(require.context("../../../assets/images/home/chinese", false, /\.(png|jpe?g|svg|webp)$/));
        const raya = this.imageImport(require.context("../../../assets/images/home/raya", false, /\.(png|jpe?g|svg|webp)$/));
        const mooncake = this.imageImport(require.context("../../../assets/images/home/mooncake", false, /\.(png|jpe?g|svg|webp)$/));
        const halloween = this.imageImport(require.context("../../../assets/images/home/halloween", false, /\.(png|jpe?g|svg|webp)$/));
        const christmas = this.imageImport(require.context("../../../assets/images/home/christmas", false, /\.(png|jpe?g|svg|webp)$/));

        // const fadeData = [1, 2, 3, 4, 5];
        const liveCtg = ["football", "basketball"];

        const popoverHoverFocus = (
            <Popover id="popover-trigger-hover-focus">
                <p className="px-3 py-2">{t('main-page.odds-content-1')}</p>
            </Popover>
        );

        let sabaOdds = this.props.sabaOdds ? this.props.sabaOdds : [];

        return (
            <section className="events-section live-sport-event">
                <div className="x-container">
                    <div
                        className={`section-header ${this.props.currentEvent === "cny" && "cny-header"
                            }`}
                    >
                        <div className="d-flex align-item-center">
                            {this.props.currentEvent === "christmas" ? (
                                <img
                                    src={christmas["title-deco-left.webp"]}
                                    alt="header-deco"
                                />
                            ) : this.props.currentEvent === "cny" ? (
                                <img
                                    src={chinese["title-deco-left.webp"]}
                                    alt="header-deco"
                                />
                            ) :(
                                <></>
                            )}
                            {this.props.currentEvent === "cny" ?  <h2>{t("main-page.popular-events")}</h2> :  <h2 className="mb-4">{t("main-page.popular-events")}</h2> }
                            {this.props.currentEvent === "christmas" ? (
                                <img
                                    src={christmas["title-deco-right.webp"]}
                                    alt="header-deco"
                                />
                            ) : this.props.currentEvent === "cny" ? (
                                <img
                                    src={chinese["title-deco-right.webp"]}
                                    alt="header-deco"
                                />
                            ) : (
                                <></>
                            )}
                        </div>
                        <p>{t("main-page.popular-events-remark")}</p>
                    </div>
                    <div className="global-game-section">
                        <div className="game-container no-bg">
                            <div className="game-category border-0">
                                <ul className="row justify-content-center">
                                    {liveCtg &&
                                        liveCtg.map((item, index) => (
                                            <li className="game-ctg-col" key={index}>
                                                <div
                                                    className={`game-link ${this.state.activeTab === item ? "active" : ""
                                                        }`}
                                                    onClick={() => this.changeTab(item)}
                                                >
                                                    <span>
                                                        {this.state.activeTab === item ? (
                                                            <img
                                                                className="icon-white"
                                                                src={liveImg[`${item}-active.webp`]}
                                                                alt="live category"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="icon-grey"
                                                                src={liveImg[`${item}.webp`]}
                                                                alt="live category"
                                                            />
                                                        )}
                                                    </span>
                                                    <p>{t(`live-stream.${item}`)}</p>
                                                </div>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        {this.props.currentEvent === "raya" ? (
                            <div className="events-deco-image raya">
                                <img className="deco-1" src={raya["content-deco-1.webp"]} alt="deco" />
                            </div>
                        ) : this.props.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ? (
                            <div className="events-deco-image mooncake">
                                <img className="deco-1" src={mooncake["content-deco-1.webp"]} alt="deco" />
                                <img className="deco-2" src={mooncake["content-deco-1.webp"]} alt="deco" />
                            </div>
                        ) : this.props.currentEvent === "halloween" ? (
                            <div className="events-deco-image halloween">
                            <img
                            className="halloween-deco-3"
                            src={halloween["content-deco-3.webp"]}
                            alt="content-deco-3"
                          /> 
                          </div>
                          ) : this.props.currentEvent === "cny" ? (
                            <div className="events-deco-image cny ">
                            <img
                            className="deco-1"
                            src={chinese["content-deco-1.webp"]}
                            alt="content-deco-3"
                          /> 
                          </div>
                          ) : this.props.currentEvent === "christmas" && (
                            <div className="events-deco-image christmas">
                            <img
                            className="christmas-deco-3"
                            src={christmas["deco-3.webp"]}
                            alt="content-deco-3"
                          /> 
                          </div>
                          )}
                        <div className="events-video-container card-shadow">
                            {this.props.currentEvent === "cny" && (
                                <img className="border-deco" src={chinese["border-bg.png"]} alt="border deco" />
                            )}
                            <div className="events-video-content">
                                <div className="row">
                                    <div className="col-8">
                                        <div className="ratio ratio-16x9">
                                            {/* <img className="stream-cover" src={liveImg["live-streaming-cover.webp"]} alt="cover"/> */}
                                            {/* {this.props.loading ? (
                                                <Skeleton count={1} height={"100%"} />
                                            ) : this.props.data.length === 0 ? (
                                                <></>
                                            ) : (
                                                <>
                                                {this.props.data.map((item, idx) => {
                                                    if (this.props.promotedVideoId === item.video_id) {
                                                    return (
                                                        <ReactPlayer
                                                        volume={0.3}
                                                        width="100%"
                                                        height="100%"
                                                        url={item.video_url}
                                                        onEnded={this.playNext}
                                                        controls
                                                        playing
                                                        />
                                                    );
                                                    } else {
                                                    return null;
                                                    }
                                                })}
                                                </>
                                            )} */}
                                            {this.props.data.length > 0 ? this.props.data.map((item, idx) => {
                                                return (
                                                    <ReactPlayer
                                                    volume={0.3}
                                                    width="100%"
                                                    height="100%"
                                                    url={item.video_url}
                                                    onEnded={this.playNext}
                                                    muted
                                                    controls
                                                    playing
                                                    />
                                                );
                                            }) : (
                                                    <ReactPlayer
                                                        volume={0.3}
                                                        width="100%"
                                                        height="100%"
                                                        // url={AdsVideo}
                                                        url={''}
                                                        onEnded={this.playNext}
                                                        muted
                                                        controls
                                                        playing
                                                    />
                                                
                                            )}
                                        </div>
                                        <div className="mt-5 d-flex justify-content-center">
                                            {/* <Link
                                                className="btn-gradient-blue"
                                                to={{ pathname: `/online-sports-games-${dynamicCountry}` }}
                                            >
                                                {t('main-page.bet-now')}
                                            </Link> */}
                                            <button
                                                className="btn-gradient-blue"
                                                onClick={() => this.props.accessGame('sb')}
                                            >
                                                {t('main-page.bet-now')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        {this.props.loading
                                            ?
                                            <Skeleton count={1} height={"100%"} />
                                            :
                                            <ul>
                                                {sabaOdds && sabaOdds.map((item, index) => {
                                                    if (item.sport_type === this.state.activeTab) {
                                                        return (
                                                            <li key={index}>
                                                                {/* <Link className="event-sport-detail card-shadow" to={{ pathname: `/online-sports-games-${dynamicCountry}` }}> */}
                                                                <div className="event-sport-detail card-shadow" onClick={() => this.props.accessGame('sb')}>
                                                                    <div className="event-sport-header">
                                                                        <p className="team-vs">{item.home_name} {t('global.vs')} {item.away_name}</p>
                                                                        <p>{item.match_date}</p>
                                                                        <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={popoverHoverFocus}>
                                                                            <span className="info-icon">
                                                                                <Icon icon="mdi:information-variant" />
                                                                            </span>
                                                                        </OverlayTrigger>
                                                                    </div>
                                                                    <div className="event-sport-content">
                                                                        <div className="team-1">
                                                                            <span>{t('global.h')}</span>
                                                                            <p>{item.home_name}</p>
                                                                        </div>
                                                                        <div className="team-2">
                                                                            <span>{t('global.a')}</span>
                                                                            <p>{item.away_name}</p>
                                                                        </div>
                                                                        <div className="event-sport-bet">
                                                                            <div className="sport-hdp">
                                                                                <p>{item.home_ah}</p>
                                                                                <span>{t('main-page.handicap')}</span>
                                                                                <p>{item.away_ah}</p>
                                                                            </div>
                                                                            <div className="sport-hdp">
                                                                                <p>{item.home_odds}</p>
                                                                                <span>{t('main-page.odds')}</span>
                                                                                <p>{item.away_odds}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {/* </Link> */}
                                                            </li>
                                                        );
                                                    }
                                                    else {
                                                        return null;
                                                    }
                                                })}
                                            </ul>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        currentUser: state.currentUser.data,
    };
};

export default connect(mapStateToProps)(withNamespaces("translation")(EventSectionNew));

