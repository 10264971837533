import React, { Component } from "react";
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import Modal from "react-modal";
import { isDesktop } from "react-device-detect";
import { Wheel } from "react-custom-roulette";
import Swal from "sweetalert2";
import SideBar from '../../../components/SideBar/SideBar';

// Miscellaneous
import { Icon } from "@iconify/react";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { currency } from "../../../common/config/currency";

// Components
import Button from "../../../components/Button/Button";

// Actions
import * as actions from "../../../store/ducks/luckySpin/actions";
import { Link } from "react-router-dom";

class LuckySpin extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            // prizeArry: data,
            prizeArry: [],
            historyList: [],
            // groupPrize: 4, // if have specific group prize winning, set [1,2,3,4] else  null
            prizeNumber: false, // winning prize
            prizeType: {},
            mustSpin: false,
            openModal: false,
            coin: null,
            countRemain: null,
            disableSearchButton: false,
            modalExtraInfo: [],
        };
    }

    componentDidMount() {
        // if (localStorage.getItem("accessToken")) {
            this.props.funcGetList();
        // }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;
        if (nextProps.rs !== this.props.rs && nextProps.rs !== undefined) {
            if (nextProps.rs.responseCode === 200 && nextProps.rs.msgType === 'success') {
                let prizeArry = [];
                let historyList = nextProps.rs.data.history;
                // console.log(nextProps.rs.data);
                nextProps.rs.data.prize.map((val, idx) => {
                    prizeArry.push({
                        id: val.id,
                        option: val.title,
                        seq: val.sequence,
                        group: val.category_id,
                    });

                    return null;
                });

                this.setState({
                    prizeArry,
                    historyList,
                    coin: nextProps.rs.data.coin,
                    countRemain: nextProps.rs.data.count,
                });
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === 'success') {
                let mustSpin = (nextProps.store.data && nextProps.store.data.item_id) ? true : false;
                let prizeNumber = '';
                let modalExtraInfo = nextProps.store.data;

                this.state.prizeArry.filter((val, idx) => {
                    if (val.id === Number(nextProps.store.data.item_id)) {
                        prizeNumber = idx;
                    }

                    return null;
                });

                // console.log(nextState.prizeArry, prizeNumber);

                this.setState({
                    mustSpin,
                    prizeNumber,
                    prizeType: nextState.prizeArry[prizeNumber],
                    modalExtraInfo,
                });
            }
            else {
                this.swalErrorHandler(nextProps.store.message[0]);
            }
        }

        return true;
    }

    onStopSpinning = () => {
        this.setState({
            openModal: true,
            disableSearchButton: false,
            mustSpin: false,
        }, () => {
            this.props.funcGetList();
        });
    }

    closeModal = () => {
        this.setState({
            openModal: false,
        });
    }

    startToSpin = () => {
        if (!localStorage.getItem("accessToken")) {
            this.setState({
                openModal: true
            });
        }
        else {
            this.setState({
                disableSearchButton: true
            }, () => {
                this.props.funcStore();
            });
        }
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    swalErrorHandler = (msg) => {
        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : '',
            title: t("global.error"),
            text: msg,
            icon: 'error',
            confirmButtonText: t('global.ok'),
            customClass: (!isDesktop) ? {
                container: "swal-mobile-container failed",
                icon: "swal-failed-icon",
            } : [],
        }).then(() => {
            let newTab = Object.assign({}, this.state[this.state.historyTab]);
            newTab['loading'] = false;

            this.setState({
                [this.state.historyTab]: newTab,
                disableSearchButton: false,
            });
        });
    }

    render() {
        const { t } = this.props;

        const banner = this.imageImport(require.context("../../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        const tNc = this.imageImport(require.context("../../../assets/images/tnc", false, /\.(png|jpe?g|svg)$/));
        const coin = this.imageImport(require.context("../../../assets/images/profile/mobile/icons", false, /\.(png|jpe?g|svg)$/));
        const mobileTnc = this.imageImport(require.context("../../../assets/images/tnc/mobile", false, /\.(png|jpe?g|svg)$/));
        const img = this.imageImport(require.context("../../../assets/images/lucky-spin", false, /\.(png|jpe?g|svg|webp)$/));

        let imageLanguage = '';
        // let tncImageLanguage = '';
        switch (this.props.currentUser.language_code) {
            case "zh-CN":
                imageLanguage = "-cn";
                // tncImageLanguage = "lucky-tnc-cn.svg";
                break;
            case "ms":
                imageLanguage = "-ms";
                // tncImageLanguage = "term-condition-bm.svg";
            break;
            case "indon":
                imageLanguage = "-indon";
                // tncImageLanguage = "term-condition-id.svg";
            break;
            default:
                imageLanguage = "";
                // tncImageLanguage = "term-condition.svg";
                break;
        }

        return (
            <Auxiliary>
                <Modal
                    isOpen={this.state.openModal}
                    closeTimeoutMS={500}
                    portalClassName={`global-modal`}
                    className="modal modal-dialog modal-dialog-centered modal-pd"
                    ariaHideApp={false}
                    onRequestClose={this.closeModal}
                >
                    <div className={`modal-content ${isDesktop ? '' : 'mobile-modal-bg-white p-0'}`}>
                        <div className={`modal-body ls-modal-body ${!isDesktop ? " p-3" : ""}`} style={{borderRadius:!isDesktop?'0.5rem':''}}>
                            <div className="ls-modal gap-2">
                                {
                                    localStorage.getItem("accessToken")
                                        ?
                                        (
                                            <div className={`${isDesktop ? '' : 'position-relative'}`}>
                                                {
                                                    this.state.prizeType.group === 3 ? (
                                                        <img src={img[`wallet${isDesktop ? "" : "-m"}.webp`]} alt="award" />
                                                    ) : this.state.prizeType.group === 4 ? (
                                                        <img src={img[`angpao.webp`]} alt="award" />
                                                    ) : this.state.prizeType.group === 2 ? (
                                                        <img src={img[`${this.state.prizeType.option}${isDesktop ? "" : "-m"}.webp`]} alt="award" />
                                                    ) : this.state.prizeType.group === 5 ? (
                                                        <img src={img[`np.webp`]} alt="award" />
                                                    ) : (
                                                        <img src={img[`${this.state.prizeType.option}.webp`]} alt="award" />
                                                    )
                                                }
                                                {
                                                    this.state.prizeType.group === 3
                                                    ?
                                                        <p className="prize-type">{t(`lucky-spin.sub-${this.state.prizeType.option}`)}</p>
                                                    : 
                                                        this.state.prizeType.group === 2
                                                        ?
                                                            <p className="prize-type">{t('game-type.sl').toUpperCase()}</p>
                                                        :
                                                            ''
                                                }
                                                <div className={`${isDesktop ? 'mt-2' : 'mt-4'}`}>
                                                    <h4>
                                                        {(this.state.prizeType.group === 5) ? `${t('lucky-spin.sorry')}...` : t('lucky-spin.congratulation')}
                                                    </h4>
                                                    <label className="prize-name">
                                                        {
                                                            (this.state.prizeType.group === 5) 
                                                            ? 
                                                            t('lucky-spin.no-prize-desc')
                                                            :
                                                            t('lucky-spin.won-prize', { prize: t(`lucky-spin.${this.state.prizeType.option}`, {currency: currency[this.props.currentUser.country_id] ?? ""}) })
                                                        } 
                                                    </label>
                                                    <br />
                                                    {
                                                        this.state.prizeType.group === 1 ? (
                                                            <label>{t('lucky-spin.redeem-prize-desc')}</label>
                                                        ) : this.state.prizeType.group === 2 ? (
                                                            <label>{t('lucky-spin.won-cash-back-desc', { prize: t(`lucky-spin.${this.state.prizeType.option}`) })}</label>
                                                        ) : this.state.prizeType.group === 3 ? (
                                                            <label>
                                                                {t('lucky-spin.won-wallet-desc-1', {currency: currency[this.props.currentUser.country_id] ?? "", amount: (this.state.modalExtraInfo.max_claim || 0)})} <br />
                                                                {t('lucky-spin.won-wallet-desc-2', {turnover: (this.state.modalExtraInfo.turnover || 0)})}
                                                            </label>
                                                            // `The ${this.state.prizeType.option.match(/(\d+)/)[0]}% has been credited to your wallet.`
                                                        ) : this.state.prizeType.group === 4 ? (
                                                            <label>{t('lucky-spin.won-angpao-desc', {turnover: (this.state.modalExtraInfo.turnover || 0)})}</label>
                                                        ) : (
                                                            ""
                                                        )
                                                    }
                                                </div>

                                            </div>
                                        ) : (
                                            <div className={`${isDesktop ? '' : 'position-relative'}`}>
                                                <img src={img[`login-to${isDesktop ? "" : "-m"}.webp`]} alt="login" style={{ width: "3.25rem" }} />
                                                <div className={`${isDesktop ? 'mt-2' : 'mt-4'}`}>
                                                    <h4>{t('lucky-spin.login-acc')}</h4>
                                                    <label>
                                                        {t('lucky-spin.login-to-play')}
                                                    </label>
                                                </div>
                                            </div>
                                        )
                                }

                                {
                                    localStorage.getItem("accessToken") ? isDesktop ?
                                        this.state.prizeType.group === 1 ? (
                                            <>
                                                <Link
                                                    to={{ pathname: "/redemption-history" }}
                                                    className="btn btn-gradient-blue d-flex align-items-center justify-content-center"
                                                >
                                                    {t('lucky-spin.claim-now')}
                                                </Link>
                                                <Button
                                                    typeButton="button"
                                                    classValue="btn btn-gray"
                                                    buttonName={t('lucky-spin.later')}
                                                    clicked={this.closeModal}
                                                />
                                            </>
                                        ) : (
                                            <Button
                                                typeButton="button"
                                                classValue="btn btn-gradient-blue"
                                                buttonName={t('lucky-spin.ok')}
                                                clicked={this.closeModal}
                                            />
                                        ) : (
                                            <></>
                                        ) : isDesktop ? (
                                            <Link
                                                className="btn btn-gradient-blue d-flex align-items-center justify-content-center"
                                                to={{ pathname: '/login' }}
                                            >
                                                {t('lucky-spin.ok')}
                                            </Link>
                                        ) : (
                                        <></>
                                    )
                                }
                            </div>
                        </div>

                        {
                            localStorage.getItem("accessToken") ? !isDesktop ?
                                this.state.prizeType.group === 1 ? (
                                    <>
                                        <Link
                                            to={{ pathname: "/redemption-history" }}
                                            className="btn btn-white"
                                        >
                                            {t('lucky-spin.claim-now')}
                                        </Link>
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-gray"
                                            buttonName={t('lucky-spin.later')}
                                            clicked={this.closeModal}
                                        />
                                    </>
                                ) : (
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-white"
                                        buttonName={t('lucky-spin.ok')}
                                        clicked={this.closeModal}
                                    />

                                ) : (
                                    <></>
                                ) : !isDesktop ? (
                                    <Link className="btn btn-white" to={{ pathname: '/login' }}>
                                        {t('lucky-spin.ok')}
                                    </Link>
                                ) : (
                                <></>
                            )
                        }

                    </div>
                </Modal>

                {isDesktop ? (
                    <section className="ls-section">
                        <div className="banner-col">
                            <div className="banner-item">
                                <img src={banner[`lucky-spin-banner${imageLanguage}.webp`]} alt="R77 Lucky Spin" />
                            </div>
                        </div>
                        <div className="x-container">
                            <div className="section-header-gray">
                                <h2>{t("lucky-spin.lucky-spin")}</h2>
                                <p>{t("lucky-spin.lucky-spin-desc")}</p>
                            </div>
                            <div className="ls-container">
                                <div className="row">
                                    <div className="col-9">
                                        <div className={`ls-wheel-container ${this.props.currentUser.language_code === "id" ? "indon-my" : ""}`}>
                                            <div className="wheel-btn" onClick={(!this.state.disableSearchButton) ? this.startToSpin : () => { }}>
                                                <img src={img["wheel-btn.webp"]} alt="wheel buttom" />
                                                <div className="wheel-btn-text">
                                                    <h2>{t("lucky-spin.spin")}</h2>
                                                    <p>
                                                        ({t("lucky-spin.left", { time: this.state.countRemain ? this.state.countRemain : 0 })})
                                                    </p>
                                                </div>
                                            </div>
                                            <Wheel
                                                mustStartSpinning={this.state.mustSpin}
                                                // spinDuration='0.2' // disable it for normal speed
                                                prizeNumber={this.state.prizeNumber}
                                                data={this.state.prizeArry}
                                                outerBorderColor={["transparent"]}
                                                radiusLineColor={["transparent"]}
                                                fontSize={false}
                                                backgroundColors={[
                                                    "transparent"
                                                ]}
                                                onStopSpinning={() => {
                                                    this.onStopSpinning();
                                                }}
                                            />
                                        </div>
                                        <div className="ls-total-coin">
                                            <img src={coin["coin.png"]} alt="coin" />
                                            <div>
                                                <p>R77 {t("withdraw.coin")} <span className="number">{this.state.coin || 0}</span></p>
                                                <small>*{t("lucky-spin.10-coin-1-spin")}</small>
                                            </div>
                                        </div>
                                        <div className="ls-btn-container">
                                            {
                                                localStorage.getItem("accessToken") ? (
                                                    <>
                                                        <button className="btn btn-gradient-blue" onClick={() => this.state.mustSpin === false ? this.props.funcStore() : undefined}>
                                                            {t("lucky-spin.10-coin-1-spin")}
                                                        </button>
                                                        <Link to={{ pathname: '/redemption-history' }} className="btn btn-success">
                                                            {t("profile-sidebar.redemption-history")}
                                                        </Link>
                                                    </>
                                                ) : (
                                                    <div className="mx-auto" style={{ width: '35%' }}>
                                                        <button className="w-100 btn btn-gradient-blue" onClick={(!this.state.disableSearchButton) ? this.startToSpin : () => { }}>
                                                            {t("lucky-spin.10-coin-1-spin")}
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                    <div className="col-3">
                                        <div className="winner-list">
                                            <Icon icon="mdi:star" />
                                            <h2 className="text-center">{t("lucky-spin.lucky-winner")}</h2>
                                            <Icon icon="mdi:star" />
                                        </div>
                                        <div className="events-table-container card-shadow">
                                            <table className="table table-borderless events-table">
                                                <thead>
                                                    <tr>
                                                        <th>{t("lucky-spin.winner-list")}</th>
                                                        <th>{t("lucky-spin.prize")}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.historyList && this.state.historyList.length > 0 && this.state.historyList.map((val, idx) => (
                                                        <tr key={idx}>
                                                            <td>{val.username}</td>
                                                            <td className="reward">{t(`lucky-spin.${val.slug}`, {currency: currency[this.props.currentUser.country_id] ?? ""})}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>

                                <div className="term-section mt-5">
                                    <div className="term-container card-shadow">
                                        {/* <img src={tNc[`${tncImageLanguage}`]} alt="Terms Condition" /> */}
                                        <div className="term-content">
                                            <ol>
                                                <li>
                                                    {t("lucky-spin.ls-tnc-1").replaceAll("##currency##", currency[this.props.currentUser.country_id] ?? "")}
                                                </li>
                                                <li>
                                                    {t("lucky-spin.ls-tnc-2")}
                                                </li>
                                                <li>
                                                    {t("lucky-spin.ls-tnc-3").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}
                                                </li>
                                                <li>
                                                    {t("lucky-spin.ls-tnc-4")}
                                                    <div className="custom-list ms-3">
                                                        <p>{t("lucky-spin.ls-sub-tnc-1")}</p>
                                                        <p>{t("lucky-spin.ls-sub-tnc-2")}</p>
                                                        <p>{t("lucky-spin.ls-sub-tnc-3").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</p>
                                                        <p>{t("lucky-spin.ls-sub-tnc-4")}</p>
                                                    </div>
                                                </li>
                                                <li>
                                                    {t("lucky-spin.ls-tnc-5")}
                                                </li>
                                                <li>
                                                    {t("lucky-spin.ls-tnc-6")}
                                                </li>
                                            </ol>
                                        </div>
                                        <img src={tNc["term-footer.png"]} alt="Terms Footer" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                ) : (
                    <section className="ls-m-section">
                        <SideBar />
                        <div className="banner-col">
                            <div className="banner-item">
                                <img src={banner[`lucky-spin-banner${imageLanguage}.webp`]} alt="R77 Lucky Spin" />
                            </div>
                        </div>

                        <div className="ls-m-container p-3">
                            <div className="ls-total-coin">
                                <img src={coin["coin.png"]} alt="coin" />
                                <div>
                                    <p>R77 {t("withdraw.coin")} <span className="number">{this.state.coin || 0}</span></p>
                                    <small>*{t("lucky-spin.10-coin-1-spin")}</small>
                                </div>
                            </div>

                            <div className={`ls-wheel-container ${this.props.currentUser.language_code === "id" ? "indon-my" : ""}`}>
                                <div className="wheel-btn" onClick={(!this.state.disableSearchButton) ? this.startToSpin : () => { }} >
                                    <img src={img["wheel-btn.webp"]} alt="wheel buttom" />
                                    <div className="wheel-btn-text">
                                        <h2>{t("lucky-spin.spin")}</h2>
                                        <p>({t("lucky-spin.left", { time: this.state.countRemain ? this.state.countRemain : 0 })})</p>
                                    </div>
                                </div>
                                <Wheel
                                    mustStartSpinning={this.state.mustSpin}
                                    // spinDuration='0.2' // disable it for normal speed
                                    prizeNumber={this.state.prizeNumber}
                                    data={this.state.prizeArry}
                                    outerBorderColor={["transparent"]}
                                    radiusLineColor={["transparent"]}
                                    fontSize={false}
                                    backgroundColors={[
                                        "transparent"
                                    ]}
                                    onStopSpinning={() => {
                                        this.onStopSpinning();
                                    }}
                                />
                            </div>

                            <div className="ls-btn-container">
                                {
                                    localStorage.getItem("accessToken") ? (
                                        <>
                                            <button className="btn btn-gradient-blue" onClick={() => this.state.mustSpin === false ? this.props.funcStore() : undefined}>
                                                {t("lucky-spin.10-coin-1-spin")}
                                            </button>
                                            <Link to={{ pathname: '/redemption-history' }} className="btn btn-success">
                                                {t("profile-sidebar.redemption-history")}
                                            </Link>
                                        </>
                                    ) : (
                                        <div className="mx-auto" style={{ width: '48%' }}>
                                            <button className="w-100 btn btn-gradient-blue" onClick={(!this.state.disableSearchButton) ? this.startToSpin : () => { }}>
                                                {t("lucky-spin.10-coin-1-spin")}
                                            </button>
                                        </div>
                                    )
                                }
                            </div>

                            <div className="mt-3 col-12">
                                <div className="winner-list">
                                    <Icon icon="mdi:star" />
                                    <h2 className="text-center">{t("lucky-spin.lucky-winner")}</h2>
                                    <Icon icon="mdi:star" />
                                </div>
                                <div className="events-table-container card-shadow">
                                    <table className="table table-borderless events-table">
                                        <thead>
                                            <tr>
                                                <th>{t("lucky-spin.winner-list")}</th>
                                                <th>{t("lucky-spin.prize")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {this.state.historyList && this.state.historyList.length > 0 && this.state.historyList.map((val, idx) => (
                                                <tr key={idx}>
                                                    <td>{val.username}</td>
                                                    <td className="reward">{t(`lucky-spin.${val.slug}`, {currency: currency[this.props.currentUser.country_id] ?? ""})}</td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div className="term-section no-bg mobile mt-3 p-3">
                            <div className="term-container card-shadow">
                                {/* <img src={mobileTnc[`${tncImageLanguage}`]} alt="Terms Condition" /> */}
                                <div className="term-content">
                                    <ol>
                                        <li>
                                            {t("lucky-spin.ls-tnc-1").replaceAll("##currency##", currency[this.props.currentUser.country_id] ?? "")}
                                        </li>
                                        <li>
                                            {t("lucky-spin.ls-tnc-2")}
                                        </li>
                                        <li>
                                            {t("lucky-spin.ls-tnc-3").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}
                                        </li>
                                        <li>
                                            {t("lucky-spin.ls-tnc-4")}
                                            <div className="custom-list">
                                                <p>{t("lucky-spin.ls-sub-tnc-1")}</p>
                                                <p>{t("lucky-spin.ls-sub-tnc-2")}</p>
                                                <p>{t("lucky-spin.ls-sub-tnc-3").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</p>
                                                <p>{t("lucky-spin.ls-sub-tnc-4")}</p>
                                            </div>
                                        </li>
                                        <li>
                                            {t("lucky-spin.ls-tnc-5")}
                                        </li>
                                        <li>
                                            {t("lucky-spin.ls-tnc-6")}
                                        </li>
                                    </ol>
                                </div>
                                <img src={tNc["term-footer.png"]} alt="Terms Footer" />
                            </div>
                        </div>
                    </section>
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = state => {
    return {
        rs: state.luckySpin.rs,
        store: state.luckySpin.store,
        currentUser: state.currentUser.data,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        funcGetList: (filter) => dispatch(actions.getList(filter)),
        funcStore: () => dispatch(actions.store()),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(LuckySpin));