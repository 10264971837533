import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import { default as ReactSelect } from "react-select";
import { Icon } from "@iconify/react";
import Dropzone from "react-dropzone";
import ReactTooltip from "react-tooltip";

import { isDesktop } from "react-device-detect";

//Components
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import { storedDepositDropdownValidation, storedSecretPayBankOptionsValidation } from "../../../common/config/depositDataValidation";

//Actions
import * as actions from "../../../store/ducks/deposit/actions";
import MobileBankTransfer from "./MobileBankTransfer";

import PromosTncModal from "../../../components/PromosTncModal/PromosTncModal";
import * as CryptoJS from "crypto-js";
import moment from "moment";
import StringInput from "../../../components/Input/StringInput/StringInput";

const depositAmts = [{ value: 30 }, { value: 50 }, { value: 100 }, { value: 300 }, { value: 500 }, { value: 1000 }, { value: 5000 }];

class BankTransfer extends Component {
    constructor(props) {
        super(props);
        this.onDrop = (files) => {
            this.setState({ files });
        };
        this.state = {
            promoOpts: "",
            depositAmt: "",
            files: [],
            input: {
                amount: "",
                bank_id: "",
                bank_account_id: "",
                // reference_code: `${this.randomString(5)}`,
                bank_last_five_digit: "",
                promo_id: "",
                paymentGatewaySelected: (this.props.paymentGatewaySelected) ? this.props.paymentGatewaySelected : "",
                bankOfflineBankAccName: "",
                bankOfflineBankConfigCode: "",
                bankOfflineBankAccNo: "",
            },
            dropDown: {
                bankAccount: [],
                groupSetting: [],
                promoList: [],
                lastDeposit: 0,
                paymentTypeId: "",
                paymentGatewayOptions: [],
            },
            dropDownBankAccOffline: {
                bankAccount: [],
                bankAccountName: [],
                bankAccountCode: [],
                bankAccountConfigCode: [],
            },
            loading: false,
            errorMsg: [],
            formError: false,
            // copyText: "",

            openPromoTncModal: false,
            showPromosTitle: "",
            showPromosContent: "",
            showPromosTable: "",

            isOptionLoading: true,
        };
        this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
    }

    componentDidMount() {
        formValidation("deposit_form");
        this.props.funcGetDropDown();

        this.fetchStorageDeposit();
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.bank_offline_option !== this.props.bank_offline_option && nextProps.bank_offline_option.data.length > 0) {
            let to_store_data = storedSecretPayBankOptionsValidation(this.props.default_secretpay_bank_offline_options, nextProps.bank_offline_option.data);

            if (to_store_data.overwrite === true) {
                let bankAccountOffline = [];
                let newInput = Object.assign({}, this.state.input);

                nextProps.bank_offline_option.data.forEach((val, index) => {
                    bankAccountOffline[index] = val;
                    if (bankAccountOffline[val.receiverAccNo] === undefined) {
                        bankAccountOffline[val.receiverAccNo] = val;
                    }
                    // newInput["bank_id"] = val.receiverAccNo;
                });

                // let temp = nextProps.bank_offline_option.data.filter((val) => val.maintenance_status === 0);
                newInput["bank_id"] = ( nextProps.bank_offline_option.data &&  nextProps.bank_offline_option.data[0]) ? nextProps.bank_offline_option.data[0].receiverAccNo : "";
                newInput["bankOfflineBankAccName"] =
                    nextProps.bank_offline_option.data && nextProps.bank_offline_option.data[0] ? nextProps.bank_offline_option.data[0].receiverAccName : "";
                newInput["bankOfflineBankConfigCode"] =
                    nextProps.bank_offline_option.data && nextProps.bank_offline_option.data[0] ? nextProps.bank_offline_option.data[0].receiverBkConfigCode : "";
                newInput["bankOfflineBankAccNo"] =
                    nextProps.bank_offline_option.data && nextProps.bank_offline_option.data[0] ? nextProps.bank_offline_option.data[0].receiverAccNo : "";
                // newInput["bank_account_id"] = (temp && temp[0]) ? temp[0].id: "";
                this.setState({
                    input: newInput,
                    isOptionLoading: false,
                    dropDownBankAccOffline: {
                        bankAccount: bankAccountOffline,
                    },
                    loading: false,
                });

                const storeOfflineBank = {};
                storeOfflineBank.data = nextProps.bank_offline_option.data;
                this.props.funcDefaultBankOfflineOptions(storeOfflineBank);
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            this.setState({
                isOptionLoading: false,
            });

            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                let to_store_data = storedDepositDropdownValidation(this.props.default_dropdown_options, nextProps.dropDown.data);
                if (to_store_data.overwrite === true) {
                    let bankAccount = [];
                    let newInput = Object.assign({}, this.state.input);
                    nextProps.dropDown.data.bank_account.forEach((val) => {
                        if (val.maintenance_status === 0) {
                            if (bankAccount[val.bank_id] === undefined) {
                                bankAccount[val.bank_id] = val;
                            }
                            newInput["bank_id"] = (newInput["bank_id"] !== "" && newInput["bank_id"] < val.bank_id) ? newInput["bank_id"] : val.bank_id;
                            newInput["bank_account_id"] = newInput["bank_account_id"] !== "" ? newInput["bank_account_id"] : val.id;
                        }
                    });

                    let temp = nextProps.dropDown.data.bank_account.filter((val) => val.maintenance_status === 0);
                    newInput["bank_id"] = temp && temp[0] ? temp[0].bank_id : "";
                    newInput["bank_account_id"] = temp && temp[0] ? temp[0].id : "";

                    this.setState({
                        input: newInput,
                        dropDown: {
                            bankAccount: bankAccount,
                            groupSetting: nextProps.dropDown.data.member_group,
                            promoList: nextProps.dropDown.data.promotion,
                            lastDeposit: nextProps.dropDown.data.last_deposit || 0,
                            paymentTypeId: nextProps.dropDown.data.payment_gateway[0].id,
                            paymentGatewayOptions: nextProps.dropDown.data.payment_gateway,
                        },
                        loading: false,
                    });

                    const storeDropdown = {};
                    storeDropdown.data = nextProps.dropDown.data;
                    this.props.funcDefaultDropDownOptions(storeDropdown);
                }
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            // console.log('shouldComponentUpdate (create):', nextProps.store);
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === "success") {
                if (nextProps.store.data.p_url) {
                    //Fpay
                    window.location.href = nextProps.store.data.p_url;
                } else if (nextProps.store.data.deposit_url) {
                    // SecretPay
                    window.location.href = nextProps.store.data.deposit_url;
                } // bank transfer
                else {
                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : "",
                        icon: "success",
                        title: t("global.success"),
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        timer: 2000,
                        customClass: !isDesktop
                            ? {
                                  container: "swal-mobile-container sucess",
                                  icon: "swal-success-icon",
                              }
                            : [],
                    }).then(() => {
                        clearFormValidation("deposit_form");
                        let newInput = Object.assign({}, this.state.input);
                        newInput["amount"] = "";
                        newInput["bank_last_five_digit"] = "";

                        this.setState({
                            // notSameDepositedAcc: false,
                            depositAmt: null,
                            input: newInput,
                            errorMsg: [],
                            formError: false,
                        });

                        this.props.redirectHandler();
                    });
                }
            } else {
                clearFormValidation("deposit_form");
                const { t } = this.props;

                if (nextProps.store.message.input_error) {
                    this.setState({
                        errorMsg: nextProps.store.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                } else {
                    let msg = null;
                    msg = "<div>";
                    nextProps.store.message.forEach((val) => {
                        msg += "<p>" + val + "</p>";
                    });
                    msg += "</div>";

                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: !isDesktop
                            ? {
                                  container: "swal-mobile-container sorry",
                                  icon: "swal-sorry-icon",
                              }
                            : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (nextProps.store.redirect && nextProps.store.redirect !== "" && nextProps.store.redirect !== null) {
                                window.location.href = nextProps.store.redirect;
                            }
                        }
                    });
                }
            }
        }

        return true;
    }

    fetchStorageDeposit = () => {
        if (this.props.default_dropdown_options !== undefined) {
            let bankAccount = [];
            let newInput = Object.assign({}, this.state.input);
            this.props.default_dropdown_options.data.bank_account.forEach((val) => {
                        if (val.maintenance_status === 0) {
                            if (bankAccount[val.bank_id] === undefined) {
                                bankAccount[val.bank_id] = val;
                            }
                            // newInput["bank_id"] = (newInput["bank_id"] !== "" && newInput["bank_id"] < val.bank_id) ? newInput["bank_id"] : val.bank_id;
                            // newInput["bank_account_id"] = newInput["bank_account_id"] !== "" ? newInput["bank_account_id"] : val.id;
                        }
                    });
            let temp = this.props.default_dropdown_options.data.bank_account.filter((val) => val.maintenance_status === 0);
            newInput["bank_id"] = temp && temp[0] ? temp[0].bank_id : "";
            newInput["bank_account_id"] = temp && temp[0] ? temp[0].id : "";

            this.setState({
                input: newInput,
                dropDown: {
                    bankAccount: bankAccount,
                    groupSetting: this.props.default_dropdown_options.data.member_group,
                    promoList: this.props.default_dropdown_options.data.promotion,
                    lastDeposit: this.props.default_dropdown_options.data.last_deposit || 0,
                    paymentTypeId: this.props.default_dropdown_options.data.payment_gateway[0].id,
                    paymentGatewayOptions: this.props.dropDown.data.payment_gateway,
                },
                loading: false,
                isOptionLoading: false,
            });
        }

        if (this.props.default_secretpay_bank_offline_options !== undefined && this.props.default_secretpay_bank_offline_options.data.length > 0) {
            let bankAccountOffline = [];
            let newInput = Object.assign({}, this.state.input);

            // newInput["bank_account_id"] = (temp && temp[0]) ? temp[0].id: "";

            this.props.default_secretpay_bank_offline_options.data.forEach((val, index) => {
                bankAccountOffline[index] = val;
                        if (bankAccountOffline[val.receiverAccNo] === undefined) {
                            bankAccountOffline[val.receiverAccNo] = val;
                        }
                    });
                    newInput["bankOfflineBankAccName"] =
                        this.props.default_secretpay_bank_offline_options.data && this.props.default_secretpay_bank_offline_options.data[0]
                            ? this.props.default_secretpay_bank_offline_options.data[0].receiverAccName
                            : "";
                    newInput["bankOfflineBankConfigCode"] =
                        this.props.default_secretpay_bank_offline_options.data && this.props.default_secretpay_bank_offline_options.data[0]
                            ? this.props.default_secretpay_bank_offline_options.data[0].receiverBkConfigCode
                            : "";
                    newInput["bankOfflineBankAccNo"] =
                        this.props.default_secretpay_bank_offline_options.data && this.props.default_secretpay_bank_offline_options.data[0]
                            ? this.props.default_secretpay_bank_offline_options.data[0].receiverAccNo
                            : "";

            // let temp = this.props.default_dropdown_options.data.bank_account.filter((val) => val.maintenance_status === 0);
            newInput["bank_id"] = ( this.props.bank_offline_option.data &&  this.props.bank_offline_option.data[0]) ? this.props.bank_offline_option.data[0].receiverAccNo : ""
            // newInput["bank_account_id"] = temp && temp[0] ? temp[0].id : "";
            this.setState({
                input: newInput,
                isOptionLoading: false,
                dropDownBankAccOffline: {
                    bankAccount: bankAccountOffline,
                },
                loading: false,
            });
        }
    };

    onInputChangeHandler = (event, value='', bankOfflineBankAccName='', bankOfflineBankCode='', receiverAccNo='') => {
        let newInput = Object.assign({}, this.state.input);
        let depositAmt = event.target.name === "depositAmt" ? event.target.value : this.state.depositAmt;

        if (event.target.name === "bonusCode") {
            event.target.value = event.target.value.toUpperCase();
        }

        newInput[event.target.name] = event.target.value;

        if (event.target.name === "bank_id") {
            if (this.state.dropDown.bankAccount[event.target.value]) {
                newInput["bank_account_id"] = this.state.dropDown.bankAccount[event.target.value].id;
            }
        }

        if (event.target.name === "depositAmt") {
            newInput["amount"] = event.target.value;
        }

        if (event.target.name.includes("amount")) {
            const splitedValue = event.target.value.split(".");
            if (splitedValue[1] && splitedValue[1].length > 2) {
                let val = parseFloat(event.target.value).toFixed(2);
                newInput[event.target.name] = val;
            }
            depositAmt = "";
        }

        if (event.target.name === "bank_id") {
            newInput["paymentGatewaySelected"] = value;
            newInput["bankOfflineBankAccName"] = bankOfflineBankAccName;
            newInput["bankOfflineBankConfigCode"] = bankOfflineBankCode;
            newInput["bankOfflineBankAccNo"] = receiverAccNo;
        }

        this.setState({
            input: newInput,
            depositAmt: depositAmt,
            errorMsg: [],
            formError: false,
        });
    };

    removeFileHandler = () => {
        this.setState({
            files: [],
            // progress: 0,
        });
    };

    randomString = (length) => {
        let result = "";
        // var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let characters = "0123456789";
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        let refRand = ["lazada", "shopee", "taobao"];
        let refLength = Math.floor(Math.random() * refRand.length);

        return refRand[refLength] + result;
    };

    selectPaymentGatewayHandler = (val) => {
        // console.log(val);
        let newInput = Object.assign({}, this.state.input);
        let dropDown = Object.assign({}, this.state.dropDown);
        newInput['paymentGatewaySelected'] = val.slug;
        dropDown['paymentTypeId'] = val.id;
        
        if(val.slug === 'secret_pay_offline') {
            newInput["bank_id"] = (this.state.dropDownBankAccOffline.bankAccount && this.state.dropDownBankAccOffline.bankAccount[0]) ? this.state.dropDownBankAccOffline.bankAccount[0].receiverAccNo : "";
            newInput["bankOfflineBankAccName"] = this.state.dropDownBankAccOffline.bankAccount && this.state.dropDownBankAccOffline.bankAccount[0] ? this.state.dropDownBankAccOffline.bankAccount[0].receiverAccName : "";
            newInput["bankOfflineBankConfigCode"] = this.state.dropDownBankAccOffline.bankAccount && this.state.dropDownBankAccOffline.bankAccount[0] ? this.state.dropDownBankAccOffline.bankAccount[0].receiverBkConfigCode : "";
            newInput["bankOfflineBankAccNo"] = this.state.dropDownBankAccOffline.bankAccount && this.state.dropDownBankAccOffline.bankAccount[0] ? this.state.dropDownBankAccOffline.bankAccount[0].receiverAccNo : "";            
        } else {
            this.state.dropDown.bankAccount.forEach((val) => {
                if (val.maintenance_status === 0) {
                    newInput["bank_id"] = (newInput["bank_id"] !== "" && newInput["bank_id"] < val.bank_id) ? newInput["bank_id"] : val.bank_id;
                    newInput["bank_account_id"] = newInput["bank_account_id"] !== "" ? newInput["bank_account_id"] : val.id;
                }
            });
            newInput["bankOfflineBankAccName"] = "";
            newInput["bankOfflineBankConfigCode"] = "";
            newInput["bankOfflineBankAccNo"] = "";            
        }

        this.setState({
            input: newInput,
            dropDown,
        });
    };

    promoChangeHandler = (event) => {
        let newInput = Object.assign({}, this.state.input);
        newInput["promo_id"] = event === null || event.id === "" ? "" : event.id;

        let showBonus = this.state.dropDown.promoList.filter((val) => event !== null && val.id === event.id);
        let showBonusTitle = showBonus && showBonus[0] && showBonus[0].title ? showBonus[0].title : "";
        let showBonusContent = showBonus && showBonus[0] && showBonus[0].content ? showBonus[0].content : "";

        this.setState({
            promoOpts: event !== null && event.id > 0 ? event : "",
            input: newInput,
            showPromosTitle: showBonusTitle,
            showPromosContent: showBonusContent,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    copyToClipboard = (e, ref) => {
        const { t } = this.props;
        ref.select();
        document.execCommand("copy");
        e.target.focus();
        this.setState({ copySuccess: `${t("global.copied")}!`, copyText: ref.name });
        setTimeout(() => this.setState({ copyText: "" }), 1000);
        // console.log(ref)
    };

    confirmHandler = (e) => {
        // console.log('confirmHandler :', this.state);
        e.preventDefault();
        if (this.state.input.promo_id > 0) {
            const { t } = this.props;
            let pickedBonus = this.state.dropDown.promoList.filter((val, idx) => {
                if (val.id === this.state.input.promo_id) {
                    return val;
                }
                return null;
            });
            // console.log(pickedBonus);
            const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
            let bonusTitle = pickedBonus && pickedBonus[0] && pickedBonus[0].title ? pickedBonus[0].title : "";
            let bonusTurnover = pickedBonus && pickedBonus[0] && pickedBonus[0].turnover_multiply ? pickedBonus[0].turnover_multiply : 0;
            let bonusPercentage = pickedBonus && pickedBonus[0] && pickedBonus[0].percentage ? pickedBonus[0].percentage : 0;
            let bonusMaxPayout = pickedBonus && pickedBonus[0] && pickedBonus[0].max_payout ? pickedBonus[0].max_payout : 0;
            let promotionAmt = (parseFloat(this.state.input.amount) * parseFloat(bonusPercentage)) / 100;
            // console.log(promotionAmt);
            let bonusAmt = promotionAmt > parseFloat(bonusMaxPayout) ? bonusMaxPayout : promotionAmt;
            // console.log(bonusAmt);
            let totalRollover = (parseFloat(this.state.input.amount) + parseFloat(bonusAmt)) * bonusTurnover;
            // console.log(totalRollover);
            let htmlText =
                "<div>" +
                t("deposit.deposit-warning-msg-1").replace("_promotion_", bonusTitle).replace("_turnover_", bonusTurnover) +
                "</div><br>" +
                '<div style="text-align: left">' +
                t("deposit.deposit-warning-msg-2") +
                `: ${this.props.currentUser.currency} ${this.state.input.amount}</div>` +
                '<div style="text-align: left">' +
                t("deposit.deposit-warning-msg-3") +
                `: ${bonusPercentage}%</div>` +
                '<div style="text-align: left">' +
                t("deposit.deposit-warning-msg-4") +
                `: ${this.props.currentUser.currency} ${new Intl.NumberFormat("en-US", num_format).format(totalRollover)}</div>`;
            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                title: t("validation.promotion_confirmation"),
                html: htmlText,
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: !isDesktop
                    ? {
                          container: "swal-mobile-container reminder",
                          icon: "swal-reminder-icon",
                      }
                    : [],
            }).then((result) => {
                if (result.isConfirmed) {
                    this.submitHandler();
                }
            });
        } else {
            this.submitHandler();
        }
    };

    submitHandler = () => {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        let formData = new FormData();
        Object.keys(this.state.input).forEach((val) => {
            formData.append(val, this.state.input[val]);
        });

        formData.append("transaction_channel_id", this.props.transactionChannelId);
        formData.append('platform', this.props.platform);
        // if (this.state.input.paymentGatewaySelected !== "") {
        //     formData.append("payment_type", "quick-pay");
        //     formData.append("payment_gateway", this.state.input.paymentGatewaySelected);
        //     formData.append("receiverAccNo", this.state.input.bankOfflineBankAccNo);
        //     formData.append("receiverAccName", this.state.input.bankOfflineBankAccName);
        //     formData.append("receiverBkConfigCode", this.state.input.bankOfflineBankConfigCode);
        //     formData.append("callbackUrl", process.env.REACT_APP_PAYMENT_GATEWAY_URL + "/payment_gateway/secret_pay/deposit_success");
        //     formData.append("ownerBy", "test");
        // } else {
        //     formData.append("payment_type", this.props.depositOption);
        // }
        // if (this.props.depositOption === "quick-pay") {
        //     // formData.append('transaction_channel_id', this.state.input.transaction_channel_id);
        //     formData.append("payment_gateway", this.state.paymentGatewaySelected);
        //     formData.append("currency", this.props.currentUser.currency);
        //     formData.append("email", this.props.currentUser.email);
        //     formData.append("phone_number", this.props.currentUser.contact_no);
        //     // formData.append('amount', this.state.input.amount);
        //     formData.append("promo_id", this.state.input.promo_id);
        //     formData.append("redirect_url", window.location.href);

        //     if (this.state.paymentGatewaySelected === "fpay") {
        //         formData.append("payment_type_id", 7);
        //     }

        //     if (this.state.paymentGatewaySelected === "secret_pay") {
        //         formData.append("payment_type_id", 10);
        //         formData.append("currencyCode", this.props.currentUser.currency);
        //         formData.append("bankCode", this.state.secretPayBankCode);
        //         formData.append("ownerBy", "test");
        //     }
        // } else {
            if (this.props.depositOption === "bank-transfer" && this.state.files[0]) {
                formData.append("file", this.state.files[0]);
            }
            
            // if (this.props.currentUser.country_id === 4) {
                formData.append("payment_type_id", this.props.bankTransferPaymentTypeId[this.state.input.paymentGatewaySelected]);
                formData.append("payment_gateway", this.state.input.paymentGatewaySelected);
            // } else 
            if (this.state.input.paymentGatewaySelected === "secret_pay_offline") {

                // if (this.state.input.paymentGatewaySelected !== "") {
                    formData.append("payment_type", "quick-pay");
                // }else{
                //     formData.append("payment_type", this.props.depositOption);
                // }

                formData.append("receiverAccNo", this.state.input.bankOfflineBankAccNo);
                formData.append("receiverAccName", this.state.input.bankOfflineBankAccName);
                formData.append("receiverBkConfigCode", this.state.input.bankOfflineBankConfigCode);
                formData.append("callbackUrl", process.env.REACT_APP_PAYMENT_GATEWAY_URL + "/payment_gateway/secret_pay/deposit_success");
                formData.append("ownerBy", this.props.currentUser.id);

                formData.append("currency", this.props.currentUser.currency);
                formData.append("email", this.props.currentUser.email);
                formData.append("phone_number", this.props.currentUser.contact_no);
                formData.append("redirect_url", window.location.href);

                // formData.append("payment_type_id", this.props.bankTransferPaymentTypeId);
                formData.append("fees", (this.state.input.amount * this.props.bankOfflineFees) / 100);
            } 
            else {
                formData.append("payment_type", this.props.depositOption);
            }
        // }

        // HASHING ALGO
        formData.append("trxTime", moment().unix());
        let toHashStr = "";
        let hashStr = "";
        let paramsKey = [];
        for (var to_hash of formData.entries()) {
            if (!paramsKey.includes(to_hash[0])) {
                paramsKey.push(to_hash[0]);
            }
        }
        paramsKey.sort();
        paramsKey.forEach((val) => {
            let x = formData.get(val);
            if (x !== "") {
                if (val === "file") {
                    // file required hashing params
                    toHashStr += val + x.name + x.size;
                } else {
                    toHashStr += val + x;
                }
            }
        });
        toHashStr += process.env.REACT_APP_HASH_SECRET;
        hashStr = CryptoJS.MD5(toHashStr).toString();
        formData.append("trxHash", hashStr);

        // for (var pair of formData.entries())
        // {
        //     console.log(pair[0]+ ' = ' + pair[1]);
        // }

        this.props.funcStore(formData);
    };

    toggleTncModalHandler = () => {
        this.setState({
            openPromoTncModal: !this.state.openPromoTncModal,
        });
    };



    render() {
        const { t } = this.props;

        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
        const bankImg = this.imageImport(require.context("../../../assets/images/profile/banks", false, /\.(png|jpe?g|svg)$/));
        const payment = this.imageImport(require.context("../../../assets/images/profile/payment", false, /\.(png|jpe?g|svg)$/));

        let paymentGateway = this.state.dropDown.paymentGatewayOptions ? this.state.dropDown.paymentGatewayOptions : [];
        let bankAccount = this.state.dropDown.bankAccount ? this.state.dropDown.bankAccount : [];
        let bankAccountOffline = this.state.dropDownBankAccOffline.bankAccount ? this.state.dropDownBankAccOffline.bankAccount : [];
        let promoArry = [];
        let groupSetting = this.state.dropDown.groupSetting ? this.state.dropDown.groupSetting : [];
        let lastDeposit = this.state.dropDown.lastDeposit.last_deposit || 0;

        let files = this.state.files.map((file) => (
            <li key={file.name}>
                <p>
                    {file.name} - {Math.round(file.size / 1024)} KB{" "}
                    <button className="btn icon" onClick={this.removeFileHandler}>
                        <Icon icon="mdi:close" />
                    </button>
                </p>
            </li>
        ));

        if (this.state.dropDown.promoList && !this.state.loading) {
            if (isDesktop) {
                promoArry.push({
                    id: "",
                    label: t("global.none"),
                    key: "",
                });
            }
            // promoArry = this.state.dropDown.promoList.map((val, idx) => {
            //     return {
            //         id: val.id,
            //         label: val.title,
            //         key: idx,
            //     };
            // });
            this.state.dropDown.promoList.map((val, idx) => {
                return promoArry.push({
                    id: val.id,
                    label: val.title,
                    key: idx,
                });
            });
        }
        return isDesktop ? (
            <form className="forms-col needs-validation" method="post" id="deposit_form" encType="multipart/form-data" onSubmit={this.confirmHandler} noValidate>
                {this.state.loading ? (
                    <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                ) : (
                    <>
                        <PromosTncModal {...this.state} {...this.props} toggleTncModalHandler={this.toggleTncModalHandler} />
                        <div className="row">
                            <label className="col-3 col-form-label">{t('deposit.payment-option')}:</label>
                            <div className="col-9 select-bank-opts">
                                {paymentGateway.length > 0 ? (
                                    paymentGateway.map((val, idx) => {
                                        if (val.transaction_slug === "bank-transfer" && val.dp_status === 1) {
                                            let slug = (t(`payment-type.${val.slug}`) !== `payment-type.${val.slug}`) ? t(`payment-type.${val.slug}`) : val.slug.replaceAll('_', ' ');
                                            return (
                                                <div className="form-check form-check-inline" key={idx}>
                                                    <input
                                                        className="btn-check"
                                                        type="radio"
                                                        id={`payment${val.slug}`}
                                                        value={val.slug}
                                                        checked={this.state.input.paymentGatewaySelected === val.slug ? true : false}
                                                        onChange={() => {}}
                                                    />
                                                    <label className="btn form-check-label" htmlFor={`payment${val.slug}`} onClick={() => this.selectPaymentGatewayHandler(val)}>
                                                        <img className="active" src={payment[`${val.slug}.svg`]} alt={val.slug} />
                                                        <p>{slug}</p>
                                                        {val.deposit_est_time && (
                                                            <div className="transfer-duration">
                                                                {val.deposit_est_time === "00:00:00" ? (
                                                                <>
                                                                    <Icon icon="ant-design:thunderbolt-outlined" />
                                                                        {t("deposit.instant-deposit")}
                                                                </>
                                                                ) : (
                                                                <>
                                                                    <Icon icon="ic:baseline-alarm" />
                                                                        {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                </>
                                                                )}
                                                                                
                                                            </div>                 
                                                        )}
                                                    </label>
                                                </div>
                                            )
                                        }
                                        else {
                                            return null;
                                        }
                                    })
                                ) : (
                                    this.state.isOptionLoading === false ? 
                                        <span className="text-danger">{t('deposit.invalid-payment-gateway')}</span> :
                                        <Skeleton />
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-3 col-form-label">{t("deposit.choose-a-bank")}:</label>
                            <div className="col-9 align-self-center">
                                {(this.state.input.paymentGatewaySelected === 'secret_pay_offline')
                                ?
                                    (bankAccountOffline.length > 0)
                                    ?
                                    (
                                        <div className={`form-check-group select-bank-opts ${this.state.errorMsg.bank_id ? "form-control is-invalid" : ""}`}>
                                            {bankAccountOffline.length > 0 &&
                                                bankAccountOffline.map((val, index) => {
                                                    let bankColorImage =
                                                        bankImg[`${val.receiverBankCode.toLowerCase()}-color.svg`] !== undefined
                                                            ? bankImg[`${val.receiverBankCode.toLowerCase()}-color.svg`]
                                                            : bankImg["default-bank-icon-color.png"];

                                                    return (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="bank_id"
                                                                id={`bankOpt${val.receiverAccNo}`}
                                                                value={val.receiverAccNo}
                                                                onChange={(event) =>
                                                                    this.onInputChangeHandler(event, "secret_pay_offline", val.receiverAccName, val.receiverBkConfigCode, val.receiverAccNo)
                                                                }
                                                                checked={Number(this.state.input.bank_id) === Number(val.receiverAccNo) ? true : false}
                                                            />
                                                            <label className="btn form-check-label" htmlFor={`bankOpt${val.receiverAccNo}`}>
                                                                <img src={bankColorImage} alt={val.icon} />
                                                                <p>
                                                                    {(() => {
                                                                        switch (val.receiverBankCode) {
                                                                            case "MBB":
                                                                                return "Maybank";
                                                                            case "HLB": 
                                                                                return "Hong Leong Bank";
                                                                            case "CIMB":
                                                                                return "CIMB Bank";
                                                                            default:
                                                                                return "RHB Bank";
                                                                        }
                                                                    })()}
                                                                </p>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                        </div>
                                    )
                                    :
                                    (<span className="text-danger">{t("deposit.invalid-bank")}</span>)
                                : 
                                    (this.state.input.paymentGatewaySelected === 'online_banking')
                                    ?
                                        (bankAccount.length > 0)
                                        ?
                                        (
                                            <div className={`form-check-group select-bank-opts ${this.state.errorMsg.bank_id ? "form-control is-invalid" : ""}`}>
                                                {bankAccount.map((val, index) => {
                                                    let bankColorImage =
                                                        bankImg[`${val.swift_code.toLowerCase()}-color.svg`] !== undefined
                                                            ? bankImg[`${val.swift_code.toLowerCase()}-color.svg`]
                                                            : bankImg["default-bank-icon-color.png"];

                                                    return (
                                                        <div className="form-check form-check-inline" key={index}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="bank_id"
                                                                id={`bankOpt${val.bank_id}`}
                                                                value={val.bank_id}
                                                                onChange={(event) => this.onInputChangeHandler(event, "online_banking")}
                                                                checked={Number(this.state.input.bank_id) === Number(val.bank_id) ? true : false}
                                                            />
                                                            <label className="btn form-check-label" htmlFor={`bankOpt${val.bank_id}`}>
                                                                <img src={bankColorImage} alt={val.icon} />
                                                                <p>{val.bank_name}</p>
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        )
                                        :
                                        (<span className="text-danger">{t("deposit.invalid-bank")}</span>)
                                    :
                                        (<span className="text-danger">{t("deposit.invalid-bank")}</span>)
                                }
                                <div className="invalid-feedback">{this.state.errorMsg.bank_id || ""}</div>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-3 col-form-label">{t("deposit.bank-account-name")}:</label>
                            <div className="col-9">
                                <div className="input-group right">
                                    {/* {bankAccountOffline[this.state.input.bank_id] && this.state.input.paymentGatewaySelected === 'secret_pay_offline' ? (
                                        <input
                                            type="text"
                                            id="bankAccName"
                                            name="bankAccName"
                                            className="form-control"
                                            value={bankAccountOffline[this.state.input.bank_id] ? bankAccountOffline[this.state.input.bank_id].receiverAccName : ""}
                                            ref={(c) => (this.copyBankName = c)}
                                            readOnly
                                        />
                                    ) : (
                                        <input
                                            type="text"
                                            id="bankAccName"
                                            name="bankAccName"
                                            className="form-control"
                                            value={bankAccount[this.state.input.bank_id] ? bankAccount[this.state.input.bank_id].bank_acc_name : ""}
                                            ref={(c) => (this.copyBankName = c)}
                                            readOnly
                                        />
                                    )} */}

                                    <input
                                        type="text"
                                        id="bankAccName"
                                        name="bankAccName"
                                        className="form-control"
                                        value={
                                            (this.state.input.paymentGatewaySelected === 'online_banking')
                                            ?
                                                (bankAccount[this.state.input.bank_id] && bankAccount[this.state.input.bank_id].bank_acc_name) 
                                                ? 
                                                    bankAccount[this.state.input.bank_id].bank_acc_name 
                                                :
                                                    ''
                                            :
                                                (this.state.input.paymentGatewaySelected === 'secret_pay_offline')
                                                ?
                                                    (bankAccountOffline[this.state.input.bank_id] && bankAccountOffline[this.state.input.bank_id].receiverAccName) 
                                                    ? 
                                                        bankAccountOffline[this.state.input.bank_id].receiverAccName 
                                                    :
                                                        ''
                                                :
                                                    ''
                                        }
                                        ref={(c) => (this.copyBankName = c)}
                                        readOnly
                                    />
                                    <span className="input-group-text">
                                        {/* {
                                                this.state.copyText === "bankAccName" && <button className="btn btn-gradient-blue show-copied" disabled>{t('global.copied')}</button>
                                            } */}

                                        <button
                                            type="button"
                                            data-tip={t("global.copied")}
                                            data-event="click1 focus"
                                            className="btn icon"
                                            onClick={(e) => this.copyToClipboard(e, this.copyBankName, "acc-no")}
                                        >
                                            <Icon icon="mdi:content-copy" />
                                        </button>
                                        <ReactTooltip globalEventOff="click1" className="custom-tooltip" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-3 col-form-label">{t("deposit.account-number")}:</label>
                            <div className="col-9">
                                <div className="input-group right">
                                    {/* {bankAccountOffline[this.state.input.bank_id] && this.state.input.paymentGatewaySelected === 'secret_pay_offline' ? (
                                        <input
                                            type="number"
                                            id="bankAccNo"
                                            name="bankAccNo"
                                            className="form-control"
                                            value={bankAccountOffline[this.state.input.bank_id] ? bankAccountOffline[this.state.input.bank_id].receiverAccNo : ""}
                                            ref={(c) => (this.copyBankNum = c)}
                                            readOnly
                                        />
                                    ) : (
                                        <input
                                            type="number"
                                            id="bankAccNo"
                                            name="bankAccNo"
                                            className="form-control"
                                            value={bankAccount[this.state.input.bank_id] ? bankAccount[this.state.input.bank_id].bank_acc_number : ""}
                                            ref={(c) => (this.copyBankNum = c)}
                                            readOnly
                                        />
                                    )} */}
                                    <input
                                        type="number"
                                        id="bankAccNo"
                                        name="bankAccNo"
                                        className="form-control"
                                        value={
                                            (this.state.input.paymentGatewaySelected === 'online_banking')
                                            ?
                                                (bankAccount[this.state.input.bank_id] && bankAccount[this.state.input.bank_id].bank_acc_number) 
                                                ? 
                                                    bankAccount[this.state.input.bank_id].bank_acc_number
                                                :
                                                    ''
                                            :
                                                (this.state.input.paymentGatewaySelected === 'secret_pay_offline')
                                                ?
                                                    (bankAccountOffline[this.state.input.bank_id] && bankAccountOffline[this.state.input.bank_id].receiverAccNo)
                                                    ? 
                                                        bankAccountOffline[this.state.input.bank_id].receiverAccNo
                                                    :
                                                        ''
                                                :
                                                    ''
                                        }
                                        ref={(c) => (this.copyBankNum = c)}
                                        readOnly
                                    />
                                    <span className="input-group-text">
                                        {/* {
                                                this.state.copyText === "bankAccNo" && <button className="btn btn-gradient-blue show-copied" disabled>{t('global.copied')}</button>
                                            } */}
                                        <button
                                            type="button"
                                            className="btn icon"
                                            data-tip={t("global.copied")}
                                            data-event="click2 focus"
                                            onClick={(e) => this.copyToClipboard(e, this.copyBankNum)}
                                        >
                                            <Icon icon="mdi:content-copy" />
                                        </button>
                                        <ReactTooltip globalEventOff="click2" className="custom-tooltip" />
                                    </span>
                                </div>
                            </div>
                        </div>
                        {/* <div className="row">
                                <label className="col-3 col-form-label">{t('deposit.reference-code')}:</label>
                                <div className="col-9">
                                    <div className="input-group right">
                                        <input
                                            type="text"
                                            id="referenceCode"
                                            name="referenceCode"
                                            className="form-control"
                                            value={this.state.input.reference_code}
                                            ref={c => this.copyRefCode = c}
                                            readOnly
                                        />
                                        <span className="input-group-text">
                                            {
                                                this.state.copyText === "referenceCode" && <button className="btn btn-gradient-blue" disabled>{t('global.copied')}</button>
                                            }
                                            <button type="button" className="btn icon" onClick={(e) => this.copyToClipboard(e, this.copyRefCode)}>
                                                <Icon icon="mdi:content-copy" />
                                            </button>
                                        </span>
                                    </div>
                                </div>
                            </div> */}
                        <div className="row">
                            <label className="col-3 col-form-label">{t("deposit.promotion")}:</label>
                            <div className="col-9">
                                <ReactSelect
                                    classNamePrefix="react-select"
                                    className="w-100"
                                    placeholder={t("history.select-promotion")}
                                    name="selectPromo"
                                    options={promoArry}
                                    onChange={this.promoChangeHandler}
                                    value={this.state.promoOpts}
                                    // isClearable={true}
                                />
                                {this.state.promoOpts && this.state.promoOpts.id && (
                                    <p className="tnc-text" onClick={this.toggleTncModalHandler}>
                                        {" "}
                                        {t("deposit.tnc-apply")}{" "}
                                    </p>
                                )}
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-3 col-form-label">{t("transaction-type.bonus-code")}</label>
                            <div className="col-9 d-grid gap-2">
                                <div className="input-group left has-validation">
                                    <StringInput
                                        id="bonusCode"
                                        name="bonusCode"
                                        className={`form-control ${this.state.errorMsg.bonusCode && "was-validated is-invalid"}`}
                                        value={this.state.input.bonusCode}
                                        placeholder={`${t("global.insert")} ${t("transaction-type.bonus-code")}`}
                                        onChange={(event) => this.onInputChangeHandler(event)}
                                        formError={this.state.formError}
                                        errorMsg={this.state.errorMsg.bonusCode || ""}
                                        autoComplete="off"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            {process.env.REACT_APP_ENV === "staging" && this.props.currentUser.country_id === 4 &&
                                <span className="col-9 offset-3" style={{color: "#4a89fc"}}>Satuan 50 = IDR 50.000</span>
                            }
                            <label className="col-3 col-form-label">{t("deposit.deposit-amount")}:</label>
                            <div className="col-9 d-grid gap-2">
                                <div className={`input-group left has-validation ${this.props.currentUser.country_id === 4 && "indonesia" }`}>
                                    <span className="input-group-text">{this.props.currentUser.currency}</span>
                                    <NumberInput
                                        id="amount"
                                        name="amount"
                                        // className="form-control"
                                        className={`form-control ${this.state.errorMsg.amount && "was-validated is-invalid"}`}
                                        aria-label="Username"
                                        aria-describedby="depositAmtHelp"
                                        value={this.state.input.amount}
                                        placeholder={`${t("deposit.min-max-limit")} ${new Intl.NumberFormat("en-US", num_format).format(
                                            this.state.input.paymentGatewaySelected === "secret_pay_offline" ? this.props.bankOfflineMin : groupSetting.min_deposit || 0.0
                                        )}/${new Intl.NumberFormat("en-US", num_format).format(
                                            this.state.input.paymentGatewaySelected === "secret_pay_offline" ? this.props.bankOfflineMax : groupSetting.max_deposit || 0.0
                                        )}`}
                                        onChange={(event) => this.onInputChangeHandler(event)}
                                        required={true}
                                        min={this.state.input.paymentGatewaySelected === "secret_pay_offline" ? this.props.bankOfflineMin : groupSetting.min_deposit || 0}
                                        max={this.state.input.paymentGatewaySelected === "secret_pay_offline" ? this.props.bankOfflineMax : groupSetting.max_deposit || 0}
                                        step="any"
                                        onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                        minValueMessage={t("validation.amtMinValue", {
                                            0: this.state.input.paymentGatewaySelected === "secret_pay_offline" ? this.props.bankOfflineMin : groupSetting.min_deposit,
                                        })}
                                        maxValueMessage={t("validation.amtMaxValue", {
                                            0: this.state.input.paymentGatewaySelected === "secret_pay_offline" ? this.props.bankOfflineMax : groupSetting.max_deposit,
                                        })}
                                        requiredMessage={t("validation.required")}
                                        formError={this.state.formError}
                                        errorMsg={this.state.errorMsg.amount || ""}
                                    />
                                </div>
                                <div className="form-check-group deposit-amts">
                                    {depositAmts &&
                                        depositAmts.map((depositAmt, index) => (
                                            <div className="form-check form-check-inline" key={index}>
                                                <input
                                                    className="btn-check"
                                                    type="radio"
                                                    name="depositAmt"
                                                    id={`depositAmt${depositAmt.value}`}
                                                    value={depositAmt.value}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    checked={Number(this.state.depositAmt) === Number(depositAmt.value) ? true : false}
                                                />
                                                <label className="btn form-check-label" htmlFor={`depositAmt${depositAmt.value}`}>
                                                    {depositAmt.value}
                                                </label>
                                            </div>
                                        ))}
                                </div>
                                <small>
                                    {t("deposit.last-deposit-amount")}:{" "}
                                    <span style={{ fontWeight: "600" }}>
                                        {this.props.currentUser.currency}
                                        {lastDeposit}
                                    </span>
                                </small>
                            </div>
                        </div>
                        <div className="row">
                            <label className="col-3 col-form-label">{t("deposit.deposit-attachment-file")}:</label>
                            <div className="col-9">
                                <Dropzone
                                    maxFiles={1}
                                    accept={"image/*"}
                                    // maxSize={500000}
                                    onDrop={this.onDrop}
                                >
                                    {({ getRootProps, getInputProps }) => (
                                        <div className={`dropzone-container ${this.state.errorMsg.file ? "form-control is-invalid" : ""}`}>
                                            {files && files.length ? (
                                                <aside>
                                                    <ul>{files}</ul>
                                                </aside>
                                            ) : (
                                                ""
                                            )}
                                            <div {...getRootProps({ className: "dropzone" })}>
                                                <div>
                                                    <input {...getInputProps()} />
                                                    <Icon icon="pajamas:upload" />
                                                    <p>{t("deposit.drop-file-msg-1")}</p>
                                                    <p>{t("deposit.drop-file-msg-3")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </Dropzone>
                                <div className="invalid-feedback">{this.state.errorMsg.file || ""}</div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-9 offset-3">
                                <div className="dropzone-container">
                                    {files && files.length ? (
                                        <aside>
                                            <ul>{files}</ul>
                                        </aside>
                                    ) : (
                                        ""
                                    )}
                                </div>
                                <div className="d-flex">
                                    {/* <Dropzone
                                            maxFiles={1}
                                            accept={'image/*'}
                                            // maxSize={500000}
                                            onDrop={this.onDrop}>
                                            {({ getRootProps, getInputProps }) => (                                    
                                                <div className={`dropzone-container d-inline-block me-2 ${(this.state.errorMsg.file) ? 'form-control is-invalid' : ''}`}>
                                                    <button {...getRootProps({ className: 'btn btn-gradient-blue' })} type="button">
                                                        <input {...getInputProps()} />
                                                        Upload
                                                    </button>
                                                    <div className="invalid-feedback">{this.state.errorMsg.file || ''}</div>
                                                </div>
                                            )}
                                        </Dropzone> */}
                                    <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                        {t("global.confirm")}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </>
                )}
            </form>
        ) : (
            <MobileBankTransfer
                depositAmts={depositAmts}
                onDrop={this.onDrop}
                onInputChangeHandler={this.onInputChangeHandler}
                promoChangeHandler={this.promoChangeHandler}
                copyToClipboard={this.copyToClipboard}
                confirmHandler={this.confirmHandler}
                toggleTncModalHandler={this.toggleTncModalHandler}
                selectPaymentGatewayHandler={this.selectPaymentGatewayHandler}
                numFormat={num_format}
                bankImg={bankImg}
                payment={payment}
                bankAccount={bankAccount}
                bankAccountOffline={bankAccountOffline}
                promoArry={promoArry}
                groupSetting={groupSetting}
                lastDeposit={lastDeposit}
                dropFiles={files}
                isOptionLoading={this.state.isOptionLoading}
                {...this.state}
                {...this.props}
            />
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        dropDown: state.deposit.dropDown,
        store: state.deposit.store,
        currentUser: state.currentUser.data,
        default_dropdown_options: state.deposit.default_dropdown_options,
        bank_offline_option: state.deposit.bank_offline_option,
        default_secretpay_bank_offline_options: state.deposit.default_secretpay_bank_offline_options,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcStore: (data) => dispatch(actions.store(data)),
        funcDefaultDropDownOptions: (data) => dispatch(actions.defaultDropDownOptions(data)),
        funcGetBankOfflineOptions: (data) => dispatch(actions.getSecretPayBankOfflineOption(data)),
        funcDefaultBankOfflineOptions: (data) => dispatch(actions.defaultSecretPayBankOfflineOptions(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BankTransfer));
