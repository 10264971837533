import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Miscellaneous
import { Icon } from '@iconify/react';
import { Fade } from "react-awesome-reveal";
// import { keyframes } from "@emotion/react";

//Components
import DataTable from "../../../components/Datatable/DataTable";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import Button from '../../../components/Button/Button';
import DateScroller from '../../../components/MobileAsset/DateScroller/DateScroller';

class MobileBettingHistory extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            filterShow: '',
        };
    }

    componentDidMount() { }

    showTypeHandler = () => {
        this.setState({
            filterShow: 'type'
        })
    }

    showDateHandler = () => {
        this.setState({
            filterShow: 'date'
        })
    }

    showProviderHandler = () => {
        this.setState({
            filterShow: 'provider'
        })
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    render() {
        const { t } = this.props;
        const providers = this.imageImport(require.context("../../../assets/images/profile/provider-icon/mobile", false, /\.(png|jpe?g|svg|webp)$/));
        return (
            <Auxiliary>
                <DateScroller
                    onChangeDateHandler={this.props.onChangeDateHandler}
                    toggleShowDateSelect={this.props.toggleShowDateSelect}
                    {...this.state}
                    {...this.props}
                />
                <section className="pf-m-dashboard">
                    <div className={`filters-m-col ${this.state.filterShow ? 'show' : ''}`}>
                        <MobileSubHeader
                            // className="bg-white"
                            returnPage="/me"
                            header={t('profile-sidebar.betting-history')}
                            style={{ justifyContent: "unset" }}
                        />
                        <div className="filters-m-panel">
                            <div className="filter-m-tp-col">
                                <div className="filter-m-cat">
                                    <label className="filter-m-label">{t('history.type')}:</label>
                                    <button className={`btn btn-m-filter ${this.state.filterShow === 'type' && 'active'}`} type="button" onClick={this.showTypeHandler}>
                                        <p>
                                            {
                                                this.props.game_type === '' ? (
                                                    t(`global.all`)
                                                ) :
                                                    t(`game-type.${this.props.game_type}`)
                                            }
                                        </p>
                                        <Icon icon="dashicons:arrow-down" />
                                    </button>
                                </div>
                                <div className="filter-m-cat">
                                    <label className="filter-m-label">{t('history.date')}:</label>
                                    <button className={`btn btn-m-filter date ${this.state.filterShow === 'date' && 'active'}`} type="button" onClick={this.showDateHandler}>
                                        <p>
                                            {this.props.start_date}
                                            <br />
                                            {this.props.end_date}
                                        </p>
                                        <Icon icon="dashicons:arrow-down" />
                                    </button>
                                </div>
                            </div>
                            <div className="filter-m-tp-col">
                                <div className="filter-m-cat d-flex align-items-center">
                                    <label
                                        className="filter-m-label provider-label"
                                    >
                                        {t('profile.game-provider')}:
                                    </label>
                                    <button
                                        className={`btn btn-m-filter ${this.state.filterShow === 'provider' && 'active'} provider`}
                                        type="button"
                                        onClick={this.showProviderHandler}>
                                            {
                                                this.props.gameProviders && this.props.gameProviders.map((item, index)=>(
                                                    this.props.game_provider === item.value && (
                                                        <p>{this.props.game_provider === "" ? t(`global.all`) : item.label}</p>
                                                    )
                                                ))
                                            }
                                        <Icon icon="dashicons:arrow-down" />
                                    </button>
                                </div>
                            </div>
                            <div className="filter-m-btm-col">
                                <div className={`filters-m-group ${this.state.filterShow === 'type' ? 'd-block' : 'd-none'}`}>
                                    {this.props.gameCategories && this.props.gameCategories.map((item, index) => (
                                        <div className="form-check form-check-inline filter" key={index}>
                                            <input
                                                className="btn-check"
                                                type="radio"
                                                name="game_type"
                                                id={item.id}
                                                value={item.value}
                                                defaultChecked={index === 0}
                                                checked={item.value === this.props.selectedFilterType}
                                                onClick={(e) => this.props.onInputChange(e)}
                                            />
                                            <label className="btn form-check-label" htmlFor={item.id}>
                                                {item.value === '' ? t(`global.all`) : t(`game-type.${item.value}`)}
                                            </label>
                                        </div>
                                    ))}
                                </div>
                                <div className={`filters-m-date-group ${this.state.filterShow === 'date' ? 'd-block' : 'd-none'}`}>
                                    <div className="d-flex align-items-center flex-wrap">
                                        {this.props.filterDate && this.props.filterDate.map((item, index) => (
                                            <div className="form-check form-check-inline filter" key={index}>
                                                <input
                                                    className="btn-check"
                                                    type="radio"
                                                    name="filterDate"
                                                    id={item.id}
                                                    value={item.value}
                                                    defaultChecked={index === 0}
                                                    checked={index === this.props.currentFilterDate}
                                                    onClick={() => this.props.onChangeFilterDateHandler(index)}
                                                />
                                                <label className="btn form-check-label" htmlFor={item.id} onClick={
                                                    () => [
                                                        this.props.filterDayRange(item.value)
                                                    ]
                                                }>
                                                    {t(`history.${item.label}`)}
                                                </label>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="filter-m-date">
                                        <label className="filter-m-label">{t('history.start-date')}:</label>
                                        <button className="btn btn-m-filter-date" type="button" onClick={(e) => this.props.toggleShowDateSelect(e, 'startDate')}>
                                            <p>{this.props.start_date}</p>
                                            <Icon icon="uil:angle-right" />
                                        </button>
                                    </div>
                                    <div className="filter-m-date">
                                        <label className="filter-m-label">{t('history.end-date')}:</label>
                                        <button className="btn btn-m-filter-date" type="button" onClick={(e) => this.props.toggleShowDateSelect(e, 'endDate')}>
                                            <p>{this.props.end_date}</p>
                                            <Icon icon="uil:angle-right" />
                                        </button>
                                    </div>
                                </div>
                                <div className={`filters-m-group ${this.state.filterShow === 'provider' ? 'd-flex flex-wrap' : 'd-none'}`}>
                                    {this.props.gameProviders && this.props.gameProviders.map((item, index) => (
                                        <div className="form-check form-check-inline filter" key={index}>
                                            <input
                                                className="btn-check"
                                                type="radio"
                                                name="game_provider"
                                                id={item.id}
                                                value={item.value}
                                                defaultChecked={index === 0}
                                                checked={item.value === this.props.game_provider}
                                                onClick={(e) => this.props.handleGameProviderChange(e)}
                                            />
                                            <div className='btn form-check-label provider-m-check'>
                                                {item.icon && <img className='provider-img' src={providers[item.icon]} alt={item.label} />}
                                                <label className="" htmlFor={item.id}>
                                                    {item.label === '' ? t(`global.all`) : item.label}
                                                </label>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                {this.state.filterShow &&
                                    <>
                                        <Button
                                            typeButton="button"
                                            classValue="btn btn-gradient-blue mt-3"
                                            idValue="search"
                                            buttonName={t('global.check')}
                                            clicked={() => { this.props.searchHandler(this.props.historyTab); this.setState({ filterShow: '' }); }}
                                            disabled={this.props.disableSearchButton}
                                        />
                                        <Button
                                            typeButton='button'
                                            classValue='btn btn-success w-100'
                                            idValue='reset'
                                            buttonName={t('global.reset')}
                                            clicked={()=>{this.props.onResetHandler();this.setState({ filterShow: '' });}}
                                        />
                                    </>

                                }
                            </div>
                        </div>
                    </div>
                    <div className="overlay" onClick={() => this.setState({ filterShow: '' })} />
                    <div className="pd-m-card-col">
                        <Fade duration={1000} triggerOnce>
                            <DataTable
                                keyField="no"
                                data={this.props.rsData}
                                columns={this.props.columns}
                                activePage={this.props.activePage}
                                limit={this.props.limit}
                                totalItems={this.props.totalRecords}
                                paginationHandler={(val) => this.props.datatablesChangeHandler(val, 'betting_summary', "activePage")}
                                // sizePerPageHandler={(val) => this.datatablesChangeHandler(val, 'betting_summary', "limit")}
                                loading={this.props.loading}
                                hideSearchBar={true}
                                hideTotalRecords={true}
                                classValue="pd-table"
                                component="betting-history"
                            />
                        </Fade>
                    </div>
                </section>
            </Auxiliary>
        )
    }
}

export default withNamespaces('translation')(MobileBettingHistory);
