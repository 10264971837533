//Check validity function for checking the input by passing the value, rules(ex:required) , custom validation rules message
// and init set the validation started first or not
export const required = (init = true, value, requiredMessage) => {
    //By default set the isValid to true and message to null
    let message;
    //Check the required message by default is undefined or empty then assign default error message
    if (requiredMessage === undefined || requiredMessage === "") {
        message = "Required field";
    } else {
        message = requiredMessage;
    }

    // When init = false not running the validation first and init = true is started validation.
    if (!init) {
        return {
            validity: '',
            message
        }
    }

    //Get the input type count value
    let stringLength = value.toString().length;

    //Required rules
    if (stringLength === 0) {
        return {
            validity: false,
            message
        };
    }

    //
    // //Max-Length rules
    // if(rules.maxLength){
    //     isValid = value.length <= rules.maxLength && isValid;
    // }
    //
    // //Check email rules
    // if(rules.isEmail){
    //     const pattern = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    //     isValid = pattern.test(value) && isValid;
    // }
    //
    // console.log(isValid);

    //Return validation is pass
    return {
        validity: true,
        message: null
    }
}

export const minLength = (init = true, value, length, minLengthMessage) => {
    let message;
    if (minLengthMessage === undefined || minLengthMessage === "") {
        message = `${length} characters must be a minimum.`;
    } else {
        message = minLengthMessage + ' ' + length;
    }

    if (!init) {
        return {
            validity: '',
            message
        }
    }

    if (value.length < length) {
        return {
            validity: false,
            message
        };
    }

    return {
        validity: true,
        message: null
    }
}

export const minValue = (init = true, value, min, msg) => {
    let message;

    if (!init) {
        return {
            validity: '',
            message
        }
    }

    if (min !== undefined) {
        message = (msg) ? msg : `Min value must be ${min}`;
        if (Number(value) < Number(min)) {
            return {
                validity: false,
                message
            };
        }
    }
    return {
        validity: true,
        message: null
    }
}

export const maxValue = (init = true, value, max, msg) => {
    let message;

    if (!init) {
        return {
            validity: '',
            message
        }
    }
    if (max !== undefined) {
        message = (msg) ? msg : `Max value must be ${max}`;
        if (Number(value) > Number(max)) {
            return {
                validity: false,
                message
            };
        }
    }
    return {
        validity: true,
        message: null
    }
}

export const isEmail = (init = true, value, emailMessage) => {
    let message;

    //Check the required message by default is undefined or empty then assign default error message
    if (emailMessage === undefined || emailMessage === "") {
        message = "Invalid Email Format";
    } else {
        message = emailMessage;
    }

    // When init = false not running the validation first and init = true is started validation.
    if (!init) {
        return {
            validity: '',
            message
        }
    }

    let isValid;
    const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
    // const emailPattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{3,}))$/;
    // const emailPattern = new RegExp('[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+(?:\\.[a-zA-Z0-9!#$%&\'*+/=?^_`{|}~-]+)*@(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?\\.)+[a-zA-Z0-9](?:[a-zA-Z0-9-]*[a-zA-Z0-9])?');
    isValid = emailPattern.test(value);

    //Required rules
    if (isValid === false) {
        return {
            validity: false,
            message
        };
    }

    return {
        validity: true,
        message: null
    };

}

export const checkPasswordMatch = (init = true, old_password, confirm_password, checkPasswordMatchMsg) => {
    let message;

    //Check the required message by default is undefined or empty then assign default error message
    if (checkPasswordMatchMsg === undefined || checkPasswordMatchMsg === "") {
        message = "Your confirm password does not match with the password";
    } else {
        message = checkPasswordMatchMsg;
    }

    if (!init) {
        return {
            validity: '',
            message
        }
    }
    
    if (old_password !== confirm_password) {
        return {
            validity: false,
            message
        }
    }

    return {
        validity: true,
        message: null

    }
}

export const checkPasswordFormat = (init = true, value, checkPasswordFormatMessage) => {
    let message;

    //Check the required message by default is undefined or empty then assign default error message
    if (checkPasswordFormatMessage === undefined || checkPasswordFormatMessage === "") {
        message = <span>Invalid password Format! <br />
        Password must include:
        <ul>
            <li>Minimum 6 characters.</li>
            <li>One Special Characters.(For example: @#$%^&)</li>
            <li>A Number</li>
            <li>A lowercase letter</li>
            <li>One Capital Uppercase letter</li>
        </ul>
        </span>;

            // "Invalid Password Format! Password must minumum 6 characters, one special characters, " +
            // "number, lowercase letter and one capital uppercase letter ";
    } else {
        message = checkPasswordFormatMessage;
    }

    // When init = false not running the validation first and init = true is started validation.
    if (!init) {
        return {
            validity: '',
            message
        }
    }

    let isValid;
    // const emailPattern = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    const passwordFormatPattern = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[@$!%*?&])[A-Za-z\\d@$!%*?&]{6,}');
    isValid = passwordFormatPattern.test(value);

    //Required rules
    if (isValid === false) {
        return {
            validity: false,
            message
        };
    }

    return {
        validity: true,
        message: null
    };
}


export const checkUsername = (init = true, checkUsername, checkUsernameMessage) => {
    let message;

    //Check the required message by default is undefined or empty then assign default error message
    if (checkUsernameMessage === undefined || checkUsernameMessage === "") {
        message = "Username is not available to register";
    } else {
        message = checkUsernameMessage;
    }

    if (!init) {
        return {
            validity: '',
            message
        }
    }

     //Required rules
     if (checkUsername === false) {
        return {
            validity: false,
            message
        };
    }else {
        return {
            validity: true,
            message: null
        };
    }

}



//Validate display checking the rules validity is true append the string input with class is-valid or is-invalid and return the message on it.
export const validateDisplay = rules => {
    let validClass = rules.validity === '' ? '' : rules.validity ? 'is-valid' : 'is-invalid';

    return [validClass, rules.message];
};


//Validate getting the form name when rules not meet when click submit button cannot pass
export const formValidation = (form_name, remove_class) => {
    let form = document.getElementById(form_name);

    if (form === null) {
        return;
    }
    
    if(remove_class){
        form.classList.remove('was-validated');
    }

    form.addEventListener('submit', (e) => {
        if (form.checkValidity() === false) {
            e.preventDefault();
            e.stopPropagation();
            form.classList.add('was-validated');
        }
    });
};

//clear validate css after submit
export const clearFormValidation = (form_name) => {
    document.getElementById(form_name).classList.remove('was-validated');
};

export const clearInputField = (form_name) => {
    var inputs = document.getElementById(form_name).elements;
    var i = 0;

    for (i; i < inputs.length; i++) {
        inputs[i].classList.remove('is-valid');
        inputs[i].classList.remove('is-invalid');
    }
};


