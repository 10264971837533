import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import { LazyLoadImage } from "react-lazy-load-image-component";
import domain from "../../../common/data/domain.json";

let dynamicCountry = "Malaysia";
if(domain.id.key.includes(window.location.host)){
  dynamicCountry = "Indonesia";
}

export class MainSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };
  render() {
    // let imageLanguage = "";
    // switch (this.props.currentUser.language_code) {
    //   case "zh-CN":
    //     imageLanguage = "-cn";
    //     break;
    //   // case "ms":
    //   //   imageLanguage = "-ms"
    //   //   break;
    //   default:
    //     imageLanguage = "";
    //     break;
    // }

    const { t } = this.props;
    const homeImg = this.imageImport(
      require.context(
        "../../../assets/images/home/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    // const videos = this.imageImport(
    //   require.context(
    //     "../../../assets/images/videos/",
    //     false,
    //     /\.(png|jpe?g|svg|mp4)$/
    //   )
    // );
    const christmas = this.imageImport(
      require.context(
        "../../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const chinese = this.imageImport(
      require.context(
        "../../../assets/images/home/chinese",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const raya = this.imageImport(
      require.context(
        "../../../assets/images/home/raya",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const merdeka = this.imageImport(
      require.context(
        "../../../assets/images/home/merdeka",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const mooncake = this.imageImport(
      require.context(
        "../../../assets/images/home/mooncake",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const halloween = this.imageImport(
      require.context(
        "../../../assets/images/home/halloween",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );


    return (
      <section
        className={`global-section ${this.props.currentEvent === "cny" ? "cny event" : this.props.currentEvent === "mooncake" && this.props.currentUser.country_id !== 4 ? "mooncake event" :  ""}`}
      >

      </section>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

export default connect(mapStateToProps)(
  withNamespaces("translation")(MainSection)
);
