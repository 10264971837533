import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from 'react-device-detect';
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";
import moment from "moment";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import MobileVipExclusive from "./MobileVipExclusive"
import { LazyLoadImage } from "react-lazy-load-image-component";
import Swal from "sweetalert2";
import LoadingPage from "../../../components/LoadingPage/LoadingPage";

import { validateVIPWeeklyBonus, claimVIPWeeklyBonus, validateVIPBirthdayBonus, claimVIPBirthdayBonus, getVIP, getVIPDepositTurnover } from "../../../store/ducks/vip/actions";
//Icons
// import { Icon } from "@iconify/react";
// import { bottom } from "@popperjs/core";

class VipExclusive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeVip: 1,
      vipArry: [],
      collectVIPWeeklyBonus: false,
      vipWeeklyBonusMSg: "",
      collectVIPBirthdayBonus: false,
      vipBirthdayBonusMsg: "",
      birthdayBonusAmt: 0.00,
      vipBonusAmt: 0.00,
      loading: true,
      curDepositTotal: 0.00,
      curTurnoverTotal: 0.00,
      set_birthday_date: true,
    };
  }

  componentDidMount() {
    if (localStorage.getItem("accessToken")) {
      this.props.validateVIPWeeklyBonus();

      this.props.validateVIPBirthdayBonus();

      this.props.getVIPDepositTurnover();

      this.setState({ activeVip: this.props.currentUser.user_group_level });
    }

    let showVIPData = {};
    // showVIPData.country_id = this.props.currentUser.country_id;
    this.props.getVIP(showVIPData);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.validate_vip_weekly_bonus !== undefined && nextProps.validate_vip_weekly_bonus !== this.props.validate_vip_weekly_bonus) {
      if (nextProps.validate_vip_weekly_bonus.responseCode === 200 && nextProps.validate_vip_weekly_bonus.msgType === "success") {
        this.setState({
          collectVIPWeeklyBonus: nextProps.validate_vip_weekly_bonus.data.allow_to_claim,
          vipWeeklyBonusMSg: nextProps.validate_vip_weekly_bonus.data.allowClaimErrMsg,
          vipBonusAmt: nextProps.validate_vip_weekly_bonus.data.vip_weekly_bonus_amount
        });
      }
    }

    if (nextProps.claim_vip_weekly_bonus !== undefined && nextProps.claim_vip_weekly_bonus !== this.props.claim_vip_weekly_bonus) {
      if (nextProps.claim_vip_weekly_bonus.responseCode === 200 && nextProps.claim_vip_weekly_bonus.msgType === "success") {
        const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
          icon: "info",
          title: t("global.success"),
          text: t("vip.success-claim"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sucess',
            icon: 'swal-success-icon'
          } : []
        });
      }
    }

    if (nextProps.validate_vip_birthday_bonus !== undefined && nextProps.validate_vip_birthday_bonus !== this.props.validate_vip_birthday_bonus) {
      if (nextProps.validate_vip_birthday_bonus.responseCode === 200 && nextProps.validate_vip_birthday_bonus.msgType === "success") {
        this.setState({
          collectVIPBirthdayBonus: nextProps.validate_vip_birthday_bonus.data.birthday_bonus_status,
          birthdayBonusAmt: nextProps.validate_vip_birthday_bonus.data.birthday_bonus_amount,
          dob: nextProps.validate_vip_birthday_bonus.data.dob
        });
      }
    }

    if (nextProps.claim_vip_birthday_bonus !== undefined && nextProps.claim_vip_birthday_bonus !== this.props.claim_vip_birthday_bonus) {
      if (nextProps.claim_vip_birthday_bonus.responseCode === 200 && nextProps.claim_vip_birthday_bonus.msgType === "success") {
        const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
          icon: "info",
          title: t("global.success"),
          text: t("vip.success-claim"),
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sucess',
            icon: 'swal-success-icon'
          } : []
        }).then((result) => {
          if (result.isConfirmed) {
            this.props.validateVIPBirthdayBonus();
          }
        });
      }
    }

    if (nextProps.vip_data !== undefined && nextProps.vip_data !== this.props.vip_data) {
      if (nextProps.vip_data.responseCode === 200 && nextProps.vip_data.msgType === "success") {
        this.setState({
          vipArry: nextProps.vip_data.data,
          loading: false
        });
      }
    }

    if (nextProps.vip_deposit_turnover !== undefined && nextProps.vip_deposit_turnover !== this.props.vip_deposit_turnover) {
      if (nextProps.vip_deposit_turnover.responseCode === 200 && nextProps.vip_deposit_turnover.msgType === "success") {
        this.setState({
          curDepositTotal: nextProps.vip_deposit_turnover.data.total_deposit,
          curTurnoverTotal: nextProps.vip_deposit_turnover.data.total_turnonver
        });
      }
    }

    return true;
  }

  handleClick = (index) => {
    this.setState({
      activeVip: index,
    });
  };


  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  vipWeeklyBonusClaimHandler = () => {
    const { t } = this.props;
    if (!localStorage.getItem("accessToken")) {
      Swal.fire({
        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
        icon: "info",
        title: t("global.sorry"),
        text: t("global.login-proceed"),
        confirmButtonText: t("global.ok"),
        customClass: (!isDesktop) ? {
          container: "swal-mobile-container sorry",
          icon: "swal-sorry-icon",
        } : []
      });
    }
    else {
      let claimable = this.state.collectVIPWeeklyBonus;
      if (claimable) {
        this.props.claimVIPWeeklyBonus();
      }
      else {
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
          icon: "info",
          title: t("global.sorry"),
          text: this.state.vipWeeklyBonusMSg,
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sorry',
            icon: 'swal-sorry-icon'
          } : []
        });
      }
    }
  }

  vipBirthdayBonusClaimHandler = () => {
    const { t } = this.props;
    if (!localStorage.getItem("accessToken")) {
      Swal.fire({
        iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
        icon: "info",
        title: t("global.sorry"),
        text: t("global.login-proceed"),
        confirmButtonText: t("global.ok"),
        showCancelButton: (!isDesktop) ? false : '',
        customClass: (!isDesktop) ? {
          container: "swal-mobile-container sorry",
          icon: "swal-sorry-icon",
        } : []
      });
    }
    else {
      let claimable = this.state.collectVIPBirthdayBonus;
      if (claimable) {

        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
          title: t("global.confirmation"),
          text: t("global.would-you-like-to-proceed") + "?",
          icon: "warning",
          confirmButtonText: t("global.confirm"),
          showDenyButton: true,
          denyButtonText: t("global.cancel"),
          customClass: (!isDesktop) ? {
            container: "swal-mobile-container confirmation",
            icon: "swal-confirmation-icon",
          } : []
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: "",
              allowOutsideClick: false,
              allowEscapeKey: false,
              allowEnterKey: false,
              onOpen: () => {
                Swal.showLoading();
              },
            });

            this.props.claimVIPBirthdayBonus();
          }
        });

      } else {
        Swal.fire({
          iconHtml: !isDesktop && '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
          icon: "info",
          title: t("global.sorry"),
          text: t("vip.not-allowed-to-claim-birthday-bonus"),
          confirmButtonText: t("global.ok"),
          customClass: !isDesktop && {
            container: 'swal-mobile-container sorry',
            icon: 'swal-sorry-icon'
          },
        });
      }
    }
  }

  render() {
    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const vip = this.imageImport(
      require.context(
        "../../../assets/images/profile/vip",
        false,
        /\.(png|jpe?g|svg|gif|webp)$/
      )
    );

    const ctgIcon = this.imageImport(
      require.context(
        "../../../assets/images/icons/mobile",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const fadeUp = keyframes`
      from {
        opacity: 0;
        transform: translate3d(0, 50px ,0);
      }

      to {
        opacity: 1;
        transform: translate3d(0, 0, 0);
      }
    `;
    const { t } = this.props;
    const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    let tempCurInfo = [];
    let requireDeposit = 0.00;
    let requireTurnover = 0.00;
    let lastVip = this.state.vipArry.length;
    let vipInfo = this.state.vipArry ? this.state.vipArry.map((vipval, idx) => {
      if (vipval.level === this.state.activeVip) {
        tempCurInfo.push(vipval);
      }
      if (vipval.level === this.state.activeVip + 1) {
        requireDeposit = vipval.minDepositTierUpgrade;
        requireTurnover = vipval.minTurnoverTierUpgrade;
      }
      else {
        if (this.state.activeVip === lastVip && idx === lastVip - 1) {
          requireDeposit = vipval.minDepositTierUpgrade;
          requireTurnover = vipval.minTurnoverTierUpgrade;
        }
      }
      return {
        vip: vipval.level,
        deposit: new Intl.NumberFormat("en-US", num_format).format(vipval.minDepositTierUpgrade),
        turnover: new Intl.NumberFormat("en-US", num_format).format(vipval.minTurnoverTierUpgrade)
      };
    }) : [];
    let vipInfoLength = vipInfo.length > 0 ? (100 / vipInfo.length) : 8.34;
    let curVipInfo = tempCurInfo.length > 0 ? tempCurInfo[0] : null;
    let requireDepositRate = this.state.curDepositTotal >= parseFloat(requireDeposit) ? 100 : (this.state.curDepositTotal / parseFloat(requireDeposit)) * 100;
    let requireTurnoverRate = this.state.curTurnoverTotal >= parseFloat(requireTurnover) ? 100 : (this.state.curTurnoverTotal / parseFloat(requireTurnover)) * 100;

    // Get birthday countdown
    let birthdayCountdown = null;
    if (this.state.dob) {
      let nextBirthday = moment().format("yyyy-") + moment(this.state.dob).format("MM-DD");
      if (nextBirthday === moment().format("yyyy-MM-DD")) {
        birthdayCountdown = "(" + t("vip.happy-birthday") + ")";
      } else {
        if (nextBirthday < moment().format("yyyy-MM-DD")) {
          nextBirthday = moment().add(1, "years").format("yyyy-") + moment(this.state.dob).format("MM-DD");
        }

        const given = moment(nextBirthday, "YYYY-MM-DD");
        const current = moment().startOf('day');
        const dayLeft = moment.duration(given.diff(current)).asDays();
        birthdayCountdown = "(" + t("global.count-down") + " " + dayLeft + " " + t("datepicker.day") + ")";
      }
    }

    return (
      <Auxiliary>
        {
          this.state.loading && <LoadingPage />
        }
        {
          isDesktop ? (
            <section className="pf-dashboard">
              <div className="x-container">
                <ProfileSideBar />
                <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                  <div className="row g-3">
                    <div className="col-12">
                      <div className="pd-card-col">
                        <div className="pd-content">
                          <div className="vip-lvl-col">
                            <div className="vip-lvl-tp">
                              <LazyLoadImage src={vip[`vip${(this.props.currentUser.user_group_level-1)}.webp`]} alt={`vip${this.props.currentUser.user_group_level}`} effect="blur" />
                              <div className="vip-lvl-details-col">
                                <h5>{t("vip.current-lvl")}: {t(`vip.vip-${this.props.currentUser.user_group_level}`)}</h5>
                                <div className="vip-lvl-details">
                                  <span>
                                    <p>{t("vip.deposit")}:  {this.props.currentUser.currency}{new Intl.NumberFormat("en-US", num_format).format(this.state.curDepositTotal)}</p>
                                    <p>{t("vip.turnover")}:  {this.props.currentUser.currency}{new Intl.NumberFormat("en-US", num_format).format(this.state.curTurnoverTotal)}</p>
                                  </span>
                                  <Link className="btn btn-gradient-blue" to={{ pathname: "/vip" }}>
                                    {t("vip.vip-details")}
                                  </Link>
                                </div>
                              </div>
                            </div>
                            <div className="vip-lvl-md">
                              <ul>
                                {vipInfo &&
                                  vipInfo.map((item, index) => (
                                    <li
                                      key={item.vip}
                                      className={this.state.activeVip === item.vip ? 'active' : ''}
                                      onClick={() => this.handleClick(item.vip)}
                                    >
                                      <span>{t(`vip.vip-${item.vip}`)}</span>
                                    </li>
                                  ))}
                              </ul>
                              <div className="vip-lvl-progress-bar" style={{ width: `calc(${vipInfoLength}% * ${this.state.activeVip})` }} />
                            </div>
                            <div className="vip-lvl-bt">
                              <h6>{t("vip.next-lvl-vip")}</h6>
                              <div className="vip-lvl-deposit">
                                <p>{t("vip.deposit")}</p>
                                <div className="progress-bar">
                                  <span style={{ width: `${requireDepositRate}%` }} />
                                </div>
                                <p>{t("vip.next-lvl-deposit")} {this.props.currentUser.currency}{(() => {
                                  let temp = parseFloat(requireDeposit) - this.state.curDepositTotal;
                                  if (temp > 0) {
                                    return new Intl.NumberFormat("en-US", num_format).format(temp);
                                  }
                                  else {
                                    return 0.00;
                                  }
                                })()}</p>
                              </div>
                              <div className="vip-lvl-deposit">
                                <p>{t("vip.turnover")}</p>
                                <div className="progress-bar">
                                  <span style={{ width: `${requireTurnoverRate}%` }} />
                                </div>
                                <p>{t("vip.next-lvl-turnover")} {this.props.currentUser.currency}{(() => {
                                  let temp = parseFloat(requireTurnover) - this.state.curTurnoverTotal;
                                  if (temp > 0) {
                                    return new Intl.NumberFormat("en-US", num_format).format(temp);
                                  }
                                  else {
                                    return 0.00;
                                  }
                                })()}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="pd-card-col">
                        {/* {this.state.activeVip === 1 ?
                          <>
                            <h6>
                              {t(`vip.vip-${this.state.activeVip}`)} <span className="fw-normal">{t("vip.exclusive")}</span>
                            </h6>
                            <div className="pd-content m-auto">
                                <div className="row text-center ">
                                    <p>{t('vip.not-exclusive')}</p>
                                    <p>{t('vip.deposit-more-exclusive')}</p>
                                    <Link style={{ alignContent: 'center' }} className="btn btn-gradient-blue w-50 m-auto mt-3" to={{ pathname: "/deposit" }}>
                                      {t("norecord.deposit-now")}
                                    </Link>
                                </div>
                            </div>
                          </>
                          :
                          <> */}
                            <h6>
                              {t(`vip.vip-${this.state.activeVip}`)} <span className="fw-normal">{t("vip.exclusive")}</span>
                            </h6>
                            <div className="pd-content my-auto">
                              <div className="vip-exclusive-col">
                                <div className="row">
                                  <div className="col-5ths">
                                    <img src={icons["withdrawal.webp"]} alt="withdrawal" />
                                    <h5>{curVipInfo ? curVipInfo.dailyWithdrawalCounts : 0.00}</h5>
                                    <p>{t("vip.no-withdrawals-per-day")}</p>
                                  </div>
                                  <div className="col-5ths">
                                    <img src={icons["daily-limit.webp"]} alt="daily-limit" />
                                    <h5>{curVipInfo ? new Intl.NumberFormat("en-US", num_format).format(curVipInfo.maxDailyWithdrawal) : 0.00}</h5>
                                    <p>{t("vip.daily-withdrawal-limit-sm")}</p>
                                  </div>
                                  <div className="col-5ths">
                                    <img src={icons["bonus.webp"]} alt="bonus" />
                                    <h5>{curVipInfo ? new Intl.NumberFormat("en-US", num_format).format(curVipInfo.upLevelBonus) : 0.00}</h5>
                                    <p>{t("vip.upgrade-bonus-sm")} <small>{t("vip.pay-auto")}</small></p>
                                  </div>
                                  <div className="col-5ths" role="button" onClick={this.vipWeeklyBonusClaimHandler}>
                                    <img src={icons["monthly-cash.webp"]} alt="monthly-cash" />
                                    {/* <h5>{this.state.vipBonusAmt}</h5> */}
                                    <h5>{curVipInfo ? new Intl.NumberFormat("en-US", num_format).format(curVipInfo.vipWeeklybonus) : 0.00}</h5>
                                    <p>{t("vip.vip-weekly-bonus")} <small>{t("vip.collect-manually")}</small></p>
                                    <button className="btn btn-outline-blue">{t("vip.redeem")}</button>
                                  </div>
                                  <div className="col-5ths" role="button" onClick={this.vipBirthdayBonusClaimHandler}>
                                    <img src={icons["birthday.webp"]} alt="birthday" />
                                    {/* <h5>{this.state.birthdayBonusAmt}</h5> */}
                                    <h5>{curVipInfo ? new Intl.NumberFormat("en-US", num_format).format(curVipInfo.birthdayBonus) : 0.00}</h5>
                                    <p>{t("vip.vip-birthday-bonus")}
                                      {
                                        this.state.set_birthday_date && <small>
                                          {birthdayCountdown}
                                        </small>
                                      }
                                    </p>
                                    <button className="btn btn-outline-blue">{t("vip.redeem")}</button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          {/* </>
                        } */}
                      </div>
                      <div className="pd-card-col">
                      {/* {this.state.activeVip === 1 ?
                          <>
                            <h6>
                              {t(`vip.vip-${this.state.activeVip}`)} <span className="fw-normal">{t("vip.exclusive")}</span>
                            </h6>
                            <div className="pd-content m-auto">
                                <div className="row text-center ">
                                    <p>{t('vip.not-exclusive')}</p>
                                    <p>{t('vip.deposit-more-exclusive')}</p>
                                    <Link style={{ alignContent: 'center' }} className="btn btn-gradient-blue w-50 m-auto mt-3" to={{ pathname: "/deposit" }}>
                                      {t("norecord.deposit-now")}
                                    </Link>
                                </div>
                            </div>
                          </>
                          :
                          <> */}
                        <h6>
                          {t(`vip.vip-${this.state.activeVip}`)} <span className="fw-normal">{t("vip.category-rebate")}</span>
                        </h6>
                        <div className="pd-content my-auto">
                          <div className="vip-offer-col">
                            <div className="row justify-content-center">
                              <div className="col-5ths">
                                <span className="vip-offer-img">
                                  <img src={ctgIcon["sport-m.webp"]} alt="" />
                                </span>
                                <p>{curVipInfo ? (curVipInfo.sportRebate !== null ? curVipInfo.sportRebate : '0.00') : '0.00'}%</p>
                                <h5>{t("page-header.sports")}</h5>
                              </div>
                              <div className="col-5ths">
                                <span className="vip-offer-img">
                                  <img src={ctgIcon["lc-m.webp"]} alt="" />
                                </span>
                                <p>{curVipInfo ? (curVipInfo.live_casino_rebate !== null ? curVipInfo.live_casino_rebate : '0.00') : '0.00'}%</p>
                                <h5>{t("page-header.live-casino")}</h5>
                              </div>
                              <div className="col-5ths">
                                <span className="vip-offer-img">
                                  <img src={ctgIcon["slot-m.webp"]} alt="" />
                                </span>
                                <p>{curVipInfo ? (curVipInfo.slotRebate !== null ? curVipInfo.slotRebate : '0.00') : '0.00'}%</p>
                                <h5>{t("page-header.slots")}</h5>
                              </div>
                              <div className="col-5ths">
                                <span className="vip-offer-img">
                                  <img src={ctgIcon["games-m.webp"]} alt="" />
                                </span>
                                <p>{curVipInfo ? (curVipInfo.virtualRebate !== null ? curVipInfo.virtualRebate : '0.00') : '0.00'}%</p>
                                <h5>{t("page-header.games")}</h5>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* </>} */}
                      </div>
                    </div>
                  </div>
                </Reveal>
              </div>
            </section>
          ) : (
            <MobileVipExclusive
              handleClick={this.handleClick}
              vipWeeklyBonusClaimHandler={this.vipWeeklyBonusClaimHandler}
              vipBirthdayBonusClaimHandler={this.vipBirthdayBonusClaimHandler}
              vipInfo={curVipInfo}
              requireDepositRate={requireDepositRate}
              requireTurnoverRate={requireTurnoverRate}
              requireDeposit={requireDeposit}
              requireTurnover={requireTurnover}
              vipData={this.state.vipArry}
              {...this.state}
              {...this.props}
            />
          )
        }

      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser.data,
    validate_vip_weekly_bonus: state.vip.validate_vip_weekly_bonus,
    claim_vip_weekly_bonus: state.vip.claim_vip_weekly_bonus,
    validate_vip_birthday_bonus: state.vip.validate_vip_birthday_bonus,
    claim_vip_birthday_bonus: state.vip.claim_vip_birthday_bonus,
    vip_data: state.vip.vip_data,
    vip_deposit_turnover: state.vip.vip_deposit_turnover
  };
}

const mapDispatchToProps = {
  validateVIPWeeklyBonus,
  claimVIPWeeklyBonus,
  validateVIPBirthdayBonus,
  claimVIPBirthdayBonus,
  getVIP,
  getVIPDepositTurnover
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(VipExclusive));
