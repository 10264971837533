import React, { Component } from "react";
import { withNamespaces } from 'react-i18next';
import moment from "moment";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Fade } from "react-awesome-reveal";

//Components
import DataTable from "../../../components/Datatable/DataTable";

//Icons
// import { Icon } from "@iconify/react";
class Promotion extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = {};
    }

    componentDidMount() 
    {
        if(!this.props.disableSearchButton)
        {
            const listingArr = this.props.getFilterArray("promotion");
            this.props.funcGetList(listingArr);
        }
    }

    render() 
    {
        const { t } = this.props;
        let rsData = [];
        let stCls = {0:'pending', 1:'success', 2:'failed', 4:'failed'}; 
        let stVal = {0: t('history.processing'), 1: t('history.completed'), 2: t('history.failed'), 4: t('history.cancelled')};
        const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

        const columns =
        [
            {text: t('history.start-date'), dataField: 'start_date'},
            {text: t('history.end-date'), dataField: 'end_date'},
            {
                text: t('history.promotion'),
                dataField: 'method',
                formatter: (cell, row, rowIndex) => 
                {
                    return <span>{`${row.title}`}</span>;
                },
            },
            {text: t('history.deposit'), dataField: 'deposit_amount'},
            {text: t('history.bonus'), dataField: 'bonus_amount'},
            {text: t('history.target'), dataField: 'target_amount'},
            {text: t('history.achieve'), dataField: 'turnover'},
            {text: t("history.status"), dataField: 'status'},
            // {
            //     text: '',
            //     dataField: 'status',
            //     formatter: (cell, row, rowIndex) => 
            //     {
            //         return <span className={`${stCls[row.status]} form-text`}>{row.status} | {stVal[row.status]}</span>;
            //     },
            // },
        ];

        if(this.props.data.data)
        {
            rsData = this.props.data.data.map((val, idx) =>
            {
                let st = <span className={`status ${stCls[val.status]}`}>{stVal[val.status]}</span>;
                return {
                    no: idx,
                    start_date: moment(val.start_date).format('YYYY-MM-DD HH:MM'),
                    end_date: moment(val.end_date).format('YYYY-MM-DD HH:MM'),
                    title: val.title,
                    deposit_amount: new Intl.NumberFormat("en-US", num_format).format(val.deposit_amount),
                    bonus_amount: new Intl.NumberFormat("en-US", num_format).format(val.bonus_amount),
                    target_amount: new Intl.NumberFormat("en-US", num_format).format(val.target_amount),
                    turnover: new Intl.NumberFormat("en-US", num_format).format(val.turnover),
                    status: st,
                };
            });
        }

        return (
            <Auxiliary>
                <Fade duration={1000} triggerOnce>
                    <DataTable
                        keyField="no"
                        data={rsData}
                        columns={columns}
                        activePage={this.props.data.activePage}
                        limit={this.props.data.limit}
                        totalItems={this.props.data.totalRecords}
                        paginationHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'activePage')}
                        sizePerPageHandler={(val) => this.props.datatablesChangeHandler(val, this.props.tabName, 'limit')}
                        loading={this.props.data.loading}
                        hideSearchBar={true}
                        hideTotalRecords={true}
                        classValue="pd-table"
                        component="transaction-history"
                    />
                </Fade>
            </Auxiliary>
        );
    }
}

export default withNamespaces("translation")(Promotion);
