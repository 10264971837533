import React, { Component } from 'react'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SideBar from '../../components/SideBar/SideBar';
import { withNamespaces } from "react-i18next";
// import Slider from "react-slick";
// import Banner from '../../components/Banner/PrizesPool';


import BannerImage from '../../assets/images/banners/about-us-banner.webp'
import { Link } from 'react-router-dom';


export class MobileAboutUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCtg: 1,
      activePage: 1,
      activeAnimation: true,
    };
  }
  componentDidMount() {
  }

  toggleActiveCtg = (id) => {
    this.removeAnimation(id);
    setTimeout(() => {
      this.setState({ activePage: id, activeAnimation: true });
    }, 1000);
  };

  removeAnimation = (id) => {
    this.setState({
      activeAnimation: false,
      activeCtg: id,
    });
  };

  ProductContent = (props) => {
    // const settings = {
    //   arrows: false,
    //   infinite: true,
    //   slidesToShow: 5,
    //   slidesToScroll: 1,
    //   autoplay: true,
    //   speed: 1000,
    //   cssEase: "linear",
    // };
    const { t } = this.props;
    return (
      <>
        <div className='product-m-image'>
          <LazyLoadImage
            className={`${props.activeAnimation === true ? "fade-in-left" : "fade-out-left"
              }`}
            key={props.img[props.bigImg]}
            src={props.img[props.bigImg]}
            alt="sport-img"
            effect="blur"
          />
        </div>
        <div className="section-m-container">
          <div
            className={`${props.activeAnimation === true
              ? "fade-in-right"
              : "fade-out-right"
              }`}
          >
            <h1 className='gray'>{props.title}</h1>
            <p>{props.content}</p>
            <p className="my-4">{t("global.providers")}:</p>
            {props.provider.length >= 6 ? (
              // <Slider {...settings}>
              //   {props.provider &&
              //     props.provider.map((item, index) => (
              //       <span className="card-shadow" key={index}>
              //         <img src={props.icon[item]} alt={item} />
              //       </span>
              //     ))}
              // </Slider>
              <ul className="row g-2">
                {props.provider &&
                  props.provider.map((item, index) => (
                    <li key={index} className="col-5ths">
                      <span className="card-shadow">
                        <img src={props.icon[item]} alt={item} />
                      </span>
                    </li>
                  ))}
              </ul>
            ) : (
              <ul className="row g-2 justify-content-center">
                {props.provider &&
                  props.provider.map((item, index) => (
                    <li key={index} className="col-5ths">
                      <span className="card-shadow">
                        <img src={props.icon[item]} alt={item} />
                      </span>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        </div>
      </>
    );
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/about-us",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const icon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg|webp)$/)
    );

    const productCtg = [
      { id: 1, icon: "lc-icon", activeIcon: "lc-icon-active", lable: "live-casino" },
      { id: 2, icon: "sports-icon", activeIcon: "sports-icon-active", lable: "sports" },
      { id: 3, icon: "slot-icon", activeIcon: "slot-icon-active", lable: "slots" },
      { id: 4, icon: "game-icon", activeIcon: "game-icon-active", lable: "games" },
      { id: 5, icon: "p2p-icon", activeIcon: "p2p-icon-active", lable: "p2p" },
      { id: 6, icon: "lottery-icon", activeIcon: "lottery-icon-active", lable: "lottery" },
    ];

    return (
      <Auxiliary>
        <SideBar />
        <img className='w-100 h-auto' src={BannerImage} alt="" />

        <section className='global-m-section px-3 mb-3'>
          <div className='section-m-header text-center'>
            <h3>{t("about-us.about-us")}</h3>
            <p className='p-0'>{t("about-us.about-us-details")}</p>
          </div>

          <div className='w-100 d-flex justify-content-center'>
            <LazyLoadImage
              className="section-m-img"
              src={img["mission-img-2.webp"]}
              alt="mission-img"
              effect="blur"
            />
          </div>

          <div className='section-m-container'>
            <h1>{t("about-us.our-mission")}</h1>
            <p>
              {t("about-us.our-mission-details-1")}
              <br />
              <br />
              {t("about-us.our-mission-details-2")}
            </p>
          </div>
        </section>

        <section className='global-m-section px-3 mb-3'>
          <div className='section-m-header text-center'>
            <h3>{t("about-us.product-overview")}</h3>
            <p className='p-0'>{t("about-us.product-overview-details")}</p>
          </div>

          <div className='product-m-section'>
            <div className='game-m-nav my-2'>
              <div className='row g-3'>
                {
                  productCtg && productCtg.map((item, index) => (
                    <div className='col-4'>
                      <div className={`game-nav-btn ${item.id === this.state.activeCtg ? "active" : ""}`} onClick={() => this.toggleActiveCtg(item.id)} style={{
                        pointerEvents: ` ${this.state.activeAnimation === false ? "none" : ""}`
                      }} key={index}>
                        <img className='nav-img' src={item.id === this.state.activeCtg ? icon[`${item.activeIcon}.webp`] : icon[`${item.icon}.webp`]} alt=""/>
                        <p>{t(`about-us.${item.lable}`)}</p>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

            <div>
              {this.state.activePage === 1 ? (
                <this.ProductContent
                  img={img}
                  icon={icon}
                  bigImg="lc-img.webp"
                  title={t("about-us.live-casino-title")}
                  content={t("about-us.live-casino-content")}
                  provider={[
                    "evo-icon.svg",
                    "pp-icon.svg",
                    "ag-icon.svg",
                    "pt-icon.svg",
                    "gp-icon.svg",
                    "dg-icon.svg",
                    "ab-icon.svg",
                    "mg-icon.svg",
                    "sa-icon.svg",
                    "sbo-icon.svg",
                    // "ea-icon.svg",
                  ]}
                  activeAnimation={this.state.activeAnimation}
                />
              ) : this.state.activePage === 2 ? (
                <this.ProductContent
                  img={img}
                  icon={icon}
                  bigImg="sport-img.webp"
                  title={t("about-us.sports-title")}
                  content={t("about-us.sports-content")}
                  provider={["sb-icon.svg", "sbo-icon.svg"]}
                  activeAnimation={this.state.activeAnimation}
                />
              ) : this.state.activePage === 3 ? (
                <this.ProductContent
                  img={img}
                  icon={icon}
                  bigImg="slot-img.webp"
                  title={t("about-us.slots-title")}
                  content={t("about-us.slots-content")}
                  provider={[
                    // "ib-icon.svg",
                    "pp-icon.svg",  
                    "pt-icon.svg",
                    "mg-icon.svg",
                    "sg-icon.svg",
                    "ag-icon.svg",
                    "gp-icon.svg",
                    "qs-icon.svg",
                    "net-icon.svg",
                    "png-icon.svg",
                    "qt-icon.svg",
                    "evo-icon.svg",
                    "sp-icon.svg",
                    "ttg-icon.svg",
                    "sbo-icon.svg",
                  ]}
                  activeAnimation={this.state.activeAnimation}
                />
              ) : this.state.activePage === 4 ? (
                <this.ProductContent
                  img={img}
                  icon={icon}
                  bigImg="games-img.webp"
                  title={t("about-us.games-title")}
                  content={t("about-us.games-content")}
                  provider={[
                    // "ib-icon.svg",
                    "pt-icon.svg",
                    "pp-icon.svg",  
                    "mg-icon.svg",
                    "evo-icon.svg",
                    "net-icon.svg",
                    "png-icon.svg",
                    "qt-icon.svg",
                    "sb-icon.svg",
                    "sbo-icon.svg",
                    "sg-icon.svg",
                    "sp-icon.svg",
                  ]}
                  activeAnimation={this.state.activeAnimation}
                />
              ) : this.state.activePage === 5 ? (
                <this.ProductContent
                  img={img}
                  icon={icon}
                  bigImg="p2p-img.webp"
                  title={t("about-us.p2p-title")}
                  content={t("about-us.p2p-content")}
                  provider={["gp-icon.svg", 
                    "sg-icon.svg"
                  ]}
                  activeAnimation={this.state.activeAnimation}
                />
              ) : this.state.activePage === 6 ? (
                <this.ProductContent
                  img={img}
                  icon={icon}
                  bigImg="lottery-img.webp"
                  title={t("about-us.lottery-title")}
                  content={t("about-us.lottery-content")}
                  provider={[
                    "gp-icon.svg",                            
                    "sb-icon.svg", 
                    "qt-icon.svg"
                  ]}
                  activeAnimation={this.state.activeAnimation}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </section>

        <section className='global-m-section px-3 mb-5'>
          <div className='w-100 d-flex justify-content-center'>
            <LazyLoadImage
              className="section-m-img"
              src={img["customer-img.webp"]}
              alt="customer-img"
              effect="blur"
            />
          </div>
          <div className='section-m-container'>
            <h1 className='mt-3'>{t("about-us.customer-care-1")}</h1>
            <h2>{t("about-us.customer-care-2")}</h2>
            <p>
              {t("about-us.customer-care-details")} {""}
              <Link to={{ pathname: "/contact-us"}}>                 
                {t("about-us.live-chat")}                  
              </Link> {""} 
              {t("about-us.and")} {""}
              <Link to={{ pathname: "/contact-us"}}>
                {t("about-us.email")}
              </Link>.
            </p>
          </div>
        </section>
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileAboutUs));