import React, { Component } from "react";
import { connect } from "react-redux";
import { isDesktop } from "react-device-detect";

//Miscellaneous
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import Swal from "sweetalert2";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Components
// import NoRecords from "../../../components/NoRecords/NoRecords";
import { Fade } from "react-awesome-reveal";
import DataTable from "../../../components/Datatable/DataTable";

//Icons
import { Icon } from "@iconify/react";

// import MessagesFile from "./Messages";

import { getInbox, deleteInbox, readInbox, getCountUnreadInboxAlert } from "../../../store/ducks/inbox/actions";
class Notice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      msgListings: [],
      page: this.props.activePage ? this.props.activePage : Number(process.env.REACT_APP_DATATABLE_PAGE),
      limit: Number(process.env.REACT_APP_DATATABLE_LIMIT),
      totalPages: 0,
      totalRecords: 0,
      sorted: null,
      loading: true,
      isInterval: false,
      unreadNotice: 0,
      unreadAnnouncement: 0
    };
  }

  componentDidMount() {
    this.fetchData(false);

    let params = {};
    params.lang_id = this.props.currentUser.data.language_id;
    
    this.intervalId = setInterval(() => {
      this.setState({
        isInterval: true
      });
      this.fetchData(true);
    }, 10000);

    if(!isDesktop){
      this.props.onRef(this);
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    if(!isDesktop){
      this.props.onRef(undefined);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.get_inbox_data !== undefined && nextProps.get_inbox_data !== this.props.get_inbox_data) {
      if (nextProps.get_inbox_data.data.responseCode === 200 && nextProps.get_inbox_data.data.msgType === "success") {
        let inbox_data = nextProps.get_inbox_data.data.data;
        inbox_data.forEach((val) => {
          val.value = val.Id;
          val.isChecked = false;
          val.read = val.status;
          val.date = val.created_at;
          val.html_content = val.content;
          val.content = val.content.replace(/(<([^>]+)>)/ig, '');
          val.icon = "msg-notice";
          val.msgType = "inbox";
        });

        if (this.state.isInterval === true && nextProps.get_inbox_data.data.hasNewMsg === "1") {
          this.setState({
            loading: true
          })
        }
        
        if (this.state.isInterval === false || (this.state.isInterval === true && nextProps.get_inbox_data.data.hasNewMsg === 1)) {
          if (nextProps.get_inbox_data.data.totalPages !== this.state.totalPages) {
            this.setState({
              loading: true,
              // msgListings: inbox_data,
              totalRecords: nextProps.get_inbox_data.data.totalRecords,
              totalPages: nextProps.get_inbox_data.data.totalPages,
              page: this.state.page,
              isInterval: false,
              filter: {
                activePage: this.state.page,
              },
              msgListings: [],
            }, () => {
              this.fetchData(true);
            });
          } else {
            this.setState({
              loading: false,
              msgListings: inbox_data,
              totalRecords: nextProps.get_inbox_data.data.totalRecords,
              totalPages: nextProps.get_inbox_data.data.totalPages
            });
          }
        }else{
          this.setState({
            loading: false,
            msgListings: inbox_data,
            totalRecords: nextProps.get_inbox_data.data.totalRecords,
            totalPages: nextProps.get_inbox_data.data.totalPages
          });
        }
      }

      if (nextProps.totalUnread !== undefined && nextProps.totalUnread !== this.props.totalUnread) {
        if (nextProps.totalUnread.responseCode === 200 && nextProps.totalUnread.msgType === "success") {
          let unreadNoticeCnt = nextProps.totalUnread.data.inboxCount;
          let unreadAnnouncementCnt = nextProps.totalUnread.data.notificationCount;
          unreadNoticeCnt = unreadNoticeCnt >= 99 ? 99 : unreadNoticeCnt;
          unreadAnnouncementCnt = unreadAnnouncementCnt >= 99 ? 99 : unreadAnnouncementCnt;
          this.setState({
            loading: false,
            unreadNotice: unreadNoticeCnt,
            unreadAnnouncement: unreadAnnouncementCnt
          });
        }
      }
    }

    if (nextProps.read_inbox_data !== this.props.read_inbox_data) {
      this.fetchData(true);
    }

    if (nextProps.delete_inbox_result !== this.props.delete_inbox_result) {
      const { t } = this.props;
        Swal.fire({
          iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
          title: t("global.success"),
          text: t("global.record-deleted"),
          icon: "success",
          confirmButtonText: t("global.ok"),
          customClass: (!isDesktop) ? {
            container: 'swal-mobile-container sucess',
            icon: 'swal-success-icon'
        } : [],
        }).then((result) => {
          if (result.isConfirmed) {
            this.fetchData(true);
          }
        });
    }
    return true;
  }

  fetchData = loading => {
    let params = {};
    params.page = this.state.page;
    params.limit = this.state.limit;
    params.country_id = this.props.currentUser.data.country_id;
    params.category_id = 1;

    if (loading && this.state.isInterval === false) {
      this.setState({ loading: true });
    }
    this.props.getInbox(params);

    params = {};
    params.lang_id = this.props.currentUser.data.language_id;
    this.props.getCountUnreadInboxAlert(params);
  }

  handleEdit = () => {
    this.setState({ showEdit: !this.state.showEdit }, () => {
      if (this.state.showEdit) {
        clearInterval(this.intervalId);
      } else {
        this.intervalId = setInterval(() => {
          this.setState({
            isInterval: true
          });
          this.fetchData(true);
        }, 10000);
      }
    });
  }

  handleAllChecked = (event) => {
    const msgListings = this.state.msgListings;
    msgListings.forEach(msgListing => (msgListing.isChecked = event.target.checked));
    this.setState({ msgListings: msgListings });
  };

  handleCheckChildElement = (event) => {
    const msgListings = this.state.msgListings;
    msgListings.forEach(msgListing => {
      if (msgListing.value === parseInt(event.target.value)) {
        msgListing.isChecked = event.target.checked;
      }
    });
    this.setState({ msgListings: msgListings });
  };

  handleRead = (event, type) => {
    event.preventDefault();
    const { t } = this.props;
    let read_inbox = [];

    this.setState({isInterval: false});

    if (type === "all") {
      clearInterval(this.intervalId);
      read_inbox = this.state.msgListings.filter((item) => item.status === 0).map((item) => item.Id);
    }
    else if (type === "selectable") {
      read_inbox = this.state.msgListings.filter((item) => item.isChecked === true && item.status === 0).map((item) => item.Id);
    }
    if (read_inbox.length > 0) {
      if (isDesktop) {
        Swal.fire({
          title: t("global.confirmation"),
          text: t("global.would-you-like-to-proceed") + "?",
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: t("global.cancel"),
          confirmButtonText: t("global._yes"),
        }).then((result) => {
          if (result.isConfirmed) {
            const readinboxData = {};
            readinboxData.inbox_id = read_inbox;
            if (type === "selectable") {
              this.setState({ showEdit: false });
            }
            this.props.readInbox(readinboxData);
            
            this.intervalId = setInterval(() => {
              this.setState({
                isInterval: true
              });
              this.fetchData(true);
            }, 10000);
          }
          if (result.isDismissed && type === "all") {
            this.intervalId = setInterval(() => {
              this.setState({
                isInterval: true
              });
              this.fetchData(true);
            }, 10000);
          }
        });
      } else {
        Swal.fire({
          iconHtml:
          '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">',
          icon: "warning",
          title: t("global.confirmation"),
          text: t("global.would-you-like-to-proceed") + "?",
          cancelButtonText: t("global.cancel"),
          confirmButtonText: t("global._yes"),
          showCancelButton: true,
          customClass: {
            container: "swal-mobile-container confirmation",
            icon: "swal-confirmation-icon",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const readinboxData = {};
            readinboxData.inbox_id = read_inbox;
            if (type === "selectable") {
              this.setState({ showEdit: false });
            }
            this.props.readInbox(readinboxData);

            this.intervalId = setInterval(() => {
              this.setState({
                isInterval: true
              });
              this.fetchData(true);
            }, 10000);

            this.props.unsetEditBtnState(0);
          }
          if (result.isDismissed && type === "all") {
            this.intervalId = setInterval(() => {
              this.setState({
                isInterval: true
              });
              this.fetchData(true);
            }, 10000);
          }
        });
      }
    }

    let params = {};
    params.lang_id = this.props.currentUser.data.language_id;
    this.props.getCountUnreadInboxAlert(params);
  }

  handleDelete = event => {
    event.preventDefault();
    const { t } = this.props;
    let delete_inbox = this.state.msgListings.filter((item) => item.isChecked === true).map((item) => item.Id);
    if (delete_inbox.length > 0) {
      if (isDesktop) {
        Swal.fire({
          title: t("global.reminder"),
          text: t("global.once-deleted-it") + "?",
          icon: 'warning',
          showCancelButton: true,
          cancelButtonText: t("global.cancel"),
          confirmButtonText: t("global.delete"),
        }).then((result) => {
          if (result.isConfirmed) {
            const deleteinboxData = {};
            deleteinboxData.inbox_id = delete_inbox;
            this.props.deleteInbox(deleteinboxData);

            //return to first page regardless of where picked messages are deleted
            this.setState({ page: 1, loading: true, msgListings: [], showEdit: false }, () => {
              this.fetchData(false);

              this.intervalId = setInterval(() => {
                this.setState({
                  isInterval: true
                });
                
                this.fetchData(true);
              }, 10000);
            });
          }
        });
      } else {
        Swal.fire({
          iconHtml:
          '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">',
          icon: "warning",
          title: t("global.reminder"),
          text: t("global.once-deleted-it") + "?",
          confirmButtonText: t("global.delete"),
          cancelButtonText: t("global.cancel"),
          showCancelButton: true,
          customClass: {
            container: "swal-mobile-container reminder",
            icon: "swal-reminder-icon",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            const deleteinboxData = {};
            deleteinboxData.inbox_id = delete_inbox;
            this.props.deleteInbox(deleteinboxData);

            //return to first page regardless of where picked messages are deleted
            this.setState({ page: 1, loading: true, msgListings: [], showEdit: false }, () => {
              this.fetchData(false);

              this.intervalId = setInterval(() => {
                this.setState({
                  isInterval: true
                });
                this.fetchData(true);
              }, 10000);

              this.props.unsetEditBtnState(0);
            });
          }
        });
      }
    }
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  datatablesChangeHandler = (val) => {
    this.setState({
      filter: {
        activePage: val,
      },
      msgListings: [],
      loading: true,
      page: val,
      isInterval: false
    }, () => {
      this.fetchData(true);
    });
  }

  render() {
    const { t } = this.props;
    // let inbox = this.state.msgListings ? this.state.msgListings : [];
    let rsData = [];

    const truncate = (value) => {
      const replaceValue = value.replace('&nbsp;', ' ');
    
      return isDesktop ? (
        replaceValue?.length > 60 ? `${replaceValue.substring(0, 60)}...` : replaceValue
      ) : (
        replaceValue?.length > 40 ? `${replaceValue.substring(0, 40)}...` : replaceValue
      );
    };

    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const columns = [
      { text: 'content', dataField: "msgContent", headerAttrs: { hidden: true }, classes: 'msg-list-group' },
    ];

    rsData = this.state.msgListings.map((val, idx) => {
      const msgContent = (
        <div className={`list-group-item ${this.state.showEdit && 'show'}`} onClick={(event) => this.props.handleChangePage(event, 'details', val.value, this.state.msgListings, 'notification', val.status, this.state.page)}>
          {isDesktop ? (
            this.state.showEdit &&
            <input
              className="form-check-input"
              onChange={this.handleCheckChildElement}
              type="checkbox"
              checked={val.isChecked}
              value={val.value}
              onClick={(event) => event.stopPropagation()}
            />
          ) : (
            <input
              className="form-check-input"
              onChange={this.handleCheckChildElement}
              type="checkbox"
              checked={val.isChecked}
              value={val.value}
              onClick={(event) => event.stopPropagation()}
            />
            )
          }
          <div className="msg-icon-col">
            {
              !val.read &&
              <span className="notify-red-dot" />
            }
            <img src={icons['msg-notice.svg']} alt='msg-notice' />
          </div>
          {isDesktop ? (
            <>
              <h6>【<span>{val.title}</span>】</h6>
              <p>{truncate(val.content)}</p>
              <span>{val.date}</span>
            </>
          ) : (
            <div className="list-group-item-r">
              <label>
                <h6>【<span>{val.title}</span>】</h6>
                <span>{val.date}</span>
              </label>
              <p>{truncate(val.content)}</p>
            </div>
          )}
        </div>
      );
      return {
        msgContent: msgContent
      };
    });

    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              <div className={`tab-pane fade ${this.props.activePanel === 0 ? "show active" : ""}`} id="msg-notice" role="tabpanel">
                <div className="msg-list-col">
                  <div className="msg-edit-col" >
                    {this.state.showEdit &&
                      <div className="msg-edit-panel left">
                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" value="" id="checkAll" onChange={this.handleAllChecked} key="checkAll" />
                          <label htmlFor="checkAll">
                            {t("message.all")}
                          </label>
                        </div>
                        <button className="btn text-icon" type="button" onClick={(event) => this.handleRead(event, 'selectable')}>
                          <Icon icon="mdi:email-open-outline" />
                          {t("message.mark-as-read")}
                        </button>
                        <button className="btn text-icon trash" type="button" onClick={(event) => this.handleDelete(event)}>
                          <Icon icon="uil:trash-alt" />
                          {t("message.delete")}
                        </button>
                      </div>
                    }
                    <div className="msg-edit-panel right">
                      {this.state.showEdit ? (
                        <button className="btn text-icon" type="button" onClick={this.handleEdit}>
                          <>{t("message.done")}</>
                        </button>
                      ) : (
                        // inbox && inbox.length>0 &&
                        <>
                          <button className="btn text-icon" type="button" onClick={(event) => this.handleRead(event, 'all')}>
                            <Icon icon="mdi:email-open-multiple-outline" />
                            {t("message.all-read")}
                          </button>
                          <button className="btn text-icon" type="button" onClick={this.handleEdit}>
                            {t("global.edit")}
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="msg-list">
                    {this.state.loading ? (
                      <Skeleton count={2} height={50} style={{ marginBottom: ".25rem" }} />
                    ) : (
                      <Fade duration={1000} triggerOnce>
                        <DataTable
                          keyField="no"
                          data={rsData}
                          columns={columns}
                          activePage={this.state.page}
                          limit={this.state.limit}
                          totalItems={this.state.totalRecords}
                          paginationHandler={(val) => this.datatablesChangeHandler(val)}
                          sizePerPageHandler={(val) => this.datatablesChangeHandler(val)}
                          loading={this.state.loading}
                          hideSearchBar={true}
                          hideTotalRecords={true}
                          classValue="pd-table"
                          type="notification"
                        />
                      </Fade>
                    )}
                  </div>
                </div>
              </div>
            </>
          ) :
            (
              <div className={`tab-pane fade ${this.props.activePanel === 0 ? "show active" : ""}`} id="msg-notice" role="tabpanel">
                <div className="msg-list-col">
                  <div className="msg-list">
                    {this.state.loading ? (
                      <Skeleton count={2} height={180} style={{ marginBottom: ".5rem" }} />
                    ) : (
                      <Fade duration={1000} triggerOnce>
                        <DataTable
                          keyField="no"
                          data={rsData}
                          columns={columns}
                          activePage={this.state.page}
                          limit={this.state.limit}
                          totalItems={this.state.totalRecords}
                          paginationHandler={(val) => this.datatablesChangeHandler(val)}
                          sizePerPageHandler={(val) => this.datatablesChangeHandler(val)}
                          loading={this.state.loading}
                          hideSearchBar={true}
                          hideTotalRecords={true}
                          classValue="pd-table"
                          type="notification"
                        />
                      </Fade>
                    )}
                  </div>
                </div>
                <div className={`msg-edit-col ${this.state.showEdit && 'show'}`} >
                  <div className="msg-edit-panel left">
                    <button className="btn text-icon" type="button" onClick={(event) => this.handleRead(event, 'selectable')}>
                      {t("message.mark-as-read")}
                    </button>
                  </div>
                  <div className="msg-edit-panel right">
                    {/* <button className="btn text-icon" type="button" onClick={this.handleEdit}>
                        <>{t("message.done")}</>
                      </button> */}
                    <button className="btn text-icon trash" type="button" onClick={(event) => this.handleDelete(event)}>
                      {t("message.delete")}
                    </button>
                  </div>
                </div>
              </div>
            )
        }
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  // console.log("[mapStateToProps]",state);
  return {
    currentUser: state.currentUser,
    get_inbox_data: state.inbox.get_inbox_data,
    delete_inbox_result: state.inbox.delete_inbox_result,
    read_inbox_data: state.inbox.read_inbox_data,
    totalUnread: state.inbox.count_unread_inbox_alert_data,
  }
}

const mapDispatchToProps = {
  getCountUnreadInboxAlert,
  getInbox,
  deleteInbox,
  readInbox
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Notice));
