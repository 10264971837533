import React, { Component } from 'react'
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
import Modal from "react-modal";
import StringInput from "../Input/StringInput/StringInput";
import Dropzone from 'react-dropzone';
import { isDesktop } from 'react-device-detect';
import { default as ReactSelect } from "react-select";

import { getMemberBank } from "../../store/ducks/profile/actions";
import { formValidation } from "../../hoc/Shared/utility";
import { Link } from 'react-router-dom';
import { currency } from "../../common/config/currency";

export class UpgradeAccountModal extends Component {
  constructor(props) {
    super(props);
    this.state =
    {
      bankDropDown: [],
      companyName: "",
      companyRegNo: "",
      bank_name: null,
      bank_account_number: "",
      ssm_image: "",
      loading: true,
    }
  }
  componentDidMount() {
    this.props.getMemberBank();
  }
  shouldComponentUpdate(nextProps, nextState) {
    // console.log("[BankingDetails] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
    // const { t } = this.props;
    if (nextProps.memberBankData !== this.props.memberBankData && nextProps.memberBankData !== undefined) {
      if (nextProps.memberBankData.responseCode === 200) {
        let accountHolder = nextProps.memberBankData.data.accountHolder;
        let memberBank = nextProps.memberBankData.data.memberBank;
        let bankDropdown = nextProps.memberBankData.data.bank;
        this.setState({
          bank_acc_name: accountHolder.name,
          memberBankData: memberBank,
          bankDropDown: bankDropdown,
          loading: false,
          totalRecords: memberBank.length,
        });
      }
    }

    return true
  }
  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  }

  render() {
    const { t } = this.props;
    const bankImg = this.imageImport(require.context("../../assets/images/profile/banks", false, /\.(png|jpe?g|svg)$/));
    let addMemberBankArray = [];
    if (this.state.bankDropDown && !this.state.loading) {
      addMemberBankArray = this.state.bankDropDown.map((val, idx) => {
        return {
          id: val.bankId,
          label: val.bankName,
          icon: `${val.swiftCode.toLowerCase()}-color`,
          key: idx,
        };
      });
    }

    if (this.props.upgradeAccForm === true) {
      formValidation('business_account_form');
    }

    return (
      <>
        <Modal
          isOpen={this.props.businessAccModal}
          closeTimeoutMS={500}
          portalClassName={`global-modal`}
          className="modal modal-dialog modal-dialog-centered modal-pd"
          ariaHideApp={false}
          onRequestClose={this.props.toggleBusinessAccModal}>
          {
            isDesktop ? (
              <>
                {
                  this.props.upgradeAccForm ? (
                    <div className="modal-content upgrade-acc-modal">
                      <div className="modal-header">
                        <div className="pb-3">
                          <h5>{t("profile.upgrade-business-acc")}</h5>
                          <p>{t("profile.upgrade-business-acc-desc")}</p>
                        </div>
                        <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleBusinessAccModal} />
                      </div>
                      <div className="modal-body">
                        <form className="form-col needs-validation d-grid gap-3" method="post" id="business_account_form" encType='multipart/form-data' onSubmit={this.props.submitBusinessAccountHandler} noValidate>
                          <div className="row">
                            <label className="col-4 col-form-label">{t("profile.comp-name")}</label>
                            <div className="col-8">
                              <StringInput
                                className={`form-control ${(this.props.errorMsg.company_name) ? 'was-validated is-invalid' : ''}`}
                                onChange={(e) => this.props.setBusinessAcc(e)}
                                id="companyName"
                                name="companyName"
                                placeholder={t("profile.insert-comp-name")}
                                value={this.props.businessAccDetail.companyName}
                                required
                                requiredMessage={t("validation.required")}
                                errorMsg={this.props.errorMsg.company_name || ''}
                                formError={this.props.formError}
                              />
                            </div>

                          </div>
                          <div className="row">
                            <label className="col-4 col-form-label">{t("profile.comp-reg-no")}</label>
                            <div className="col-8">
                              <StringInput
                                className={`form-control ${(this.props.errorMsg.company_reg_no) ? 'was-validated is-invalid' : ''}`}
                                onChange={(e) => this.props.setBusinessAcc(e)}
                                id="companyNo"
                                name="companyNo"
                                placeholder={t("profile.insert-comp-reg-no")}
                                value={this.props.businessAccDetail.companyNo}
                                required
                                requiredMessage={t("validation.required")}
                                errorMsg={this.props.errorMsg.company_reg_no || ''}
                                formError={this.props.formError}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <label className="col-4 col-form-label">{t("banking-details.bank-name")}</label>
                            <div className="col-8">
                              <ReactSelect
                                classNamePrefix="react-select"
                                className={`${(this.props.errorMsg.bank_id) ? 'form-control is-invalid' : ''}`}
                                placeholder={t("banking-details.select-bank")}
                                name="bank_name"
                                options={addMemberBankArray}
                                onChange={this.props.handleBankChange}
                                value={(this.props.businessAccDetail.bankId !== null) ? addMemberBankArray[this.props.businessAccDetail.bankId] : ''}
                                formatOptionLabel={(item) => (
                                  <>
                                    <img src={
                                      (bankImg[`${item.icon.toLowerCase()}.svg`] !== undefined)
                                        ?
                                        bankImg[`${item.icon.toLowerCase()}.svg`]
                                        :
                                        bankImg["default-bank-icon.png"]
                                    } alt={item.label} />
                                    <p>{item.label}</p>
                                  </>
                                )}
                              />
                              <div className="invalid-feedback block">{this.props.errorMsg.bank_id || ''}</div>
                            </div>

                          </div>
                          <div className="row">
                            <label className="col-4 col-form-label">{t("profile.bank-acc-no")}</label>
                            <div className="col-8">
                              <StringInput
                                className={`form-control ${(this.props.errorMsg.bank_account_number) ? 'was-validated is-invalid' : ''}`}
                                onChange={(e) => this.props.setBusinessAcc(e)}
                                id="bankNo"
                                name="bankNo"
                                placeholder={t("profile.insert-bank-acc-no")}
                                value={this.props.businessAccDetail.bankNo}
                                required
                                requiredMessage={t("validation.required")}
                                errorMsg={this.props.errorMsg.bank_account_number || ''}
                                formError={this.props.formError}
                              />
                            </div>

                          </div>
                          <div className="row">
                            <label className="col-4 col-form-label">{t("profile.ssm-no")}</label>
                            <div className="col-8">
                              <Dropzone
                                maxFiles={1}
                                accept={'image/*,.pdf'}
                                required
                                // maxSize={500000}
                                onDrop={this.props.onDrop}>
                                {({ getRootProps, getInputProps }) => (
                                  <div className={`dropzone-container ${(this.props.errorMsg?.file) ? 'form-control is-invalid' : ''}`}>
                                    {this.props.files && this.props.files.length ? (
                                      <aside>
                                        <ul>{this.props.files}</ul>
                                      </aside>
                                    ) : ''}
                                    <div {...getRootProps({ className: 'dropzone' })}>
                                      <div>
                                        <input {...getInputProps()} />
                                        <Icon icon="mdi:upload" />
                                        <p>{t('deposit.drop-file-msg-1')}</p>
                                        <p>{t('deposit.drop-file-msg-4')}</p>
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Dropzone>
                              <div className="invalid-feedback d-block">{this.props.errorMsg.ssm_image || ''}</div>
                            </div>
                          </div>

                          <button type="submit"
                            className="btn btn-gradient-blue mb-3"
                            disabled={this.props.businessAccDetail.companyName === "" || this.props.businessAccDetail.companyNo === "" || this.props.businessAccDetail.bankNo === "" || this.props.businessAccDetail.ssm_image === "" || this.props.businessAccDetail.bankId === "" ? true : false}>
                            {t("global.submit")}
                          </button>
                        </form>
                      </div>
                    </div>
                  ) : (
                    <div className="modal-content upgrade-acc-modal">
                      <div className="modal-header border-bottom">
                        <div className="pb-3">
                          <h5>{t("profile.upgrade-business-acc")}</h5>
                          {
                            this.props.verifyBusinessAcc === true && this.props.businessAccountStatus === false ? (
                              <></>
                            ) : (
                              <p>{t("profile.upgrade-business-acc-role").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</p>
                            )
                          }
                          {/* <p>{t("profile.upgrade-business-acc-role")}</p> */}
                        </div>
                        <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleBusinessAccModal} />
                      </div>
                      <div className="modal-body mt-3">
                        <h6 className='pt-0'>{t("vip.term-condition")}</h6>
                        <ul className='upgrade-acc-benefit'>
                          <li>
                            <span>1. {t("profile.business-acc-benefit-1").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</span>
                          </li>
                          <li>
                            <span>2. {t("profile.business-acc-benefit-2")}</span>
                          </li>
                          <li>
                            <span>3. {t("profile.business-acc-benefit-3")}</span>
                          </li>
                          <li>
                            <span>4. {t("profile.business-acc-benefit-4")}</span>
                          </li>
                          <li>
                            <span>5. {t("profile.business-acc-benefit-5").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</span>
                          </li>
                          <li>
                            <span>6. {t("profile.business-acc-benefit-6")}</span>
                          </li>
                        </ul>
                        {
                          this.props.readedBnf ? (
                            <div className="continue-btn-container mt-3">
                              <button className="btn btn-gray me-2" onClick={this.props.toggleBusinessAccModal}>{t("global.cancel")}</button>
                              {this.props.verifyBusinessAcc === true && this.props.businessAccountStatus === false ? <button className="btn btn-gradient-blue ms-2" onClick={() => this.props.updateBusinessAccBtn("continue")}>{t("global.continue")}{" >>"}</button> : <Link className="btn btn-gradient-blue ms-2 d-flex align-items-center justify-content-center"
                                to={{ pathname: "/deposit" }}>
                                {t('norecord.deposit-now')}
                              </Link>}
                            </div>
                          ) : (
                            <button className="btn btn-gradient-blue mt-3" onClick={() => this.props.updateBusinessAccBtn("readed")}>{t("global.ok")}</button>
                          )
                        }

                      </div>
                    </div>
                  )
                }
              </>
            ) : (
              <div className="modal-content upgrade-acc-modal mobile-modal-bg-white p-0">
                <div className="modal-header border-bottom p-3">
                  <div>
                    <h5>{t("profile.upgrade-business-acc")}</h5>
                    {
                      this.props.verifyBusinessAcc === true && this.props.businessAccountStatus === false ? (
                        <></>
                      ) : (
                        <p>{t("profile.upgrade-business-acc-role").replace("##currency##", currency[this.props.currentUser.country_id]) ?? ""}</p>
                      )
                    }
                    {/* <p>{t("profile.upgrade-business-acc-role")}</p> */}
                  </div>
                  <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleBusinessAccModal} />
                </div>
                <div className="modal-body p-3">
                  <h6 className='pt-0'>{t("vip.term-condition")}</h6>
                  <ul className='upgrade-acc-benefit'>
                    <li>
                      <span>1. {t("profile.business-acc-benefit-1").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</span>
                    </li>
                    <li>
                      <span>2. {t("profile.business-acc-benefit-2")}</span>
                    </li>
                    <li>
                      <span>3. {t("profile.business-acc-benefit-3")}</span>
                    </li>
                    <li>
                      <span>4. {t("profile.business-acc-benefit-4")}</span>
                    </li>
                    <li>
                      <span>5. {t("profile.business-acc-benefit-5").replace("##currency##", currency[this.props.currentUser.country_id] ?? "")}</span>
                    </li>
                    <li>
                      <span>6. {t("profile.business-acc-benefit-6")}</span>
                    </li>
                  </ul>
                </div>
                {
                  this.props.readedBnf ? (
                    <>
                      {this.props.verifyBusinessAcc === true && this.props.businessAccountStatus === false ? (
                        <button type="button" className="btn btn-white" onClick={() => this.props.updateBusinessAccBtn("continue")} >{t("global.continue")}{" >>"}</button>
                      ) : (
                        <Link className="btn btn-white d-flex align-items-center justify-content-center"
                          to={{ pathname: "/deposit" }}>
                          {t('norecord.deposit-now')}
                        </Link>
                      )}
                      <button type="button" className="btn btn-gray" onClick={this.props.toggleBusinessAccModal}>{t("global.cancel")}</button>
                    </>
                  ) : (
                    <button type="button" className="btn btn-white" onClick={() => this.props.updateBusinessAccBtn("readed")} >{t("global.ok")}</button>
                  )
                }

              </div>
            )
          }

        </Modal>


      </>

    )
  }
}

const mapStateToProps = (state) => {
  const { profile } = state;

  return {
    memberBankData: profile.member_bank_data,
    currentUser: state.currentUser.data
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getMemberBank: () => dispatch(getMemberBank())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(UpgradeAccountModal));