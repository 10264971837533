import React, { Component } from 'react'
import { isDesktop } from "react-device-detect";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";

export class MallCheckbox extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(require.context("../../assets/images/x33mall/", false, /\.(png|jpe?g|svg)$/));

    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              <tbody>
                <tr>
                  <td>
                    {
                      (this.props.stock > 0) &&
                      <input
                        className="form-check-input"
                        key={this.props.id}
                        onClick={this.props.handleCheckChildElement}
                        type="checkbox"
                        checked={this.props.isChecked}
                        value={this.props.id}
                      />
                    }
                  </td>
                  <td>
                    <div className='ratio ratio-16x9'>
                    <img src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + this.props.image} alt={this.props.title} />
                    </div>
                  </td>
                  <td>{this.props.title}</td>
                  {
                    (this.props.qty > 0) ? (
                      <td>
                        <button className="btn count-btn left" onClick={() => this.props.minusProductCount(this.props.idx)}>-</button>
                        <button className="btn count-btn center" type="text">{this.props.qty}</button>
                        <button className="btn count-btn right" onClick={() => this.props.addProductCount(this.props.idx)}>+</button>
                      </td>
                    ) : (
                      <td>{t('x33-mall.out-of-stock')}</td>
                    )
                  }

                  {
                    (this.props.qty > 0) ? (
                      <td><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{this.props.qty * this.props.coin}</td>
                    ) : (
                      <td></td>
                    )
                  }
                  <td>
                    <button className="btn" onClick={() => this.props.removeProduct(this.props.idx)}>
                      <img src={img["delete-btn.svg"]} alt="delete button" className="delete-icon" />
                    </button>
                  </td>
                </tr>
              </tbody>
            </>
          ) : (
            <div className="checkout-product">
              <div className="row align-items-center my-3">
                <div className='col-1'>
                  {
                    (this.props.stock > 0) &&
                    <input
                      className="form-check-input"
                      key={this.props.id}
                      onClick={this.props.handleCheckChildElement}
                      type="checkbox"
                      checked={this.props.isChecked}
                      value={this.props.id}
                    />
                  }
                </div>
                <div className="col-3">
                  <div className="product-broder">
                    <div className='ratio ratio-1x1'>
                    <img className="product-img" src={process.env.REACT_APP_API_DEVELOPMENT_URL.slice(0, -1) + this.props.image} alt={this.props.title} />
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <div>
                      <p className="details-title">{this.props.value}</p>
                    </div>
                    <div>
                      <button className="btn" onClick={() => this.props.removeProduct(this.props.idx)}>
                        <img src={img["delete-btn.svg"]} alt="delete button" className="delete-icon" />
                      </button>
                    </div>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    {
                      (this.props.qty > 0) ? (
                        <span className="details-cont">
                          <p>Order Total</p><small className="f-orange"><img src={img["coin.svg"]} alt="R77 coin" className="product-coin" />{this.props.qty * this.props.coin}</small>
                        </span>
                      ) : (
                        <span className="details-cont"></span>
                      )
                    }

                    {
                      (this.props.qty > 0) ? (
                        <div>
                          <button className="btn count-btn left" onClick={() => this.props.minusProductCount(this.props.idx)}>-</button>
                          <button className="btn count-btn center" type="text">{this.props.qty}</button>
                          <button className="btn count-btn right" onClick={() => this.props.addProductCount(this.props.idx)}>+</button>
                        </div>
                      ) : (
                        <div>{t('x33-mall.out-of-stock')}</div>
                      )
                    }
                  </div>
                </div>
              </div>
            </div>
          )
        }
      </Auxiliary>
    )
  }
}

export default MallCheckbox
