import React, { Component } from 'react'
import { withNamespaces } from "react-i18next";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary'
import MobileSubHeader from '../PageHeader/MobileSubHeader'

export class MobileImportantNotice extends Component {

  render() {
    const { t } = this.props;
    const notice = this.props.notice;
    return (
      <Auxiliary>
        <section className="pf-m-dashboard on-top">
          <MobileSubHeader
            // className="bg-white"
            closeModalHandler={this.props.toggleNoticeHandler}
            header={t('deposit.notice')}
            style={{ justifyContent: "unset" }}
          />
          <div className="payment-notices-col">
            {
              notice === "deposit" ? (
                <ol>
                  <li>{t('deposit.deposit-notice-content-1')}</li>
                  <li>{t('deposit.deposit-notice-content-2')}</li>
                  <li><span>{t('deposit.deposit-notice-content-title-3')}</span> {t('deposit.deposit-notice-content-3')}</li>
                  <li>{t('deposit.deposit-notice-content-4')}<span>{t('deposit.deposit-notice-content-title-4')}</span>{t('deposit.deposit-notice-content-4-cont')}</li>
                  <li>{t('deposit.deposit-notice-content-5')}</li>
                  <li>{t('deposit.deposit-notice-content-6')}<a href="/" onClick={window['liveChatButton']}>{t('deposit.deposit-notice-content-title-6')}</a>{t('deposit.deposit-notice-content-6-cont')}</li>
                </ol>
              ) : notice === "withdrawal" ? (
                <ol>
                  <li>{t('withdrawal.notice-content-1')}<a href="/" onClick={window['liveChatButton']}>{t('withdrawal.notice-content-2')}</a>{t('withdrawal.notice-content-3')}</li>
                  <li>{t('withdrawal.notice-content-4')}</li>
                  <li>{t('withdrawal.notice-content-5')}</li>
                  <li>{t('withdrawal.notice-content-6')}</li>
                </ol>
              ) : (
                <></>
              )
            }
          </div>
        </section>
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileImportantNotice));
