import React, { Component } from "react";
import { Link } from "react-router-dom";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
export class GamePageContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      moreContent: false,
    };
  }

  componentDidUpdate(prevProps) {
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  displayMoreContent = () => {
    this.setState({
      moreContent: !this.state.moreContent,
    });
  };

  hiddenMoreContent = () => {
    this.setState({
      moreContent: false,
    });
  };

  render() {
    const { t } = this.props;

    let hotLang = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        hotLang = "-cn"
        break;
        default:
          hotLang = ""
          break;
    }

    return (
      <div className="game-page-container">
        <div
          className={`${this.props.fadeIn === true ? "fade-in-right" : "fade-out-right"
            } d-grid gap-3`}
        >
          <LazyLoadImage className="title-img" src={this.props.pageTitle} alt="title-img" effect="blur" />
          <div>
            <>
              <p
                className={`game-content readMore-content
                ${this.state.moreContent ? "active" : ""}
                
                `}
              >
                {this.props.content}
              </p>
              <p className="more-btn" onClick={() => this.displayMoreContent()}>
                {
                  this.state.moreContent ? `${t("global.read-less")}` : `${t("global.read-more")}`
                }
              </p>
            </>

            {this.props.contentImg && (
              <img
                className="content-img mt-3"
                src={this.props.contentImg}
                alt="content-img"
              />
            )}
          </div>
        </div>

        <div className="row g-2">
          {this.props.gameIcon &&
            this.props.gameIcon.map((item, index) => (
              <div className="col-5ths" key={index}>
                <span
                  className={`game-icon-container ${(item.hotStatus === 1) ? `hot${hotLang}` : ''}`}
                  style={{
                    pointerEvents: `${this.props.fadeIn === false ? "none" : ""
                      }`,
                  }}
                >
                  <img
                    className="game-icon-img"
                    src={
                      this.props.activeBtn === item.id
                        ? item.activeImg
                        : item.img
                    }
                    alt="game-icon"
                    onClick={() => {
                      this.props.changeActivePage(item.id);
                      this.hiddenMoreContent();
                    }}
                  />
                </span>
              </div>
            ))}
        </div>

        {this.props.nolink ?
          <button
            className="enter-btn-blue"
            onClick={() => this.props.accessGame(this.props.curVendor)}
            style={{
              pointerEvents: `${this.props.fadeIn === false ? "none" : ""
                }`,
            }}>
            {t("global.enter-games")}
          </button> :
          <Link
            to={{ pathname: `${this.props.link}` }}
            className="enter-btn-blue"
            style={{
              pointerEvents: `${this.props.fadeIn === false ? "none" : ""
                }`,
            }}
          >
            {t("global.enter-games")}
            {/* <img src={enterGame[`enter-game-btn${imageLanguage}.png"`]} alt="enter-game-btn" /> */}
          </Link>
        }
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser.data
  }
};


export default connect(mapStateToProps)(withNamespaces("translation")(GamePageContent));
