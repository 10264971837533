import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import moment from "moment";
import ReactLoading from "react-loading";

import { Icon } from "@iconify/react";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";

import { getCountUnreadInboxAlert } from "../../../store/ducks/inbox/actions";
import { getUserData } from "../../../store/ducks/currentUser/actions";
import { getTopProfile, getBusinessAccount } from "../../../store/ducks/profile/actions";
import { getSystemSetting } from "../../../store/ducks/setting/actions";

import UpgradeAccountModal from "../../../components/BusinessUpgrade/UpgradeAccountModal";
import MobileBusinessUpgrade from "../../../components/BusinessUpgrade/MobileBusinessUpgrade";
import MobileBusinessAccount from "../../../components/BusinessUpgrade/MobileBusinessAccount";
import X33CoinModal from "../../../components/X33CoinModal/X33CoinModal";

export class MobileProfile extends Component {
  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      // let newFiles = this.state.files
      // newFiles.push(files)
      // this.setState({ files:newFiles })
      this.setState({ files })
    }
    this.state = {
      login: localStorage.getItem('accessToken') ? true : false,
      msgLoading: true,
      unreadMsgCnt: 0,
      topProfileData: [],

      businessAccModal: false,
      readedBnf: false,
      upgradeAccForm: false,

      businessAccDetail: {},
      files: [],
      showCoporateInfo: false,
      companyName: "",
      companyRegNo: "",
      bankAccNo: "",
      ssmImage: "",

      openCoinModal: false,
      activeBusinessAcc: false
    };
  }

  componentDidMount() {
    if (this.state.login) {
      let data = {};
      data.lang_id = this.props.currentUser.language_id;
      this.props.getCountUnreadInboxAlert(data);

      this.props.getTopProfile();
      this.mobileTopProfileInterval = setInterval(() => {
        this.props.getTopProfile();
      }, 10000);

      this.props.getSystemSetting();
      this.props.getBusinessAccount();
    }
    else {
      clearInterval(this.mobileTopProfileInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.mobileTopProfileInterval);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.unreadMessagesCnt !== undefined && nextProps.unreadMessagesCnt !== this.props.unreadMessagesCnt) {
      if (nextProps.unreadMessagesCnt.responseCode === 200 && nextProps.unreadMessagesCnt.msgType === "success") {
        let unreadCnt = nextProps.unreadMessagesCnt.data.totalCount;
        unreadCnt = unreadCnt >= 99 ? 99 : unreadCnt;
        this.setState({
          msgLoading: false,
          unreadMsgCnt: unreadCnt
        });
      }
    }

    if (nextProps.topProfileData !== undefined && nextProps.topProfileData !== this.props.topProfileData) {
      if (nextProps.topProfileData.responseCode === 200) {
        this.setState({
          topProfileData: nextProps.topProfileData.data,
        });
      }
    }

    if (nextProps.get_business_account_result !== undefined && nextProps.get_business_account_result !== this.props.get_business_account_result) {
      if (nextProps.get_business_account_result.responseCode === 200) {
        if (nextProps.get_business_account_result.data) {
          this.setState({
            companyName: nextProps.get_business_account_result.data.company_name,
            companyRegNo: nextProps.get_business_account_result.data.company_reg_no,
            bankAccNo: nextProps.get_business_account_result.data.bank_account_number,
            ssmImage: nextProps.get_business_account_result.data.ssm_image
          });
        }
      }
    }

    if (nextProps.system_setting !== undefined && nextProps.system_setting !== this.props.system_setting) {
      if (nextProps.system_setting.data.responseCode === 200 && nextProps.system_setting.data.msgType === "success") {
        // nextProps.system_setting.data.data.result.map((val) => {
        //     if(val.event === "business-account" && val.active === false){
        //       this.setState({ activeBusinessAcc: false });
        //     }else{
        //       this.setState({ activeBusinessAcc: true});
        //     }
        // });
        let businessAcc = nextProps.system_setting.data.data.result.filter((val) => val.event === "business-account");
        if (businessAcc.length > 0 && businessAcc[0]) {
          this.setState({ activeBusinessAcc: businessAcc[0].active });
        }
      }
    }

    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  toggleBusinessAccModal = () => {
    this.setState({
      businessAccModal: !this.state.businessAccModal,
      readedBnf: false,
      // upgradeAccForm:true
    })
  }

  updateBusinessAccBtn = (ctg) => {
    if (ctg === 'readed') {
      this.setState({ readedBnf: true })
    } else if (ctg === 'continue') {
      this.setState({
        upgradeAccForm: true,
        businessAccModal: false,
        readedBnf: false
      })
    } else if (ctg === 'cancel') {
      this.setState({ readedBnf: false })
    }
  }

  setBusinessAcc = (event) => {
    let newFilter = Object.assign({}, this.state.businessAccDetail);
    newFilter[event.target.id] = event.target.value;
    this.setState({
      businessAccDetail: newFilter,
      formError: false,
      errorMsg: []
    });
  }


  removeFileHandler = () => {
    this.setState({
      files: [],
      // progress: 0,
    });
  }

  toggleBusinessForm = () => {
    this.setState({
      upgradeAccForm: !this.state.upgradeAccForm
    })
  }

  showCorporateInfoHandler = () => {
    this.setState({
      showCoporateInfo: !this.state.showCoporateInfo
    })
  }

  toggleCoinModal = () => {
    this.setState({
      openCoinModal: !this.state.openCoinModal
    })
  }

  render() {
    const { t } = this.props;
    const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };

    const icons = this.imageImport(require.context("../../../assets/images/profile/nav-icons", false, /\.(png|jpe?g|svg|webp)$/));
    const avatar = this.imageImport(
      require.context("../../../assets/images/profile/profile-icons", false, /\.(png|jpe?g|svg|webp)$/)
    );
    const mbIcon = this.imageImport(
      require.context(
        "../../../assets/images/profile/mobile/icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    let memberRegisterDate = moment(this.props.currentUser.register_date_time, "YYYY-MM-DD HH:mm:ss");
    let currentDateTime = moment();
    let calculateRegisterDays = currentDateTime.diff(memberRegisterDate, 'days');

    let topProfileData = null;
    let verifyBusinessAccount = false;
    let businessAccountStatus = false;

    if (!this.state.loading) {
      topProfileData = (this.props.topProfileData && this.props.topProfileData.data);
      verifyBusinessAccount = (this.props.topProfileData && this.props.topProfileData.data.topProfile.verify_business_account);
      businessAccountStatus = (this.props.topProfileData && this.props.topProfileData.data.topProfile.business_account_status);
    }

    let files = this.state.files.map(file => (
      <li key={file.name}>
        <p>
          {file.name} - {Math.round(file.size / 1024)} KB{" "}
          <button className="btn icon" onClick={this.removeFileHandler}>
            <Icon icon="mdi:close" />
          </button>
        </p>
      </li>
    ));

    return (
      <Auxiliary>
        <MobileBusinessUpgrade
          {...this.state}
          files={files}
          onDrop={this.onDrop}
          // removeFileHandler={this.removeFileHandler}
          setBusinessAcc={this.setBusinessAcc}

          toggleBusinessForm={this.toggleBusinessForm}
        />

        <MobileBusinessAccount
          showBusinessAcc={this.state.showCoporateInfo}
          toggleBusinessAcc={this.showCorporateInfoHandler}
          companyName={this.state.companyName}
          companyRegNo={this.state.companyRegNo}
          bankAccNo={this.state.bankAccNo}
          ssmImage={this.state.ssmImage}
        />

        <UpgradeAccountModal
          {...this.state}
          toggleBusinessAccModal={this.toggleBusinessAccModal}
          updateBusinessAccBtn={this.updateBusinessAccBtn}
          verifyBusinessAcc={verifyBusinessAccount}
          businessAccountStatus={businessAccountStatus}
        />

        <X33CoinModal
          openCoinModal={this.state.openCoinModal}
          toggleCoinModal={this.toggleCoinModal}
          currentUser={this.props.currentUser}
        />

        <section className="pi-m-section">
          <div className="pi-m-header">
            <MobileSubHeader
              subPage="/personal-information"
              subPageHeader={<img src={mbIcon["personal-info-edit.svg"]} alt="edit" />}
              className="bg-transparent"
              // showSubIcon={true}
            />
            <div className="d-flex justify-content-between align-items-center">
              <div className="pi-m-info m-0">
                <div role="button" className="user-profile-container">
                  {topProfileData ? (
                    <img
                      className="user-profile-img"
                      src={(topProfileData && topProfileData.topProfile.profile_picture) || avatar["male-7.jpg"]}
                      alt="profile img"
                    />
                  ) : (
                    <div className="react-loading">
                      <ReactLoading
                        type={"spinningBubbles"}
                        color={"#fff"}
                      />
                    </div>
                  )}
                </div>
                <div className="user-info">
                  <div className="d-flex align-items-center gap-2">
                    <p className="us-name">{this.props.currentUser.username}</p>
                    <p className="vip-icon">  <img src={avatar[`vip-${this.props.currentUser.user_group_level}.webp`]} alt="vip-badge"/> </p>
                    {/* <p className="vip-icon">{t(`vip.vip-${this.props.currentUser.user_group_level}`)}</p> */}
                  </div>
                  <p className="us-join">{t("profile-sidebar.joining-r77-for")} {calculateRegisterDays} {t("datepicker.day")}</p>
                </div>
              </div>

              {/* {this.state.activeBusinessAcc === true && (
                <div className="ps-upgrade-acc mobile">
                  <div className={`upgrade-acc-container ${businessAccountStatus === true ? "active" : ""}`}
                    onClick={businessAccountStatus === true ? this.showCorporateInfoHandler : this.toggleBusinessAccModal}
                  >
                    <div className="upgrade-acc-icon">
                      <Icon icon="material-symbols:business-center" />
                    </div>
                    <p>{t("profile.business-acc")}</p>
                  </div>
                </div>
              )} */}

            </div>


            <div className="pi-m-amount">
              <div className="amount">
                <p>{this.props.currentUser.currency}</p>
                <p className="number">{new Intl.NumberFormat("en-US", num_format).format((topProfileData && topProfileData.topProfile && topProfileData.topProfile.balance) || 0.00)}</p>
                <div className="coin-earning"> 
                  <div className="coin">
                    <img src={mbIcon["coin.svg"]} alt="coin" />
                    <div>
                      <p>{t("profile-sidebar.r77-coin")}</p>
                      <p className="number">{Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((topProfileData != null && topProfileData.topProfile.coin) || 0)}</p>
                      <span className="my-learn-more" onClick={this.toggleCoinModal}>{t("global.learn-more")}</span>
                    </div>
                  </div>
                  <div className="earning">
                    <p>{t("profile-sidebar.today-earning")}</p>
                    <p className="number">+ {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format((topProfileData != null && topProfileData.topProfile.today_coin) || 0)}</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="pi-deposit-withdrawal">
              <NavLink
                className="btn btn-white hm-5"
                to={{ pathname: "/deposit" }}
              >
                <img src={icons["deposit-ico.webp"]} alt="deposit-ico" />
                <p>{t("profile-sidebar.deposit")}</p>
              </NavLink>
              <NavLink
                className="btn btn-white hm-5"
                to={{ pathname: "/withdrawal" }}
              >
                <img src={icons["withdrawal-ico.webp"]} alt="withdrawal-ico" />
                <p>{t("profile-sidebar.withdraw")}</p>
              </NavLink>
            </div>
            <div className="pi-selection">
              <div className="row">
                <div className="col-3">
                  <NavLink to={{ pathname: "/my-wallet" }}>
                    <img src={mbIcon["wallet-icon.svg"]} alt="wallet" />
                    <p>{t("profile-sidebar.my-wallet")}</p>
                  </NavLink>
                </div>
                <div className="col-3">
                  <NavLink to={{ pathname: "/transaction-history" }}>
                    <img
                      src={mbIcon["transaction-icon.svg"]}
                      alt="transaction"
                    />
                    <p>{t("profile-sidebar.transaction-history")}</p>
                  </NavLink>
                </div>
                <div className="col-3">
                  <NavLink to={{ pathname: "/betting-history" }}>
                    <img
                      src={mbIcon["betting-icon.svg"]}
                      alt="betting history"
                    />
                    <p>{t("profile-sidebar.betting-history")}</p>
                  </NavLink>
                </div>
                <div className="col-3">
                  <NavLink to={{ pathname: "/vip-exclusive" }}>
                    <img src={mbIcon["vip-icon.svg"]} alt="vip" />
                    <p>{t("profile-sidebar.vip-exclusive")}</p>
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div className="pi-m-option">
            {/* <ul className="pi-mall-redemption">
              <li>
                <NavLink className="nav-link" to={{ pathname: "/x33mall" }}>
                  <img className="option-img" src={mbIcon["mall-icon.svg"]} alt="mall" />
                  <p>{t("profile-sidebar.x33-mall")}</p>
                </NavLink>
              </li>
              <li>
                <NavLink className="nav-link" to={{ pathname: "/redemption-history" }}>
                  <img className="option-img" src={mbIcon["redemption-icon.svg"]} alt="redemption" />
                  <p>{t("profile-sidebar.redemption-history")}</p>
                </NavLink>
              </li>
            </ul> */}
            <ul className="pi-m-list">
              <li>
                <NavLink to={{ pathname: "/bonus" }}>
                  <img className="option-img" src={mbIcon["bonus.svg"]} alt="bonus" />
                  <p>{t("profile-sidebar.bonus")}</p>
                  {/* <div className="badge">8</div> */}
                  <Icon icon="uil:angle-right" />
                </NavLink>
              </li>
              <li>
                <NavLink to={{ pathname: "/messages" }}>
                  <img className="option-img" src={mbIcon["message.svg"]} alt="message" />
                  <p>{t("profile-sidebar.messaging")}</p>
                  <div className="badge">
                    {(() => {
                      if (!this.state.msgLoading) {
                        if (this.state.unreadMsgCnt > 0) {
                          return this.state.unreadMsgCnt;
                        }
                        else {
                          return null;
                        }
                      }
                    })()}
                  </div>
                  <Icon icon="uil:angle-right" />
                </NavLink>
              </li>
              <li>
                <NavLink to={{ pathname: "/referral" }}>
                  <img className="option-img" src={mbIcon["referral.svg"]} alt="referral" />
                  <p>{t("profile-sidebar.referral")}</p>
                  <Icon icon="uil:angle-right" />
                </NavLink>
              </li>
              {/* <li>
                <NavLink to={{ pathname: "/feedback" }}>
                  <img className="option-img" src={mbIcon["feedback.svg"]} alt="feedback" />
                  <p>Feedback</p>
                  <Icon icon="uil:angle-right" />
                </NavLink>
              </li> */}
            </ul>
          </div>
        </section>
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser.data,
    unreadMessagesCnt: state.inbox.count_unread_inbox_alert_data,
    topProfileData: state.profile.top_profile_data,
    get_business_account_result: state.profile.get_business_account_result,
    system_setting: state.setting.system_setting
  }
}

const mapDispatchToProps = {
  getCountUnreadInboxAlert,
  getUserData,
  getTopProfile,
  getBusinessAccount,
  getSystemSetting
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileProfile));
