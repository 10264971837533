import * as actionType from './types';
import axios from '../../../common/config/axios';

export const claim = (props) => (dispatch) =>
{
  
    axios.post('christmas/claim', props)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.CLAIM,
            value: response.data
        });
    })
    .catch(err => 
    {
      console.log(err);
    });
};

export const mobileAppsClaim = (data) => dispatch => {
    axios.post('mobile_apps_promo/claim', data).then( (response) => {
      dispatch ({
          type: actionType.MOBILE_APPS_PROMO_CLAIM,
          value: response.data
      })
    }).catch (err => {
      console.log(err);
    })
  };