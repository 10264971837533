import React, { Component } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { isDesktop } from "react-device-detect";
import { withNamespaces } from "react-i18next";

import MobileContactUs from "./MobileContactUs";
class ContactUs extends Component {
  ContactUsType = (props) => {
    return (
      <div className="col-6">
        <a href={props.link} onClick={props.onClick} target="_blank" rel="noreferrer">
          <span className="card-shadow">
            <img src={props.img["contact-bg.png"]} alt="contact background" />
          </span>
          <div className="contact-content-container">
            <img
              className="contact-icon"
              src={props.icon[props.iconImg]}
              alt="contact icon"
            />
            <img
              className="contact-icon-hover"
              src={props.icon[props.iconHover]}
              alt="contact icon"
            />
            <div className="contact-content">
              <h4>{props.title}</h4>
              {props.content}
            </div>
          </div>
        </a>
      </div>
    );
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const icon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/)
    );
    const img = this.imageImport(
      require.context(
        "../../assets/images/contact-us",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const customerImg = this.imageImport(
      require.context(
        "../../assets/images/about-us",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    return (
      <Auxiliary>
        {isDesktop ? (
          <section
            className="contact-us-section"
            // style={{ backgroundImage: `url(${img["contact-us-bg.webp"]}` }}
          >
            <div className="x-container">
              <div className="section-header">
                <h2 className="no-banner">{t("contact-us.contact-us")}</h2>
                <p className="w-75">
                  {t("contact-us.contact-us-details")}
                  <br /><br/>
                  {t("contact-us.contact-us-details-2")}
                </p>
              </div>
              <div className="row">
                <div className="col-6 position-relative">
                  <LazyLoadImage
                    className="contact-us-img"
                    src={customerImg["customer-img.webp"]}
                    alt="customer-img"
                    effect="blur"
                  />
                </div>
                <div className="col-6">
                  <div className="contact-us-container">
                    <div className="row g-4">
                      <this.ContactUsType
                        onClick={window['liveChatButton']}
                        icon={icon}
                        img={img}
                        iconImg="live-chat.svg"
                        iconHover="live-chat-blue.svg"
                        title={t("contact-us.live-chat")}
                        content={
                          <p>
                            {t("contact-us.live-chat-details")}
                          </p>
                        }
                      />
                      <this.ContactUsType
                        link={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_MERCHANT_WHATSAPP.replace(/[+ ]/g, '')}&text=Welcome%20to%20${process.env.REACT_APP_WEBSITE_TITLE}%20%EF%BC%81`}
                        icon={icon}
                        img={img}
                        iconImg="whatsapp.svg"
                        iconHover="whatsapp-blue.svg"
                        title={t("contact-us.whatsapp")}
                        content={
                          <p>
                            {t("contact-us.whatsapp-details")} <br /> {process.env.REACT_APP_MERCHANT_WHATSAPP}
                          </p>
                        }
                      />
                      <this.ContactUsType
                        link={`https://t.me/${process.env.REACT_APP_MERCHANT_TELEGRAM_BOT_SUPPORT}`}
                        icon={icon}
                        img={img}
                        iconImg="telegram.svg"
                        iconHover="telegram-blue.svg"
                        title={t("contact-us.telegram")}
                        content={
                          <p>
                            {t("contact-us.telegram-details")} <br /> {process.env.REACT_APP_MERCHANT_TELEGRAM_BOT}
                          </p>
                        }
                      />
                      <this.ContactUsType
                        link={`mailto:${process.env.REACT_APP_MERCHANT_EMAIL}`}
                        icon={icon}
                        img={img}
                        iconImg="email.svg"
                        iconHover="email-blue.svg"
                        title={t("contact-us.email")}
                        content={
                          <p>
                            {t("contact-us.email-details")}<br />{" "}
                            {process.env.REACT_APP_MERCHANT_EMAIL}
                          </p>
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <MobileContactUs />
        )}
      </Auxiliary>
    );
  }
}

export default (withNamespaces("translation")(ContactUs));
