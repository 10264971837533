import React, { Component } from 'react'
import { connect } from "react-redux";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import SideBar from '../../components/SideBar/SideBar';
import { withNamespaces } from "react-i18next";
export class MobileCertificate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activePage: 1,
    };
  }

  //certificate content component START
  certificateContent = (props) => {
    let imageLanguage = '';
    switch (this.props.currentUser.data.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //   imageLanguage="-ms" 
      //   break;
      default:
        imageLanguage = ""
        break;
    }
    return (
      <div className="position-relative p-3">
        <span className="card-shadow">
          <img className="w-100 h-auto" src={props.img[`license-bg-mobile${imageLanguage}.webp`]} alt="license background" />
        </span>
        <div className="cert-img">
          <LazyLoadImage src={props.img[props.cert]} alt="cert" key={props.img[props.cert]} effect="blur" />
        </div>
        <div className="cert-content-container">
          {props.header}
          {props.content}
        </div>
      </div>
    );
  };
  //certificate content component END

  updateActiveCert = (num) => {

    this.setState({
      activePage: num,
      triggerAnimation: true,
    });

  };


  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    // const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../assets/images/certificate",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <Auxiliary>

        <section className='certificate-m-section'>
          <SideBar />
          <div className="cert-page-btn mt-3">
            <ul>
              <li
                className={`cert-btn ${this.state.activePage === 1 ? "active" : ""
                  }`}
                onClick={() => this.updateActiveCert(1)}
              >
                PAGCOG
              </li>
              <li
                className={`cert-btn ${this.state.activePage === 2 ? "active" : ""
                  }`}
                onClick={() => this.updateActiveCert(2)}
              >
                Isle of Man Government
              </li>
              <li
                className={`cert-btn ${this.state.activePage === 3 ? "active" : ""
                  }`}
                onClick={() => this.updateActiveCert(3)}
              >
                First Cagayan
              </li>
            </ul>
          </div>

          {this.state.activePage === 1 ? (
            <this.certificateContent
              img={img}
              cert={"pagcor-cert.webp"}
              header={
                <h1>
                  PAGCOR <br /> Gaming License
                </h1>
              }
              content={
                <p>
                  The government has provided X22 with a license to host and
                  facilitate real money games. We have various licenses,
                  including the PAGCOR license, Isle of Man Gaming License,
                  and the First Cagayan License.
                  <br />
                  <br />
                  The government regulates us to ensure that we provide a
                  conducive and fair environment for all our players. The
                  online gaming license pertains to our use of the internet to
                  host online gambling games that players have to pay real
                  money to participate in.
                  <br />
                  <br />
                  All players on the R77 platform can be assured that they are
                  dealing with a licensed organization, and all the activities
                  that we take part in are entirely legal. As such, they will
                  not get into trouble for playing on any of our online casino
                  games as everything is done clearly and transparently. We
                  also ensure that all our games are per the government rules
                  and regulations.
                  <br />
                  <br />
                  R77 is a registered Trade Mark for an online casino which is
                  based in Philippines. All our digital systems, including
                  software logs for our back-end applications, can be availed
                  to third-party auditors. This will ensure that all bets and
                  financial transactions on our betting website are legal and
                  all the terms of the licenses are being met.
                  <br />
                  <br />
                  Authorities can also audit our servers at any moment to
                  ensure that we are using lawful means and methods in all our
                  games, as well as giving all players the same experience on
                  the platform. Being an online service, most of our work is
                  digital, but it is also open to audits and inspections to
                  ensure that we work transparently to offer honest services.
                  <br />
                  <br />
                </p>
              }
            />
          ) : this.state.activePage === 2 ? (
            <this.certificateContent
              img={img}
              cert={"isle-cert.webp"}
              header={
                <h1>
                  Isle of Man Government <br /> Gaming License
                </h1>
              }
              content={
                <p>
                  The government has provided X22 with a license to host and
                  facilitate real money games. We have various licenses,
                  including the PAGCOR license, Isle of Man Gaming License,
                  and the First Cagayan License. <br />
                  <br />
                  The government regulates us to ensure that we provide a
                  conducive and fair environment for all our players. The
                  online gaming license pertains to our use of the internet to
                  host online gambling games that players have to pay real
                  money to participate in.
                  <br />
                  <br />
                  All players on the R77 platform can be assured that they are
                  dealing with a licensed organization, and all the activities
                  that we take part in are entirely legal. As such, they will
                  not get into trouble for playing on any of our online casino
                  games as everything is done clearly and transparently. We
                  also ensure that all our games are per the government rules
                  and regulations.
                  <br />
                  <br />
                  R77 is a registered Trade Mark for an online casino which is
                  based in Philippines. All our digital systems, including
                  software logs for our back-end applications, can be availed
                  to third-party auditors. This will ensure that all bets and
                  financial transactions on our betting website are legal and
                  all the terms of the licenses are being met.
                  <br />
                  <br />
                  Authorities can also audit our servers at any moment to
                  ensure that we are using lawful means and methods in all our
                  games, as well as giving all players the same experience on
                  the platform. Being an online service, most of our work is
                  digital, but it is also open to audits and inspections to
                  ensure that we work transparently to offer honest services.
                  <br />
                  <br />
                </p>
              }
            />
          ) : this.state.activePage === 3 ? (
            <this.certificateContent
              img={img}
              cert={"cagayan-cert.webp"}
              header={
                <h1>
                  First Cagayan <br /> Gaming License
                </h1>
              }
              content={
                <p>
                  The government has provided X22 with a license to host and
                  facilitate real money games. We have various licenses,
                  including the PAGCOR license, Isle of Man Gaming License,
                  and the First Cagayan License.
                  <br />
                  <br />
                  The government regulates us to ensure that we provide a
                  conducive and fair environment for all our players. The
                  online gaming license pertains to our use of the internet to
                  host online gambling games that players have to pay real
                  money to participate in.
                  <br />
                  <br />
                  All players on the R77 platform can be assured that they are
                  dealing with a licensed organization, and all the activities
                  that we take part in are entirely legal. As such, they will
                  not get into trouble for playing on any of our online casino
                  games as everything is done clearly and transparently. We
                  also ensure that all our games are per the government rules
                  and regulations.
                  <br />
                  <br />
                  R77 is a registered Trade Mark for an online casino which is
                  based in Philippines. All our digital systems, including
                  software logs for our back-end applications, can be availed
                  to third-party auditors. This will ensure that all bets and
                  financial transactions on our betting website are legal and
                  all the terms of the licenses are being met.
                  <br />
                  <br />
                  Authorities can also audit our servers at any moment to
                  ensure that we are using lawful means and methods in all our
                  games, as well as giving all players the same experience on
                  the platform. Being an online service, most of our work is
                  digital, but it is also open to audits and inspections to
                  ensure that we work transparently to offer honest services.
                </p>
              }
            />
          ) : (
            <div></div>
          )}
        </section>
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    currentUser: state.currentUser
  }
};

export default connect(mapStateToProps)(withNamespaces("translation")(MobileCertificate));