import React, { Component } from 'react'
import { withNamespaces } from 'react-i18next';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Miscellaneous
import { Icon } from '@iconify/react';

//Components
import Deposit from "./Deposit";
import Withdrawal from "./Withdrawal";
import Rebate from "./Rebate";
import Promotion from "./Promotion";
import Bonus from "./Bonus";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import Button from '../../../components/Button/Button';
import DateScroller from '../../../components/MobileAsset/DateScroller/DateScroller';

class MobileTransactionHistory extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            filterShow: '',
        };
    }

    componentDidMount() {}

    showTypeHandler = () => 
    {
        this.setState({
            filterShow: 'type'
        })
    }

    showDateHandler = () => 
    {
        this.setState({
            filterShow: 'date'
        })
    }

    render() 
    {
        const { t } = this.props;
        return (
            <Auxiliary>
                <DateScroller
                    onChangeDateHandler={this.props.onChangeDateHandler}
                    toggleShowDateSelect={this.props.toggleShowDateSelect}
                    {...this.state}
                    {...this.props}
                />
                
                <section className="pf-m-dashboard">
                    <div className={`filters-m-col ${this.state.filterShow ? 'show' : ''}`}>
                        <MobileSubHeader
                            // className="bg-white"
                            returnPage="/me"
                            header={t('profile-sidebar.transaction-history')}
                            style={{ justifyContent: "unset" }}
                        />
                        <div className="filters-m-panel">
                            <div className="filter-m-tp-col">
                                <div className="filter-m-cat">
                                    <label className="filter-m-label">{t('history.type')}:</label>
                                    <button className={`btn btn-m-filter ${this.state.filterShow === 'type' && 'active'}`} type="button" onClick={this.showTypeHandler}>
                                        <p>{t(`history.${this.props.historyTab}`)}</p>
                                        <Icon icon="dashicons:arrow-down" />
                                    </button>
                                </div>
                                <div className="filter-m-cat">
                                    <label className="filter-m-label">{t('history.date')}:</label>
                                    <button className={`btn btn-m-filter date ${this.state.filterShow === 'date' && 'active'}`} type="button" onClick={this.showDateHandler}>
                                        <p>
                                        { this.props.start_date }
                                        <br/>
                                        { this.props.end_date }
                                        </p>
                                        <Icon icon="dashicons:arrow-down" />
                                    </button>
                                </div>
                            </div>
                            <div className="filter-m-btm-col">
                                <div className={`filters-m-group ${this.state.filterShow === 'type' ? 'd-block' : 'd-none'}`}>
                                {this.props.transactionTypes &&
                                    this.props.transactionTypes.map((item, index) => (
                                    <div className="form-check form-check-inline filter" key={index}>
                                        <input
                                        className="btn-check"
                                        type="radio"
                                        name="filterType"
                                        id={item.id}
                                        value={item.value}
                                        defaultChecked={index === this.props.defaultTab}
                                        checked={item.value === this.props.historyTab}
                                        />
                                        <label className="btn form-check-label" htmlFor={item.id} onClick={() => this.props.tabShow(item.value)}>
                                        {t(`history.${item.label}`)}
                                        </label>
                                    </div>
                                    ))}
                                </div>
                                <div className={`filters-m-date-group ${this.state.filterShow === 'date' ? 'd-block' : 'd-none'}`}>
                                <div className="d-flex align-items-center flex-wrap">
                                    {this.props.filterDate &&
                                    this.props.filterDate.map((item, index) => (
                                        <div className="form-check form-check-inline filter" key={index}>
                                        <input
                                            className="btn-check"
                                            type="radio"
                                            name="filterDate"
                                            id={item.id}
                                            value={item.value}
                                            defaultChecked={index === 0}
                                            checked={index === this.props.currentFilterDate}
                                            onClick={() => this.props.onChangeFilterDateHandler(index)}
                                        />
                                        <label className="btn form-check-label" htmlFor={item.id} onClick={
                                            () => [
                                            this.props.filterDayRange(item.value)
                                            ]
                                        }>
                                            {t(`history.${item.label}`)}
                                        </label>
                                        </div>
                                    ))}
                                </div>
                                <div className="filter-m-date">
                                    <label className="filter-m-label">{t("history.start-date")}:</label>
                                    <button className="btn btn-m-filter-date" type="button" onClick={(e) => this.props.toggleShowDateSelect(e, 'startDate')}>
                                    <p>{this.props.start_date}</p>
                                    <Icon icon="uil:angle-right" />
                                    </button>
                                </div>
                                <div className="filter-m-date">
                                    <label className="filter-m-label">{t("history.end-date")}:</label>
                                    <button className="btn btn-m-filter-date" type="button" onClick={(e) => this.props.toggleShowDateSelect(e, 'endDate')}>
                                    <p>{this.props.end_date}</p>
                                    <Icon icon="uil:angle-right" />
                                    </button>
                                </div>
                                </div>
                                {this.state.filterShow &&
                                <>
                                    <Button
                                        typeButton="button"
                                        classValue="btn btn-gradient-blue mt-3"
                                        idValue="search"
                                        buttonName={t('global.check')}
                                        clicked={() => {this.props.searchHandler(this.props.historyTab); this.setState({ filterShow: ''});}}
                                        disabled={this.props.disableSearchButton}
                                    />       
                                    <Button
                                     typeButton='button'
                                     classValue='btn btn-success w-100'
                                     idValue='reset'
                                     buttonName={t('global.reset')}
                                     clicked={this.props.onResetHandler}
                                    />                         
                                </>

                                }
                            </div>
                        </div>
                    </div>
                <div className="overlay" onClick={() => this.setState({ filterShow: '' })} />
                <div className="pd-m-card-col">
                    {this.props.historyTab === 'deposit' ? (
                    <Deposit
                        tabName='deposit'
                        data={this.props.deposit}
                        datatablesChangeHandler={(val, tab, act) => this.props.datatablesChangeHandler(val, tab, act)}
                        getFilterArray={() => this.props.getFilterArray(this.props.historyTab)}
                        funcGetList={() => this.props.funcGetList(this.props.listingArr)}
                        cancelHandler={(event, id) => this.props.cancelHandler(event, id)}
                        applyPromoHandler={(event, tid, pid) => this.props.applyPromoHandler(event, tid, pid)}
                        promoModalHandler={(event, tid) => this.props.promoModalHandler(event, tid)}
                        openModal={this.props.openModal}
                        onChangeHandler={(event) => this.props.onChangeHandler(event)}
                        onPromoInputChangeHandler={(event, data) => this.props.onPromoInputChangeHandler(event, data)}
                        {...this.state}
                        {...this.props}
                    />
                    ) : this.props.historyTab === 'withdrawal' ? (
                    <Withdrawal
                        tabName='withdrawal'
                        data={this.props.withdrawal}
                        datatablesChangeHandler={(val, tab, act) => this.props.datatablesChangeHandler(val, tab, act)}
                        getFilterArray={() => this.props.getFilterArray(this.props.historyTab)}
                        funcGetList={() => this.props.funcGetList(this.props.listingArr)}
                        cancelHandler={(event, id) => this.props.cancelHandler(event, id)}
                        applyPromoHandler={(event, tid, pid) => this.props.applyPromoHandler(event, tid, pid)}
                        promoModalHandler={(event, tid) => this.props.promoModalHandler(event, tid)}
                        openModal={this.props.openModal}
                        onChangeHandler={(event) => this.props.onChangeHandler(event)}
                        onPromoInputChangeHandler={(event, data) => this.props.onPromoInputChangeHandler(event, data)}
                        {...this.state}
                        {...this.props}
                    />
                    ) : this.props.historyTab === 'rebate' ? (
                    <Rebate
                        tabName='rebate'
                        data={this.props.rebate}
                        datatablesChangeHandler={(val, tab, act) => this.props.datatablesChangeHandler(val, tab, act)}
                        getFilterArray={() => this.props.getFilterArray(this.props.historyTab)}
                        funcGetList={() => this.props.funcGetList(this.props.listingArr)}
                        {...this.props}
                    />
                    ) : this.props.historyTab === 'promotion' ? (
                    <Promotion
                        tabName='promotion'
                        data={this.props.promotion}
                        datatablesChangeHandler={(val, tab, act) => this.props.datatablesChangeHandler(val, tab, act)}
                        getFilterArray={() => this.props.getFilterArray(this.props.historyTab)}
                        funcGetList={() => this.props.funcGetList(this.props.listingArr)}
                        {...this.props}
                    />
                    ) : this.props.historyTab === 'bonus' ? (
                    <Bonus
                        tabName='bonus'
                        data={this.props.bonus}
                        datatablesChangeHandler={(val, tab, act) => this.props.datatablesChangeHandler(val, tab, act)}
                        getFilterArray={() => this.props.getFilterArray(this.props.historyTab)}
                        funcGetList={() => this.props.funcGetList(this.props.listingArr)}
                        {...this.props}
                    />
                    ) : (
                    <></>
                    )}
                </div>
                </section>
            </Auxiliary>
        )
    }
}

export default withNamespaces('translation')(MobileTransactionHistory);
