import { Icon } from '@iconify/react/dist/iconify.js';
import React, { Component } from 'react'
// import { Icon } from "@iconify/react";
import { withNamespaces } from 'react-i18next';
export class MobileSelectRadio extends Component {
  constructor() {
    super();
    this.state = {
      event: null,
      checked: {},
      optionSelected: []
    };
  }

  onChangeHandler = (event) => 
  {
      // console.log(event);
      this.setState({
        event
      });
  }

  // Multi Select Handler
  onCheckedHandler = index => {
      let selectedOptions = this.state.optionSelected;
      if (!!(selectedOptions) && selectedOptions.length > 0) {
          let newAnswer = true;
          selectedOptions.forEach((val,selectedIdx) => {
              if (val.value === this.props.options[index].value) {
                  selectedOptions = selectedOptions.filter((item, idx) => selectedIdx !== idx);
                  newAnswer = false;
              }
          });

          if (newAnswer === true) {
              selectedOptions.push({value:this.props.options[index].value});
          }
      } else {
          selectedOptions.push({value:this.props.options[index].value});
      }
      
      this.setState(previousState => ({
          checked: {
              ...previousState.checked,
              [index]: !previousState.checked[index]
          },
          optionSelected: selectedOptions
      }));
  }


  onClickHandler = (val) =>
  {
    // console.log(val);
    var radios = null;
    var infoRadio = null;
    var x = 0;

    if(val === 'ok')
    {
      if(this.state.event !== null)
      {
        this.props.onChange(this.state.event);

        radios = document.getElementsByName("infoCentre");

        for(x = 0; x < radios.length; x++){
          radios[x].checked =false;
        }
      }
      else if (!!(this.state.optionSelected) && window.location.pathname === "/fifa-qna") 
      {
          this.props.onChange(this.state.optionSelected);

          radios = document.getElementsByName("infoCentre");

          for(x = 0; x < radios.length; x++){
            radios[x].checked =false;
          }
      }
    }
    else
    {
      radios = document.getElementsByName("language");
      infoRadio = document.getElementsByName("infoCentre")

      for(x = 0; x < radios.length; x++){
        radios[x].checked =false;
      }

      for(x = 0; x < infoRadio.length; x++){
        infoRadio[x].checked =false;
      }

      this.setState({
        event: null,
      });
    }
    this.props.toggleHandler();

  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const img = this.imageImport(
      require.context(
        "../../../assets/images/icons/mobile",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );

    const {checked} = this.state;
    const totalChecked = Object.keys(checked).filter(key => checked[key]).length;
    const disabled = totalChecked > (!!(this.props.maxSelect) ? (this.props.maxSelect-1) : 0); // Multi select limit set here
    // console.log(this.props.options,this.props.value);
    return (
      <>
        <div className={`mobile-asset-section ${this.props.showSelectRadio && "open"}`}>
          <div className="mobile-asset-content">
            <div className="mobile-asset-header" id="date-scroller">
              <button
                type="button"
                className="btn btn-cancel"
                // onClick={this.props.toggleHandler}
                onClick={() => this.onClickHandler('cancel')}
              >
                {/* {t('global.cancel')} */}
                <Icon icon="uil:angle-left" alt="arrow-left"/>
              </button>
              <p>{this.props.header}</p>
              <button 
                type="button" 
                className="btn btn-ok" 
                // onClick={this.props.toggleHandler}
                onClick={() => this.onClickHandler('ok')}
              >
                {t('global.ok')}
              </button>
            </div>
            <div className="mobile-asset-body">
              <div className='mobile-radio-select'>
                <ul className="list-group">
                  {/* {
                    this.props.options && this.props.options.map((item, index) => 
                    {
                      // return (<li className="form-check" key={index}>
                      //   <label className="form-check-label" htmlFor={`item${index}`}>
                      //     {item.icon && <img src={img[`${item.icon}.svg`]} alt="radio pic" />}
                      //     {item.label}
                      //   </label>
                      //   <input
                      //     className="form-check-input"
                      //     type="radio"
                      //     id={this.props.id}
                      //     name={this.props.name}
                      //     value={item.value}
                      //     onChange={() => this.onChange(item)}
                      //     required={this.props.required}
                      //     // checked={(this.props.requireChecked) ? ((index === this.props.value) ? true : false) : null}
                      //   />
                      // </li>)
                      )
                    }
                  } */}
                  {this.props.options && this.props.options.map((item, index) => {
                      // if((index !== this.props.value) && (item.value && item.value.toLowerCase() !== this.props.value.toLowerCase()))
                      let existItem = true;
                      
                      if(item.value && !!(this.props.value)){
                        if(item.value.toLowerCase() !== this.props.value.toLowerCase())
                        {
                          existItem = false;
                        }
                      }
                      else
                      {
                        if(index !== this.props.value)
                        {
                          existItem = false;
                        }
                      }

                      if(!existItem)
                      {
                        return (
                          <>
                          {
                            window.location.pathname === "/fifa-qna" ?  <>
                             <li className="form-check" key={index}>
                             <input
                              className="form-check-input fifa-select"
                              type="checkbox"
                              id={`item${index}`}
                              name={this.props.name}
                              value={item.value}
                              onChange={() => this.onCheckedHandler(index)}
                              checked={checked[index] || false}
                              disabled={!checked[index] && disabled}
                              required={this.props.required}
                            />
                            <label className="form-check-label" htmlFor={`item${index}`}>
                              {item.icon && <img src={img[`${item.icon}.svg`]} alt="radio pic" />}
                              {t(`world-cup.team.${item.label.toLowerCase()}`)}
                            </label>
                          </li>
                          </>
                               
                             : <>
                             <li className="form-check" key={index}>
                            <label className="form-check-label" htmlFor={`item${index}`}>
                              {item.icon && <img src={img[`${item.icon}.svg`]} alt="radio pic" />}
                              {item.label}
                            </label>
                            <input
                              className="form-check-input"
                              type="radio"
                              id={`item${index}`}
                              name={this.props.name}
                              value={item.value}
                              onChange={() => this.onChangeHandler(item)}
                              required={this.props.required}
                            />
                          </li>
                          </>
                        }
                         </>
                        );
                      }
                      else
                      {
                        return null;
                      }
                    }
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="overlay" id="menu-overlay" />
      </>
    )
  }
}

export default withNamespaces('translation')(MobileSelectRadio);