import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import { Icon } from "@iconify/react";
import { connect } from "react-redux";
//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { showAnnouncement } from "../../store/ducks/announcements/actions";
import { getUserData } from "../../store/ducks/currentUser/actions";
import moment from "moment";
import { isDesktop } from "react-device-detect";
class Announcement extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            loading: true,
        };
    }

    fetchData() {
        const data = {};
        // data.country_id = this.props.currentUser.country_id || 1;
        // data.member_group_id = this.props.currentUser.member_group_id || 1;
        // data.lang_id = this.props.currentUser.language_id || 1;
        this.props.showAnnouncement(data);
    }

    // fetchLoadingCountryData(country_code, country_id, language_code, language_id, time_zone, member_group_id) {
    //     const data = {};
    //     data.country_code = country_code;
    //     data.country_id = country_id;
    //     data.language_code = language_code;
    //     data.language_id = language_id;
    //     data.time_zone = time_zone;
    //     data.member_group_id = member_group_id;

    //     this.props.getUserData(data);

    //     const showAnnouncementData = {};
    //     showAnnouncementData.country_id = country_id;
    //     showAnnouncementData.member_group_id = member_group_id;
    //     showAnnouncementData.lang_id = language_id;

    //     this.props.showAnnouncement(showAnnouncementData);
    // }

    componentDidMount() {
        // if (!localStorage.getItem("changeCountry") && !localStorage.getItem("accessToken")) {
        //     let domains = domainMapping;
        //     let domainContent = null;
        //     if(domains.my.key.includes(window.location.host)){
        //         domainContent = domains.my.data;
        //     }
        //     else if(domains.th.key.includes(window.location.host)){
        //         domainContent = domains.th.data;
        //     }
        //     else if(domains.id.key.includes(window.location.host)){
        //         domainContent = domains.id.data;
        //     }
        //     else if(domains.vn.key.includes(window.location.host)){
        //         domainContent = domains.vn.data;
        //     }
        //     else if(domains.us.key.includes(window.location.host)){
        //         domainContent = domains.us.data;
        //     }

        //     if(domainContent){                
        //         this.fetchLoadingCountryData(domainContent[0],domainContent[1],domainContent[2],domainContent[3],domainContent[4],domainContent[5]);
        //     }
        //     else{
        //         // default to my
        //         let language_code = (this.props.currentUser && this.props.currentUser.language_code) ? this.props.currentUser.language_code : 'en';
        //         let language_id = (this.props.currentUser && this.props.currentUser.language_id) ? this.props.currentUser.language_id : 1;
        //         this.fetchLoadingCountryData('MY', 1, language_code, language_id, 'Asia/Kuala_Lumpur', 1);
        //     }
        // } else {
            this.fetchData();
        // }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log("[Announcement] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
        if (nextProps.showAnnouncementData !== this.props.showAnnouncementData) {
            if (nextProps.showAnnouncementData.data.responseCode === 200) {
                let announcementData = nextProps.showAnnouncementData.data.data.announcement;

                if (announcementData.length !== 0) {
                    this.setState({
                        data: nextProps.showAnnouncementData.data.data.announcement,
                        loading: false,
                    });
                } else {
                    this.setState({
                        loading: false,
                    });
                }
            } else {
                return false;
            }
        }

        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;
        // const img = this.imageImport(
        //   require.context("../../assets/images", false, /\.(png|jpe?g|svg)$/)
        // );
        const icons = this.imageImport(require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg)$/));

        let announcement_modal_item = null;

        if (!this.state.loading) {
            if (this.state.data.length !== 0) {
                announcement_modal_item = this.state.data.map((item, idx) => {
                    return (
                        <li key={idx}>
                            <p>{item.message}</p>
                            <div className="ann-date">
                                <Icon icon="mdi:clock-time-four-outline" />
                                <span>
                                    {moment(item.startTime).format("MMM DD, YYYY")} {t("global.at")} {moment(item.startTime).format("LT")}
                                </span>
                            </div>
                        </li>
                    );
                });
            }
        }

        return (
            <Auxiliary>
                <section className="anm-section my-2">
                    {
                        isDesktop ? (
                            <div className="x-container-no-m">
                                <div className="marquee-col">
                                    <img src={icons["megaphone.svg"]} alt="r77-megaphone" />
                                    {!this.state.loading ? (
                                        <marquee>
                                            {this.state.data.length !== 0 &&
                                                this.state.data.map((item, idx) => {
                                                    return <span key={idx}>{item.message}</span>;
                                                })}
                                            {this.state.data.length === 0 && <span>{t("global.no-announcement")}</span>}
                                        </marquee>
                                    ) : (
                                        <marquee>
                                            <span>{t("global.no-announcement")}</span>
                                        </marquee>
                                    )}
                                    <button type="button" className="more-btn-blue" data-bs-toggle="modal" data-bs-target="#nofiticationModal">
                                        {t("profile.more")}
                                    </button>
                                </div>
                            </div>
                        ) : (
                            <div className="marquee-m-col" data-bs-toggle="modal" data-bs-target="#nofiticationModal">
                                <img className="megaphone" src={icons["megaphone.svg"]} alt="r77-megaphone" />
                                {/* <Icon className="megaphone" icon="ri:volume-up-fill"/> */}
                                {!this.state.loading ? (
                                    <marquee>
                                        {this.state.data.length !== 0 &&
                                            this.state.data.map((item, idx) => {
                                                return <span key={idx}>{item.message}</span>;
                                            })}
                                        {this.state.data.length === 0 && <span>{t("global.no-announcement")}</span>}
                                    </marquee>
                                ) : (
                                    <marquee>
                                        <span>{t("global.no-announcement")}</span>
                                    </marquee>
                                )}
                            </div>
                        )
                    }

                    <div className="modal global-modal fade" id="nofiticationModal" tabIndex="-1" aria-labelledby="notificationLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered gap-3">
                            <div className="modal-content">
                                <div className="modal-body">
                                    <h4>{t("message.announcement")}</h4>
                                    <ul>{announcement_modal_item}</ul>
                                </div>
                            </div>
                            <button type="button" className="btn btn-gradient-blue" data-bs-dismiss="modal">
                                {t("global.close")}
                            </button>
                        </div>
                    </div>
                </section>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { home, announcements, currentUser } = state;
    return {
        country_data: home.country_data,
        showAnnouncementData: announcements.show_announcement_data,
        currentUser: currentUser.data,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        showAnnouncement: (data) => dispatch(showAnnouncement(data)),
        getUserData: (data) => dispatch(getUserData(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Announcement));
