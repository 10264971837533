import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

//Miscellaneous

//Icons

class NoRecords extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const component = this.props.component;
    const pagePersonalInformation = component === "personal-information";
    const pageBankingDetails = component === "banking-details";
    const pageCryptoDetails = component === "crypto-details";
    const pageTransactionHistory = component === "transaction-history";
    const pageBettingHistory = component === "betting-history";
    const pageX33Mall = component === "x33-mall";
    const pageRedemptionHistory = component === "redemption-history";
    const pageReferral = component === "referral";
    const pageFeedback= component === "feedback";

    const img = this.imageImport(
      require.context(
        "../../assets/images/profile/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <div className="no-records">
        <img src={img["no-records.webp"]} alt="no-records" />
        <label>{t("norecord.norecords")}</label>
        {pagePersonalInformation ? (
          <>
            <p>{t("norecord.nobetting")}</p>
            <Link className="btn btn-gradient-blue" to={{ pathname: "/#" }}>
              <p>{t("norecord.deposit-now")}</p>
            </Link>
          </>
        ) : pageBankingDetails ? (
          <>
            <p>{t("norecord.nobank")}</p>
          </>
        )
        : pageCryptoDetails ? (
          <>
            <p>{t("norecord.nocrypto")}</p>
          </>
        ) : pageTransactionHistory ? (
          <>
            <p>{t("norecord.nobetting")}</p>
            <Link className="btn btn-gradient-blue" to={{ pathname: "/deposit" }}>
              <p>{t("norecord.deposit-now")}</p>
            </Link>
          </>
        ) : pageBettingHistory ? (
          <>
            <p>{t("norecord.nobetting")}</p>
            {/* <Link className="btn btn-gradient-blue" to={{ pathname: "/#" }}>
              <p>Betting Now</p>
            </Link> */}
          </>
        ) : pageX33Mall ? (
          <>
            <Link className="btn btn-gradient-blue" to={{ pathname: "/x33mall" }}>
              <p>{t("norecord.redeem-now")}</p>
            </Link>
          </>
        ) : pageRedemptionHistory ? (
          <>
            <p>{t("norecord.nobetting")}</p>
            <Link className="btn btn-gradient-blue" to={{ pathname: "/lucky-spin" }}>
              <p>{t("norecord.redeem-now")}</p>
            </Link>
          </>
        ) : pageReferral ? (
          <>
            <p>{t("referral.no-record")}</p>
            <button className="btn btn-gradient-blue" data-bs-toggle="modal" data-bs-target="#referralModal">
              <p>{t("referral.refer-now")}</p>
            </button>
          </>
        ) : pageFeedback ? (
          <>
            <p>{t("norecord.nofeedback")}</p>
            <Link className="btn btn-gradient-blue" to={{ pathname: "/feedback" }}>
              <p>{t("norecord.send-feedback")}</p>
            </Link>
          </>
        ) : (
          <></>
        )}
      </div>
    );
  }
}

export default withNamespaces("translation")(NoRecords);
