import { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import * as actions from "../../../store/ducks/sport/actions";
import Swal from "sweetalert2";
import moment from "moment-timezone";
import { isDesktop } from "react-device-detect";

class SportHeader extends Component{
    constructor(props){
        super(props);
        this.state = {
            vendorList: [],
            loading: true
        }
    }

    componentDidMount(){}

    shouldComponentUpdate(nextProps, nextState){
        // console.log("[shouldComponentUpdate]", nextProps, nextProps.rs);
        if(nextProps.vendor!==undefined && nextProps.vendor!==this.props.vendor){
            if(nextProps.vendor.responseCode===200 && nextProps.vendor.msgType==="success"){
                let vendorList = nextProps.vendor.data.vendor_list;
                let sportVendorList = [];
                if(vendorList.length>0){
                    vendorList.map((val,idx)=>{
                        if(val.game_type_slug==="sp"){
                            sportVendorList.push(val);
                        }
                        return null;
                    });
                }
                this.setState({
                    loading: false,
                    vendorList: sportVendorList
                });
            }
        }

        // still using?
        if(nextProps.rs!==undefined && nextProps.rs!==this.props.rs){
            if(nextProps.rs.responseCode===200 && nextProps.rs.msgType==="success"){
                if(nextProps.sport.actionFrom === "PageHeader"){
                    Swal.close();
                    if(nextProps.rs.data.hasOwnProperty("url")){
                        window.open(nextProps.rs.data.url);
                    }else if(nextProps.rs.data.hasOwnProperty("gameError")){
                        this.gameErrorResponse(nextProps.rs.data.gameError);
                    }
                }
            }
            else{
                const { t } = this.props;
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} (${nextProps.rs.message[0]}). ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                    
                });
            }
        }
        return true;
    }

    gameErrorResponse = (response) => {
        const { t } = this.props;

        switch (response.status) {
            case 1:
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    title:  t("global.reminder"),
                    icon: "error",
                    html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.kindly-refresh-the-page")}`,
                    showConfirmButton: true,
                    confirmButtonText: `${t("global.refresh")}`,
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
                break;
            case 2:
                const currentUserTimeZone = this.props.currentUser.data.time_zone;
                const startTime = moment(response.maintenance_start_time)
                    .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
                    .format("YYYY/MM/DD HH:mm:ss")
                    .toString();
                const endTime = moment(response.maintenance_end_time)
                    .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
                    .format("YYYY/MM/DD HH:mm:ss")
                    .toString();
                const timeZone = moment()
                    .tz(currentUserTimeZone === undefined ? "Asia/Kuala_Lumpur" : currentUserTimeZone)
                    .format("[(GMT] z[)]")
                    .toString();
                Swal.fire({
                    iconHtml: (!isDesktop) ?  '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
                    icon: "info",
                    titleText: `${t("global.under-maintenance")}`,
                    html: `${t("global.from")} <b>${startTime}</b> ${t("global.to")} <b>${endTime}</b> ${timeZone}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container reminder",
                        icon: "swal-reminder-icon", 
                    } : [],
                });
                break;
            case 3:
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} ${t("global.code")}: ${response.status} - ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
                break;
            default:
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    title:  t("global.failed"),
                    icon: "error",
                    html: `${t("global.error")} ${t("global.code")}: 500 - ${t("global.contact-administrator")}`,
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: (!isDesktop) ? {
                        container: "swal-mobile-container failed",
                        icon: "swal-failed-icon", 
                    } : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    }
                });
                break;
        }
    };

    accessGame = (game) => {
        if(!localStorage.getItem("accessToken")){
            const { t } = this.props;
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                icon: "info",
                title: t("global.sorry"),
                text: t("global.login-proceed"),
                confirmButtonText: t("global.ok"),
                customClass: (!isDesktop) ? {
                    container: 'swal-mobile-container sorry',
                    icon: 'swal-sorry-icon',
                } : [],
            });
        }else{
            localStorage.setItem("gameLauncher", 'sport|'+game);
            window.open('/game-launcher');
        }
    };

    render(){
        const { t } = this.props;
        let imgDropdown = this.props.imgDropdown;
        let sports = this.state.vendorList ? this.state.vendorList : [];
        // console.log("[render]", this.state.vendorList);
        return(
            <>
            {(!this.state.loading && sports) &&
                sports.map((item, index) => {
                    if(item.maintenance_status===0){
                        return (
                            <div className="col-3 position-relative" key={index} onClick={()=>this.accessGame(item.game_vendor_slug)} role="button">
                                <div className="dropdown-title">
                                    <img
                                        src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]}
                                        alt="Dropdwon Icon"
                                    />
                                    <p>{item.game_vendor_title}</p>
                                </div>
                                <div className="more-btn-blue mt-3">{t("page-header.enter")}</div>
                                <img
                                    className="dropdown-img"
                                    src={imgDropdown[`sp-${item.game_vendor_slug}-boy.webp`]}
                                    alt="Dropdown "
                                />
                            </div>
                        );
                    }
                    else{
                        return(
                            <div className="col-3 position-relative under-maintenance" key={index}>
                                <div className="dropdown-title">
                                    <img src={imgDropdown[`${item.game_vendor_slug}-dropdown.webp`]} alt="Dropdwon Icon" />
                                    <p>{item.game_vendor_title}</p>
                                </div>
                                <div className="small">{t("global.under-maintenance")}</div>
                                <img className="dropdown-img" src={imgDropdown[`sp-${item.game_vendor_slug}-boy.webp`]} alt="Dropdown" />
                            </div>
                        );
                    }
                })}
            </>
        )
    }
}

const mapStateToProps = state => {
    // console.log("[mapStateToProps]", state);
    return {
        vendor: state.general.vendor,
        sport: state.sport,
        currentUser: state.currentUser,
        rs: state.sport.rs
    }
};

const mapDispatchToProps = dispatch => {
    return {
        accessGame: (params, vendor) => dispatch(actions.accessGame(params, vendor))
    }
}

export default connect(mapStateToProps,mapDispatchToProps)(withNamespaces("translation")(SportHeader));