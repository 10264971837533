import React, { Component } from "react";
import { withRouter, Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { deviceType, deviceDetect } from "react-device-detect";
import axios from "axios";

// Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { postLogin, updateFingerPrint } from "../../store/ducks/auth/actions";
import { formValidation } from "../../hoc/Shared/utility";
import { getUserData } from "../../store/ducks/currentUser/actions";
import { redirectGoogle } from "../../store/ducks/profile/actions";
import { isDesktop } from "react-device-detect";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

// Assets
import BackVideo from "../../assets/images/videos/login-page-video-b.mp4";
import Logo from "../../assets/images/brand-logo.png";
// import ChristmasLogo from "../../assets/images/brand-logo-christmas.png";
// import EventLogo from "../../assets/images/brand-logo-raya.png";

// Components
import StringInput from "../../components/Input/StringInput/StringInput";
import PasswordInput from "../../components/Input/PasswordInput/PasswordInput";
import ValidationError from "../../components/ValidationError/ValidationError";
// import Button from "../../components/Button/Button";
import LoadingPage from "../../components/LoadingPage/LoadingPage";
import MobileLogin from "./MobileLogin";
import domain from "../../common/data/domain.json";
import * as CryptoJS from "crypto-js";
import moment from "moment";
import PageHeader from "../../components/PageHeader/PageHeader";

let dynamicCountry = "malaysia";
if (domain.id.key.includes(window.location.host)) {
  dynamicCountry = "indonesia";
}

const initialFormInputState = {
  /* form input value */
  username: "",
  password: "",
  showPwd: false,
};
class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formFilter: initialFormInputState,
      showPassword: false,
      isLoading: false,
      errorMsg: [],
      formError: false,
      currentEvent: ""
    };
  }

  componentDidMount() {
    formValidation("member_login_from");
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { t } = this.props;
    if (nextProps.login_data !== this.props.login_data) {
      if (nextProps.login_data.responseCode === 200) {
        Swal.close();
        this.setState({ isLoading: false });

        sessionStorage.removeItem("telegram_user_info");
        sessionStorage.removeItem("google_user_info");

        let loginData = nextProps.login_data.data;
        const loggedData = {};
        loggedData.id = loginData.id;
        loggedData.username = loginData.username;
        loggedData.name = loginData.name;
        loggedData.user_group = loginData.user_group;
        loggedData.user_group_level = loginData.user_group_level;
        loggedData.country_id = loginData.country_id;
        loggedData.country_code = loginData.country_code;
        loggedData.currency = loginData.currency;
        if (this.props.currentUser.data.country_id !== loginData.country_id) {
          loggedData.language_id = loginData.language_id;
          loggedData.language_code = loginData.language_code;
        } else {
          loggedData.language_id = this.props.currentUser.data.language_id;
          loggedData.language_code = this.props.currentUser.data.language_code;
        }
        loggedData.member_group_id = loginData.group_id;
        loggedData.group_id = loginData.group_id;
        loggedData.contact_no = loginData.contact_no;
        loggedData.email = loginData.email;
        loggedData.balance = loginData.balance;
        loggedData.register_date_time = loginData.created_at;
        loggedData.time_zone = loginData.time_zone;
        this.props.getUserData(loggedData);

        localStorage.setItem("accessToken", loginData.access_token);

        if (this.props.currentUser.data.country_id !== loginData.country_id) {
          let defaultLang =
            loginData.language_code
              .replace("-", "_")
              .toString()
              .toLowerCase() || "en";
          localStorage.setItem("lang", defaultLang);
          this.props.i18n.changeLanguage(defaultLang); // force language change
        } else {
          localStorage.setItem(
            "lang",
            this.props.currentUser.data.language_code
              .replace("-", "_")
              .toString()
              .toLowerCase() || "en"
          );
        }

        // reset vip details page if diff country id
        localStorage.removeItem('vipDetails');


        // Initialize an agent at application startup.
        const fpPromise = FingerprintJS.load();

        // Get the visitor identifier when you need it.
        fpPromise
          .then((fp) => fp.get())
          .then((result) => {
            let fingerPrintData = {};
            fingerPrintData.member_id = loginData.id;
            fingerPrintData.browser = result.components.vendorFlavors.value[0];
            fingerPrintData.browser_version = result.browserVersion;
            fingerPrintData.operating_system = result.components.platform.value;
            fingerPrintData.operating_system_version = result.osVersion;
            fingerPrintData.finger_print_id = result.visitorId;
            // fingerPrintData.finger_print_response = JSON.stringify(result);
             //Get the IPV6
            axios
            .get(
              'https://api6.my-ip.io/v2/ip.json'
            )
            .then((response) => {
              if(response.data){

                //Get the IPV4
                axios
                .get(
                  'https://api4.my-ip.io/v2/ip.json'
                )
                .then((responseIPV4) => {
                  if(responseIPV4.data){
                    fingerPrintData.ip_address = responseIPV4.data.ip;
                    fingerPrintData.ip_address_v6 = response.data.ip;
                  }

                  if(!isDesktop){
                    let deviceModel;
                    deviceModel = deviceDetect();
        
                    fingerPrintData.device_model = deviceModel.vendor;
                    fingerPrintData.device_model_code = deviceModel.model;
                  }

                  this.props.updateFingerPrint(fingerPrintData);
                }).catch((err) => {
                    fingerPrintData.ip_address_v6 = response.data.ip;

                    if(!isDesktop){
                      let deviceModel;
                      deviceModel = deviceDetect();
          
                      fingerPrintData.device_model = deviceModel.vendor;
                      fingerPrintData.device_model_code = deviceModel.model;
                    }
  
                    this.props.updateFingerPrint(fingerPrintData);
                });
                
              }
            }).catch((err) => {
                //Get the IPV4
                axios
                .get(
                  'https://api4.my-ip.io/v2/ip.json'
                )
                .then((responseIPV4) => {
                  if(responseIPV4.data){
                    fingerPrintData.ip_address = responseIPV4.data.ip;
                  }

                  if(!isDesktop){
                    let deviceModel;
                    deviceModel = deviceDetect();
        
                    fingerPrintData.device_model = deviceModel.vendor;
                    fingerPrintData.device_model_code = deviceModel.model;
                  }

                  this.props.updateFingerPrint(fingerPrintData);
                }).catch((err) => {
                  // const data = {};
                  // data.username = this.state.formFilter.username;
                  // data.password = this.state.formFilter.password;
                  // data.device_type = deviceType;
                  // let params = this.encryptionAuth(data);
                  // //Call the login api
                  // this.props.postLogin(params);
                });
            });

            // console.log(fingerPrintData)
            // this.props.updateFingerPrint(fingerPrintData);
          });

        if (isDesktop) {
          this.props.history.push({
            pathname: "/",
            state: { showBackupUrlModal: true },
          });
        } else {
          this.props.history.push(`/online-live-games-${dynamicCountry}`);
        }
        // this.props.getCountry();

        // const showBannerData = {};
        // showBannerData.country_id = loginData.country_id;
        // this.props.showBanner(showBannerData);

        // const showAnnouncementData = {};
        // showAnnouncementData.country_id = loginData.country_id || "1";
        // showAnnouncementData.member_group_id = loginData.group_id || "1";
        // showAnnouncementData.lang_id = loginData.language_id || "1";
        // this.props.showAnnouncement(showAnnouncementData);

        this.setState({
          username: "",
          password: "",
          openModal: false,
        });
      } else if (nextProps.login_data.responseCode === 422) {
        this.setState({
          isLoading: false,
          errorMsg: nextProps.login_data.message.input_error || [],
          formError: true,
        });
        Swal.fire({
          iconHtml: !isDesktop
            ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">'
            : "",
          title: t("global.failed"),
          text: nextProps.login_data.message,
          // icon: nextProps.login_data.msgType,
          icon: "error",
          confirmButtonText: t("global.confirm"),
          customClass: !isDesktop
            ? {
              container: "swal-mobile-container failed",
              icon: "swal-failed-icon",
            }
            : [],
        });
      } else {
        if (
          nextProps.login_data.data.message[0] ===
          "User not exist in the system!"
        ) {
          this.setState({ isLoading: false });
          Swal.close();
          window.location.href = "/registerSocialMedia";
        } else {
          this.setState({ isLoading: false });
          Swal.fire({
            iconHtml: !isDesktop
              ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">'
              : "",
            title: t("global.failed"),
            text: nextProps.login_data.data.message[0],
            // icon: nextProps.login_data.data.msgType,
            icon: "error",
            confirmButtonText: t("global.confirm"),
            customClass: !isDesktop
              ? {
                container: "swal-mobile-container failed",
                icon: "swal-failed-icon",
              }
              : [],
          });
        }
      }
    }

    if (
      nextProps.google_redirect_result !== this.props.google_redirect_result
    ) {
      if (nextProps.google_redirect_result.responseCode === 200) {
        this.setState({
          isLoading: false,
        });

        window.location.href = nextProps.google_redirect_result.data;
      }
    }

    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  showPwd = () => {
    let newFilter = Object.assign({}, this.state.formFilter);
    newFilter["showPwd"] = !this.state.formFilter.showPwd;
    this.setState({
      formFilter: newFilter,
    });
  };

  onInputChange = (event) => {
    let newFilter = Object.assign({}, this.state.formFilter);
    newFilter[event.target.id] = event.target.value;
    this.setState({
      formFilter: newFilter,
      formError: false,
      errorMsg: [],
    });
  };

  encryptionAuth = (data) => {
    data.trxTime = moment().unix();
    let toHashStr = "";
    let hashStr = "";
    let paramsKey = [];
    for (const key in data) {
      if (!paramsKey.includes(key)) {
        paramsKey.push(key);
      }
    }

    paramsKey.sort();
    paramsKey.forEach((val) => {
        let x = data[val];
        if (x !== "") {
            if (val === "file") {
                // file required hashing params
                toHashStr += val + x.name + x.size;
            } else {
                toHashStr += val + x;
            }
        }
    });
    toHashStr += process.env.REACT_APP_HASH_SECRET;
    hashStr = CryptoJS.MD5(toHashStr).toString();
    data.trxHash = hashStr;

    return data;
  };

  postMemberLogin = (event) => {
    event.preventDefault();

    //Get the IPV6
    axios
    .get(
      'https://api6.my-ip.io/v2/ip.json'
    )
    .then((response) => {
      if(response.data){

        //Get the IPV4
        axios
        .get(
          'https://api4.my-ip.io/v2/ip.json'
        )
        .then((responseIPV4) => {
          if(responseIPV4.data){
            const data = {};
            data.username = this.state.formFilter.username;
            data.password = this.state.formFilter.password;
            data.ipv4 = responseIPV4.data.ip;
            data.ipv6 = response.data.ip;
            data.device_type = deviceType;
            let params = this.encryptionAuth(data);
            
            //Call the login api
            this.props.postLogin(params);
          }
        }).catch((err) => {
          const data = {};
          data.username = this.state.formFilter.username;
          data.password = this.state.formFilter.password;
          if(response.data){
            data.ipv6 = response.data.ip;
          }
          data.device_type = deviceType;
          let params = this.encryptionAuth(data);
          
          //Call the login api
          this.props.postLogin(params);
        });

      }
    }).catch((err) => {
         //Get the IPV4
         axios
         .get(
           'https://api4.my-ip.io/v2/ip.json'
         )
         .then((responseIPV4) => {
           if(responseIPV4.data){
              const data = {};
              data.username = this.state.formFilter.username;
              data.password = this.state.formFilter.password;
              data.ipv4 = responseIPV4.data.ip;
              data.device_type = deviceType;
              let params = this.encryptionAuth(data);
              
              //Call the login api
              this.props.postLogin(params);
           }
         }).catch((err) => {
            const data = {};
            data.username = this.state.formFilter.username;
            data.password = this.state.formFilter.password;
            data.device_type = deviceType;
            let params = this.encryptionAuth(data);
            
            //Call the login api
            this.props.postLogin(params);
         });
    });


    // Swal.showLoading();
    // Swal.fire({
    //   allowOutsideClick: false,
    //   allowEscapeKey: false,
    //   allowEnterKey: false,
    //   didOpen: () => {
    //     Swal.showLoading();
    //   },
    // });
    this.setState({ isLoading: true });
  };

  handleGoogleRedirect = () => {
    const data = {};
    data.url = window.location.origin;

    this.setState({
      isLoading: true,
    });

    this.props.redirectGoogle(data);
  };

  handleTelegramRedirect = () => {
    window.open(
      process.env.REACT_APP_SOCIAL_MEDIA_URL +
      "/?url=" +
      window.location.origin,
      "_blank"
    );
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(
      require.context(
        "../../assets/images/register/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <Auxiliary>
        {this.state.isLoading && <LoadingPage />}
        {isDesktop ? (
          <>
            {/* <video className="login-video" autoPlay loop muted>
              <source src={BackVideo} type="video/mp4" />
            </video> */}

            <section className="register-section">
              <div className="x-container-no-m">
                <div className="register-form">
                  {/* {this.state.currentEvent === "christmas" ? (
                    <img
                      src={ChristmasLogo}
                      alt="R77 logo"
                      className="logo-image"
                    />
                  ) : this.state.currentEvent === "raya" ? (
                    <img src={EventLogo} alt="R77 logo" className="logo-image" />
                  ) : (
                    <img src={Logo} alt="R77 logo" className="logo-image" />
                  )} */}

                  <p className="register-title">
                    {t('page-header.login')}
                  </p>

                  <form
                    method="post"
                    name="member_login_from"
                    id="member_login_from"
                    onSubmit={this.postMemberLogin}
                    noValidate
                  >
                    <ValidationError
                      formName="member_login_from"
                      touched={this.state.touched}
                      message={this.state.error_message}
                    />
                    {/* username */}
                    <div className="input-group">
                      <span className="input-group-text left-icon">
                        <img src={img["icon-user.webp"]} alt="username icon" />
                      </span>
                      <StringInput
                        className={`form-control ${this.state.errorMsg.username &&
                          "was-validated is-invalid"
                          }`}
                        onChange={this.onInputChange}
                        id="username"
                        name="username"
                        placeholder={t("register.username")}
                        value={this.state.formFilter.username}
                        required
                        requiredMessage={t("validation.required")}
                        errorMsg={this.state.errorMsg.username || ""}
                        formError={this.state.formError}
                      />
                      {/* <input
                            type="text"
                            id="registerUsername"
                            className="form-control"
                            required=""
                            placeholder="Username"
                          /> */}
                    </div>
                    {/* password */}
                    <div className="input-group">
                      <span className="input-group-text left-icon">
                        <img
                          src={img["icon-password.webp"]}
                          alt="password icon"
                        />
                      </span>

                      <PasswordInput
                        showPwd={this.state.formFilter.showPwd}
                        onChange={this.onInputChange}
                        id="password"
                        name="password"
                        className={`no-border form-control ${this.state.errorMsg.password &&
                          "was-validated is-invalid"
                          }`}
                        placeholder={t("register.password")}
                        value={this.state.formFilter.password}
                        minLength={6}
                        required
                        requiredMessage={t("validation.required")}
                        minLengthMessage={t("validation.minLength", { 0: 6 })}
                        errorMsg={this.state.errorMsg.password || ""}
                        formError={this.state.formError}
                        format={
                          <span className="input-group-text">
                            <button
                              type="button"
                              className=" right-icon"
                              onClick={this.showPwd}
                            >
                              {this.state.formFilter.showPwd ? (
                                <img
                                  src={img["icon-eye-open.webp"]}
                                  alt="show pwd"
                                />
                              ) : (
                                <img
                                  src={img["icon-eye-close.webp"]}
                                  alt="hide pwd"
                                />
                              )}
                            </button>
                          </span>
                        }
                      />
                      {/* <input
                            type={this.state.showPassword ? "text" : "password"}
                            id="registerPassword"
                            className="form-control no-border"
                            autoComplete="off"
                            placeholder="Password"
                            required
                          /> */}
                    </div>
                    {/* checklist and forget password*/}
                    <div className="d-flex justify-content-end small-w">
                      {/* <div className="checklist">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckChecked"
                            />
                            <small
                              className="form-check-label px-2"
                              htmlFor="flexCheckChecked"
                            >
                              Remember password
                            </small>
                          </div> */}
                      <Link to={{ pathname: "/forget-password" }}>
                        {" "}
                        <small>{t("page-header.forgot-pwd")}?</small>
                      </Link>
                    </div>
                    <button
                      className={`btn btn-blue ${this.state.formFilter.username !== "" &&
                          this.state.formFilter.password !== ""
                          ? ""
                          : "disabled"
                        }`}
                      type="submit"
                      form="member_login_from"
                      value="Submit"
                    >
                      {t("page-header.login")}
                    </button>
                    {/* register now /skip and enter*/}
                    <div className="d-flex justify-content-between small-w">
                      <div>
                        {t('register.no-account')} {""}
                        <Link to={{ pathname: "/register" }}>
                          <small style={{ color: "unset" }}>
                            {t("page-header.register-now")}
                          </small>
                        </Link>
                      </div>
                      {/* <div>
                        <Link to={{ pathname: "/" }}>
                          <small>{t("page-header.skip-n-enter")} </small>
                        </Link>
                      </div> */}
                    </div>
                    {/*google and tele login*/}
                    {/* <div className="text-center bottom-content small-w">
                      <small>
                        <span className="line">{t("page-header.or")}</span>
                      </small>
                      <small className="small-w">
                        {t("page-header.login-with")} :
                      </small>

                      <div className="google-tele-bg">
                        <button
                          type="button"
                          onClick={this.handleGoogleRedirect}
                        >
                          <img
                            src={img["icon-google.webp"]}
                            alt="google logo"
                          />
                        </button>
                        <button
                          type="button"
                          onClick={this.handleTelegramRedirect}
                        >
                          <img
                            src={img["icon-telegram.webp"]}
                            alt="telegram logo"
                          />
                        </button>
                      </div>
                    </div> */}
                    {/* contact us*/}
                    {/* <div className="text-center contact-us-btn small-w">
                      <Link to={{ pathname: "/contact-us" }}>
                        <img
                          src={img["icon-contact-us.webp"]}
                          alt="contact us icon"
                        />
                        <small> {t("footer.contact-us")} </small>
                      </Link>
                    </div> */}
                  </form>
                </div>

                <div className="jackpot">
                  <img 
                    src={img['jackpot.webp']}
                    alt="jackpot"
                  />
                </div>   
              </div>
            </section>
          </>
        ) : (
          <MobileLogin
            postMemberLogin={this.postMemberLogin}
            onInputChange={this.onInputChange}
            initialFormInputState={this.initialFormInputState}
            handleGoogleRedirect={this.handleGoogleRedirect}
            handleTelegramRedirect={this.handleTelegramRedirect}
            showPwd={this.showPwd}
            {...this.state}
            {...this.props}
          />
        )}
      </Auxiliary>
    );
  }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
  const { auth } = state;

  return {
    login_data: auth.login_data,
    google_redirect_result: state.profile.google_redirect_result,
    currentUser: state.currentUser,
    update_fingerprint: auth.update_fingerprint,
  };
};

//Using the redux calling api methods
const mapDispatchToProps = {
  postLogin,
  getUserData,
  redirectGoogle,
  updateFingerPrint,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(withNamespaces("translation")(Login)));
