import axios from 'axios';
import Swal from 'sweetalert2';
import { isDesktop } from 'react-device-detect';

//Set the axios default url based on your development machines url
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_DEVELOPMENT_URL + 'api/member/v1/'
    // baseURL: 'http://localhost:8000/api/'
});

instance.defaults.headers.common['X-Authorization'] = process.env.REACT_APP_X_AUTHORIZATION_KEY;
instance.defaults.headers.common['X-Merchant'] = process.env.REACT_APP_MERCHANT_KEY;
if (localStorage.getItem('accessToken')) {
    instance.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('accessToken');
}

instance.interceptors.request.use(request => {
    request.headers.Authorization = 'Bearer ' + localStorage.getItem('accessToken');
    request.headers.lang = (localStorage.getItem('lang') || 'en');
    request.headers.merchant = process.env.REACT_APP_MERCHANT_KEY;
    // console.log('interceptors request: ',request);
    return request;
}, error => {
    console.log(error);
    alert(error.request.data.message);
});

instance.interceptors.response.use(response => {
    // console.log('interceptors response: ',response);
    return response;
}, error => {
    // If the response api got returned error message just take a error message , if not take the return data error message

    let responseMsg = '';
    const currentLang = localStorage.getItem('lang') || 'en';
    const translationTitle = { "en": "Error", "zh_cn": "网络", "ms": "Kesilapan", "th": "ข้อผิดพลาด", "id": "Kesalahan", "vi": "lỗi", "us": "Error" };
    const translationText = { "en": "Network Error", "zh_cn": "网络错误", "ms": "Rangkaian Kesilapan", "th": "ข้อผิดพลาดของเครือข่าย", "id": "Kesalahan Jaringan", "vi": "lỗi mạng", "us": "Network Error" };
    const translationButton = { "en": "OK", "zh_cn": "好的", "ms": "Okey", "th": "ตกลง", "id": "Oke", "vi": "VÂNG", "us": "OK" };

    if (error.request.response) {
        let msg = JSON.parse(error.request.response);
        responseMsg = msg.message[0];
    }
    else {
        responseMsg = translationText[currentLang];
        // responseMsg = error;
    }

    if (error.response &&
        (error.response.data.message[0] === "Token is Incompatible" ||
            error.response.data.message[0] === "Token is Expired" ||
            error.response.data.message[0] === "Token is Invalid")) {
        localStorage.clear();
        window.location.href = "/";
    }
    else {
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : '',
                title: translationTitle[currentLang],
                text: responseMsg,
                icon: 'error',
                confirmButtonText: translationButton[currentLang],
                customClass: (!isDesktop) ? {
                    container: "swal-mobile-container failed",
                    icon: "swal-failed-icon",
                } : [],
            });

    }
    return Promise.reject(error.response);
});

export default instance;