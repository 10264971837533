import React, { Component } from "react";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import { connect } from "react-redux";
import { isDesktop } from 'react-device-detect';

import GameNav from "../../components/MobileGameNav/MobileGameNav";
import Banner from "../../components/Banner/Banner";
import MobileHeader from "../../components/PageHeader/MobilePageHeader";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
// import { Icon } from "@iconify/react";
import SideBar from "../../components/SideBar/SideBar";
import BackupUrlModal from "../../components/BackupUrlModal/BackupUrlModal";
import Skeleton from "react-loading-skeleton";
import { claimAngpau } from "../../store/ducks/angpau/actions";
import { getGameVendorFav, updateGameVendorFav } from "../../store/ducks/gameVendorFav/actions";
// import Announcement from "../../components/Announcement/Announcement";
import { getUserData } from "../../store/ducks/currentUser/actions";

import slugConf from "../../common/data/games.json";
import { Link } from "react-router-dom";
import routes from "../../routes";
import AngpaoRain from "../Events/AngpaoRain/AngpaoRain";
import RayaEvents from "../Events/RayaEvents/RayaEvents";
import DewaliEvent from "../Events/DewaliEvent/DewaliEvent";
import HomeAngpaoEvent from "../Events/HomeAngpaoEvent/HomeAngpaoEvent";

class MobileHome extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            // showBackupUrlModal: localStorage.getItem("accessToken") ? true : false,
            showBackupUrlModal: false,
            backupUrlValue: "",
            gameType: 'casino',
            slideIndex: 0,
            currentEvent: "",
            showAngpau: false,
            bonusStatus: false,
            homePageEvents: true,
            angpaoAmt: "",
            favIcon: true,
            favList: [],
            vendorLoading: false
        };
    }

    componentDidMount() {
        const { pathname } = this.props.location;
        let gameType = '';

        routes.forEach((route, index) => {
            if (route.path === pathname) {
                gameType = (route.slug === 'lottery') ? 'keno' : route.slug;
            }
        });

        this.setState({
            gameType,
        });

        if(localStorage.getItem('accessToken')){
            this.props.claimAngpau();
            let fetchData = {};
            fetchData.type = gameType;
            this.props.getGameVendorFav(fetchData);
            this.setState({ vendorLoading: true });
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (nextProps.claim_angpau_result !== this.props.claim_angpau_result) {
            if (nextProps.claim_angpau_result.responseCode === 200) {
                if (nextProps.claim_angpau_result.data.eligible === false) {
                    this.setState({
                        showAngpau: false
                    });
                } else {
                    this.setState({
                        showAngpau: true
                    });
                }
            } else {
                return false;
            }
        }

        if (nextProps.game_vendor_fav !== undefined && nextProps.game_vendor_fav !== this.props.game_vendor_fav) {
            if (nextProps.game_vendor_fav.responseCode === 200 && nextProps.game_vendor_fav.msgType === "success") {
                this.setState({
                    vendorLoading: false,
                    favList: nextProps.game_vendor_fav.data.result
                });
            }
        }

        if (nextProps.update_game_vendor_fav !== undefined && nextProps.update_game_vendor_fav !== this.props.update_game_vendor_fav) {
            if (nextProps.update_game_vendor_fav.responseCode === 200 && nextProps.update_game_vendor_fav.msgType === "success") {
                let fetchData = {};
                fetchData.type = this.state.gameType;
                this.props.getGameVendorFav(fetchData);
                this.setState({ vendorLoading: true });
            }
        }

        if (nextProps.get_login_data !== this.props.get_login_data) {
            if (nextProps.get_login_data.responseCode === 200) {
                let loginData = nextProps.get_login_data.data;
                const loggedData = {};
                loggedData.country_id = loginData.country_id;

                this.props.getUserData(loggedData);    
            }
            
        }

        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });

        return images;
    }

    toggleBackupUrlModal = () => {
        this.setState({
            showBackupUrlModal: !this.state.showBackupUrlModal,
        });
    }

    setDontShow = (e) => {
        const data = {};
        data.checked = e.target.checked;
        this.props.updateMemberPopUp(data);
    };

    redirectBackupUrlHandler = (e, value) => {
        e.preventDefault();
        window.location.href = value;
    }

    changeGameHandler = (gameType, url) => {
        // this.props.changeGameHandler(game);
        this.setState({
            gameType: '' // clear vendor list, then only reload
        }, () => {
            this.setState({
                gameType
            }, () => {
                if(localStorage.getItem('accessToken')){
                    let fetchData = {};
                    fetchData.type = gameType;
                    this.props.getGameVendorFav(fetchData);
                    this.setState({ vendorLoading: true });
                }

                window.history.pushState('', '', url);
            });
        });
    }

    accessGame = (game, gameType) => {
        if (!localStorage.getItem("accessToken")) {
            const { t } = this.props;
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                icon: "info",
                title: t("global.sorry"),
                text: t("global.login-proceed"),
                confirmButtonText: t("global.ok"),
                showCancelButton: (!isDesktop) ? false : '',
                customClass: (!isDesktop) ? {
                    container: "swal-mobile-container sorry",
                    icon: "swal-sorry-icon",
                } : []
            });
        }
        else {
            localStorage.setItem("gameLauncher", gameType + '|' + game);
            window.open('/game-launcher');
        }
    }

    openAngpao = () => {
        this.setState({
            bonusStatus: true,
        });
    };

    toggleAngpaoRain = () => {
        this.setState({
            homePageEvents: false,
        });

        localStorage.setItem('showAngbao', false);
    };

    toggleHomeEvents = () => {
        this.setState({
          homePageEvents: false,
        });
    
        localStorage.setItem("showAngbao", false);
        document.body.classList.remove('modal-open');
      };

      openEventsBonus = () => {
        this.setState({
          bonusStatus: true,
        });
      };
    

    toggleFavIcon = (e, slug) => {
        e.preventDefault();
        e.stopPropagation();
        if (!localStorage.getItem("accessToken")) {
            const { t } = this.props;
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                icon: "info",
                title: t("global.sorry"),
                text: t("global.login-proceed"),
                confirmButtonText: t("global.ok"),
                showCancelButton: (!isDesktop) ? false : '',
                customClass: (!isDesktop) ? {
                    container: "swal-mobile-container sorry",
                    icon: "swal-sorry-icon",
                } : []
            });
        }
        else{
            let fetchData = {};
            fetchData.type = this.state.gameType;
            fetchData.slug = slug;
            this.props.updateGameVendorFav(fetchData);
            this.setState({ vendorLoading: true });
        }
    }


    render() {
        const { t } = this.props;
        let s3ImgUrl = `${process.env.REACT_APP_S3_URL}/images/icon/v2`;
        const favIcon = this.imageImport(require.context("../../assets/images/icons/mobile", false, /\.(png|jpe?g|svg|webp)$/));

        let vendorList = this.props.vendorList;

        let hotLang = '';
        switch (this.props.currentUser.language_code) {
          case "zh-CN":
            hotLang = "-cn"
            break;
            default:
              hotLang = ""
              break;
        }
        
        return (
            <Auxiliary>
                {this.state.currentEvent === "cny" && localStorage.getItem('accessToken') && this.state.showAngpau === true && !localStorage.getItem('showAngbao') &&
                    <HomeAngpaoEvent
                        {...this.state}
                        {...this.props}
                        // bonusStatus={this.state.bonusStatus}
                        // homePageEvents={this.state.homePageEvents}
                        openEventsBonus={this.openEventsBonus}
                        toggleHomeEvents={this.toggleHomeEvents}
                        
                    />
                }
                {/* {  localStorage.getItem('accessToken') && this.state.showAngpau === true && !localStorage.getItem('showAngbao') && 
                    this.state.currentEvent === "raya" && (
                    <RayaEvents
                        {...this.state}
                        {...this.props}
                        bonusStatus={this.state.bonusStatus}
                        homePageEvents={this.state.homePageEvents}
                        openEventsBonus={this.openEventsBonus}
                        toggleHomeEvents={this.toggleAngpaoRain}
                    />
                )}

                {process.env.REACT_APP_ENV === "staging" && this.state.showAngpau === true && this.state.currentEvent === "dewali" &&
                    <DewaliEvent 
                    {...this.state}
                    {...this.props}
                    bonusStatus={this.state.bonusStatus}
                    homePageEvents={this.state.homePageEvents}
                    openEventsBonus={this.openEventsBonus}
                    toggleHomeEvents={this.toggleHomeEvents}
                    />
                } */}

                <SideBar />

                <section className="d-flex overflow-hidden flex-column" style={{ height: "calc(100% - 2.5rem)" }}>
                    <Banner category="home" />
                    <div className="px-3 d-flex overflow-hidden flex-column">
                        <MobileHeader />
                        <div className="d-flex gap-2 mt-2 overflow-hidden">
                        <GameNav
                            {...this.state}
                            {...this.props}
                            changeGameHandler={this.changeGameHandler}
                        />
                            <div className="mobile-game-container">
                                {/* {vendorList && vendorList.map((item, index) => {
                                    if (item.gameType === this.state.gameType) {
                                        if (item.gameType === 'slot' || item.gameType === 'games' || item.gameType === 'keno' || item.gameType === 'p2p') {
                                            return (
                                                <Link to={{ pathname: `/${(item.gameType === 'keno') ? 'lottery' : item.gameType}/${((slugConf['slug-link'] && slugConf['slug-link'][item.slug]) || '')}`, state: { vendorSlug: item.slug, vendorId: item.vendor_id } }} className="mobile-game-card" key={index}>
                                                    <span className=" card-shadow">
                                                        <img src={`${s3ImgUrl}/${item.gameType}/${item.slug}.webp`} alt={item.name} />
                                                    </span>
                                                    <button className="btn btn-white">
                                                        {t("global.enter")}
                                                    </button>
                                                    {process.env.REACT_APP_ENV === "staging" && (
                                                    <button type="button" className="fav-btn" onClick={(e) => this.toggleFavIcon(e, item.slug)}>
                                                        {!this.state.favIcon ? (
                                                            <img src={favIcon["fav-red.png"]} alt="fav red" />) : (<img src={favIcon["fav-gray.png"]} alt="fav gray" />)}
                                                    </button>
                                                    )}

                                                </Link>
                                            );
                                        }
                                        else {
                                            return (
                                                <div className="mobile-game-card" key={index} onClick={() => this.accessGame(item.slug, item.gameType)}>
                                                    <span className=" card-shadow">
                                                        <img src={`${s3ImgUrl}/${item.gameType}/${item.slug}.webp`} alt={item.name} />
                                                    </span>
                                                    <div role="button" className="btn btn-white">
                                                        {t("global.enter")}
                                                    </div>
                                                    {process.env.REACT_APP_ENV === "staging" && (
                                                    <button type="button" className="fav-btn" onClick={(e) => this.toggleFavIcon(e, item.slug)}>
                                                        {!this.state.favIcon ? (
                                                            <img src={favIcon["fav-red.png"]} alt="fav red" />) : (<img src={favIcon["fav-gray.png"]} alt="fav gray" />)}
                                                    </button>
                                                    )}

                                                </div>
                                            );
                                        }
                                    }
                                    else {
                                        return null;
                                    }
                                })} */}

                                {(()=> {
                                    let favVendor = [];
                                    let nonFavVendor = [];
                                    let combinedVendor = [];
                                    let fetchedFavVendor = this.state.favList;
                                    if(vendorList && !this.state.vendorLoading){
                                        vendorList.forEach((item, index) => {
                                            if (item.gameType === this.state.gameType) {
                                                if (item.gameType === 'slot' || item.gameType === 'games' || item.gameType === 'keno' || item.gameType === 'p2p') {
                                                    if(fetchedFavVendor.length > 0){
                                                        if(fetchedFavVendor.indexOf(item.slug) >= 0){
                                                            favVendor[fetchedFavVendor.indexOf(item.slug)] = (
                                                                <Link to={{ pathname: `/${(item.gameType === 'keno') ? 'lottery' : item.gameType}/${((slugConf['slug-link'] && slugConf['slug-link'][item.slug]) || '')}`, state: { vendorSlug: item.slug, vendorId: item.vendor_id } }} className={`mobile-game-card ${(item.hotStatus === 1) ? `hot${hotLang}` : ''}`} key={index}>
                                                                    <span className=" card-shadow">
                                                                        <img src={`${s3ImgUrl}/${item.gameType}/${item.slug}.webp`} alt={item.name} />
                                                                    </span>
                                                                    <button className="btn btn-blue">
                                                                        {t("global.enter")}
                                                                    </button>
                                                                    <button type="button" className="fav-btn" onClick={(e) => this.toggleFavIcon(e, item.slug)}>
                                                                        <img src={favIcon["fav-blue.webp"]} alt="fav red" />
                                                                    </button>
                                                                </Link>
                                                            );
                                                        }
                                                        else{
                                                            nonFavVendor.push(
                                                                <Link to={{ pathname: `/${(item.gameType === 'keno') ? 'lottery' : item.gameType}/${((slugConf['slug-link'] && slugConf['slug-link'][item.slug]) || '')}`, state: { vendorSlug: item.slug, vendorId: item.vendor_id } }} className={`mobile-game-card ${(item.hotStatus === 1) ? `hot${hotLang}` : ''}`} key={index}>
                                                                    <span className=" card-shadow">
                                                                        <img src={`${s3ImgUrl}/${item.gameType}/${item.slug}.webp`} alt={item.name} />
                                                                    </span>
                                                                    <button className="btn btn-blue">
                                                                        {t("global.enter")}
                                                                    </button>
                                                                    <button type="button" className="fav-btn" onClick={(e) => this.toggleFavIcon(e, item.slug)}>
                                                                        <img src={favIcon["fav-gray.webp"]} alt="fav gray" />
                                                                    </button>
                                                                </Link>
                                                            );
                                                        }
                                                    }
                                                    else{
                                                        nonFavVendor.push(
                                                            <Link to={{ pathname: `/${(item.gameType === 'keno') ? 'lottery' : item.gameType}/${((slugConf['slug-link'] && slugConf['slug-link'][item.slug]) || '')}`, state: { vendorSlug: item.slug, vendorId: item.vendor_id } }} className={`mobile-game-card ${(item.hotStatus === 1) ? `hot${hotLang}` : ''}`} key={index}>
                                                                <span className=" card-shadow">
                                                                    <img src={`${s3ImgUrl}/${item.gameType}/${item.slug}.webp`} alt={item.name} />
                                                                </span>
                                                                <button className="btn btn-blue">
                                                                    {t("global.enter")}
                                                                </button>
                                                                <button type="button" className="fav-btn" onClick={(e) => this.toggleFavIcon(e, item.slug)}>
                                                                    <img src={favIcon["fav-gray.webp"]} alt="fav gray" />
                                                                </button>
                                                            </Link>
                                                        );
                                                    }
                                                }
                                                else {
                                                    // sport & casino
                                                    if(fetchedFavVendor.length > 0){
                                                        if(fetchedFavVendor.indexOf(item.slug) >= 0){
                                                            favVendor[fetchedFavVendor.indexOf(item.slug)] = (
                                                                <div className={`mobile-game-card ${(item.hotStatus === 1) ? `hot${hotLang}` : ''}`} key={index} onClick={() => this.accessGame(item.slug, item.gameType)}>
                                                                    <span className=" card-shadow">
                                                                        <img src={`${s3ImgUrl}/${item.gameType}/${item.slug}.webp`} alt={item.name} />
                                                                    </span>
                                                                    <div role="button" className="btn btn-blue">
                                                                        {t("global.enter")}
                                                                    </div>
                                                                    <button type="button" className="fav-btn" onClick={(e) => this.toggleFavIcon(e, item.slug)}>
                                                                        <img src={favIcon["fav-blue.webp"]} alt="fav red" />
                                                                    </button>
                                                                </div>
                                                            );
                                                        }
                                                        else{
                                                            nonFavVendor.push(
                                                                <div className={`mobile-game-card ${(item.hotStatus === 1) ? `hot${hotLang}` : ''}`} key={index} onClick={() => this.accessGame(item.slug, item.gameType)}>
                                                                    <span className=" card-shadow">
                                                                        <img src={`${s3ImgUrl}/${item.gameType}/${item.slug}.webp`} alt={item.name} />
                                                                    </span>
                                                                    <div role="button" className="btn btn-blue">
                                                                        {t("global.enter")}
                                                                    </div>
                                                                    <button type="button" className="fav-btn" onClick={(e) => this.toggleFavIcon(e, item.slug)}>
                                                                        <img src={favIcon["fav-gray.webp"]} alt="fav gray" />
                                                                    </button>
                                                                </div>
                                                            );
                                                        }
                                                    }
                                                    else{
                                                        nonFavVendor.push(
                                                            <div className={`mobile-game-card ${(item.hotStatus === 1) ? `hot${hotLang}` : ''}`} key={index} onClick={() => this.accessGame(item.slug, item.gameType)}>
                                                                <span className=" card-shadow">
                                                                    <img src={`${s3ImgUrl}/${item.gameType}/${item.slug}.webp`} alt={item.name} />
                                                                </span>
                                                                <div role="button" className="btn btn-blue">
                                                                    {t("global.enter")}
                                                                </div>
                                                                <button type="button" className="fav-btn" onClick={(e) => this.toggleFavIcon(e, item.slug)}>
                                                                    <img src={favIcon["fav-gray.webp"]} alt="fav gray" />
                                                                </button>
                                                            </div>
                                                        );
                                                    }
                                                }
                                            }
                                        });

                                        combinedVendor = favVendor.concat(nonFavVendor);
                                        return combinedVendor;
                                    }
                                    else{
                                        return (
                                            <Skeleton count={1} height="100%" />
                                        );
                                    }
                                })()}
                        </div>
                    </div>
                    </div>
                </section>

                {this.props.showBackUpUrl && this.props.currentUser.country_id !== 4 && (
                    <BackupUrlModal
                        setDontShow={this.setDontShow}
                        toggleBackupUrlModal={this.toggleBackupUrlModal}
                        handleSubmit={this.handleSubmit}
                        redirectBackupUrlHandler={this.redirectBackupUrlHandler}
                        {...this.props}
                        {...this.state}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        claim_angpau_result: state.events.claim_angpau_result,
        game_vendor_fav: state.gameVendorFav.game_vendor_fav,
        update_game_vendor_fav: state.gameVendorFav.update_game_vendor_fav,
        currentUser: state.currentUser.data,
    };
};

const mapDispatchToProps = {
    claimAngpau,
    getGameVendorFav,
    updateGameVendorFav,
    getUserData,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withNamespaces("translation")(MobileHome));