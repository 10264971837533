import React, { Component } from 'react'
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from "react-i18next";
import MobileSubHeader from '../PageHeader/MobileSubHeader';

import { Lightbox } from "react-modal-image";

export class MobileBusinessAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openImageModal: false,
    };
  }

  render() {
    const { t } = this.props;
    return (
      <Auxiliary>
        {
          this.state.openImageModal && (
            <Lightbox
              medium={this.props.ssmImage}
              hideZoom={true}
              hideDownload={true}
              onClose={() => { this.setState({ openImageModal: false }) }}
            />
          )
        }
        {
          this.props.showBusinessAcc &&
          <section className="business-m-info pf-m-dashboard on-top d-block pe-auto">
            <MobileSubHeader
              // className="bg-white"
              closeModalHandler={this.props.toggleBusinessAcc}
              header={t("profile.corporate-info")}
              style={{ justifyContent: "unset" }}
            />
            <div className='pd-form-m-col my-3'>
              <div className='input-group-col'>
                <div className='pb-3'>
                  <label className='col-form-label'>{t("profile.comp-name")}</label>
                  <p>{this.props.companyName}</p>
                </div>

                <div className='py-3 border-top'>
                  <label className='col-form-label'>{t("profile.comp-reg-no")}</label>
                  <p>{this.props.companyRegNo}</p>
                </div>

                <div className='py-3 border-top'>
                  <label className='col-form-label'>{t("profile.bank-acc-no")}</label>
                  <p>{this.props.bankAccNo}</p>
                </div>

                <div className='pt-3 border-top'>
                  <label className='col-form-label'>{t("profile.ssm-no")}</label>
                  {
                    this.props.ssmImage &&
                    <p className="view-image" onClick={() => this.setState({ openImageModal: true })}>{t("global.view-image")}</p>
                  }
                </div>

              </div>
            </div>

            <p className='footer-desc'>
              {t("profile.corporate-info-desc1")}
              <span onClick={window['liveChatButton']}>{t("global.customer-service")}</span>
              {t("profile.corporate-info-desc2")}
            </p>
          </section>
        }

      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileBusinessAccount));