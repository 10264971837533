import React, { Component } from 'react'
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { withNamespaces } from 'react-i18next'
// import { Link } from "react-router-dom";

import StringInput from '../../../components/Input/StringInput/StringInput';
import MobileSubHeader from '../../../components/PageHeader/MobileSubHeader';

export class MobileUpdateName extends Component {
  render() {
    const { t } = this.props;
    return (
      <Auxiliary>
        {
          this.props.showUpdateName &&
          <section className="edit-name-section pf-m-dashboard on-top d-block pe-auto">
            <MobileSubHeader
              // className="bg-white"
              closeModalHandler={() => {
                this.props.toggleShowUpdateName();
              }}
              header={t("profile.edit-full-name")}
              style={{ justifyContent: "unset" }}
            />
            <form className="forms-col needs-validation my-3" method="post" onSubmit={this.props.verifyOtpHandler}>
              <div className="pd-form-m-col">
                <div className="input-group-col">
                  <label className="col-form-label p-0 m-0">{t("profile.fullname")}</label>
                  {/* <ValidationError formName="mobile_verify_from" touched={this.props.touched} message={this.props.errorMsgMobile} /> */}
                  <div className="input-group left has-validation pb-3">
                    <StringInput
                      // className={`${this.props.errorMsgMobile.contact_no && "was-validated is-invalid"}`}
                      style={{ width: "70%" }}
                      type="text"
                      placeholder={t("profile.enter-full-name")}
                      name="verificationPhoneNo"
                      defaultValue=""
                      value={this.props.fullName}
                      onChange={(e) => {
                        this.props.updateFullName(e);
                      }}
                      // minLength={this.props.contact_no_min_limits}
                      // maxLength={this.props.contact_no_max_limits}
                      // minLengthMessage={t("validation.minLength", {
                      //   0: this.props.contact_no_min_limits,
                      // })}
                      // maxLengthMessage={t("validation.maxLength", {
                      //   0: this.props.contact_no_max_limits,
                      // })}
                      // required
                      requiredMessage={t("validation.required")}
                      // errorMsg={this.props.errorMsgMobile.contact_no || ""}
                      // formError={this.props.formErrorMobile}
                    />
                  </div>
                </div>

                <button
                  className="btn btn-gradient-blue mx-3"
                  type="submit"
                  value="Submit"
                  // disabled={!this.props.currentMobileNo || (this.props.sms_otp_code.length < 6 && "active")}
                >
                  {t("global.submit")}
                </button>
              </div>
            </form>
          </section>
        }

      </Auxiliary>
    )
  }
}

export default withNamespaces("translation")(MobileUpdateName);