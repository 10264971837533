export const GET_TOP_PROFILE = "GET_TOP_PROFILE";
export const GET_WALLET_BALANCE = "GET_WALLET_BALANCE";
export const GET_MEMBER_PROFILE = "GET_MEMBER_PROFILE";
export const UPDATE_MEMBER_PROFILE = "UPDATE_MEMBER_PROFILE";

export const GET_MEMBER_BANK = "GET_MEMBER_BANK";
export const ADD_MEMBER_BANK = "ADD_MEMBER_BANK";
export const EDIT_MEMBER_BANK = "EDIT_MEMBER_BANK";
export const REMOVE_MEMBER_BANK = "REMOVE_MEMBER_BANK";

export const UPDATE_MEMBER_PASSWORD = "UPDATE_MEMBER_PASSWORD";

export const GET_MEMBER_REFERRAL = "GET_MEMBER_REFERRAL";
export const GET_MEMBER_REFERRAL_BONUS = "GET_MEMBER_REFERRAL_BONUS";

export const GENERATE_SMS_OTP = "GENERATE_SMS_OTP";
export const VERFIY_SMS_OTP = "VERFIY_SMS_OTP";
export const RESEND_OTP = "RESEND_OTP";

export const GET_AVAILABLE_CRYPTO_LIST = "GET_AVAILABLE_CRYPTO_LIST";
export const SOCIAL_MEDIA_USER_VERIFICATION = "SOCIAL_MEDIA_USER_VERIFICATION";

export const GENERATE_EMAIL_OTP = "GENERATE_EMAIL_OTP";
export const VERIFY_EMAIL_OTP = "VERIFY_EMAIL_OTP";
export const RESEND_EMAIL_OTP = "RESEND_EMAIL_OTP";

export const UPDATE_MEMBER_EMAIL = "UPDATE_MEMBER_EMAIL";
export const UPDATE_MEMBER_CONTACT_NO = "UPDATE_MEMBER_CONTACT_NO";

export const GET_CRYPTO_ADDRESS = "GET_CRYPTO_ADDRESS";
export const REMOVE_CRYPTO_ADDRESS = "REMOVE_CRYPTO_ADDRESS";
export const UPDATE_CRYPTO_ADDRESS = "UPDATE_CRYPTO_ADDRESS";

export const GOOGLE_REDIRECT = "GOOGLE_REDIRECT";
export const UNLINK_GOOGLE = "UNLINK_GOOGLE";

export const SET_SECURITY_PIN = "SET_SECURITY_PIN";
export const VERIFY_SECURITY_PIN = "VERIFY_SECURITY_PIN";
export const UPDATE_SECURITY_PIN = "UPDATE_SECURITY_PIN";
export const CHECK_SECURITY_PIN_ATTEMPTS = "CHECK_SECURITY_PIN_ATTEMPTS";
export const REMOVE_SECURITY_PIN = "REMOVE_SECURITY_PIN";

export const UPDATE_PROFILE_PICTURE = "UPDATE_PROFILE_PICTURE";

export const UNLINK_TELEGRAM = "UNLINK_TELEGRAM";

export const UPDATE_MEMBER_POP_UP = "UPDATE_MEMBER_POP_UP";

export const UPGRADE_BUSINESS_ACCOUNT = "UPGRADE_BUSINESS_ACCOUNT";
export const GET_BUSINESS_ACCOUNT = "GET_BUSINESS_ACCOUNT";

export const GET_INTEREST = "GET_INTEREST";

export const UPDATE_MEMBER_ADDRESS = "UPDATE_MEMBER_ADDRESS";