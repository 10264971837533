import React, { Component, createRef } from 'react'

import { isDesktop } from 'react-device-detect';
import { withNamespaces } from "react-i18next";

import Modal from "react-modal";
import { Icon } from "@iconify/react";
import QRCode from "react-qr-code";
import moment from "moment";
import ReactTooltip from "react-tooltip";
import Timer from "react-compound-timer";

export class CryptoDepositModal extends Component {
  constructor(props) {
    super(props);
    this.selectText = this.selectText.bind(this);
    this.linkRef = createRef();
    this.ref = createRef();
  }

  async selectText(id) {
    const p = document.getElementById(id);
    const selection = window.getSelection();
    selection.removeAllRanges();

    // Select paragraph
    const range = document.createRange();
    range.selectNodeContents(p);
    selection.addRange(range);

    const pValue = p.textContent;
    await navigator.clipboard.writeText(pValue);
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  }

  getDuration = (createDate) => {
    let tmpDate = createDate.split(' ');
    let createDateEof = moment(`${tmpDate}`).tz("Asia/Kuala_Lumpur");
    const now = moment().tz("Asia/Kuala_Lumpur");
    const eof = moment(`${tmpDate}`).tz("Asia/Kuala_Lumpur");
    const duration = eof.diff(now, "milliseconds");
    return (duration < 0 || now > createDateEof) ? 0 : duration;
  }

  copyToClipboardHandler = event => {
    this.linkRef.current.select();
    document.execCommand("copy");
  }

  copyUstdAmount = event => {
    this.ref.current.select();
    document.execCommand("copy");
  }


  render() {
    const { t } = this.props;
    const cryptoImg = this.imageImport(require.context("../../assets/images/profile/crypto", false, /\.(png|jpe?g|svg)$/));

    return (
      <Modal
        isOpen={this.props.openCryptoDepositModal}
        closeTimeoutMS={500}
        portalClassName={`global-modal`}
        className="modal modal-dialog modal-dialog-centered modal-pd cypto-deposit-modal"
        ariaHideApp={false}
        onRequestClose={this.props.toggleCryptoDepositModalHandler}>
        <div className={`modal-content crypto-deposit ${!isDesktop && "mobile-modal-bg-white p-0"}`}>
          <div className={`modal-header ${!isDesktop && "border-bottom"}`}>
            <div className='cd-top gap-1 mb-1'>
              <img src={cryptoImg[`crypto-${this.props.cryptoPayment === 'pending' ? 'pending' : this.props.cryptoPayment === 'reject' ? 'reject' : 'success'}.png`]} alt="crypto-img" />
              <p className='cd-status'>{t("history." + this.props.cryptoPayment)} {t("deposit.payment")}</p>
              {this.props.cryptoPayment === "pending" && this.props.time.h >= 0 && (
                <Timer
                  initialTime={this.getDuration(this.props.expiredDateTime)}
                  direction="backward"
                  formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                  onStop={(value) => { console.log('onStop hook') }}
                >
                  <p style={{color:"red"}}>(<Timer.Hours />:<Timer.Minutes />:<Timer.Seconds />)</p>
                </Timer>
                 /* <p className='count-down'>{"0" + this.props.time.h + ":" + this.props.time.m + ":" + this.props.time.s}</p>*/
             )} 
              {/* <h2 className='number mb-0'>{this.props.currentUser.currency} {this.props.input.amount}</h2>
              <div className='cd-usdt-amount mb-3'>
                <p className='pe-1'>{t("deposit.usdt-amount", { CRYPTO: this.props.type.toUpperCase() })} </p>
                <div className='cd-ref-no'>
                  <p id='usdt-amount'>{this.props.usdtAmt}</p>
                  {
                    this.props.usdtAmt &&
                    <button type="button" className="btn icon"
                      data-tip={t('global.copied')} data-event='click1 focus'
                      onClick={() => { this.selectText("usdt-amount") }}
                    >
                      <Icon icon="mdi:content-copy" />
                    </button>
                  }
                  <ReactTooltip globalEventOff='click1' className="custom-tooltip" />
                </div>
              </div> */}
            </div>
            <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleCryptoDepositModalHandler} />
          </div>
          <div className="modal-body ">
            <div className='cd-details'>
              <div className='row g-1'>
                <div className='col-4'>
                  <p>{t("deposit.order-no")}</p>
                </div>
                <div className='col-8'>
                  <p>
                    {this.props.txid}
                  </p>
                </div>

                <div className='col-4'>
                  <p>{t("deposit.time-created")}</p>
                </div>
                <div className='col-8'>
                  {/* <p>01/12/2022 21:00:03</p> */}
                  <p>{moment().format('YYYY-MM-DD hh:mm A')}</p>
                </div>

                <div className='col-4'>
                  <p>{t("deposit.deposit-amt")}</p>
                </div>
                <div className='col-8'>
                  <p>{this.props.currentUser.currency} {this.props.input.amount}</p>
                </div>

                <div className='col-4'>
                  <p>{t("deposit.deposit-method")}</p>
                </div>
                <div className='col-8'>
                  <p>{this.props.channel}</p>
                </div>

                <div className='col-4'>
                  <p>{t("deposit.exchange-rate")}</p>
                </div>
                <div className='col-8'>
                  <p>{this.props.exchangeRate}</p>
                </div>
                
                      {/* <div className='col-4'>
                        <p>Cash Back (1.00%)</p>
                      </div>
                      <div className='col-8'>
                        <p>{this.props.currentUser.currency} 2.00</p>
                      </div> */}
                    

                <div className='col-4'>
                  <p>{t("deposit.total-amt")}</p>
                </div>
                <div className='col-8'>
                  <p>{this.props.currentUser.currency} {this.props.input.amount}</p>
                </div>
              </div>

              <div className='cd-qr-code'>
                <div className='row'>
                  <div className='col-4'>
                    <p>{t("deposit.qr-code")}</p>
                  </div>
                  <div className='col-8'>
                    <QRCode
                      value={this.props.refCode}
                      id="r77-qr-code"
                      size={`${isDesktop ? "130" : "300"}`} />
                  </div>
                </div>
                {/* <div className='cd-ref-no'>
                  <p id="ref-no">{this.props.refCode}</p>
                  {
                    this.props.refCode &&
                    <button type="button" className="btn icon"
                      data-tip={t('global.copied')} data-event='click2 focus'
                      onClick={() => { this.selectText('ref-no') }}
                    >
                      {
                        isDesktop ? (
                          <>
                            <Icon icon="mdi:content-copy" />
                            <ReactTooltip globalEventOff='click2' className="custom-tooltip" />
                          </>

                        ) : (
                          <>
                            <span>{t("deposit.copy")}</span>
                            <ReactTooltip globalEventOff='click2' className="custom-tooltip" />
                          </>

                        )
                      }
                    </button>
                  }
                </div> */}
              </div>

              <div className='row g-2'>
              <div className="input-group cd-copy">
                <span className="input-group-text left-icon ">
                {t("deposit.pay-address")}
                </span>
                    <textarea
                      ref={this.linkRef}
                      type="text"
                      className="form-control"
                      name="referCode"
                      value={this.props.refCode}
                      readOnly
                    />
                    <span className="input-group-text right-icon">
                      <button type="button" className="btn icon" onClick={(event) => this.copyToClipboardHandler(event)}>
                        <Icon icon="mdi:content-copy" />
                      </button>
                    </span>
                    <small className='text-danger'><Icon icon="akar-icons:circle-alert-fill" />{t("deposit.alert")}</small>
              </div>
                {/* <div className='col-4'>
                  <p>{t("deposit.deposit-method")}</p>
                </div>
                <div className='col-8'>
                  <p>{this.props.channel}</p>
                </div>

                <div className='col-4'>
                  <p>{t("deposit.exchange-rate")}</p>
                </div>
                <div className='col-8'>
                  <p>{this.props.exchangeRate}</p>
                </div>

                <div className='col-4 d-flex align-items-center'>
                  <p>{t("deposit.usdt-amount", { CRYPTO: this.props.type.toUpperCase() })}</p>
                </div>
                <div className='col-8'>
                  <p>{this.props.usdtAmt}</p>
                </div> */}
                <div className="input-group cd-copy">
                <span className="input-group-text left-icon ">
                <p>{t("deposit.usdt-amount", { CRYPTO: this.props.type.toUpperCase() })}</p>
                </span>
                <input
                      ref={this.ref}
                      type="text"
                      className="form-control"
                      name="ustdAmt"
                      value={this.props.usdtAmt}
                      readOnly
                    />
                    <span className="input-group-text right-icon">
                      <button type="button" className="btn icon" onClick={(event) => this.copyUstdAmount(event)}>
                        <Icon icon="mdi:content-copy" />
                      </button>
                    </span>
              </div>

              </div>

              <div className='cd-tnc'>
                <p className='mb-1'>{t("deposit.tnc")}</p>
                <ol>
                  <li>{t("deposit.tnc-1")}</li>
                  <li>{t("deposit.tnc-2", { ADDRESS: this.props.refCode })}</li>
                  <li>{t("deposit.tnc-3")}</li>
                </ol>
              </div>


            </div>
            {
              isDesktop && this.props.cryptoPayment !== 'reject' ? (
                <button className='btn btn-gradient-blue w-100' onClick={this.props.toggleCryptoDepositModalHandler}>{t("global.ok")}</button>
              ) : isDesktop && this.props.cryptoPayment === 'reject' && (
                <button className='btn btn-gradient-blue w-100' onClick={this.props.toggleCryptoDepositModalHandler}>{t("deposit.try-again")}</button>
              )
            }
          </div>

          {!isDesktop && this.props.cryptoPayment !== 'reject' ? (
            <button type="button" className="btn btn-white" onClick={this.props.toggleCryptoDepositModalHandler} >{t("global.ok")}</button>
          ) : !isDesktop && this.props.cryptoPayment === 'reject' && (
            <button type="button" className="btn btn-white" onClick={this.props.toggleCryptoDepositModalHandler} >{t("deposit.try-again")}</button>
          )
          }
          {
            !isDesktop && this.props.cryptoPayment === 'reject' &&
            <button type="button" className="btn btn-gray" onClick={this.props.toggleCryptoDepositModalHandler}>{t("history.cancel")}</button>
          }
        </div>
      </Modal>


    )
  }
}

export default (withNamespaces("translation")(CryptoDepositModal));