import * as type from './types';
import { createReducer } from "../../util/index";

const initialState = {
    data: [],
};

const x33MallReducer = createReducer(initialState) ({
    // Export the response to the react.js to get the response data based on type name
    [type.REWARD_LIST]: (state = initialState, action) => ({
        ...state,
        reward_data: action.payload,
    }),
    [type.FAVORITE_LIST]: (state = initialState, action) => ({
        ...state,
        favorite_data: action.payload,
    }),
    [type.FAVORITE]: (state, action) => ({
        ...state,
        fave: action.payload,
    }),
    [type.CART_LIST]: (state = initialState, action) => ({
        ...state,
        cart_data: action.payload,
    }),
    [type.CART_ADD]: (state = initialState, action) => ({
        ...state,
        cart_add: action.payload,
    }),
    [type.CART_UPDATE]: (state = initialState, action) => ({
        ...state,
        cart_update: action.payload,
    }),
    [type.CHECKOUT]: (state = initialState, action) => ({
        ...state,
        checkout_data: action.payload,
    }),
});

export default x33MallReducer;
