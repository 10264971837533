import axios from "../../../common/config/axios";
import * as type from "./types";

export const getTopProfile = () => (dispatch) => {
    axios
        .get("profile/get_top_profile")
        .then((res) => {
            dispatch({
                type: type.GET_TOP_PROFILE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Top Profile Error", err);
        });
};

export const getWalletBalance = () => (dispatch) => {
    axios
        .get("profile/get_wallet_balance")
        .then((res) => {
            // console.log(res.data.data.walletBalance.balance);
            dispatch({
                type: type.GET_WALLET_BALANCE,
                payload: res.data.data.walletBalance.balance,
            });
        })
        .catch((err) => {
            console.log("Get Wallet Balance Error", err);
        });
};

export const getMemberProfile = () => (dispatch) => {
    axios
        .get("profile/get_member_profile")
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_PROFILE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Member Profile Error", err);
        });
};

export const updateMemberProfile = (data) => (dispatch) => {
    axios
        .post("profile/update_member_profile", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_MEMBER_PROFILE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Member Profile Error" + err);
        });
};

export const getMemberBank = () => (dispatch) => {
    axios
        .get("profile/get_member_bank")
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_BANK,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Member Bank Error", err);
        });
};

export const addMemberBank = (data) => (dispatch) => {
    axios
        .post("profile/add_member_bank", data)
        .then((res) => {
            dispatch({
                type: type.ADD_MEMBER_BANK,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Add Member Bank Error" + err);
        });
};

export const editMemberBank = (data) => (dispatch) => {
    axios
        .post("profile/update_member_bank", data)
        .then((res) => {
            dispatch({
                type: type.EDIT_MEMBER_BANK,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Add Member Bank Error" + err);
        });
};

export const removeMemberBank = (data) => (dispatch) => {
    axios
        .post("profile/remove_member_bank", data)
        .then((res) => {
            dispatch({
                type: type.REMOVE_MEMBER_BANK,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Remove Member Bank Error" + err);
        });
};

export const getMemberReferral = () => (dispatch) => {
    axios
        .get("profile/get_member_referral")
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_REFERRAL,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Member Referral Error", err);
        });
};

export const getMemberReferralBonus = (data) => (dispatch) => {
    axios
        .get("profile/get_member_referral_bonus", { params: data })
        .then((res) => {
            dispatch({
                type: type.GET_MEMBER_REFERRAL_BONUS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Member Referral Bonus Error", err);
        });
};

export const updateMemberPassword = (data) => (dispatch) => {
    axios
        .post("profile/update_member_password", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_MEMBER_PASSWORD,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Change Member password Error" + err);
        });
};

export const generateSMSOTP = (data) => (dispatch) => {
    axios
        .post("sms/generateOTP", data)
        .then((res) => {
            dispatch({
                type: type.GENERATE_SMS_OTP,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Generate SMS OTP Error" + err);
        });
};

export const verifySMSOTP = (data) => (dispatch) => {
    axios
        .post("sms/verifyOTP", data)
        .then((res) => {
            dispatch({
                type: type.VERFIY_SMS_OTP,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Verify SMS OTP Error" + err);
        });
};

export const resendOTP = (data) => (dispatch) => {
    axios
        .post("sms/resendOTP", data)
        .then((res) => {
            dispatch({
                type: type.RESEND_OTP,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Resend OTP Error" + err);
        });
};

export const getAvailableCryptoList = () => (dispatch) => {
    axios
        .get("profile/get_available_crypto_list")
        .then((res) => {
            dispatch({
                type: type.GET_AVAILABLE_CRYPTO_LIST,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Available Crypto List Error", err);
        });
};

export const socialMediaUserVerification = (data) => (dispatch) => {
    axios
        .post("profile/social_media_verification", data)
        .then((res) => {
            dispatch({
                type: type.SOCIAL_MEDIA_USER_VERIFICATION,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Social Media User Verification Error" + err);
        });
};

export const generateEmailOTP = (data) => (dispatch) => {
    axios
        .post("email/generateOTP", data)
        .then((res) => {
            dispatch({
                type: type.GENERATE_EMAIL_OTP,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Generate Email OTP Error" + err);
        });
};

export const verifyEmailOTP = (data) => (dispatch) => {
    axios
        .post("email/verifyOTP", data)
        .then((res) => {
            dispatch({
                type: type.VERIFY_EMAIL_OTP,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Verify Email OTP Error" + err);
        });
};

export const resendEmailOTP = (data) => (dispatch) => {
    axios
        .post("email/resendOTP", data)
        .then((res) => {
            dispatch({
                type: type.RESEND_EMAIL_OTP,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Resend Email OTP Error" + err);
        });
};

export const updateEmail = (data) => (dispatch) => {
    axios
        .post("profile/update_email", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_MEMBER_EMAIL,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("update Member Email Error" + err);
        });
};

export const updateContactNo = (data) => (dispatch) => {
    axios
        .post("profile/update_contact_no", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_MEMBER_CONTACT_NO,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("update Member contact no Error" + err);
        });
};

export const getMemberCryptoAddress = () => (dispatch) => {
    axios
        .get("profile/get_member_crypto_address")
        .then((res) => {
            dispatch({
                type: type.GET_CRYPTO_ADDRESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Member Crypto Address Error", err);
        });
};

export const removeMemberCryptoAddress = (data) => (dispatch) => {
    axios
        .post("profile/remove_crypto_address", data)
        .then((res) => {
            dispatch({
                type: type.REMOVE_CRYPTO_ADDRESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Remove Member Crypto Address Error" + err);
        });
};

export const redirectGoogle = (data) => (dispatch) => {
    axios
        .post("google/google_redirect", data)
        .then((res) => {
            dispatch({
                type: type.GOOGLE_REDIRECT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Google Redirect" + err);
        });
};

export const unlinkGoogle = (data) => (dispatch) => {
    axios
        .post("google/verifyOTP", data)
        .then((res) => {
            dispatch({
                type: type.UNLINK_GOOGLE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Unlink Google" + err);
        });
}

export const setSecurityPin = (data) => (dispatch) => {
    axios
        .post("profile/set_security_pin", data)
        .then((res) => {
            dispatch({
                type: type.SET_SECURITY_PIN,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Set Security Pin Error" + err);
        });
};

export const verifySecurityPin = (data) => (dispatch) => {
    axios
        .post("profile/verify_security_pin", data)
        .then((res) => {
            dispatch({
                type: type.VERIFY_SECURITY_PIN,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Verify Security Pin Error" + err);
        });
};

export const updateSecurityPin = (data) => (dispatch) => {
    axios
        .post("profile/update_security_pin", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_SECURITY_PIN,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Security Pin Error" + err);
        });
};

export const checkSecurityPinAttempts = (data) => (dispatch) => {
    axios
        .post("withdrawal/check_security_pin_attempts", data)
        .then((res) => {
            dispatch({
                type: type.CHECK_SECURITY_PIN_ATTEMPTS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Check Security Pin Attempts Error" + err);
        });
};

export const updateProfilePicture = (data) => (dispatch) => {
    axios
        .post("profile/update_profile_picture", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_PROFILE_PICTURE,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Profile Picture Error" + err);
        });
};

export const unlinkTelegram = (data) => (dispatch) => {
    axios
        .post("telegram/unlinkTelegram", data)
        .then((res) => {
            dispatch({
                type: type.UNLINK_TELEGRAM,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Unlink Telegram" + err);
        });
}

export const updateMemberPopUp = (data) => (dispatch) => {
    axios
        .post("profile/update_pop_up", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_MEMBER_POP_UP,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Member Pop Up" + err);
        });
}

export const removeSecurityPin = (data) => (dispatch) => {
    axios
        .post("profile/remove_security_pin", data)
        .then((res) => {
            dispatch({
                type: type.REMOVE_SECURITY_PIN,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Remove Security Pin Error" + err);
        });
};

export const upgradeBusinessAccount = (data) => (dispatch) => {
    axios
        .post("profile/upgrade_business_account", data)
        .then((res) => {
            dispatch({
                type: type.UPGRADE_BUSINESS_ACCOUNT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Upgrade Business Account Error" + err);
        });
};

export const getBusinessAccount = () => (dispatch) => {
    axios
        .get("profile/get_business_account")
        .then((res) => {
            dispatch({
                type: type.GET_BUSINESS_ACCOUNT,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Business Account Error", err);
        });
};

export const getInterest = () => (dispatch) => {
    axios
        .get("profile/get_interest")
        .then((res) => {
            dispatch({
                type: type.GET_INTEREST,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Get Interest Error", err);
        });
};

export const updateMemberWithdrawalAddress = (data) => (dispatch) => {
    axios
        .post("profile/update_crypto_address", data)
        .then((res) => {
            dispatch({
                type: type.UPDATE_CRYPTO_ADDRESS,
                payload: res.data,
            });
        })
        .catch((err) => {
            console.log("Update Crypto Address Error" + err);
        });
};

export const updateMemberAddress = (data) => (dispatch) => {
    axios
    .post("profile/update_member_address", data)
    .then((res) => {
        dispatch({
            type: type.UPDATE_MEMBER_ADDRESS,
            payload: res.data,
        });
    })
    .catch((err) => {
        console.log("Update Member Address Error" + err);
    });
};