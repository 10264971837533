import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "@iconify/react";
import { withNamespaces } from "react-i18next";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";

import NumberInput from "../Input/NumberInput/NumberInput";
import Button from "../Button/Button";
import MobileSelectRadio from "../MobileAsset/MobileSelectRadio/MobileSelectRadio";
import MobileSubHeader from "../PageHeader/MobileSubHeader";

import { addWithdrawalCryptoAddress, getWithdrawalCryptoAddress, getDropDown } from "../../store/ducks/withdrawal/actions";

export class MobileAddCrypto extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            crypto: "",
            network: "",
            withdrawalAddress: "",

            errorMsg: [],
            formError: false,
            showSelectRadio: false,
        };
    }

    componentDidMount() { }

    shouldComponentUpdate(nextProps, nextState) {
        const { t } = this.props;
        if (nextProps.store_withdrawal_crypto_address !== this.props.store_withdrawal_crypto_address) {
            if (nextProps.store_withdrawal_crypto_address.responseCode === 200) {
                Swal.fire({
                    iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
                    title: t("global.success"),
                    html: t("global.record-added"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: {
                        container: "swal-mobile-container sucess",
                        icon: "swal-success-icon",
                    },
                }).then((result) => {
                    if (result.isConfirmed) {
                        this.props.searchHandler();
                        this.closeAddCryptoHandler();
                    }
                });
            } else {
                // clearFormValidation("add_bank_form");
                if (nextProps.store_withdrawal_crypto_address.message.input_error) {
                    this.setState({
                        errorMsg: nextProps.store_withdrawal_crypto_address.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                } else {
                    let msg = null;
                    msg = "<ul>";
                    nextProps.store_withdrawal_crypto_address.message.forEach((val) => {
                        msg += "<li>" + val + "</li>";
                    });
                    msg += "</ul>";
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        },
                    });
                }
            }
        }
        return true;
    }

    closeAddBankHandler = () => {
        this.props.toggleAddCryptoHandler();
        this.searchHandler();
    };

    searchHandler = () => {
        this.setState({
            loading: true,
        });
        this.props.funcGetDropDown();
    };

    handleCryptoChange = (event) => {
        this.setState({
            crypto: event.icon,
            network: event.label,
            errorMsg: [],
        });
    };

    addCryptoWithdrawalAddressHandler = (event) => {
        event.preventDefault();
        const { t } = this.props;

        let network = { network: t("crypto-address-details.network-required") };
        let withdrawal_address = { withdrawal_address: t("crypto-address-details.address-required") };
        if (!this.state.network) {
            this.setState({
                errorMsg: network,
                formError: true,
            });
        } else if (!this.state.withdrawalAddress) {
            this.setState({
                errorMsg: withdrawal_address,
                formError: true,
            });
        } else {
            Swal.fire({
                iconHtml: '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">',
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: t("global.save"),
                cancelButtonText: t("global.cancel"),
                customClass: {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                },
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        title: "",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    let data = {};
                    data.crypto = this.state.crypto.toUpperCase();
                    data.network = this.state.network;
                    data.withdrawal_address = this.state.withdrawalAddress;

                    this.props.funcStoreWithdrawalCryptoAddress(data);
                }
            });
        }
    };

    onInputChangeHandler = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            errorMsg: [],
            formError: false,
        });
    };

    closeAddCryptoHandler = () => {
        this.props.toggleAddCryptoHandler();

        let data = {};
        data.crypto = this.state.crypto.toUpperCase();
        this.props.funcGetDropDown(data);
    };

    toggleSelectRadio = () => {
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() {
        const { t } = this.props;

        const cryptoImg = this.imageImport(require.context("../../assets/images/profile/crypto", false, /\.(png|jpe?g|svg)$/));

        let cryptoChannel = [
            // { id: 21, label: "ERC20-USDT", type: "usdt" },
            { id: 20, label: "TRC20-USDT", type: "usdt", icon: "usdt" },
            { id: 22, label: "TRC20-USDC", type: "usdc", icon: "usdc" },
            // { id: 23, label: "ERC20-USDC", type: "usdc" },
        ];

        return (
            <Auxiliary>
                <section className={`pf-m-dashboard on-top remain-w ${this.props.showAddNewCrypto ? "d-block pe-auto" : "d-none"}`}>
                    <MobileSubHeader
                        // className="bg-white"
                        closeModalHandler={this.closeAddCryptoHandler}
                        header={t("crypto-address-details.add-new-crypto-acc")}
                        style={{ justifyContent: "unset" }}
                    />

                    <form method="post" id="add_bank_form" className="forms-col needs-validation" onSubmit={this.addCryptoWithdrawalAddressHandler} noValidate>
                        <div className="pd-form-m-col">
                            {this.state.loading ? (
                                <div className="input-group-col">
                                    <Skeleton count={1} height={100} />
                                </div>
                            ) : (
                                <>
                                    <div>
                                        <label className="col-form-label">{t("crypto-address-details.network")}</label>
                                        <div className="input-group-col">
                                            <div className="input-group right has-validation" onClick={this.toggleSelectRadio}>
                                                {this.state.crypto && <img src={cryptoImg[`${this.state.crypto}.svg`]} alt="crypto icon" />}
                                                <p className={`form-control lh-lg ${this.state.errorMsg?.network ? "is-invalid" : ""}`}>
                                                    {this.state.network ? this.state.network : t("crypto-address-details.select-network")}
                                                </p>
                                                <span className="input-group-text">
                                                    <Icon icon="uil:angle-right" />
                                                </span>
                                                <div className="invalid-feedback">{this.state.formError && this.state.errorMsg.network}</div>
                                            </div>
                                            <MobileSelectRadio
                                                name={"crypto_wallet"}
                                                showSelectRadio={this.state.showSelectRadio}
                                                toggleHandler={this.toggleSelectRadio}
                                                onChange={this.handleCryptoChange}
                                                options={cryptoChannel}
                                            // value={this.state.bank_key}
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <label className="col-form-label">{t("crypto-address-details.withdrawal-address")}</label>
                                        <div className="input-group-col">
                                            <NumberInput
                                                id="withdrawalAddress"
                                                name="withdrawalAddress"
                                                className={`${this.state.errorMsg.withdrawal_address ? "was-validated is-invalid" : ""}`}
                                                value={this.state.withdrawalAddress}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                required={true}
                                                requiredMessage={t("validation.required")}
                                                formError={this.state.formError}
                                                errorMsg={this.state.errorMsg?.withdrawal_address || ""}
                                            />
                                        </div>
                                    </div>
                                    {/* <div>
                                        <label className="col-form-label">OPT</label>
                                        <div className="input-group-col">
                                            <div className="d-flex align-items-center">
                                                <NumberInput
                                                    id="withdrawalAddress"
                                                    name="withdrawalAddress"
                                                    className={`${this.state.errorMsg.withdrawal_address ? "was-validated is-invalid" : ""}`}
                                                    value={this.state.withdrawalAddress}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    required={true}
                                                    requiredMessage={t("validation.required")}
                                                    formError={this.state.formError}
                                                    errorMsg={this.state.errorMsg?.withdrawal_address || ""}
                                                />
                                                <Button
                                                    typeButton="button"
                                                    clicked={this.props.withdrawalMaxHandler}
                                                    classValue="btn btn-gradient-blue ms-2"
                                                    style={{ minWidth: "unset" }}
                                                    buttonName={t("withdraw.retransmit")}
                                                />
                                            </div>

                                        </div>
                                    </div> */}
                                    <Button typeButton="submit" classValue={`btn btn-gradient-blue mx-3`} idValue="submit" buttonName={t("global.submit")} />
                                </>
                            )}
                        </div>
                    </form>
                </section>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { withdrawal } = state;

    return {
        store_withdrawal_crypto_address: withdrawal.store_withdrawal_crypto_address,
        get_withdrawal_crypto_address: withdrawal.get_withdrawal_crypto_address,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        funcStoreWithdrawalCryptoAddress: (data) => dispatch(addWithdrawalCryptoAddress(data)),
        getWithdrawalCryptoAddress: (data) => dispatch(getWithdrawalCryptoAddress(data)),
        funcGetDropDown: () => dispatch(getDropDown())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileAddCrypto));
