import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import { connect } from 'react-redux';
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import Slider from "react-slick";
import { Icon } from "@iconify/react";
import { Fade } from "react-awesome-reveal";
import Modal from "react-modal";
import { isDesktop } from 'react-device-detect';
import moment from "moment-timezone";
import Confetti from 'react-confetti';
import Timer from "react-compound-timer";

import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Button from "../../components/Button/Button";
import MobileFifaPremierLeague from './MobileFifaPremierLeague';
import LoadingPage from "../../components/LoadingPage/LoadingPage";

import { getUserData } from "../../store/ducks/currentUser/actions";
import * as actions from "../../store/ducks/fifaWorldCup/actions";

class FifaPremierLeague extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            fistLoading: true,
            loading: true,
            openModal: '',
            preWinCount: 0,
            dropDown:
            {
                dateList: [],
                matchOrg: [],
                match: [],
                month: [],
                reward: [],
            },
            input:
            {
                date: "",
                slideCount: 0,
                matchArr: [],
                matchBetArr: [],
                yearMth: "",
                month: "",
            },
            totalMatch: [],
        };
        this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
    }

    componentDidMount() 
    {
        // const currentDate = new Date();
        // const year = currentDate.getFullYear();    // 4-digit year (e.g., 2023)
        // const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        // let yearMth = year+"-"+month;
        // yearMth = (yearMth <= "2023-08") ? "2023-08" : yearMth;
        this.props.funcGetDropDown(4); // 4 = fifa premier league cup 2024 league id
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;
        if (nextProps.listing !== this.props.listing && nextProps.listing !== undefined) 
        {
            if (nextProps.listing.responseCode === 200 && nextProps.listing.msgType === "success") 
            {   
                let tmpArr = [];
                let totalMatch = [];
                let totalMatchVal = [];
                let totalMatchCount = [];
                let dateList = [];
                let match = [];
                let dateTime = [];
                let newInput = Object.assign({}, this.state.input);
                let openModal = false;
                let preWinCount = 0;
                let sliderCount = 0;
                const currentUserTimeZone = this.props.currentUser.time_zone || "Asia/Kuala_Lumpur";
                const now = moment(moment().tz(currentUserTimeZone).format('YYYY-MM-DD HH:mm:ss'));
                const currentDate = new Date();
                const year = currentDate.getFullYear();    // 4-digit year (e.g., 2023)
                const months = String(currentDate.getMonth() + 1).padStart(2, '0');
                let yearMth = year+"-"+months;

                // newInput['month'] = nextProps.listing.data.month[0].date; //initial date
                if (nextProps.listing.data.month && nextProps.listing.data.month.length > 0) {
                    newInput['month'] = nextProps.listing.data.month[0].date;
                } else {
                    newInput['month'] = ""
                }
                // console.log( 'new Date : ' + moment(newInput["date"]).format('YYYY-MM') + ' | new Mth : ' + newInput["yearMth"] + ' | ' + yearMth + ' | ' + nextProps.listing.data.month[0].date);
                if( ( moment(newInput["date"]).format('YYYY-MM') !== newInput["yearMth"]) && yearMth !== newInput["yearMth"] && newInput["date"] !== undefined){
                    newInput['month'] = yearMth;
                }

                let newMatch = nextProps.listing.data.match.filter((item) => item.year_mth === newInput['month']);
                
                newMatch.forEach((val) => 
                {
                    dateTime = val.match_date.split(' ');
                    let momentDateTime = moment(val.match_date).tz(currentUserTimeZone);
                    let duration = momentDateTime.diff(now, "milliseconds");
                    let labelDate = dateTime[0].split('-');
                    let labelTime = dateTime[1].split(':');
                    let day = moment(dateTime[0]).format('ddd').toString().toLowerCase();
                    let labelDay = val.match_day;
                    let dateObj = new Date(dateTime[0]);
                    let yearMth = dateObj.getFullYear() + "-" + (dateObj.getMonth()+1).toString().padStart(2, '0');
                    let mid = val.id;
                    
                    if (tmpArr[dateTime[0]] === undefined) 
                    {
                        dateList.push({
                            name: dateTime[0], 
                            label: `${labelDate[0]}${t('premier-league.year')}${labelDate[1]}${t('premier-league.month')}${labelDate[2]}${t('premier-league.day')}`,
                            labelDay: `${t('premier-league.'+labelDay)}`,
                            labelYearMonth: `${labelDate[0]}${t('premier-league.year')} ${labelDate[1]}${t('premier-league.months')}`,
                            labelDate: `${labelDate[2]}`,
                            status: (duration < 0) ? 'deactive' : ((duration === 0) ? 'active' : 'pending'),  //deactive, active, pending
                            annouce: (duration < 0) ? t('premier-league.end') : ((duration === 0) ? t('premier-league.running') : t('premier-league.early')),
                            yearMth : yearMth,
                            mid: mid,
                        });

                        tmpArr[dateTime[0]] = dateTime[0];
                        // newInput["date"] = (duration === 0) ? dateTime[0] : ((this.state.input.date !== '') ? this.state.input.date : ((newInput["date"] === "" && duration > 0) ? dateTime[0] : ''));
                        
                        if(duration === 0)
                        {
                            newInput["date"] = dateTime[0];
                            newInput["yearMth"] = yearMth;
                        }
                        else
                        {
                            if(this.state.input.date !== '')
                            {
                                newInput["date"] = this.state.input.date;
                                newInput["yearMth"] = this.state.input.yearMth;
                            }
                            else
                            {
                                if(newInput["date"] === "" && duration > 0)
                                {
                                    newInput["date"] = dateTime[0];
                                    newInput["yearMth"] = yearMth;
                                }
                            }
                        }
                        
                        sliderCount++;
                        if(newInput["date"] !=='' && newInput["date"] === dateTime[0])
                        {
                            newInput["slideCount"] = ((sliderCount - 4) > 0) ? (sliderCount - 4) : 0;
                        }
                    }
                    
                    totalMatchCount[dateTime[0]] = (totalMatchCount[dateTime[0]] === undefined) ? 1 : totalMatchCount[dateTime[0]]+1;
                    totalMatchVal = {
                        count: totalMatchCount[dateTime[0]],
                        disabled: (duration <= 0) ? true : false,
                        totalWinCount: 0,
                        totalWinAmt: 0,
                        slug: val.match_slug,
                        firstMatchDate: (totalMatch[dateTime[0]] && totalMatch[dateTime[0]].firstMatchDate) ? totalMatch[dateTime[0]].firstMatchDate : val.match_date,
                    }
                    totalMatch[dateTime[0]] = totalMatchVal;
                    
                    match.push({
                        id: val.id,
                        date: dateTime[0],
                        dateTime: val.match_date,
                        // label: `${labelDate[0]}-${labelDate[1]}-${labelDate[2]} ${t(`premier-league.${day}`)} ${labelTime[0]}:${labelTime[1]}`,
                        label: `${labelTime[0]}:${labelTime[1]}`,
                        labelDay: `${t('premier-league.'+labelDay)}`,
                        labelYearMonth: `${labelDate[0]}${t('premier-league.year')} ${labelDate[1]}${t('premier-league.months')}`,
                        labelDate: `${labelDate[2]}`,
                        teamAId: val.team_a_id,
                        teamBId: val.team_b_id,
                        teamA: val.team_a,
                        teamB: val.team_b,
                        teamAScore: val.team_a_score,
                        teamBScore: val.team_b_score,
                        teamAExtraScore: val.team_a_extra_score,
                        teamBExtraScore: val.team_b_extra_score,
                        isExtra: val.is_extra,
                    });
                });

                newInput["date"] = (newInput["date"] !== "") ? newInput["date"] : dateTime[0];
                newInput["yearMth"] = (newInput["yearMth"] !== "") ? newInput["yearMth"] : yearMth;
               
                if(nextProps.listing.data.matchBet && nextProps.listing.data.matchBet.length > 0)
                {
                    nextProps.listing.data.matchBet.forEach((val) => 
                    {
                        newInput['matchBetArr'][val.match_id] = val.team_id;
                        if (!totalMatch[val.match_date]) 
                        {
                            totalMatch[val.match_date] = {};
                        }
                        totalMatch[val.match_date].disabled = true;
                        if(val.transaction_id !== null && val.transaction_id > 0)
                        {
                            totalMatch[val.match_date].totalWinCount = totalMatch[val.match_date].totalWinCount + 1;
                            totalMatch[val.match_date].totalWinAmt = totalMatch[val.match_date].totalWinAmt + 8;
                            openModal = (nextState.openModal === false) ? false : true;
                            preWinCount = totalMatch[val.match_date].totalWinCount;
                        }
                    });
                }
                // openModal = (nextState.openModal && nextState.openModal !== '') ? false : true;
                // console.log(newInput, totalMatch, dateList, match);
                
                this.setState({
                    openModal,
                    preWinCount,
                    input: newInput,
                    totalMatch,
                    dropDown: 
                    {
                        dateList,
                        matchOrg: nextProps.listing.data.match,
                        match,
                        month: nextProps.listing.data.month,
                        reward: nextProps.listing.data.reward,
                    },
                    loading: false,
                    fistLoading: false,
                });
            }
        }
        
        if (nextProps.store !== this.props.store && nextProps.store !== undefined) 
        {
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === "success") 
            {
                Swal.fire({
                    iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
                    icon: "success",
                    title: t("global.success"),
                    showConfirmButton: true,
                    customClass: !isDesktop
                        ? {
                              container: "swal-mobile-container sucess",
                              icon: "swal-success-icon",
                          }
                        : [],
                }).then(() => 
                {
                    this.props.funcGetDropDown(4);
                });
            }
            else 
            {
                this.swalErrorHandler(nextProps.store.message[0]);
            }
        }
        
        return true;
    }

    onInputChangeHandler = (event) => 
    {
        // console.log(event.target.name+' | '+event.target.value);
        let matchTeamArr = event.target.value.split('_');
        let newInput = Object.assign({}, this.state.input);
        newInput['matchArr'][matchTeamArr[0]] = matchTeamArr[1];

        this.setState({
            input: newInput,
        });
    }

    closeModal = () => 
    {
        this.setState({
            openModal: false,
        });
    }

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item) => 
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    changeDateHandler = (date, month) => 
    {
        let input = Object.assign({}, this.state.input);
        let matchOrg = Object.assign([], this.state.dropDown.matchOrg);
        let dateObj = new Date(date);
        // let dateList = this.state.dropDown.dateList ? this.state.dropDown.dateList : [];
        let dateList = [];
        input['yearMth'] = dateObj.getFullYear() + "-" + (dateObj.getMonth()+1).toString().padStart(2, '0');
        input['date'] = date;
        input['matchArr'] = [];
        input['month'] = month === undefined ? this.state.input.month : month; 
        let newMatch = matchOrg.filter((item) => item.year_mth === input['month']); //2023-09
        let dateTime = [];
        const currentUserTimeZone = this.props.currentUser.time_zone || "Asia/Kuala_Lumpur";
        const now = moment(moment().tz(currentUserTimeZone).format('YYYY-MM-DD HH:mm:ss'));
        let tmpArr = [];
        let totalMatchCount = [];
        let totalMatchVal = [];
        let totalMatch = [];
        let match = [];
        const { t } = this.props;
        let newInput = Object.assign({}, this.state.input);
        let sliderCount = 0;
        let openModal = false;
        let preWinCount = 0;
        newInput['matchArr'] = [];

        newMatch.forEach((val) => 
        {
            dateTime = val.match_date.split(' ');
            let momentDateTime = moment(val.match_date).tz(currentUserTimeZone);
            let duration = momentDateTime.diff(now, "milliseconds");
            let labelDate = dateTime[0].split('-');
            let labelTime = dateTime[1].split(':');
            let day = moment(dateTime[0]).format('ddd').toString().toLowerCase();
            let labelDay = val.match_day;
            let dateObj = new Date(dateTime[0]);
            let yearMth = dateObj.getFullYear() + "-" + (dateObj.getMonth()+1).toString().padStart(2, '0');
            let mid = val.id;
            
            if (tmpArr[dateTime[0]] === undefined) 
            {
                dateList.push({
                    name: dateTime[0], 
                    label: `${labelDate[0]}${t('premier-league.year')}${labelDate[1]}${t('premier-league.month')}${labelDate[2]}${t('premier-league.day')}`,
                    labelDay: `${t('premier-league.'+labelDay)}`,
                    labelYearMonth: `${labelDate[0]}${t('premier-league.year')} ${labelDate[1]}${t('premier-league.months')}`,
                    labelDate: `${labelDate[2]}`,
                    status: (duration < 0) ? 'deactive' : ((duration === 0) ? 'active' : 'pending'),  //deactive, active, pending
                    annouce: (duration < 0) ? t('premier-league.end') : ((duration === 0) ? t('premier-league.running') : t('premier-league.early')),
                    yearMth : yearMth,
                    mid: mid,
                });

                let newDate = dateList[0].name;
                let newYearMth = dateList[0].yearMth;

                tmpArr[dateTime[0]] = dateTime[0];
                
                if(duration === 0)
                {
                    newInput["date"] = dateTime[0] !== newDate ? newDate : dateTime[0];
                    newInput["yearMth"] = yearMth !== newYearMth ? newYearMth : yearMth;
                }
                else
                {
                    if(this.state.input.date !== '')
                    {
                        if(month === undefined){
                            newInput["date"] = date;
                            newInput["yearMth"] = yearMth;
                        }else{
                            newInput["date"] = this.state.input.date !== newDate ? newDate : this.state.input.date;
                            newInput["yearMth"] = this.state.input.yearMth !== newYearMth ? newYearMth : this.state.input.yearMth;
                        }
                        newInput["month"] = newYearMth;
                    }
                    else
                    {
                        if(newInput["date"] === "" && duration > 0)
                        {
                            newInput["date"] = dateTime[0] !== newDate ? newDate : dateTime[0];
                            newInput["yearMth"] = yearMth !== newYearMth ? newYearMth : yearMth;
                            newInput["month"] = newYearMth;
                        }
                    }
                }
                
                sliderCount++;
                if(newInput["date"] !=='' && newInput["date"] === dateTime[0])
                {
                    newInput["slideCount"] = ((sliderCount - 4) > 0) ? (sliderCount - 4) : 0;
                }
            }
            
            totalMatchCount[dateTime[0]] = (totalMatchCount[dateTime[0]] === undefined) ? 1 : totalMatchCount[dateTime[0]]+1;
            totalMatchVal = {
                count: totalMatchCount[dateTime[0]],
                disabled: (duration <= 0) ? true : false,
                totalWinCount: 0,
                totalWinAmt: 0,
                slug: val.match_slug,
                firstMatchDate: (totalMatch[dateTime[0]] && totalMatch[dateTime[0]].firstMatchDate) ? totalMatch[dateTime[0]].firstMatchDate : val.match_date,
            }
            totalMatch[dateTime[0]] = totalMatchVal;
            
            match.push({
                id: val.id,
                date: dateTime[0],
                dateTime: val.match_date,
                // label: `${labelDate[0]}-${labelDate[1]}-${labelDate[2]} ${t(`premier-league.${day}`)} ${labelTime[0]}:${labelTime[1]}`,
                label: `${labelTime[0]}:${labelTime[1]}`,
                labelDay: `${t('premier-league.'+labelDay)}`,
                labelYearMonth: `${labelDate[0]}${t('premier-league.year')} ${labelDate[1]}${t('premier-league.months')}`,
                labelDate: `${labelDate[2]}`,
                teamAId: val.team_a_id,
                teamBId: val.team_b_id,
                teamA: val.team_a,
                teamB: val.team_b,
                teamAScore: val.team_a_score,
                teamBScore: val.team_b_score,
                teamAExtraScore: val.team_a_extra_score,
                teamBExtraScore: val.team_b_extra_score,
                isExtra: val.is_extra,
            });

        });

        newInput["date"] = (newInput["date"] !== "") ? newInput["date"] : dateTime[0];
       
        if(this.props.listing.data.matchBet && this.props.listing.data.matchBet.length > 0)
        {
            this.props.listing.data.matchBet.forEach((val) => 
            {
                newInput['matchBetArr'][val.match_id] = val.team_id;
                if (!totalMatch[val.match_date]) 
                {
                    totalMatch[val.match_date] = {};
                }
                totalMatch[val.match_date].disabled = true;
                if(val.transaction_id !== null && val.transaction_id > 0)
                {
                    totalMatch[val.match_date].totalWinCount = totalMatch[val.match_date].totalWinCount + 1;
                    totalMatch[val.match_date].totalWinAmt = totalMatch[val.match_date].totalWinAmt + 8;
                    openModal = (this.state.openModal === false) ? false : true;
                    preWinCount = totalMatch[val.match_date].totalWinCount;
                }
            });
        }

        this.setState({
            openModal,
            preWinCount,
            input: newInput,
            totalMatch,
            dropDown: 
            {
                dateList,
                matchOrg: this.props.listing.data.match,
                match,
                month: this.props.listing.data.month,
                reward: this.props.listing.data.reward,
            },
            loading: true,
        }, () => 
        {
            this.setState({
                loading: false,
            });
        });

    }

    swalErrorHandler = (msg) => 
    {
        const { t } = this.props;
        Swal.fire({
            iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : '',
            title: t("global.error"),
            text: msg,
            icon: 'error',
            confirmButtonText: t('global.ok'),
            customClass: (!isDesktop) ? {
            container: "swal-mobile-container failed",
            icon: "swal-failed-icon",
            } : [],
        }).then(() => 
        {
            // let newTab = Object.assign({}, this.state[this.state.historyTab]);
            // newTab['loading'] = false;

            // this.setState({
            //     [this.state.historyTab]: newTab,
            //     disableSearchButton: false,
            // });
            // Swal.close();
        });
    }

    getDuration = (date) => 
    {
        const currentUserTimeZone = this.props.currentUser.time_zone || "Asia/Kuala_Lumpur";
        // let selectedDate = `${this.state.input.date} 00:00:00`;
        let firstMatchDate = this.state.totalMatch[this.state.input.date].firstMatchDate;
        let selectedDate = moment(firstMatchDate).tz(currentUserTimeZone);
        let now = moment(moment().tz(currentUserTimeZone).format('YYYY-MM-DD HH:mm:ss'));
        let duration = selectedDate.diff(now, "milliseconds");
        // console.log(selectedDate, now, duration);
        return (duration > 0) ? duration : 0;
    }

    submitHandler = (e) => 
	{
		e.preventDefault();
		const { t } = this.props;
        
        let newFilter = Object.assign({}, this.state.input.matchArr);
        let formData = new FormData();
        let totalMatch = 0;

        for (const key in newFilter)
        {
            formData.append('match['+key+']', newFilter[key]);
            totalMatch++;
        }

        // console.log(totalMatch+' | '+this.state.totalMatch[this.state.input.date].count);
        if (totalMatch === this.state.totalMatch[this.state.input.date].count)
        {
            Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : '',
            	title: t("global.confirmation"),
            	text: t("global.would-you-like-to-proceed") + "?",
            	icon: "warning",
            	showCancelButton: true,
            	confirmButtonText: t("global.save"),
            	cancelButtonText: t("global.cancel"),
                customClass: !isDesktop
                        ? {
                              container: "swal-mobile-container confirmation",
                              icon: "swal-confirmation-icon",
                          }
                        : [],
            }).then((result) => 
            {
            	if (result.isConfirmed) 
            	{
            		Swal.fire({
            			title: "",
            			allowOutsideClick: false,
            			allowEscapeKey: false,
            			allowEnterKey: false,
            			didOpen: () => 
                        {
            				Swal.showLoading();
            			},
            		});
                    
                    formData.append('date', this.state.input.date);
                    // for (var pair of formData.entries()) 
                    // {
                    //     console.log(pair[0]+ ' = ' + pair[1]);
                    // }
                    this.props.funcStore(formData);
            	}
            });
        }
	}

    beforeChange = (prev, next) => {
        this.setState({ index: next });
    };

    render() 
    {
        const { t } = this.props;
        const tNc = this.imageImport(require.context("../../assets/images/tnc", false, /\.(png|jpe?g|svg)$/));
        const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
        // const img = this.imageImport(require.context("../../assets/images/leaderboard", false, /\.(png|jpe?g|svg)$/));
        const image = this.imageImport(require.context("../../assets/images/world-cup", false, /\.(png|jpe?g|svg)$/));
        const flag = this.imageImport(require.context("../../assets/images/world-cup/epl-flag", false, /\.(png|jpe?g|svg)$/));

        let imageLanguage = '';
        switch (this.props.currentUser.language_code) 
        {
            case "zh-CN":
                imageLanguage = "-cn";
            break;
            case "ms":
              imageLanguage = "-bm"
              break;
              case "indon":
                imageLanguage = "-id"
                break;
            default:
                imageLanguage = "";
            break;
        }

        let settings = {
            dots: false,
            infinite: false,
            speed: 500,
            slidesToShow: 11,
            slidesToScroll: 1,
            swipeToSlide: false,
            initialSlide: (this.state.input.slideCount || 0),
            arrows: false,
            beforeChange: this.beforeChange
        };

        let dateList = this.state.dropDown.dateList ? this.state.dropDown.dateList : [];
        let month = this.state.dropDown.month ? this.state.dropDown.month : [];
        let match = this.state.dropDown.match ? this.state.dropDown.match : [];
        let fakeData = [];
        let tcDataTHead = [];
        let tcDataTBody = [];

        const updatedData = month.map(item => {
            let mthLabelDate = item.date.split('-');
            let mthLabelYearMonth = `${mthLabelDate[0]}${t('premier-league.year')} ${mthLabelDate[1]}${t('premier-league.months')}`;
            return {
                ...item,
                labelYearMonth: mthLabelYearMonth, 
            };
        });
        month = updatedData;
        
        if(this.state.totalMatch[this.state.input.date] && this.state.totalMatch[this.state.input.date].count > 0)
        {
            let fakeAmt = (this.state.dropDown.reward && this.state.dropDown.reward.prize) ? this.state.dropDown.reward.prize : 0;
            let fakeAmt2 = fakeAmt;
            let initialAmt = fakeAmt;
            let fakeCount = (this.state.totalMatch[this.state.input.date].count > 4) ? 4 : this.state.totalMatch[this.state.input.date].count;
            // let fakeCount = (this.state.totalMatch[this.state.input.date].count > 0) ? this.state.totalMatch[this.state.input.date].count : 0;
            for (let i = 1; i <= fakeCount; i++) 
            {
                fakeData.push(
                <div className={` wc-reward-container col-3`} key={i}>
                    <div className='wc-reward-header'>
                        <h3 className='m-0'>{t('premier-league.modal-msg', {attribute: i})}</h3>
                    </div>
                    <div>
                        <img src={image[`reward-${i}.png`]} alt="reward-1" />
                        <h4>{fakeAmt}</h4>
                    </div>
                </div>);
                fakeAmt = fakeAmt + initialAmt;

                tcDataTHead.push(
                    <th>{t('premier-league.guess', {attribute: i})}</th>
                );

                tcDataTBody.push(
                    <td>{fakeAmt2}</td>
                );
                fakeAmt2 = fakeAmt2 + initialAmt;


            }
        } 
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        let displayYear = (currentMonth >= 7) ?  `${currentYear}/${(currentYear + 1).toString().slice(-2)}` : `${currentYear - 1}/${currentYear.toString().slice(-2)}`;
        
        return (
            <Auxiliary>
                {this.state.fistLoading && <LoadingPage />}
                <Modal
                    isOpen={this.state.openModal}
                    closeTimeoutMS={500}
                    portalClassName={`global-modal wc-modal`}
                    className="modal modal-dialog modal-dialog-centered modal-pd"
                    ariaHideApp={false}
                    onRequestClose={this.closeModal}
                >
                    <div className={`modal-content ${isDesktop ? '' : 'mobile-modal-bg-white p-0'}`}>
                        <div className="modal-body">
                            <div className="ls-modal gap-3">
                                <div>
                                    <img src={image["reward-4.png"]} alt="award" />
                                    <h4>{t('premier-league.congrats-msg')}</h4>
                                    <label>{t('premier-league.congrats-msg-1', {attribute: this.state.preWinCount})}<br />{t('premier-league.congrats-msg-2')}</label>
                                </div>
                                <Button
                                    typeButton="button"
                                    classValue={`btn ${isDesktop ? "btn-gradient-blue" : "btn-white w-100 "}`}
                                    buttonName={t('global.confirm')}
                                    clicked={this.closeModal}
                                />
                            </div>
                            <Confetti
                                width={isDesktop ? 574 : 880}
                                height={isDesktop ? 339 : 448}
                                numberOfPieces={30}
                                opacity={0.3}
                            />
                        </div>
                    </div>
                </Modal>
                {
                    isDesktop ? (
                        <section className='pl-section'>
                            <div className="banner-col">
                                <div className="banner-item">
                                <img src={banner[`epl-banner${imageLanguage}.webp`]} alt="R77 Promos" />
                                </div>
                            </div>

                            <div className='x-container'>
                                <div className='section-header-gray mb-3'>
                                    <h2>{t('premier-league.title')}</h2>
                                    <p>{t('premier-league.title-1')}</p>
                                </div>

                                <div className='lb-section'>
                                    <div className='lb-event-date my-3'>
                                    {
                                        (this.state.input.month !== '') 
                                        ?
                                            month.length > 0 && month.map((item, index) => (
                                                (item.date === this.state.input.month)
                                                ? 
                                                    <>
                                                        <button className='btn btn-gradient-blue' onClick={() => this.changeDateHandler('', month[((index - 1) < 0) ? (month.length - 1) : (index - 1)].date )}>
                                                            <Icon icon="uil:angle-left" />
                                                        </button>
                                                        <div className='date'>
                                                            <span>{item.labelYearMonth}</span>
                                                        </div>
                                                        <button className='btn btn-gradient-blue' onClick={() => this.changeDateHandler('', month[((index + 1) === month.length) ? 0 : (index + 1)].date,  )}>
                                                            <Icon icon="uil:angle-right" />
                                                        </button>
                                                    </>
                                                : 
                                                    null
                                            ))
                                        :
                                            null
                                    }
                                    </div>
                                </div>
                                <div className='wc-category-cont'>
                                    <div className='slick-container'>
                                        {this.state.fistLoading ? (
                                            <Skeleton count={1} height={40} style={{ marginBottom: "1rem" }} />
                                        ) : (
                                            <Slider ref={c => (this.slider = c)} {...settings}>
                                                {dateList.length > 0 && dateList.map((item, index) => (
                                                    <>
                                                    <button type="button"
                                                        className={`lb-game ${this.state.input.date === item.name && 'active'}`}
                                                        onClick={() => this.changeDateHandler(item.name)}
                                                        key={index}
                                                    >
                                                        <small>{item.labelDay}</small>
                                                        <h1 className='mb-0'>{item.labelDate}</h1>
                                                        <p className={`status ${item.status}`}>{item.annouce}</p>
                                                    </button>
                                                    </>
                                                ))}
                                            </Slider>
                                        )}
                                        <div className="slick-arrow">
                                            <div className="slick-arrow-btn prev" onClick={() => this.slider.slickPrev()}>
                                                <Icon icon="uil:angle-left-b" />
                                            </div>
                                            <div className="slick-arrow-btn next" onClick={() => this.slider.slickNext()}>
                                                <Icon icon="uil:angle-right-b" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='wc-vs-section card-shadow wc-table mt-4'>
                                    <div className='section-header-gray'>
                                        <h3>
                                            {t('premier-league.title-2', 
                                                {
                                                    attribute: ((this.state.totalMatch[this.state.input.date] && this.state.totalMatch[this.state.input.date].slug && t(`premier-league.${this.state.totalMatch[this.state.input.date].slug}`)) || ''),
                                                    year: displayYear
                                                }
                                            )}
                                        </h3>
                                        <p className='event-date'>{t('premier-league.remain-time')}：
                                            {this.state.input.date && this.state.input.date !== '' && !this.state.loading && 
                                                <Timer 
                                                    initialTime={this.getDuration()}
                                                    direction="backward"
                                                    formatValue={(value) => `${(value < 10 ? `0${value}` : value)}`}
                                                >
                                                    <Timer.Days />{t('premier-league.d')} <Timer.Hours />{t('premier-league.h')} <Timer.Minutes />{t('premier-league.min')} <Timer.Seconds />{t('premier-league.s')}
                                                </Timer>
                                            }
                                        </p>
                                        <h4 className='mt-3'>
                                            {this.state.totalMatch[this.state.input.date] && !this.state.totalMatch[this.state.input.date].disabled &&
                                                (<>{t('premier-league.submit-msg', {attribute: (this.state.totalMatch[this.state.input.date] && this.state.totalMatch[this.state.input.date].count) || 0})}</>)
                                            }
                                        </h4>
                                    </div>

                                    <form className="" method="post" id="wc_form" encType='multipart/form-data' onSubmit={this.submitHandler} noValidate>
                                        {match.length > 0 &&
                                        <div className='wc-head row gx-4 gy-4 '>
                                            {match.map((item, index) => 
                                            {
                                                if(item.date === this.state.input.date)
                                                {
                                                    return (
                                                        <div className='wc-body' key={index}>
                                                            <Fade duration={1000} triggerOnce>
                                                                <div className='wc-content'>
                                                                    <div className='wc-time'> 
                                                                        <p>{t('premier-league.start-time')}：{item.label}</p>
                                                                        </div>
                                                                        <div className='wc-details'>
                                                                            <div className={`form-check form-check-inline`}>
                                                                                <input
                                                                                    className="btn-check"
                                                                                    type="radio"
                                                                                    name={`matchOpt${item.teamAId}`}
                                                                                    id={`matchOpt${item.teamAId}`}
                                                                                    value={`${item.id}_${item.teamAId}`}
                                                                                    onChange={
                                                                                        (this.state.totalMatch[this.state.input.date] && !this.state.totalMatch[this.state.input.date].disabled)
                                                                                        ?
                                                                                        (event) => this.onInputChangeHandler(event)
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    checked={
                                                                                        (this.state.input.matchArr[item.id]
                                                                                        && this.state.input.matchArr[item.id] !== undefined
                                                                                        && Number(this.state.input.matchArr[item.id]) === Number(item.teamAId))
                                                                                        || (Number(this.state.input.matchBetArr[item.id]) === Number(item.teamAId))
                                                                                        ? 
                                                                                        true 
                                                                                        : 
                                                                                        false
                                                                                    }
                                                                                />
                                                                                <label className={`btn form-check-label ${(Number(item.teamAScore) < Number(item.teamBScore)) ? 'disabled' : ''}`} htmlFor={`matchOpt${item.teamAId}`}>
                                                                                    <img src={flag[`${item.teamA.toLowerCase()}.svg`]} alt={item.teamA} />
                                                                                    <p>{t(`premier-league.team.${item.teamA.toLowerCase()}`)}</p>
                                                                                </label>
                                                                                {(Number(item.teamAScore) > Number(item.teamBScore))
                                                                                // {((Number(item.teamAScore) > Number(item.teamBScore)) || ((Number(item.teamAExtraScore) > Number(item.teamBExtraScore))))
                                                                                ?
                                                                                    <img className="win-img" src={image["win-2.png"]} alt={`win${item.teamA}`} />
                                                                                :
                                                                                    null
                                                                                }
                                                                            </div>
                                                                            <div>
                                                                                <h5 className='mt-2 flex-start fw-bold'>VS</h5>
                                                                            </div>

                                                                            <div className={`form-check form-check-inline`}>
                                                                                <input
                                                                                    className="btn-check"
                                                                                    type="radio"
                                                                                    name={`matchOpt${item.teamBId}`}
                                                                                    id={`matchOpt${item.teamBId}`}
                                                                                    value={`${item.id}_${item.teamBId}`}
                                                                                    onChange={
                                                                                        (this.state.totalMatch[this.state.input.date] && !this.state.totalMatch[this.state.input.date].disabled)
                                                                                        ?
                                                                                        (event) => this.onInputChangeHandler(event)
                                                                                        :
                                                                                        null
                                                                                    }
                                                                                    checked={
                                                                                        (this.state.input.matchArr[item.id]
                                                                                        && this.state.input.matchArr[item.id] !== undefined
                                                                                        && Number(this.state.input.matchArr[item.id]) === Number(item.teamBId))
                                                                                        || (Number(this.state.input.matchBetArr[item.id]) === Number(item.teamBId))
                                                                                        ? 
                                                                                        true 
                                                                                        : 
                                                                                        false
                                                                                    }
                                                                                />
                                                                                <label className={`btn form-check-label ${(Number(item.teamBScore) < Number(item.teamAScore)) ? 'disabled' : ''}`} htmlFor={`matchOpt${item.teamBId}`}>
                                                                                    <img src={flag[`${item.teamB.toLowerCase()}.svg`]} alt={item.teamB} />
                                                                                    <p>{t(`premier-league.team.${item.teamB.toLowerCase()}`)}</p>
                                                                                </label>
                                                                                {(Number(item.teamBScore) > Number(item.teamAScore))
                                                                                // {((Number(item.teamBScore) > Number(item.teamAScore)) || ((Number(item.teamBExtraScore) > Number(item.teamAExtraScore))))
                                                                                ?
                                                                                    <img className="win-img" src={image["win-2.png"]} alt={`win${item.teamB}`} />
                                                                                :
                                                                                    null
                                                                                }
                                                                            </div>
                                                                        </div>
                                                                        <div className={(item.teamAScore !== null && item.teamBScore !== null) ? `score-wrap` : ''}>
                                                                                {(item.teamAScore !== null && item.teamBScore !== null) 
                                                                                ? <>
                                                                                    <div className='score-body'>
                                                                                        <span>{t('premier-league.score')}</span>
                                                                                        <span className='score-font btn-white'>
                                                                                            {/* {(item.isExtra === 1)
                                                                                            ?
                                                                                            <>{item.teamAExtraScore}-{item.teamBExtraScore} </>
                                                                                            : */}
                                                                                            <>{item.teamAScore}-{item.teamBScore} </>
                                                                                            {/* } */}
                                                                                        </span>   
                                                                                        </div>
                                                                                        <div className='score-body'> 
                                                                                        <span className='aet-font'>{t('premier-league.aet')}</span>
                                                                                        <span className='btn-white score-font aet-font'>{item.teamAExtraScore}-{item.teamBExtraScore}</span>
                                                                                        {/* {item.isExtra >= 1 && <span className='aet-font'>({t('premier-league.aet')})</span>}
                                                                                        
                                                                                        {item.isExtra === 2 &&
                                                                                            <><span className='mt-2'>{t('premier-league.penalties')}</span>
                                                                                            <span className='btn-gradient-blue'>{item.teamAExtraScore} - {item.teamBExtraScore}</span>
                                                                                        </>
                                                                                        } */}
                                                                                        </div>
                                                                                        </>
                                                                                :
                                                                                    ""
                                                                                }
                                                                            </div>
                                                                        {(item.teamAScore !== null && item.teamBScore !== null) &&
                                                                            <Confetti
                                                                                width={244}
                                                                                height={234}
                                                                                numberOfPieces={20}
                                                                                opacity={0.3}
                                                                            />
                                                                        }
                                                                    </div>
                                                            </Fade>
                                                        </div>
                                                    );
                                                }
                                                else
                                                {
                                                    return null;
                                                }
                                            })}
                                        </div>}
                                        {(this.state.totalMatch[this.state.input.date] && !this.state.totalMatch[this.state.input.date].disabled)
                                        ?
                                            <Button
                                                typeButton={(localStorage.getItem("accessToken")) ? `submit` : `button`}
                                                classValue="btn btn-gradient-blue"
                                                buttonName={(localStorage.getItem("accessToken")) ? t('global.submit') : t("premier-league.login-to-submit")}
                                                clicked={(localStorage.getItem("accessToken")) ? null : () => this.props.history.push("/login")}
                                            />
                                        :
                                            null
                                        }
                                        {this.state.totalMatch[this.state.input.date] 
                                        && this.state.totalMatch[this.state.input.date].disabled 
                                        && this.state.totalMatch[this.state.input.date].totalWinCount > 0
                                        && 
                                            (<h4 className='result-text'>{t('premier-league.congrats-msg-3')}<span className='orange-text'>{this.state.totalMatch[this.state.input.date].totalWinCount}</span>{t('premier-league.congrats-msg-4')}</h4>)
                                        }
                                    </form>
                                </div>
                                {/*
                                <div className='section-header-gray mt-5'>
                                    <h2>{t('premier-league.title-3')}</h2>
                                    <p>{t('premier-league.title-4')}</p>
                                </div>
                                <div className='wc-vs-section'>
                                    <div className='wc-reward row gap-5'>
                                        {fakeData}
                                    </div>
                                    {(this.state.totalMatch[this.state.input.date] 
                                    && this.state.totalMatch[this.state.input.date].disabled 
                                    && this.state.totalMatch[this.state.input.date].totalWinCount > 0) 
                                    &&
                                        (<h4 className='result-text'>{t('premier-league.congrats-msg-3')}<span className='blue-text'>{this.state.totalMatch[this.state.input.date].totalWinCount}</span>{t('premier-league.congrats-msg-5')}<span className='orange-text'>{this.props.currentUser.currency}{this.state.totalMatch[this.state.input.date].totalWinAmt}</span></h4>)
                                    }
                                </div>
                                */}
                                <div className='term-section mt-5'>
                                    <div className='term-container card-shadow'>
                                    <img src={tNc[`term-condition${imageLanguage}.svg`]} alt="Terms Condition" />
                                        <div className='term-content'>
                                            {/*
                                            <h4 className='ps-4 '>{t('premier-league.title-5')}:</h4>
                                            <table className='table'>
                                                <thead>
                                                    <tr>
                                                        {tcDataTHead}
                                                    </tr>
                                                </thead>  
                                                <tbody>
                                                    <tr>
                                                        {tcDataTBody}
                                                    </tr>
                                                </tbody>
                                            </table>
                                            */}
                                            <h4 className='ps-4 mt-3 mb-3'>{t('bet_and_earn.rule_of_activity')}：</h4>
                                            <ol>
                                                <li>{t('premier-league.rule-1')}</li>
                                                <li>{t('premier-league.rule-2').replace('##coin##',((this.state.dropDown.reward && this.state.dropDown.reward.coin) || 10))}</li>
                                                <li>{t('premier-league.rule-3')}</li>
                                                <li>{t('premier-league.rule-4')}</li>
                                                <li>{t('premier-league.rule-5')}</li>
                                                <li>{t('premier-league.rule-6')}</li>
                                                <li>{t('premier-league.rule-7').replace('##turnover##',((this.state.dropDown.reward && this.state.dropDown.reward.turnover) || 5))}</li>
                                                <li>{t('premier-league.rule-8')}</li>
                                                <li>{t('premier-league.rule-9')}</li>
                                                <li>{t('premier-league.rule-10')}</li>
                                                <li>{t('premier-league.rule-11')}</li>
                                            </ol>
                                        </div>
                                                     <img src={tNc["term-footer.png"]} alt="Terms Condition footer" />
                                    </div>
                                </div>
                            </div>
                        </section>
                    ) : (
                        <MobileFifaPremierLeague
                            {...this.state}
                            {...this.props}
                            changeDateHandler={this.changeDateHandler} 
                            getDuration={this.getDuration} 
                            onInputChangeHandler={(event) => this.onInputChangeHandler(event)}
                            mobileSlickHandler={this.mobileSlickHandler}
                            submitHandler={(event) => this.submitHandler(event)}
                            
                        />
                    )
                }
            </Auxiliary>
        )
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => 
{
    return {
        listing: state.fifaWorldCup.dropDown,
        store: state.fifaWorldCup.store,
        currentUser: state.currentUser.data,
    };
}

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => 
{   
    return {
        funcGetDropDown: (leagueId, yearMth) => dispatch(actions.getDropDown(leagueId, yearMth)),
        funcStore: (data) => dispatch(actions.store(data)),
        getUserData: (data) => dispatch(getUserData(data)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(FifaPremierLeague));