import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import Modal from "react-modal";
import Swal from "sweetalert2";
import { default as ReactSelect } from "react-select";
import Skeleton from "react-loading-skeleton";
import { Fade } from "react-awesome-reveal";
import { Icon } from "@iconify/react";
import moment from "moment";
import { isDesktop } from "react-device-detect";

//Components
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import Button from "../../../components/Button/Button";
import SecurityPinModal from "../../../components/SecurityPinModal/SecurityPinModal";
import MobileBankTransfer from "./MobileBankTransfer";

//Actions
import * as actions from "../../../store/ducks/withdrawal/actions";
import { editMemberBank, removeMemberBank } from "../../../store/ducks/profile/actions";
import { getTopProfile } from "../../../store/ducks/profile/actions";
import { checkSecurityPinAttempts } from "../../../store/ducks/profile/actions";
import { getUserData } from "../../../store/ducks/currentUser/actions";

import * as CryptoJS from "crypto-js";

class BankTransfer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            editBankDropDown: [],
            input: {
                amount: "",
                bank_id: "",
                transaction_channel_id: "",
                payment_type_id: 1,
                member_bank_account_id: null,
                bank_acc_number: "",
            },
            dropDown: {
                memberBankAccount: [],
                addMemberBankAccount: [],
            },
            editInput: {
                id: null,
                bank_key: null,
                bank_name: "",
                bank_acc_number: "",
                bank_acc_name: "",
            },
            showAddBank: false,

            loading: true,
            checkSecurityAttemptsLoading: true,
            errorMsg: [],
            formError: false,
            addSecurityPinStatus: false,

            enter_pin_attempts: 3,
            time: {},
            seconds: 60,
            timer: 0,
            reenter_security_pin_status: true,
            verify_security_pin_status: true,
            transType: "withdrawal",
            security_pin_create_date: "",

            openSecurityPinModal: false,
        };

        this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
        this.onEditInputChangeHandler = this.onEditInputChangeHandler.bind(this);
    }

    componentDidMount() {
        // if (this.props.topProfileData === undefined) {
        if (localStorage.getItem("accessToken")) {
            this.props.getTopProfile();
        }
        // }
        formValidation("withdrawal_form");
        // formValidation("update_bank_form");
        this.props.funcGetDropDown();
        // this.props.getTopProfile();
        // const data = {};
        // data.group_id = this.props.currentUser.group_id;
    }

    shouldComponentUpdate(nextProps, nextState) {
        // console.log(nextProps.dropDown.data);
        const { t } = this.props;

        if (nextProps.topProfileData !== undefined && nextProps.topProfileData !== this.props.topProfileData) {
            if (nextProps.topProfileData.responseCode === 200) {
                this.setState({
                    addSecurityPinStatus: nextProps.topProfileData.data.topProfile.security_pin_verified === 1 ? true : false,
                });
            }
        }

        if (nextProps.dropDown !== this.props.dropDown && nextProps.dropDown !== undefined) {
            if (nextProps.dropDown.responseCode === 200 && nextProps.dropDown.msgType === "success") {
                // if(this.state.addSecurityPinStatus === true){
                //     this.props.checkSecurityPinAttempts();
                // }
                let memberBankAccount = [];
                let addMemberBankAccount = [];
                let newInput = Object.assign({}, this.state.input);
                let newEditInput = Object.assign({}, this.state.editInput);
                // let withdrawalOption = this.state.withdrawalOption;
                let maxWithdrawal = nextProps.dropDown.data.member_group.max_daily_withdrawal;
                let maxButtonWithdrawal =
                    parseFloat(nextProps.dropDown.data.wallet.balance) < parseFloat(nextProps.dropDown.data.member_group.max_daily_withdrawal)
                        ? nextProps.dropDown.data.wallet.balance
                        : nextProps.dropDown.data.member_group.max_daily_withdrawal;

                nextProps.dropDown.data.transaction_channel.forEach((val) => {
                    // withdrawalOption = (withdrawalOption === "" && val.wd_status===1) ? val.slug : withdrawalOption;
                    newInput["transaction_channel_id"] = newInput["transaction_channel_id"] === "" && val.wd_status === 1 ? val.id : newInput["transaction_channel_id"];
                });

                nextProps.dropDown.data.member_bank_account.forEach((val) => {
                    memberBankAccount[val.bank_id] = val;
                    newInput["bank_id"] = newInput["bank_id"] !== "" && newInput["bank_id"] < val.bank_id ? newInput["bank_id"] : val.bank_id;
                    newInput["member_bank_account_id"] = newInput["member_bank_account_id"] !== null ? newInput["member_bank_account_id"] : val.id;
                    newInput["bank_acc_number"] = memberBankAccount[newInput["bank_id"]] ? memberBankAccount[newInput["bank_id"]].bank_acc_number : "";
                    newEditInput["bank_acc_name"] = val.bank_acc_name;
                });

                nextProps.dropDown.data.add_bank_data.forEach((val) => {
                    addMemberBankAccount[val.bank_id] = val;
                    // newInput["bank_id"] = newInput["bank_id"] !== "" ? newInput["bank_id"] : val.bank_id;
                });

                this.setState({
                    input: newInput,
                    editInput: newEditInput,
                    dropDown: {
                        memberBankAccount: memberBankAccount,
                        addMemberBankAccount: addMemberBankAccount,
                    },
                    minWithdrawal: nextProps.dropDown.data.member_group.min_withdrawal || 0,
                    maxWithdrawal: maxWithdrawal || 0,
                    maxButtonWithdrawal: maxButtonWithdrawal || 0,
                    loading: false,
                    showAddBank: nextProps.dropDown.data.member_bank_account.length > 0 ? false : true,
                });
            }
        }

        if (nextProps.store !== this.props.store && nextProps.store !== undefined) {
            // console.log("shouldComponentUpdate (create):", nextProps.store);
            if (nextProps.store.responseCode === 200 && nextProps.store.msgType === "success") {
                // if(document.getElementById('securityPinModal'))
                // {
                //     document.getElementById('securityPinModal').click();
                // }
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : "",
                    icon: "success",
                    title: t("global.success"),
                    html: t("global.record-added"),
                    showConfirmButton: true,
                    timer: 2000,
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then(() => {
                    let newInput = Object.assign({}, this.state.input);
                    newInput["amount"] = "";

                    this.setState({
                        input: newInput,
                        errorMsg: [],
                        formError: false,
                    });

                    // this.props.funcGetDropDown();
                    this.props.redirectHandler();
                    // this.props.history.push({
                    //     pathname: '/transaction-history',
                    //     historyTab: "TransactionHistory"
                    // });
                });
            } else {
                clearFormValidation("withdrawal_form");
                if (nextProps.store.message.input_error) {
                    this.setState({
                        errorMsg: nextProps.store.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                    // if(document.getElementById('securityPinModal'))
                    // {
                    //     document.getElementById('securityPinModal').click();
                    // }
                } else {
                    let msg = null;
                    msg = "<div>";
                    nextProps.store.message.forEach((val) => {
                        msg += "<p>" + val + "</p>";
                    });
                    msg += "</div>";

                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        customClass: !isDesktop
                            ? {
                                container: "swal-mobile-container sorry",
                                icon: "swal-sorry-icon",
                            }
                            : [],
                    }).then((result) => {
                        if (result.isConfirmed) {
                            if (nextProps.store.redirect && nextProps.store.redirect !== "" && nextProps.store.redirect !== null) {
                                window.location.href = nextProps.store.redirect;
                            }
                        }
                    });
                }
            }
        }

        if (nextProps.memberRemoveBankResult !== this.props.memberRemoveBankResult) {
            if (nextProps.memberRemoveBankResult.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : "",
                    title: t("global.success"),
                    html: t("global.bank-record-deleted"),
                    // icon: nextProps.memberRemoveBankResult.msgType,
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    let newInput = Object.assign({}, this.state.input);
                    newInput["bank_id"] = "";
                    newInput["bank_acc_number"] = "";
                    newInput["member_bank_account_id"] = null;

                    this.setState({
                        loading: true,
                        input: newInput,
                    });
                    this.props.funcGetDropDown();
                });
            } else if (nextProps.memberRemoveBankResult.responseCode === 422) {
                this.setState({
                    error_message: nextProps.memberRemoveBankResult.message,
                });
                Swal.close();
            } else {
                return false;
            }
        }

        if (nextProps.memberEditBankResult !== this.props.memberEditBankResult) {
            if (nextProps.memberEditBankResult.responseCode === 200) {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : "",
                    title: t("global.success"),
                    html: t("banking-details.banking-details-update-successful"),
                    icon: "success",
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sucess",
                            icon: "swal-success-icon",
                        }
                        : [],
                }).then((result) => {
                    if (result.isConfirmed) {
                        let newInput = Object.assign({}, this.state.input);
                        newInput["bank_id"] = "";
                        newInput["bank_acc_number"] = "";
                        newInput["member_bank_account_id"] = null;

                        this.setState({
                            openModal: false,
                            // editBankDropDown: [],
                            input: newInput,
                            loading: true,
                        });
                        this.props.funcGetDropDown();
                    }
                });
            } else {
                clearFormValidation("update_bank_form");
                if (nextProps.memberEditBankResult.message.input_error) {
                    this.setState({
                        errorMsg: nextProps.memberEditBankResult.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                } else {
                    let msg = null;
                    msg = "<div>";
                    nextProps.memberEditBankResult.message.forEach((val) => {
                        msg += "<p>" + val + "</p>";
                    });
                    msg += "</div>";

                    Swal.fire({
                        iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
                        icon: "error",
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        customClass: !isDesktop
                            ? {
                                container: "swal-mobile-container sorry",
                                icon: "swal-sorry-icon",
                            }
                            : [],
                    });
                }
            }
        }

        if (nextProps.verify_security_pin_result !== this.props.verify_security_pin_result) {
            if (nextProps.verify_security_pin_result.responseCode === 200) {
                if (nextProps.verify_security_pin_result.data.verify_security_pin_status === true) {
                    // if (isDesktop) {
                    //     document.getElementById("securityPinModal").click();
                    // }

                    this.securityPinsubmitHandler();
                }
            } else {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">' : "",
                    // icon: nextProps.verify_security_pin_result.msgType,
                    icon: "error",
                    title: t("global.error"),
                    html: nextProps.verify_security_pin_result.message[0],
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container failed",
                            icon: "swal-failed-icon",
                        }
                        : [],
                }).then(() => {
                    if (this.state.enter_pin_attempts !== 0) {
                        this.setState({
                            touched: true,
                            errorMsg: nextProps.verify_security_pin_result.message.input_error || [],
                            formError: true,
                            enter_pin_attempts: this.state.enter_pin_attempts - 1,
                            verify_security_pin_status: false,
                        });
                    }

                    if (this.state.enter_pin_attempts === 0) {
                        if (this.state.seconds === 0) {
                            // var now = moment(new Date()); //todays date
                            // var end = moment(this.state.security_pin_create_date); // another date
                            // var duration = moment.duration(now.diff(end));
                            // var seconds = duration.asSeconds();

                            this.setState({
                                timer: 0,
                                seconds: 60,
                            });
                        }

                        let timeLeftVar = this.secondsToTime(this.state.seconds);
                        // console.log(this.state.seconds);
                        // console.log(timeLeftVar);

                        // this.setState({ time: timeLeftVar });

                        this.startTimer();

                        this.setState({
                            time: timeLeftVar,
                            reenter_security_pin_status: false,
                            verify_security_pin_status: true,
                        });
                    }

                    Swal.close();
                });
            }
        }

        if (nextProps.update_security_pin_result !== this.props.update_security_pin_result) {
            if (nextProps.update_security_pin_result.responseCode === 200) {
                // if (isDesktop) {
                //     document.getElementById("securityPinModal").click();
                // }

                this.securityPinsubmitHandler();
            } else {
                Swal.fire({
                    iconHtml: !isDesktop ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : "",
                    icon: "error",
                    title: t("global.sorry"),
                    html: nextProps.update_security_pin_result.message[0],
                    showConfirmButton: true,
                    confirmButtonText: t("global.ok"),
                    customClass: !isDesktop
                        ? {
                            container: "swal-mobile-container sorry",
                            icon: "swal-sorry-icon",
                        }
                        : [],
                }).then(() => {
                    this.setState({
                        touched: true,
                        errorMsg: nextProps.update_security_pin_result.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                });
            }
        }

        if (nextProps.check_security_pin_attempts_result !== this.props.check_security_pin_attempts_result) {
            if (nextProps.check_security_pin_attempts_result.responseCode === 200) {
                if (nextProps.check_security_pin_attempts_result.data) {
                    this.setState({
                        enter_pin_attempts:
                            nextProps.check_security_pin_attempts_result.data.security_pin_attempts !== 0
                                ? nextProps.check_security_pin_attempts_result.data.security_pin_attempts
                                : this.state.enter_pin_attempts,
                        security_pin_create_date: nextProps.check_security_pin_attempts_result.data
                            ? nextProps.check_security_pin_attempts_result.data.created_at
                            : this.state.security_pin_create_date,
                        checkSecurityAttemptsLoading: false,
                    });
                } else {
                    this.setState({
                        enter_pin_attempts: nextProps.check_security_pin_attempts_result.data
                            ? nextProps.check_security_pin_attempts_result.data.security_pin_attempts
                            : this.state.enter_pin_attempts,
                        security_pin_create_date: nextProps.check_security_pin_attempts_result.data
                            ? nextProps.check_security_pin_attempts_result.data.created_at
                            : this.state.security_pin_create_date,
                        checkSecurityAttemptsLoading: false,
                    });
                }

                const current = moment(new Date(), "YYYY-mm-dd HH:mm:ss");

                if (nextProps.check_security_pin_attempts_result.data) {
                    if (nextProps.check_security_pin_attempts_result.data.security_pin_attempts === 0) {
                        var t2 = moment(nextProps.check_security_pin_attempts_result.data.created_at);
                        var given = moment(t2, "YYYY-mm-dd HH:mm:ss");
                        const secondsLeft = moment.duration(current.diff(given)).asSeconds();

                        let minusSeconds = 60 - secondsLeft;
                        clearInterval(this.timer);

                        if (secondsLeft >= 60) {
                            this.setState({
                                reenter_security_pin_status: true,
                                verify_security_pin_status: true,
                            });
                        } else if (minusSeconds > 0) {
                            let timeLeftVar = this.secondsToTime(secondsLeft);

                            this.setState({
                                time: timeLeftVar,
                                seconds: minusSeconds,
                                reenter_security_pin_status: false,
                                verify_security_pin_status: true,
                            });

                            this.startTimer();
                        }
                    }
                }
            }
        }

        return true;
    }

    onInputChangeHandler = (event) => {
        // console.log(event.target.name+' | '+event.target.value);
        let newInput = Object.assign({}, this.state.input);
        newInput[event.target.name === "add_bank_id" ? "bank_id" : event.target.name] = event.target.value;

        if (event.target.name === "bank_id" || event.target.name === "add_bank_id") {
            if (this.state.dropDown.memberBankAccount[event.target.value]) {
                newInput["member_bank_account_id"] = this.state.dropDown.memberBankAccount[event.target.value].id;
                newInput["bank_acc_number"] = this.state.dropDown.memberBankAccount[event.target.value].bank_acc_number;
            }
            if (this.state.dropDown.addMemberBankAccount[event.target.value]) {
                newInput["member_bank_account_id"] = "";
                if (this.state.dropDown.memberBankAccount.length > 0) {
                    newInput["bank_acc_number"] = "";
                }
            }
        }

        if (event.target.name.includes("amount")) {
            const splitedValue = event.target.value.split(".");
            if (splitedValue[1] && splitedValue[1].length > 2) {
                let val = parseFloat(event.target.value).toFixed(2);
                newInput[event.target.name] = val;
            }
        }

        this.setState({
            input: newInput,
            // withdrawalAmt: null,
            showAddBank: event.target.name === "bank_id" ? false : this.state.showAddBank,
            [event.target.name]: event.target.value,
            formError: false,
            errorMsg: [],
        });
    };

    onEditInputChangeHandler = (event, val) => {
        // console.log(val);
        let newInput = Object.assign({}, this.state.editInput);
        if (val !== undefined && val === "bank_name") {
            newInput[val] = event.id;
            newInput["bank_key"] = event.key;
        } else {
            newInput[event.target.name] = event.target.value;
        }

        this.setState({
            editInput: newInput,
            formError: false,
            errorMsg: [],
        });
    };

    withdrawalMaxHandler = () => {
        let newInput = Object.assign({}, this.state.input);
        if (this.state.withdrawalOption === "crypto") {
            newInput["cryptoAmount"] = this.state.maxButtonWithdrawal;
        } else {
            newInput["amount"] = this.state.maxButtonWithdrawal;
        }

        this.setState({
            // withdrawalAmt: this.state.maxButtonWithdrawal,
            input: newInput,
        });
    };

    showAddBankHandler = () => {
        this.setState({
            showAddBank: true,
        });
    };

    removeBankHandler = (e, id, bankName) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : "",
            title: t("global.reminder"),
            // text: t("global.remove") + " " + bankName + "?" + " " + t("global.deleted-cannot-recovered") + ".",
            text: `${t("global.remove")} ${bankName}? ${t("global.deleted-cannot-recovered")}.`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.confirm"),
            cancelButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container reminder",
                    icon: "swal-reminder-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.member_bank_account_id = id;
                this.props.removeMemberBank(data);
            }
        });
    };

    openModalHandler = (e, id, bankId, bankName, swiftCode, bankAccNo) => {
        e.preventDefault();

        let newArray = Object.assign([], this.state.dropDown.addMemberBankAccount);
        newArray.push({
            bank_id: bankId,
            bank_name: bankName,
            swift_code: swiftCode,
        });

        let newEditInput = Object.assign({}, this.state.editInput);
        newEditInput["id"] = id;
        newEditInput["bank_name"] = bankId;
        newEditInput["bank_key"] = newArray.length - 1 >= 0 ? newArray.length - 1 : 0;
        newEditInput["bank_acc_number"] = bankAccNo;

        this.setState({
            openModal: true,
            editBankDropDown: newArray,
            editInput: newEditInput,
            formError: false,
            errorMsg: [],
        });
    };

    closeModalHandler = (e) => {
        e.preventDefault();
        this.setState({
            openModal: false,
            formError: false,
            errorMsg: [],
        });
    };

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    updateBankHandler = (e) => {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
            customClass: !isDesktop
                ? {
                    container: "swal-mobile-container confirmation",
                    icon: "swal-confirmation-icon",
                }
                : [],
        }).then((result) => {
            if (result.isConfirmed) {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                Object.keys(this.state.editInput).forEach((val) => {
                    data[val] = this.state.editInput[val];
                });

                // console.log("BankingDetails Form Submit:", this.state.editInput);
                this.props.editMemberBank(data);
            }
        });
    };

    securityPinsubmitHandler = (e) => {
        // console.log('submitHandler :', this.state.input);
        // e.preventDefault();

        // const { t } = this.props;
        // Swal.fire({
        //     title: t("validation.confirmation"),
        //     icon: "warning",
        //     confirmButtonText: t("global.confirm"),
        //     showDenyButton: true,
        //     denyButtonText: t("global.cancel"),
        // }).then((result) =>
        // {
        //     if (result.isConfirmed)
        //     {
        Swal.fire({
            allowOutsideClick: false,
            allowEscapeKey: false,
            allowEnterKey: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });

        const inputArr = {};

        Object.keys(this.state.input).forEach((val) => {
            if (this.state.withdrawalOption === "crypto") {
                inputArr["amount"] = this.state.input["cryptoAmount"];
                inputArr["transaction_channel_id"] = this.state.input["transaction_channel_id"];
                inputArr.crypto = this.state.cryptoOption;
                inputArr.withdrawal_address = this.state.selectWithdrawalCryptoAddr.value || "";
            } else {
                inputArr[val] = this.state.input[val];
            }
        });

        // HASHING ALGO
        inputArr["trxTime"] = moment().unix();
        let hashStr = this.hashingAlg(inputArr);
        inputArr["trxHash"] = hashStr;
        this.props.funcStore(inputArr);
    };

    submitHandler = (e) => {
        // console.log("submitHandler :", this.state.input);
        e.preventDefault();
        const { t } = this.props;

        if (this.state.input.bank_id === "" || this.state.input.bank_id === null) {
            this.setState({
                errorMsg: { bank_id: t("error-msg.bank_id") },
            });
        } else {
            if (this.state.addSecurityPinStatus === true) {
                document.getElementById("securityPinSubmitBtn").click();
            }

            Swal.fire({
                iconHtml: !isDesktop ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : "",
                title: t("global.confirmation"),
                text: t("global.would-you-like-to-proceed") + "?",
                icon: "warning",
                confirmButtonText: t("global.confirm"),
                showDenyButton: true,
                denyButtonText: t("global.cancel"),
                customClass: !isDesktop
                    ? {
                        container: "swal-mobile-container confirmation",
                        icon: "swal-confirmation-icon",
                    }
                    : [],
            }).then((result) => {
                if (result.isConfirmed) {
                    Swal.fire({
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        allowEnterKey: false,
                        didOpen: () => {
                            Swal.showLoading();
                        },
                    });

                    const inputArr = {};

                    Object.keys(this.state.input).forEach((val) => {
                        if (this.state.withdrawalOption === "crypto") {
                            inputArr["amount"] = this.state.input["cryptoAmount"];
                            inputArr["transaction_channel_id"] = this.state.input["transaction_channel_id"];
                            inputArr.crypto = this.state.cryptoOption;
                            inputArr.withdrawal_address = this.state.selectWithdrawalCryptoAddr.value || "";
                        } else {
                            inputArr[val] = this.state.input[val];
                        }
                    });

                    // HASHING ALGO
                    inputArr["trxTime"] = moment().unix();
                    let hashStr = this.hashingAlg(inputArr);
                    inputArr["trxHash"] = hashStr;
                    this.props.funcStore(inputArr);
                }
            });
            // }
        }
    };

    securityPinModalHandler = (value) => {
        if (this.state.input.amount && this.state.input.bank_id && this.state.input.member_bank_account_id !== null && this.state.input.bank_acc_number) {
            this.setState({ openSecurityPinModal: true, securityPinModal: value, checkSecurityAttemptsLoading: true });
        }

        this.props.checkSecurityPinAttempts();
    };

    secondsToTime(secs) {
        let hours = Math.floor(secs / (60 * 60));

        let divisor_for_minutes = secs % (60 * 60);
        let minutes = Math.floor(divisor_for_minutes / 60);

        let divisor_for_seconds = divisor_for_minutes % 60;
        let seconds = Math.ceil(divisor_for_seconds);

        let obj = {
            h: hours,
            m: minutes,
            s: seconds.toString().padStart(2, "0"),
        };
        return obj;
    }

    startTimer() {
        if (this.state.timer === 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown = () => {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;

        this.setState({
            time: this.secondsToTime(seconds),
            seconds: seconds,
        });

        // Check if we're at zero.
        if (seconds === 0 || seconds <= 0) {
            clearInterval(this.timer);
            this.setState({ reenter_security_pin_status: true, seconds: 60, time: 0, enter_pin_attempts: 3 });
        }
    };

    hashingAlg = (inputArr) => {
        let toHashStr = "";
        let hashStr = "";
        let paramsKey = [];
        Object.keys(inputArr).forEach((item) => {
            if (inputArr[item] !== "") {
                paramsKey.push(item);
            }
        });
        paramsKey.sort();
        paramsKey.forEach((val) => {
            let x = inputArr[val];
            if (x !== "") {
                toHashStr += val + x;
            }
        });
        toHashStr += process.env.REACT_APP_HASH_SECRET;
        hashStr = CryptoJS.MD5(toHashStr).toString();
        return hashStr;
    };

    toggleSecurityPinModal = () => {
        this.setState({
            openSecurityPinModal: !this.state.openSecurityPinModal
        })
    }

    render() {
        const { t } = this.props;
        const bankImg = this.imageImport(require.context("../../../assets/images/profile/banks", false, /\.(png|jpe?g|svg)$/));

        let inputArr = this.state.input ? this.state.input : [];
        let memberBankAccount = this.state.dropDown.memberBankAccount ? this.state.dropDown.memberBankAccount : [];
        let addMemberBankAccount = this.state.dropDown.addMemberBankAccount ? this.state.dropDown.addMemberBankAccount : [];
        let editMemberBankArray = [];

        if (this.state.editBankDropDown && this.state.openModal) {
            editMemberBankArray = this.state.editBankDropDown.map((val, idx) => {
                return {
                    id: val.bank_id,
                    label: val.bank_name,
                    icon: `${val.swift_code.toLowerCase()}-color`,
                    key: idx,
                };
            });
        }

        if (this.state.openModal) {
            formValidation("update_bank_form");
        }

        // if(this.state.formError)
        // {
        //     clearFormValidation("withdrawal_form");
        //     if (this.state.openModal && this.state.errorMsg.bank_acc_number)
        //     {
        //         console.log(this.state.errorMsg);
        //         clearFormValidation("update_bank_form");
        //     }
        // }
        return (
            <Auxiliary>
                {isDesktop ? (
                    <>
                        <Modal
                            isOpen={this.state.openModal}
                            closeTimeoutMS={500}
                            portalClassName={`global-modal`}
                            className="modal modal-dialog modal-dialog-centered modal-pd"
                            ariaHideApp={false}
                            onRequestClose={(event) => this.closeModalHandler(event)}
                        >
                            <div className="modal-content">
                                <div className="modal-header">
                                    <h5 className="modal-title">{t("banking-details.edit-banking-details")}</h5>
                                    <Icon type="button" className="btn-close" icon="mdi:close" onClick={(event) => this.closeModalHandler(event)} />
                                </div>
                                <div className="modal-body">
                                    <form className="forms-col" method="post" id="update_bank_form" onSubmit={this.updateBankHandler} noValidate>
                                        <div>
                                            <label className="col-form-label lh-1">{t("banking-details.bank-name")}</label>
                                            <ReactSelect
                                                classNamePrefix="react-select"
                                                placeholder={t("banking-details.select-bank")}
                                                name="bank_name"
                                                options={editMemberBankArray}
                                                onChange={(event) => this.onEditInputChangeHandler(event, "bank_name")}
                                                value={this.state.editInput.bank_key !== null ? editMemberBankArray[this.state.editInput.bank_key] : ""}
                                                formatOptionLabel={(item) => (
                                                    <>
                                                        <img
                                                            src={
                                                                bankImg[`${item.icon.toLowerCase()}.svg`] !== undefined
                                                                    ? bankImg[`${item.icon.toLowerCase()}.svg`]
                                                                    : bankImg["default-bank-icon.png"]
                                                            }
                                                            alt={item.label}
                                                        />
                                                        <p>{item.label}</p>
                                                    </>
                                                )}
                                            />
                                        </div>
                                        <div>
                                            <label className="col-form-label lh-1">{t("banking-details.account-no")}</label>
                                            <NumberInput
                                                // className={`form-control was-validated is-invalid`}
                                                className={`${this.state.errorMsg.bank_acc_number ? "was-validated is-invalid" : ""}`}
                                                onChange={(event) => this.onEditInputChangeHandler(event)}
                                                name="bank_acc_number"
                                                id="bank_acc_number"
                                                value={this.state.editInput.bank_acc_number}
                                                required={true}
                                                requiredMessage={t("validation.required")}
                                                formError={this.state.formError}
                                                errorMsg={this.state.errorMsg.bank_acc_number || ""}
                                            />
                                        </div>
                                        <Button typeButton="submit" classValue="btn btn-gradient-blue mt-3" buttonName={t("top-profile.update")} />
                                    </form>
                                </div>
                            </div>
                        </Modal>
                        <SecurityPinModal
                            {...this.state}
                            toggleSecurityPinModal={this.toggleSecurityPinModal}
                        // verificationModalHandler={this.verificationModalHandler}
                        />
                        <form className="forms-col needs-validation" method="post" id="withdrawal_form" onSubmit={this.submitHandler} noValidate>
                            {this.state.loading ? (
                                <Skeleton count={2} height={40} style={{ marginBottom: "1rem" }} />
                            ) : (
                                <>
                                    <div className="row">
                                        <label className="col-3 col-form-label">{t("withdraw.select-a-bank")}</label>
                                        <div className="col-9 select-bank-opts">
                                            {memberBankAccount.length > 0 &&
                                                memberBankAccount.map((val, idx) => {
                                                    let bankColorImage = null;
                                                    if (val.swift_code && bankImg[`${val.swift_code.toLowerCase()}-color.svg`] !== undefined) {
                                                        bankColorImage = bankImg[`${val.swift_code.toLowerCase()}-color.svg`];
                                                    } else {
                                                        bankColorImage = bankImg["default-bank-icon-color.png"];
                                                    }

                                                    return (
                                                        <div className="form-check form-check-inline" key={idx}>
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="bank_id"
                                                                id={`bankOpt${val.bank_id}`}
                                                                value={val.bank_id}
                                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                                checked={Number(this.state.input.bank_id) === Number(val.bank_id) ? true : false}
                                                            />
                                                            <label className="btn form-check-label" htmlFor={`bankOpt${val.bank_id}`}>
                                                                <img src={bankColorImage} alt={val.swift_code} />
                                                                {val.bank_name}
                                                                {/* <p>{val.bank_acc_number}</p> */}
                                                                {/* <div>
                                                                    <Button
                                                                        typeButton="button"
                                                                        classValue="btn icon"
                                                                        key={`edit-${val.id}`}
                                                                        idValue={val.id === undefined ? 0 : val.id}
                                                                        clicked={(e) =>
                                                                            this.openModalHandler(e, val.id, val.bank_id, val.bank_name, val.swift_code, val.bank_acc_number)
                                                                        }
                                                                        buttonName={<Icon icon="uil:edit" />}
                                                                    />
                                                                    <Button
                                                                        typeButton="button"
                                                                        classValue="btn icon red"
                                                                        key={`remove-${val.id}`}
                                                                        idValue={val.id === undefined ? 0 : val.id}
                                                                        clicked={(e) => this.removeBankHandler(e, val.id, val.bank_name)}
                                                                        buttonName={<Icon icon="uil:trash-alt" />}
                                                                    />
                                                                </div> */}
                                                            </label>
                                                        </div>
                                                    );
                                                })}
                                            <div className="form-check form-check-inline">
                                                <input
                                                    className="btn-check"
                                                    type="radio"
                                                    name="bankOtps"
                                                    id="bankOtpsAdd"
                                                    value="addBankOtp"
                                                    onChange={() => this.showAddBankHandler()}
                                                    checked={this.state.showAddBank ? true : false}
                                                />
                                                <label className="btn form-check-label wdrawal-add-bank mt-0" htmlFor="bankOtpsAdd">
                                                    {this.state.showAddBank ? <Icon className="me-2" icon="radix-icons:minus-circled" /> : <Icon className="me-2" icon="radix-icons:plus-circled" />}
                                                    {t("withdraw.add-bank-account")}
                                                </label>
                                            </div>
                                            <div className="invalid-feedback">{this.state.errorMsg.bank_id || ""}</div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-9 offset-3">
                                            <Fade direction="up" duration={500} triggerOnce>
                                                <li
                                                className={`list-group-item select-bank-opts p-3 ${this.state.showAddBank ? "" : "d-none"} form-control ${this.state.errorMsg.bank_id ? "was-validated is-invalid" : ""
                                                    }`}
                                            >
                                                {this.state.showAddBank &&
                                                    addMemberBankAccount.map((val, idx) => {
                                                        let bankColorImage =
                                                            val.swift_code && bankImg[`${val.swift_code.toLowerCase()}-color.svg`] !== undefined
                                                                ? bankImg[`${val.swift_code.toLowerCase()}-color.svg`]
                                                                : bankImg["default-bank-icon-color.png"];

                                                        return (
                                                            <div className="form-check form-check-inline" key={idx}>
                                                                <input
                                                                    className="btn-check"
                                                                    type="radio"
                                                                    name="add_bank_id"
                                                                    id={`addBankOtps${val.bank_id}`}
                                                                    value={val.bank_id}
                                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                                    checked={Number(this.state.input.bank_id) === Number(val.bank_id) ? true : false}
                                                                />
                                                                <label className="btn form-check-label" htmlFor={`addBankOtps${val.bank_id}`}>
                                                                    <img src={bankColorImage} alt={val.swift_code} />
                                                                    <p>{val.bank_name}</p>
                                                                </label>
                                                            </div>
                                                        );
                                                    })}
                                                </li>
                                            </Fade>
                                        </div>
                                    </div>
    
                                    <div className="row">
                                        <label className="col-3 col-form-label">{t("withdraw.account-number")}:</label>
                                        <div className="col-9">
                                            <NumberInput
                                                id="bank_acc_number"
                                                name="bank_acc_number"
                                                className={`form-control ${this.state.errorMsg.bank_acc_number ? "was-validated is-invalid" : ""}`}
                                                placeholder={memberBankAccount[inputArr.bank_id] ? memberBankAccount[inputArr.bank_id].bank_acc_number : ""}
                                                value={inputArr.bank_acc_number}
                                                onChange={(event) => this.onInputChangeHandler(event)}
                                                step="any"
                                                readOnly={memberBankAccount[inputArr.bank_id] ? true : false}
                                                required={this.props.withdrawalOption === "bank-transfer" ? true : false}
                                                requiredMessage={t("validation.required")}
                                                formError={this.state.formError}
                                                errorMsg={this.state.errorMsg.bank_acc_number || ""}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <label className="col-3 col-form-label">{t("withdraw.amount")}:</label>
                                        <div className="col-9 d-flex">
                                            <div className="input-group left has-validation">
                                                <span className="input-group-text">{this.props.currentUser.currency}</span>
                                                <NumberInput
                                                    id="amount"
                                                    name="amount"
                                                    className={`form-control ${this.state.errorMsg.amount ? "was-validated is-invalid" : ""}`}
                                                    // className={`form-control was-validated is-invalid`}
                                                    // className={`form-control ${this.props.formClass}`}
                                                    // className={`form-control ${(this.props.errorMsg.amount!==undefined) ? 'was-validated is-invalid' : ''}`}
                                                    // className='form-control'
                                                    placeholder={`${t("withdraw.min-max-limit")} ${this.state.minWithdrawal}/${this.state.maxWithdrawal}`}
                                                    value={inputArr.amount}
                                                    onChange={(event) => this.onInputChangeHandler(event)}
                                                    min={this.state.minWithdrawal || 0}
                                                    max={this.state.maxWithdrawal || 0}
                                                    step="any"
                                                    required={this.props.withdrawalOption === "bank-transfer" ? true : false}
                                                    onKeyPress={(evt) => (evt.key === "e" || evt.key === "-" || evt.key === "+") && evt.preventDefault()}
                                                    minValueMessage={t("validation.amtMinValue", { 0: this.state.minWithdrawal })}
                                                    maxValueMessage={t("validation.amtMaxValue", { 0: this.state.maxWithdrawal })}
                                                    requiredMessage={t("validation.required")}
                                                    formError={this.state.formError}
                                                    errorMsg={this.state.errorMsg.amount || ""}
                                                />
                                            </div>
                                            <Button
                                                typeButton="button"
                                                clicked={this.withdrawalMaxHandler}
                                                classValue="btn btn-gradient-blue ms-2"
                                                style={{ minWidth: "60px" }}
                                                buttonName={t("withdraw.max")}
                                            />
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-9 offset-3">
                                            {this.state.addSecurityPinStatus === true &&
                                                this.state.input.amount &&
                                                this.state.input.bank_id &&
                                                this.state.input.member_bank_account_id !== null &&
                                                this.state.input.bank_acc_number ? (
                                                <button
                                                    className="btn btn-gradient-blue"
                                                    type="button"
                                                    // data-bs-toggle="modal"
                                                    // data-bs-target="#securityPinModal"
                                                    onClick={() => this.securityPinModalHandler("enter-sec-pin")}
                                                    id="securityPinSubmitBtn"
                                                >
                                                    {t("global.confirm")}
                                                </button>
                                            ) : (
                                                <button className="btn btn-gradient-blue" type="submit" value="Submit">
                                                    {t("global.confirm")}
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                </>
                            )}
                        </form>
                    </>
                ) : (
                    <MobileBankTransfer
                        submitHandler={this.submitHandler}
                        onInputChangeHandler={this.onInputChangeHandler}
                        withdrawalMaxHandler={this.withdrawalMaxHandler}
                        removeBankHandler={this.removeBankHandler}
                        imageImport={this.imageImport}
                        securityPinsubmitHandler={this.securityPinsubmitHandler}
                        secondsToTime={this.secondsToTime}
                        startTimer={this.startTimer}
                        countDown={this.countDown}
                        securityPinModalHandler={this.securityPinModalHandler}
                        bankImg={bankImg}
                        inputArr={inputArr}
                        memberBankAccount={memberBankAccount}
                        {...this.state}
                        {...this.props}
                    />
                )}
            </Auxiliary>
        );
    }
}

//Received the response from the redux calling the api response
const mapStateToProps = (state) => {
    return {
        topProfileData: state.profile.top_profile_data,
        dropDown: state.withdrawal.dropDown,
        store: state.withdrawal.store,
        // store_withdrawal_crypto_address: state.withdrawal.store_withdrawal_crypto_address,
        // get_withdrawal_crypto_address: state.withdrawal.get_withdrawal_crypto_address,
        // store_withdrawal_crypto: state.withdrawal.store_withdrawal_crypto,
        // get_withdrawal_details: state.withdrawal.get_withdrawal_details,
        currentUser: state.currentUser.data,
        // get_available_crypto_list_result: state.profile.get_available_crypto_list_result
        memberRemoveBankResult: state.profile.member_remove_bank_result,
        memberEditBankResult: state.profile.member_edit_bank_result,
        verify_security_pin_result: state.profile.verify_security_pin_result,
        update_security_pin_result: state.profile.update_security_pin_result,
        check_security_pin_attempts_result: state.profile.check_security_pin_attempts_result,
    };
};

//Using the redux calling api methods
const mapDispatchToProps = (dispatch) => {
    return {
        getTopProfile: () => dispatch(getTopProfile()),
        funcGetDropDown: () => dispatch(actions.getDropDown()),
        funcStore: (data) => dispatch(actions.store(data)),
        // funcStoreWithdrawalCryptoAddress: (data) => dispatch(actions.addWithdrawalCryptoAddress(data)),
        // funcGetWithdrawalCryptoAddress: (data) => dispatch(actions.getWithdrawalCryptoAddress(data)),
        // funcStoreWithdrawalCrypto: (data) => dispatch(actions.cryptoWithdrawalStore(data)),
        // funcGetWithdrawalDetails: (data) => dispatch(actions.getWithdrawalDetails(data)),
        // funcGetAvailableCrypto: () => dispatch(getAvailableCryptoList())
        removeMemberBank: (data) => dispatch(removeMemberBank(data)),
        editMemberBank: (data) => dispatch(editMemberBank(data)),
        getUserData: (data) => dispatch(getUserData(data)),
        checkSecurityPinAttempts: (data) => dispatch(checkSecurityPinAttempts(data)),
    };
};

// Connect redux

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BankTransfer));
