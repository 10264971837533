import React, { Component } from "react";
import { connect } from "react-redux";
import { Icon } from "@iconify/react";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import { formValidation/*, clearFormValidation*/ } from "../../hoc/Shared/utility";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";

//Components
import NumberInput from "../Input/NumberInput/NumberInput";
import Button from "../Button/Button";
import MobileSelectRadio from "../MobileAsset/MobileSelectRadio/MobileSelectRadio";
import MobileSubHeader from "../PageHeader/MobileSubHeader";

//Actions
import { getMemberBank, addMemberBank } from "../../store/ducks/profile/actions";
import { getDropDown } from "../../store/ducks/withdrawal/actions";

class MobileAddBank extends Component 
{
    constructor(props) 
    {
        super(props);
        this.state = 
        {
            memberBankData: [],
            bankDropDown: [],
            loading: false,
            selectedBank: "",

            bank_icon: "",
            bank_acc_number: "",
            bank_name: null,
            bank_key: null,

            errorMsg: [],
            formError: false,
            showSelectRadio: false,
        };
    }

    componentDidMount() 
    {
        formValidation("add_bank_form");
        this.searchHandler();
    }

    shouldComponentUpdate(nextProps, nextState) 
    {
        const { t } = this.props;
        if (nextProps.memberBankData !== this.props.memberBankData && nextProps.memberBankData !== undefined) 
        {
            if (nextProps.memberBankData.responseCode === 200) 
            {
                let accountHolder = nextProps.memberBankData.data.accountHolder;
                let memberBank = nextProps.memberBankData.data.memberBank;
                let bankDropdown = nextProps.memberBankData.data.bank;
                this.setState({
                    bank_acc_name: accountHolder.name,
                    memberBankData: memberBank,
                    bankDropDown: bankDropdown,
                    loading: false,
                });
            }
        }

        if (nextProps.memberAddBankResult !== this.props.memberAddBankResult) 
        {
            if (nextProps.memberAddBankResult.responseCode === 200) 
            {
                Swal.fire({
                    iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
                    title: t('global.success'),
                    html: t('global.record-added'),
                    icon: 'success',
                    confirmButtonText: t("global.ok"),
                    customClass: {
                        container: 'swal-mobile-container sucess',
                        icon: 'swal-success-icon'
                    }
                }).then((result) => 
                {
                    if (result.isConfirmed) 
                    {
                        this.setState({
                            bank_name: null,
                            bank_key: null,
                            bank_acc_number: "",
                            selectedBank: "",
                            bank_icon: "",
                            showAddBank: false,
                            touched: false,
                            backToWallet: true
                        });
                        this.closeAddBankHandler();
                    }
                });
            }
            else 
            {
                // clearFormValidation("add_bank_form");
                if (nextProps.memberAddBankResult.message.input_error) 
                {
                    this.setState({
                        errorMsg: nextProps.memberAddBankResult.message.input_error || [],
                        formError: true,
                    });
                    Swal.close();
                }
                else 
                {
                    let msg = null;
                    msg = "<ul>";
                    nextProps.memberAddBankResult.message.forEach((val) => 
                    {
                        msg += "<li>" + val + "</li>";
                    });
                    msg += "</ul>";
                    Swal.fire({
                        iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
                        icon: 'error',
                        title: t("global.sorry"),
                        html: msg,
                        showConfirmButton: true,
                        confirmButtonText: t("global.ok"),
                        customClass: {
                            container: 'swal-mobile-container sorry',
                            icon: 'swal-sorry-icon'
                        }
                    });
                }
            }
        }
        return true;
    }

    closeAddBankHandler = () => 
    {
        this.props.toggleAddBankHandler();
        this.searchHandler();
    }

    toggleSelectRadio = () => 
    {
        this.setState({
            showSelectRadio: !this.state.showSelectRadio,
        });
    };

    handleBankChange = (event) => 
    {
        // console.log(event);
        this.setState({
            bank_name: event.id,
            bank_key: event.key,
            bank_icon: event.icon,
            selectedBank: event.label,
        });
    };

    addBankHandler = (e) => 
    {
        e.preventDefault();
        const { t } = this.props;

        Swal.fire({
            iconHtml: '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">',
            title: t("global.confirmation"),
            text: t("global.would-you-like-to-proceed") + "?",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: t("global.save"),
            cancelButtonText: t("global.cancel"),
            customClass: {
                container: "swal-mobile-container confirmation",
                icon: "swal-confirmation-icon",
            },
        }).then((result) => 
        {
            if (result.isConfirmed) 
            {
                Swal.fire({
                    title: "",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    allowEnterKey: false,
                    didOpen: () => {
                        Swal.showLoading();
                    },
                });

                let data = {};
                data.bank_name = this.state.bank_name;
                data.bank_acc_name = this.state.bank_acc_name;
                data.bank_acc_number = this.state.bank_acc_number;

                // console.log("BankingDetails Form Submit:", data);
                this.props.addMemberBank(data);
            }
        });
    };

    onInputChangeHandler = (event) => 
    {
        this.setState({
            [event.target.name]: event.target.value,
            errorMsg: [],
            formError: false,
        });
    };

    searchHandler = () => 
    {
        this.setState({
            loading: true,
        });
        this.props.getMemberBank();
        this.props.funcGetDropDown();
    };

    imageImport = (r) => 
    {
        let images = {};
        r.keys().map((item, index) => 
        {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    render() 
    {
        const { t } = this.props;
        const bankImg = this.imageImport(require.context("../../assets/images/profile/banks", false, /\.(png|jpe?g|svg)$/));

        let addMemberBankArray = [];

        if (this.state.bankDropDown && !this.state.loading) 
        {
            addMemberBankArray = this.state.bankDropDown.map((val, idx) => 
            {
                return {
                    id: val.bankId,
                    label: val.bankName,
                    icon: `${val.swiftCode.toLowerCase()}-color`,
                    key: idx,
                };
            });
        }

        // console.log(this.state.bank_key);
        return (
            <Auxiliary>
                <section className={`pf-m-dashboard on-top remain-w ${this.props.showAddNewBank ? 'd-block pe-auto' : 'd-none'}`}>

                <MobileSubHeader
                    // className="bg-white"
                    closeModalHandler={this.closeAddBankHandler}
                    header={t('banking-details.add-bank')}
                    style={{ justifyContent: "unset" }}
                />

                <form method="post" id="add_bank_form" className="forms-col needs-validation" onSubmit={this.addBankHandler} noValidate>
                    <div className="pd-form-m-col">
                    {
                        this.state.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <label className="col-form-label">{t('banking-details.bank-name')}</label>
                                    <div className="input-group-col" >
                                        <div className="input-group right has-validation" onClick={this.toggleSelectRadio}>
                                        {this.state.bank_icon && (
                                            <img
                                            src={(bankImg[`${this.state.bank_icon}.svg`] !== undefined)
                                                ?
                                                bankImg[`${this.state.bank_icon}.svg`]
                                                :
                                                bankImg["default-bank-icon.png"]}
                                            alt="bank"
                                            />
                                        )}
                                        <p className={`form-control lh-lg ${(this.state.errorMsg.bank_name) ? 'is-invalid' : ''}`}>
                                            {this.state.selectedBank ? this.state.selectedBank : t('banking-details.select-bank')}
                                        </p>
                                        <span className="input-group-text"><Icon icon="uil:angle-right" /></span>
                                        <div className="invalid-feedback">{this.state.formError && this.state.errorMsg.bank_name}</div>
                                        </div>
                                            <MobileSelectRadio
                                                name={"bank_name"}
                                                showSelectRadio={this.state.showSelectRadio}
                                                toggleHandler={this.toggleSelectRadio}
                                                onChange={this.handleBankChange}
                                                options={addMemberBankArray}
                                                value={this.state.bank_key}
                                            />
                                    </div>
                                </div>
                                <div>
                                    <label className="col-form-label">{t('banking-details.account-no')}</label>
                                    <div className="input-group-col">
                                        <NumberInput
                                            id="bank_acc_number"
                                            name="bank_acc_number"
                                            className={`${this.state.errorMsg.bank_acc_number
                                                ? "was-validated is-invalid"
                                                : ""
                                                } px-0`}
                                            value={this.state.bank_acc_number}
                                            onChange={(event) => this.onInputChangeHandler(event)}
                                            step="any"
                                            required={true}
                                            requiredMessage={t("validation.required")}
                                            formError={this.state.formError}
                                            errorMsg={this.state.errorMsg.bank_acc_number || ""}
                                        />
                                    </div>
                                </div>
                                <Button
                                    typeButton="submit"
                                    classValue={`btn btn-gradient-blue mx-3 ${this.state.bank_acc_number && this.state.selectedBank !== ""
                                        ? "active"
                                        : ""
                                        }`}
                                    idValue="submit"
                                    buttonName={t("banking-details.add-bank")}
                                />
                            </>
                        )
                    }
                    </div>
                </form>

                </section>
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    const { profile } = state;

    return {
        memberBankData: profile.member_bank_data,
        memberAddBankResult: profile.member_add_bank_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        funcGetDropDown: () => dispatch(getDropDown()),
        getMemberBank: () => dispatch(getMemberBank()),
        addMemberBank: (data) => dispatch(addMemberBank(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileAddBank));
