import React, { Component } from "react";
// import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous 
// import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Icon } from "@iconify/react";

//Components 
import QuickPay from "./QuickPay";
import BankTransfer from "./BankTransfer";
import EWallet from "./EWallet";
import Crypto from "./Crypto";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import MobileImportantNotice from "../../../components/MobileImportantNotice/MobileImportantNotice";

class MobileDeposit extends Component {
    constructor(props) {
        super(props);
        this.state =
        {
            showNotice: false,
        }
    }

    shouldComponentUpdate() {
        return true;
    }

    toggleNoticeHandler = () => {
        if (this.state.showNotice === false) {
            document.body.classList.add('modal-open');
        }
        else {
            document.body.classList.remove('modal-open');
        }

        this.setState({
            showNotice: !this.state.showNotice,
        });
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    }

    render() {
        const { t } = this.props;
        const icons = this.imageImport(require.context("../../../assets/images/profile/icons", false, /\.(png|jpe?g|svg)$/));
        return (
            <Auxiliary>
                {this.state.showNotice &&
                    <MobileImportantNotice
                        notice="deposit"
                        toggleNoticeHandler={this.toggleNoticeHandler}
                    />
                }
                <section className="pf-m-dashboard dnw-section">
                    <MobileSubHeader
                        // className="bg-white"
                        returnPage="/me"
                        header={t('profile-sidebar.deposit')}
                        modalHeader={t('deposit.notice')}
                        modalHandler={this.toggleNoticeHandler}
                    />
                    <div className="pd-form-m-col">
                        {this.props.loading ? (
                            <div className="input-group-col">
                                <Skeleton count={1} height={100} />
                            </div>
                        ) : (
                            <>
                                <div>
                                    <label className="col-form-label">{t('deposit.deposit-option')}:</label>
                                    {this.props.depositEstTime && (
                                        <>
                                            <div className="transfer-duration-label">
                                                {this.props.depositEstTime === "00:00:00" ? (
                                                    <>
                                                     <Icon icon="ant-design:thunderbolt-outlined" />
                                                        {t("deposit.instant-deposit")}
                                                    </> 
                                                ) : (
                                                    <>
                                                        <Icon icon="ic:baseline-alarm" />
                                                        {t("deposit.deposit-in")} {this.props.depositEstTime.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}

                                                    </>
                                                )
                                                }
                                            </div>
                                        </>
                                    )}
                                    <div className="input-group-col">
                                        <div className="form-check-group">
                                            {this.props.transactionChannel.map((val, idx) => {
                                                if (val.dp_status === 1) {
                                                    return (
                                                        <div
                                                            className="form-check form-check-inline four-check"
                                                            onClick={(event) => this.props.optionSelectHandler(event, val.slug, val.id, val.deposit_est_time)}
                                                            key={idx}
                                                        >
                                                            <input
                                                                className="btn-check"
                                                                type="radio"
                                                                name="depositOption"
                                                                id={`deposit${val.slug}`}
                                                                value={val.slug}
                                                                onChange={() => { }}
                                                                checked={this.props.depositOption === val.slug ? true : false}
                                                            />
                                                            <label className="btn form-check-label" htmlFor={`deposit${val.slug}`}>
                                                                <img src={icons[`${val.slug}.svg`]} alt={val.slug} />
                                                                <p>{t(`transaction-channel.${val.slug}`)}</p>
                                                                {val.deposit_est_time && (
                                                                <>
                                                                    <div className="transfer-duration">
                                                                    {val.deposit_est_time === "00:00:00" ? (
                                                                        <>
                                                                            <Icon icon="ant-design:thunderbolt-outlined" />
                                                                                {t("deposit.instant")}
                                                                        </>
                                                                        ) : (
                                                                        <>
                                                                            <Icon icon="ic:baseline-alarm" />
                                                                                {t("deposit.deposit-in")} {val.deposit_est_time.replace("Hour", t("global.hour")).replace("Min", t("global.minute")).replace("Sec", t("global.second"))}
                                                                        </>
                                                                        )}
                                                                    </div>
                                                                    </>
                                                                )}
                                                            </label>
                                                        </div>
                                                    );
                                                }
                                                else {
                                                    return null;
                                                }
                                            })}
                                        </div>
                                    </div>
                                </div>
                                {this.props.depositOption === 'quick-pay' ? (
                                    <QuickPay
                                        redirectHandler={this.props.redirectHandler}
                                        {...this.props}
                                    />
                                ) : this.props.depositOption === 'bank-transfer' ? (
                                    <BankTransfer
                                        redirectHandler={this.props.redirectHandler}
                                        {...this.props}
                                    />
                                ) : this.props.depositOption === 'e-wallet' ? (
                                    <EWallet
                                        redirectHandler={this.props.redirectHandler}
                                        {...this.props}
                                    />
                                ) : this.props.depositOption === "crypto" ? (
                                    <Crypto
                                        redirectHandler={this.props.redirectHandler}
                                        {...this.props}
                                    />
                                ) : (
                                    <></>
                                )}
                            </>
                        )}
                    </div>
                </section >
            </Auxiliary >
        );
    }
}

export default (withNamespaces("translation")(MobileDeposit));
