import React, { Component } from "react";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import SideBar from "../../components/SideBar/SideBar";
import { isDesktop } from "react-device-detect";
import Logo from "../../assets/images/brand-logo.png";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

export class ErrorPageMobile extends Component {
  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(
      require.context(
        "../../assets/images/miscellaneous",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    return (
      <Auxiliary>
        {
          isDesktop ? (
            <>
              <section className="miscellaneous desktop">
                <div className="x-container-no-m">
                  <div className="miscellaneous-content">
                    <div className="miscellaneous-body">
                      <img src={img["error-page.webp"]} alt="maintenance" className="left-image" />
                    </div>
                    <div className="miscellaneous-body">
                      <img src={Logo} alt="R77 logo" className="logo-image" />
                      <h3>{t("miscellaneous.error-page")}</h3>
                      <Link className="btn enter-btn-blue"
                        to={{ pathname: "/contact-us" }}>
                        {t("contact-us.contact-us")}
                      </Link>
                    </div>
                  </div>
                </div>
              </section>
            </>) : (

            <section className="miscellaneous mobile">
              <SideBar />
              <div className="m-container">
                <div className="miscellaneous-content">
                  <img src={img["error-page.webp"]} alt="Error Page" />
                  <h4>{t("miscellaneous.error-page")}</h4>
                  <Link className="btn-gradient-blue"
                    to={{ pathname: "/contact-us" }}>
                    {t("contact-us.contact-us")}
                  </Link>
                </div>
              </div>
            </section>
          )

        }
      </Auxiliary>
    );
  }
}

export default (withNamespaces("translation")(ErrorPageMobile));
