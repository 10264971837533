import React, { Component } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { LazyLoadImage } from 'react-lazy-load-image-component';

//Miscellaneous
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { getPromos, showPromos } from "../../store/ducks/promos/actions";
import { isDesktop } from 'react-device-detect';

//Components
import MobileEvents from "./MobileEvents";
import { promoSeo } from "../../components/SeoMeta/SeoMeta";

const navList = [
  { id: 1, icon: "all-icon", label: "All" },
  { id: 2, icon: "lc-icon", label: "Live Casino" },
  { id: 3, icon: "sport-icon", label: "Sport" },
  { id: 4, icon: "slot-icon", label: "Slots" },
  { id: 5, icon: "game-icon", label: "Games" },
  { id: 6, icon: "p2p-icon", label: "P2P" },
  { id: 7, icon: "lottery-icon", label: "Lottery" },
];

let promotionCategoryArry = [];

const promos = [
  { id: 1, promosId: 1, img: "promos-1", titleImg: "" },
  { id: 2, promosId: 1, img: "promos-2", titleImg: "" },
  { id: 3, promosId: 2, img: "promos-3", titleImg: "lc-title" },
  // { id: 4, promosId: 5, img: "promos-4", titleImg: "game-title" },
  { id: 4, promosId: 5, img: "promos-3", titleImg: "game-title" },
];

class Events extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      currentSelectPromoCategoryId: '',
      showPromosImage: '',
      showPromosTitle: '',
      showPromosContent: ''
    };
  }

  fetchData() {
    const showPromosData = {};
    // showPromosData.country_id = this.props.currentUser.country_id || "1";
    showPromosData.category_id = ""; // All Promo Category
    // showPromosData.lang_id = this.props.currentUser.language_id || "1";
    // showPromosData.member_group_id = this.props.currentUser.group_id || "";
    // console.log(showPromosData);

    this.props.getPromos(showPromosData);
  }

  componentDidMount = () => {
    this.fetchData();
  };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.promos_data !== this.props.promos_data) {
      if (nextProps.promos_data.responseCode === 200) {
        let data = nextProps.promos_data.data.promotion_category;
        Object.keys(data).map((item, i) => {
          return promotionCategoryArry.push({
            'id': i,
            'value': data[item]
          });
        })
        this.setState({
          isLoading: false,
          promosList: nextProps.promos_data.data.promotion,
          promotion_categories: nextProps.promos_data.data.promotion_category
        });
      } else {
        return false;
      }
    }

    if (nextProps.show_promos_data !== this.props.show_promos_data) {
      if (nextProps.show_promos_data.responseCode === 200) {
        this.setState({
          isLoading: false,
          showPromosImage: nextProps.show_promos_data.data[0].image,
          showPromosTitle: nextProps.show_promos_data.data[0].title,
          showPromosContent: nextProps.show_promos_data.data[0].content,
          openModal: true,
        });
      } else {
        this.setState({
          isLoading: false,
          openModal: false,
        });
      }
    }
    return true;
  }

  toggleActiveCtg = (id) => {
    const filterPromos = promos.filter((promos) => promos.promosId === id);
    if (id !== 1) {
      this.setState({
        activeCtg: id,
        promosList: filterPromos,
      });
    } else {
      this.setState({
        activeCtg: id,
        promosList: promos,
      });
    }
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  promoCategoryHandler = (event, category_id) => {
    event.preventDefault();

    const showPromosData = {};
    // showPromosData.country_id = this.props.currentUser.country_id || "1";
    showPromosData.category_id = category_id;
    // showPromosData.lang_id = this.props.currentUser.language_id || "1";
    // showPromosData.member_group_id = this.props.currentUser.group_id || "";

    this.setState({
      currentSelectPromoCategoryId: category_id,
      isLoading: true,
    })

    this.props.getPromos(showPromosData);
  }

  render() {
    const { t } = this.props;
    const banner = this.imageImport(require.context("../../assets/images/banners", false, /\.(png|jpe?g|svg|webp)$/));
    const img = this.imageImport(
      require.context("../../assets/images/promos", false, /\.(png|jpe?g|svg|webp)$/)
    );
    const icon = this.imageImport(
      require.context("../../assets/images/icons", false, /\.(png|jpe?g|svg|webp)$/)
    );

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //   imageLanguage = "-ms"
      //   break;
      default:
        imageLanguage = ""
        break;
    }

    return (
      <Auxiliary>
        {promoSeo}
        {
          isDesktop ? (
            <>
              <div className="banner-col">
                <div className="banner-item">
                  <img src={banner[`promos-banner${imageLanguage}.jpg`]} alt="R77 Promos" />
                </div>
              </div>
              <section className="promos-container">
                <div className="x-container">
                  <div className="row g-3">
                    <div className="col">
                      <span className="card-shadow">
                        <img className="sideNav-bg" src={img["sideBar-bg.webp"]} alt="sideBar-bg" />
                      </span>
                      <ul className="promos-sideNav-container gap-3">

                        <li className={(this.state.currentSelectPromoCategoryId === '') ? 'active' : ''}>
                          <div className="promos-sideNav" role="button" onClick={(event) => this.promoCategoryHandler(event, '')}>
                            <span>
                              <img
                                className="promos-icon"
                                src={icon[`all-icon.png`]}
                                alt={"all"}
                              />
                            </span>
                            <p>{t("global.all")}</p>
                          </div>
                        </li>
                        {this.state.promotion_categories &&
                          this.state.promotion_categories.map((item, index) => (
                            <li
                              key={index}
                              className={`${this.state.currentSelectPromoCategoryId === item.id ? "active" : ""
                                }`}
                              onClick={(event) => this.promoCategoryHandler(event, item.id)}
                            >
                              <div className="promos-sideNav">
                                <span>
                                  <img
                                    className="promos-icon"
                                    src={icon[`${item.title.toLowerCase() === 'live casino' ? "lc" : item.title.toLowerCase()}-icon.png`]}
                                    alt={item.icon}
                                  />
                                </span>
                                <p>{t(`promos.${item.title}`)}</p>
                              </div>
                            </li>
                          ))}
                      </ul>
                    </div>

                    <div className="col d-grid gap-3">
                      {this.state.promosList &&
                        this.state.promosList.map((item, index) => (
                          <Link to={{ pathname: "/promos-detail/" + item.promotionId, params: { promo_id: item.promotionId } }} className="promos-list position-relative">
                            <span
                              className="card-shadow"
                              key={index}
                            >
                              <LazyLoadImage className="w-100 h-auto" src={item.promotionImage} alt={item.promotionName} effect="blur" />
                            </span>
                            <img className="promos-title" src={img[`${item.promotionCategoriesTitle.replace(/ /g, "-").toLowerCase()}-cat.webp`]} alt={item.promotionCategoriesTitle} />
                          </Link>
                        ))}
                    </div>
                  </div>
                </div>
              </section>
            </>
          ) : (
            <MobileEvents
              navList={navList}
              {...this.state}
              promoCategoryHandler={this.promoCategoryHandler}
            />
          )
        }



      </Auxiliary>
    );
  }
}

const mapStateToProps = (state) => {
  const { promos } = state;

  return {
    promos_data: promos.promos_data,
    show_promos_data: promos.show_promos_data,
    currentUser: state.currentUser.data
  }
}

const mapDispatchToProps = {
  getPromos,
  showPromos
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Events));
