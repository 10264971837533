import React, { Component } from 'react';
import Modal from "react-modal";

//Miscellaneous 
import { isDesktop } from "react-device-detect";
import Auxiliary from '../../hoc/Auxiliary/Auxiliary';
import MobileSubHeader from '../PageHeader/MobileSubHeader';

export class BonusCodeModal extends Component {

  closeBonusCodeModal = () => {
    this.props.toggleBonusCodeModal();
  }

  render() {
    const t = this.props.t;
    return (
      <Auxiliary>
        {isDesktop ? (
          <Modal
            isOpen={this.props.showBonusCodeModal}
            closeTimeoutMS={500}
            portalClassName={`modal-address-form global-modal`}
            className="modal modal-dialog modal-dialog-centered"
            ariaHideApp={false}
            onRequestClose={this.props.toggleBonusCodeModal}
          >

            <div className="modal-content">
              <div className="modal-header">
                <div>
                  <h5 className="modal-title">{t('global.insert')} {t('transaction-type.bonus-code')}</h5>
                  <small>
                  {t('transaction-type.redeem-prize')}
                  </small>
                </div>
                {/* <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleBonusCodeModal} /> */}
              </div>
              <div className="modal-body">
                <input
                  type="text"
                  onChange={(e) => {
                    e.target.value = e.target.value.toUpperCase();

                    this.props.onVoucherCodeChange(e.target.value);
                  }}
                  className="form-control mb-3"
                  placeholder= {`${t("global.insert")} ${t("transaction-type.bonus-code")}`}
                  autoCapitalize="on"
                />

                <div className='d-flex my-3'>
                  <button type="submit" className="btn btn-gray w-100" onClick={this.props.toggleBonusCodeModal}>
                    {t('global.cancel')}
                  </button>
                  <button type="submit" className="btn btn-gradient-blue mx-3 w-100" onClick={() => this.props.applyBonusHandler('voucher-code')}>
                    {t('global.confirm')}
                  </button>
                </div>
              </div>
            </div>
          </Modal>
        ) : (

          <section className="checkout">
            <div className={`pf-m-dashboard ${this.props.showBonusCodeModal ? 'd-block pe-auto on-top mall' : 'd-none'}`}>
              <MobileSubHeader
                // className="bg-white"
                closeModalHandler={this.closeBonusCodeModal}
                header={t('transaction-type.bonus-code')}
                subPage='/'
              />
              
              <div className='forms-col my-3'>
                <div className='pd-form-m-col'>
                  <div className='input-group-col'>
                    <div className="py-2">
                      <label className="col-form-label lh-1 p-0 m-0">{t('transaction-type.bonus-code')}</label>
                      <div className="input-group has-validation">
                        <input type="text" 
                          onChange={(e) => {
                            e.target.value = e.target.value.toUpperCase();
        
                            this.props.onVoucherCodeChange(e.target.value);
                          }}
                          value={this.props.bonusCode}
                          className="form-control px-0 "
                          placeholder= {`${t("global.insert")} ${t("transaction-type.bonus-code")}`} />
                        
                      </div>
                    </div>
                  </div>
                  <button type='submit' className='btn btn-gradient-blue mx-3' onClick={() => this.props.applyBonusHandler('voucher-code')}>{t("global.submit") }</button>
                </div>
              </div>
            </div>
          </section>

        )
        }

      </Auxiliary>
    )
  }
}

export default BonusCodeModal