import React, { Component } from "react";
import Picker from "react-scrollable-picker";
import { withNamespaces } from "react-i18next";
// import Swal from "sweetalert2";

export class Gender extends Component {
  constructor(props) {
    super(props);
    this.state = {
      valueGroups: {
        gender: this.props.formFilter.gender,
      },
    };
  }

  componentDidMount() {
    // Swal.fire({
    //   iconHtml: '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">',
    //   title: 'Successful',
    //   text: 'Your mobile number has be verified successfully. Thank you.',
    //   customClass: {
    //     container: 'swal-mobile-container sucess',
    //     icon: 'swal-success-icon'
    //   }
    // })
    // Swal.fire({
    //   iconHtml:
    //     '<img src="https://api.iconify.design/mdi:close.svg?color=%23ea4e3d">',
    //   title: "Failed",
    //   text: "We have encountered a system issue, sorry for the inconvenience caused. Please try it again.",
    //   confirmButtonText: "Try Again",
    //   showCancelButton: true,
    //   customClass: {
    //     container: "swal-mobile-container failed",
    //     icon: "swal-failed-icon", 
    //   },
    // });
    // Swal.fire({
    //   iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
    //   title: 'Sorry',
    //   text: 'Invalid Code. Please try again.',
    //   customClass: {
    //     container: 'swal-mobile-container sorry',
    //     icon: 'swal-sorry-icon'
    //   }
    // })
    // Swal.fire({
    //   iconHtml:
    //     '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">',
    //   title: "Reminder",
    //   text: "Once deleted, it cannot be recovered. Are you sure you want to delete it?",
    //   confirmButtonText: "Delete",
    //   showCancelButton: true,
    //   customClass: {
    //     container: "swal-mobile-container reminder",
    //     icon: "swal-reminder-icon",
    //   },
    // });
        // Swal.fire({
    //   iconHtml:
    // '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">'
    // title: t("global.confirmation"),
    // text: t("global.would-you-like-to-proceed") + "?",
    //   confirmButtonText: "Confirm",
    //   showCancelButton: true,
    //   customClass: {
    //     container: "swal-mobile-container confirm",
    //     icon: "swal-confirm-icon",
    //   },
    // });
  }

  shouldComponentUpdate(nextProps)
  {    
      if (nextProps.formFilter.gender !== this.state.valueGroups.gender && nextProps.formFilter.gender !== undefined) {
        this.setState({
            valueGroups: {
              gender: nextProps.formFilter.gender
            }
        });
      }
      return true;
  }

  componentDidUpdate(nextProps, nextState) 
  {
        if (nextState.valueGroups.gender !== this.state.valueGroups.gender && nextState.valueGroups.gender !== undefined) 
        {
            this.props.onChangeGenderHandler(this.state.valueGroups.gender);
        }
        return true;
    }

  // Update the value in response to user picking event
  handleChange = (name, value) => {
    this.setState(({ valueGroups }) => ({
      valueGroups: {
        ...valueGroups,
        [name]: value,
      },
    }));
  };

  render() {
    const { t } = this.props;
    const { valueGroups } = this.state;
    const optionGroups = {
      gender: [
        { value: "M", label: `${t("profile.male")}` },
        { value: "F", label: `${t("profile.female")}` },
      ],
    }
    return (
      <section className={`mobile-asset-section ${this.props.showGenderSelect && "open"}`}>
        <div className="mobile-asset-content">
          <div className="mobile-asset-header" id="date-scroller">
            <button
              type="button"
              className="btn btn-cancel"
              data-bs-dismiss="mobile-asset-section"
              onClick={this.props.toggleShowGender}
            >
              {t("global.cancel")}
            </button>
            <button type="submit" className="btn btn-ok" onClick={this.props.toggleShowGender}>
              {t("global.ok")}
            </button>
          </div>
          <div className="mobile-asset-body">
            <Picker
              optionGroups={optionGroups}
              valueGroups={valueGroups}
              itemHeight={110}
              height={700}
              onChange={this.handleChange}
            />
          </div>
        </div>
        <div className="overlay" id="menu-overlay" onClick={this.props.toggleShowGender} />
      </section>
    );
  }
}

export default (withNamespaces("translation")(Gender));
