import React, { Component } from "react";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { isDesktop } from "react-device-detect";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Reveal } from "react-awesome-reveal";
import { keyframes } from "@emotion/react";

//Components
import ProfileSideBar from "../ProfileSideBar/ProfileSideBar";
import BankingDetails from "./BankingDetails";
import CryptoDetails from "./CryptoDetails";
import { getTopProfile, getInterest } from "../../../store/ducks/profile/actions";
import MobileWallet from "./MobileWallet";
import { Icon } from "@iconify/react";
import Modal from "react-modal";

import X33CoinModal from "../../../components/X33CoinModal/X33CoinModal";

class Wallet extends Component {
    constructor(props) {
        super(props);
        this.state = {
            pageShow: "banking_details",
            loading: true,
            refresh: true,
            login: localStorage.getItem("accessToken") ? true : false,
            openInterestModal: false,
            openCoinModal: false,
            interestPercentage: 0,
            totalInterestPayout: "",
            referrerInterestPercentage: 0,
            minPayout: 0,
        };
    }

    componentDidMount() {
        this.props.getTopProfile();

        this.props.getInterest();

        if (!isDesktop) {
            if (this.state.login) {
                this.mobileTopProfileInterval = setInterval(() => {
                    this.props.getTopProfile();
                }, 10000);
            } else {
                clearInterval(this.mobileTopProfileInterval);
            }
        }
    }

    componentWillUnmount() {
        if (!isDesktop) {
            clearInterval(this.mobileTopProfileInterval);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        // const { t } = this.props;
        if (nextProps.topProfileData !== undefined && nextProps.topProfileData !== this.props.topProfileData) {
            if (nextProps.topProfileData.responseCode === 200) {
                this.setState({
                    loading: false,
                    refresh: false,
                });
            }
        }

        if (nextProps.get_interest_result !== undefined && nextProps.get_interest_result !== this.props.get_interest_result) {
            if (nextProps.get_interest_result.responseCode === 200) {
                this.setState({
                    loading: false,
                    refresh: false,
                    interestPercentage: nextProps.get_interest_result.data.stakingSetting.interest_percentage,
                    totalInterestPayout: nextProps.get_interest_result.data.payout,
                    referrerInterestPercentage: nextProps.get_interest_result.data.stakingSetting.referrer_interest_percentage,
                    minPayout: nextProps.get_interest_result.data.stakingSetting.min_payout,
                });
            }
        }

        return true;
    }

    imageImport = (r) => {
        let images = {};
        r.keys().map((item, index) => {
            return (images[item.replace("./", "")] = r(item)["default"]);
        });
        return images;
    };

    changeTabHandler = (e) => {
        e.preventDefault();
        this.setState({
            pageShow: e.target.id,
        });
    };

    toggleInterestModal = () => {
        this.setState({
            openInterestModal: !this.state.openInterestModal,
        });
    };

    toggleCoinModal = () => {
        this.setState({
            openCoinModal: !this.state.openCoinModal,
        });
    };

    render() {
        const { t } = this.props;
        // const promos = this.imageImport(require.context("../../../assets/images/profile/promos", false, /\.(png|jpe?g|svg)$/));
        const icons = this.imageImport(require.context("../../../assets/images/profile/icons", false, /\.(png|jpe?g|svg)$/));
        let topProfileData = null;

        if (!this.state.loading && this.props.topProfileData && this.props.topProfileData.data) {
            topProfileData = this.props.topProfileData.data;
        }

        const fadeUp = keyframes`
            from {
            opacity: 0;
            transform: translate3d(0, 50px ,0);
            }
    
            to {
            opacity: 1;
            transform: translate3d(0, 0, 0);
            }
        `;

        return (
            <Auxiliary>
                {/* Interest Modal */}
                <Modal
                    isOpen={this.state.openInterestModal}
                    closeTimeoutMS={500}
                    portalClassName={`global-modal`}
                    className="modal modal-dialog modal-dialog-centered modal-pd"
                    ariaHideApp={false}
                    onRequestClose={this.toggleInterestModal}
                >
                    <div className={`modal-content my-wallet-modal ${isDesktop ? "" : "mobile-modal-bg-white p-0"}`}>
                        <div className={`modal-header border-bottom ${!isDesktop && " p-3"}`}>
                            <div className={`${isDesktop ? "pb-3" : ""}`}>
                                <h5>{t("top-profile.interest-title")}</h5>
                                {/* <p>{t("top-profile.upgrade-business-acc-role")}</p> */}
                            </div>
                            {isDesktop && <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.toggleInterestModal} />}
                        </div>
                        <div className={`modal-body ${!isDesktop ? "p-3" : " mt-3"}`}>
                            <h6 className="pt-0">{t("vip.term-condition")}</h6>
                            <ol className="my-wallet-modal-content">
                                <li>
                                    <span>{t("top-profile.interest-tnc-1")}</span>
                                </li>
                                <li>
                                    <span>
                                        {t("top-profile.interest-tnc-2", {
                                            interest_rate: Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 0 }).format(
                                                this.state.interestPercentage || 0.0
                                            ),
                                            referrer_rate: Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 0 }).format(
                                                this.state.referrerInterestPercentage || 0.0
                                            ),
                                        })}
                                    </span>
                                </li>
                                <li>
                                    <span>
                                        {t("top-profile.interest-tnc-3")} {this.props.currentUser.currency}{" "}
                                        {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(this.state.minPayout || 0.0)}{" "}
                                        {t("top-profile.interest-tnc-3-continue")}
                                    </span>
                                </li>
                                <li>
                                    <span>{t("top-profile.interest-tnc-4")}</span>
                                </li>
                                <li>
                                    <span>{t("top-profile.interest-tnc-5")}</span>
                                </li>
                                <li>
                                    <span>{t("top-profile.interest-tnc-6")}</span>
                                </li>
                            </ol>
                            {isDesktop && (
                                <button className="btn btn-gradient-blue mt-3" onClick={this.toggleInterestModal}>
                                    {t("global.ok")}
                                </button>
                            )}
                        </div>
                        {!isDesktop && (
                            <button type="button" className="btn btn-dark-gray" onClick={this.toggleInterestModal}>
                                {t("global.ok")}
                            </button>
                        )}
                    </div>
                </Modal>
                {/* X33 Coin Learn More Modal */}
                <X33CoinModal openCoinModal={this.state.openCoinModal} toggleCoinModal={this.toggleCoinModal} currentUser={this.props.currentUser} />
                {isDesktop ? (
                    <section className="pf-dashboard">
                        <div className="x-container">
                            <ProfileSideBar />
                            <Reveal className="pf-fade-col" keyframes={fadeUp} duration={1000} triggerOnce>
                                <div className="row g-3">
                                    <div className="col-12">
                                        <div className="pd-card-col">
                                            <h4>{t("profile-sidebar.my-wallet")}</h4>
                                            <div className="pd-content">
                                                <div className="my-wallet-col">
                                                    <div className="row g-3">
                                                        <div className="col-6">
                                                            <div className="my-wallet-content">
                                                                <div className="wallet-container">
                                                                    <img className="active" src={icons["wallet-ico.svg"]} alt="wallet-ico" />
                                                                    <div className="w-100">
                                                                        <label>{t("top-profile.main-wallet")}</label>
                                                                        <p>
                                                                            {this.state.loading ? (
                                                                                <Skeleton count={1} />
                                                                            ) : (
                                                                                <>
                                                                                    {this.props.currentUser.currency}
                                                                                    <span className="ms-2">
                                                                                        {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                                            topProfileData.topProfile.balance || 0.0
                                                                                        )}{" "}
                                                                                    </span>
                                                                                </>
                                                                            )}
                                                                        </p>

                                                                        <div className="interest-tnc" onClick={this.toggleInterestModal}>
                                                                            <Icon icon="material-symbols:info" />
                                                                            {this.state.loading ? (
                                                                                <Skeleton count={1} />
                                                                            ) : (
                                                                                <span>
                                                                                    {t("top-profile.include-interest", {
                                                                                        rate: Intl.NumberFormat("en-US", { maximumFractionDigits: 1, minimumFractionDigits: 0 }).format(
                                                                                            this.state.interestPercentage || 0.0
                                                                                        )
                                                                                    })}
                                                                                </span>
                                                                            )}
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="interest-container">
                                                                    <label>{t("top-profile.total-interest")}</label>
                                                                    <p className="w-100 text-center">
                                                                        {this.state.loading ? (
                                                                            <Skeleton count={1} />
                                                                        ) : (
                                                                            <>
                                                                                {this.props.currentUser.currency}
                                                                                <span className="ms-2">
                                                                                    {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                                        this.state.totalInterestPayout || 0.0
                                                                                    )}{" "}
                                                                                </span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="my-wallet-content">
                                                                <div className="coin-container">
                                                                    <img className="active" src={icons["x33-coin.svg"]} alt="R77-coin" />
                                                                    <div className="w-100">
                                                                        <label>{t("top-profile.r77-coin")}</label>
                                                                        <p>
                                                                            {this.state.loading ? (
                                                                                <Skeleton count={1} />
                                                                            ) : (
                                                                                <span>
                                                                                    {Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(
                                                                                        topProfileData.topProfile.coin || 0
                                                                                    )}{" "}
                                                                                </span>
                                                                            )}
                                                                        </p>
                                                                        <span className="my-learn-more" onClick={this.toggleCoinModal}>
                                                                            {t("global.learn-more")}
                                                                        </span>
                                                                    </div>
                                                                    <Icon icon="uil:angle-right" onClick={this.toggleCoinModal} />
                                                                </div>
                                                                {/* <div className="interest-container">
                                                                    <label>{t("top-profile.today-earning")}</label>
                                                                    <p className="w-100 text-center">
                                                                        {this.state.loading ? (
                                                                            <Skeleton count={1} />
                                                                        ) : (
                                                                            <>
                                                                                <span>{Intl.NumberFormat("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 }).format(topProfileData.topProfile.balance || 0.00)}{" "}</span>
                                                                            </>
                                                                        )}
                                                                    </p>
                                                                </div> */}
                                                            </div>
                                                            {/* <div>
                                                            <label>{t("top-profile.x33-coin")}</label>
                                                            <p>
                                                                {this.state.loading ? (
                                                                    <Skeleton count={1}/>
                                                                ) : (
                                                                    <span>
                                                                            {Intl.NumberFormat("en-US", { maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(topProfileData.topProfile.coin || 0)}{" "}
                                                                    </span>
                                                                )}
                                                            </p>
                                                        </div> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="pd-card-col">
                                            <h6>{t("account-details.account-details")}</h6>
                                            <div className="pd-content">
                                                <div className="fd-col">
                                                    <ul className="nav nav-tabs" role="tablist">
                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                className="nav-link active"
                                                                id="banking_details"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#banking-details"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="banking-details"
                                                                aria-selected="true"
                                                                onClick={(event) => this.changeTabHandler(event)}
                                                            >
                                                                {t("account-details.banking-details")}
                                                            </button>
                                                        </li>

                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                className="nav-link"
                                                                id="crypto_details"
                                                                data-bs-toggle="tab"
                                                                data-bs-target="#crypto-details"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="crypto-details"
                                                                aria-selected="false"
                                                                onClick={(event) => this.changeTabHandler(event)}
                                                            >
                                                                {t("account-details.crypto-details")}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                    <div className="tab-content">
                                                        {this.state.pageShow === "banking_details" && <BankingDetails />}
                                                        {this.state.pageShow === "crypto_details" && <CryptoDetails />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Reveal>
                        </div>
                    </section>
                ) : (
                    <MobileWallet
                        {...this.state}
                        {...this.props}
                        topProfileData={topProfileData}
                        toggleInterestModal={this.toggleInterestModal}
                        toggleCoinModal={this.toggleCoinModal}
                    />
                )}
            </Auxiliary>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        topProfileData: state.profile.top_profile_data,
        currentUser: state.currentUser.data,
        get_interest_result: state.profile.get_interest_result,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getTopProfile: () => dispatch(getTopProfile()),
        getInterest: () => dispatch(getInterest()),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(Wallet));
