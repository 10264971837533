import React, { Component } from "react";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";

import DomPurify from "../../../components/Sanitize/DomPurify";

//Icons
// import { Icon } from "@iconify/react";

class MobileInnerMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    // console.log("[componentDidMount]");
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg)$/
      )
    );
    const { t } = this.props;
    // let lastIndex = this.props.data.length - 1;
    let data = this.props.data ? this.props.data.map((item, idx) => {
      return (
        <div className="pd-content">
          <div className="msg-inner-col">
            <div className="msg-inner-header">
              <div className="msg-icon-col">
                <img src={icons[`${item.icon}.svg`]} alt={item.icon} />
              </div>
              <div className="msg-inner-title">
                <h5><span>{item.title}</span></h5>
                <p>{item.date}</p>
              </div>
            </div>
            <div className="msg-inner-content">
              <p>
                {/* {item.content.replace("&nbsp;","")} */}
                <DomPurify text={item.html_content} />
              </p>
            </div>
          </div>
        </div>
      );
    }) : [];
    return (
      <Auxiliary>
        <section className="pf-m-dashboard">
          <MobileSubHeader
            // className="bg-white"
            closeModalHandler={(event) => this.props.handleChangePage(event, "listing", 0, [], "", 0, this.props.activePage)}
            header={t(`message.details.${this.props.msgType}`)}
            style={{ justifyContent: "unset" }}
          />
          <div className="pd-m-card-col">
            {data.length > 0 && data[this.props.currentIndex]}
          </div>
        </section >
      </Auxiliary >
    );
  }
}

export default withNamespaces("translation")(MobileInnerMessage);
