import React, { Component } from "react";
import { Link, NavLink } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";

//Miscellaneous
import Swal from "sweetalert2";
import { isDesktop } from 'react-device-detect';
import { getUserData } from "../../store/ducks/currentUser/actions";
import { getTopProfile } from "../../store/ducks/profile/actions";

class MobilePageHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      login: localStorage.getItem("accessToken") ? true : false,

      currentEvent: "",
    };
  }

  componentDidMount() {
    if (this.state.login) {
      this.props.getTopProfile();

      this.mobileTopProfileInterval = setInterval(() => {
        this.props.getTopProfile();
      }, 10000);
    } else {
      clearInterval(this.mobileTopProfileInterval);
    }
  }

  componentWillUnmount() {
    clearInterval(this.mobileTopProfileInterval);
  }

  accessHandler = (e) => {
    if (!localStorage.getItem("accessToken")) {
      const { t } = this.props;
      Swal.fire({
        iconHtml: '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">',
        icon: "info",
        title: t("global.sorry"),
        text: t("global.login-proceed"),
        confirmButtonText: t("global.ok"),
        customClass: (!isDesktop) ? {
          container: 'swal-mobile-container sorry',
          icon: 'swal-sorry-icon',
        } : [],
      });
    } else {
      if (e.target.name === 'wallet-btn') {
        window.location.href = '/deposit';
      }
      if (e.target.name === 'withdrawal-btn') {
        window.location.href = '/withdrawal';
      }
    }
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    const img = this.imageImport(
      require.context(
        "../../assets/images/mobile-page-header/",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const christmas = this.imageImport(
      require.context(
        "../../assets/images/home/christmas",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );
    const chinese = this.imageImport(
      require.context(
        "../../assets/images/home/chinese",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const raya = this.imageImport(
      require.context(
        "../../assets/images/home/raya",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const avatar = this.imageImport(
      require.context(
        "../../assets/images/profile/profile-icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    let topProfileData = null;
    if (!this.state.loading) {
      topProfileData =
        this.props.topProfileData && this.props.topProfileData.data;
    }

    return (
      <>
        <section className="mobile-page-header-section">
          <div className="mobile-page-header-left">
            {
              this.state.login ? (
                <>
                  <div className="d-flex ">
                    <p>{this.props.currentUser.data.username}</p>
                    <span className="vip-icon">  <img src={avatar[`vip-${this.props.currentUser.data.user_group_level}.webp`]} alt="vip-badge"/> </span>
                    {/* <small className="vip-icon">
                      {t(`vip.vip-${this.props.currentUser.data.user_group_level}`)}
                    </small> */}
                  </div>
                  <h2>
                    {new Intl.NumberFormat("en-US", num_format).format(
                      (topProfileData &&
                        topProfileData.topProfile &&
                        topProfileData.topProfile.balance) ||
                      0.0
                    )}
                    <img src={img["cash-icon-home-mobile.webp"]} alt="show pwd" />
                  </h2>
                </>
              ) : (

                <NavLink to={{ pathname: "/login" }} className="enter-btn-blue">
                  {t("page-header.login")}{" "}/{" "}{t("page-header.register")}
                </NavLink>
              )
            }
          </div>
          <div className="mobile-page-header-right">
            <ul className="d-flex">
              <li>
                <button class="mphr-link" name="wallet-btn" onClick={(e) => this.accessHandler(e)}>
                  {this.state.currentEvent ? (
                    <img
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["deposit-icon.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["deposit-icon.webp"]
                            : this.state.currentEvent === "raya" &&
                            raya["deposit-icon.webp"]

                      }
                      alt="show pwd"
                    />
                  ) : (
                    <img
                      src={img["wallet-icon-home-mobile.webp"]}
                      alt="show pwd"
                    />
                  )}
                  {t("page-header.deposit")}
                </button>
              </li>
              <li>
                <button class="mphr-link" name="withdrawal-btn" onClick={(e) => this.accessHandler(e)}>
                  {this.state.currentEvent ? (
                    <img
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["withdraw-icon.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["withdraw-icon.webp"]
                            : this.state.currentEvent === "raya" &&
                            raya["withdraw-icon.webp"]
                      }
                      alt="show pwd"
                    />
                  ) : (
                    <img
                      src={img["cashout-icon-home-mobile.webp"]}
                      alt="show pwd"
                    />
                  )}
                  {t("page-header.withdraw")}
                </button>
              </li>
              <li>
                <Link to={{ pathname: "/vip" }}>
                  {this.state.currentEvent ? (
                    <img
                      src={
                        this.state.currentEvent === "christmas"
                          ? christmas["vip-icon.webp"]
                          : this.state.currentEvent === "cny" ?
                            chinese["vip-icon.webp"]
                            : this.state.currentEvent === "raya" &&
                            raya["vip-icon.webp"]
                      }
                      alt="show pwd"
                    />
                  ) : (
                    <img
                      src={img["vip-icon-home-mobile-active.webp"]}
                      alt="show pwd"
                    />
                  )}
                  {t("page-header.vip")}
                </Link>
              </li>
            </ul>
          </div>
        </section>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser,
    topProfileData: state.profile.top_profile_data,
  };
};

const mapDispatchToProps = {
  getUserData,
  getTopProfile,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(MobilePageHeader));
