import React, { Component } from 'react'
import { Accordion, TabContainer, Nav, NavItem, NavLink, TabContent, TabPane } from 'react-bootstrap'
import { withNamespaces } from "react-i18next";
import Auxiliary from "../../hoc/Auxiliary/Auxiliary";
import { Fade } from "react-awesome-reveal";
import MobileSubHeader from "../../components/PageHeader/MobileSubHeader";
import MobileSelectRadio from "../../components/MobileAsset/MobileSelectRadio/MobileSelectRadio";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

export class MobileInfoCentre extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSelectRadio: false,
      activeTab: 'faq',
      activeOption: '',
      activeKey: 0,
      faqOptions: [],
      ruleOptions: [],
      options: []
    };
  }

  componentDidMount() {
    const { t } = this.props;
    const faqOptions = [
      { label: t("info-centre.general"), key: 0 },
      { label: t("info-centre.my-acc"), key: 1 },
      { label: t("info-centre.payment"), key: 2 },
      { label: t("info-centre.cryptocurrency"), key: 3 },
      { label: t("info-centre.cash-rebates"), key: 4 },
      { label: t("info-centre.products"), key: 5 },
    ];

    const ruleOptions = [
      { label: t("info-centre.info-sport"), key: 0 },
      { label: t("info-centre.info-live-casino"), key: 1 },
      { label: t("info-centre.info-games"), key: 2 },
      { label: t("info-centre.info-lottery"), key: 3 },
      { label: t("info-centre.info-poker"), key: 4 },
    ]

    const newOptions = faqOptions.filter((item) => item.key !== this.state.activeKey)

    this.setState({
      activeOption: t("info-centre.general"),
      faqOptions: faqOptions,
      ruleOptions: ruleOptions,
      options: newOptions
    })

  }

  changeActiveTab = (tab) => {
    const { t } = this.props;

    if (tab === 'faq') {
      const newOptions = this.state.faqOptions.filter((item) => item.key !== 0)
      this.setState({
        activeTab: tab,
        activeOption: t("info-centre.general"),
        activeKey: 0,
        options: newOptions
      })
    } else if (tab === 'gaming') {
      const newOptions = this.state.ruleOptions.filter((item) => item.key !== 0)
      this.setState({
        activeTab: tab,
        activeOption: t("info-centre.info-sport"),
        activeKey: 0,
        options: newOptions
      })
    } else if (tab === 'tnc') {
      this.setState({
        activeTab: tab
      })
    }
  }

  toggleSelectRadio = () => {
    this.setState({
      showSelectRadio: !this.state.showSelectRadio,
    });
  };

  handleOptionChange = (event) => {
    var newOptions;
    if (this.state.activeTab === 'faq') {
      newOptions = this.state.faqOptions.filter((item) => item.key !== event.key)
    } else if (this.state.activeTab === 'gaming') {
      newOptions = this.state.ruleOptions.filter((item) => item.key !== event.key)
    }
    this.setState({
      activeOption: event.label,
      activeKey: event.key,
      options: newOptions
    })
  }


  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    const img = this.imageImport(require.context("../../assets/images/info-centre/mobile", false, /\.(png|jpe?g|svg|webp)$/));

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
      case "zh-CN":
        imageLanguage = "-cn"
        break;
      // case "ms":
      //     imageLanguage = "-ms"
      //     break;
      default:
        imageLanguage = ""
        break;
    }

    // console.log("Active Tab", this.state.activeTab)

    return (
      <Auxiliary>
        <section className='ic-m-section info-center-section'>
          {!localStorage.getItem("browseByApp") &&  
          <MobileSubHeader
            // className="bg-white"
            returnPage="/"
            header={t("footer.info-centre")}
            style={{ justifyContent: "unset" }}
          />}

          <TabContainer defaultActiveKey="faq">
            <Nav className='mobile' variant="pills">
              <NavItem>
                <NavLink eventKey="faq" onClick={() => this.changeActiveTab("faq")}>{t("info-centre.faq")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="tnc" onClick={() => this.changeActiveTab("tnc")}>{t("info-centre.terms-and-conditions")}</NavLink>
              </NavItem>
              <NavItem>
                <NavLink eventKey="gaming" onClick={() => this.changeActiveTab("gaming")}>{t("info-centre.responsible-gaming")}</NavLink>
              </NavItem>
            </Nav>

            {
              // this.state.activeTab !== 'tnc' &&
              // <div className="faq-selection" onClick={this.toggleSelectRadio}>
              //   <p>{this.state.activeOption}</p>
              //   <Icon icon="uil:angle-right" />
              // </div>
            }

            {/* <MobileSelectRadio
              name="infoCentre"
              showSelectRadio={this.state.showSelectRadio}
              toggleHandler={this.toggleSelectRadio}
              onChange={this.handleOptionChange}
              options={this.state.options}
              value={this.state.bank_key}
            /> */}
            <TabContent className='tab-content-container'>
              <TabPane eventKey="faq">
                {
                  this.state.activeTab === 'faq' && this.state.activeKey === 0 && (
                    //General
                  <Fade duration={1000}>
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header><h6>{t("info-centre.general-title-1")}</h6></Accordion.Header>
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-1")}</p>
                          <p>{t("info-centre.general-details-1-2")}</p>
                          {/* Video */}
                          <div className="watch-video mobile" onClick={() => this.props.toggleVideoPlayerModal("register")}>
                            <img src={img['watch-video.webp']}/>
                            <span>{t('info-centre.watch-video-tutorial')}</span>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="1">
                        <Accordion.Header><h6>{t("info-centre.general-title-2")}</h6></Accordion.Header>
                        {/* <Accordion.Body> <p>{t("info-centre.general-details-2")} <Link to={{ pathname: "/register" }}> { window.location.hostname === "localhost" ? window.location.protocol + "//" + window.location.hostname + ":" + window.location.port : window.location.protocol + "//" + window.location.hostname }/register</Link>{t("info-centre.general-details-2-1")}</p></Accordion.Body> */}
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-2")}</p>
                          {/* Video */}
                          <div className="watch-video mobile" onClick={() => this.props.toggleVideoPlayerModal("payment_gateway")}>
                            <img src={img['watch-video.webp']}/>
                            <span>{t('info-centre.watch-video-tutorial')}</span>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="2">
                        <Accordion.Header><h6>{t("info-centre.general-title-3")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-3")}</p>
                          {/* Video */}
                          <div className="watch-video mobile" onClick={() => this.props.toggleVideoPlayerModal("bank_in")}>
                            <img src={img['watch-video.webp']}/>
                            <span>{t('info-centre.watch-video-tutorial')}</span>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="3">
                        <Accordion.Header><h6>{t("info-centre.general-title-4")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          {/* Video */}
                          <div className="watch-video mobile" onClick={() => this.props.toggleVideoPlayerModal("transfer")}>
                            <img src={img['watch-video.webp']}/>
                            <span>{t('info-centre.watch-video-tutorial')}</span>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="4">
                        <Accordion.Header><h6>{t("info-centre.general-title-5")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-5")}</p>
                          <ul>
                            <li>{t("info-centre.gd5-li-1")}</li>
                            <li>{t("info-centre.gd5-li-2")}</li>
                          </ul>                               
                          {/* Video */}
                          <div className="watch-video mobile" onClick={() => this.props.toggleVideoPlayerModal("withdrawal")}>
                            <img src={img['watch-video.webp']}/>
                            <span>{t('info-centre.watch-video-tutorial')}</span>
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="5">
                        <Accordion.Header><h6>{t("info-centre.general-title-6")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-6")}</p>                                                                       
                        </Accordion.Body>                                  
                      </Accordion.Item>

                      <Accordion.Item eventKey="6">
                        <Accordion.Header><h6>{t("info-centre.general-title-7")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-7")}</p>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="7">
                        <Accordion.Header><h6>{t("info-centre.general-title-8")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-8")}</p>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="8">
                        <Accordion.Header><h6>{t("info-centre.general-title-9")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-9")}</p>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="9">
                        <Accordion.Header><h6>{t("info-centre.general-title-10")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          <p>
                            {t("info-centre.general-details-10-1")}
                            <span>
                              {t("info-centre.terms-and-conditions")}.
                            </span>
                            {t("info-centre.general-details-10-2")}
                          </p>
                        </Accordion.Body>
                      </Accordion.Item>

                      <Accordion.Item eventKey="10">
                        <Accordion.Header><h6>{t("info-centre.general-title-11")}</h6></Accordion.Header>                                 
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-11")}</p>
                          <ul>
                            <li>
                              <Link to={{ pathname: "/contact-us"}}>
                                {t("info-centre.gd11-li-1")}                                      
                              </Link>
                            </li>
                            <li>
                              {t("info-centre.gd11-li-2-1")} {" "}
                              <Link to={{ pathname: "/contact-us"}}>
                                {t("info-centre.gd11-li-2-2")}                                      
                              </Link>
                            </li>
                            <li>Wechat ID: {" "} Royal77Asia</li>
                            <li>
                              {t("info-centre.gd11-li-4")} {" "}
                              <Link to={{ pathname: "/contact-us"}}>
                                help@royal77.com                                  
                              </Link>                                        
                            </li>
                          </ul>
                        </Accordion.Body>
                      </Accordion.Item>

                      {/* <Accordion.Item eventKey="3">
                        <Accordion.Header><h6>{t("info-centre.general-title-3")}</h6></Accordion.Header>
                        <Accordion.Body>
                          <p>{t("info-centre.general-details-3")}</p>
                          <ul>
                            <li>{t("info-centre.gd3-li-1")}</li>
                            <li>{t("info-centre.gd3-li-2")}</li>
                            <li>{t("info-centre.gd3-li-3")}</li>
                            <li>{t("info-centre.gd3-li-4")}</li>
                            <li>{t("info-centre.gd3-li-5")}</li>
                            <li>{t("info-centre.gd3-li-6")}</li>
                            <li>{t("info-centre.gd3-li-7")}</li>
                            <li>{t("info-centre.gd3-li-8")}</li>
                            <li>{t("info-centre.gd3-li-9")}</li>
                          </ul>
                          <p>{t("info-centre.pd-notes")}
                            <br />{t("info-centre.gd3-1")}
                            <br />{t("info-centre.gd3-1-1")}</p>
                        </Accordion.Body>
                      </Accordion.Item> */}
                    </Accordion>
                  </Fade>                  
                )}
              </TabPane>
              <TabPane eventKey="gaming">
                {
                  this.state.activeTab === 'gaming' && this.state.activeKey === 0 && (
                    // responsible gaming
                    <div>
                      <p>{t("info-centre.rg-details")}</p>
                      <div className="tab-content">
                        <div className="info-rg" id="rg-main">
                          <p>{t("info-centre.rg-title-1")}</p>

                          <ol className="rg-custom-list">
                            <li>{t("info-centre.rg1-li-1")}</li>
                            <li>{t("info-centre.rg1-li-2")}</li>
                            <li>{t("info-centre.rg1-li-3")}</li>
                            <li>{t("info-centre.rg1-li-4")}</li>
                            <li>{t("info-centre.rg1-li-5")}</li>
                            <li>{t("info-centre.rg1-li-6")}</li>
                            <li>{t("info-centre.rg1-li-7")}</li>
                            <li>{t("info-centre.rg1-li-8")}</li>
                            <li>{t("info-centre.rg1-li-9")}</li>                              
                          </ol>

                          <p>{t("info-centre.rg-title-2")}</p>

                          <ol className="rg-custom-list">
                            <li>{t("info-centre.rg2-li-1")}</li>
                            <li>{t("info-centre.rg2-li-2")}</li>
                            <li>{t("info-centre.rg2-li-3")}</li>
                            <li>{t("info-centre.rg2-li-4")}</li>
                            <li>{t("info-centre.rg2-li-5")}</li>                             
                          </ol>

                          <p>{t("info-centre.rg-title-3")}</p>

                          <p className="blue-text">{t("info-centre.rg-title-4")}</p>
                          <span>{t("info-centre.rg-desc-4")}</span>

                          <p className="blue-text">{t("info-centre.rg-title-5")}</p>
                          <span>{t("info-centre.rg-desc-5-1")}</span>

                          <p>{t("info-centre.rg-desc-5-2")}</p>

                          <p className="blue-text">{t("info-centre.rg-title-6")}</p>
                          <span>{t("info-centre.rg-desc-6")}</span>

                          <ol className="rg-custom-list">
                            <li>
                              {t("info-centre.rg6-li-1")}
                              <a target="_blank" href="https://www.netnanny.com">
                                www.netnanny.com
                              </a>
                            </li>
                            <li>
                              {t("info-centre.rg6-li-2")}
                              <a target="_blank" href="https://www.cybersitter.com">
                                www.cybersitter.com
                              </a>
                            </li>                                             
                          </ol>
                        </div>
                      </div>
                    </div>                                
                )}
              </TabPane>
              <TabPane eventKey="tnc">
                <div>                        
                  <p>{t("info-centre.tnc-details")}</p>
                  <div className="tab-content">
                    {/* faq-general-start*/}
                    <div className="tab-pane fade show active info-tnc" id="tnc-main">                         

                      <ol className="tnc-custom-list mobile">
                          <label>1</label>
                          <li>{t('info-centre.tnc-desc-1')}</li>
                        
                          <label>2</label>
                          <li>{t('info-centre.tnc-desc-2')}</li>
                        
                          <label>3</label>
                          <li>{t('info-centre.tnc-desc-3')}</li>
                        
                          <label>4</label>
                          <li>{t('info-centre.tnc-desc-4')}</li>
                      
                          <label>5</label>
                          <li>{t('info-centre.tnc-desc-5')}</li>
                        
                          <label>6</label>
                          <li>{t('info-centre.tnc-desc-6')}</li>
                        
                          <label>7</label>
                          <li>{t('info-centre.tnc-desc-7')}</li>
                      
                          <label>8</label>
                          <li>{t('info-centre.tnc-desc-8')}</li>
                      
                          <label>9</label>
                          <li>{t('info-centre.tnc-desc-9')}</li>
                        
                          <label>10</label>
                          <li>{t('info-centre.tnc-desc-10')}</li>
                      
                          <label>11</label>
                          <li>{t('info-centre.tnc-desc-11')}</li>
                      
                          <label>12</label>
                          <li>{t('info-centre.tnc-desc-12')}</li>
                        
                          <label>13</label>
                          <li>{t('info-centre.tnc-desc-13')}</li>
                        
                          <label>14</label>
                          <li>{t('info-centre.tnc-desc-14')}</li>
                        
                          <label>15</label>
                          <li>{t('info-centre.tnc-desc-15')}</li>
                        
                          <label>16</label>
                          <li>{t('info-centre.tnc-desc-16')}</li>
                      
                          <label>17</label>
                          <li>{t('info-centre.tnc-desc-17')}</li>
                        
                          <label>18</label>
                          <li>{t('info-centre.tnc-desc-18')}</li>
                      
                          <label>19</label>
                          <li>{t('info-centre.tnc-desc-19')}</li>

                          <label>20</label>
                          <li>{t('info-centre.tnc-desc-20')}</li>
                      </ol>
                    </div>
                    {/* tnc-accordion-end*/}
                  </div>
                </div>
              </TabPane>
            </TabContent>
          </TabContainer>
        </section>
      </Auxiliary >
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.data,
  };
};

export default connect(mapStateToProps)(withNamespaces("translation")(MobileInfoCentre));