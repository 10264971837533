import React, { Component, createRef } from "react";
import { withNamespaces } from "react-i18next";
import { isDesktop } from 'react-device-detect';

//Icons
import { Icon } from "@iconify/react";

const fb_app_id = "1903817429995758"; // app id from facebook developer app

class ShareModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // loading: true,
      promo_link: window.location.href,
    };
    this.linkRef = createRef();
  }

  componentDidMount() { }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  copyToClipboardHandler = event => {
    this.linkRef.current.select();
    document.execCommand("copy");
  }

  shareToSocialMedia = (event, type) => {
    let url = null;
    switch (type) {
      case "fb":
        // currrently using URL Redirection, an alternate solution suggested by fb (default solution is SDK ver)
        if (fb_app_id) {
          url = `https://www.facebook.com/dialog/share?app_id=${fb_app_id}&display=popup`;
        }
        // &redirect_uri=https%3A%2F%2Fdevelopers.facebook.com%2Ftools%2Fexplorer"; // additional optional params
        break;
      // case "ig":
      //   // based on official documentation by meta/fb, possibly support IOS & Android only
      //   break;
      case "whatsapp":
        // url = "https://wa.me/?text="; #possible broken at whatsapp
        url = "https://api.whatsapp.com/send?text=";
        break;
      case "telegram":
        url = "https://t.me/share/url?url=";
        break;
      case "messenger":
        if (fb_app_id) {
          url = `http://www.facebook.com/dialog/send?app_id=${fb_app_id}`;
        }
        break;
      case "twitter":
        url = "https://twitter.com/intent/tweet?text=";
        break;
      default:
        break;
    }
    if (url) {
      if (type === "fb") {
        window.open(url + "&href=" + encodeURI(this.state.promo_link));
        // window.open(url+"&href="+encodeURI("https://www.google.com.my")); // for localhost test only
      }
      else if (type === "messenger") {
        window.open(url + "&link=" + encodeURI(this.state.promo_link) + "&redirect_uri=" + encodeURI(window.location.protocol + "//" + window.location.hostname));
        // window.open(url+"&link="+encodeURI("https://www.google.com.my")+"&redirect_uri="+encodeURI("http://www.google.com.my")); // for localhost test only
      }
      else {
        window.open(url + encodeURI(this.state.promo_link));
      }
    }
  }

  render() {
    const { t } = this.props;
    const icons = this.imageImport(require.context("../../assets/images/profile/icons", false, /\.(png|jpe?g|svg)$/));

    return (
      isDesktop ? (
        <div className="modal global-modal fade" id="shareModal" tabIndex="-1" aria-labelledby="shareModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered modal-referral">
            <div className="modal-content">
              <div className="modal-header pb-0">
                <h5 className="modal-title">{t("promos.share")}</h5>
                <Icon type="button" className="btn-close" icon="mdi:close" data-bs-dismiss="modal" aria-label="Close" />
              </div>
              <div className="modal-body">
                <ul className="list-group list-group-horizontal mt-3">
                  <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "fb")}>
                    <img src={icons["facebook.svg"]} alt="facebook" />
                    <p>{t("referral.popup.fb")}</p>
                  </div>
                  {/* <div role="button" className="list-group-item">
                  <img src={icons["instagram.svg"]} alt="instagram" />
                  <p>{t("referral.popup.ig")}</p>
                </div> */}
                  <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "whatsapp")}>
                    <img src={icons["whatsapp.svg"]} alt="whatsapp" />
                    <p>{t("referral.popup.whatsapp")}</p>
                  </div>
                  <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "telegram")}>
                    <img src={icons["telegram.svg"]} alt="telegram" />
                    <p>{t("referral.popup.telegram")}</p>
                  </div>
                  <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "messenger")}>
                    <img src={icons["messenger.svg"]} alt="messenger" />
                    <p>{t("referral.popup.messenger")}</p>
                  </div>
                  <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "twitter")}>
                    <img src={icons["twitter.svg"]} alt="twitter" />
                    <p>{t("referral.popup.twitter")}</p>
                  </div>
                  <a role="button" className="list-group-item" href={`mailto:?subject=${this.state.promo_link}`} target="_blank" rel="noreferrer">
                    <img src={icons["email.svg"]} alt="email" />
                    <p>{t("referral.popup.email")}</p>
                  </a>
                </ul>
                <div className="input-group mb-3">
                  {/* {!this.state.loading &&
                  <> */}
                  <input
                    ref={this.linkRef}
                    type="text"
                    className="form-control"
                    name="referCode"
                    defaultValue={this.state.promo_link}
                    readOnly
                  />
                  <span className="input-group-text ms-2">
                    <button type="button" className="btn btn-gradient-blue" onClick={(event) => this.copyToClipboardHandler(event)}>
                      {t("referral.popup.copy")}
                    </button>
                  </span>
                  {/* </>} */}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <section className={`mobile-asset-section ${this.props.showShareSelect && "open"}`}>
          <div className="mobile-asset-content">
            <div className="mobile-asset-header" id="date-scroller">
              <button
                type="button"
                className="btn btn-cancel"
                data-bs-dismiss="mobile-asset-section"
                onClick={this.props.toggleShareSelect}
              >
                {/* {t("global.cancel")} */}
                <Icon icon="uil:angle-left" alt="arrow-left"/>
              </button>
              <p>{this.props.header}</p>
              <button 
                type="button" 
                className="btn btn-ok" 
                // onClick={this.props.toggleHandler}
                onClick={this.props.toggleShareSelect}
              >
                {t('global.ok')}
            </button>
            </div>
            <div className="mobile-asset-body m-referral pt-0">
              <ul className="list-group list-group-horizontal">
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "fb")}>
                  <img src={icons["facebook.svg"]} alt="facebook" />
                  <p>{t("referral.popup.fb")}</p>
                </div>
                {/* <div role="button" className="list-group-item">
                  <img src={icons["instagram.svg"]} alt="instagram" />
                  <p>{t("referral.popup.ig")}</p>
                </div> */}
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "whatsapp")}>
                  <img src={icons["whatsapp.svg"]} alt="whatsapp" />
                  <p>{t("referral.popup.whatsapp")}</p>
                </div>
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "telegram")}>
                  <img src={icons["telegram.svg"]} alt="telegram" />
                  <p>{t("referral.popup.telegram")}</p>
                </div>
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "messenger")}>
                  <img src={icons["messenger.svg"]} alt="messenger" />
                  <p>{t("referral.popup.messenger")}</p>
                </div>
                <div role="button" className="list-group-item" onClick={(event) => this.shareToSocialMedia(event, "twitter")}>
                  <img src={icons["twitter.svg"]} alt="twitter" />
                  <p>{t("referral.popup.twitter")}</p>
                </div>
                <a role="button" className="list-group-item" href={`mailto:?subject=${this.state.promo_link}`} target="_blank" rel="noreferrer">
                  <img src={icons["email.svg"]} alt="email" />
                  <p>{t("referral.popup.email")}</p>
                </a>
              </ul>
              <div className="input-group right">
                {/* {!this.state.loading &&
                  <> */}
                <input
                  ref={this.linkRef}
                  type="text"
                  className="form-control"
                  name="referCode"
                  defaultValue={this.state.promo_link}
                  readOnly
                />
                <span className="input-group-text">
                  <button type="button" className="btn btn-gradient-blue" onClick={(event) => this.copyToClipboardHandler(event)}>
                    {t("referral.popup.copy")}
                  </button>
                </span>
                {/* </>} */}
              </div>
            </div>
          </div>
          <div className="overlay" id="menu-overlay" onClick={this.props.toggleShareSelect} />
        </section>
      )
    );
  }
}

export default (withNamespaces("translation")(ShareModal));
