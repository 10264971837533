import React, { Component } from 'react';
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
import { Icon } from '@iconify/react';
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import { isDesktop } from "react-device-detect";
import { connect } from "react-redux";
import { claimAngpau } from "../../../store/ducks/angpau/actions";

// import { Link} from "react-router-dom";

class AngpaoRain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      angpaoAmt: "",
      bonusStatus: false,
      click: false,
    };
  }

  componentDid = () => {
    const { t } = this.props;
    Swal.fire({
      iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
      icon: "info",
      title: t("global.sorry"),
      text: "您需要在1月份成功存款一次，即可在活动期间每天抽取一个红包。",
      confirmButtonText: t("global.ok"),
      customClass: (!isDesktop) ? {
        container: "swal-mobile-container sorry",
        icon: "swal-sorry-icon",
      } : []
    });
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { t } = this.props;

    if (nextProps.claim_angpau_result !== this.props.claim_angpau_result) {
      if (nextProps.claim_angpau_result.responseCode === 200) {
          if(nextProps.claim_angpau_result.data.depositStatus === false){
            if(this.state.click === true){
              Swal.fire({
                iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
                icon: "info",
                title: t("global.sorry"),
                text: t("cny.cny-requirement"),
                confirmButtonText: t("global.ok"),
                customClass: (!isDesktop) ? {
                  container: "swal-mobile-container sorry",
                  icon: "swal-sorry-icon",
                } : []
              });
            }
          
          }else{
            if(this.state.click === true){
              this.setState({
                bonusStatus: true,
                angpaoAmt: nextProps.claim_angpau_result.data.amount 
              });
            }
          }
      } else {
        return false;
      }
    }

    return true;
  }

  openAngpao = () => {

    this.setState({
      click: true
    });
    
    let data = {};
    data.action = "click";
    this.props.claimAngpau(data);
  };

  closeAngpaoRain = () => {
    this.setState({
      homePageEvents: false,
    });
  };

  imageImport = (r) => {
    let images = {};
    r.keys().map((item) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;
    const angpao = this.imageImport(require.context("../../../assets/images/home-overlay-events/", false, /\.(png|jpe?g|svg|webp)$/));

    let imageLanguage = '';
    switch (this.props.currentUser.language_code) {
        case "zh-CN":
            imageLanguage = "-cn";
            break;
        case "ms":
            imageLanguage = "-bm"
            break;
        case "id":
            imageLanguage = "-bm"
            break;
        default:
            imageLanguage = "";
            break;
    }

    return (
      <Auxiliary>
        {this.props.homePageEvents ? (<>
          <div className='ani-section cny' >
            <div className='ani-content-wrapper'>
            <div className={`ani-header-wrapper ${!isDesktop && 'mobile'}`}>
            <div className='ani-title'>
            {this.state.bonusStatus === true && <div> <h3>{t("cny.congratz")} <span className='fes-ani-amount'>RM{this.state.angpaoAmt}</span></h3></div>}
            </div>
            </div>
            <div className='ani-item-wrapper'>
              <img className={`flare-light ${!isDesktop && 'mobile'}`} src={angpao["shine.png"]} alt="flare light" />
              {this.state.bonusStatus === false ? (
                <img className={`fes-ani-asset  ${!isDesktop && 'mobile'}`} src={angpao[`angpao${imageLanguage}.webp`]} alt="angpao" onClick={ this.openAngpao} />
              ) :
                ( <> 
                  <img className={`fes-ani-asset  ${!isDesktop && 'mobile'}`}  src={angpao[(this.state.angpaoAmt == "0.33") ? `033${imageLanguage}.webp`: this.state.angpaoAmt + `${imageLanguage}.webp`]} alt="angpao"  />
                  <img className={`ani-burst ${!isDesktop && 'mobile'}`} src={angpao["coin.png"]} alt="coin burst" />
                  <img className={`ani-burst-one ${!isDesktop && 'mobile'}`} src={angpao["coin-1.png"]} alt="coin burst" />
                </>
                )
              }

            <div>
            <div className={`ani-bottom-wrapper ${!isDesktop && 'mobile'}`}>
            <div className='close-button-wrapper'>
              {this.state.bonusStatus === false &&
                  <h5 onClick={(e) => { e.preventDefault(); window.location.href = '/promos'; }}>{t("cny.details")}</h5>
                }
                <Icon type="button" className="fes-btn-close" icon="mdi:close" onClick={this.props.toggleHomeEvents} />
              </div>

            {/* {this.state.bonusStatus === false && 
              <h5 style={{cursor:"pointer"}} onClick={(e) => { e.preventDefault(); window.location.href = '/promos'; }} className='mt-3'>{t("cny.details")}</h5>
            }
              <Icon type="button" className="btn-close" icon="mdi:close" onClick={this.props.toggleHomeEvents} /> */}
              </div>
              </div>
          </div>
          </div>
          </div>
          <section className='fes-ani-bg-wrap'>
            <img className={`rain-one ${!isDesktop && 'mobile'}`} src={angpao["rain-1.png"]} alt="rain one" />
            <img className={`rain-two ${!isDesktop && 'mobile'}`} src={angpao["rain-2.png"]} alt="rain two" />
            <img className={`rain-three ${!isDesktop && 'mobile'}`} src={angpao["rain-3.png"]} alt="rain three" />
            <div className='fes-ani-overlay mobile'></div>
          </section>
        </>
        ) :
          ("")
        }

      </Auxiliary>

    )
  }
}

const mapStateToProps = (state) => {
  return {
    claim_angpau_result: state.events.claim_angpau_result
  };
};

const mapDispatchToProps = {
  claimAngpau
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNamespaces("translation")(AngpaoRain))