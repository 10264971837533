import * as actionType from './types';
import axios from '../../../common/config/axios';

export const claim = (props) => (dispatch) =>
{
  
    axios.post('cny/claim', props)
    .then(function (response) 
    {
        dispatch (
        {
            type: actionType.CLAIM,
            value: response.data
        });
    })
    .catch(err => 
    {
      console.log(err);
    });
};

export const mobileAppsClaim = (data) => dispatch => {
    axios.post('mobile_apps_promo/claim', data).then( (response) => {
      dispatch ({
          type: actionType.MOBILE_APPS_PROMO_CLAIM,
          value: response.data
      })
    }).catch (err => {
      console.log(err);
    })
  };

export const getMemberLuckDrawInfo = () => (dispatch, getState) => 
{
    let data = {};
    data.member_id = (getState().currentUser.data.id || '');
    data.country_id = getState().currentUser.data.country_id;
    axios
        .get('cny/get_member_luck_draw_info', {params: data})
        .then(function (response) 
        {
            dispatch ({
                type: actionType.GET_MEMBER_LUCK_DRAW_INFO,
                value: response.data
            })
        })
        .catch(err => 
        {
            console.log('Get Member Lucky Info Error', err);
        });
};