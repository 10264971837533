import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

// language file
import translationEN from './en/translation.json';
import translationZHCN from './cn/translation.json';
import translationMS from './ms/translation.json';
import gamesEN from './en/games.json';
import gamesZHCN from './cn/games.json';
import gamesMS from './ms/games.json';
import routesEN from './en/routes.json';
import routesZHCN from './cn/routes.json';
import routesMS from './ms/routes.json';

import translationIndon from './indon/translation.json';
import gamesIndon from './indon/games.json';
import routesIndon from './indon/routes.json';
import domain from "../../common/data/domain.json";

let resources = {};

resources = {
  en: {
    translation: translationEN,
    games: gamesEN,
    routes: routesEN
  },
  zh_cn: {
    translation: translationZHCN,
    games: gamesZHCN,
    routes: routesZHCN
  },
  ms: {
    translation: translationMS,
    games: gamesMS,
    routes: routesMS
  },
  id: {
    translation: translationIndon,
    games: gamesIndon,
    routes: routesIndon
  }
}

let initLang = localStorage.getItem('lang') || "en";
if(domain.id.key.includes(window.location.host)){
  if(!localStorage.getItem("accessToken")){
    if(localStorage.getItem("lang")){
      initLang = localStorage.getItem("lang");
    }
    else{
      initLang = "id";
    }
  }
}

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: initLang, //localStorage.getItem('lang') || "en",
    fallbackLng: "en",
    // keySeparator: false, // if do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    react: {
      wait: false
    }
  });

export default i18n;