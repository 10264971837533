import * as type from "./types";
import { createReducer } from "../../util/index";

const initialState = {
    // data: [],
};

const profileReducer = createReducer(initialState) ({
    [type.GET_TOP_PROFILE]: (state, action) => ({
        ...state,
        top_profile_data: action.payload,
    }),

    [type.GET_WALLET_BALANCE]: (state, action) => ({
        ...state,
        top_profile_data: {
            ...state.top_profile_data,
            data:{
                ...state.top_profile_data.data,
                topProfile: {
                    ...state.top_profile_data.data.topProfile,
                    balance: action.payload
                }
            }
        },
    }),

    [type.GET_MEMBER_PROFILE]: (state, action) => ({
        ...state,
        member_profile_data: action.payload,
    }),

    [type.UPDATE_MEMBER_PROFILE]: (state, action) => ({
        ...state,
        member_profile_update_result: action.payload,
    }),

    [type.GET_MEMBER_BANK]: (state, action) => ({
        ...state,
        member_bank_data: action.payload,
    }),

    [type.ADD_MEMBER_BANK]: (state, action) => ({
        ...state,
        member_add_bank_result: action.payload,
    }),

    [type.EDIT_MEMBER_BANK]: (state, action) => ({
        ...state,
        member_edit_bank_result: action.payload,
    }),

    [type.REMOVE_MEMBER_BANK]: (state, action) => ({
        ...state,
        member_remove_bank_result: action.payload,
    }),

    [type.GET_MEMBER_REFERRAL]: (state, action) => ({
        ...state,
        member_referral_data: action.payload,
    }),

    [type.GET_MEMBER_REFERRAL_BONUS]: (state, action) => ({
        ...state,
        member_referral_bonus_data: action.payload,
    }),

    [type.UPDATE_MEMBER_PASSWORD]: (state, action) => ({
        ...state,
        member_update_password_result: action.payload,
    }),

    [type.GENERATE_SMS_OTP]: (state, action) => ({
        ...state,
        generate_sms_otp_result: action.payload,
    }),

    [type.VERFIY_SMS_OTP]: (state, action) => ({
        ...state,
        verify_sms_otp_result: action.payload,
    }),

    [type.RESEND_OTP]: (state, action) => ({
        ...state,
        resend_otp_result: action.payload,
    }),

    [type.GET_AVAILABLE_CRYPTO_LIST]: (state, action) => ({
        ...state,
        get_available_crypto_list_result: action.payload,
    }),

    [type.SOCIAL_MEDIA_USER_VERIFICATION]: (state, action) => ({
        ...state,
        social_media_user_verification_result: action.payload,
    }),

    [type.GENERATE_EMAIL_OTP]: (state, action) => ({
        ...state,
        generate_email_otp_result: action.payload,
    }),

    [type.VERIFY_EMAIL_OTP]: (state, action) => ({
        ...state,
        verify_email_otp_result: action.payload,
    }),

    [type.RESEND_EMAIL_OTP]: (state, action) => ({
        ...state,
        resend_email_otp_result: action.payload,
    }),

    [type.UPDATE_MEMBER_EMAIL]: (state, action) => ({
        ...state,
        update_member_email_result: action.payload,
    }),

    [type.UPDATE_MEMBER_CONTACT_NO]: (state, action) => ({
        ...state,
        update_member_contact_no_result: action.payload,
    }),

    [type.GET_CRYPTO_ADDRESS]: (state, action) => ({
        ...state,
        member_crypto_address_data: action.payload,
    }),

    [type.REMOVE_CRYPTO_ADDRESS]: (state, action) => ({
        ...state,
        member_remove_crypto_address_result: action.payload,
    }),

    [type.GOOGLE_REDIRECT]: (state, action) => ({
        ...state,
        google_redirect_result: action.payload,
    }),

    [type.UNLINK_GOOGLE]: (state, action) => ({
        ...state,
        unlink_google_result: action.payload,
    }),

    [type.SET_SECURITY_PIN]: (state, action) => ({
        ...state,
        set_security_pin_result: action.payload,
    }),

    [type.VERIFY_SECURITY_PIN]: (state, action) => ({
        ...state,
        verify_security_pin_result: action.payload,
    }),

    [type.UPDATE_SECURITY_PIN]: (state, action) => ({
        ...state,
        update_security_pin_result: action.payload,
    }),

    [type.CHECK_SECURITY_PIN_ATTEMPTS]: (state, action) => ({
        ...state,
        check_security_pin_attempts_result: action.payload,
    }),

    [type.UPDATE_PROFILE_PICTURE]: (state, action) => ({
        ...state,
        update_profile_picture_result: action.payload,
    }),

    [type.UNLINK_TELEGRAM]: (state, action) => ({
        ...state,
        unlink_telegram_result: action.payload,
    }),

    [type.UPDATE_MEMBER_POP_UP]: (state, action) => ({
        ...state,
        update_member_pop_up_result: action.payload,
    }),

    [type.REMOVE_SECURITY_PIN]: (state, action) => ({
        ...state,
        remove_security_pin_result: action.payload,
    }),

    [type.UPGRADE_BUSINESS_ACCOUNT]: (state, action) => ({
        ...state,
        upgrade_business_account_result: action.payload,
    }),

    [type.GET_BUSINESS_ACCOUNT]: (state, action) => ({
        ...state,
        get_business_account_result: action.payload,
    }),

    [type.GET_INTEREST]: (state, action) => ({
        ...state,
        get_interest_result: action.payload,
    }),

    [type.UPDATE_CRYPTO_ADDRESS]: (state, action) => ({
        ...state,
        update_crypto_address_result: action.payload,
    }),

    [type.UPDATE_MEMBER_ADDRESS]: (state, action) => ({
        ...state,
        update_member_address: action.payload,
    }),
});

export default profileReducer;