import React, { Component } from 'react';
import { withNamespaces } from "react-i18next";
import Skeleton from "react-loading-skeleton";

//Miscellaneous
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import { Reveal } from "react-awesome-reveal";
// import { keyframes } from "@emotion/react";

//Components
import MobileSubHeader from '../../../components/PageHeader/MobileSubHeader';
import Notice from "./Notice";
// import Activity from "./Activity";
import Announcement from "./Announcement";

import { Icon } from "@iconify/react";

// import * as actions from "../../../store/ducks/inbox/actions";

export class MobileMessage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showEdit: false,
      noticeInEdit: false,
      announcementInEdit: false
    };
  }

  componentDidMount() {
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.pageShow !== undefined && nextProps.pageShow !== this.props.pageShow && nextProps.pageShow !== "listing") {
      // console.log("[CONDITION MET!!!]");
      this.setState({ showEdit: false, noticeInEdit: false, announcementInEdit: false });
    }
    return true;
  }

  componentWillUnmount() {
  }

  handleEdit = () => {
    this.setState({
      showEdit: !this.state.showEdit
    }, () => {
      switch (this.props.activePanel) {
        case 0:
          this.notice.handleEdit();
          this.setState({ noticeInEdit: !this.state.noticeInEdit });
          break;
        case 2:
          this.announcement.handleEdit();
          this.setState({ announcementInEdit: !this.state.announcementInEdit });
          break;
        default:
          break;
      }
    });
  }

  handleActivePanelEditBtnText = (panel) => {
    if (!this.state.noticeInEdit && !this.state.announcementInEdit) {
      this.setState({ showEdit: false });
    }
    else {
      if (this.state.noticeInEdit && panel === 0) {
        this.setState({ showEdit: true });
      }
      else if (this.state.announcementInEdit && panel === 2) {
        this.setState({ showEdit: true });
      }
      else {
        this.setState({ showEdit: false });
      }
    }
    this.props.handleActivaPanel(panel);
  }

  unsetEditBtnState = (panel) => {
    this.setState({ showEdit: false }, () => {
      if (panel === 0) {
        this.setState({ noticeInEdit: false });
      }
      else if (panel === 2) {
        this.setState({ announcementInEdit: false });
      }
    });
  }


  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const { t } = this.props;

    // const fadeUp = keyframes`
    //   from {
    //     opacity: 0;
    //     transform: translate3d(0, 50px ,0);
    //   }

    //   to {
    //     opacity: 1;
    //     transform: translate3d(0, 0, 0);
    //   }
    //   `;
    return (
      <Auxiliary>
        {this.props.pageShow === "listing" &&
          <>
            <div className="pf-m-dashboard">
              <MobileSubHeader
                // className="bg-white"
                returnPage="/me"
                header={t("message.message-center")}
                style={{ justifyContent: "center" }}
                modalHeader={`${this.state.showEdit === true ? t("message.done") : t("global.edit")}`}
                modalHandler={this.handleEdit}
                {...this.state}
              />
              <div className="msg-tabs-col">
                <ul className="nav nav-tabs" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${this.props.activePanel === 0 ? "active" : ""}`} data-bs-toggle="tab" data-bs-target="#msg-notice" type="button" role="tab"
                      onClick={() => this.handleActivePanelEditBtnText(0)}
                    >
                      {t("message.notice")}{(() => {
                        if (!this.props.loading) {
                          if (this.props.unreadNotice > 0) {
                            return <span className="notify-num">{this.props.unreadNotice}</span>;
                          }
                          else {
                            return null;
                          }
                        }
                      })()}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button className={`nav-link ${this.props.activePanel === 2 ? "active" : ""}`} data-bs-toggle="tab" data-bs-target="#msg-announcement" type="button" role="tab"
                      onClick={() => this.handleActivePanelEditBtnText(2)}>
                      {t("message.announcement")}{(() => {
                        if (!this.props.loading) {
                          if (this.props.unreadAnnouncement > 0) {
                            return <span className="notify-num">{this.props.unreadAnnouncement}</span>;
                          }
                          else {
                            return null;
                          }
                        }
                      })()}
                    </button>
                  </li>
                </ul>
              </div>

              
              <div className='msg-m-whatsapp'>
                <div className='d-flex align-items-center'>
                  <Icon className="me-1" icon="mdi:whatsapp" />
                  <p className='whatsapp-title'>{t("message.notification")}</p>
                </div>
                <div className="d-flex align-items-center">
                {this.props.loading ? (
                  <Skeleton count={1} />
                ) : (
                  <>
                    <div className="d-flex align-items-center">
                      <input type="checkbox" className="form-check-input" id="deposit-check" onChange={(e) => this.props.onNotificationsSettingHandler(e)} checked={this.props.depositNotifcation === 1 ? "checked" : ""} />
                      <label className="ms-1" htmlFor="deposit-check">{t("transaction-type.deposit")}</label>
                    </div>
                    <div className="d-flex align-items-center ms-2">
                      <input type="checkbox" className="form-check-input" id="withdrawal-check" onChange={(e) => this.props.onNotificationsSettingHandler(e)} checked={this.props.withdrawalNotification === 1 ? "checked" : ""} />
                      <label className="ms-1" htmlFor="withdrawal-check">{t("transaction-type.withdrawal")}</label>
                    </div>

                    {/* <div className="d-flex align-items-center ms-2">
                      <input type="checkbox" className="form-check-input" id="rebate-check" onChange={(e) => this.props.onNotificationsSettingHandler(e)} checked={this.props.rebateNotification === 1 ? "checked" : ""} />
                      <label className="ms-1" htmlFor="rebate-check">{t("transaction-type.rebate")}</label>
                    </div> */}
                  </>
                )}
                </div>
              </div>

              <div className="pd-m-card-col">
                <div className="msg-tabs-content">
                  <div className="tab-content">
                    <Notice
                      handleChangePage={this.props.handleChangePage}
                      activePanel={this.props.activePanel}
                      onRef={ref => (this.notice = ref)}
                      unsetEditBtnState={this.unsetEditBtnState}
                      activePage={this.props.activePage}
                    />
                    <Announcement
                      handleChangePage={this.props.handleChangePage}
                      activePanel={this.props.activePanel}
                      onRef={ref => (this.announcement = ref)}
                      unsetEditBtnState={this.unsetEditBtnState}
                      activePage={this.props.activePage}
                    />
                  </div>
                </div>
              </div>
            </div>
          </>
        }
      </Auxiliary>
    )
  }
}

export default (withNamespaces("translation")(MobileMessage));