import React, { Component } from "react";
import { connect } from "react-redux";
import { default as ReactSelect } from "react-select";
import { Icon } from "@iconify/react";
import { withNamespaces } from "react-i18next";
import Swal from "sweetalert2";
import Skeleton from "react-loading-skeleton";
import Modal from "react-modal";
import { isDesktop } from "react-device-detect";

//Miscellaneous 
import { formValidation, clearFormValidation } from "../../../hoc/Shared/utility";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";

//Components 
import NumberInput from "../../../components/Input/NumberInput/NumberInput";
import DataTable from "../../../components/Datatable/DataTable";
import Button from "../../../components/Button/Button";
import MobileBankingDetails from "./MobileBankingDetails";

//Actions 
import { getMemberBank, addMemberBank, editMemberBank, removeMemberBank } from "../../../store/ducks/profile/actions";

class BankingDetails extends Component 
{
	constructor(props) 
	{
		super(props);
		this.state = 
		{
			memberBankData: [],
			bankDropDown: [],
			loading: true,
			bank_key: null,
			bank_name: null,
			bank_acc_name: "",
			bank_acc_number: "",
			error_message: [],
			touched: false,
			profileSideBar: false,
			totalRecords: 0,
			showAddBank: false,

			openModal: false,
			editBankDropDown: [],
			edit_id: null,
			edit_bank_key: null,
			edit_bank_name: "",
			edit_bank_acc_number: "",

			errorMsg: [],
			formError: false,
		};
		this.onInputChangeHandler = this.onInputChangeHandler.bind(this);
	}

	componentDidMount() 
	{
		formValidation("add_bank_form");
		this.searchHandler();
	}

	shouldComponentUpdate(nextProps, nextState) 
	{
		// console.log("[BankingDetails] shouldComponentUpdate()", "\nnextProps: ", nextProps, "\nnextState: ", nextState);
		const { t } = this.props;
		if (nextProps.memberBankData !== this.props.memberBankData && nextProps.memberBankData !== undefined) 
		{
			if (nextProps.memberBankData.responseCode === 200) 
			{
				let accountHolder = nextProps.memberBankData.data.accountHolder;
				let memberBank = nextProps.memberBankData.data.memberBank;
				let bankDropdown = nextProps.memberBankData.data.bank;
				this.setState({
					bank_acc_name: accountHolder.name,
					memberBankData: memberBank,
					bankDropDown: bankDropdown,
					loading: false,
					totalRecords: memberBank.length,
				});
				Swal.close();
			}
		}

		if (nextProps.memberAddBankResult !== this.props.memberAddBankResult) 
		{
			if (nextProps.memberAddBankResult.responseCode === 200) 
			{
				Swal.fire({
					icon: 'success',
					title: t('global.success'), 
          			html: t('global.record-added'),
					// icon: nextProps.memberAddBankResult.msgType,
					confirmButtonText: t('global.ok'),
				}).then((result) => 
				{
					if (result.isConfirmed) 
					{
						this.setState({
							bank_name: null,
							bank_key: null,
							bank_acc_number: "",
							showAddBank: false,
							touched: false,
						});
						this.searchHandler();
					}
				});
			} 
			else 
			{
				clearFormValidation('add_bank_form');
				if (nextProps.memberAddBankResult.message.input_error) 
				{
					this.setState({
						errorMsg: (nextProps.memberAddBankResult.message.input_error || []),
						formError: true,
					});
					Swal.close();
				}
				else 
				{
					let msg = null;
					msg = '<ul>';
					nextProps.memberAddBankResult.message.forEach((val) => {
						msg += '<li>' + val + '</li>';
					});
					msg += '</ul>';

					Swal.fire({
						iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
						icon: 'error',
						title: t("global.sorry"),
						html: msg,
						showConfirmButton: true,
						confirmButtonText: t("global.ok"),
						customClass: (!isDesktop) ? {
							container: 'swal-mobile-container sorry',
							icon: 'swal-sorry-icon'
						} : [],
					});

				}
			}
		}

		if (nextProps.memberEditBankResult !== this.props.memberEditBankResult) 
		{
			if (nextProps.memberEditBankResult.responseCode === 200) 
			{
				Swal.fire({
					iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
					icon: 'success',
					title: t('global.success'), 
          html: t('global.bank-record-updated'),
					confirmButtonText: t('global.ok'),
					customClass: (!isDesktop) ? {
						container: 'swal-mobile-container sucess',
						icon: 'swal-success-icon'
					} : [],
				}).then((result) => 
				{
					if (result.isConfirmed) 
					{
						this.setState({
							openModal: false,
							edit_bank_name: null,
							edit_bank_key: null,
							edit_bank_acc_number: "",
							showAddBank: false,
							bank_name: null,
							bank_key: null,
							bank_acc_number: "",
							touched: false,
						});
						this.searchHandler();
					}
				});
			} 
			else 
			{
				clearFormValidation('update_bank_form');
				if (nextProps.memberEditBankResult.message.input_error) 
				{
					this.setState({
						errorMsg: (nextProps.memberEditBankResult.message.input_error || []),
						formError: true,
					});
					Swal.close();
				}
				else 
				{
					let msg = null;
					msg = '<ul>';
					nextProps.memberEditBankResult.message.forEach((val) => 
					{
						msg += '<li>' + val + '</li>';
					});
					msg += '</ul>';

					Swal.fire({
						iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/iconamoon:pensive-face-thin.svg?color=%2393a2Aa">' : '',
						icon: 'error',
						title: t("global.sorry"),
						html: msg,
						showConfirmButton: true,
						confirmButtonText: t("global.ok"),
						customClass: (!isDesktop) ? {
							container: 'swal-mobile-container sorry',
							icon: 'swal-sorry-icon'
						} : [],
					});
				}
			}
		}

		if (nextProps.memberRemoveBankResult !== this.props.memberRemoveBankResult) 
		{
			if (nextProps.memberRemoveBankResult.responseCode === 200) 
			{
				Swal.fire({
					iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ri:check-fill.svg?color=%2306DB04">' : '',
					icon: 'success',
					title: t('global.success'), 
          html: t('global.bank-record-deleted'),
					confirmButtonText: t('global.ok'),
					customClass: (!isDesktop) ? {
						container: 'swal-mobile-container sucess',
						icon: 'swal-success-icon'
					} : [],
				}).then(() => 
				{
					this.setState({
						bank_name: null,
						bank_key: null,
						bank_acc_number: "",
						touched: false,
					});
					this.searchHandler();
				});
			}
			else if (nextProps.memberRemoveBankResult.responseCode === 422) 
			{
				this.setState({
					touched: true,
					error_message: nextProps.memberRemoveBankResult.message,
				});
				Swal.close();
			}
			else 
			{
				return false;
			}
		}

		return true;
	}

	onInputChangeHandler = (event) => 
	{
		this.setState({
			[event.target.name]: event.target.value,
			errorMsg: [],
			formError: false,
		});
	}

	handleBankChange = (event) => 
	{
		// console.log(event);
		this.setState({
			bank_name: event.id,
			bank_key: event.key,
		});
	}

	handleEditBankChange = (event) => {
		// console.log(event);
		this.setState({
			edit_bank_name: event.id,
			edit_bank_key: event.key,
		});
	}

	toggleAddBank = () => {
		this.setState({
			showAddBank: !this.state.showAddBank,
		});
	}

	actionFormatter = (cell, row) => 
	{
		let removeButton, output;
		removeButton = (
			<>
				<Button
					typeButton="button"
					classValue="btn icon"
					key={`edit-${row.memberBankAccId}`}
					idValue={row.memberBankAccId === undefined ? 0 : row.memberBankAccId}
					clicked={(e) => this.openModalHandler(e, row.memberBankAccId, row.bankId, row.bankName, row.swiftCode, row.accountNo)}
					buttonName={<Icon icon="uil:edit" />}
				/>
				<Button
					typeButton="button"
					classValue="btn icon red"
					key={`remove-${row.memberBankAccId}`}
					idValue={row.memberBankAccId === undefined ? 0 : row.memberBankAccId}
					clicked={(e) => this.removeBankHandler(e, row.memberBankAccId, row.bankName)}
					buttonName={<Icon icon="uil:trash-alt" />}
				/>
			</>
		);

		output = [removeButton];
		return output;
	};

	openModalHandler = (e, id, bankId, bankName, swiftCode, bankAccNo) => 
	{
		e.preventDefault();

		let newArray = Object.assign([], this.state.bankDropDown);
		newArray.push({
			bankId,
			bankName,
			swiftCode,
		});

		this.setState({
			openModal: true,
			editBankDropDown: newArray,
			edit_id: id,
			edit_bank_name: bankId,
			edit_bank_key: ((newArray.length - 1) >= 0 ? (newArray.length - 1) : 0),
			edit_bank_acc_number: bankAccNo,
			errorMsg: [],
			formError: false,
			showAddBank: !this.state.showAddBank,
		});
	}

	closeModalHandler = (e) => 
	{
		e.preventDefault();

		this.setState({
			openModal: false,
			errorMsg: [],
			formError: false,
		});
	}

	addBankHandler = (e) => 
	{
		e.preventDefault();
		const { t } = this.props;

		Swal.fire({
			title: t("global.confirmation"),
			text: t("global.would-you-like-to-proceed") + "?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: t("global.save"),
			cancelButtonText: t("global.cancel"),
		}).then((result) => 
		{
			if (result.isConfirmed) 
			{
				Swal.fire({
					title: "",
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					didOpen: () => {
						Swal.showLoading();
					},
				});

				let data = {};
				data.bank_name = this.state.bank_name;
				data.bank_acc_name = this.state.bank_acc_name;
				data.bank_acc_number = this.state.bank_acc_number;

				// console.log("BankingDetails Form Submit:", data);
				this.props.addMemberBank(data);
			}
		});
	}

	updateBankHandler = (e) => 
	{
		e.preventDefault();
		const { t } = this.props;

		Swal.fire({
			iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/ic:outline-question-mark.svg?color=%2302f1ff">' : '',
			title: t("global.confirmation"),
			text: t("global.would-you-like-to-proceed") + "?",
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: t("global.save"),
			cancelButtonText: t("global.cancel"),
			customClass: (!isDesktop) ? {
				container: "swal-mobile-container confirmation",
				icon: "swal-confirmation-icon",
			} : [],
		}).then((result) => 
		{
			if (result.isConfirmed) 
			{
				Swal.fire({
					title: "",
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					didOpen: () => 
					{
						Swal.showLoading();
					},
				});

				let data = {};
				data.id = this.state.edit_id;
				data.bank_name = this.state.edit_bank_name;
				data.bank_acc_name = this.state.bank_acc_name;
				data.bank_acc_number = this.state.edit_bank_acc_number;

				// console.log("BankingDetails Form Submit:", data);
				this.props.editMemberBank(data);
			}
		});
	}

	removeBankHandler = (e, id, bankName) => 
	{
		e.preventDefault();
		const { t } = this.props;

		Swal.fire({
			iconHtml: (!isDesktop) ? '<img src="https://api.iconify.design/uil:exclamation.svg?color=%23ffa700">' : '',
			title: t("global.reminder"),
			// text:  t("global.remove") + " " + bankName + "?" + " " +  t("global.deleted-cannot-recovered") + ".",
			text: `${t("global.remove")} ${bankName}? ${t("global.deleted-cannot-recovered")}.`,
			icon: "warning",
			showCancelButton: true,
			confirmButtonText: t("global.confirm"),
			cancelButtonText: t("global.cancel"),
			customClass: (!isDesktop) ? {
				container: "swal-mobile-container reminder",
				icon: "swal-reminder-icon",
			} : [],
		}).then((result) => 
		{
			if (result.isConfirmed) 
			{
				Swal.fire({
					title: "",
					allowOutsideClick: false,
					allowEscapeKey: false,
					allowEnterKey: false,
					didOpen: () => 
					{
						Swal.showLoading();
					},
				});

				let data = {};
				data.member_bank_account_id = id;
				this.props.removeMemberBank(data);
			}
		});
	}

	searchHandler = () => 
	{
		this.setState({
			loading: true,
		});
		this.props.getMemberBank();
	}

	imageImport = (r) => 
	{
		let images = {};
		r.keys().map((item, index) => 
		{
			return (images[item.replace("./", "")] = r(item)["default"]);
		});
		return images;
	}

	render() 
	{
		const { t } = this.props;
		const bankImg = this.imageImport(require.context("../../../assets/images/profile/banks", false, /\.(png|jpe?g|svg)$/));

		let rsData = [];
		let addMemberBankArray = [];
		let editMemberBankArray = [];

		const columns = [
			{ text: t("banking-details.bank-name"), dataField: "bank" },
			{ text: t("banking-details.account-holder"), dataField: "accountHolder" },
			{ text: t("banking-details.account-no"), dataField: "accountNo" },
			{ text: "", dataField: "delete", formatter: this.actionFormatter },
		];

		if (this.state.bankDropDown && !this.state.loading) 
		{
			addMemberBankArray = this.state.bankDropDown.map((val, idx) => 
			{
				return {
					id: val.bankId,
					label: val.bankName,
					icon: `${val.swiftCode.toLowerCase()}-color`,
					key: idx,
				};
			});
		}

		if (this.state.editBankDropDown && !this.state.loading) 
		{
			editMemberBankArray = this.state.editBankDropDown.map((val, idx) => 
			{
				return {
					id: val.bankId,
					label: val.bankName,
					icon: `${val.swiftCode.toLowerCase()}-color`,
					key: idx,
				};
			});
		}

		if (this.state.memberBankData && !this.state.loading) 
		{
			let bankVal = null;
			let bankColorImage = null;

			rsData = this.state.memberBankData.map((val, idx) => 
			{
				bankColorImage = (bankImg[`${val.swiftCode.toLowerCase()}-color.svg`] !== undefined) ? bankImg[`${val.swiftCode.toLowerCase()}-color.svg`] : bankImg["default-bank-icon-color.png"];
				bankVal = (
					isDesktop ? (
						<div className="d-flex align-items-center" key={idx}>
							<img className="me-3" src={bankColorImage} alt={val.swiftCode.toUpperCase()} />
							<p>{val.bankName}</p>
						</div>
					) : (
						<div className="my-bd-m-col" key={idx}>
							<img src={bankColorImage} alt={val.swiftCode.toUpperCase()} />
							<div className="my-bd-m-acc">
								<p>{(val.companyName ? val.companyName : this.state.bank_acc_name)}</p>
								<p>{val.memberBankAccNumber}</p>
							</div>
						</div>
					)
				);

				return {
					no: idx,
					bank: bankVal,
					bankName: val.bankName,
					memberBankAccId: val.memberBankAccId,
					accountHolder: (val.companyName ? val.companyName : this.state.bank_acc_name),
					accountNo: val.memberBankAccNumber,
					swiftCode: val.swiftCode,
					bankId: val.memberBankId,
				};
			});
		}

		if (this.state.openModal) 
		{
			formValidation("update_bank_form");
		}

		return (
			<Auxiliary>
				{
					(isDesktop) ? (
						<>
							<Modal
								isOpen={this.state.openModal}
								closeTimeoutMS={500}
								portalClassName={`global-modal`}
								className="modal modal-dialog modal-dialog-centered modal-pd"
								ariaHideApp={false}
								onRequestClose={(event) => this.closeModalHandler(event)}
							>
								<div className="modal-content">
									<div className="modal-header">
										<h5 className="modal-title">{t("banking-details.edit-banking-details")}</h5>
										<Icon type="button" className="btn-close" icon="mdi:close" onClick={(event) => this.closeModalHandler(event)} />
									</div>
									<div className="modal-body">
										<form className="forms-col" method="post" id="update_bank_form" encType='multipart/form-data' onSubmit={this.updateBankHandler} noValidate>
											<div>
												<label className="col-form-label lh-1">{t("banking-details.bank-name")}</label>
												<ReactSelect
													classNamePrefix="react-select"
													placeholder={t("banking-details.select-bank")}
													name="edit_bank_name"
													options={editMemberBankArray}
													onChange={this.handleEditBankChange}
													value={(this.state.edit_bank_key !== null) ? editMemberBankArray[this.state.edit_bank_key] : ''}
													formatOptionLabel={(item) => (
														<>
															<img src={
																(bankImg[`${item.icon.toLowerCase()}.svg`] !== undefined)
																	?
																	bankImg[`${item.icon.toLowerCase()}.svg`]
																	:
																	bankImg["default-bank-icon.png"]
															} alt={item.label} />
															<p>{item.label}</p>
														</>
													)}
												/>
											</div>
											<div>
												<label className="col-form-label lh-1">{t("banking-details.account-no")}</label>
												<NumberInput
													id="edit_bank_acc_number"
													name="edit_bank_acc_number"
													className={`form-control ${(this.state.errorMsg.bank_acc_number) ? 'was-validated is-invalid' : ''}`}
													value={this.state.edit_bank_acc_number}
													onChange={(event) => this.onInputChangeHandler(event)}
													step="any"
													required={true}
													requiredMessage={t("validation.required")}
													formError={this.state.formError}
													errorMsg={this.state.errorMsg.bank_acc_number || ''}
												/>
											</div>
											<Button typeButton="submit" classValue="btn btn-gradient-blue mt-3" buttonName={t("top-profile.update")} />
										</form>
									</div>
								</div>
							</Modal>
							<div className="tab-pane fade show active" id="banking-details" role="tabpanel" aria-labelledby="banking-tab">
								<DataTable
									keyField="no"
									data={rsData}
									columns={columns}
									activePage={1}
									limit={20}
									totalItems={this.state.totalRecords}
									paginationHandler={(val) => this.datatablesChangeHandler(val, "activePage")}
									sizePerPageHandler={(val) => this.datatablesChangeHandler(val, "limit")}
									loading={this.state.loading}
									hideSearchBar={true}
									hidePagination={true}
									hideTotalRecords={true}
									classValue="pd-table w-border mb-0"
									component="banking-details"
								/>
								<form method="post" id="add_bank_form" className="forms-col" onSubmit={this.addBankHandler} noValidate>
									{this.state.loading ? (
										<Skeleton count={2} style={{ marginBottom: "1rem" }} height={"56px"} />
									) : (
										<>
											<button onClick={() => this.toggleAddBank()} className="btn text-icon mt-5 mb-3" type="button" >
												{this.state.showAddBank ? (
													<Icon icon="radix-icons:minus-circled" />
												) : (
													<Icon icon="radix-icons:plus-circled" />
												)}
												{t("banking-details.add-bank")}
											</button>
											<div className={`add-more-col ${this.state.showAddBank ? "show" : ""}`}>
												<div className="add-more-headers mb-3">
													<div className="row">
														<label className="col-6">{t("banking-details.bank-name")}</label>
														<label className="col-6">{t("banking-details.account-no")}.</label>
													</div>
												</div>
												{/* <form method="post" id="add_bank_form" className="forms-col" onSubmit={this.addBankHandler} noValidate> */}
												<div className="row g-2">
													<div className="col-6">
														<ReactSelect
															classNamePrefix="react-select"
															className={`${(this.state.errorMsg.bank_name) ? 'form-control is-invalid' : ''}`}
															placeholder={t("banking-details.select-bank")}
															name="bank_name"
															options={addMemberBankArray}
															onChange={this.handleBankChange}
															value={(this.state.bank_key !== null) ? addMemberBankArray[this.state.bank_key] : ''}
															formatOptionLabel={(item) => (
																<>
																	<img src={
																		(bankImg[`${item.icon.toLowerCase()}.svg`] !== undefined)
																			?
																			bankImg[`${item.icon.toLowerCase()}.svg`]
																			:
																			bankImg["default-bank-icon.png"]
																	} alt={item.label} />
																	<p>{item.label}</p>
																</>
															)}
														/>
														<div className="invalid-feedback">{this.state.formError && this.state.errorMsg.bank_name}</div>
													</div>
													<div className="col-6">
														<div className="d-flex">
															<div className="w-100 me-2">
																<NumberInput
																	id="bank_acc_number"
																	name="bank_acc_number"
																	className={`form-control me-2 ${(this.state.errorMsg.bank_acc_number) ? 'was-validated is-invalid' : ''}`}
																	value={this.state.bank_acc_number}
																	onChange={(event) => this.onInputChangeHandler(event)}
																	step="any"
																	required={true}
																	requiredMessage={t("validation.required")}
																	formError={this.state.formError}
																	errorMsg={this.state.errorMsg.bank_acc_number || ''}
																/>
															</div>
															<Button typeButton="submit" classValue="btn btn-gradient-blue" idValue="submit" buttonName={t("banking-details.add-bank")} style={{ width: "30%" }} />
														</div>
													</div>
												</div>
												{/* </form> */}
											</div>
										</>
									)}
								</form>
							</div>
						</>
					) : (
						<MobileBankingDetails
							{...this.state}
							rsData={rsData}
							columns={columns}
							datatablesChangeHandler={this.datatablesChangeHandler}
							actionFormatter={this.actionFormatter}
							removeBankHandler={this.removeBankHandler}
							searchHandler={this.searchHandler}
						/>
					)
				}
			</Auxiliary>
		);
	}
}

const mapStateToProps = (state) => {
	const { profile } = state;

	return {
		memberBankData: profile.member_bank_data,
		memberAddBankResult: profile.member_add_bank_result,
		memberEditBankResult: profile.member_edit_bank_result,
		memberRemoveBankResult: profile.member_remove_bank_result,
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getMemberBank: () => dispatch(getMemberBank()),
		addMemberBank: (data) => dispatch(addMemberBank(data)),
		editMemberBank: (data) => dispatch(editMemberBank(data)),
		removeMemberBank: (data) => dispatch(removeMemberBank(data)),
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(BankingDetails));