import React, { Component } from "react";
import Auxiliary from "../../../hoc/Auxiliary/Auxiliary";
// import { NavLink } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";

// import { Icon } from "@iconify/react";
import Slider from "react-slick";

import MobileSubHeader from "../../../components/PageHeader/MobileSubHeader";
import moment from "moment";
import { getTopProfile } from "../../../store/ducks/profile/actions";

export class MobileVipExclusive extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rangeValue: 70,
      expireDate: moment().add(1, 'M').startOf('month').format('YYYY-MM-DD HH:mm:ss'),
      topProfileData: [],
    };
  }

  componentDidMount() {
    if (localStorage.getItem("accessToken")) {
      this.props.getTopProfile();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.topProfileData !== undefined && nextProps.topProfileData !== this.props.topProfileData) {
      if (nextProps.topProfileData.responseCode === 200) {
        this.setState({
          topProfileData: nextProps.topProfileData.data,
        });
      }
    }

    return true;
  }

  imageImport = (r) => {
    let images = {};
    r.keys().map((item, index) => {
      return (images[item.replace("./", "")] = r(item)["default"]);
    });
    return images;
  };

  render() {
    const icons = this.imageImport(
      require.context(
        "../../../assets/images/profile/icons",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const vip = this.imageImport(
      require.context(
        "../../../assets/images/profile/vip",
        false,
        /\.(gif|png|jpe?g|svg|webp|webm)$/
      )
    );

    const avatar = this.imageImport(
      require.context("../../../assets/images/profile/profile-icons", false, /\.(png|jpe?g|svg|webp)$/)
    );

    const ctgIcon = this.imageImport(
      require.context(
        "../../../assets/images/icons/mobile",
        false,
        /\.(png|jpe?g|svg|webp)$/
      )
    );

    const settings = {
      slidesToShow: 1,
      centerMode: true,
      centerPadding: "60px",
      // variableWidth:true,
      adaptiveHeight: false,
      focusOnSelect: true,
      dots: false,
      arrows: false,
      initialSlide: (this.props.currentUser.user_group_level ?? 1) - 1,
      customPaging: function (i) {
        return <span>{i + 1}</span>;
      },
      beforeChange: (current, next) =>
        this.setState({ oldSlide: current, activeSlide: next }),
      afterChange: (current) => [
        this.setState({ activeSlide2: current }),
        this.props.handleClick(current + 1),
      ],
    };

    const { t } = this.props;
    const num_format = { maximumFractionDigits: 2, minimumFractionDigits: 2 };
    const vipList = this.props.vipData ? this.props.vipData.map((val, idx) => {
      return {
        id: val.level,
        name: t(`vip.vip-${val.level}`),
        img: 'vip' + (val.level-1),
        current: this.props.currentUser.user_group_level === val.level ? true : false,
        deposit: val.minDepositTierUpgrade,
        turnover: val.minTurnoverTierUpgrade
      };
    }) : [];

    // Get birthday countdown
    let birthdayCountdown = null;
    if (this.props.dob) {
      let nextBirthday = moment().format("yyyy-")+moment(this.props.dob).format("MM-DD");
      if (nextBirthday === moment().format("yyyy-MM-DD")) {
          birthdayCountdown = "("+t("vip.happy-birthday")+")";
      } else {
          if (nextBirthday < moment().format("yyyy-MM-DD")) {
              nextBirthday = moment().add(1,"years").format("yyyy-")+moment(this.props.dob).format("MM-DD");
          }

          const given = moment(nextBirthday, "YYYY-MM-DD");
          const current = moment().startOf('day');
          const dayLeft = moment.duration(given.diff(current)).asDays();
          birthdayCountdown = "("+t("global.count-down")+" "+dayLeft+" "+t("datepicker.day")+")";
      }
    }

    return (
      <Auxiliary>
        <section className="ve-m-container">
          <div className="ve-m-header">
            <MobileSubHeader
              returnPage="/me"
              header={t("profile-sidebar.vip-exclusive")}
              subPage="/vip"
              subPageHeader={t("vip.vip-details-mobile")}
            />
            <div className="pi-m-info mt-3">
              <div role="button" className="user-profile-container">
                <img
                  className="user-profile-img"
                  src={(this.state.topProfileData && this.state.topProfileData.topProfile && this.state.topProfileData.topProfile.profile_picture) ? this.state.topProfileData.topProfile.profile_picture : avatar["male-7.jpg"]}
                  alt="profile img"
                />
              </div>
              <div className="user-info">
                <p className="us-name pe-2">{this.props.currentUser.username}</p>
                <p className="vip-icon">  <img src={avatar[`vip-${this.props.currentUser.user_group_level}.webp`]} alt="vip-badge"/> </p>
                {/* <p className="vip-icon">{t(`vip.vip-${this.props.currentUser.user_group_level}`)}</p> */}
              </div>
            </div>
            <div className="dt-container mt-3">
              <div className="dt-amount">
                <p className="deposit">{t("vip.deposit")}: </p>
                <div className="progress">
                  <p className="number back">
                    <span>{this.props.currentUser.currency}{new Intl.NumberFormat("en-US", num_format).format(this.props.curDepositTotal)} / {new Intl.NumberFormat("en-US", num_format).format(this.props.requireDeposit)}</span>
                  </p>
                  <p
                    className="number front"
                    style={{ clipPath: `inset(0 0 0 ${this.props.requireDepositRate}%)` }}
                  >
                    <span>{this.props.currentUser.currency}{new Intl.NumberFormat("en-US", num_format).format(this.props.curDepositTotal)} / {new Intl.NumberFormat("en-US", num_format).format(this.props.requireDeposit)}</span>
                  </p>
                </div>
              </div>
              <div className="dt-amount">
                <p className="turnover">{t("vip.turnover")} </p>
                <div className="progress">
                  <p className="number back">
                    <span>{this.props.currentUser.currency}{new Intl.NumberFormat("en-US", num_format).format(this.props.curTurnoverTotal)} / {new Intl.NumberFormat("en-US", num_format).format(this.props.requireTurnover)}</span>
                  </p>
                  <p
                    className="number front"
                    style={{ clipPath: `inset(0 0 0 ${this.props.requireTurnoverRate}%)` }}
                  >
                    <span>{this.props.currentUser.currency}{new Intl.NumberFormat("en-US", num_format).format(this.props.curTurnoverTotal)} / {new Intl.NumberFormat("en-US", num_format).format(this.props.requireTurnover)}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="slick-container mt-3">
              <Slider ref={(c) => (this.slider = c)} {...settings}>
                {vipList &&
                  vipList.map((item, index) => (
                    <div className="ve-vip-card" key={index}>
                      <span className="card-shadow">
                        <img
                          className="vip-card"
                          src={
                            vip[
                            `${item.id === 10
                              ? "vip10-bg.webp"
                              : item.id === 11
                                ? "vip11-bg.webp"
                                : item.id === 12
                                  ? "vip12-bg.webp"
                                  : "vip-bg.webp"
                            }`
                            ]
                          }
                          alt="vip-card"
                        />
                      </span>
                      <p
                        className={`vip-state ${item.current ? "current" : ""}`}
                      >
                        {item.current ? t("vip.vip-current") : t("vip.vip-lock")}
                      </p>
                      {vip[`${item.img}${this.state.activeSlide2 === index ? ".webp": ".webp"}`] &&
                      <img
                        id={`cur-vip-logo-${index}`}
                        className="vip-logo"
                        src={vip[`${item.img}${this.state.activeSlide2 === index ? ".webp": ".webp"}`]}
                        alt="vip-logo"
                      />}
                      <div className="vip-content-container">
                        <div className="vip-content">
                          <h1>{item.name}</h1>
                          <p>
                            {t("vip.mission-requirement-text-one")} <span className="number">{(() => {
                              let temp = parseFloat(this.props.requireDeposit) - this.props.curDepositTotal;
                              if (temp > 0) {
                                return new Intl.NumberFormat("en-US", num_format).format(temp);
                              }
                              else {
                                return 0.00;
                              }
                            })()}</span> {t("vip.mission-requirement-text-two")}<br />
                            <span className="number">{(() => {
                              let temp = parseFloat(this.props.requireTurnover) - this.props.curTurnoverTotal;
                              if (temp > 0) {
                                return new Intl.NumberFormat("en-US", num_format).format(temp);
                              }
                              else {
                                return 0.00;
                              }
                            })()}</span> {t("vip.mission-requirement-text-three")} {(() => {
                              if (item.id === 6) {
                                return t(`vip.vip-${item.id}`);
                              }
                              else {
                                return t(`vip.vip-${(item.id+1)}`);
                              }
                            })()}
                          </p>
                        </div>
                        <div className="vip-notice">
                          <p>{t("vip.mission-hint").replace("_date_", this.state.expireDate)}</p>
                          <p>
                            {t("vip.mission-completed")}{" "}
                            <span className="number">{new Intl.NumberFormat("en-US", num_format).format(this.props.curDepositTotal)}</span> {t("vip.mission-completed-deposit")}{" "}
                            <span className="number">{new Intl.NumberFormat("en-US", num_format).format(this.props.curTurnoverTotal)}</span> {t("vip.mission-completed-turnover")}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
              </Slider>
            </div>
          </div>

          <div className="ve-m-content">
            <div className="my-ve">
              <h5>{t(`vip.vip-${this.props.activeVip}`)} {t("vip.exclusive")}</h5>
              <div className="row g-2">
                <div className="col-6">
                  <img src={icons["withdrawal.webp"]} alt="withdrawal" />
                  <div>
                    <p className="number">{this.props.vipInfo ? this.props.vipInfo.dailyWithdrawalCounts : 0.00}</p>
                    <p className="exclusive">{t("vip.no-withdrawals-per-day-mobile")}</p>
                  </div>
                </div>
                <div className="col-6">
                  <img src={icons["daily-limit.webp"]} alt="daily-limit" />
                  <div>
                    <p className="number">{this.props.vipInfo ? new Intl.NumberFormat("en-US", num_format).format(this.props.vipInfo.maxDailyWithdrawal) : 0.00}</p>
                    <p className="exclusive">{t("vip.daily-withdrawal-limit-sm")}</p>
                  </div>
                </div>
                <div className="col-6">
                  <img src={icons["bonus.webp"]} alt="bonus" />
                  <div>
                    <p className="number">{this.props.vipInfo ? new Intl.NumberFormat("en-US", num_format).format(this.props.vipInfo.upLevelBonus) : 0.00}</p>
                    <p className="exclusive">{t("vip.upgrade-bonus-sm")}</p>
                  </div>
                </div>
                <div className="col-6">
                  <img src={icons["monthly-cash.webp"]} alt="monthly-cash" />
                  <div>
                    <p className="number">{this.props.vipInfo ? new Intl.NumberFormat("en-US", num_format).format(this.props.vipInfo.vipWeeklybonus) : 0.00}</p>
                    <p className="exclusive">{t("vip.vip-weekly-bonus")}</p>
                    <button onClick={this.props.vipWeeklyBonusClaimHandler} className="btn btn-gradient-blue">{t("vip.redeem")}</button>
                  </div>
                </div>
                <div className="col-6">
                  <img src={icons["birthday.webp"]} alt="birthday" />
                  <div>
                    <p className="number">{this.props.vipInfo ? new Intl.NumberFormat("en-US", num_format).format(this.props.vipInfo.birthdayBonus) : 0.00}</p>
                    <p className="exclusive">{t("vip.vip-birthday-bonus")}</p>
                    {
                      this.props.set_birthday_date && <p className="exclusive">
                        {birthdayCountdown}
                      </p>
                    }
                    <button onClick={this.props.vipBirthdayBonusClaimHandler} className="btn btn-gradient-blue">{t("vip.redeem")}</button>
                  </div>
                </div>
              </div>
            </div>
            <div className="my-detail my-3">
              <h5>{t(`vip.vip-${this.props.activeVip}`)} {t("vip.category-rebate")}</h5>
              <div className="row g-4">
                <div className="col-6">
                  <span className="vip-offer-img">
                    <img src={ctgIcon["sport-m.webp"]} alt=""/>
                  </span>
                  <div>
                    <p className="number">{this.props.vipInfo ? (this.props.vipInfo.sportRebate !== null ? this.props.vipInfo.sportRebate : '0.00') : '0.00'}%</p>
                    <p>{t("page-header.sports")}</p>
                  </div>

                </div>
                <div className="col-6">
                  <span className="vip-offer-img">
                    <img src={ctgIcon["lc-m.webp"]}  alt=""/>
                  </span>
                  <div>
                    <p className="number">{this.props.vipInfo ? (this.props.vipInfo.live_casino_rebate !== null ? this.props.vipInfo.live_casino_rebate : '0.00') : '0.00'}%</p>
                    <p>{t("page-header.live-casino")}</p>
                  </div>

                </div>
                <div className="col-6">
                  <span className="vip-offer-img">
                    <img src={ctgIcon["slot-m.webp"]}  alt=""/>
                  </span>
                  <div>
                    <p className="number">{this.props.vipInfo ? (this.props.vipInfo.slotRebate !== null ? this.props.vipInfo.slotRebate : '0.00') : '0.00'}%</p>
                    <p>{t("page-header.slots")}</p>
                  </div>

                </div>
                <div className="col-6">
                  <span className="vip-offer-img">
                    <img src={ctgIcon["games-m.webp"]}  alt=""/>
                  </span>
                  <div>
                    <p className="number">{this.props.vipInfo ? (this.props.vipInfo.virtualRebate !== null ? this.props.vipInfo.virtualRebate : '0.00') : '0.00'}%</p>
                    <p>{t("page-header.games")}</p>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </section>
      </Auxiliary>
    );
  }
}

const mapStateToProps = state => {
  return {
    topProfileData: state.profile.top_profile_data
  }
}

const mapDispatchToProps = {
  getTopProfile
}

export default connect(mapStateToProps, mapDispatchToProps)(withNamespaces("translation")(MobileVipExclusive));
